<script>
    // core components
    import {Pulse} from 'svelte-loading-spinners';
    import {navigate} from 'svelte-routing';
    import {onMount} from 'svelte';
    import {selectedCampaign, selectedBookingRequest, missingLinkId} from '../../store.js';
    import {patchBookingRequest, getBookingRequestsByPodcastIdCached, getCampaignByIdCached, getPodcastCompanyExclusives, uploadFile} from '../../api/api.js';
    import {prettyPrintNumber, getDateNow, databaseToPrettyDate} from '../../functions.js';
    import {sortCountPerBookingRequest} from '../../filterBookingRequests.js';
    import {getText} from '../../language.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    // can be one of light or dark table layout
    export let color = 'light';
    export let podcast;
    // let mergedData = [];
    let allBookingRequests = [];
    let offeredImpressions = [];
    let offeredImpressionsOverview = [];
    let showExclusiveBar = false;

    const tableTitle = podcast.name;
    // let todo = 0;

    let counts = {todo: 0, waiting: 0, rejected: 0, cancelled: 0, upcoming: 0, running: 0, completed: 0, wrapped: 0};
    let bRs = {todo: [], waiting: [], rejected: [], cancelled: [], upcoming: [], running: [], completed: [], wrapped: []};

    onMount(() => {
        // reset selected campaign
        selectedCampaign.set({});
        selectedBookingRequest.set({});
    });

    // TODO continue here: Benachrichtigung über exklusive Vermarktung einblenden + email notification?
    // TODO podcast möglichkeit geben, dass er exclusiv angefragt werden darf oder nicht ???

    async function collectExclusive() {
        // check if an unanswered exclusive promotion request exists for podcast
        const exclusive = await getPodcastCompanyExclusives(podcast.id);
        !exclusive[0].confirmed ? (showExclusiveBar = true) : (showExclusiveBar = false);
    }

    // get all info from the api
    async function collectData() {
        var bookingRequests = [];

        try {
            let all = await getBookingRequestsByPodcastIdCached(podcast.id);
            all.forEach((br) => {
                if (br.activated) {
                    bookingRequests.push(br);
                }
            });
        } catch (e) {
            console.log(`Error ${e} occoured whilst loading the booking requests of podcast ${podcast.id}`);
        }

        // check todo status
        if (bookingRequests) {
            for (let bookingRequest of bookingRequests) {
                // check if something is to do
                let filtered = await sortCountPerBookingRequest(bookingRequest, counts, bRs);
                let c = filtered.counts;
                let b = filtered.bRs;
                b['todo'].forEach((bR) => {
                    if (!bRs['todo'].some((el) => el.id == bR.id)) {
                        bRs['todo'].push(bR);
                    }
                });

                for (let x in counts) {
                    counts.x += c.x;
                }

                offeredImpressions[bookingRequest.campaign_id] = bookingRequest.offered_impressions ? bookingRequest.offered_impressions : bookingRequest.requested_impressions;
                offeredImpressionsOverview[bookingRequest.campaign_id] = bookingRequest.offered_impressions ? bookingRequest.offered_impressions : 0;
            }
        }

        // set allBookingRequests to todos only
        allBookingRequests = [...bRs['todo']];

        return bRs['todo'];
    }

    async function collectCampaignData(campaignId) {
        return getCampaignByIdCached(campaignId);
    }

    // Handler
    async function archiveBookingRequest(bookingRequest) {
        bookingRequest.archived = true;

        try {
            await patchBookingRequest(bookingRequest.id, bookingRequest);
        } catch (e) {
            console.log(`Error ${e} occoured whilst archiving the booking requests ${bookingRequest.id}`);
        }

        // update for reactivity
        for (let x in allBookingRequests) {
            if (allBookingRequests[x].id == bookingRequest.id) {
                allBookingRequests[x] = bookingRequest;
            }
        }
    }

    const showBookingRequestDetails = async (bookingRequest) => {
        // store selected campaign
        selectedCampaign.set(await collectCampaignData(bookingRequest.campaign_id));
        selectedBookingRequest.set(bookingRequest);
        navigate(`/podcast/campaign/${$selectedCampaign.id}/booking-request/${bookingRequest.id}`, {replace: false});
    };

    // ACCORDION
    let accordionKey = null;
    const duration = 2; // seconds
    const easing = 'linear';

    // FILE UPLOAD
    let file;
    let fileName;
    let duration_ms;
    let formData = new FormData();

    async function submitForm(type, bookingRequest) {
        event.preventDefault();

        fileName = file[0].name;
        fileName = fileName.replaceAll(' ', '-');

        formData.append('file', file[0]);
        formData.append('filename', fileName);

        if (type == 'audio') {
            // TODO accept other format as well?
            if (!fileName.endsWith('mp3')) {
                window.alert(getText('ui_messages.alerts.podcast.alert_mp3_check'));
                return;
            }

            disableButtons();
            if (bookingRequest.state == 2) {
                await uploadAudio(bookingRequest)
                    .then(() => {
                        getURLDuration(`https://asap-dispo.s3.amazonaws.com/audio/${fileName}`).then(() => {
                            setTimeout(() => {
                                if (accordionKey != null) {
                                    enabledButtons();
                                }
                                navigate(`/podcast`);
                            }, 1500);
                        });
                    })
                    .catch(() => {
                        if (accordionKey != null) {
                            enabledButtons();
                        }
                        console.log(`Error ${e} occoured whilst uploading the audio file ${fileName}`);
                    });
            }
            if (bookingRequest.state == 3 || bookingRequest.state == 5) {
                await uploadAudioWithStateChange(bookingRequest)
                    .then(() => {
                        getURLDuration(`https://asap-dispo.s3.amazonaws.com/audio/${fileName}`).then(() => {
                            setTimeout(() => {
                                if (accordionKey != null) {
                                    enabledButtons();
                                }
                                navigate(`/podcast`);
                            }, 1500);
                        });
                    })
                    .catch(() => {
                        if (accordionKey != null) {
                            enabledButtons();
                        }
                        console.log(`Error ${e} occoured whilst uploading the audio file ${fileName}`);
                    });
            }
        }
        if (type == 'report') {
            uploadPDF(bookingRequest);
        }
    }

    function disableButtons() {
        document.getElementById('upload-pulse').style.display = 'flex';
        Array.from(document.querySelectorAll('button')).forEach((elem) => {
            elem.setAttribute('disabled', '');
            elem.style.filter = 'grayscale(1)';
        });
    }

    function enabledButtons() {
        document.getElementById('upload-pulse').style.display = 'none';
        Array.from(document.querySelectorAll('button')).forEach((elem) => {
            elem.removeAttribute('disabled');
            elem.style.filter = 'grayscale(0)';
        });
    }

    async function uploadAudio(bookingRequest) {
        let response = await uploadFile(formData, 'audio').then((response) => {
            bookingRequest['audiofile_url'] = `https://asap-dispo.s3.amazonaws.com/audio/${fileName}`;
            bookingRequest.audiofile_uploaded_date = getDateNow();
            bookingRequest.audiofile_duration_ms = parseInt(duration_ms);
            return response;
        });

        if (response.status != 200) {
            window.alert(getText('ui_messages.alerts.podcast.alert_audio_upload', response.status));
        } else {
            try {
                await patchBookingRequest(bookingRequest.id, bookingRequest);
            } catch (e) {
                console.log(`Error ${e} occoured whilst patching the audio for the booking requests ${bookingRequest.id}`);
            }

            for (let x in allBookingRequests) {
                if (allBookingRequests[x].id == bookingRequest.id) {
                    allBookingRequests[x] = bookingRequest;
                }
            }
        }
    }

    // if already agreed and now an audiofile, set state and close acc
    async function uploadAudioWithStateChange(bookingRequest) {
        // save audiofile to s3
        let response = await uploadFile(formData, 'audio').then((response) => {
            bookingRequest['audiofile_url'] = `https://asap-dispo.s3.amazonaws.com/audio/${fileName}`;
            bookingRequest.audiofile_uploaded_date = getDateNow();
            bookingRequest.audiofile_duration_ms = parseInt(duration_ms);
            return response;
        });

        bookingRequest.state = 7;
        accordionKey = null;

        if (response.status != 200) {
            window.alert(getText('ui_messages.alerts.podcast.alert_audio_upload', response.status));
        } else {
            try {
                await patchBookingRequest(bookingRequest.id, bookingRequest);
            } catch (e) {
                console.log(`Error ${e} occoured whilst patching the audio for the booking requests ${bookingRequest.id}`);
            }

            for (let x in allBookingRequests) {
                if (allBookingRequests[x].id == bookingRequest.id) {
                    allBookingRequests[x] = bookingRequest;
                }
            }
        }
    }

    async function uploadPDF(bookingRequest) {
        let response = await uploadFile(formData, 'report').then((response) => {
            bookingRequest['report'] = `https://asap-dispo.s3.amazonaws.com/report/${fileName}`;
            bookingRequest.state = 11;
            bookingRequest.report_uploaded_date = getDateNow();
            return response;
        });

        accordionKey = null;

        if (response.status != 200) {
            window.alert(getText('ui_messages.alerts.podcast.alert_report_upload'));
        } else {
            try {
                await patchBookingRequest(bookingRequest.id, bookingRequest);
            } catch (e) {
                console.log(`Error ${e} occoured whilst patching the report for the booking requests ${bookingRequest.id}`);
            }

            // update for file uploaded state
            for (let x in allBookingRequests) {
                if (allBookingRequests[x].id == bookingRequest.id) {
                    allBookingRequests[x] = bookingRequest;
                }
            }
        }
    }

    $: allBookingRequests && checkOnActivity();
    let doSomething = [];
    let fileUploaded = [];
    let stateUpdated = [];

    function checkOnActivity() {
        doSomething = [];
        fileUploaded = [];
        stateUpdated = [];
        if (allBookingRequests.length > 0) {
            allBookingRequests.forEach((bR) => {
                stateUpdated[bR.campaign_id] = true;
                if (bR.audiofile_url) {
                    fileUploaded[bR.campaign_id] = true;
                } else if (bR.state == 6 && bR.archived) {
                    doSomething[bR.campaign_id] = false;
                    return;
                }
                doSomething[bR.campaign_id] = true;
            });
        }
    }

    var promiseData = collectData();
    let promiseExclusive = collectExclusive();
</script>

{#await promiseExclusive}
    <div style="display: flex; justify-content: center">
        <Pulse size="60" color="#FF3E00" unit="px" duration="1s" />
    </div>
{:then data}
    {#if showExclusiveBar}
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">
            <div class="rounded-t mb-0 px-4 py-3 border-0">
                <div class="flex flex-wrap items-center">
                    <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                        <h3 class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}">
                            {getText('podcast.inventory.exclusive_promoter.section_title')}
                        </h3>
                    </div>
                </div>
                <div class="flex flex-wrap border-t-0 align-middle border-l-0 border-r-0 mb-4">
                    <div class="flex-auto p-4">
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-12/12 px-4">
                                <div class="relative w-full mb-3" />
                                <h4 class="mb-4">
                                    {getText('podcast.inventory.exclusive_promoter.section_4.title', [podcast.name, '<p>button</p>'])}
                                </h4>
                                <CardButton
                                    id="edit-settings"
                                    type="go"
                                    text={getText('buttons.button_edit')}
                                    on:click={() => {
                                        navigate(`/podcast/inventory/settings`, {replace: false});
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    {/if}
{/await}

{#await promiseData}
    <div style="display: flex; justify-content: center">
        <Pulse size="60" color="#FF3E00" unit="px" duration="1s" />
    </div>
{:then data}
    {#if counts.todo > 0}
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">
            <div class="rounded-t mb-0 px-4 py-3 border-0">
                <div class="flex flex-wrap items-center">
                    <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                        <h3 class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}">
                            {tableTitle}
                        </h3>
                    </div>
                </div>
            </div>

            <div class="block w-full overflow-x-auto">
                <table class="items-center w-full bg-transparent border-collapse">
                    <thead>
                        <tr>
                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-700 text-red-200 border-red-600'}"
                            >
                                {getText('podcast.booking_requests.todo.headline_1')}
                            </th>

                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-700 text-red-200 border-red-600'}"
                            >
                                {getText('podcast.booking_requests.todo.headline_2')}
                            </th>

                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-700 text-red-200 border-red-600'}"
                            >
                                <div>{getText('podcast.booking_requests.todo.headline_3')}</div>
                            </th>

                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-700 text-red-200 border-red-600'}"
                            >
                                {getText('podcast.booking_requests.todo.headline_4')}
                            </th>

                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-700 text-red-200 border-red-600'}"
                            >
                                {getText('podcast.booking_requests.todo.headline_5')}
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {#each data as bookingRequest (bookingRequest.id)}
                            {#if doSomething[bookingRequest.campaign_id]}
                                <tr
                                    on:click={() => {
                                        showBookingRequestDetails(bookingRequest);
                                    }}
                                    class="hover:cell-highlight-color cursor-pointer"
                                >
                                    <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                        {#if doSomething[bookingRequest.campaign_id]}
                                            <i class="fas fa-bell text-orange-500" />
                                            <span class="ml-3 font-bold {color === 'light' ? 'btext-blueGray-600' : 'text-whit'}">{bookingRequest.sender}</span>
                                        {:else}
                                            <i class="fas fa-exclamation text-red-500" />
                                            <span class="ml-3 font-bold {color === 'light' ? 'btext-blueGray-600' : 'text-whit'}">{bookingRequest.sender}</span>
                                        {/if}
                                    </th>

                                    {#if doSomething[bookingRequest.campaign_id]}
                                        {#if bookingRequest.state == 2}
                                            <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                                {#if bookingRequest.campaign.type == 'hostread'}
                                                    <i class="fas fa-microphone text-blueGray-400" />
                                                {:else if bookingRequest.campaign.type == 'spot'}
                                                    <i class="fas fa-play-circle text-blueGray-400" />
                                                {:else if bookingRequest.campaign.type == 'crosspromo'}
                                                    <i class="fas fa-bullhorn text-blueGray-400" />
                                                {/if}
                                                {bookingRequest.campaign.title}
                                            </th>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {#if doSomething[bookingRequest.campaign_id]}
                                                    {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                                {:else}
                                                    {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                                {/if}
                                            </td>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {getText('podcast.booking_requests.todo.rows.next_step_1')}
                                            </td>
                                        {:else if bookingRequest.state == 3}
                                            {#if bookingRequest.campaign.type == 'spot' || bookingRequest.campaign.type == 'crosspromo'}
                                                <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                                    {#if bookingRequest.campaign.type == 'spot'}
                                                        <i class="fas fa-play-circle text-blueGray-400" />
                                                    {:else if bookingRequest.campaign.type == 'crosspromo'}
                                                        <i class="fas fa-bullhorn text-blueGray-400" />
                                                    {/if}
                                                    {bookingRequest.campaign.title}
                                                </th>
                                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                                </td>
                                                {#if !bookingRequest.booking_changes_requested && bookingRequest.campaign.audiofile_url}
                                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                        <!-- Audio uploaded  -->
                                                        {getText('podcast.booking_requests.todo.rows.next_step_2')}
                                                    </td>
                                                {:else if bookingRequest.booking_changes_requested}
                                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                        <!-- React to change request ! -->
                                                        {getText('podcast.booking_requests.todo.rows.next_step_3')}
                                                    </td>
                                                {/if}
                                            {:else if bookingRequest.campaign.type == 'hostread'}
                                                <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                                    <i class="fas fa-microphone text-blueGray-400" />
                                                    {bookingRequest.campaign.title}
                                                </th>
                                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                                </td>
                                                {#if bookingRequest.booking_changes_requested}
                                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                        <!-- React to change request ! -->
                                                        {getText('podcast.booking_requests.todo.rows.next_step_3')}
                                                    </td>
                                                {:else if !bookingRequest.campaign.briefing_completed}
                                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                        <!-- Awaiting briefing -->
                                                        {getText('podcast.booking_requests.todo.rows.next_step_4')}
                                                    </td>
                                                {/if}
                                            {/if}
                                        {:else if bookingRequest.state == 5}
                                            <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                                <i class="fas fa-microphone text-blueGray-400" />
                                                {bookingRequest.campaign.title}
                                            </th>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                            </td>
                                            {#if !bookingRequest.campaign.briefing_completed}
                                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <!-- Awaiting briefing -->
                                                    {getText('podcast.booking_requests.todo.rows.next_step_4')}
                                                </td>
                                            {:else if bookingRequest.campaign.briefing_completed && bookingRequest.campaign.feedback_loop_script && bookingRequest.script_updated_promoter && !bookingRequest.script_approved_date}
                                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <!-- Check script briefing ! -->
                                                    {getText('podcast.booking_requests.todo.rows.next_step_15')}
                                                </td>
                                            {:else if bookingRequest.campaign.briefing_completed && bookingRequest.script_approved_date && bookingRequest.campaign.feedback_loop_script && !bookingRequest.audiofile_url}
                                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <!-- Script approved - produce audio ! -->
                                                    {getText('podcast.booking_requests.todo.rows.next_step_6')}
                                                </td>
                                            {:else if bookingRequest.campaign.briefing_completed && bookingRequest.campaign.feedback_loop_audio && bookingRequest.audiofile_url && !bookingRequest.audiofile_url_approved}
                                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <!-- Check audio feedback ! -->
                                                    {getText('podcast.booking_requests.todo.rows.next_step_7')}
                                                </td>
                                            {:else if bookingRequest.campaign.briefing_completed && bookingRequest.campaign.feedback_loop_none}
                                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <!-- React to briefing ! -->
                                                    {getText('podcast.booking_requests.todo.rows.next_step_8')}
                                                </td>
                                            {:else if bookingRequest.campaign.briefing_completed}
                                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <!-- Briefing completed ! -->
                                                    {getText('podcast.booking_requests.todo.rows.next_step_9')}
                                                </td>
                                            {:else if bookingRequest.campaign.briefing_completed && !bookingRequest.audiofile_url}
                                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <!-- Upload audio ! -->
                                                    {getText('podcast.booking_requests.todo.rows.next_step_10')}
                                                </td>
                                            {:else if bookingRequest.campaign.briefing_completed}
                                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <!-- React to briefing ! -->
                                                    {getText('podcast.booking_requests.todo.rows.next_step_8')}
                                                </td>
                                            {/if}
                                        {:else if bookingRequest.state == 6 && !bookingRequest.archived}
                                            <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                                {#if bookingRequest.campaign.type == 'hostread'}
                                                    <i class="fas fa-microphone text-blueGray-400" />
                                                {:else if bookingRequest.campaign.type == 'spot'}
                                                    <i class="fas fa-play-circle text-blueGray-400" />
                                                {:else if bookingRequest.campaign.type == 'crosspromo'}
                                                    <i class="fas fa-bullhorn text-blueGray-400" />
                                                {/if}
                                                {bookingRequest.campaign.title}
                                            </th>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {#if doSomething[bookingRequest.campaign_id]}
                                                    {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                                {:else}
                                                    {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                                {/if}
                                            </td>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                <!-- Booking Request has been withdrawn on {databaseToPrettyDate(bookingRequest.cancelled_date)} -->
                                                {getText('podcast.booking_requests.todo.rows.next_step_14', databaseToPrettyDate(bookingRequest.cancelled_date))}
                                            </td>
                                        {:else if bookingRequest.state == 7}
                                            <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                                {#if bookingRequest.campaign.type == 'hostread'}
                                                    <i class="fas fa-microphone text-blueGray-400" />
                                                {:else if bookingRequest.campaign.type == 'spot'}
                                                    <i class="fas fa-play-circle text-blueGray-400" />
                                                {:else if bookingRequest.campaign.type == 'crosspromo'}
                                                    <i class="fas fa-bullhorn text-blueGray-400" />
                                                {/if}
                                                {bookingRequest.campaign.title}
                                            </th>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                            </td>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                <!-- Awaiting audiofile to be approved -->
                                                {getText('podcast.booking_requests.todo.rows.next_step_11')}
                                            </td>
                                        {:else if bookingRequest.state == 8}
                                            <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                                {#if bookingRequest.campaign.type == 'hostread'}
                                                    <i class="fas fa-microphone text-blueGray-400" />
                                                {:else if bookingRequest.campaign.type == 'spot'}
                                                    <i class="fas fa-play-circle text-blueGray-400" />
                                                {:else if bookingRequest.campaign.type == 'crosspromo'}
                                                    <i class="fas fa-bullhorn text-blueGray-400" />
                                                {/if}
                                                {bookingRequest.campaign.title}
                                            </th>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {#if doSomething[bookingRequest.campaign_id]}
                                                    {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                                {:else}
                                                    {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                                {/if}
                                            </td>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {getText('podcast.booking_requests.todo.rows.next_step_12')}
                                            </td>
                                        {:else if bookingRequest.state == 10}
                                            <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                                {#if bookingRequest.campaign.type == 'hostread'}
                                                    <i class="fas fa-microphone text-blueGray-400" />
                                                {:else if bookingRequest.campaign.type == 'spot'}
                                                    <i class="fas fa-play-circle text-blueGray-400" />
                                                {:else if bookingRequest.campaign.type == 'crosspromo'}
                                                    <i class="fas fa-bullhorn text-blueGray-400" />
                                                {/if}
                                                {bookingRequest.campaign.title}
                                            </th>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {#if doSomething[bookingRequest.campaign_id]}
                                                    {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                                {:else}
                                                    {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                                {/if}
                                            </td>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                <!-- Campaign is over - upload report ! -->
                                                {getText('podcast.booking_requests.todo.rows.next_step_13')}
                                            </td>
                                        {/if}

                                        <!-- Same as above - to trigger reactive re-render -->
                                    {:else if !doSomething[bookingRequest.campaign_id]}
                                        {#if bookingRequest.state == 2}
                                            <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                                {#if bookingRequest.campaign.type == 'hostread'}
                                                    <i class="fas fa-microphone text-blueGray-400" />
                                                {:else if bookingRequest.campaign.type == 'spot'}
                                                    <i class="fas fa-play-circle text-blueGray-400" />
                                                {:else if bookingRequest.campaign.type == 'crosspromo'}
                                                    <i class="fas fa-bullhorn text-blueGray-400" />
                                                {/if}
                                                {bookingRequest.campaign.title}
                                            </th>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {#if doSomething[bookingRequest.campaign_id]}
                                                    {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                                {:else}
                                                    {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                                {/if}
                                            </td>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {getText('podcast.booking_requests.todo.rows.next_step_1')}
                                            </td>
                                        {:else if bookingRequest.state == 3}
                                            {#if bookingRequest.campaign.type == 'spot' || bookingRequest.campaign.type == 'crosspromo'}
                                                <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                                    {#if bookingRequest.campaign.type == 'spot'}
                                                        <i class="fas fa-play-circle text-blueGray-400" />
                                                    {:else if bookingRequest.campaign.type == 'crosspromo'}
                                                        <i class="fas fa-bullhorn text-blueGray-400" />
                                                    {/if}
                                                    {bookingRequest.campaign.title}
                                                </th>
                                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                                </td>
                                                {#if !bookingRequest.booking_changes_requested && bookingRequest.campaign.audiofile_url}
                                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                        <!-- Audio uploaded  -->
                                                        {getText('podcast.booking_requests.todo.rows.next_step_2')}
                                                    </td>
                                                {:else if bookingRequest.booking_changes_requested}
                                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                        <!-- React to change request ! -->
                                                        {getText('podcast.booking_requests.todo.rows.next_step_3')}
                                                    </td>
                                                {/if}
                                            {:else if bookingRequest.campaign.type == 'hostread'}
                                                <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                                    <i class="fas fa-microphone text-blueGray-400" />
                                                    {bookingRequest.campaign.title}
                                                </th>
                                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                                </td>
                                                {#if bookingRequest.booking_changes_requested}
                                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                        <!-- React to change request ! -->
                                                        {getText('podcast.booking_requests.todo.rows.next_step_3')}
                                                    </td>
                                                {:else if !bookingRequest.campaign.briefing_completed}
                                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                        <!-- Awaiting briefing -->
                                                        {getText('podcast.booking_requests.todo.rows.next_step_4')}
                                                    </td>
                                                {/if}
                                            {/if}
                                        {:else if bookingRequest.state == 5}
                                            <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                                <i class="fas fa-microphone text-blueGray-400" />
                                                {bookingRequest.campaign.title}
                                            </th>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                            </td>
                                            {#if !bookingRequest.campaign.briefing_completed}
                                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <!-- Awaiting briefing -->
                                                    {getText('podcast.booking_requests.todo.rows.next_step_4')}
                                                </td>
                                            {:else if bookingRequest.campaign.briefing_completed && bookingRequest.campaign.feedback_loop_script && bookingRequest.script_updated_promoter && !bookingRequest.script_approved_date}
                                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <!-- Check script briefing ! -->
                                                    {getText('podcast.booking_requests.todo.rows.next_step_15')}
                                                </td>
                                            {:else if bookingRequest.campaign.briefing_completed && bookingRequest.script_approved_date && bookingRequest.campaign.feedback_loop_script && !bookingRequest.audiofile_url}
                                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <!-- Script approved - produce audio ! -->
                                                    {getText('podcast.booking_requests.todo.rows.next_step_6')}
                                                </td>
                                            {:else if bookingRequest.campaign.briefing_completed && bookingRequest.campaign.feedback_loop_audio && bookingRequest.audiofile_url && !bookingRequest.audiofile_url_approved}
                                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <!-- Check audio feedback ! -->
                                                    {getText('podcast.booking_requests.todo.rows.next_step_7')}
                                                </td>
                                            {:else if bookingRequest.campaign.briefing_completed && bookingRequest.campaign.feedback_loop_none}
                                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <!-- React to briefing ! -->
                                                    {getText('podcast.booking_requests.todo.rows.next_step_8')}
                                                </td>
                                            {:else if bookingRequest.campaign.briefing_completed}
                                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <!-- Briefing completed ! -->
                                                    {getText('podcast.booking_requests.todo.rows.next_step_9')}
                                                </td>
                                            {:else if bookingRequest.campaign.briefing_completed && !bookingRequest.audiofile_url}
                                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <!-- Upload audio ! -->
                                                    {getText('podcast.booking_requests.todo.rows.next_step_10')}
                                                </td>
                                            {:else if bookingRequest.campaign.briefing_completed}
                                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                    <!-- React to briefing ! -->
                                                    {getText('podcast.booking_requests.todo.rows.next_step_8')}
                                                </td>
                                            {/if}
                                        {:else if bookingRequest.state == 6 && !bookingRequest.archived}
                                            <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                                {#if bookingRequest.campaign.type == 'hostread'}
                                                    <i class="fas fa-microphone text-blueGray-400" />
                                                {:else if bookingRequest.campaign.type == 'spot'}
                                                    <i class="fas fa-play-circle text-blueGray-400" />
                                                {:else if bookingRequest.campaign.type == 'crosspromo'}
                                                    <i class="fas fa-bullhorn text-blueGray-400" />
                                                {/if}
                                                {bookingRequest.campaign.title}
                                            </th>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {#if doSomething[bookingRequest.campaign_id]}
                                                    {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                                {:else}
                                                    {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                                {/if}
                                            </td>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                <!-- Booking Request has been withdrawn on {databaseToPrettyDate(bookingRequest.cancelled_date)} -->
                                                {getText('podcast.booking_requests.todo.rows.next_step_14', databaseToPrettyDate(bookingRequest.cancelled_date))}
                                            </td>
                                        {:else if bookingRequest.state == 7}
                                            <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                                {#if bookingRequest.campaign.type == 'hostread'}
                                                    <i class="fas fa-microphone text-blueGray-400" />
                                                {:else if bookingRequest.campaign.type == 'spot'}
                                                    <i class="fas fa-play-circle text-blueGray-400" />
                                                {:else if bookingRequest.campaign.type == 'crosspromo'}
                                                    <i class="fas fa-bullhorn text-blueGray-400" />
                                                {/if}
                                                {bookingRequest.campaign.title}
                                            </th>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                            </td>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                <!-- Awaiting audiofile to be approved -->
                                                {getText('podcast.booking_requests.todo.rows.next_step_11')}
                                            </td>
                                        {:else if bookingRequest.state == 8}
                                            <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                                {#if bookingRequest.campaign.type == 'hostread'}
                                                    <i class="fas fa-microphone text-blueGray-400" />
                                                {:else if bookingRequest.campaign.type == 'spot'}
                                                    <i class="fas fa-play-circle text-blueGray-400" />
                                                {:else if bookingRequest.campaign.type == 'crosspromo'}
                                                    <i class="fas fa-bullhorn text-blueGray-400" />
                                                {/if}
                                                {bookingRequest.campaign.title}
                                            </th>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {#if doSomething[bookingRequest.campaign_id]}
                                                    {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                                {:else}
                                                    {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                                {/if}
                                            </td>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {getText('podcast.booking_requests.todo.rows.next_step_12')}
                                            </td>
                                        {:else if bookingRequest.state == 10}
                                            <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                                {#if bookingRequest.campaign.type == 'hostread'}
                                                    <i class="fas fa-microphone text-blueGray-400" />
                                                {:else if bookingRequest.campaign.type == 'spot'}
                                                    <i class="fas fa-play-circle text-blueGray-400" />
                                                {:else if bookingRequest.campaign.type == 'crosspromo'}
                                                    <i class="fas fa-bullhorn text-blueGray-400" />
                                                {/if}
                                                {bookingRequest.campaign.title}
                                            </th>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {#if doSomething[bookingRequest.campaign_id]}
                                                    {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                                {:else}
                                                    {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                                {/if}
                                            </td>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                <!-- Campaign is over - upload report ! -->
                                                {getText('podcast.booking_requests.todo.rows.next_step_13')}
                                            </td>
                                        {/if}
                                    {/if}

                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {#if bookingRequest.state == 6 && !bookingRequest.archived}
                                            <CardButton
                                                id="archive-campaign"
                                                type="default"
                                                icon="fas fa-archive mr-2 text-sm"
                                                text={getText('podcast.booking_requests.todo.rows.button_archive')}
                                                on:click={(e) => {
                                                    archiveBookingRequest(bookingRequest);
                                                    e.stopPropagation();
                                                }}
                                            />
                                        {:else}
                                            <CardButton id="info" type="icon" icon="fas fa-info-circle mr-2 text-sm" on:click={() => showBookingRequestDetails(bookingRequest)} />
                                        {/if}
                                    </td>
                                </tr>
                            {/if}
                        {/each}
                    </tbody>
                </table>
            </div>
        </div>
    {/if}
{/await}
