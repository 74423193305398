import {countApiCall, countCacheCall, countSoloCacheCall, getApiData, setApiCache} from './api-cache';

export const env = process;
let fastApiBaseUrl = 'https://' + (env.API_HOST || 'dispo.svmaudio.com') + (env.API_PORT ? ':' + env.API_PORT : '') + (env.API_PATH || '');
// let fastApiBaseUrl = 'https://apidev.dispo.svmaudio.com:8081';
// let fastApiBaseUrl = 'https://apidev.dispo.svmaudio.com:5050';

/*
 ************************************************************
 *
 *                    PROMETHEUS LIVE TRACKS
 *           not used when cronjob crawler is active
 *
 ************************************************************
 */
export const getPrometheusImpressionsAll = async () => {
    countApiCall('getPrometheusImpressionsAll');
    try {
        return fetch(`${fastApiBaseUrl}/impressions`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

export const getPrometheusImpressionsDealId = async (dealId) => {
    countApiCall('getPrometheusImpressionsDealId');
    try {
        return fetch(`${fastApiBaseUrl}/impressions/${dealId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

/*
 ************************************************************
 *
 *                       AUTH
 *
 ************************************************************
 */
export const login = async (email, password) => {
    countApiCall('login');
    try {
        return fetch(`${fastApiBaseUrl}/auth/login`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            credentials: 'include',
            body: `grant_type=&username=${encodeURIComponent(email)}&password=${password}&scope=&client_id=&client_secret=`,
        });
    } catch (e) {
        console.log(e);
    }
};

export const logout = async () => {
    countApiCall('logout');
    try {
        return fetch(`${fastApiBaseUrl}/auth/logout`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            credentials: 'include',
            body: '',
        });
    } catch (e) {
        console.log(e);
    }
};

export const whoami = async () => {
    if (Object.keys(getApiData().whoami).length > 0) {
        return new Promise((resolve, reject) => {
            countCacheCall('whoami');
            resolve(getApiData().whoami);
        });
    }

    try {
        if (fastApiBaseUrl) {
            return fetch(`${fastApiBaseUrl}/users/me`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            })
                .then((response) => response.json())
                .then((data) => {
                    countApiCall('whoami');
                    setApiCache('whoami', data, 'GET');
                    return data;
                });
        }
    } catch (e) {
        console.log(e);
    }
};

export const register = async (data) => {
    try {
        return fetch(`${fastApiBaseUrl}/auth/register`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(data),
        });
    } catch (e) {
        console.log(e);
    }
};

export const getAllUsersOfCompany = async (companyId) => {
    countApiCall('getAllUsersOfCompany');
    try {
        return fetch(`${fastApiBaseUrl}/company/${companyId}/user`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

export const getAllUsers = async () => {
    countApiCall('getAllUsers');
    try {
        return fetch(`${fastApiBaseUrl}/admin/users`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const patchUser = async (data) => {
    try {
        return fetch(`${fastApiBaseUrl}/users/me`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

export const patchUserById = (userId, data) => {
    try {
        return fetch(`${fastApiBaseUrl}/users/${userId}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const deleteUserById = (userId) => {
    try {
        return fetch(`${fastApiBaseUrl}/users/${userId}`, {
            method: 'DELETE',
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

export const forgotPassword = async (data) => {
    try {
        return fetch(`${fastApiBaseUrl}/auth/forgot-password`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(data),
        });
    } catch (e) {
        console.log(e);
    }
};

export const resetPassword = async (data) => {
    try {
        return fetch(`${fastApiBaseUrl}/auth/reset-password`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(data),
        });
    } catch (e) {
        console.log(e);
    }
};

/*
 ************************************************************
 *
 *                       COMPANY
 *
 ************************************************************
 */
export const getCompanyById = async (companyId) => {
    countApiCall('getCompanyById');
    try {
        return fetch(`${fastApiBaseUrl}/company/${companyId}`, {
            method: 'GET',
            headers: {Accept: 'application/json'},
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const createCompany = async (data) => {
    try {
        return fetch(`${fastApiBaseUrl}/company`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

export const getAllCompanies = async () => {
    countApiCall('getAllCompanies');
    try {
        return fetch(`${fastApiBaseUrl}/company`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

export const getCompanyInfoCached = async (campaignId) => {
    if (getApiData().campaignCompanyInfo[campaignId]) {
        return new Promise((resolve, reject) => {
            countCacheCall('getCompanyInfoCached');
            resolve(getApiData().campaignCompanyInfo[campaignId]);
        });
    }
    try {
        return fetch(`${fastApiBaseUrl}/company/campaign/${campaignId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                countApiCall('getCompanyInfoCached');
                setApiCache('campaignCompanyInfo', data, 'GET', campaignId);
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const patchCompany = (companyId, data) => {
    try {
        return fetch(`${fastApiBaseUrl}/company/${companyId}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const deleteCompanyById = (companyId) => {
    try {
        return fetch(`${fastApiBaseUrl}/company/${companyId}`, {
            method: 'DELETE',
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

/*
 ************************************************************
 *
 *                       PODCAST
 *
 ************************************************************
 */
export const getAllPodcasts = async () => {
    countApiCall('getAllPodcasts');
    try {
        return fetch(`${fastApiBaseUrl}/podcast`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                setApiCache('podcasts', data, 'GET');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const getPodcastCached = async (podcastId) => {
    if (getApiData().podcasts[podcastId]) {
        return new Promise((resolve, reject) => {
            countCacheCall('getPodcastCached');
            resolve(getApiData().podcasts[podcastId]);
        });
    }
    try {
        return fetch(`${fastApiBaseUrl}/podcast/${podcastId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                countApiCall('getPodcastCached');
                setApiCache('podcasts', data, 'GET');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const createPodcast = async (companyId, data) => {
    try {
        return fetch(`${fastApiBaseUrl}/company/${companyId}/podcast`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                setApiCache('podcasts', data, 'POST');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const patchPodcast = async (podcastId, data) => {
    try {
        return fetch(`${fastApiBaseUrl}/podcast/${podcastId}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                setApiCache('podcasts', data, 'PATCH');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const getAllPodcastsOfCompanyCached = async (companyId) => {
    if (getApiData().podcastsOfCompany[companyId]) {
        return new Promise((resolve, reject) => {
            countCacheCall('getAllPodcastsOfCompanyCached');
            resolve(getApiData().podcastsOfCompany[companyId]);
        });
    }
    try {
        return fetch(`${fastApiBaseUrl}/company/${companyId}/podcast`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                countApiCall('getAllPodcastsOfCompanyCached');
                setApiCache('podcasts', data, 'GET');
                setApiCache('podcastsOfCompany', data, 'GET');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const deletePodcastById = (podcastId) => {
    try {
        return fetch(`${fastApiBaseUrl}/podcast/${podcastId}`, {
            method: 'DELETE',
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

/*
 ************************************************************
 *
 *                       KEYWORD
 *
 ************************************************************
 */
export const getAllKeywords = async () => {
    try {
        return fetch(`${fastApiBaseUrl}/keyword`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

export const getKeywordsPerPodcast = async (podcastId) => {
    try {
        return fetch(`${fastApiBaseUrl}/podcast-keyword-podcast/${podcastId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const createKeyword = async (data) => {
    try {
        return fetch(`${fastApiBaseUrl}/keyword`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

export const createPodcastKeywordRelation = async (data) => {
    try {
        return fetch(`${fastApiBaseUrl}/podcast-keyword`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};
/*
 ************************************************************
 *
 *                       CAMPAIGN
 *
 ************************************************************
 */
export const getCampaignsByCompanyCached = (companyId) => {
    if (getApiData().campaignsByCompany[companyId]) {
        return new Promise((resolve, reject) => {
            countCacheCall('getCampaignsByCompanyCached');
            resolve(getApiData().campaignsByCompany[companyId]);
        });
    }

    try {
        return fetch(`${fastApiBaseUrl}/company/${companyId}/campaign`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                countApiCall('getCampaignsByCompanyCached');
                data.forEach((campaign) => {
                    setApiCache('campaigns', campaign, 'GET');
                });
                setApiCache('campaignsByCompany', data, 'GET');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const getCampaignByIdCached = async (campaignId) => {
    if (getApiData().campaigns[campaignId]) {
        return new Promise((resolve, reject) => {
            countCacheCall('getCampaignByIdCached');
            resolve(getApiData().campaigns[campaignId]);
        });
    }
    try {
        return fetch(`${fastApiBaseUrl}/campaign/${campaignId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                countApiCall('getCampaignByIdCached');
                setApiCache('campaigns', data, 'GET');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const addCampaign = async (companyId, data) => {
    try {
        return fetch(`${fastApiBaseUrl}/company/${companyId}/campaign`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                setApiCache('campaigns', data, 'POST');
                setApiCache('campaignsByCompany', data, 'POST');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const patchCampaign = async (campaignId, data) => {
    try {
        return fetch(`${fastApiBaseUrl}/campaign/${campaignId}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                setApiCache('campaigns', data, 'PATCH');
                setApiCache('campaignsByCompany', data, 'PATCH');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const deleteCampaignById = async (campaignId) => {
    try {
        fetch(`${fastApiBaseUrl}/campaign/${campaignId}`, {
            method: 'DELETE',
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                data = {campaign_id: campaignId};
                setApiCache('campaigns', data, 'DELETE');
                setApiCache('campaignsByCompany', data, 'DELETE');
                setApiCache('bookingRequests', data, 'DELETE', 'campaigns');
                setApiCache('campaignBookingRequests', data, 'DELETE', 'campaigns');
                setApiCache('podcastBookingRequests', data, 'DELETE', 'campaigns');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

/*
 ************************************************************
 *
 *                       BOOKING REQUESTS
 *
 ************************************************************
 */
export const postBookingRequest = async (campaignId, podcastId, data) => {
    try {
        return fetch(`${fastApiBaseUrl}/campaign/${campaignId}/booking-request/${podcastId}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                setApiCache('bookingRequests', data, 'POST');
                setApiCache('campaignBookingRequests', data, 'POST');
                setApiCache('podcastBookingRequests', data, 'POST');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const getBookingRequestsByPodcastIdCached = async (podcastId) => {
    if (getApiData().podcastBookingRequests[podcastId]) {
        return new Promise((resolve, reject) => {
            countCacheCall('getBookingRequestsByPodcastIdCached');
            resolve(getApiData().podcastBookingRequests[podcastId]);
        });
    }
    try {
        return fetch(`${fastApiBaseUrl}/podcast/${podcastId}/booking-request`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                countApiCall('getBookingRequestsByPodcastIdCached');
                setApiCache('podcastBookingRequests', data, 'GET');
                setApiCache('bookingRequests', data, 'GET');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const getBookingRequestsByCampaignIdCached = async (campaignId) => {
    if (getApiData().campaignBookingRequests[campaignId]) {
        return new Promise((resolve, reject) => {
            countCacheCall('getBookingRequestsByCampaignIdCached');
            resolve(getApiData().campaignBookingRequests[campaignId]);
        });
    }

    try {
        return fetch(`${fastApiBaseUrl}/campaign/${campaignId}/booking-request`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                countApiCall('getBookingRequestsByCampaignIdCached');
                setApiCache('campaignBookingRequests', data, 'GET');
                setApiCache('bookingRequests', data, 'GET');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const getBookingRequestByIdCached = async (bookingRequestId) => {
    if (getApiData().bookingRequests[bookingRequestId]) {
        return new Promise((resolve, reject) => {
            countCacheCall('getBookingRequestByIdCached');
            resolve(getApiData().bookingRequests[bookingRequestId]);
        });
    }
    try {
        return fetch(`${fastApiBaseUrl}/booking-request/${bookingRequestId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                countApiCall('getBookingRequestByIdCached');
                setApiCache('bookingRequests', data, 'GET');
                setApiCache('campaignBookingRequests', data, 'GET');
                setApiCache('podcastBookingRequests', data, 'GET');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const patchBookingRequest = async (bookingRequestId, data) => {
    try {
        return fetch(`${fastApiBaseUrl}/booking-request/${bookingRequestId}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                setApiCache('bookingRequests', data, 'PATCH');
                setApiCache('campaignBookingRequests', data, 'PATCH');
                setApiCache('podcastBookingRequests', data, 'PATCH');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const deleteBookingRequestById = async (bookingRequestId) => {
    try {
        fetch(`${fastApiBaseUrl}/booking-request/${bookingRequestId}`, {
            method: 'DELETE',
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                data = {booking_request_id: bookingRequestId};
                setApiCache('bookingRequests', data, 'DELETE', 'bookingRequests');
                setApiCache('campaignBookingRequests', data, 'DELETE', 'bookingRequests');
                setApiCache('podcastBookingRequests', data, 'DELETE', 'bookingRequests');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const deleteKey = async (bucket, key) => {
    try {
        fetch(`${fastApiBaseUrl}/delete-key/${bucket}/${key}`, {
            method: 'DELETE',
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

/*
 ************************************************************
 *
 *                       DEAL
 *
 ************************************************************
 */
export const makeDeal = async (bookingRequestId) => {
    try {
        return fetch(`${fastApiBaseUrl}/${bookingRequestId}/deal-id`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

/*
 ************************************************************
 *
 *                       FILE UPLOAD
 *
 ************************************************************
 */
export const uploadFile = async (formData, type) => {
    try {
        return fetch(`${fastApiBaseUrl}/uploadfile/${type}`, {
            method: 'POST',
            headers: {
                // 'Content-Type': 'multipart/form-data',
                // 'Content-Type': file.type,
                // 'Content-Disposition': `attachment; filename="${file.name}"`,
            },
            body: formData,
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

/*
 ************************************************************
 *
 *                       COMMIT MESSAGE
 *
 ************************************************************
 */
export const postCommitMessage = async (bookingRequestId, data) => {
    try {
        return fetch(`${fastApiBaseUrl}/${bookingRequestId}/commit-message`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(data),
        });
    } catch (e) {
        console.log(e);
    }
};

export const patchCommitMessage = async (commitMessageId) => {
    try {
        return fetch(`${fastApiBaseUrl}/commit-message/${commitMessageId}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

export const allCommitMessagesOfBookingRequest = async (bookingRequestId) => {
    countApiCall('allCommitMessagesOfBookingRequest');
    try {
        return fetch(`${fastApiBaseUrl}/${bookingRequestId}/all-commit-messages`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

export const lastCommitMessageOfBookingRequest = async (bookingRequestId) => {
    countApiCall('lastCommitMessageOfBookingRequest');
    try {
        return fetch(`${fastApiBaseUrl}/${bookingRequestId}/last-commit-message`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

export const deleteCommitMessagesOfBookingRequest = async (bookingRequestId) => {
    try {
        return fetch(`${fastApiBaseUrl}/${bookingRequestId}/commit-messages`, {
            method: 'DELETE',
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

/*
 ************************************************************
 *
 *                       SELFPROMO
 *
 ************************************************************
 */
// TODO implement in cache when feature is deployed
export const getSelfpromosOfPodcast = async (podcastId) => {
    countApiCall('getSelfpromosOfPodcast');
    try {
        return fetch(`${fastApiBaseUrl}/${podcastId}/selfpromos`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

export const saveSelfpromo = async (podcastId, data) => {
    try {
        return fetch(`${fastApiBaseUrl}/${podcastId}/selfpromo`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(data),
        });
    } catch (e) {
        console.log(e);
    }
};

export const updateSelfpromo = async (podcastId, selfpromoId, data) => {
    try {
        return fetch(`${fastApiBaseUrl}/${podcastId}/selfpromos/${selfpromoId}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

export const deleteSelfpromo = async (podcastId, selfpromoId) => {
    try {
        return fetch(`${fastApiBaseUrl}/${podcastId}/selfpromos/${selfpromoId}`, {
            method: 'DELETE',
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

/*
 ************************************************************
 *
 *                       INVENTORY RESERVATION
 *
 ************************************************************
 */
export const saveInventoryReservation = async (campaignId, bookingRequestId, podcastId, data) => {
    try {
        return fetch(`${fastApiBaseUrl}/inventory/reservation/${campaignId}/${bookingRequestId}/${podcastId}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                countApiCall('saveInventoryReservation');
                setApiCache('inventoryReservations', data, 'POST');
                setApiCache('campaignInventoryReservations', data, 'POST');
                setApiCache('podcastInventoryReservations', data, 'POST');
                setApiCache('bookingRequestInventoryReservations', data, 'POST');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const getInventoryReservationsOfCampaignCached = async (campaignId) => {
    if (getApiData().campaignInventoryReservations[campaignId]) {
        return new Promise((resolve, reject) => {
            countCacheCall('getInventoryReservationsOfCampaign');
            resolve(getApiData().campaignInventoryReservations[campaignId]);
        });
    }

    try {
        return fetch(`${fastApiBaseUrl}/inventory/reservation/campaign/${campaignId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                countApiCall('getInventoryReservationsOfCampaignCached');
                setApiCache('inventoryReservations', data, 'GET');
                setApiCache('campaignInventoryReservations', data, 'GET');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const getInventoryReservationsOfPodcastCached = async (podcastId) => {
    if (getApiData().podcastInventoryReservations[podcastId]) {
        return new Promise((resolve, reject) => {
            countCacheCall('getInventoryReservationsOfPodcastCached');
            resolve(getApiData().podcastInventoryReservations[podcastId]);
        });
    }
    try {
        return fetch(`${fastApiBaseUrl}/inventory/reservation/podcast/${podcastId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                countApiCall('getInventoryReservationsOfPodcastCached');
                setApiCache('inventoryReservations', data, 'GET');
                setApiCache('podcastInventoryReservations', data, 'GET');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const getInventoryReservationsOfBookingRequestCached = async (bookingRequestId) => {
    if (getApiData().bookingRequestInventoryReservations[bookingRequestId]) {
        return new Promise((resolve, reject) => {
            countCacheCall('getInventoryReservationsOfBookingRequest');
            resolve(getApiData().bookingRequestInventoryReservations[bookingRequestId]);
        });
    }
    try {
        return fetch(`${fastApiBaseUrl}/inventory/reservation/booking-request/${bookingRequestId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                countApiCall('getInventoryReservationsOfBookingRequestCached');
                setApiCache('inventoryReservations', data, 'GET');
                setApiCache('bookingRequestInventoryReservations', data, 'GET');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

// for when a publisher accepts/rejects a booking request (remove reservations & create bookings)
export const deleteInventoryReservationByRollPosition = async (bookingRequestId, rollPosition) => {
    try {
        return fetch(`${fastApiBaseUrl}/inventory/reservation/booking-request/${bookingRequestId}/${rollPosition}`, {
            method: 'DELETE',
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                data = {booking_request_id: bookingRequestId};
                countApiCall('deleteInventoryReservationByRollPosition');
                setApiCache('inventoryReservations', data, 'DELETE');
                setApiCache('campaignInventoryReservations', data, 'DELETE');
                setApiCache('podcastInventoryReservations', data, 'DELETE');
                setApiCache('bookingRequestInventoryReservations', data, 'DELETE');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

// for when a booking requests gets cancelled by the promoter - does not have effect on publisher cash
export const deleteInventoryReservationByBookingRequest = async (bookingRequestId) => {
    try {
        return fetch(`${fastApiBaseUrl}/inventory/reservation/booking-request/${bookingRequestId}`, {
            method: 'DELETE',
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                data = {booking_request_id: bookingRequestId};
                countApiCall('deleteInventoryReservationByBookingRequest');
                setApiCache('inventoryReservations', data, 'DELETE', 'bookingRequests');
                setApiCache('campaignInventoryReservations', data, 'DELETE', 'bookingRequests');
                setApiCache('podcastInventoryReservations', data, 'DELETE', 'bookingRequests');
                setApiCache('bookingRequestInventoryReservations', data, 'DELETE', 'bookingRequests');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

// for when a campaign gets deleted by the promoter - does not have effect on publisher cash
export const deleteInventoryReservationByCampaign = async (campaignId) => {
    try {
        return fetch(`${fastApiBaseUrl}/inventory/reservation/campaign/${campaignId}`, {
            method: 'DELETE',
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                data = {campaign_id: campaignId};
                countApiCall('deleteInventoryReservationByCampaign');
                setApiCache('inventoryReservations', data, 'DELETE', 'campaigns');
                setApiCache('campaignInventoryReservations', data, 'DELETE', 'campaigns');
                setApiCache('podcastInventoryReservations', data, 'DELETE', 'campaigns');
                setApiCache('bookingRequestInventoryReservations', data, 'DELETE', 'campaigns');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

/*
 ************************************************************
 *
 *                       INVENTORY BOOKING
 *
 ************************************************************
 */
export const saveInventoryBooking = async (campaignId, bookingRequestId, podcastId, data) => {
    try {
        return fetch(`${fastApiBaseUrl}/inventory/booking/${campaignId}/${bookingRequestId}/${podcastId}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                countApiCall('saveInventoryBooking');
                setApiCache('inventoryBookings', data, 'POST');
                setApiCache('campaignInventoryBookings', data, 'POST');
                setApiCache('podcastInventoryBookings', data, 'POST');
                setApiCache('bookingRequestInventoryBookings', data, 'POST');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const getInventoryBookingsOfCampaignCached = async (campaignId) => {
    if (getApiData().campaignInventoryBookings[campaignId]) {
        return new Promise((resolve, reject) => {
            countCacheCall('getInventoryBookingsOfCampaignCached');
            resolve(getApiData().campaignInventoryBookings[campaignId]);
        });
    }
    try {
        return fetch(`${fastApiBaseUrl}/inventory/booking/campaign/${campaignId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                countApiCall('getInventoryBookingsOfCampaignCached');
                setApiCache('inventoryBookings', data, 'GET');
                setApiCache('campaignInventoryBookings', data, 'GET');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const getInventoryBookingsOfPodcastCached = async (podcastId) => {
    if (getApiData().podcastInventoryBookings[podcastId]) {
        return new Promise((resolve, reject) => {
            countCacheCall('getInventoryBookingsOfPodcastCached');
            resolve(getApiData().podcastInventoryBookings[podcastId]);
        });
    }
    try {
        return fetch(`${fastApiBaseUrl}/inventory/booking/podcast/${podcastId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                countApiCall('getInventoryBookingsOfPodcastCached');
                setApiCache('inventoryBookings', data, 'GET');
                setApiCache('podcastInventoryBookings', data, 'GET');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const getInventoryBookingsOfBookingRequestCached = async (bookingRequestId) => {
    if (getApiData().bookingRequestInventoryBookings[bookingRequestId]) {
        return new Promise((resolve, reject) => {
            countCacheCall('getInventoryBookingsOfBookingRequestCached');
            resolve(getApiData().bookingRequestInventoryBookings[bookingRequestId]);
        });
    }
    try {
        return fetch(`${fastApiBaseUrl}/inventory/reservation/booking-request/${bookingRequestId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                countApiCall('getInventoryBookingsOfBookingRequestCached');
                setApiCache('inventoryReservations', data, 'GET');
                setApiCache('bookingRequestInventoryBookings', data, 'GET');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

// for when a campaign gets deleted by the promoter - does not have effect on publisher cash
export const deleteInventoryBookingByCampaign = async (campaignId) => {
    try {
        return fetch(`${fastApiBaseUrl}/inventory/booking/campaign/${campaignId}`, {
            method: 'DELETE',
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                data = {campaign_id: campaignId};
                countApiCall('deleteInventoryBookingByCampaign');
                setApiCache('inventoryBookings', data, 'DELETE', 'campaigns');
                setApiCache('campaignInventoryBookings', data, 'DELETE', 'campaigns');
                setApiCache('podcastInventoryBookings', data, 'DELETE', 'campaigns');
                setApiCache('bookingRequestInventoryBookings', data, 'DELETE', 'campaigns');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

// for when bookingRequest gets cancelled
export const deleteInventoryBookingByBookingRequest = async (bookingRequestId) => {
    console.log('bookingRequestId: ', bookingRequestId);
    try {
        return fetch(`${fastApiBaseUrl}/inventory/booking/booking-request/${bookingRequestId}`, {
            method: 'DELETE',
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                data = {booking_request_id: bookingRequestId};
                countApiCall('deleteInventoryBookingByBookingRequest');
                setApiCache('inventoryBookings', data, 'DELETE', 'bookingRequests');
                setApiCache('campaignInventoryBookings', data, 'DELETE', 'bookingRequests');
                setApiCache('podcastInventoryBookings', data, 'DELETE', 'bookingRequests');
                setApiCache('bookingRequestInventoryBookings', data, 'DELETE', 'bookingRequests');
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const getInventoryBatch = async (inventories) => {
    countApiCall('getInventoryBatch');
    try {
        return fetch(`${fastApiBaseUrl}/inventory/batch?inventories=${inventories}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

/*
 ************************************************************
 *
 *                  PERSISTED TRACKS
 *
 ************************************************************
 */
export const getImpressionsByDealId = (dealId) => {
    countApiCall('getImpressionsByDealId');
    try {
        return fetch(`${fastApiBaseUrl}/persist/impression-calls/${dealId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                if (data !== null) {
                    return data;
                } else {
                    let response = {
                        value: 0,
                    };
                    return response;
                }
            });
    } catch (e) {
        console.log(e);
    }
};

export const deleteImpressionsByDealId = async (dealId) => {
    try {
        fetch(`${fastApiBaseUrl}/persist/impression-calls/${dealId}`, {
            method: 'DELETE',
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

/*
 ************************************************************
 *
 *               PERSISTED DOWNSAMPLED TRACKS
 *
 ************************************************************
 */
export const deleteDownsampledImpressionsByDealId = async (dealId) => {
    try {
        fetch(`${fastApiBaseUrl}/persist/downsampled/impression-calls/${dealId}`, {
            method: 'DELETE',
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

/*
 ************************************************************
 *
 *               NETWORK FAVORITES
 *
 ************************************************************
 */
export const getCompanyPodcastFavorites = async (companyId) => {
    countApiCall('getCompanyPodcastFavorites');
    try {
        return fetch(`${fastApiBaseUrl}/network/favorite/${companyId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const createNetworkCompanyPodcastFavoriteRelation = async (data) => {
    countApiCall('createNetworkCompanyPodcastFavoriteRelation');
    try {
        return fetch(`${fastApiBaseUrl}/network/favorite`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const deleteNetworkCompanyPodcastFavoriteRelation = async (companyId, podcastId) => {
    countApiCall('deleteNetworkCompanyPodcastFavoriteRelation');
    try {
        fetch(`${fastApiBaseUrl}/network/favorite/${companyId}/${podcastId}`, {
            method: 'DELETE',
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

/*
 ************************************************************
 *
 *               NETWORK EXCLUSIVES
 *
 ************************************************************
 */
export const getCompanyPodcastExclusives = async (companyId) => {
    countApiCall('getCompanyPodcastExclusives');
    try {
        return fetch(`${fastApiBaseUrl}/network/exclusive/company/${companyId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const getPodcastCompanyExclusives = async (podcastId) => {
    countApiCall('getPodcastCompanyExclusives');
    try {
        return fetch(`${fastApiBaseUrl}/network/exclusive/podcast/${podcastId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const deleteNetworkCompanyPodcastExclusiveRelation = async (companyId, podcastId) => {
    countApiCall('deleteNetworkCompanyPodcastExclusiveRelation');
    try {
        fetch(`${fastApiBaseUrl}/network/exclusive/${companyId}/${podcastId}`, {
            method: 'DELETE',
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

export const createNetworkCompanyPodcastExclusiveRelation = async (data) => {
    countApiCall('createNetworkCompanyPodcastExclusiveRelation');
    try {
        return fetch(`${fastApiBaseUrl}/network/exclusive`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const patchNetworkCompanyPodcastExclusiveRelation = async (companyId, podcastId, data) => {
    try {
        return fetch(`${fastApiBaseUrl}/network/exclusive/${companyId}/${podcastId}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

export const getAllExclusives = async () => {
    countApiCall('getAllExclusives');
    try {
        return fetch(`${fastApiBaseUrl}/network/exclusives`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

/*
 ************************************************************
 *
 *                       SCRIPT BACKLOG
 *
 ************************************************************
 */
export const postScriptBacklog = async (data) => {
    countApiCall('postScriptBacklog');
    try {
        return fetch(`${fastApiBaseUrl}/script`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const getScriptBacklogByBookingRequest = async (campaignId, bookingRequestId) => {
    countApiCall('getScriptBacklogByBookingRequest');
    try {
        return fetch(`${fastApiBaseUrl}/scripts/${campaignId}/${bookingRequestId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const deleteScriptBacklogByCampaign = async (campaignId) => {
    countApiCall('deleteScriptBacklog');
    try {
        return fetch(`${fastApiBaseUrl}/scripts/campaign/${campaignId}`, {
            method: 'DELETE',
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

export const deleteScriptBacklogByBookingRequest = async (bookingRequestId) => {
    countApiCall('deleteScriptBacklog');
    try {
        return fetch(`${fastApiBaseUrl}/scripts/booking-request/${bookingRequestId}`, {
            method: 'DELETE',
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

/*
 ************************************************************
 *
 *                       AUDIO BACKLOG
 *
 ************************************************************
 */
export const postAudioBacklog = async (data) => {
    countApiCall('postAudioBacklog');
    try {
        return fetch(`${fastApiBaseUrl}/audio`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const getAudioBacklogByBookingRequest = async (campaignId, bookingRequestId) => {
    countApiCall('getAudioBacklogByBookingRequest');
    try {
        return fetch(`${fastApiBaseUrl}/audios/${campaignId}/${bookingRequestId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const deleteAudioBacklogByCampaign = async (campaignId) => {
    countApiCall('deleteAudioBacklog');
    try {
        return fetch(`${fastApiBaseUrl}/audios/campaign/${campaignId}`, {
            method: 'DELETE',
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

export const deleteAudioBacklogByBookingRequest = async (bookingRequestId) => {
    countApiCall('deleteAudioBacklog');
    try {
        return fetch(`${fastApiBaseUrl}/audios/booking-request/${bookingRequestId}`, {
            method: 'DELETE',
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

/*
 ************************************************************
 *
 *                       REPORT BACKLOG
 *
 ************************************************************
 */
export const postReportBacklog = async (data) => {
    countApiCall('postReportBacklog');
    try {
        return fetch(`${fastApiBaseUrl}/report`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const getReportBacklogByBookingRequest = async (campaignId, bookingRequestId) => {
    countApiCall('getReportBacklogByBookingRequest');
    try {
        return fetch(`${fastApiBaseUrl}/reports/${campaignId}/${bookingRequestId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                return data;
            });
    } catch (e) {
        console.log(e);
    }
};

export const deleteReportBacklogByCampaign = async (campaignId) => {
    countApiCall('deleteReportBacklog');
    try {
        return fetch(`${fastApiBaseUrl}/reports/campaign/${campaignId}`, {
            method: 'DELETE',
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};

export const deleteReportBacklogByBookingRequest = async (bookingRequestId) => {
    countApiCall('deleteReportBacklog');
    try {
        return fetch(`${fastApiBaseUrl}/reports/booking-request/${bookingRequestId}`, {
            method: 'DELETE',
            credentials: 'include',
        });
    } catch (e) {
        console.log(e);
    }
};
