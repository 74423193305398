<script>
    export let color = 'light';
    import {navigate} from 'svelte-routing';
    import {whoami, getAllUsersOfCompany, getAllUsers, getCompanyById} from '../../api/api.js';
    import {Pulse} from 'svelte-loading-spinners';
    import {storedUser} from '../../store.js';
    import {getText} from '../../language.svelte';
    import RowAdminUser from '../TableRows/RowAdminUser.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let companyId;
    export let showNewButton = false;

    function newUser() {
        navigate('/admin/newuser', {replace: false});
    }

    function getUser() {
        try {
            return whoami();
        } catch (e) {
            console.log(`Error ${e} occoured whilst loading whoami`);
        }
    }

    async function getAllCompanyUsers() {
        if (companyId >= 0) {
            try {
                return await getAllUsersOfCompany(companyId)
                    .then((response) => response.json())
                    .then((data) => {
                        return data;
                    });
            } catch (e) {
                console.log(`Error ${e} occoured whilst loading users of company ${companyId}`);
            }
        } else if ($storedUser.is_superuser) {
            let company;
            try {
                company = await getCompanyById($storedUser.company_id);
            } catch (e) {
                console.log(`Error ${e} occoured whilst loading company info of ${$storedUser.company_id}`);
            }

            if (company.type == 'admin') {
                try {
                    return getAllUsers();
                } catch (e) {
                    console.log(`Error ${e} occoured whilst loading all users !`);
                }
            } else {
                return [$storedUser];
            }
        } else {
            return [$storedUser];
        }
    }

    let userPromise = getUser();
    let allUsersPromise = getAllCompanyUsers();
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">
    <div style="display: flex; justify-content: center" />
    <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
            <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}">
                    {getText('admin.users.current_user.title')}
                </h3>
            </div>
            {#if typeof companyId == 'undefined' && showNewButton}
                <CardButton id="new-user" type="go" text={getText('admin.users.current_user.button_new')} on:click={() => newUser()} />
            {/if}
        </div>
    </div>

    <div class="block w-full overflow-x-auto">
        <!-- Projects table -->
        <table class="items-center w-full bg-transparent border-collapse">
            <thead>
                <tr>
                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-red-700 text-red-200 border-red-600'}"
                    >
                        {getText('admin.users.current_user.headline_1')}
                    </th>
                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-red-700 text-red-200 border-red-600'}"
                    >
                        {getText('admin.users.current_user.headline_2')}
                    </th>

                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-red-700 text-red-200 border-red-600'}"
                    >
                        {getText('admin.users.current_user.headline_3')}
                    </th>
                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-red-700 text-red-200 border-red-600'}"
                    >
                        {getText('admin.users.current_user.headline_4')}
                    </th>
                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-red-700 text-red-200 border-red-600'}"
                    >
                        {getText('admin.users.current_user.headline_5')}
                    </th>
                </tr>
            </thead>

            {#await userPromise}
                <div style="display: flex; justify-content: center">
                    <Pulse size="60" color="#FF3E00" unit="px" duration="1s" />
                </div>
            {:then data}
                <tbody />
                {#if Object.keys(data).length > 0}
                    <tr>
                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {data.first_name} </td>
                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {data.email} </td>
                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {data.company_id} </td>
                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {data.id} </td>
                        {#if data.is_superuser}
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> superuser </td>
                        {:else}
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> editor </td>
                        {/if}
                    </tr>
                {/if}
            {/await}
        </table>
    </div>
</div>

{#await allUsersPromise}
    <div style="display: flex; justify-content: center">
        <Pulse size="60" color="#FF3E00" unit="px" duration="1s" />
    </div>
{:then data}
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">
        <div style="display: flex; justify-content: center" />
        <div class="rounded-t mb-0 px-4 py-3 border-0">
            <div class="flex flex-wrap items-center">
                <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}">
                        {getText('admin.users.all_users.title')}
                    </h3>
                </div>
                {#if typeof companyId == 'undefined' && showNewButton}
                    <CardButton id="new-user" type="go" text={getText('admin.users.all_users.button_new')} on:click={() => newUser()} />
                {/if}
            </div>
        </div>

        <div class="block w-full overflow-x-auto">
            <!-- Projects table -->
            <table class="items-center w-full bg-transparent border-collapse">
                <thead>
                    <tr>
                        <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-red-700 text-red-200 border-red-600'}"
                        />
                        <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-red-700 text-red-200 border-red-600'}"
                        >
                            {getText('admin.users.all_users.headline_1')}
                        </th>
                        <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-red-700 text-red-200 border-red-600'}"
                        >
                            {getText('admin.users.all_users.headline_2')}
                        </th>

                        <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-red-700 text-red-200 border-red-600'}"
                        >
                            {getText('admin.users.all_users.headline_3')}
                        </th>
                        <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-red-700 text-red-200 border-red-600'}"
                        >
                            {getText('admin.users.all_users.headline_4')}
                        </th>
                        <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-red-700 text-red-200 border-red-600'}"
                        >
                            {getText('admin.users.all_users.headline_5')}
                        </th>
                        <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-red-700 text-red-200 border-red-600'}"
                        />
                    </tr>
                </thead>

                <tbody />
                {#if Object.keys(data).length > 0}
                    {#each data as item}
                        <RowAdminUser {item} />
                    {/each}
                {/if}
            </table>
        </div>
    </div>
{/await}
