<script>
    import {navigate} from 'svelte-routing';

    import {getAllKeywords, createKeyword} from '../../api/api';
    import {onMount} from 'svelte';
    import {getText} from '../../language.svelte';
    import CardTableKeywords from 'components/Cards/CardTableKeywords.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let cardTitle = getText('admin.keywords.new_keyword.title');
    export let type;
    let keywords;
    let allKeywords;

    onMount(async () => {
        try {
            allKeywords = await getAllKeywords()
                .then((response) => response.json())
                .then((data) => {
                    let sorted = data.sort((a, b) => {
                        let fa = a.keyword.toLowerCase(),
                            fb = b.keyword.toLowerCase();

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    });

                    return sorted;
                });
        } catch (e) {
            console.log(`Error ${e} occoured whilst loading all keywords !`);
        }
    });

    function checkRequired() {
        if (!keywords) {
            window.alert(getText('ui_messages.alerts.general.alert_required_information'));
            return false;
        }
        return true;
    }

    async function save() {
        if (checkRequired()) {
            // check if keyword already exists in DB
            keywords = keywords.replace(/\s+/g, ' ');
            keywords = keywords.replace(/[^ ,a-zA-Z0-9]/g, '');
            keywords = keywords.replace(/, /g, ',');
            keywords = keywords
                .split(',')
                .filter((bb) => !!bb)
                .join(',');
            let keywordList = keywords.replace(/, /g, ',');
            keywordList = keywordList.split(',');
            for (let key of keywordList) {
                let lookUp = allKeywords.find((keyword) => {
                    if (keyword.keyword.toLowerCase() == key.toLowerCase()) {
                        return keyword;
                    }
                });
                if (!lookUp) {
                    let data = {
                        keyword: key,
                    };

                    try {
                        await createKeyword(data);
                    } catch (e) {
                        console.log(`Error ${e} occoured whilst saving the keyword ${key}`);
                    }

                    if (!type) {
                        navigate('/admin', {replace: true});
                    } else {
                        navigate('/', {replace: true});
                    }
                }
            }
        }
    }

    function cancel() {
        if (!type) {
            navigate('/admin', {replace: true});
        } else {
            navigate('/', {replace: true});
        }
    }
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
            <h6 class="text-blueGray-700 text-xl font-bold">{cardTitle}</h6>
            <div class="flex flex-wrap items-center">
                <CardButton classes="saveproceed" id="saveproceed" type="go" text={getText('admin.keywords.new_keyword.button_save')} on:click={() => save()} />
                <CardButton classes="saveproceed" id="saveproceed" type="cancel" text={getText('admin.keywords.new_keyword.button_cancel')} on:click={() => cancel()} />
            </div>
        </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form>
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {getText('admin.keywords.new_keyword.category_1.title')}
            </h6>

            <div class="flex flex-wrap">
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-title">
                            {getText('admin.keywords.new_keyword.category_1.entry_1.title')}
                        </label>
                        <input
                            id="grid-title"
                            type="input"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder={getText('admin.keywords.new_keyword.category_1.entry_1.input_placeholder')}
                            bind:value={keywords}
                        />
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<CardTableKeywords showNewButton={false} />
