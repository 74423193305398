<script>
    import {Router, Route} from 'svelte-routing';
    import NewCompany from 'views/admin/NewCompany.svelte';
    import NewPodcast from 'views/admin/NewPodcast.svelte';
    import NewKeyword from 'views/admin/NewKeyword.svelte';
    import OverviewTable from 'views/admin/OverviewTable.svelte';
    import NewUser from 'views/admin/NewUser.svelte';
</script>

<div>
    <Router url="admin">
        <Route path="/" component={OverviewTable} />
        <Route path="/newcompany" component={NewCompany} />
        <Route path="/newpodcast" component={NewPodcast} />
        <Route path="/newkeyword" component={NewKeyword} />
        <Route path="/newuser" component={NewUser} />
    </Router>
</div>
