<script>
    import {getText} from '../../language.svelte';
    import {Accordion, AccordionItem} from 'svelte-collapsible';
    import {getAllPodcastsOfCompanyCached, getInventoryReservationsOfPodcastCached, getInventoryBookingsOfPodcastCached} from '../../api/api.js';
    import {storedCompany, render} from '../../store.js';
    import CardLineChartStacked from '../../components/Cards/CardLineChartStacked.svelte';
    import CardBarChartStacked from '../../components/Cards/CardBarChartStacked.svelte';

    // can be one of light or dark table layout
    // export let color = 'light';

    let promisePodcasts = getPodcasts();
    export let rollPosition = 6; // 6 = all positions
    export let location;

    $render = rollPosition;

    // ACCORDION
    let accordionKey = null;
    const duration = 0.5; // seconds
    const easing = 'linear';

    // PROMISE
    async function getPodcasts() {
        try {
            return await getAllPodcastsOfCompanyCached($storedCompany.id);
        } catch (e) {
            console.log(`Error ${e} occoured whilst loading podcasts of company ${$storedCompany.id}`);
        }
    }
</script>

<!-- INFO BOX -->
<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between" />
        <h6 class="text-blueGray-700 mb-4 text-xl font-bold">{getText('podcast.inventory.title_general')}</h6>
        <div class="block w-full overflow-x-auto">
            <Accordion bind:key={accordionKey} {duration} {easing}>
                <AccordionItem key="Info">
                    <div slot="header">
                        <div
                            class="header bg-teal-100 hover:cell-highlight-color text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                        >
                            <div class="ml-2">
                                <h2>Info</h2>
                            </div>
                        </div>
                    </div>

                    <p slot="body" class="body">
                        {#if accordionKey}
                            <div class="bg-blueGray-100 border-t-0 align-middle border-l-0 border-r-0 mb-4">
                                <div class="ml-2">
                                    {getText('podcast.inventory.description')}
                                </div>
                            </div>
                        {/if}
                    </p>
                </AccordionItem>
            </Accordion>
        </div>
    </div>
</div>

<!-- TABLE PER PODCAST -->
{#await promisePodcasts then data}
    {#each data as podcast}
        {#await getInventoryReservationsOfPodcastCached(podcast.id) then inventoryReservations}
            {#await getInventoryBookingsOfPodcastCached(podcast.id) then inventoryBookings}
                {#if rollPosition == 6}
                    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                        <div class="rounded-t bg-white mb-0 px-6 py-6">
                            <div class="text-center flex justify-between" />
                            <h6 class="text-blueGray-700 mb-4 text-xl font-bold">{getText('podcast.inventory.titles.title_1')} <i>{podcast.name}</i></h6>
                            <div class="block w-full overflow-x-auto">
                                <CardBarChartStacked {podcast} {inventoryReservations} {inventoryBookings} {rollPosition} title="Overview" subtitle={getText('podcast.inventory.titles.entry_1')} />
                            </div>
                        </div>
                    </div>
                {:else if rollPosition == 1}
                    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                        <div class="rounded-t bg-white mb-0 px-6 py-6">
                            <div class="text-center flex justify-between" />
                            <h6 class="text-blueGray-700 mb-4 text-xl font-bold">{getText('podcast.inventory.titles.title_2')} <i>{podcast.name}</i></h6>
                            <div class="block w-full overflow-x-auto">
                                <CardBarChartStacked {podcast} {inventoryReservations} {inventoryBookings} {rollPosition} title="Pre-Rolls" subtitle={getText('podcast.inventory.titles.entry_2')} />
                            </div>
                        </div>
                    </div>
                {:else if rollPosition == 2}
                    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                        <div class="rounded-t bg-white mb-0 px-6 py-6">
                            <div class="text-center flex justify-between" />
                            <h6 class="text-blueGray-700 mb-4 text-xl font-bold">{getText('podcast.inventory.titles.title_2')} <i>{podcast.name}</i></h6>
                            <div class="block w-full overflow-x-auto">
                                <CardBarChartStacked {podcast} {inventoryReservations} {inventoryBookings} {rollPosition} title="Mid-1-Rolls" subtitle={getText('podcast.inventory.titles.entry_3')} />
                            </div>
                        </div>
                    </div>
                {:else if rollPosition == 3}
                    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                        <div class="rounded-t bg-white mb-0 px-6 py-6">
                            <div class="text-center flex justify-between" />
                            <h6 class="text-blueGray-700 mb-4 text-xl font-bold">{getText('podcast.inventory.titles.title_2')} <i>{podcast.name}</i></h6>
                            <div class="block w-full overflow-x-auto">
                                <CardBarChartStacked {podcast} {inventoryReservations} {inventoryBookings} {rollPosition} title="Mid-2-Rolls" subtitle={getText('podcast.inventory.titles.entry_4')} />
                            </div>
                        </div>
                    </div>
                {:else if rollPosition == 4}
                    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                        <div class="rounded-t bg-white mb-0 px-6 py-6">
                            <div class="text-center flex justify-between" />
                            <h6 class="text-blueGray-700 mb-4 text-xl font-bold">{getText('podcast.inventory.titles.title_2')} <i>{podcast.name}</i></h6>
                            <div class="block w-full overflow-x-auto">
                                <CardBarChartStacked {podcast} {inventoryReservations} {inventoryBookings} {rollPosition} title="Mid-3-Rolls" subtitle={getText('podcast.inventory.titles.entry_5')} />
                            </div>
                        </div>
                    </div>
                {:else if rollPosition == 5}
                    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                        <div class="rounded-t bg-white mb-0 px-6 py-6">
                            <div class="text-center flex justify-between" />
                            <h6 class="text-blueGray-700 mb-4 text-xl font-bold">{getText('podcast.inventory.titles.title_2')} <i>{podcast.name}</i></h6>
                            <div class="block w-full overflow-x-auto">
                                <CardBarChartStacked {podcast} {inventoryReservations} {inventoryBookings} {rollPosition} title="Post-Rolls" subtitle={getText('podcast.inventory.titles.entry_6')} />
                            </div>
                        </div>
                    </div>
                {/if}
            {/await}
        {/await}
    {/each}
{/await}
