<script>
    import {databaseToPrettyDate} from '../../functions.js';
    import {getText} from '../../language.svelte';
    import AudioPlayer from '../AudioPlayer.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';
    export let campaign;
    export let copyToClipboard;
    export let downloadFromUrl;
    export let backgroundColor = 'bg-blueGray-200';
</script>

{#if (campaign.type == 'spot' || campaign.type == 'crosspromo') && campaign.audiofile_url}
    <form>
        <div class="{backgroundColor} flex-auto mt-1 pt-1 px-4 lg:px-10 py-10 pt-0">
            {#if (campaign.type == 'spot' || campaign.type == 'crosspromo') && campaign.audiofile_url}
                <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                    {getText('podcast.booking_request_details.spot_audio.title')}
                </h6>

                <div class="w-full lg:w-12/12 px-4 flex flex-wrap">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-requested-impressions">
                            {getText('podcast.booking_request_details.spot_audio.category_1.title')}
                        </label>

                        <AudioPlayer src={campaign.audiofile_url} />
                        <p class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                            {getText('podcast.booking_request_details.spot_audio.category_1.label_1')}
                            {campaign.audiofile_name_original} /
                            <a class="underline" target="_blank" href={campaign.audiofile_url}>{campaign.audiofile_url}</a>
                            <i
                                class="fas fa-copy active:color-emerald-600"
                                on:click={() => {
                                    copyToClipboard(campaign.audiofile_url);
                                }}
                            />
                        </p>

                        <div class="flex flex-wrap">
                            <div
                                class="lg:w-6/12 border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            >
                                {getText('podcast.booking_request_details.spot_audio.category_1.label_2', databaseToPrettyDate(campaign.audiofile_uploaded_date))}
                            </div>
                        </div>
                        <CardButton
                            id="download"
                            type="go"
                            icon="fas fa-download"
                            text={getText('podcast.booking_request_details.spot_audio.category_1.button_download')}
                            on:click={() => downloadFromUrl(campaign.audiofile_url, campaign.audiofile_name_original)}
                        />
                    </div>
                </div>
            {/if}
        </div>
    </form>
{/if}
