<script>
    import {prettyPrintNumber, getDateDiff, getDateNowDatetime} from '../../functions.js';
    import CardBookingProductionFlow from 'components/Cards/CardBookingProductionFlow.svelte';
    import CardScriptVersionControl from 'components/Cards/CardScriptVersionControl.svelte';
    import CardCommitMessageFlow from 'components/Cards/CardCommitMessageFlow.svelte';
    import {getText} from '../../language.svelte';
    import {getImpressionsByDealId} from '../../api/api.js';
    import {storedCompany} from '../../store.js';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let podcastName;
    export let bookingRequest;
    export let campaign;

    let showFlow = false;
    let showScriptControl = false;
    let showCommitMessages = false;

    let bR2;
    function details(bR) {
        bR2 = bR;
        showFlow = true;
    }

    let bR4;
    function detailsScript(bR) {
        bR4 = bR;
        showScriptControl = true;
    }

    let bR3;
    function detailsCommitMessages(bR) {
        bR3 = bR;
        showCommitMessages = true;
    }
</script>

{#if showFlow}
    <CardBookingProductionFlow bookingRequest={bR2} {campaign} />
{/if}

{#if showScriptControl}
    <CardScriptVersionControl bookingRequest={bR4} {campaign} bind:showScriptControl />
{/if}

{#if showCommitMessages}
    <CardCommitMessageFlow bookingRequest={bR3} {campaign} bind:showCommitMessages />
{/if}

{#await getImpressionsByDealId(bookingRequest.deal_id) then liveImpressions}
    <tr class="hover:cell-highlight-color-level-2">
        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {getText('promoter.campaign_overview.booking_requests_table_rows.on_air.next_step_1', getDateDiff(getDateNowDatetime(), campaign.end_date))}
        </td>
        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {podcastName}
        </td>
        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
        </td>

        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {prettyPrintNumber(liveImpressions.value)} </td>

        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            <CardButton id="info" type="icon" icon="fas fa-info-circle mr-2 text-sm" title="Production Flow" on:click={() => details(bookingRequest)} />

            {#if $storedCompany.type !== 'podcast'}
                <CardButton id="commit-messages" type="icon" icon="fas fa-comment-alt mr-2 text-sm" title="Commit Messages" on:click={() => detailsCommitMessages(bookingRequest)} />
            {/if}
            {#if campaign.type == 'hostread' && campaign.briefing_completed}
                <CardButton id="script-version-control" type="icon" icon="fas fa-file-alt mr-2 text-sm" title="Script Version Control" on:click={() => detailsScript(bookingRequest)} />
            {/if}
        </td>
    </tr>
{/await}
