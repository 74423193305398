<script>
    export let id = '';
    export let text;
    export let backgroundColor = 'bg-blueGray-100';
    export let activeColor = 'bg-blueGray-100';
    export let borderColor = 'border-blueGray-300';
    export let textColor = 'text-blueGray-600';
    export let disabled = false;
    export let classRounded = 'rounded-full';
    export let classText = 'text-xs';
    export let classFont = 'font-semibold';
    export let classPY = 'py-1';
    export let classPX = 'px-2';
    export let icon = '';
    export let iconOnClick = '';
</script>

<span
    class="{classText} {classFont} {classPY} {classPX} {classRounded} {borderColor} {textColor} {backgroundColor} active:{activeColor} inline-block uppercase last:mr-0 mr-1 hover:edit-pointer"
    id="grid-badge-clickable-tag-{id}"
    {disabled}
    on:click
>
    {#if text}
        {text}
    {/if}
    {#if icon}
        <i class={icon} on:click={iconOnClick} />
    {/if}
</span>
