<script>
    export let text;
    export let icon = true;
</script>

<div class="tooltip">
    {#if icon}
        <i class="fas fa-info-circle">
            <span class="tooltiptext">
                {text}
            </span>
        </i>
    {:else}
        <span class="tooltiptext">
            {text}
        </span>
    {/if}
</div>
