<script>
    import {prettyPrintNumber, getDateNow} from '../../functions.js';
    import {campaignBookingRequests, requestedTargetImpressionsCount} from '../../store.js';
    import {patchBookingRequest, deleteBookingRequestById} from '../../api/api.js';
    import {getText} from '../../language.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let podcastName;
    export let bookingRequest;

    async function sendSoloBookingRequest(bookingRequest) {
        bookingRequest.state = 2;
        bookingRequest.activated = true;
        bookingRequest.activated_date = getDateNow();

        try {
            await patchBookingRequest(bookingRequest.id, bookingRequest);

            // remove br from notActivated-store
            $campaignBookingRequests[bookingRequest.campaign_id].notActivatedBRs = $campaignBookingRequests[bookingRequest.campaign_id].notActivatedBRs.filter((bR) => bR.id !== bookingRequest.id);
            // add br to pending-store
            $campaignBookingRequests[bookingRequest.campaign_id].pendingBRs = [...$campaignBookingRequests[bookingRequest.campaign_id].pendingBRs, bookingRequest];
        } catch (e) {
            console.log(`Error ${e} occoured whilst sending the booking request ${bookingRequest}`);
        }
    }

    async function deleteBookingRequest(bookingRequest) {
        try {
            await deleteBookingRequestById(bookingRequest.id);

            // update total requested impressions
            $requestedTargetImpressionsCount = $requestedTargetImpressionsCount - bookingRequest.requested_impressions;

            // remove from notActivated
            $campaignBookingRequests[bookingRequest.campaign_id].notActivatedBRs = $campaignBookingRequests[bookingRequest.campaign_id].notActivatedBRs.filter((bR) => bR.id !== bookingRequest.id);
        } catch (e) {
            console.log(`Error ${e} occoured whilst deleting the booking request ${bookingRequest}`);
        }
    }
</script>

<tr class="hover:cell-highlight-color-level-2">
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        <!-- <i class="fas fa-circle text-red-600 mr-2" /> -->
        <CardButton
            id="send-booking-request"
            type="go"
            text={getText('promoter.campaign_overview.booking_requests_table_rows.not_activated.button_send')}
            on:click={() => sendSoloBookingRequest(bookingRequest)}
        />
    </td>
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        {podcastName}
    </td>
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        {prettyPrintNumber(bookingRequest.requested_impressions)} / 0
    </td>
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {getText('promoter.campaign_overview.booking_requests_table_rows.not_activated.status')} </td>
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        <CardButton id="delete-booking-request" type="default" icon="fas fa-trash mr-2 text-sm" on:click={() => deleteBookingRequest(bookingRequest)} />
    </td>
</tr>
