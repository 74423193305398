<script>
    // example:  https://github.com/TahaSh/svelte-confirm/blob/master/src/Confirm.svelte
    // example: https://svelte.dev/repl/56c729e10a1e4348809367eda9bdc0d3?version=3.12.1
    import {fly, fade} from 'svelte/transition';
    export let buttonConfirm;
    export let buttonCancel;

    let modalConfirmOpen = false;

    function close() {
        modalConfirmOpen = false;
    }

    let functionToCall = {
        func: null,
        args: null,
    };

    function callFunction() {
        modalConfirmOpen = false;
        functionToCall['func'](...functionToCall['args']);
    }

    function confirm(func, ...args) {
        functionToCall = {func, args};
        modalConfirmOpen = true;
    }
</script>

<slot {confirm} />

{#if modalConfirmOpen}
    <div class="overlay" in:fade={{duration: 200}} out:fade={{delay: 200, duration: 200}} />
    <div
        class="confirm-dialog"
        in:fly={{
            y: -10,
            delay: 200,
            duration: 200,
        }}
        out:fly={{
            y: -10,
            duration: 200,
        }}
    >
        <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
            <div class="relative w-auto my-6 mx-auto max-w-sm">
                <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                        <h3 class="text-3xl font-semibold"><slot name="title" /></h3>
                        <button class="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none" on:click={close}>
                            <span class="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none"> × </span>
                        </button>
                    </div>
                    <div class="relative p-6 flex-auto">
                        <p class="my-4 text-blueGray-500 text-lg leading-relaxed">
                            <slot name="description" />
                        </p>
                    </div>
                    <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                            class="bg-red-400 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            on:click={close}
                        >
                            <slot name="cancel">
                                {buttonCancel}
                            </slot>
                        </button>
                        <button
                            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            on:click={callFunction}
                        >
                            <slot name="confirm">
                                {buttonConfirm}
                            </slot>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="opacity-25 fixed inset-0 z-40 bg-black" />
{/if}
