// a central function for every possible state and meta of a BR and Campaign
import {storedCompany, storedUser} from '../src/store.js';

import {get} from 'svelte/store';
import {getDateNow} from '../src/functions.js';

/*
 * valid for both campaign & bookingRequest
 */
export function setAudio(obj, url, duration, nameOriginal) {
    url ? (obj.audiofile_url = url) : '';
    obj.audiofile_uploaded_date = getDateNow();
    duration ? (obj.audiofile_duration_ms = duration) : '';
    nameOriginal ? (obj.audiofile_name_original = nameOriginal) : '';

    return obj;
}

/*
 * campaigns
 * 2:	activated
 * 9:	on-air
 * 10:	completed
 * 14:   missed deadline - campaign cancelled
 * 15:   manually cancelled by owner
 */
export function setCampaignState(campaign, state) {
    if (state == 2) {
        campaign.state = 2;
    }

    if (state == 9) {
        campaign.state = 9;
    }

    if (state == 10) {
        campaign.state = 10;
    }

    if (state == 15) {
        campaign.state = 15;
    }

    return campaign;
}

/*
 * bookingRequests:
 * 1:	not activated
 * 2: 	activated
 * 3:	accepted
 * 4:	rejected
 * 5:	agreed
 * 6:	cancelled
 * 7:	audiofile
 * 8:	approved
 * 9:	on-air
 * 10:	completed
 * 11:	report
 * 12:   wrapped
 * 13:   missed deadline
 * 14:   missed deadline - campaign cancelled
 */
export function setBookingRequestState(bookingRequest, state) {
    if (state == 2) {
        bookingRequest.activated = true;
        bookingRequest.activated_date = getDateNow();
        bookingRequest.state = 2;
    }

    if (state == 3) {
        bookingRequest.accepted = true;
        bookingRequest.accepted_date = getDateNow();
        bookingRequest.state = 3;
    }

    if (state == 5) {
        bookingRequest.agreed = true;
        bookingRequest.agreed_date = getDateNow();
        bookingRequest.state = 5;
    }

    if (state == 6) {
        bookingRequest.cancelled_date = getDateNow();
        bookingRequest.state = 6;
    }

    if (state == 7) {
        bookingRequest.state = 7;
    }

    if (state == 8) {
        bookingRequest.state = 8;
        bookingRequest.approved = true;
        bookingRequest.approved_date = getDateNow();
    }

    if (state == 10) {
        bookingRequest.state = 10;
    }

    if (state == 11) {
        bookingRequest.state = 11;
        bookingRequest.report_uploaded_date = getDateNow();
    }

    if (state == 12) {
        bookingRequest.state = 12;
        bookingRequest.report_approved_date = getDateNow();
    }

    return bookingRequest;
}

/*
 * BOOKING REQUEST SETTER
 */
// SCRIPT
export function setBookingRequestScript(bookingRequest, script, updatedPromoter, updatedPodcast) {
    const company = get(storedCompany);
    const user = get(storedUser);
    script ? (bookingRequest.script = script) : '';
    bookingRequest.script_updated_promoter = updatedPromoter ? updatedPromoter : false;
    bookingRequest.script_updated_podcast = updatedPodcast ? updatedPodcast : false;
    script ? (bookingRequest.script_updated_date = getDateNow()) : '';
    script ? (bookingRequest.script_editor_company = company.name) : '';
    script ? (bookingRequest.script_editor_user = user.first_name) : '';

    return bookingRequest;
}

export function setBookingRequestApproveScript(bookingRequest) {
    const company = get(storedCompany);
    const user = get(storedUser);
    bookingRequest.script_editor_company = company.name;
    bookingRequest.script_editor_user = user.first_name;
    bookingRequest.script_approved_date = getDateNow();

    return bookingRequest;
}

// AUDIO
export function setBookingRequestAudioEditor(bookingRequest) {
    const company = get(storedCompany);
    const user = get(storedUser);
    bookingRequest.audio_editor_company = company.name;
    bookingRequest.audio_editor_user = user.first_name;

    return bookingRequest;
}

export function setBookingRequestApproveAudio(bookingRequest) {
    bookingRequest.audiofile_url_approved = true;
    bookingRequest.audiofile_approved_date = getDateNow();
    bookingRequest = setBookingRequestAudioEditor(bookingRequest);

    return bookingRequest;
}

export function rejectedBookingRequestAudio(bookingRequest) {
    bookingRequest.state = 5;
    bookingRequest.audiofile_url = '';
    bookingRequest.audiofile_uploaded_date = null;
    bookingRequest.audiofile_duration_ms = 0;
    bookingRequest.audiofile_url_approved = false;

    return bookingRequest;
}

// REPORT
export function setBookingRequestReport(bookingRequest, report, originalFileName) {
    bookingRequest.report = report;
    bookingRequest.report_name_original = originalFileName;
    bookingRequest = setBookingRequestReportEditor(bookingRequest);

    return bookingRequest;
}

export function setBookingRequestReportEditor(bookingRequest) {
    const company = get(storedCompany);
    const user = get(storedUser);
    bookingRequest.report_editor_company = company.name;
    bookingRequest.report_editor_user = user.first_name;

    return bookingRequest;
}

export function rejectedBookingRequestReport(bookingRequest) {
    bookingRequest.state = 10;
    bookingRequest.report = '';
    bookingRequest.report_uploaded_date = null;

    return bookingRequest;
}

// Backlogs
export function setScriptbacklogMeta(script, campaignId, bookingRequest, approved) {
    const company = get(storedCompany);
    const user = get(storedUser);
    return {
        created: getDateNow(),
        campaign_id: campaignId,
        booking_request_id: bookingRequest ? bookingRequest.id : 0,
        script: script,
        editor_company_id: company.id,
        editor_company_name: company.name,
        editor_user_id: user.id,
        editor_user_name: user.first_name,
        approved: approved ? approved : false,
        approved_date: approved ? getDateNow() : null,
    };
}

export function setAudiobacklogMeta(audio, campaignId, bookingRequest, approved) {
    const company = get(storedCompany);
    const user = get(storedUser);
    return {
        created: getDateNow(),
        campaign_id: campaignId,
        booking_request_id: bookingRequest ? bookingRequest.id : 0,
        audiofile_url: audio,
        editor_company_id: company.id,
        editor_company_name: company.name,
        editor_user_id: user.id,
        editor_user_name: user.first_name,
        approved: approved ? approved : false,
        approved_date: approved ? getDateNow() : null,
    };
}

export function setReportbacklogMeta(report, campaignId, bookingRequest, approved) {
    const company = get(storedCompany);
    const user = get(storedUser);
    return {
        created: getDateNow(),
        campaign_id: campaignId,
        booking_request_id: bookingRequest ? bookingRequest.id : 0,
        report_url: report,
        editor_company_id: company.id,
        editor_company_name: company.name,
        editor_user_id: user.id,
        editor_user_name: user.first_name,
        approved: approved ? approved : false,
        approved_date: approved ? getDateNow() : null,
    };
}

/*
 * RE-SETTER
 * for rejected booking requests,
 * requested changes,
 * or deleted campaigns
 */
export function resetBookingRequestState(bookingRequest, state) {
    if (state == 2) {
        bookingRequest.roll_position_pre = null;
        bookingRequest.roll_position_mid_1 = null;
        bookingRequest.roll_position_mid_2 = null;
        bookingRequest.roll_position_mid_3 = null;
        bookingRequest.roll_position_post = null;

        bookingRequest.offered_impressions = 0;
        bookingRequest.agreed = false;
        bookingRequest.agreed_date = null;
        bookingRequest.accepted = false;
        bookingRequest.accepted_date = null;
        bookingRequest.approved = false;
        bookingRequest.approved_date = null;

        bookingRequest.deal_id = null;
        bookingRequest.vast_url = null;
        bookingRequest.ad_notes = null;
        bookingRequest.ad_created = false;

        bookingRequest.state = 2;
    }

    if (state == 7) {
        bookingRequest.approved_date = null;
        bookingRequest.approved = false;
        bookingRequest.audiofile_url_approved = false;
        bookingRequest.audiofile_approved_date = null;
        bookingRequest.deal_id = null;
        bookingRequest.vast_url = null;
        bookingRequest.ad_created = false;
        bookingRequest.ad_notes = null;

        bookingRequest.state = 7;
    }

    return bookingRequest;
}
