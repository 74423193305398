<script>
    import NewUser from '../views/admin/NewUser.svelte';
    import CardTableUsers from 'components/Cards/CardTableUsers.svelte';
    import {storedUser, render} from '../store.js';

    $render = Math.random();

    export let type;
    let companyId;

    if (type) {
        companyId = $storedUser.company_id;
    } else companyId = -1;
</script>

<CardTableUsers {companyId} />
<NewUser {type} />
