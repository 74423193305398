<script>
    // library for creating dropdown menu appear on click
    import {createPopper} from '@popperjs/core';
    import {link} from 'svelte-routing';
    import {storedUser} from '../../store.js';
    import {getText} from '../../language.svelte';

    let image;
    $: image = $storedUser.image_url ? $storedUser.image_url : 'https://asap-dispo.s3.amazonaws.com/images/team-avatar-800x800.png';
    $: $storedUser.image_url, updateImage();

    function updateImage() {
        image = $storedUser.image_url;
    }

    let dropdownPopoverShow = false;

    let btnDropdownRef;
    let popoverDropdownRef;

    const toggleDropdown = (event) => {
        event.preventDefault();
        if (dropdownPopoverShow) {
            dropdownPopoverShow = false;
        } else {
            dropdownPopoverShow = true;
            createPopper(btnDropdownRef, popoverDropdownRef, {
                placement: 'bottom-start',
            });
        }
    };
</script>

<div>
    <a class="text-blueGray-500 block" href="#pablo" bind:this={btnDropdownRef} on:click={toggleDropdown}>
        <div class="items-center flex">
            <span class="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
                <img alt="..." class="w-12 h-12 w-full rounded-full align-middle border-none shadow-lg" src={image} />
            </span>
        </div>
    </a>
    <div bind:this={popoverDropdownRef} class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 {dropdownPopoverShow ? 'block' : 'hidden'}">
        <!-- <a href="#pablo" on:click={(e) => e.preventDefault()} class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"> Profile settings </a> -->
        <a use:link href="/user/profile" class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700" on:click={toggleDropdown}>
            {getText('user.dropdown.entry_1')}
        </a>
        <div class="h-0 my-2 border border-solid border-blueGray-100" />
        {#if $storedUser.id}
            <a use:link href="/auth/logout" class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700" on:click={toggleDropdown}>
                {getText('user.dropdown.entry_2')}
            </a>
        {:else}
            <a use:link href="/auth/login" class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700" on:click={toggleDropdown}>
                {getText('user.dropdown.entry_3')}
            </a>
        {/if}
    </div>
</div>
