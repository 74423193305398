<script>
    import {navigate} from 'svelte-routing';

    import {register, getAllUsers} from '../../api/api';
    import {allCompanies, storedCompany, storedUser} from '../../store.js';
    import {getText} from '../../language.svelte';
    import CardTableUsers from 'components/Cards/CardTableUsers.svelte';
    import SnackbarNoDismiss from 'components/Snackbars/SnackbarNoDismiss.svelte';
    import {validateEmail} from '../../functions.js';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let cardTitle = getText('admin.users.new_user.title');
    export let type;
    let firstName;
    let email;
    let password;
    let snackbarNoDismissOpen = false;
    let snackbarNoDismissMsg;
    let snackbarNoDismissType;

    let companieNames = [];
    $allCompanies.forEach((item) => {
        companieNames.push([item.name, item.id]);
    });

    function fireSnackbarNoDismiss(msg, type) {
        snackbarNoDismissMsg = msg;
        snackbarNoDismissType = type;
        snackbarNoDismissOpen = true;
    }

    function checkRequired() {
        if (!firstName | !email | !password) {
            window.alert(getText('ui_messages.alerts.general.alert_required_information'));
            return false;
        }
        if (!type && document.getElementById('company').value == 'select') {
            window.alert(getText('ui_messages.alerts.general.alert_required_information'));
            return false;
        }
        return true;
    }

    async function save() {
        if (checkRequired()) {
            let companyId;
            if (!type) {
                let selection = document.getElementById('company').value;
                companyId = parseInt(selection.split(',')[1]);
            } else {
                companyId = $storedUser.company_id;
            }

            let data = {
                email: email,
                password: password,
                is_active: true,
                is_superuser: false,
                is_verified: false,
                first_name: firstName,
                company_id: companyId,
            };

            // check password
            let save = true;

            if (data.password.length < 8) {
                fireSnackbarNoDismiss(getText('ui_messages.snackbars.admin.snackbar_password_characters'), 'warning');
                save = false;
                return;
            }

            if (data.password.includes(data.email)) {
                fireSnackbarNoDismiss(getText('ui_messages.snackbars.admin.snackbar_password_includes_email'), 'warning');
                save = false;
                return;
            }

            // validate email
            let valid = await validateEmail(data.email);
            if (!valid) {
                fireSnackbarNoDismiss(getText('ui_messages.snackbars.admin.snackbar_email_format'), 'warning');
                save = false;
                return;
            }

            // check email exists
            let all = await getAllUsers();

            all.forEach((user) => {
                if (data.email == user.email) {
                    fireSnackbarNoDismiss(getText('ui_messages.snackbars.admin.snackbar_user_exists'), 'warning');
                    save = false;
                    return;
                }
            });

            if (save) {
                try {
                    let newUser = await register(data)
                        .then((response) => response.json())
                        .then((data) => {
                            return data;
                        });

                    if (newUser) {
                        if (!type) {
                            navigate('/admin', {replace: true});
                        } else {
                            navigate('/', {replace: true});
                        }
                    }
                } catch (e) {
                    console.log(`Error ${e} occoured whilst creating the new user ${data}`);
                }
            }
        }
    }

    function cancel() {
        if (!type) {
            navigate('/admin', {replace: true});
        } else {
            navigate('/', {replace: true});
        }
    }
</script>

{#if snackbarNoDismissOpen}
    <SnackbarNoDismiss {snackbarNoDismissMsg} bind:snackbarNoDismissOpen {snackbarNoDismissType} timeout="4000" />
{/if}

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
            <h6 class="text-blueGray-700 text-xl font-bold">{cardTitle}</h6>
            <div class="flex flex-wrap items-center">
                <CardButton classes="saveproceed" id="saveproceed" type="go" text={getText('admin.users.new_user.button_save')} on:click={() => save()} />
                <CardButton classes="saveproceed" id="saveproceed" type="cancel" text={getText('admin.users.new_user.button_cancel')} on:click={() => cancel()} />
            </div>
        </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form>
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {getText('admin.users.new_user.category_1.title')}
            </h6>

            <div class="flex flex-wrap">
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-title">
                            {getText('admin.users.new_user.category_1.entry_1.title')}
                        </label>
                        <input
                            id="grid-title"
                            type="input"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            bind:value={firstName}
                        />
                    </div>
                </div>

                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-client">
                            {getText('admin.users.new_user.category_1.entry_2.title')}
                        </label>
                        <input
                            id="grid-title"
                            type="input"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            bind:value={email}
                        />
                    </div>
                </div>
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-client">
                            {getText('admin.users.new_user.category_1.entry_3.title')}
                        </label>
                        <input
                            id="grid-title"
                            type="input"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            bind:value={password}
                        />
                    </div>
                </div>
                {#if !type}
                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-client">
                                {getText('admin.users.new_user.category_1.entry_4.title')}
                            </label>

                            <select name="type" id="company">
                                <option value="select">
                                    {getText('admin.users.new_user.category_1.entry_4.input_placeholder')}
                                </option>
                                {#each companieNames as company}
                                    <option value={company}>{company[0]}</option>
                                {/each}
                            </select>
                        </div>
                    </div>
                {/if}
            </div>
        </form>
    </div>
</div>

{#if $storedCompany.type == 'admin'}
    <CardTableUsers showNewButton={false} />
{/if}
