<script>
    import {getText} from '../../language.svelte';
    import {missingLinkId} from '../../store.js';

    export let bookingRequest;
    export let campaign;
    export let adCreated;
    export let adNotes;
    export let updateAdCreated;
    export let checkStatus;
    export let backgroundColor = 'bg-blueGray-200';

    // missing link sonderlocke
    let missingLinkCase = campaign.company_id == $missingLinkId ? true : false;
</script>

{#if ((bookingRequest.state == 8 && bookingRequest.deal_id) || bookingRequest.state >= 9) && bookingRequest.state != 14 && !missingLinkCase}
    <form>
        <div class="{backgroundColor} flex-auto mt-1 pt-1 px-4 lg:px-10 py-10 pt-0">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {getText('podcast.booking_request_details.ad_server_setup.title')}
            </h6>
            <div class="flex flex-wrap">
                <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-select-ad-created-{bookingRequest.id}">
                            {getText('podcast.booking_request_details.ad_server_setup.category_1.title')}
                        </label>
                        {#if bookingRequest.ad_created}
                            <input
                                id="grid-select-ad-created-{bookingRequest.id}"
                                type="checkbox"
                                checked
                                readonly
                                disabled="disabled"
                                data-id={bookingRequest.id}
                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                            />
                        {:else}
                            <input
                                id="grid-select-ad-created-{bookingRequest.id}"
                                type="checkbox"
                                data-id={bookingRequest.id}
                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                on:click={() => {
                                    if (!adCreated) {
                                        updateAdCreated(bookingRequest.id);
                                    }
                                }}
                            />
                        {/if}
                        {#if !bookingRequest.ad_created}
                            {#if campaign.type == 'hostread'}
                                <span class="ml-2 text-sm font-semibold text-blueGray-600">
                                    {getText('podcast.booking_request_details.ad_server_setup.category_1.description_hostread')}
                                </span>
                            {:else if campaign.type == 'spot'}
                                <span class="ml-2 text-sm font-semibold text-blueGray-600">
                                    {getText('podcast.booking_request_details.ad_server_setup.category_1.description_spot')}
                                </span>
                            {:else if campaign.type == 'crosspromo'}
                                <span class="ml-2 text-sm font-semibold text-blueGray-600">{getText('podcast.booking_request_details.ad_server_setup.category_1.description_crosspromo')} </span>
                            {/if}
                        {/if}
                    </div>
                </div>
                {#if adCreated}
                    {#if !bookingRequest.ad_notes}
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-title">
                                    {getText('podcast.booking_request_details.ad_server_setup.category_2.title')}
                                </label>
                                <input
                                    id="grid-ad-notes-{bookingRequest.id}"
                                    type="input"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder={getText('podcast.booking_request_details.ad_server_setup.category_2.input_placeholder')}
                                    bind:value={adNotes}
                                    on:input={checkStatus}
                                />
                            </div>
                        </div>
                    {:else}
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-title">
                                    {getText('podcast.booking_request_details.ad_server_setup.category_2.title')}
                                </label>
                                <p
                                    id="grid-ad-notes"
                                    class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                >
                                    {bookingRequest.ad_notes}
                                </p>
                            </div>
                        </div>
                    {/if}
                {/if}
            </div>
        </div>
    </form>
{/if}
