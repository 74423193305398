<script>
    import {Router, Route} from 'svelte-routing';
    import AuthNavbar from '../components/Navbars/AuthNavbar.svelte';
    import Login from '../views/auth/Login.svelte';
    import Register from '../views/auth/Register.svelte';
    // import PasswordReset from '../views/auth/PasswordReset.svelte';

    let routes = [
        // {path: '/reset-password', component: PasswordReset, params: {}},
        {path: '/register', component: Register, params: {}},
        {path: '/login', component: Login, params: {}},
        {path: '/', component: Login, params: {}},
        {path: '/*', component: Login, params: {}},
    ];

    console.log('auth');
</script>

<div>
    <AuthNavbar />
    <main>
        <section class="relative w-full h-full py-40 min-h-screen">
            <div class="absolute top-0 w-full h-full bg-blueGray-600 bg-no-repeat bg-full bg-image" />
            <Router>
                {#each routes as route}
                    <Route path={route.path} component={route.component} {...route.params} />
                {/each}
            </Router>
        </section>
    </main>
</div>
