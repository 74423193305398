<script>
    // core components
    import {storedCompany} from '../store.js';
    import UserDropdown from './Dropdowns/UserDropdown.svelte';
    import Language from './Dropdowns/Language.svelte';
</script>

<!-- Navbar -->
<nav class="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
    <div class="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
        <!-- Brand -->
        <a class="text-white text-sm uppercase hidden lg:inline-block font-semibold" href="#pablo" on:click={(e) => e.preventDefault()}>
            {#if $storedCompany.name}
                {$storedCompany.name}
            {/if}
        </a>
        <!-- Form -->
        <!-- <form class="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
			<div class="relative flex w-full flex-wrap items-stretch">
                <span class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                    <i class="fas fa-search" />
                </span>
				<input
						type="text"
						placeholder="Search here..."
						class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
				/>
			</div>
		</form> -->
        <!-- Language Settings -->
        <!-- User -->
        <ul class="flex-col md:flex-row list-none items-center hidden md:flex">
            <!-- TODO check mit Vinc wieso es hier throttling gibt wenn kein user eingelogged ist und das language dropdown getriggered wird (siehe Chrome bug) -->
            {#if $storedCompany.name}
                <Language />
            {/if}
            <UserDropdown />
        </ul>
    </div>
</nav>
<!-- End Navbar -->
