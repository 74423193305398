<script>
    import {navigate} from 'svelte-routing';

    import {createCompany} from '../../api/api';
    import {getText} from '../../language.svelte';
    import CardTableCompanies from 'components/Cards/CardTableCompanies.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let cardTitle = getText('admin.companies.new_company.title');
    let name;

    const types = ['promoter', 'podcast', 'admin'];

    function checkRequired() {
        if (!name) {
            window.alert(getText('ui_messages.alerts.general.alert_required_information'));
            return false;
        }
        if (document.getElementById('companyType').value == 'select') {
            window.alert(getText('ui_messages.alerts.general.alert_required_information'));
            return false;
        }
        return true;
    }

    async function save() {
        if (checkRequired()) {
            let type = document.getElementById('companyType').value;

            let data = {
                name: name,
                type: type,
            };

            try {
                let newCompany = await createCompany(data)
                    .then((response) => response.json())
                    .then((data) => {
                        return data;
                    });

                if (newCompany) {
                    console.log('company created: ', newCompany);
                    navigate('/admin', {replace: true});
                }
            } catch (e) {
                console.log(`Error ${e} occoured whilst creating the new company !`);
            }
        }
    }

    function cancel() {
        navigate('/admin', {replace: true});
    }
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
            <h6 class="text-blueGray-700 text-xl font-bold">{cardTitle}</h6>
            <div class="flex flex-wrap items-center">
                <CardButton classes="saveproceed" id="saveproceed" type="go" text={getText('admin.companies.new_company.button_save')} on:click={() => save()} />
                <CardButton classes="saveproceed" id="saveproceed" type="cancel" text={getText('admin.companies.new_company.button_cancel')} on:click={() => cancel()} />
            </div>
        </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form>
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {getText('admin.companies.new_company.category_1.title')}
            </h6>

            <div class="flex flex-wrap">
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-title"> {getText('admin.companies.new_company.category_1.entry_1.title')} </label>
                        <input
                            id="grid-title"
                            type="input"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            bind:value={name}
                        />
                    </div>
                </div>

                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-client"> {getText('admin.companies.new_company.category_1.entry_2.title')} </label>

                        <select name="type" id="companyType">
                            <option value="select">
                                {getText('admin.companies.new_company.category_1.entry_2.input_placeholder')}
                            </option>
                            {#each types as type}
                                <option value={type}>{type}</option>
                            {/each}
                        </select>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<CardTableCompanies showNewButton={false} />
