<script>
    import {link} from 'svelte-routing';
    import NotificationDropdown from 'components/Dropdowns/NotificationDropdown.svelte';
    import UserDropdown from 'components/Dropdowns/UserDropdown.svelte';
    import {getText} from '../../src/language.svelte';
    import {
        storedCompany,
        storedUser,
        todosPodcast,
        waitingPodcast,
        upcomingPodcast,
        runningPodcast,
        completedPodcast,
        wrappedPodcast,
        rejectedPodcast,
        cancelledPodcast,
        completedCampaignsTodo,
        completedCampaignsTotal,
        preProductionCampaignsTodo,
        preProductionCampaignsTotal,
        runningCampaigns,
        campaignsAllTotal,
        campaignsAllTodo,
        crossPromosTotal,
        crossPromosTodo,
        archivedCampaignsTotal,
        missingLinkId,
        render,
    } from '../store';

    //force rerendering on location change to update highlights
    export let location;

    let collapseShow = 'hidden';

    function toggleCollapseShow(classes) {
        collapseShow = classes;
    }

    let isActive = (current, path) => {
        return current.pathname === path ? 'text-red-500 hover:text-red-600' : 'text-blueGray-700 hover:text-blueGray-500';
    };

    let adminPromoter = [
        {
            title: getText('admin.sidebar.promoter.title'),
            entries: [{title: getText('admin.sidebar.promoter.entry_1'), path: '/promoter/newuser', icon: 'fas fa-users'}],
        },
    ];
    let adminPodcast = [
        {
            title: getText('admin.sidebar.podcast.title'),
            entries: [
                {title: getText('admin.sidebar.podcast.entry_1'), path: '/podcast/newpodcast', icon: 'far fa-podcast'},
                {title: getText('admin.sidebar.podcast.entry_2'), path: '/podcast/newuser', icon: 'fas fa-users'},
            ],
        },
    ];

    let promoter = [
        {
            title: getText('promoter.sidebar.section_1.title'),
            entries: [
                {title: getText('promoter.sidebar.section_1.entry_4'), badge: $campaignsAllTotal, badgeAction: $campaignsAllTodo, path: '/promoter', icon: 'fa-list'},
                {title: getText('promoter.sidebar.section_1.entry_1'), badge: $preProductionCampaignsTotal, badgeAction: $preProductionCampaignsTodo, path: '/promoter/current', icon: 'fa-list'},
                {title: getText('promoter.sidebar.section_1.entry_2'), badge: $runningCampaigns, path: '/promoter/running', icon: 'fa-list'},
                {title: getText('promoter.sidebar.section_1.entry_3'), badge: $completedCampaignsTotal, badgeAction: $completedCampaignsTodo, path: '/promoter/completed', icon: 'fa-list'},
                {title: getText('promoter.sidebar.section_1.entry_5'), badge: $archivedCampaignsTotal, path: '/promoter/archived', icon: 'fa-archive'},
            ],
        },
        // network
        {
            title: 'Network',
            entries: [{title: 'Podcasts', path: '/promoter/network/podcasts', icon: 'fa-list'}],
        },
        {
            title: getText('promoter.sidebar.section_2.title'),
            entries: [
                {title: getText('promoter.sidebar.section_2.entry_1'), path: '/user/profile', icon: 'fas fa-user'},
                {title: getText('promoter.sidebar.section_2.entry_2'), path: '/auth/logout', icon: 'fa-sign-out-alt'},
            ],
        },
    ];

    let promoter_MissingLink = [
        {
            title: getText('promoter.sidebar.section_1.title'),
            entries: [
                {title: getText('promoter.sidebar.section_1.entry_4'), badge: $campaignsAllTotal, badgeAction: $campaignsAllTodo, path: '/promoter', icon: 'fa-list'},
                {title: getText('promoter.sidebar.section_1.entry_1'), badge: $preProductionCampaignsTotal, badgeAction: $preProductionCampaignsTodo, path: '/promoter/current', icon: 'fa-list'},
                {title: getText('promoter.sidebar.section_1.entry_2'), badge: $runningCampaigns, path: '/promoter/running', icon: 'fa-list'},
                {title: getText('promoter.sidebar.section_1.entry_3'), badge: $completedCampaignsTotal, badgeAction: $completedCampaignsTodo, path: '/promoter/completed', icon: 'fa-list'},
                {title: getText('promoter.sidebar.section_1.entry_5'), badge: $archivedCampaignsTotal, path: '/promoter/archived', icon: 'fa-archive'},
            ],
        },
        {
            title: getText('promoter.sidebar.section_2.title'),
            entries: [
                {title: getText('promoter.sidebar.section_2.entry_1'), path: '/user/profile', icon: 'fas fa-user'},
                {title: getText('promoter.sidebar.section_2.entry_2'), path: '/auth/logout', icon: 'fa-sign-out-alt'},
            ],
        },
    ];

    let podcast = [
        {
            title: getText('podcast.sidebar.section_1.title'),
            entries: [{title: getText('podcast.sidebar.section_1.entry_1'), badgeAction: $todosPodcast, path: '/podcast', icon: 'fa-clipboard-list'}],
        },
        {
            title: '',
            entries: [
                {title: getText('podcast.sidebar.section_1.entry_2'), badge: $waitingPodcast, path: '/podcast/waiting', icon: 'fa-list'},
                {title: getText('podcast.sidebar.section_1.entry_3'), badge: $upcomingPodcast, path: '/podcast/upcoming', icon: 'fa-list'},
                {title: getText('podcast.sidebar.section_1.entry_4'), badge: $runningPodcast, path: '/podcast/running', icon: 'fa-list'},
                {title: getText('podcast.sidebar.section_1.entry_5'), badge: $completedPodcast, path: '/podcast/completed', icon: 'fa-list'},
                {title: getText('podcast.sidebar.section_1.entry_6'), badge: $wrappedPodcast, path: '/podcast/wrapped', icon: 'fa-archive'},
                {title: getText('podcast.sidebar.section_1.entry_7'), badge: $rejectedPodcast, path: '/podcast/rejected', icon: 'fa-archive'},
                {title: getText('podcast.sidebar.section_1.entry_8'), badge: $cancelledPodcast, path: '/podcast/cancelled', icon: 'fa-archive'},
            ],
        },
        {
            title: getText('podcast.sidebar.section_2.title'),
            entries: [
                {title: getText('podcast.sidebar.section_2.entry_1'), path: '/podcast/crosspromo/new/crosspromo', icon: 'fa-plus-circle'},
                {title: getText('podcast.sidebar.section_2.entry_2'), badge: $crossPromosTotal, badgeAction: $crossPromosTodo, path: '/podcast/crosspromo/overview', icon: 'fa-list'},
            ],
        },

        // Selfpromotion
        {
            title: getText('podcast.sidebar.section_4.title'),
            entries: [{title: getText('podcast.sidebar.section_4.entry_1'), path: '/podcast/selfpromo/settings', icon: 'fas fa-cog'}],
        },

        // Inventar
        {
            title: getText('podcast.sidebar.section_5.title'),
            entries: [
                {title: getText('podcast.sidebar.section_5.entry_1'), path: '/podcast/inventory/overview', icon: 'fas fa-list'},
                {title: getText('general.roll_positions.entry_1'), path: '/podcast/inventory/pre-roll', icon: 'fas fa-list'},
                {title: getText('general.roll_positions.entry_2'), path: '/podcast/inventory/mid-roll-1', icon: 'fas fa-list'},
                {title: getText('general.roll_positions.entry_3'), path: '/podcast/inventory/mid-roll-2', icon: 'fas fa-list'},
                {title: getText('general.roll_positions.entry_4'), path: '/podcast/inventory/mid-roll-3', icon: 'fas fa-list'},
                {title: getText('general.roll_positions.entry_5'), path: '/podcast/inventory/post-roll', icon: 'fas fa-list'},
                {title: getText('podcast.sidebar.section_5.entry_2'), path: '/podcast/inventory/campaigns', icon: 'fas fa-list'},
                {title: getText('podcast.sidebar.section_5.entry_3'), path: '/podcast/inventory/settings', icon: 'fas fa-cog'},
            ],
        },

        // User - auskommentiert aus Platzgründen in der Sidebar
        // {
        //     title: getText('podcast.sidebar.section_3.title'),
        //     entries: [
        //         {title: getText('podcast.sidebar.section_3.entry_1'), path: '/user/profile', icon: 'fas fa-user'},
        //         {title: getText('podcast.sidebar.section_3.entry_2'), path: '/auth/logout', icon: 'fa-sign-out-alt'},
        //     ],
        // },
    ];

    let admin = [
        {
            title: getText('admin.sidebar.admin.title'),
            entries: [
                {title: getText('admin.sidebar.admin.entry_1'), path: '/admin', icon: 'fa-user-ninja'},
                {title: getText('admin.sidebar.admin.entry_2'), path: '/admin/newcompany', icon: 'fa-user-ninja'},
                {title: getText('admin.sidebar.admin.entry_3'), path: '/admin/newpodcast', icon: 'fa-user-ninja'},
                {title: getText('admin.sidebar.admin.entry_4'), path: '/admin/newkeyword', icon: 'fa-user-ninja'},
                {title: getText('admin.sidebar.admin.entry_5'), path: '/admin/newuser', icon: 'fa-user-ninja'},
            ],
        },
        {
            title: 'User',
            entries: [{title: 'Logout', path: '/auth/logout', icon: 'fa-sign-out-alt'}],
        },
    ];

    $: structure = {
        promoter: promoter,
        podcast: podcast,
        admin: admin,
    };

    $: structure_MissingLink = {
        promoter: promoter_MissingLink,
    };

    let getMenuStructure = () => {
        if ($storedCompany.type == 'promoter' && $storedCompany.id == $missingLinkId) {
            return $storedUser.is_superuser ? [...structure_MissingLink[$storedCompany.type], ...adminPromoter] : structure_MissingLink[$storedCompany.type];
        } else if ($storedCompany.type == 'promoter' && $storedCompany.id !== $missingLinkId) {
            return $storedUser.is_superuser ? [...structure[$storedCompany.type], ...adminPromoter] : structure[$storedCompany.type];
        } else if ($storedCompany.type == 'podcast') {
            return $storedUser.is_superuser ? [...structure[$storedCompany.type], ...adminPodcast] : structure[$storedCompany.type];
        } else if ($storedCompany.type == 'admin') {
            return $storedUser.is_superuser ? [...structure[$storedCompany.type]] : structure[$storedCompany.type];
        }
    };

    $: menu = $storedCompany.type
        ? getMenuStructure($render, $todosPodcast, $waitingPodcast, $upcomingPodcast, $runningPodcast, $completedPodcast, $wrappedPodcast, $rejectedPodcast, $cancelledPodcast)
        : [];
</script>

<nav
    class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
>
    <div class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
        <!-- Toggler -->
        <button
            class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            on:click={() => toggleCollapseShow('bg-white m-2 py-3 px-6')}
        >
            <i class="fas fa-bars" />
        </button>
        <!-- Brand -->
        <a use:link class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0" href="/"> ASAP Dispo </a>
        <!-- User -->
        <ul class="md:hidden items-center flex flex-wrap list-none">
            <li class="inline-block relative">
                <NotificationDropdown />
            </li>
            <li class="inline-block relative">
                <UserDropdown />
            </li>
        </ul>
        <!-- Collapse -->

        <div
            class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4
                    md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto
                    overflow-x-hidden h-auto items-center flex-1 rounded {collapseShow}"
        >
            {#each menu as sections}
                <!-- Divider -->
                <hr class="my-4 md:min-w-full" />
                <!-- Heading -->
                <h6 class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">{sections.title}</h6>
                <!-- Navigation -->
                <ul class="md:flex-col md:min-w-full flex flex-col list-none">
                    {#each sections.entries as entry}
                        <li class="items-center">
                            <a use:link href={entry.path} class="text-xs uppercase py-3 font-bold block {isActive(location, entry.path)}">
                                <i class="fas {entry.icon} mr-2 text-sm" />
                                {entry.title}
                                {#if entry.badge}
                                    <span class="text-xs font-semibold inline-block py-1 px-2 rounded-full text-lightBlue-600 bg-lightBlue-200 uppercase last:mr-0 mr-1">
                                        {entry.badge}
                                    </span>
                                {/if}
                                {#if entry.badgeAction}
                                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-red-600 last:mr-0 mr-1">
                                        {entry.badgeAction}
                                    </span>
                                {/if}
                            </a>
                        </li>
                    {/each}
                </ul>
            {/each}
        </div>
    </div>
</nav>
