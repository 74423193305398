<script>
    import {getText} from '../../language.svelte';
    import {patchPodcast} from '../../api/api.js';
    import {prettyPrintNumber} from '../../functions.js';
    import SnackbarNoDismiss from 'components/Snackbars/SnackbarNoDismiss.svelte';
    import SnackbarDismiss from 'components/Snackbars/SnackbarDismiss.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let podcast;

    let maxBookingVolume = podcast.max_booking_volume;
    let showSave = false;

    let snackbarNoDismissMsg;
    let snackbarNoDismissOpen = false;
    let snackbarNoDismissType;
    let snackbarDismissMsg;
    let snackbarDismissOpen = false;
    let snackbarDismissType;
    let snackbarDismissTitle;

    // HANDLER
    function checkVolumeChanges() {
        maxBookingVolume != podcast.max_booking_volume ? (showSave = true) : (showSave = false);
    }

    async function save() {
        let newPodcast = {
            max_booking_volume: maxBookingVolume,
        };

        try {
            await patchPodcast(podcast.id, newPodcast);
            showSave = false;
            fireSnackbarNoDismiss(getText('ui_messages.snackbars.general.snackbar_save_success'), 'success');
            podcast.max_booking_volume = maxBookingVolume;
        } catch (e) {
            console.log(`Error ${e} occoured whilst updating podcast "${podcast.name}"`);
            fireSnackbarDismiss(getText('ui_messages.alerts.general.alert_update_fail'), 'alert', getText('ui_messages.sorry'));
        }
    }

    function fireSnackbarNoDismiss(msg, type) {
        snackbarNoDismissMsg = msg;
        snackbarNoDismissType = type;
        snackbarNoDismissOpen = true;
    }

    function fireSnackbarDismiss(msg, type, title) {
        snackbarDismissMsg = msg;
        snackbarDismissType = type;
        snackbarDismissTitle = title;
        snackbarDismissOpen = true;
    }
</script>

{#if snackbarNoDismissOpen}
    <SnackbarNoDismiss {snackbarNoDismissMsg} bind:snackbarNoDismissOpen {snackbarNoDismissType} timeout="2000" />
{/if}

{#if snackbarDismissOpen}
    <SnackbarDismiss {snackbarDismissTitle} {snackbarDismissMsg} bind:snackbarDismissOpen {snackbarDismissType} />
{/if}

<div class="flex flex-wrap border-t-0 align-middle border-l-0 border-r-0 mb-4">
    <div class="flex-auto p-4">
        <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-target-impressions">
                        {getText('podcast.inventory.settings.section_1.title')}
                    </label>
                    <input
                        id="grid-inventory-volume-month"
                        type="number"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        bind:value={maxBookingVolume}
                        on:input={() => {
                            checkVolumeChanges();
                        }}
                    />
                </div>
            </div>
        </div>
    </div>
    {#if showSave}
        <div class="relative flex flex-col min-w-0 break-words rounded ml-2 shadow-lg mb-2 mt-2">
            <!-- <button
                id="grid-button-undo"
                type="button"
                class="flex-auto p-4 bg-red-300"
                on:click={() => {
                    maxBookingVolume = podcast.max_booking_volume;
                    showSave = false;
                }}
            >
                <i class="fas fa-times" />
            </button> -->
            <!-- TODO test CardButton when live -->
            <CardButton
                id="undo"
                type="custom"
                icon="fas fa-times"
                classList="flex-auto p-4 bg-red-300"
                on:click={() => {
                    maxBookingVolume = podcast.max_booking_volume;
                    showSave = false;
                }}
            />

            <!-- <button
                id="grid-button-save"
                type="button"
                class="flex-auto p-4 bg-emerald-300"
                on:click={() => {
                    save();
                }}
            >
                <i class="fas fa-save" />
            </button> -->
            <CardButton
                id="save-inventory-settings"
                type="custom"
                icon="fas fa-save"
                classList="flex-auto p-4 bg-emerald-300"
                on:click={() => {
                    save();
                }}
            />
        </div>
    {/if}
    <div class="flex-auto p-4">
        <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-400 text-xs font-bold mb-2" for="grid-target-impressions">
                        {getText('podcast.inventory.settings.section_2.title')}
                    </label>
                    <input
                        id="grid-inventory-volume-day"
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={prettyPrintNumber(Math.round((maxBookingVolume * 12) / 365))}
                        disabled
                        readonly="true"
                    />
                </div>
            </div>
        </div>
    </div>
    <div class="flex-auto p-4">
        <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-400 text-xs font-bold mb-2" for="grid-target-impressions">
                        {getText('podcast.inventory.settings.section_3.title')}
                    </label>
                    <input
                        id="grid-inventory-volume-year"
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={prettyPrintNumber(maxBookingVolume * 12)}
                        disabled
                        readonly="true"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
