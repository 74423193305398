<script>
    import {prettyPrintNumber} from '../../functions.js';
    import {getText} from '../../language.svelte';
    import CardCommitMessage from 'components/Cards/CardCommitMessage.svelte';
    import {storedCompany, missingLinkId} from '../../store.js';
    import CardButton from 'components/Cards/CardButton.svelte';

    function close() {
        showModifications = false;
    }

    export let campaign;
    export let podcastName;
    export let bookingRequest;
    export let commitTitle = getText('commit_messages.commit_message.headline_2');
    export let showModifications;
    export let commitMessage;
    export let confirmBooking = () => {};
    export let rejectModifications = () => {};
    export let cancelBookingRequest = () => {};

    let action;
    let continueProcess = false;
    let showCommitMessage = false;
    let confirmBookingClicked = false;
    let requestChangesClicked = false;
    let deleteRequestClicked = false;
    let showModificationsLocal = showModifications;

    let campaignRollPositionChecked = [
        {idx: 1, val: campaign.roll_position_pre},
        {idx: 2, val: campaign.roll_position_mid_1},
        {idx: 3, val: campaign.roll_position_mid_2},
        {idx: 4, val: campaign.roll_position_mid_3},
        {idx: 5, val: campaign.roll_position_post},
    ];

    let bookingRequestRollPositionChecked = [
        {idx: 1, val: bookingRequest.roll_position_pre},
        {idx: 2, val: bookingRequest.roll_position_mid_1},
        {idx: 3, val: bookingRequest.roll_position_mid_2},
        {idx: 4, val: bookingRequest.roll_position_mid_3},
        {idx: 5, val: bookingRequest.roll_position_post},
    ];

    // missing link sonderlocke
    let campaignRollPositionCheckedMissingLink = [
        {idx: 1, val: campaign.roll_position_pre},
        {idx: 2, val: campaign.roll_position_mid_1},
        {idx: 3, val: campaign.roll_position_mid_2},
        {idx: 4, val: campaign.roll_position_mid_3},
        {idx: 5, val: campaign.roll_position_mid_4},
        {idx: 6, val: campaign.roll_position_mid_5},
        {idx: 7, val: campaign.roll_position_post},
    ];

    let bookingRequestRollPositionCheckedMissingLink = [
        {idx: 1, val: bookingRequest.roll_position_pre},
        {idx: 2, val: bookingRequest.roll_position_mid_1},
        {idx: 3, val: bookingRequest.roll_position_mid_2},
        {idx: 4, val: bookingRequest.roll_position_mid_3},
        {idx: 5, val: bookingRequest.roll_position_mid_4},
        {idx: 6, val: bookingRequest.roll_position_mid_5},
        {idx: 7, val: bookingRequest.roll_position_post},
    ];

    function parentFunction() {
        if (requestChangesClicked) {
            rejectModifications();
        } else if (deleteRequestClicked) {
            return cancelBookingRequest();
        }
    }

    function triggerCommitMessagePopUp() {
        showCommitMessage = true;
        showModificationsLocal = false;
    }

    function setAction(thisAction) {
        action = thisAction;
    }

    function deleteRequest() {
        {
            deleteRequestClicked = true;
            setAction(getText('commit_messages.booking_modification.entry_2.action'));
            commitTitle = getText('commit_messages.booking_modification.entry_2.headline_1');
            triggerCommitMessagePopUp();
        }
    }

    function changeRequest() {
        requestChangesClicked = true;
        setAction(getText('commit_messages.booking_modification.entry_1.action'));
        commitTitle = getText('commit_messages.booking_modification.entry_1.headline_1');
        triggerCommitMessagePopUp();
    }

    function confirmRequest() {
        showModifications = false;
        confirmBooking();
    }
</script>

{#if showCommitMessage}
    <CardCommitMessage {action} {commitTitle} bind:commitMessage bind:continueProcess bind:showCommitMessage parentFunction={() => parentFunction()} />
{/if}

{#if showModificationsLocal}
    <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
        <div class="relative w-third mt-20 my-6 mx-auto">
            <div class="bg-gray-200 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded border-1">
                <div class="bg-gray-300 rounded-t mb-3 px-4 py-3 border-0">
                    <div class="flex flex-wrap items-center">
                        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 class="font-semibold text-base text-blueGray-700">
                                <p>
                                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.booking_request_modifications.title', podcastName)} <em>{campaign.title}</em>
                                </p>
                            </h3>
                        </div>
                        <div class="text-right">
                            <CardButton id="close" type="default" text="X" on:click={() => close()} />
                        </div>
                    </div>
                </div>

                <form>
                    <div class="'bg-blueGray-200 flex-auto mt-1 pt-1 px-4 lg:px-10 py-10 pt-0">
                        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                            {getText('promoter.campaign_overview.booking_requests_table_rows.pending.booking_request_modifications.category_1.title')}
                        </h6>
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-4/12 px-4 mt-5">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-requested-impressions">
                                        {getText('promoter.campaign_overview.booking_requests_table_rows.pending.booking_request_modifications.category_1.entry_1.title')}
                                    </label>
                                    <p
                                        id="grid-requested-impressions"
                                        class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    >
                                        {prettyPrintNumber(bookingRequest.requested_impressions)}
                                    </p>
                                </div>
                            </div>

                            <div class="w-full lg:w-4/12 px-4 mt-5">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-requested-impressions">
                                        {getText('promoter.campaign_overview.booking_requests_table_rows.pending.booking_request_modifications.category_1.entry_2.title')}
                                    </label>
                                    <p
                                        id="grid-requested-impressions"
                                        class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    >
                                        {prettyPrintNumber(bookingRequest.offered_impressions)}
                                    </p>
                                </div>
                            </div>

                            <div class="w-full lg:w-4/12 px-4 mt-5">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-requested-impressions">
                                        {getText('promoter.campaign_overview.booking_requests_table_rows.pending.booking_request_modifications.category_1.entry_3.title')}
                                    </label>
                                    <p
                                        id="grid-requested-impressions"
                                        class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    >
                                        {prettyPrintNumber(bookingRequest.requested_impressions - bookingRequest.offered_impressions)}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                            {getText('promoter.campaign_overview.booking_requests_table_rows.pending.booking_request_modifications.category_2.title')}
                        </h6>
                        <div class="flex flex-wrap">
                            <div class="w-full lg:w-12/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-requested-impressions">
                                        {getText('promoter.campaign_overview.booking_requests_table_rows.pending.booking_request_modifications.category_2.entry_1.title')}
                                    </label>
                                    <div class="text-center flex justify-between">
                                        <!-- missing link sonderlocke -->
                                        {#if $storedCompany.id == $missingLinkId}
                                            {#each campaignRollPositionCheckedMissingLink as { idx, val }}
                                                <label for="grid-campaign-checkbox-roll-${idx}" class="mr-4 mt-2">
                                                    <input
                                                        id="grid-campaign-checkbox-roll-${idx}"
                                                        type="checkbox"
                                                        class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                        bind:checked={val}
                                                        disabled="disabled"
                                                    />
                                                    {getText(`general.roll_positions_missing_link.entry_${idx}`)}
                                                </label>
                                            {/each}
                                        {:else}
                                            {#each campaignRollPositionChecked as { idx, val }}
                                                <label for="grid-campaign-checkbox-roll-${idx}" class="mr-4 mt-2">
                                                    <input
                                                        id="grid-campaign-checkbox-roll-${idx}"
                                                        type="checkbox"
                                                        class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                        bind:checked={val}
                                                        disabled="disabled"
                                                    />
                                                    {getText(`general.roll_positions.entry_${idx}`)}
                                                </label>
                                            {/each}
                                        {/if}
                                    </div>
                                </div>
                            </div>

                            <div class="w-full lg:w-12/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-requested-impressions">
                                        {getText('promoter.campaign_overview.booking_requests_table_rows.pending.booking_request_modifications.category_2.entry_2.title')}
                                    </label>
                                    <div class="text-center flex justify-between">
                                        <!-- missing link sonderlocke -->
                                        {#if $storedCompany.id == $missingLinkId}
                                            {#each bookingRequestRollPositionCheckedMissingLink as { idx, val }}
                                                <label for="grid-campaign-checkbox-roll-${idx}" class="mr-4 mt-2">
                                                    <input
                                                        id="grid-campaign-checkbox-roll-${idx}"
                                                        type="checkbox"
                                                        class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                        bind:checked={val}
                                                        disabled="disabled"
                                                    />
                                                    {getText(`general.roll_positions_missing_link.entry_${idx}`)}
                                                </label>
                                            {/each}
                                        {:else}
                                            {#each bookingRequestRollPositionChecked as { idx, val }}
                                                <label for="grid-campaign-checkbox-roll-${idx}" class="mr-4 mt-2">
                                                    <input
                                                        id="grid-campaign-checkbox-roll-${idx}"
                                                        type="checkbox"
                                                        class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                        bind:checked={val}
                                                        disabled="disabled"
                                                    />
                                                    {getText(`general.roll_positions.entry_${idx}`)}
                                                </label>
                                            {/each}
                                        {/if}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="text-center flex justify-between">
                    <div class="bg-gray-300 flex-auto px-4 lg:px-10 lg:pb-2">
                        <CardButton
                            id="confirm"
                            type="go"
                            text={getText('promoter.campaign_overview.booking_requests_table_rows.pending.booking_request_modifications.button_confirm')}
                            on:click={() => confirmRequest()}
                        />
                    </div>
                    <div class="bg-gray-300 flex-auto px-4 lg:px-10 lg:pb-2">
                        <CardButton
                            id="change"
                            type="change"
                            text={getText('promoter.campaign_overview.booking_requests_table_rows.pending.booking_request_modifications.button_request_changes')}
                            on:click={() => changeRequest()}
                        />
                    </div>
                    <div class="bg-gray-300 flex-auto px-4 lg:px-10 lg:pb-2">
                        <CardButton
                            id="cancel"
                            type="cancel"
                            text={getText('promoter.campaign_overview.booking_requests_table_rows.pending.booking_request_modifications.button_delete')}
                            on:click={() => deleteRequest()}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="opacity-25 fixed inset-0 z-40 bg-black" />
{/if}
