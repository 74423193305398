import {writable} from 'svelte/store';

// logged in user
// export const storedUser = writable({});
export const storedUser = writableSession('user', {});

// company of logged in user
export const storedCompany = writableSession('company', {});

// set when a camapaign is clicked in overview table
// reset onMount of overview table
export const selectedCampaign = writable({});

// for podcast view
export const selectedBookingRequest = writable({});

// to calculate total impression requests in targetingTable
export const requestedTargetImpressionsCount = writable(0);

// all booking requests of a targeting table when create/edit campaign
// set & updated in targeting table
export const storedBookingRequests = writable([]);

// all BRs of a campagin stored in DB, before updated
export const originalBookingRequests = writable([]);

// targetImpressions for a new campaign
export const newCampaignTargetImpressions = writable(0);

// edit state of campaign and targeting
export const campaignEdited = writable(false);
export const targetingEdited = writable(false);

// modal control
export let showModal = writable(false);
export let showScriptControl = writable(false);

// alert control
export let alertOpen = writable(false);
export let alertSingleOpen = writable(false);

// all companies for admin view
export let allCompanies = writable([]);

// for ListBRs: to order BRs accordign to state - storages
export const notActivatedBRs = writable([]);
export const pendingBRs = writable([]);
export const rejectedBRs = writable([]);
export const cancelledBRs = writable([]);
export const onAirBRs = writable([]);
export const completedBRs = writable([]);
export const wrappedBRs = writable([]);

// for Promoter Overview: to preload campaign + BR data
export const campaignBookingRequests = writable({});

export function writableSession(key, initialValue) {
    function persist(key, value) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    const sessionValue = JSON.parse(sessionStorage.getItem(key));
    if (!sessionValue) persist(key, initialValue);

    const store = writable(sessionValue || initialValue);
    store.subscribe((value) => persist(key, value));

    return store;
}

// counts for state folders
export let todosPodcast = writable(0);
export let waitingPodcast = writable(0);
export let upcomingPodcast = writable(0);
export let runningPodcast = writable(0);
export let completedPodcast = writable(0);
export let wrappedPodcast = writable(0);
export let rejectedPodcast = writable(0);
export let cancelledPodcast = writable(0);

export let campaignsAllTotal = writable(0);
export let campaignsAllTodo = writable(0);
export let preProductionCampaignsTotal = writable(0);
export let preProductionCampaignsTodo = writable(0);
export let runningCampaigns = writable(0);
export let completedCampaignsTotal = writable(0);
export let completedCampaignsTodo = writable(0);
export let archivedCampaignsTotal = writable(0);

export let crossPromosTotal = writable(0);
export let crossPromosTodo = writable(0);

export let reRender = writable(false);

// language settings
export let language = writable('DE');

// common truth for all selfpromos of a podcast network
export let podcastsSelfpromos = writable({});
export let colorCodes = writable({});

// inventoryCheck objects that live for the duration of the user session
export let inventoryChecks = writable([]);

// podcast id for podcast owned campaigns
export const podcastPodcastId = writable(0);

// missing link sonderlocke
export const missingLinkId = writable(238);

// for dynamic menu re-render
export const render = writable();
