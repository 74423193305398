<script>
    // core components

    export let statSubtitle = '';
    export let statTitle = '';
    // The value must match one of these strings
    // "up" or "down"
    export let statArrow = '';
    export let statPercent = '';
    // can be any of the text color utilities
    // from tailwindcss
    export let statPercentColor = '';
    export let statDescription = '';
    export let statIconName = '';
    // can be any of the background color utilities
    // from tailwindcss
    export let statIconColor = '';
</script>

<div class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
    <div class="flex-auto p-4">
        <div class="flex flex-wrap">
            <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                <h5 class="text-blueGray-400 uppercase font-bold text-xs">
                    {statSubtitle}
                </h5>
                <span class="font-semibold text-xl text-blueGray-700">
                    {statTitle}
                </span>
            </div>
            <div class="relative w-auto pl-4 flex-initial">
                <div class="text-white p-3 text-center inline-flex items-center justify-center w-6 h-6 shadow-lg rounded-full {statIconColor}">
                    <i class={statIconName} />
                </div>
            </div>
        </div>
        <p class="text-sm text-blueGray-400 mt-4">
            <span class="mr-2 {statPercentColor}">
                {#if statArrow}
                    <i class={statArrow === 'up' ? 'fas fa-arrow-up' : 'fas fa-arrow-down'} />
                {/if}
                {#if statPercent}
                    {statPercent}%
                {/if}
            </span>
            <span class="whitespace-nowrap">{statDescription}</span>
        </p>
    </div>
</div>
