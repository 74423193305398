<script>
    import {link, navigate} from 'svelte-routing';
    import {login, whoami, getCompanyById, logout, forgotPassword} from '../../api/api.js';
    import {clearApiCache} from '../../api/api-cache';
    import {getText} from '../../language.svelte';
    import {storedUser, storedCompany} from '../../store.js';
    import {validateEmail} from '../../functions.js';

    // export let location;

    // core components
    const github = '../assets/img/github.svg';
    const google = '../assets/img/google.svg';

    //get user
    //   {
    //   "id": "3d41d45d-58dd-437f-8ff3-74460a653cf1",
    //   "email": "podcast@trash-mail.com",
    //   "is_active": true,
    //   "is_superuser": false,
    //   "is_verified": false,
    //   "first_name": "podcast",
    //   "company_id": 112
    // }
    async function checkUser() {
        try {
            return whoami();
        } catch (e) {
            console.log(`Error ${e} occoured during whoami !`);
        }
    }

    //returns name, type, id
    async function checkCompany(companyId) {
        try {
            return getCompanyById(companyId);
        } catch (e) {
            console.log(`Error ${e} occoured whilst loading company ${companyId} !`);
        }
    }

    //api
    const doLogin = async function () {
        //login credentials
        let email = document.getElementById('email').value;
        let password = document.getElementById('password').value;
        let validPass = await validateEmail(email);

        if (!password || !email || !validPass) {
            alert(getText('ui_messages.alerts.login.alert_valid_credentails'));
        } else {
            let response = await login(email, password);

            if (response.status == 400) {
                alert(getText('ui_messages.alerts.login.alert_wrong_credentails'));
            }

            if (response.status == 200) {
                var user = await checkUser();
                storedUser.set(user);
            }

            if (user) {
                var company = await checkCompany(user.company_id);
                storedCompany.set(company);

                //check on super user
                // if (user.is_superuser) {
                //     navigate('/admin', {replace: true});
                //     return;
                // }
            }

            if (company) {
                navigate(`/${company.type}`, {replace: true});
            }
        }
    };

    //api
    const devLogin = async function (type) {
        //login credentials
        let email = `${type}@trash-mail.com`;
        let password = `${type}${type}`;

        let response = await login(email, password);

        if (response.status == 400) {
            alert(getText('ui_messages.alerts.login.alert_wrong_credentails'));
        }

        if (response.status == 200) {
            var user = await checkUser();
            // update store
            storedUser.set(user);
        }

        if (user) {
            var company = await checkCompany(user.company_id);
            storedCompany.set(company);
        }

        if (company) {
            navigate(`/${company.type}`, {replace: true});
        }
    };

    // GENERAL ADMIN LOGIN
    // const adminLogin = async function (type) {
    //     //login credentials
    //     let email = `zimmermann@schneevonmorgen.com`;
    //     let password = `asap-dispo-admin`;

    //     let response = await login(email, password);

    //     if (response.status == 400) {
    //         alert(getText('ui_messages.alerts.login.alert_wrong_credentails'));
    //     }

    //     if (response.status == 200) {
    //         var user = await checkUser();
    //         // update store
    //         storedUser.set(user);
    //     }

    //     if (user) {
    //         var company = await checkCompany(user.company_id);
    //         storedCompany.set(company);
    //     }

    //     if (company) {
    //         navigate(`/${company.type}`, {replace: true});
    //     }
    // };

    const freshLogin = async function (type) {
        let email = 'fresh-' + `${type}@trash-mail.com`;
        let password = 'fresh-' + `${type}`;

        let response = await login(email, password);

        if (response.status == 400) {
            alert(getText('ui_messages.alerts.login.alert_wrong_credentails'));
        }

        if (response.status == 200) {
            var user = await checkUser();
            // update store
            storedUser.set(user);
        }

        if (user) {
            var company = await checkCompany(user.company_id);
            storedCompany.set(company);
        }

        if (company) {
            navigate(`/${company.type}`, {replace: true});
        }
    };

    async function doLogout() {
        storedUser.set({});
        storedCompany.set({});

        try {
            await logout();
            clearApiCache();
            navigate('/auth/login', {replace: true});
        } catch (e) {
            console.log(`Error ${e} occoured whilst logging out !`);
        }
    }

    async function requestPasswordToken(mail) {
        try {
            let response = await forgotPassword({email: mail});
            if (response.status == 202) {
                window.alert('Please check your mails, we have sent you a reset link. It can take a couple of minutes until you receive the link.');
            }
        } catch (e) {
            console.log(`Error ${e} occoured whilst requesting a new password ! Please contact the admin`);
        }
    }

    if (location.pathname === '/auth/logout') {
        doLogout();
    }
</script>

<div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-4/12 px-4">
            <div class="relative flex flex-col min-w-0 break-words w-full mb-1 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <div class="text-center m-3">
                        <h6 class="text-blueGray-500 text-sm font-bold">Sign in</h6>
                    </div>
                    <form>
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="email"> Email </label>
                            <input
                                id="email"
                                type="email"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Email"
                                value=""
                            />
                        </div>

                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="password"> Password </label>
                            <input
                                id="password"
                                type="password"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Password"
                                value=""
                                on:keydown={(e) => {
                                    if (e.code == 'Enter') doLogin();
                                }}
                            />
                        </div>
                        <div class="text-center mt-6">
                            <button
                                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                type="button"
                                on:click={doLogin}
                            >
                                Sign In
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="flex flex-wrap mb-6 relative">
                <div class="w-1/2">
                    <a
                        href="#reset"
                        on:click={(e) => {
                            let mail = window.prompt('Please enter your email address to send the password reset link to ! This might take a couple of minutes.', '');
                            requestPasswordToken(mail);
                        }}
                        class="text-blueGray-200 bg-blueGray-800 rounded"
                    >
                        <small>Forgot password?</small>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-4/12 px-4">
            <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <div class="text-center m-3">
                        <h6 class="text-blueGray-500 text-sm font-bold">Dev Accounts</h6>
                    </div>
                    <form>
                        <div class="text-center mt-6">
                            <button
                                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                type="button"
                                on:click={() => {
                                    freshLogin('promoter');
                                }}
                            >
                                Sign In Fresh Promoter
                            </button>
                        </div>
                        <div class="text-center mt-6">
                            <button
                                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                type="button"
                                on:click={() => {
                                    freshLogin('podcast');
                                }}
                            >
                                Sign In Fresh Podcast
                            </button>
                        </div>

                        <div class="text-center mt-6">
                            <button
                                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                type="button"
                                on:click={() => {
                                    doLogout();
                                }}
                            >
                                Logout
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
