import {
    deleteAudioBacklogByBookingRequest,
    deleteAudioBacklogByCampaign,
    deleteBookingRequestById,
    deleteCampaignById,
    deleteCommitMessagesOfBookingRequest,
    deleteDownsampledImpressionsByDealId,
    deleteImpressionsByDealId,
    deleteInventoryBookingByCampaign,
    deleteInventoryReservationByCampaign,
    deleteKey,
    deleteReportBacklogByBookingRequest,
    deleteReportBacklogByCampaign,
    deleteScriptBacklogByBookingRequest,
    deleteScriptBacklogByCampaign,
    getBookingRequestsByCampaignIdCached,
} from '../src/api/api.js';

export function datePickerDateToDatabase(input) {
    return input + 'T00:00:00.000Z';
}

export function datePickerDateToColorCompare(input) {
    return input + 'T00:00:00+00:00';
}

export function unixTimestampToDatePicker(input) {
    if (input) {
        var date = new Date(input);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();

        day = day < 10 ? '0' + String(day) : day;
        month = month < 10 ? '0' + String(month) : month;

        return year + '-' + month + '-' + day;
    }
}

export function databaseToUnixTimestamp(input) {
    if (input) {
        return new Date(input).setHours(0, 0, 0, 0);
    }
}

export function getUnixTimestampsDailyTimerange(start, end) {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt).setHours(0, 0, 0, 0));
    }
    return arr;
}

export function getTimerangeStrings(start, end) {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt).toISOString().slice(0, 10));
    }
    return arr;
}

// cast returned database date to date pick date format
export function databaseToDatePickerDate(date) {
    if (date) {
        return date.slice(0, 10);
        // return date.slice(0, 16);
    }
}

// shift in minutes
export function getDateNow(shift = 0) {
    let date = new Date();
    let offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - (offset + shift) * 60 * 1000);
    date.setSeconds(0, 0);
    return date.toISOString().replace(/:00.000Z/, '');
}

export function getDateNowDatetime() {
    return new Date();
}

export function getDateMidnight(shift = 0) {
    let date = new Date();
    let offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - (offset + shift) * 60 * 1000);
    date.setHours(0, 0, 0, 0);
    return date.toISOString().replace(/:00.000Z/, '');
}

export function databaseToPrettyDate(date) {
    if (date) {
        let date2 = new Date(date.substr(0, 19) + 'Z');
        let month = date2.getMonth() + 1 < 10 ? '0' + (date2.getMonth() + 1) : date2.getMonth() + 1;
        let day2 = date2.getDate() < 10 ? '0' + date2.getDate() : date2.getDate();
        let year = date2.getFullYear();
        let date3 = day2 + '/' + month + '/' + year;
        return date3;
    }
}

export function databaseToPrettyDateTime(date) {
    if (date) {
        // let day = date.substr(0, 10);
        let day = databaseToPrettyDate(date);
        let time = date.substr(11, 8);
        return day + ' - ' + time;
    }
}

export function prettyPrintNumber(number) {
    if (number) {
        return number.toLocaleString('de-DE');
    } else {
        return '0';
    }
}
// export function prettyPrintNumber(number) {
//     return number.toLocaleString('de', {
//         notation: 'compact',
//         compactDisplay: 'short',
//     });
// }
// export function prettyPrintNumber(number) {
//     var nf = Intl.NumberFormat();
//     return nf.format(number);
// }

export function getDateDiff(date1, date2) {
    date1 = new Date(date1);
    date2 = new Date(date2);
    let diffTime = Math.abs(date2 - date1);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
}

export function addDaysToDateString(date, days) {
    let date2 = new Date(date);
    let date3 = date2.getTime() + daysToMilliseconds(days);
    let date4 = new Date(date3).toISOString();
    let date5 = databaseToPrettyDate(date4);
    return date5;
}

function daysToMilliseconds(days) {
    return days * 24 * 60 * 60 * 1000;
}

export function toggleButtonState(elem, buttonState, msg) {
    if (buttonState == 'disable') {
        elem.disabled = true;
        elem.innerText = msg;
        elem.className = 'disable text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150';
    }
    if (buttonState == 'enable') {
        elem.disabled = false;
        elem.innerText = msg;
        elem.className =
            'saveproceed bg-emerald-500 text-white active:bg-emerald-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150';
    }
}

export function copyToClipboard(value) {
    navigator.clipboard.writeText(value);
}

export const downloadFromUrl = (url, name) => {
    let n = url.lastIndexOf('/');
    let filename;

    if (name == '') {
        filename = url.substring(n + 1);
    } else {
        filename = name;
    }

    fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        })
        .catch((err) => {
            console.log('error whilst downloading from URL: ', err);
        });
};

//validate email format
export async function validateEmail(email) {
    var mailformat = /^\w+([+\.-]?\w+)*@\w+([+\.-]?\w+)*(\.*)+$/;

    if (email.match(mailformat)) {
        if (email.match(mailformat)[2].match(/\d+/g)) {
            return false;
        } else return true;
    } else {
        return false;
    }
}

export async function getHash(str, algo = 'SHA-256') {
    let strBuf = new TextEncoder().encode(str);
    return crypto.subtle.digest(algo, strBuf).then((hash) => {
        window.hash = hash;
        // here hash is an arrayBuffer,
        // -> connvert it to its hex version
        let result = '';
        const view = new DataView(hash);
        for (let i = 0; i < hash.byteLength; i += 4) {
            result += ('00000000' + view.getUint32(i).toString(16)).slice(-8);
        }
        console.log('result: ', result);
        return result;
    });
}

// purge campaign
export async function purgeCampaign(campaign) {
    document.getElementById('grid-button-purge-campaign-' + campaign.id).disabled = true;
    document.getElementById('grid-button-purge-campaign-' + campaign.id).innerText = 'Purging ...';

    try {
        await deleteInventoryReservationByCampaign(campaign.id);
    } catch (e) {
        console.log(`Error ${e} occoured whilst deleting the inventory reservations for campaign ${campaign.id}`);
    }

    try {
        await deleteInventoryBookingByCampaign(campaign.id);
    } catch (e) {
        console.log(`Error ${e} occoured whilst deleting the inventory reservations for campaign ${campaign.id}`);
    }

    let bookingRequests;
    try {
        bookingRequests = await getBookingRequestsByCampaignIdCached(campaign.id);
    } catch (e) {
        console.log(`Error ${e} occoured whilst getting the booking requests for campaign ${campaign.id}`);
    }

    if (bookingRequests) {
        for (let bR of bookingRequests) {
            // delete booking request meta data on digital ocean
            try {
                console.log('delete br commits');
                await deleteCommitMessagesOfBookingRequest(bR.id);
            } catch (e) {
                console.log(`Error ${e} occoured whilst deleting the commit messages of booking request ${bR.id}`);
            }

            if (bR.deal_id !== null) {
                try {
                    console.log('delete br impressions');
                    await deleteImpressionsByDealId(bR.deal_id);
                } catch (e) {
                    console.log(`Error ${e} occoured whilst deleting the impression track of booking request ${bR.id}`);
                }
                try {
                    console.log('delete br downsampled impressions');
                    await deleteDownsampledImpressionsByDealId(bR.deal_id);
                } catch (e) {
                    console.log(`Error ${e} occoured whilst deleting the downsampled impression tracks of booking request ${bR.id}`);
                }
            }

            try {
                console.log('delete br script backlog');
                await deleteScriptBacklogByBookingRequest(bR.id);
            } catch (e) {
                console.log(`Error ${e} occoured whilst deleting the script backlog of booking request ${bR.id}`);
            }

            try {
                console.log('delete br audio backlog');
                await deleteAudioBacklogByBookingRequest(bR.id);
            } catch (e) {
                console.log(`Error ${e} occoured whilst deleting the audio backlog of booking request ${bR.id}`);
            }

            try {
                console.log('delete br report backlog');
                await deleteReportBacklogByBookingRequest(bR.id);
            } catch (e) {
                console.log(`Error ${e} occoured whilst deleting the report backlog of booking request ${bR.id}`);
            }

            // delete booking request meta data on s3
            if (bR.deal_id) {
                try {
                    console.log('delete br vast');
                    await deleteKey('vast', bR.deal_id + '.xml');
                } catch (e) {
                    console.log(`Error ${e} occoured whilst deleting the VAST of booking request ${bR.id}`);
                }
            }

            if (bR.audiofile_url) {
                const regex = /audio\/(.*)/;
                // const regex = /amazonaws.com\/(.*)/;
                const matchResult = bR.audiofile_url.match(regex);
                try {
                    console.log('delete br audio');
                    await deleteKey('audio', matchResult[1]);
                } catch (e) {
                    console.log(`Error ${e} occoured whilst deleting the audio of booking request ${bR.id}`);
                }
            }
            if (bR.report) {
                const regex = /report\/(.*)/;
                const matchResult = bR.report.match(regex);
                try {
                    console.log('delete br report');
                    await deleteKey('report', matchResult[1]);
                } catch (e) {
                    console.log(`Error ${e} occoured whilst deleting the report of booking request ${bR.id}`);
                }
            }

            try {
                console.log(`delete br ${bR.id}`);
                await deleteBookingRequestById(bR.id);
            } catch (e) {
                console.log(`Error ${e} occoured whilst deleting the booking request ${bR.id}`);
            }
        }
    }

    // delete campaign meta on digitalOcean
    try {
        console.log('delete br script backlog');
        await deleteScriptBacklogByCampaign(campaign.id);
    } catch (e) {
        console.log(`Error ${e} occoured whilst deleting the script backlog of campaign ${campaign.id}`);
    }

    try {
        console.log('delete br audio backlog');
        await deleteAudioBacklogByCampaign(campaign.id);
    } catch (e) {
        console.log(`Error ${e} occoured whilst deleting the audio backlog of campaign ${campaign.id}`);
    }

    try {
        console.log('delete br report backlog');
        await deleteReportBacklogByCampaign(campaign.id);
    } catch (e) {
        console.log(`Error ${e} occoured whilst deleting the report backlog of campaign ${campaign.id}`);
    }

    // delete campaign meta data on s3
    if (campaign.audiofile_url) {
        const regex = /audio\/(.*)/;
        const matchResult = campaign.audiofile_url.match(regex);
        try {
            console.log('delete campaign audio');
            await deleteKey('briefing', matchResult[1]);
        } catch (e) {
            console.log(`Error ${e} occoured whilst deleting the audio of campaign ${campaign.id}`);
        }
    }
    if (campaign.hear_feel_url) {
        const regex = /briefing\/(.*)/;
        const matchResult = campaign.hear_feel_url.match(regex);
        try {
            console.log('delete campaign hear_feel_url');
            await deleteKey('briefing', matchResult[1]);
        } catch (e) {
            console.log(`Error ${e} occoured whilst deleting the briefing material of campaign ${campaign.id}`);
        }
    }
    if (campaign.sound_effects_url) {
        const regex = /briefing\/(.*)/;
        const matchResult = campaign.sound_effects_url.match(regex);
        try {
            console.log('delete campaign sound_effects_url');
            await deleteKey('briefing', matchResult[1]);
        } catch (e) {
            console.log(`Error ${e} occoured whilst deleting the briefing material of campaign ${campaign.id}`);
        }
    }
    if (campaign.briefing_url_sound_effects) {
        const regex = /briefing\/(.*)/;
        const matchResult = campaign.briefing_url_sound_effects.match(regex);
        try {
            console.log('delete campaign briefing_url_sound_effects');
            await deleteKey(matchResult[1]);
        } catch (e) {
            console.log(`Error ${e} occoured whilst deleting the briefing material of campaign ${campaign.id}`);
        }
    }

    try {
        console.log(`delete campaign ${campaign.title}`);
        await deleteCampaignById(campaign.id);
    } catch (e) {
        console.log(`Error ${e} occoured whilst deleting campagin ${campaign.id}`);
    }

    setTimeout(() => {
        document.getElementById('grid-button-purge-campaign-' + campaign.id).innerText = 'Purge';
        location.reload();
    }, 3000);
}
