<script>
    // core components
    import {Pulse} from 'svelte-loading-spinners';
    import {navigate} from 'svelte-routing';
    import {onMount} from 'svelte';

    import {selectedCampaign, selectedBookingRequest} from '../../store.js';
    import {getBookingRequestsByPodcastIdCached, getCampaignByIdCached} from '../../api/api.js';
    import {prettyPrintNumber, databaseToPrettyDateTime} from '../../functions.js';
    import {sortCountPerBookingRequest} from '../../filterBookingRequests.js';
    import {getText} from '../../language.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    // can be one of light or dark table layout
    export let color = 'light';
    export let podcast;

    let offeredImpressions = [];
    let offeredImpressionsOverview = [];

    const tableTitle = podcast.name;

    let counts = {todo: 0, waiting: 0, rejected: 0, cancelled: 0, upcoming: 0, running: 0, completed: 0, wrapped: 0};
    let bRs = {todo: [], waiting: [], rejected: [], cancelled: [], upcoming: [], running: [], completed: [], wrapped: []};

    onMount(() => {
        // reset selected campaign
        selectedCampaign.set({});
        selectedBookingRequest.set({});
    });

    // get all infos from the api
    async function collectData() {
        var bookingRequests = [];

        try {
            let all = await getBookingRequestsByPodcastIdCached(podcast.id);
            all.forEach((br) => {
                if (br.activated) {
                    bookingRequests.push(br);
                }
            });
        } catch (e) {
            console.log(`Error ${e} occoured whilst loading the booking requests of podcast ${podcast.id}`);
        }

        // check cancelled status
        if (bookingRequests) {
            for (let bookingRequest of bookingRequests) {
                // check if something is to be waited for
                let filtered = await sortCountPerBookingRequest(bookingRequest, counts, bRs);
                let c = filtered.counts;
                let b = filtered.bRs;

                for (let x in counts) {
                    counts.x += c.x;
                }

                b['cancelled'].forEach((bR) => {
                    if (!bRs['cancelled'].some((el) => el.id == bR.id)) {
                        bRs['cancelled'].push(bR);
                    }
                });

                // collect requested/offered impressions
                offeredImpressions[bookingRequest.campaign_id] = bookingRequest.offered_impressions ? bookingRequest.offered_impressions : bookingRequest.requested_impressions;
                offeredImpressionsOverview[bookingRequest.campaign_id] = bookingRequest.offered_impressions ? bookingRequest.offered_impressions : 0;
            }
        }

        return bRs['cancelled'];
    }

    async function collectCampaignData(campaignId) {
        try {
            return getCampaignByIdCached(campaignId);
        } catch (e) {
            console.log(`Error ${e} occoured whilst loading the campaign ${campaignId}`);
        }
    }

    const showBookingRequestDetails = async (bookingRequest) => {
        // store selected campaign
        selectedCampaign.set(await collectCampaignData(bookingRequest.campaign_id));
        selectedBookingRequest.set(bookingRequest);
        navigate(`/podcast/campaign/${$selectedCampaign.id}/booking-request/${bookingRequest.id}`, {replace: false});
    };

    var promiseData = collectData();
</script>

{#await promiseData}
    <div style="display: flex; justify-content: center">
        <Pulse size="60" color="#FF3E00" unit="px" duration="1s" />
    </div>
{:then data}
    {#if counts.cancelled > 0}
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">
            <div style="display: flex; justify-content: center" />
            <div class="rounded-t mb-0 px-4 py-3 border-0">
                <div class="flex flex-wrap items-center">
                    <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                        <h3 class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}">
                            {tableTitle}
                        </h3>
                    </div>
                </div>
            </div>

            <div class="block w-full overflow-x-auto">
                <table class="items-center w-full bg-transparent border-collapse">
                    <thead>
                        <tr>
                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-700 text-red-200 border-red-600'}"
                            >
                                {getText('podcast.booking_requests.cancelled.headline_1')}
                            </th>

                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-700 text-red-200 border-red-600'}"
                            >
                                {getText('podcast.booking_requests.cancelled.headline_2')}
                            </th>

                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-700 text-red-200 border-red-600'}"
                            >
                                {getText('podcast.booking_requests.cancelled.headline_3')}
                            </th>

                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-700 text-red-200 border-red-600'}"
                            >
                                {getText('podcast.booking_requests.cancelled.headline_4')}
                            </th>

                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-700 text-red-200 border-red-600'}"
                            >
                                {getText('podcast.booking_requests.cancelled.headline_5')}
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {#each data as bookingRequest (bookingRequest.id)}
                            {#if bookingRequest.state == 6}
                                <tr
                                    on:click={() => {
                                        showBookingRequestDetails(bookingRequest);
                                    }}
                                    class="hover:cell-highlight-color cursor-pointer"
                                >
                                    <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                        <i class="fas fa-times text-red-500" />
                                        <span class="ml-3 font-bold {color === 'light' ? 'btext-blueGray-600' : 'text-whit'}">{bookingRequest.sender}</span>
                                    </th>

                                    <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                        {#if bookingRequest.campaign.type == 'hostread'}
                                            <i class="fas fa-microphone text-blueGray-400" />
                                        {:else if bookingRequest.campaign.type == 'spot'}
                                            <i class="fas fa-play-circle text-blueGray-400" />
                                        {:else if bookingRequest.campaign.type == 'crosspromo'}
                                            <i class="fas fa-bullhorn text-blueGray-400" />
                                        {/if}
                                        {bookingRequest.campaign.title}
                                    </th>
                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                                    </td>
                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        <!-- Cancelled on {databaseToPrettyDateTime(bookingRequest.cancelled_date)} -->
                                        {getText('podcast.booking_requests.todo.rows.next_step_14', databaseToPrettyDateTime(bookingRequest.cancelled_date))}
                                    </td>

                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        <CardButton id="info" type="icon" icon="fas fa-info-circle mr-2 text-sm" on:click={() => showBookingRequestDetails(bookingRequest)} />
                                    </td>
                                </tr>
                            {/if}
                        {/each}
                    </tbody>
                </table>
            </div>
        </div>
    {/if}
{/await}
