<script>
    import {BADGE_BACKGROUND_DEFAULT, BADGE_BORDER_COLOR_DEFAULT, BADGE_TEXT_COLOR_DEFAULT} from '../../colors.js';

    export let id = '';
    export let text;
    export let backgroundColor = BADGE_BACKGROUND_DEFAULT;
    export let borderColor = BADGE_BORDER_COLOR_DEFAULT;
    export let textColor = BADGE_TEXT_COLOR_DEFAULT;
</script>

<span class="text-xs font-semibold inline-block py-1 px-2 mb-2 uppercase rounded {borderColor} {textColor} {backgroundColor} uppercase last:mr-0 mr-1" id="grid-badge-tag-{id}">
    {text}
</span>
