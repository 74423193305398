<script context="module">
    import {get} from 'svelte/store';
    import {getAllPodcastsOfCompanyCached, getBookingRequestsByPodcastIdCached, getCampaignsByCompanyCached} from '../../api/api.js';
    import {selectedCampaign, storedCompany, todosPodcast, waitingPodcast, upcomingPodcast, runningPodcast, completedPodcast, wrappedPodcast, rejectedPodcast, cancelledPodcast} from '../../store.js';
    import {sortCountPerBookingRequest} from '../../filterBookingRequests.js';
    import {triggerCampaignRenderTotal, triggerCampaignRenderTodo} from '../../badgeManager.svelte';

    let counts = {todo: 0, waiting: 0, rejected: 0, cancelled: 0, upcoming: 0, running: 0, completed: 0, wrapped: 0, missedDeadline: 0};
    let bRs = {todo: [], waiting: [], rejected: [], cancelled: [], upcoming: [], running: [], completed: [], wrapped: [], missedDeadline: []};

    export async function collectPodcastData(source) {
        let companyId = get(storedCompany).id;

        if (
            counts.todo > 0 ||
            counts.waiting > 0 ||
            counts.upcoming > 0 ||
            counts.running > 0 ||
            counts.completed > 0 ||
            counts.wrapped > 0 ||
            counts.rejected > 0 ||
            counts.cancelled > 0 ||
            counts.missedDeadline > 0
        ) {
            counts.todo = 0;
            counts.waiting = 0;
            counts.upcoming = 0;
            counts.running = 0;
            counts.completed = 0;
            counts.wrapped = 0;
            counts.rejected = 0;
            counts.cancelled = 0;
            counts.missedDeadline = 0;
        }

        let allPodcasts;

        try {
            allPodcasts = await getAllPodcastsOfCompanyCached(companyId);

            // if (allPodcasts) {
            var bookingRequests = [];
            for (let podcast of allPodcasts) {
                bookingRequests = [];
                // get bookingRequests per podcast
                let all = await getBookingRequestsByPodcastIdCached(podcast.id);
                all.forEach((br) => {
                    if (br.activated) {
                        bookingRequests.push(br);
                    }
                });

                if (bookingRequests) {
                    for (let bookingRequest of bookingRequests) {
                        // check category of booking request
                        await sortCountPerBookingRequest(bookingRequest, counts, bRs);
                    }
                }
                // }
            }
        } catch (e) {
            console.log(`Error ${e} occoured whilst loading podcasts of company ${companyId}`);
        }

        // set storage variables for side bar counts
        todosPodcast.set(counts.todo);
        waitingPodcast.set(counts.waiting);
        upcomingPodcast.set(counts.upcoming);
        runningPodcast.set(counts.running);
        completedPodcast.set(counts.completed);
        wrappedPodcast.set(counts.wrapped);
        rejectedPodcast.set(counts.rejected + counts.missedDeadline);
        cancelledPodcast.set(counts.cancelled);

        // count crosspromo campaigns for sidebar badge
        if (source != 'crosspromos') {
            let camps = await getCampaignsByCompanyCached(companyId);
            triggerCampaignRenderTotal(camps);
            triggerCampaignRenderTodo();
        }

        return allPodcasts;
    }
</script>

<script>
    // core components
    import CardTableToDoPodcast from 'components/Cards/CardTableToDoPodcast.svelte';
    import CardTableWaitingPodcast from 'components/Cards/CardTableWaitingPodcast.svelte';
    import CardTableUpcoming from 'components/Cards/CardTableUpcoming.svelte';
    import CardTableRunning from 'components/Cards/CardTableRunning.svelte';
    import CardTableRejectedBR from 'components/Cards/CardTableRejectedBR.svelte';
    import CardTableCancelledBR from 'components/Cards/CardTableCancelledBR.svelte';
    import CardTableCompleted from 'components/Cards/CardTableCompleted.svelte';
    import CardTableWrapped from 'components/Cards/CardTableWrapped.svelte';
    import {Pulse} from 'svelte-loading-spinners';
    import {getText} from '../../language.svelte';
    import {onMount} from 'svelte';
    import {getPodcastCompanyExclusives} from '../../api/api.js';

    // reset store
    selectedCampaign.set({});
    export let color = 'light';
    export let card;
    var promisePodcastData = collectPodcastData();
    let allPodcasts;
    let showExclusiveBar = false;

    onMount(async () => {
        allPodcasts = await getAllPodcastsOfCompanyCached($storedCompany.id);
        collectExclusive();
    });

    async function collectExclusive() {
        // check if an unanswered exclusive promotion request exists for any podcast of company
        for (let podcast of allPodcasts) {
            const exclusive = await getPodcastCompanyExclusives(podcast.id);
            if (!exclusive[0].confirmed) {
                showExclusiveBar = true;
                return;
            } else {
                showExclusiveBar = false;
            }
        }
    }
</script>

{#await promisePodcastData}
    <div style="display: flex; justify-content: center">
        <Pulse size="60" color="#FF3E00" unit="px" duration="1s" />
    </div>
{:then data}
    {#if card == 'todo'}
        {#if counts.todo == 0 && !showExclusiveBar}
            <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">
                <div style="display: flex; justify-content: center" />
                <div class="rounded-t mb-0 px-4 py-3 border-0">
                    <div class="flex flex-wrap items-center">
                        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}">{getText('podcast.booking_requests.todo.empty_status')}</h3>
                        </div>
                    </div>
                </div>
            </div>
        {:else}
            {#each data as podcast}
                <div class="flex flex-wrap mt-4">
                    <div class="w-full mb-12 px-4">
                        <CardTableToDoPodcast {podcast} />
                    </div>
                </div>
            {/each}
        {/if}
    {/if}
    {#if card == 'waiting'}
        {#if counts.waiting == 0}
            <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">
                <div style="display: flex; justify-content: center" />
                <div class="rounded-t mb-0 px-4 py-3 border-0">
                    <div class="flex flex-wrap items-center">
                        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}">{getText('podcast.booking_requests.waiting.empty_status')}</h3>
                        </div>
                    </div>
                </div>
            </div>
        {:else}
            {#each data as podcast}
                <div class="flex flex-wrap mt-4">
                    <div class="w-full mb-12 px-4">
                        <CardTableWaitingPodcast {podcast} />
                    </div>
                </div>
            {/each}
        {/if}
    {/if}
    {#if card == 'upcoming'}
        {#if counts.upcoming == 0}
            <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">
                <div style="display: flex; justify-content: center" />
                <div class="rounded-t mb-0 px-4 py-3 border-0">
                    <div class="flex flex-wrap items-center">
                        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}">{getText('podcast.booking_requests.upcoming.empty_status')}</h3>
                        </div>
                    </div>
                </div>
            </div>
        {:else}
            {#each data as podcast}
                <div class="flex flex-wrap mt-4">
                    <div class="w-full mb-12 px-4">
                        <CardTableUpcoming {podcast} />
                    </div>
                </div>
            {/each}
        {/if}
    {/if}
    {#if card == 'running'}
        {#if counts.running == 0}
            <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">
                <div style="display: flex; justify-content: center" />
                <div class="rounded-t mb-0 px-4 py-3 border-0">
                    <div class="flex flex-wrap items-center">
                        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}">{getText('podcast.booking_requests.running.empty_status')}</h3>
                        </div>
                    </div>
                </div>
            </div>
        {:else}
            {#each data as podcast}
                <div class="flex flex-wrap mt-4">
                    <div class="w-full mb-12 px-4">
                        <CardTableRunning {podcast} />
                    </div>
                </div>
            {/each}
        {/if}
    {/if}
    {#if card == 'completed'}
        {#if counts.completed == 0}
            <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">
                <div style="display: flex; justify-content: center" />
                <div class="rounded-t mb-0 px-4 py-3 border-0">
                    <div class="flex flex-wrap items-center">
                        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}">{getText('podcast.booking_requests.completed.empty_status')}</h3>
                        </div>
                    </div>
                </div>
            </div>
        {:else}
            {#each data as podcast}
                <div class="flex flex-wrap mt-4">
                    <div class="w-full mb-12 px-4">
                        <CardTableCompleted {podcast} />
                    </div>
                </div>
            {/each}
        {/if}
    {/if}
    {#if card == 'wrapped'}
        {#if counts.wrapped == 0}
            <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">
                <div style="display: flex; justify-content: center" />
                <div class="rounded-t mb-0 px-4 py-3 border-0">
                    <div class="flex flex-wrap items-center">
                        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}">{getText('podcast.booking_requests.wrapped.empty_status')}</h3>
                        </div>
                    </div>
                </div>
            </div>
        {:else}
            {#each data as podcast}
                <div class="flex flex-wrap mt-4">
                    <div class="w-full mb-12 px-4">
                        <CardTableWrapped {podcast} />
                    </div>
                </div>
            {/each}
        {/if}
    {/if}
    {#if card == 'rejected'}
        <!-- {#if counts.rejected == 0} -->
        {#if counts.rejected + counts.missedDeadline == 0}
            <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">
                <div style="display: flex; justify-content: center" />
                <div class="rounded-t mb-0 px-4 py-3 border-0">
                    <div class="flex flex-wrap items-center">
                        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}">{getText('podcast.booking_requests.rejected.empty_status')}</h3>
                        </div>
                    </div>
                </div>
            </div>
        {:else}
            {#each data as podcast}
                <div class="flex flex-wrap mt-4">
                    <div class="w-full mb-12 px-4">
                        <CardTableRejectedBR {podcast} />
                    </div>
                </div>
            {/each}
        {/if}
    {/if}
    {#if card == 'cancelled'}
        {#if counts.cancelled == 0}
            <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">
                <div style="display: flex; justify-content: center" />
                <div class="rounded-t mb-0 px-4 py-3 border-0">
                    <div class="flex flex-wrap items-center">
                        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}">{getText('podcast.booking_requests.cancelled.empty_status')}</h3>
                        </div>
                    </div>
                </div>
            </div>
        {:else}
            {#each data as podcast}
                <div class="flex flex-wrap mt-4">
                    <div class="w-full mb-12 px-4">
                        <CardTableCancelledBR {podcast} />
                    </div>
                </div>
            {/each}
        {/if}
    {/if}
{/await}
