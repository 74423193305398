import {get} from 'svelte/store';
import {getCampaignByIdCached} from './api/api.js';
import {missingLinkId} from './store.js';

async function collectCampaignData(campaignId) {
    return getCampaignByIdCached(campaignId);
}

export async function sortCountPerBookingRequest(bookingRequest, counts, bRs) {
    let bRs2 = {...bRs};
    // get campaign data
    let campaign = await collectCampaignData(bookingRequest.campaign_id);
    bookingRequest.campaign = campaign;

    // check if something is todo
    if (
        bookingRequest.state == 2 ||
        (bookingRequest.state == 6 && !bookingRequest.archived) ||
        // missing link sonderlocke
        // (bookingRequest.state == 8 && !bookingRequest.ad_created) ||
        (bookingRequest.state == 8 && !bookingRequest.ad_created && campaign.company_id != get(missingLinkId)) ||
        bookingRequest.state == 10 ||
        ((bookingRequest.campaign.type == 'spot' || bookingRequest.campaign.type == 'crosspromo') && bookingRequest.state == 3 && bookingRequest.booking_changes_requested) ||
        // hostread / feedbackloops
        (campaign.feedback_loop_none && bookingRequest.campaign.type == 'hostread' && bookingRequest.state == 3 && bookingRequest.booking_changes_requested) ||
        (bookingRequest.campaign.type == 'hostread' && bookingRequest.state == 5 && bookingRequest.campaign.briefing_completed && bookingRequest.script_updated_promoter) ||
        (bookingRequest.campaign.type == 'hostread' && bookingRequest.state == 5 && bookingRequest.script_approved_date && !bookingRequest.audiofile_url_approved) ||
        (bookingRequest.campaign.type == 'hostread' && bookingRequest.state == 5 && bookingRequest.script_approved_date && bookingRequest.audiofile_url_approved && !campaign.feedback_loop_audio) ||
        (bookingRequest.state == 3 && bookingRequest.booking_changes_requested) ||
        (bookingRequest.state == 5 && campaign.briefing_completed && bookingRequest.script_updated_promoter && !bookingRequest.script_approved_date) ||
        (bookingRequest.state == 5 && campaign.briefing_completed && bookingRequest.script_approved_date && !bookingRequest.audiofile_url)
    ) {
        bRs2.todo = [...bRs2.todo, bookingRequest];
        counts.todo += 1;
    }

    // check for pending
    if (
        (bookingRequest.campaign.type == 'hostread' && bookingRequest.state == 3 && !bookingRequest.campaign.briefing_completed && !bookingRequest.booking_changes_requested) ||
        ((bookingRequest.campaign.type == 'spot' || bookingRequest.campaign.type == 'crosspromo' || bookingRequest.campaign.type == 'hostread') &&
            bookingRequest.state == 3 &&
            !bookingRequest.booking_changes_requested) ||
        (bookingRequest.state == 3 && bookingRequest.campaign.briefing_completed && bookingRequest.script_updated_podcast && !bookingRequest.script_approved_date) ||
        (bookingRequest.campaign.type == 'hostread' && bookingRequest.state == 3 && bookingRequest.script_approved_date && bookingRequest.audiofile_url_approved && campaign.feedback_loop_audio) ||
        (bookingRequest.state == 5 && !campaign.briefing_completed) ||
        (bookingRequest.state == 5 && campaign.briefing_completed && campaign.feedback_loop_script && bookingRequest.script_updated_podcast && !bookingRequest.script_approved_date) ||
        (bookingRequest.campaign.type == 'hostread' && bookingRequest.state == 7 && bookingRequest.campaign.feedback_loop_audio && !bookingRequest.audiofile_url_approved) ||
        (bookingRequest.campaign.type == 'hostread' && bookingRequest.state == 7 && !bookingRequest.campaign.feedback_loop_none)
        // feedbackloops
    ) {
        bRs2.waiting = [...bRs2.waiting, bookingRequest];
        counts.waiting += 1;
    }

    // check for upcoming BRs
    // missing link sonderlocke
    if ((bookingRequest.state == 8 && bookingRequest.ad_created) || (bookingRequest.state == 8 && campaign.company_id == get(missingLinkId))) {
        bRs2.upcoming = [...bRs2.upcoming, bookingRequest];
        counts.upcoming += 1;
    }

    // check for running BRs
    if (bookingRequest.state == 9) {
        bRs2.running = [...bRs2.running, bookingRequest];
        counts.running += 1;
    }

    // check for completed BRs
    if (bookingRequest.state == 11 && bookingRequest.report) {
        bRs2.completed = [...bRs2.completed, bookingRequest];
        counts.completed += 1;
    }

    // check for wrapped BRs
    if (bookingRequest.state == 12) {
        bRs2.wrapped = [...bRs2.wrapped, bookingRequest];
        counts.wrapped += 1;
    }

    // check for rejected
    if (bookingRequest.state == 4) {
        bRs2.rejected = [...bRs2.rejected, bookingRequest];
        counts.rejected += 1;
    }

    // check for cancelled
    if (bookingRequest.state == 6) {
        bRs2.cancelled = [...bRs2.cancelled, bookingRequest];
        counts.cancelled += 1;
    }

    // check for missed deadline
    if (bookingRequest.state == 14) {
        bRs.missedDeadline = [bRs2.missedDeadline, bookingRequest];
        counts.rejected += 1;
    }

    return {counts: counts, bRs: bRs2};
}
