<script>
    import {databaseToPrettyDateTime} from '../../functions.js';
    import {BADGE_BACKGROUND_WORDCOUNT} from '../../colors.js';
    import {getText} from '../../language.svelte';
    import CardScriptVersionControl from 'components/Cards/CardScriptVersionControl.svelte';
    import CardBadgeTag from 'components/Cards/CardBadgeTag.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let bookingRequest;
    export let campaign;
    export let script;
    export let updateScript;
    export let downloadFromUrl;
    export let backgroundColor = 'bg-blueGray-200';
    let showScriptControl = false;

    $: script;

    function detailsScript() {
        showScriptControl = true;
    }
</script>

{#if showScriptControl}
    <CardScriptVersionControl {bookingRequest} {campaign} bind:showScriptControl />
{/if}

{#if campaign.type == 'hostread' && campaign.briefing_completed && bookingRequest.state >= 5 && bookingRequest.state != 14}
    <form>
        <div class="{backgroundColor} flex-auto mt-1 pt-1 px-4 lg:px-10 py-10 pt-0">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {getText('podcast.booking_request_details.briefing_information.title')}
            </h6>
            <div class="flex flex-wrap">
                <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-script">
                            {getText('podcast.booking_request_details.briefing_information.category_1.title')}
                            <CardButton id="show-script-control" type="icon" title="Script Version Control" icon="fas fa-file-alt mr-2 text-sm" on:click={() => detailsScript(bookingRequest)} />
                        </label>
                        {#if (!bookingRequest.approved_date && !bookingRequest.deal_id && !bookingRequest.script_approved_date) || (campaign.feedback_loop_none && !bookingRequest.approved && bookingRequest.state <= 8) || (campaign.feedback_loop_audio && !bookingRequest.approved && !bookingRequest.script_approved_date && (!bookingRequest.state == 6 || bookingRequest.state == 4))}
                            <textarea
                                id="grid-script"
                                type="input"
                                class="mono border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                rows="4"
                                bind:value={script}
                            />

                            <div class="flex flex-wrap">
                                <p
                                    id="grid-script-badges"
                                    class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                >
                                    <CardBadgeTag text="{script.split(' ').join('').length} {getText('badges.text_editor.entry_1')}" backgroundColor={BADGE_BACKGROUND_WORDCOUNT} />
                                    <CardBadgeTag text="{script.split(' ').length} {getText('badges.text_editor.entry_2')}" backgroundColor={BADGE_BACKGROUND_WORDCOUNT} />
                                </p>
                                {#if campaign.feedback_loop_script && bookingRequest.script_approved_date}
                                    <CardBadgeTag text={getText('badges.text_editor.entry_3', databaseToPrettyDateTime(bookingRequest.script_approved_date))} />
                                    <CardBadgeTag text="{getText('badges.general.entry_1')}{bookingRequest.script_editor_user}" />
                                {:else if campaign.feedback_loop_none || bookingRequest.script_updated_date}
                                    <CardBadgeTag text={getText('badges.text_editor.entry_4', databaseToPrettyDateTime(bookingRequest.script_updated_date))} />
                                    <CardBadgeTag text="{getText('badges.general.entry_1')}{bookingRequest.script_editor_user}" />
                                {/if}
                            </div>
                        {:else}
                            <p
                                id="grid-script"
                                class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            >
                                {bookingRequest.script}
                            </p>

                            <div class="flex flex-wrap">
                                <p
                                    id="grid-script-badges"
                                    class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                >
                                    <CardBadgeTag text="{bookingRequest.script.split(' ').join('').length} {getText('badges.text_editor.entry_1')}" backgroundColor={BADGE_BACKGROUND_WORDCOUNT} />
                                    <CardBadgeTag text="{bookingRequest.script.split(' ').length} {getText('badges.text_editor.entry_2')}" backgroundColor={BADGE_BACKGROUND_WORDCOUNT} />
                                </p>
                                {#if campaign.feedback_loop_script && bookingRequest.script_approved_date}
                                    <CardBadgeTag text={getText('badges.text_editor.entry_3', databaseToPrettyDateTime(bookingRequest.script_approved_date))} />
                                    <CardBadgeTag text="{getText('badges.general.entry_1')}{bookingRequest.script_editor_user}" />
                                {:else if campaign.feedback_loop_none || bookingRequest.script_updated_date}
                                    <CardBadgeTag text={getText('badges.text_editor.entry_4', databaseToPrettyDateTime(bookingRequest.script_updated_date))} />
                                    <CardBadgeTag text="{getText('badges.general.entry_1')}{bookingRequest.script_editor_user}" />
                                {/if}
                            </div>
                        {/if}
                    </div>

                    {#if script !== bookingRequest.script}
                        <div class="text-center flex justify-end">
                            <CardButton id="update" type="go" text={getText('podcast.booking_request_details.briefing_information.category_1.button_update')} on:click={() => updateScript()} />
                        </div>
                        <!-- {:else if bookingRequest.script_updated_promoter && !bookingRequest.script_approved_date}
                                <div class="text-center flex justify-end">
                                    <button
                                        class="bg-emerald-500 active:bg-blueGray-600 text-white font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        on:click={approveScript}
                                    >
                                        Approve this version
                                    </button>
                                    <button
                                        class="bg-red-500 active:bg-blueGray-600 text-white font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        on:click={rejectPodcastBookingRequest}
                                    >
                                        Reject this version & cancel booking
                                    </button>
                                </div> -->
                    {/if}
                </div>
                {#if campaign.background_information}
                    <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-background-information">
                                {getText('podcast.booking_request_details.briefing_information.category_2.title')}
                            </label>
                            <p
                                id="grid-background-information"
                                class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            >
                                {campaign.background_information}
                            </p>
                        </div>
                    </div>
                {/if}
                {#if campaign.goal}
                    <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-background-information">
                                {getText('podcast.booking_request_details.briefing_information.category_3.title')}
                            </label>
                            <p
                                id="grid-background-information"
                                class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            >
                                {campaign.goal}
                            </p>
                        </div>
                    </div>
                {/if}
                {#if campaign.audio_keywords}
                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-emerald-600 text-xs font-bold mb-2" for="grid-audio-keywords">
                                {getText('podcast.booking_request_details.briefing_information.category_4.title')}
                            </label>
                            {#each campaign.audio_keywords.split(',') as key}
                                <span class="bg-emerald-400 text-white font-bold uppercase text-xs px-4 mr-1" id="grid-audio-key-tag-{key}" disabled>
                                    {key}
                                </span>
                            {/each}
                        </div>
                    </div>
                {/if}
                {#if campaign.excluded_keywords}
                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-red-600 text-xs font-bold mb-2" for="grid-excluded-keywords">
                                {getText('podcast.booking_request_details.briefing_information.category_5.title')}
                            </label>
                            {#each campaign.excluded_keywords.split(',') as key}
                                <span class="bg-red-300 text-white font-bold uppercase text-xs px-4 mr-1" id="grid-audio-key-tag-{key}" disabled>
                                    <!-- <span class="bg-red-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow outline-none mr-1" id="grid-audio-key-tag-{key}" disabled> -->
                                    {key}
                                </span>
                            {/each}
                        </div>
                    </div>
                {/if}
                {#if campaign.briefing_url}
                    <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3 mt-5">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-briefing-url">
                                    {getText('podcast.booking_request_details.briefing_information.category_6.title')}
                                </label>
                                <p
                                    id="grid-background-information"
                                    class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                >
                                    {campaign.briefing_url}
                                </p>
                            </div>
                        </div>
                    </div>
                {/if}
                {#if campaign.hear_feel_url}
                    <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-briefing-url">
                                    {getText('podcast.booking_request_details.briefing_information.category_7.title')}
                                </label>
                                <p
                                    id="grid-background-information"
                                    class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                >
                                    {campaign.hear_feel_name_original} /
                                    <a class="underline" target="_blank" href={campaign.hear_feel_url}>{campaign.hear_feel_url}</a>
                                    <i
                                        class="fas fa-download active:color-emerald-600"
                                        on:click={() => {
                                            downloadFromUrl(campaign.hear_feel_url, campaign.hear_feel_name_original);
                                        }}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                {/if}
                {#if campaign.sound_effects_url}
                    <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-briefing-url">
                                    {getText('podcast.booking_request_details.briefing_information.category_8.title')}
                                </label>
                                <p
                                    id="grid-background-information"
                                    class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                >
                                    {campaign.sound_effects_name_original} /
                                    <a class="underline" target="_blank" href={campaign.sound_effects_url}>{campaign.sound_effects_url}</a>
                                    <i
                                        class="fas fa-download active:color-emerald-600"
                                        on:click={() => {
                                            downloadFromUrl(campaign.sound_effects_url, campaign.sound_effects_name_original);
                                        }}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                {/if}
                {#if campaign.briefing_url_sound_effects}
                    <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-briefing-url">
                                    {getText('podcast.booking_request_details.briefing_information.category_9.title')}
                                </label>
                                <p
                                    id="grid-background-information"
                                    class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                >
                                    <a class="underline" target="_blank" href={campaign.briefing_url_sound_effects}>{campaign.briefing_url_sound_effects}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                {/if}
                {#if campaign.briefing_supplementary_url}
                    <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-briefing-url">
                                    {getText('podcast.booking_request_details.briefing_information.category_10.title')}
                                </label>
                                <p
                                    id="grid-background-information"
                                    class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                >
                                    <a class="underline" target="_blank" href={campaign.briefing_supplementary_url}>{campaign.briefing_supplementary_url}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                {/if}
            </div>
        </div>
    </form>
{/if}

{#if campaign.type == 'hostread' && campaign.briefing_completed && bookingRequest.state == 2}
    <form>
        <div class="{backgroundColor} flex-auto mt-1 pt-1 px-4 lg:px-10 py-10 pt-0">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {getText('podcast.booking_request_details.briefing_information.title')}
            </h6>
            <div class="flex flex-wrap">
                <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-script">
                            {getText('podcast.booking_request_details.briefing_information.category_1.title')}
                            <CardButton id="show-script-control" type="icon" title="Script Version Control" icon="fas fa-file-alt mr-2 text-sm" on:click={() => detailsScript(bookingRequest)} />
                        </label>

                        <p
                            id="grid-script"
                            class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        >
                            {bookingRequest.script}
                        </p>

                        <div class="flex flex-wrap">
                            <p
                                id="grid-script-badges"
                                class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            >
                                <CardBadgeTag text="{bookingRequest.script.split(' ').join('').length} {getText('badges.text_editor.entry_1')}" backgroundColor={BADGE_BACKGROUND_WORDCOUNT} />
                                <CardBadgeTag text="{bookingRequest.script.split(' ').length} {getText('badges.text_editor.entry_2')}" backgroundColor={BADGE_BACKGROUND_WORDCOUNT} />
                            </p>
                            {#if bookingRequest.script_updated_date}
                                <CardBadgeTag text={getText('badges.text_editor.entry_4', databaseToPrettyDateTime(bookingRequest.script_updated_date))} />
                                <CardBadgeTag text="{getText('badges.general.entry_1')}{bookingRequest.script_editor_user}" />
                            {/if}
                        </div>
                    </div>
                </div>
                {#if campaign.background_information}
                    <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-background-information">
                                {getText('podcast.booking_request_details.briefing_information.category_2.title')}
                            </label>
                            <p
                                id="grid-background-information"
                                class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            >
                                {campaign.background_information}
                            </p>
                        </div>
                    </div>
                {/if}
                {#if campaign.goal}
                    <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-background-information">
                                {getText('podcast.booking_request_details.briefing_information.category_3.title')}
                            </label>
                            <p
                                id="grid-background-information"
                                class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            >
                                {campaign.goal}
                            </p>
                        </div>
                    </div>
                {/if}
                {#if campaign.audio_keywords}
                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-emerald-600 text-xs font-bold mb-2" for="grid-audio-keywords">
                                {getText('podcast.booking_request_details.briefing_information.category_4.title')}
                            </label>
                            {#each campaign.audio_keywords.split(',') as key}
                                <span class="bg-emerald-400 text-white font-bold uppercase text-xs px-4 mr-1" id="grid-audio-key-tag-{key}" disabled>
                                    <!-- <span class="bg-emerald-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow outline-none mr-1" id="grid-audio-key-tag-{key}" disabled> -->
                                    {key}
                                </span>
                            {/each}
                        </div>
                    </div>
                {/if}
                {#if campaign.excluded_keywords}
                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-red-600 text-xs font-bold mb-2" for="grid-excluded-keywords">
                                {getText('podcast.booking_request_details.briefing_information.category_5.title')}
                            </label>
                            {#each campaign.excluded_keywords.split(',') as key}
                                <span class="bg-red-300 text-white font-bold uppercase text-xs px-4 mr-1" id="grid-audio-key-tag-{key}" disabled>
                                    <!-- <span class="bg-red-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow outline-none mr-1" id="grid-audio-key-tag-{key}" disabled> -->
                                    {key}
                                </span>
                            {/each}
                        </div>
                    </div>
                {/if}
                {#if campaign.briefing_url}
                    <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3 mt-5">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-briefing-url">
                                    {getText('podcast.booking_request_details.briefing_information.category_6.title')}
                                </label>
                                <p
                                    id="grid-background-information"
                                    class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                >
                                    {campaign.briefing_url}
                                </p>
                            </div>
                        </div>
                    </div>
                {/if}
                {#if campaign.hear_feel_url}
                    <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-briefing-url">
                                    {getText('podcast.booking_request_details.briefing_information.category_7.title')}
                                </label>
                                <p
                                    id="grid-background-information"
                                    class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                >
                                    {campaign.hear_feel_name_original} /
                                    <a class="underline" target="_blank" href={campaign.hear_feel_url}>{campaign.hear_feel_url}</a>
                                    <i
                                        class="fas fa-download active:color-emerald-600"
                                        on:click={() => {
                                            downloadFromUrl(campaign.hear_feel_url, campaign.hear_feel_name_original);
                                        }}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                {/if}
                {#if campaign.sound_effects_url}
                    <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-briefing-url">
                                    {getText('podcast.booking_request_details.briefing_information.category_8.title')}
                                </label>
                                <p
                                    id="grid-background-information"
                                    class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                >
                                    {campaign.sound_effects_name_original} /
                                    <a class="underline" target="_blank" href={campaign.sound_effects_url}>{campaign.sound_effects_url}</a>
                                    <i
                                        class="fas fa-download active:color-emerald-600"
                                        on:click={() => {
                                            downloadFromUrl(campaign.sound_effects_url, campaign.sound_effects_name_original);
                                        }}
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                {/if}
                {#if campaign.briefing_url_sound_effects}
                    <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-briefing-url">
                                    {getText('podcast.booking_request_details.briefing_information.category_9.title')}
                                </label>
                                <p
                                    id="grid-background-information"
                                    class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                >
                                    <a class="underline" target="_blank" href={campaign.briefing_url_sound_effects}>{campaign.briefing_url_sound_effects}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                {/if}
                {#if campaign.briefing_supplementary_url}
                    <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-briefing-url">
                                    {getText('podcast.booking_request_details.briefing_information.category_10.title')}
                                </label>
                                <p
                                    id="grid-background-information"
                                    class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                >
                                    <a class="underline" target="_blank" href={campaign.briefing_supplementary_url}>{campaign.briefing_supplementary_url}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                {/if}
            </div>
        </div>
    </form>
{/if}
