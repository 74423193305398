<script>
    // library for creating dropdown menu appear on click
    import {navigate} from 'svelte-routing';
    import {Accordion, AccordionItem} from 'svelte-collapsible';
    import {Pulse} from 'svelte-loading-spinners';
    import {get} from 'svelte/store';
    import CardButton from 'components/Cards/CardButton.svelte';

    import {storedUser, campaignEdited, targetingEdited, selectedCampaign, storedCompany, newCampaignTargetImpressions, showModal, alertOpen, alertSingleOpen, missingLinkId} from '../../store.js';
    import {
        getCompanyPodcastExclusives,
        saveInventoryReservation,
        getCampaignByIdCached,
        postCommitMessage,
        getAllPodcastsOfCompanyCached,
        uploadFile,
        addCampaign,
        postBookingRequest,
        patchBookingRequest,
    } from '../../api/api.js';
    import {getDateMidnight, databaseToDatePickerDate, getDateNow, datePickerDateToDatabase, toggleButtonState} from '../../functions.js';
    import CardCampaignTargeting from 'components/Cards/CardCampaignTargeting.svelte';
    import ModalSmall from 'components/Modals/ModalSmall.svelte';
    import AlertMultiLine from 'components/Alerts/AlertMultiLine.svelte';
    import AlertOneLine from 'components/Alerts/AlertOneLine.svelte';
    import SnackbarNoDismiss from 'components/Snackbars/SnackbarNoDismiss.svelte';
    import CardCommitMessage from 'components/Cards/CardCommitMessage.svelte';
    import {getText} from '../../language.svelte';
    import CardTooltip from './CardTooltip.svelte';
    import {setCampaignState, setBookingRequestState} from '../../stateSetter.js';

    // VARS
    export let id;
    export let ctype;

    let campaign = {};
    let company = get(storedCompany);
    let renderingMode;

    // UI text
    let modalMsg;
    let modalTitle;
    let alertMsg;
    let alertTitle;
    let snackbarMsg;
    let snackbarOpen;

    let title;
    let hostreadDurationMs;
    let feedbackLoopScript = false;
    let feedbackLoopAudio = false;
    let feedbackLoopNone = false;
    // default to plus 7 days
    let acceptanceDeadline = databaseToDatePickerDate(getDateMidnight(-10080));
    let uploadDeadline;
    let startDate = databaseToDatePickerDate(getDateMidnight(-20160));
    let endDate = databaseToDatePickerDate(getDateMidnight(-30240));
    // var endDate = '2022-02-02T11:55'; // example time stamp
    let script;
    let background_information;
    let audioKeywords;
    let excludedKeywords;
    let briefingURL;
    let briefingSupplementaryURL;
    let briefingURLSoundEffects;
    let goal;
    let targetImpressions;
    newCampaignTargetImpressions.subscribe((value) => {
        targetImpressions = value;
    });

    let campaignTargeting;
    let campaignTargetingStatus;
    let audiofileUrl;
    let audiofile_uploaded_date;
    let audiofile_duration_ms;
    let audioUploadedSucess = false;
    let hearFeelUploadedSucess = false;
    let soundEffectsUploadedSucess = false;
    let hearFeelURL;
    let soundEffectsURL;
    let rollPositionPre = false;
    let rollPositionMid1 = false;
    let rollPositionMid2 = false;
    let rollPositionMid3 = false;
    let rollPositionMid4 = false;
    let rollPositionMid5 = false;
    let rollPositionPost = false;
    let briefingCompleted = false;

    let time = 'T00:00:00.000Z';

    let showUpdateAudioFileButton = false;
    let showUpdateHearFeelButton = false;
    let showUpdateSoundEffectsButton = false;

    let selectedPodcast;
    let exclusiveConfirmedIds;

    function campaignTargetingStatusHandler() {
        $targetingEdited = campaignTargetingStatus;
    }

    $: campaignTargetStatusListener = campaignTargetingStatus & campaignTargetingStatusHandler();

    function updateEditMode() {
        $campaignEdited = true;
    }

    async function collectCampaignData(id) {
        if (!id) {
            return {};
        }

        try {
            campaign = await getCampaignByIdCached(id);
            setup();
            return campaign;
        } catch (e) {
            console.log(`Error ${e} occoured whilst getting the campaign info !`);
            return {};
        }
    }

    let promise = collectCampaignData(id)
        .then((data) => (campaign = data))
        .then(setup);

    async function setup() {
        renderingMode = 1;
        $campaignEdited = false;
        $targetingEdited = false;
        newCampaignTargetImpressions.set(0);
        selectedCampaign.set({});

        // check if company has exclusive podcasts
        const exclusives = await getCompanyPodcastExclusives($storedCompany.id);
        exclusiveConfirmedIds = exclusives.map((item) => (item.confirmed ? item.podcast_id : ''));
        exclusiveConfirmedIds = exclusiveConfirmedIds.filter((e) => e !== '');
    }

    let allPodcasts = [];
    let allPodcastsPromise = getAllPodcasts();

    async function getAllPodcasts() {
        try {
            let podcasts = await getAllPodcastsOfCompanyCached(company.id);

            podcasts.forEach((item) => {
                allPodcasts.push([item.name, item.id]);
            });
        } catch (e) {
            console.log(`Error ${e} occoured whilst fetching and processing the podcast data !`);
            return;
        }
    }

    // +++ EVENT HANDLER +++
    function handleCancel() {
        if ($campaignEdited) {
            modalMsg = 'All changes made here will be lost. Are you sure you want to proceed without saving your changes?';
            modalTitle = 'Attention !';
            $showModal = true;
        } else {
            $storedCompany.type == 'promoter' ? navigate(`/promoter`, {replace: true}) : navigate(`/podcast`, {replace: true});
        }
    }

    // save campaign + call "send all BRs"
    async function handleSaveProceed() {
        // showCommitMessage = false;
        if (checkRequirements()) {
            if ($campaignEdited || $targetingEdited) {
                let buttons = document.getElementsByClassName('saveproceed');
                Array.from(buttons).forEach((elem) => {
                    toggleButtonState(elem, 'disable', getText('promoter.campaign.button_saving'));
                });

                let response = await save();
                if (response) {
                    //TODO make that it shows - time is too short here
                    snackbarMsg = getText('ui_messages.snackbars.promoter.snackbar_campaign_save_success');
                    snackbarOpen = true;

                    $storedCompany.type == 'promoter' ? navigate(`/promoter`, {replace: true}) : navigate(`/podcast`, {replace: true});
                }
            } else {
                $storedCompany.type == 'promoter' ? navigate(`/promoter`, {replace: true}) : navigate(`/podcast`, {replace: true});
            }
        }
    }

    function checkRequirements() {
        let bookingRequests = campaignTargeting.collectDeals();

        if (bookingRequests.length > 0) {
            campaign = setCampaignState(campaign, 2);
        }

        let zero = 0;
        for (let bookingRequest of bookingRequests) {
            if (bookingRequest.requested_impressions == 0) {
                zero += 1;
            }
        }

        // Warning for 0 requested impressions
        if (zero > 0) {
            window.alert(getText('ui_messages.alerts.promoter.alert_zero_impressions'));
            return false;
        }

        // Check on requiered values
        if (!title && !document.getElementById('grid-title').value) {
            alertMsg = getText('ui_messages.alerts.promoter.alert_no_title');
            $alertSingleOpen = true;
            return false;
        }

        if (!acceptanceDeadline && !document.getElementById('grid-acceptance-date').value) {
            alertMsg = getText('ui_messages.alerts.promoter.alert_no_acceptance_deadline');
            $alertSingleOpen = true;
            return false;
        }

        if (!startDate && !document.getElementById('grid-start-date').value) {
            alertMsg = getText('ui_messages.alerts.promoter.alert_no_start_date');
            $alertSingleOpen = true;
            return false;
        }
        if (!endDate && !document.getElementById('grid-end-date').value) {
            alertMsg = getText('ui_messages.alerts.promoter.alert_no_end_date');
            $alertSingleOpen = true;
            return false;
        }
        if ((targetImpressions == 0 || !targetImpressions) && !parseInt(document.getElementById('grid-target-impressions').value)) {
            alertMsg = getText('ui_messages.alerts.promoter.alert_no_target_impressions');
            $alertSingleOpen = true;
            return false;
        }
        if (ctype == 'crosspromo' && selectedPodcast == 'select') {
            alertMsg = getText('ui_messages.alerts.promoter.alert_no_sender');
            $alertSingleOpen = true;
            return false;
        }
        if (ctype == 'hostread' && !feedbackLoopAudio && !feedbackLoopScript && !feedbackLoopNone && !campaign.feedback_loop_audio && !campaign.feedback_loop_script && !campaign.feedback_loop_none) {
            alertMsg = getText('ui_messages.alerts.promoter.alert_no_approval_option');
            $alertSingleOpen = true;
            return false;
        }

        positionRolls.forEach((roll) => {
            roll == 0 ? (rollPositionPre = true) : false;
            roll == 1 ? (rollPositionMid1 = true) : false;
            roll == 2 ? (rollPositionMid2 = true) : false;
            roll == 3 ? (rollPositionMid3 = true) : false;
            roll == 4 ? (rollPositionMid4 = true) : false;
            roll == 5 ? (rollPositionMid5 = true) : false;
            roll == 6 ? (rollPositionPost = true) : false;
        });

        if (renderingMode == 1 && !rollPositionPre && !rollPositionMid1 && !rollPositionMid2 && !rollPositionMid3 && !rollPositionMid4 && !rollPositionMid5 && !rollPositionPost) {
            window.alert(getText('ui_messages.alerts.promoter.alert_role_position'));
            return false;
        }

        return true;
    }

    // CHECK & SAVE
    async function save() {
        let bookingRequests = campaignTargeting.collectDeals();

        if (fileAudio) {
            // upload to s3
            if (!audioUploadedSucess) {
                await submitForm(fileAudio, 'audio', 'fileAudio');
            }
        }

        if (fileHearFeel) {
            // upload to s3
            if (!hearFeelUploadedSucess) {
                await submitForm(fileHearFeel, 'audio', 'fileHearFeel');
            }
        }

        if (fileSoundEffects) {
            // upload to s3
            if (!soundEffectsUploadedSucess) {
                await submitForm(fileSoundEffects, 'audio', 'fileSoundEffects');
            }
        }

        // write to API
        // campaign
        if (renderingMode == 1) {
            let sender = 'not provided';
            if (ctype == 'crosspromo') {
                sender = selectedPodcast[0];
            } else if (ctype == 'hostread' || ctype == 'spot') {
                sender = company.name;
            }

            // quick fix to join acceptanceDeadline and uploadDeadline to one field
            uploadDeadline = datePickerDateToDatabase(acceptanceDeadline);
            let newCampaign = {
                title: title,
                script: script,
                background_information: background_information,
                goal: goal,
                start_date: datePickerDateToDatabase(startDate),
                end_date: datePickerDateToDatabase(endDate),
                created: getDateNow(),
                updated: getDateNow(),
                state: 2,
                acceptance_deadline: datePickerDateToDatabase(acceptanceDeadline),
                upload_deadline: uploadDeadline,
                target_impressions: campaignTargeting.getTotalTargetImpressions(),
                current_total_impressions: 0,
                audiofile_url: audiofileUrl,
                audiofile_uploaded_date: audiofile_uploaded_date,
                audiofile_duration_ms: audiofile_duration_ms,
                type: ctype,
                sender: sender,
                hostread_duration_ms: hostreadDurationMs > 0 ? hostreadDurationMs : 0,
                feedback_loop_script: feedbackLoopScript,
                feedback_loop_audio: feedbackLoopAudio,
                feedback_loop_none: feedbackLoopNone,
                roll_position_pre: rollPositionPre,
                roll_position_mid_1: rollPositionMid1,
                roll_position_mid_2: rollPositionMid2,
                roll_position_mid_3: rollPositionMid3,
                roll_position_mid_4: rollPositionMid4,
                roll_position_mid_5: rollPositionMid5,
                roll_position_post: rollPositionPost,
                audio_keywords: audioKeywords,
                excluded_keywords: excludedKeywords,
                briefing_url: briefingURL,
                briefing_url_sound_effects: briefingURLSoundEffects,
                briefing_supplementary_url: briefingSupplementaryURL,
                hear_feel_url: hearFeelURL,
                sound_effects_url: soundEffectsURL,
                briefing_completed: briefingCompleted,
                briefing_last_updated_date: getDateNow(),
            };

            try {
                campaign = await addCampaign(company.id, newCampaign);
                selectedCampaign.set(campaign);
            } catch (e) {
                console.log(`Error ${e} occoured whilst saving the new campaign "${newCampaign.title}"`);
            }

            if (Object.keys(campaign).length == 0) {
                console.log('something went wrong whilst saving the campaign !');
                return false;
            }

            for (let bookingRequest of bookingRequests) {
                bookingRequest.state = 2;
                bookingRequest.activated = true;
                bookingRequest.activated_date = getDateNow();

                // if campaign spot
                // if ((ctype == 'spot' || ctype == 'crosspromo') && fileAudio) {
                //     // bookingRequest.audiofile_url = audiofileUrl;
                //     // bookingRequest.audiofile_uploaded_date = audiofile_uploaded_date;
                //     // bookingRequest.audiofile_duration_ms = audiofile_duration_ms;

                //     bookingRequest = setAudio(bookingRequest, audiofileUrl, audiofile_duration_ms);
                //     console.log('bookingRequest: ', bookingRequest);
                //     debugger;
                // }

                if (ctype == 'crosspromo') {
                    bookingRequest.sender = selectedPodcast[0];
                } else if (ctype == 'hostread' || ctype == 'spot') {
                    bookingRequest.sender = campaign.sender;
                }

                let newbR;
                try {
                    newbR = await postBookingRequest(campaign.id, bookingRequest.podcast_id, bookingRequest);
                    await saveCommitMessage(newbR.id, 1).catch((e) => {
                        console.log(`Error ${e} occoured whilst saving the commit for booking request ${newbR}`);
                    });
                } catch (e) {
                    console.log(`Error ${e} occoured whilst saving the booking request of campaign ${campaign.id} for podcast ${bookingRequest.podcast_id} !`);
                }

                try {
                    // create inventoryReservation
                    let roll_positions = [];
                    rollPositionPre ? roll_positions.push(1) : '';
                    rollPositionMid1 ? roll_positions.push(2) : '';
                    rollPositionMid2 ? roll_positions.push(3) : '';
                    rollPositionMid3 ? roll_positions.push(4) : '';
                    rollPositionPost ? roll_positions.push(5) : '';

                    for (let roll of roll_positions) {
                        let newInventory = {
                            roll_position: roll,
                            campaign_id: newbR.campaign_id,
                            booking_request_id: newbR.id,
                            podcast_id: newbR.podcast_id,
                        };
                        await saveInventoryReservation(newbR.campaign_id, newbR.id, newbR.podcast_id, newInventory);
                    }
                } catch (e) {
                    console.log(`Error ${e} occoured whilst saving the inventory reservation for booking request ${newbR.id} for podcast ${newbR.podcast_id} !`);
                }

                // close deal for exclusive podcasts -> keep this for channel booking !
                // if (exclusiveConfirmedIds.includes(Number(newbR.podcast_id))) {
                //     newbR.offered_impressions = newbR.requested_impressions;
                //     newbR.roll_position_pre = newCampaign.roll_position_pre;
                //     newbR.roll_position_mid_1 = newCampaign.roll_position_mid_1;
                //     newbR.roll_position_mid_2 = newCampaign.roll_position_mid_2;
                //     newbR.roll_position_mid_3 = newCampaign.roll_position_mid_3;
                //     newbR.roll_position_post = newCampaign.roll_position_post;

                //     if (ctype == 'hostread') {
                //         newbR = setBookingRequestState(newbR, 3);
                //         newbR = setBookingRequestState(newbR, 5);
                //     }

                //     if (ctype == 'spot') {
                //         newbR = setBookingRequestState(newbR, 5);
                //     }

                //     try {
                //         await patchBookingRequest(newbR.id, newbR);
                //     } catch (e) {
                //         console.log(`Error ${e} occoured whilst patching the booking request: ${newbR}`);
                //     }
                // }
            }

            return true;
        }
    }

    // Date checks
    function handleDateChange(event) {
        updateEditMode();
        // TODO rewrite check dates on past if in edit mode !

        let pickedDateTime = Date.parse(event.target.value);

        //TODO make alert prettier, e.g. tooltip on info-icon
        alertTitle = getText('ui_messages.alerts.promoter.alert_dates_title');
        alertMsg = [
            getText('ui_messages.alerts.promoter.alert_dates_line_1'),
            getText('ui_messages.alerts.promoter.alert_dates_line_2'),
            getText('ui_messages.alerts.promoter.alert_dates_line_3'),
            getText('ui_messages.alerts.promoter.alert_dates_line_4'),
            getText('ui_messages.alerts.promoter.alert_dates_line_5'),
            getText('ui_messages.alerts.promoter.alert_dates_line_6'),
        ];
        // if fired from acceptanceDeadline
        if (event.target.id == 'grid-acceptance-date') {
            if (pickedDateTime < Date.parse(getDateMidnight(60)) || pickedDateTime > Date.parse(startDate + time) || (uploadDeadline && pickedDateTime >= Date.parse(uploadDeadline + time))) {
                $alertOpen = true;
                acceptanceDeadline = '';
            }
        }

        // if fired from startDate
        if (event.target.id == 'grid-start-date') {
            if (
                pickedDateTime < Date.parse(getDateMidnight(60)) ||
                (endDate && pickedDateTime >= Date.parse(endDate + time)) ||
                pickedDateTime < Date.parse(acceptanceDeadline) ||
                pickedDateTime <= Date.parse(uploadDeadline)
            ) {
                $alertOpen = true;
                startDate = '';
            }
        }

        // if fired from endDate
        if (event.target.id == 'grid-end-date') {
            if (
                pickedDateTime < Date.parse(getDateMidnight(60)) ||
                (startDate && pickedDateTime <= Date.parse(startDate + time)) ||
                pickedDateTime < Date.parse(acceptanceDeadline) ||
                pickedDateTime <= Date.parse(uploadDeadline)
            ) {
                $alertOpen = true;
                endDate = '';
            }
        }
    }

    // FILE UPLOADs
    // accepts mp3 and pdf
    let fileAudio;
    let fileHearFeel;
    let fileSoundEffects;

    // get duration of mp3 url
    async function getURLDuration(url) {
        var au = document.createElement('audio');
        au.src = url;
        // Once the metadata has been loaded - grep duration in ms
        au.addEventListener(
            'loadedmetadata',
            function () {
                let duration_ms = au.duration * 1000;
                audiofile_duration_ms = parseInt(duration_ms);
                audioUploadedSucess = true;
            },
            false,
        );
    }

    async function submitForm(file, type, source) {
        let formData = new FormData();

        let fileName = file[0].name;
        fileName = fileName.replaceAll(' ', '-');

        fileName = crypto.randomUUID() + '.' + fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);

        formData.append('file', file[0]);
        formData.append('filename', fileName);

        if (type == 'audio') {
            // TODO accept other format as well?
            if (!fileName.endsWith('mp3')) {
                window.alert(getText('ui_messages.alerts.promoter.alert_mp3_check'));
                return;
            }

            await uploadAudio(fileName, formData, source)
                .then(() => {
                    getURLDuration(`https://asap-dispo.s3.amazonaws.com/audio/${fileName}`).then(() => {
                        setTimeout(() => {}, 1500);
                    });
                })
                .catch((e) => {
                    console.log(`Error ${e} occoured whilst uploading the audio file ${fileName}`);
                });
        }
        showUpdateAudioFileButton = false;
        showUpdateHearFeelButton = false;
        showUpdateSoundEffectsButton = false;
    }

    async function uploadAudio(fileName, formData, source) {
        // save audiofile to s3
        try {
            await uploadFile(formData, 'audio').then((response) => {
                if (source == 'fileAudio') {
                    audiofileUrl = `https://asap-dispo.s3.amazonaws.com/audio/${fileName}`;
                    let player = document.getElementById('grid-spot-audio-player');
                    if (player) {
                        player.refresh();
                    }
                    audiofile_uploaded_date = getDateNow();
                    return response;
                }
                if (source == 'fileHearFeel') {
                    hearFeelURL = `https://asap-dispo.s3.amazonaws.com/audio/${fileName}`;
                    hearFeelUploadedSucess = true;
                    return response;
                }
                if (source == 'fileSoundEffects') {
                    soundEffectsURL = `https://asap-dispo.s3.amazonaws.com/audio/${fileName}`;
                    soundEffectsUploadedSucess = true;
                    return response;
                }
            });
        } catch (e) {
            audioUploadedSucess = false;
            hearFeelUploadedSucess = false;
            soundEffectsUploadedSucess = false;
            window.alert(getText('ui_messages.alerts.promoter.alert_audio_upload', response.status));
        }
    }

    // +++ MODAL +++
    let idName;
    let buttonState;
    let msg;
    function cancel() {
        $showModal = false;
        $storedCompany.type == 'promoter' ? navigate(`/promoter`, {replace: true}) : navigate(`/podcast`, {replace: true});
    }
    async function handleSaveExit() {
        if (checkRequirements()) {
            let buttons = document.getElementsByClassName('saveproceed');
            Array.from(buttons).forEach((elem) => {
                toggleButtonState(elem, 'disable', getText('promoter.campaign.button_saving'));
            });

            if ($alertSingleOpen == false) {
                if (await save()) {
                    $showModal = false;
                    $storedCompany.type == 'promoter' ? navigate(`/promoter`, {replace: true}) : navigate(`/podcast`, {replace: true});
                }
            }
        }
    }

    // save positionRolls
    let positionRolls = [];
    function updatePositionRolls(e, position) {
        // Missing Link Sonderlocke
        if (company.type == 'promoter' && company.id !== $missingLinkId) {
            if (e.srcElement.checked) {
                positionRolls.push(position);
            } else if (!e.srcElement.checked) {
                positionRolls = positionRolls.filter((x) => x !== position);
            }
        } else {
            if (position == 0) {
                if (e.srcElement.checked) {
                    rollPositionPre = true;
                    rollPositionMid1 = rollPositionMid2 = rollPositionMid3 = rollPositionMid4 = rollPositionMid5 = rollPositionPost = false;
                } else {
                    rollPositionPre = false;
                }
            }
            if (position == 1) {
                if (e.srcElement.checked) {
                    rollPositionMid1 = true;
                    rollPositionPre = rollPositionMid2 = rollPositionMid3 = rollPositionMid4 = rollPositionMid5 = rollPositionPost = false;
                } else {
                    rollPositionMid1 = false;
                }
            }
            if (position == 2) {
                if (e.srcElement.checked) {
                    rollPositionMid2 = true;
                    rollPositionPre = rollPositionMid1 = rollPositionMid3 = rollPositionMid4 = rollPositionMid5 = rollPositionPost = false;
                } else {
                    rollPositionMid2 = false;
                }
            }
            if (position == 3) {
                if (e.srcElement.checked) {
                    rollPositionMid3 = true;
                    rollPositionPre = rollPositionMid1 = rollPositionMid2 = rollPositionMid4 = rollPositionMid5 = rollPositionPost = false;
                } else {
                    rollPositionMid3 = false;
                }
            }
            if (position == 4) {
                if (e.srcElement.checked) {
                    rollPositionMid4 = true;
                    rollPositionPre = rollPositionMid1 = rollPositionMid2 = rollPositionMid3 = rollPositionMid5 = rollPositionPost = false;
                } else {
                    rollPositionMid4 = false;
                }
            }
            if (position == 5) {
                if (e.srcElement.checked) {
                    rollPositionMid5 = true;
                    rollPositionPre = rollPositionMid1 = rollPositionMid2 = rollPositionMid3 = rollPositionMid4 = rollPositionPost = false;
                } else {
                    rollPositionMid5 = false;
                }
            }
            if (position == 6) {
                if (e.srcElement.checked) {
                    rollPositionPost = true;
                    rollPositionPre = rollPositionMid1 = rollPositionMid2 = rollPositionMid3 = rollPositionMid4 = rollPositionMid5 = false;
                } else {
                    rollPositionPost = false;
                }
            }
            // set position
            positionRolls = [];
            positionRolls.push(position);
        }
    }

    // save feedbackLoops
    function updateFeedbackLoops(e, type) {
        if (type == 'script') {
            if (e.srcElement.checked) {
                feedbackLoopScript = true;

                // uncheck "none"
                feedbackLoopNone = false;
                document.getElementById('grid-campaign-feedback-loop-none').checked = false;
            } else {
                feedbackLoopScript = false;
            }
        }
        if (type == 'audio') {
            if (e.srcElement.checked) {
                // uncheck "none"
                feedbackLoopAudio = true;
                feedbackLoopNone = false;
                document.getElementById('grid-campaign-feedback-loop-none').checked = false;
            } else {
                feedbackLoopAudio = false;
            }
        }
        if (type == 'none') {
            if (e.srcElement.checked) {
                feedbackLoopNone = true;

                // uncheck script & audio
                feedbackLoopScript = false;
                document.getElementById('grid-campaign-feedback-loop-script').checked = false;
                feedbackLoopAudio = false;
                document.getElementById('grid-campaign-feedback-loop-audio').checked = false;
            } else {
                feedbackLoopNone = false;
            }
        }
    }

    // Collapsable Cards
    const collabsableItems = [
        {
            key: 1,
            title: 'Campaign Information',
        },
        {
            key: 3,
            title: 'Campaign Targeting',
        },
    ];

    // COMMIT MESSAGES
    function parentFunction() {
        handleSaveProceed();
    }

    async function saveCommitMessage(bookingRequestId) {
        let data = {
            booking_request_id: bookingRequestId,
            action: 1,
            author: $storedUser.first_name,
            company_name: $storedCompany.name,
            company_type: $storedCompany.type,
            message: commitMessage ? commitMessage : getText('commit_messages.default.campaign.created'),
            sender: true,
        };

        try {
            await postCommitMessage(bookingRequestId, data);
            return true;
        } catch (e) {
            console.log(`An error occoured posting the message ${data.message} with error ${e}`);
            return false;
        }
    }

    let action;
    function setAction(thisAction) {
        action = thisAction;
    }

    function setCommitMessage(thisMessage) {
        commitMessage = thisMessage;
    }

    function triggerCommitMessagePopUp() {
        showCommitMessage = true;
    }
    let showCommitMessage = false;
    let commitMessage;
    let continueProcess = false;

    function sendReservation() {
        setAction(getText('commit_messages.default.booking_request.reservation_sent'));
        triggerCommitMessagePopUp();
    }
</script>

{#await promise}
    <div style="display: flex; justify-content: center">
        <Pulse size="60" color="#FF3E00" unit="px" duration="1s" />
    </div>
{:then}
    <ModalSmall {modalMsg} {modalTitle} buttons={['Yes', 'Save', 'Stay']} functionOne={() => cancel()} functionTwo={() => handleSaveExit()} />
    <AlertMultiLine {alertTitle} {alertMsg} />
    <AlertOneLine {alertMsg} bind:idName bind:buttonState bind:msg />

    {#if snackbarOpen}
        <SnackbarNoDismiss {snackbarMsg} bind:snackbarOpen />
    {/if}

    {#if showCommitMessage}
        <CardCommitMessage {action} bind:commitMessage bind:continueProcess bind:showCommitMessage parentFunction={() => parentFunction()} />
    {/if}

    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
                {#if ctype == 'hostread'}
                    <h6 class="text-blueGray-700 text-xl font-bold">{campaign.title || getText('promoter.campaign.title_reserve_hostread')}</h6>
                {:else if ctype == 'spot'}
                    <h6 class="text-blueGray-700 text-xl font-bold">{campaign.title || getText('promoter.campaign.title_reserve_spot')}</h6>
                {:else if ctype == 'crosspromo'}
                    <h6 class="text-blueGray-700 text-xl font-bold">{campaign.title || getText('promoter.campaign.title_reserve_cross_promo')}</h6>
                {/if}
                {#if $targetingEdited == true}
                    <CardButton id="saveproceed" classes="saveproceed" type="go" text={getText('promoter.campaign.button_save_send_reservation')} on:click={() => sendReservation()} />
                {:else if $targetingEdited !== true}
                    <CardButton id="saveproceed" classes="saveproceed" type="go" text={getText('promoter.campaign.button_save')} on:click={() => handleSaveExit()} />
                {/if}
            </div>
        </div>
    </div>

    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-100 border-1">
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
                <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                    {getText('promoter.campaign.general_information.title')}
                </h6>

                {#if renderingMode == 1}
                    <div class="flex flex-wrap">
                        {#if ctype == 'crosspromo'}
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-title">
                                        {getText('promoter.campaign.general_information.category_1.title')}
                                    </label>
                                    <input
                                        id="grid-title"
                                        type="input"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder={getText('promoter.campaign.general_information.category_1.input_placeholder')}
                                        bind:value={title}
                                    />
                                </div>
                            </div>
                            {#await allPodcastsPromise then data}
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-requesting-podcast">
                                            {getText('promoter.campaign.general_information.category_3.title')}
                                        </label>

                                        <select name="podcastName" id="grid-requesting-podcast" bind:value={selectedPodcast}>
                                            <option value="select">
                                                {getText('promoter.campaign.general_information.category_3.input_placeholder')}
                                            </option>
                                            {#each allPodcasts as podcast}
                                                <option value={podcast}>{podcast[0]}</option>
                                            {/each}
                                        </select>
                                    </div>
                                </div>
                            {/await}
                        {/if}

                        {#if ctype == 'hostread' || ctype == 'spot'}
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-title">
                                        {getText('promoter.campaign.general_information.category_1.title')}
                                    </label>
                                    <input
                                        id="grid-title"
                                        type="input"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder={getText('promoter.campaign.general_information.category_1.input_placeholder')}
                                        bind:value={title}
                                    />
                                </div>
                            </div>
                        {/if}

                        {#if ctype == 'hostread'}
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-campaign-feedback-loops">
                                        {getText('promoter.campaign.general_information.category_5.title')}
                                    </label>
                                    <div class="text-center flex flex-wrap">
                                        <label for="grid-campaign-feedback-loop-script" class="mr-4 mt-2">
                                            <input
                                                id="grid-campaign-feedback-loop-script"
                                                type="checkbox"
                                                class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                on:click={(e) => {
                                                    updateFeedbackLoops(e, 'script');
                                                }}
                                            />

                                            {getText('promoter.campaign.general_information.category_5.entry_1')}
                                        </label>
                                        <label for="grid-campaign-feedback-loop-audio" class="mr-4 mt-2">
                                            <input
                                                id="grid-campaign-feedback-loop-audio"
                                                type="checkbox"
                                                class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                on:click={(e) => {
                                                    updateFeedbackLoops(e, 'audio');
                                                }}
                                            />

                                            {getText('promoter.campaign.general_information.category_5.entry_2')}
                                        </label>
                                        <label for="grid-campaign-feedback-loop-none" class="mr-4 mt-2">
                                            <input
                                                id="grid-campaign-feedback-loop-none"
                                                type="checkbox"
                                                class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                on:click={(e) => {
                                                    updateFeedbackLoops(e, 'none');
                                                }}
                                            />

                                            {getText('promoter.campaign.general_information.category_5.entry_3')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        {/if}

                        <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-campaign-roll-position">
                                    {getText('promoter.campaign.general_information.category_6.title')}
                                    {#if company.id != $missingLinkId}
                                        <CardTooltip text={getText('promoter.campaign.general_information.category_6.info')} />
                                    {/if}
                                </label>
                                <div class="text-center flex justify-between">
                                    <label for="grid-campaign-checkbox-roll-pre" class="mr-4 mt-2">
                                        <input
                                            id="grid-campaign-checkbox-roll-pre"
                                            type="checkbox"
                                            class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            bind:checked={rollPositionPre}
                                            on:click={(e) => {
                                                updatePositionRolls(e, 0);
                                            }}
                                        />

                                        {getText('general.roll_positions.entry_1')}
                                    </label>
                                    <label for="grid-campaign-checkbox-roll-mid-1" class="mr-4 mt-2">
                                        <input
                                            id="grid-campaign-checkbox-roll-mid-1"
                                            type="checkbox"
                                            class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            bind:checked={rollPositionMid1}
                                            on:click={(e) => {
                                                updatePositionRolls(e, 1);
                                            }}
                                        />

                                        {getText('general.roll_positions.entry_2')}
                                    </label>
                                    <label for="grid-campaign-checkbox-roll-mid-2" class="mr-4 mt-2">
                                        <input
                                            id="grid-campaign-checkbox-roll-mid-2"
                                            type="checkbox"
                                            class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            bind:checked={rollPositionMid2}
                                            on:click={(e) => {
                                                updatePositionRolls(e, 2);
                                            }}
                                        />

                                        {getText('general.roll_positions.entry_3')}
                                    </label>
                                    <label for="grid-campaign-checkbox-roll-mid-3" class="mr-4 mt-2">
                                        <input
                                            id="grid-campaign-checkbox-roll-mid-3"
                                            type="checkbox"
                                            class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            bind:checked={rollPositionMid3}
                                            on:click={(e) => {
                                                updatePositionRolls(e, 3);
                                            }}
                                        />

                                        {getText('general.roll_positions.entry_4')}
                                    </label>
                                    <!-- missing link sonderlocke -->
                                    {#if company.id == $missingLinkId}
                                        <label for="grid-campaign-checkbox-roll-mid-4" class="mr-4 mt-2">
                                            <input
                                                id="grid-campaign-checkbox-roll-mid-4"
                                                type="checkbox"
                                                class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                bind:checked={rollPositionMid4}
                                                on:click={(e) => {
                                                    updatePositionRolls(e, 4);
                                                }}
                                            />
                                            {getText('general.roll_positions_missing_link.entry_5')}
                                        </label>
                                        <label for="grid-campaign-checkbox-roll-mid-5" class="mr-4 mt-2">
                                            <input
                                                id="grid-campaign-checkbox-roll-mid-5"
                                                type="checkbox"
                                                class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                bind:checked={rollPositionMid5}
                                                on:click={(e) => {
                                                    updatePositionRolls(e, 5);
                                                }}
                                            />
                                            {getText('general.roll_positions_missing_link.entry_6')}
                                        </label>
                                    {/if}
                                    <label for="grid-campaign-checkbox-roll-post" class="mr-4 mt-2">
                                        <input
                                            id="grid-campaign-checkbox-roll-post"
                                            type="checkbox"
                                            class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            bind:checked={rollPositionPost}
                                            on:click={(e) => {
                                                updatePositionRolls(e, 6);
                                            }}
                                        />

                                        {getText('general.roll_positions.entry_5')}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class="mt-6 border-b-1 border-blueGray-300" />

                    <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                        {getText('promoter.campaign.date_information.title')}
                    </h6>
                    <div class="flex flex-wrap">
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-acceptance-date">
                                    {getText('promoter.campaign.date_information.category_1.title')}
                                </label>
                                <input
                                    id="grid-acceptance-date"
                                    type="date"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    bind:value={acceptanceDeadline}
                                    on:blur={handleDateChange}
                                />
                            </div>
                        </div>
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-start-date">
                                    {getText('promoter.campaign.date_information.category_2.title')}
                                </label>
                                <input
                                    id="grid-start-date"
                                    type="date"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    bind:value={startDate}
                                    on:blur={handleDateChange}
                                />
                            </div>
                        </div>
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-end-date">
                                    {getText('promoter.campaign.date_information.category_3.title')}
                                </label>
                                <input
                                    id="grid-end-date"
                                    type="date"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    bind:value={endDate}
                                    on:blur={handleDateChange}
                                />
                            </div>
                        </div>
                    </div>
                {/if}
            </form>
        </div>
    </div>

    <CardCampaignTargeting bind:this={campaignTargeting} bind:status={campaignTargetingStatus} {campaign} {selectedPodcast} />

    <div class="text-center flex justify-between">
        <div class="text-center flex">
            <CardButton id="cancel" type="cancel" text={getText('promoter.campaign.button_cancel')} on:click={() => handleCancel()} />
        </div>
        <div class="text-center flex">
            {#if $targetingEdited == true}
                <CardButton id="saveproceed" classes="saveproceed" type="go" text={getText('promoter.campaign.button_save_send_reservation')} on:click={() => sendReservation()} />
            {:else if $targetingEdited !== true}
                <CardButton id="saveproceed" classes="saveproceed" type="go" text={getText('promoter.campaign.button_save')} on:click={() => handleSaveExit()} />
            {/if}
        </div>
    </div>
{/await}
