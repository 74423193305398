<script>
    import CardCampaign from 'components/Cards/CardCampaign.svelte';
    import {getText} from '../../language.svelte';

    export let id;
    export let ctype;

    // TABS
    let openTab = 1;

    function toggleTabs(tabNumber) {
        openTab = tabNumber;
    }
</script>

<div class="relative flex flex-col min-w-0 break-words bg-blueGray-50 w-full mb-6 shadow-lg rounded">
    <div class="flex flex-wrap">
        <div class="w-full">
            <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
                <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                    <p class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal {openTab === 1 ? 'text-white bg-blueGray-600' : 'text-blueGray-600 bg-white'}">
                        {getText('promoter.campaign_overview.hostread_table.button_edit')}
                    </p>
                    <!-- <a
                        href="#edit"
                        class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal {openTab === 1 ? 'text-white bg-blueGray-600' : 'text-blueGray-600 bg-white'}"
                        on:click={() => toggleTabs(1)}
                    >
                        Edit
                    </a> -->
                </li>
                <!-- <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                    <a
                        href="#stats"
                        class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal {openTab === 2 ? 'text-white bg-blueGray-600' : 'text-blueGray-600 bg-white'}"
                        on:click={() => toggleTabs(2)}
                    >
                        Stats
                    </a>
                </li> -->
            </ul>
            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div class="px-4 py-5 flex-auto">
                    <div class="tab-content tab-space">
                        <div class={openTab === 1 ? 'block' : 'hidden'}>
                            <div class="flex flex-wrap">
                                <div class="w-full lg:w-12/12 px-4">
                                    <CardCampaign {id} {ctype} />
                                </div>
                            </div>
                        </div>
                        <!-- <div class={openTab === 2 ? 'block' : 'hidden'}>
                            <p>// TODO BUILD STATS VIEW.</p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
