/*
 ************************************************************
 * data structure
 * apiData = {
 *     counter: int to count how many time the cash was called
 *     counterSolo: {origin: int, ...}
 *     apiCounter: int to count how many time the api was called
 *     apiCounterSolo: {origin: int, ...}
 *     whoami: {user object}
 *     campaigns: {campaign_id: {campaign}, ...}
 *     campaignsByCompany: {company_id: [{campaign}, {campaign}, ...], ...},
 *     podcastsOfCompany: {company_id: [{podcast}, {podcast}, ...], ...},
 *     podcasts: {podcast_id: {podcast}, ...},
 *     campaignCompanyInfo: {campaign_id: {company_info}, ...},
 *     bookingRequests: {booking_request_id: {bookingRequest}, ...},
 *     campaignBookingRequests: {campaign_id: [{bookingRequest}, {bookingRequest}, ...}], ...},
 *     podcastBookingRequests: {podcast_id: [{bookingRequest}, {bookingRequest}, ...}], ...},
 *     inventoryReservations: {inventory_reservation_id: {inventory_reservation}, ...},
 *     campaignInventoryReservations: {campaign_id: [{inventory_reservation}, ...], ...},
 *     podcastInventoryReservations: {podcast_id: [{inventory_reservation}, ...], ...},
 *     bookingRequestInventoryReservations: {booking_request_id: [{inventory_reservation}, ...], ...},
 *     inventoryBookings: {inventory_booking_id: {inventory_booking_id}, ...},
 *     campaignInventoryBookings: {campaign_id: [{inventory_booking}, ...], ...},
 *     podcastInventoryBookings: {podcast_id: [{inventory_booking}, ...], ...},
 *     bookingRequestInventoryBookings: {booking_request_id: [{inventory_booking}, ...], ...},
 * };
 ************************************************************
 */
const apiData = {
    counter: 0,
    counterSolo: {},
    apiCounter: 0,
    apiCounterSolo: {},
    whoami: {},
    campaigns: {},
    campaignsByCompany: {},
    podcastsOfCompany: {},
    podcasts: {},
    campaignCompanyInfo: {},
    bookingRequests: {},
    campaignBookingRequests: {},
    podcastBookingRequests: {},
    inventoryReservations: {},
    campaignInventoryReservations: {},
    podcastInventoryReservations: {},
    bookingRequestInventoryReservations: {},
    inventoryBookings: {},
    campaignInventoryBookings: {},
    podcastInventoryBookings: {},
    bookingRequestInventoryBookings: {},
};

/*
 * type: which enpoint is calling the setter
 * data: data to update or match
 * method: which HTTP method
 * info: e.g. an id or origin that is not contained in the data object
 */

export function setApiCache(type, data, method, info) {
    // console.log('-----START SETTER--------');

    if (type == 'whoami') {
        // console.log(`type: whoami', method: ${method}`);

        method == 'GET' ? (apiData.whoami = data) : '';
    }

    if (type == 'campaigns') {
        // console.log(`type: campaigns', method: ${method}`);
        // gets a campagin object
        method == 'GET' || method == 'POST' || method == 'PATCH' ? (apiData.campaigns[data.id] = data) : '';

        // gets only the campaign_id as <data: {campaign_id: int}>
        method == 'DELETE' && apiData.campaigns[data.campaign_id] ? delete apiData.campaigns[data.campaign_id] : '';
    }

    if (type == 'campaignsByCompany') {
        // console.log(`type: campaignsByCompany', method: ${method}`);
        // gets a list of campaigns - can be empty if no campaigns exist
        method == 'GET' && data.length > 0 ? (apiData.campaignsByCompany[data[0].company_id] = data) : '';

        // gets a single campaign
        if (method == 'POST') {
            apiData.campaignsByCompany[data.company_id] ? '' : (apiData.campaignsByCompany[data.company_id] = []);
            apiData.campaignsByCompany[data.company_id].push(data);
        }

        // gets a single campaign
        if (method == 'PATCH') {
            apiData.campaignsByCompany[data.company_id] ? '' : (apiData.campaignsByCompany[data.company_id] = []);
            let existingCampaign = apiData.campaignsByCompany[data.company_id].find((item) => item.id === data.id);
            if (existingCampaign) {
                Object.assign(existingCampaign, data);
            } else {
                apiData.campaignsByCompany[data.company_id].push(data);
            }
        }

        // gets only the campaign_id as <data: {campaign_id: int}>
        if (method == 'DELETE') {
            Object.keys(apiData.campaignsByCompany).forEach((companyId) => {
                for (const idx in apiData.campaignsByCompany[companyId]) {
                    campaign.id == data.campaign_id ? delete apiData.campaignsByCompany[companyId][idx] : '';
                }
            });
        }
    }

    if (type == 'podcastsOfCompany') {
        // console.log(`type: podcastsOfCompany', method: ${method}`);
        // gets a list of podcasts
        method == 'GET' && data.length > 0 ? (apiData.podcastsOfCompany[data[0].company_id] = data) : '';
    }

    if (type == 'podcasts') {
        // console.log(`type: podcasts', method: ${method}`);
        // gets a list of podcasts OR a single podcast
        if (method == 'GET') {
            if (Array.isArray(data)) {
                data.forEach((podcast) => {
                    apiData.podcasts[podcast.id] = podcast;
                });
            }
            if (!Array.isArray(data)) {
                apiData.podcasts[data.id] = data;
            }
        }

        // gets a single podcast
        method == 'POST' && method == 'PATCH' ? (apiData.podcasts[data.id] = data) : '';
    }

    if (type == 'campaignCompanyInfo') {
        // console.log(`type: campaignCompanyInfo', method: ${method}`);
        // gets additional info: campaign_id
        method == 'GET' ? (apiData.campaignCompanyInfo[info] = data) : '';
    }

    if (type == 'bookingRequests') {
        // console.log(`type: bookingRequests', method: ${method}`);
        // gets a single booking request
        method == 'POST' || method == 'PATCH' ? (apiData.bookingRequests[data.id] = data) : '';

        // gets a list OR a single booking request
        if (method == 'GET') {
            if (Array.isArray(data) && data.length > 0) {
                data.forEach((bookingRequest) => {
                    apiData.bookingRequests[bookingRequest.id] = bookingRequest;
                });
            }
            if (!Array.isArray(data)) {
                apiData.bookingRequests[data.id] = data;
            }
        }

        // if a campaign gets deleted: gets the <data: {campaign_id: int}> as data
        if (method == 'DELETE' && info == 'campaigns') {
            for (const idx in apiData.bookingRequests) {
                apiData.bookingRequests[idx].campaign_id == data.campaign_id ? delete apiData.bookingRequests[idx] : '';
            }
        }

        // if a booking request gets deleted: gets <data: {booking_request_id: int}> as data
        if (method == 'DELETE' && info == 'bookingRequest') {
            for (const idx in apiData.bookingRequests) {
                apiData.bookingRequests[idx].id == data.booking_request_id ? delete apiData.bookingRequests[idx] : '';
            }
        }
    }

    if (type == 'campaignBookingRequests') {
        // console.log(`type: campaignBookingRequests', method: ${method}`);

        // gets a single booking request
        if (method == 'POST') {
            apiData.campaignBookingRequests[data.campaign_id] ? '' : (apiData.campaignBookingRequests[data.campaign_id] = []);
            apiData.campaignBookingRequests[data.campaign_id].push(data);
        }

        // if a campaign gets deleted: gets <data: {campaign_id: int}> as data
        if (method == 'DELETE' && info == 'campaigns') {
            delete apiData.campaignBookingRequests[data.campaign_id];
        }

        // if a booking requests gets deleted: gets <data: {booking_request_id: int}> as data
        if (method == 'DELETE' && info == 'bookingRequests') {
            for (let campaign in apiData.campaignBookingRequests) {
                let index = apiData.campaignBookingRequests[campaign].findIndex((item) => item.id === data.booking_request_id);
                if (index !== -1) {
                    apiData.campaignBookingRequests[campaign].splice(index, 1);
                }
            }
        }

        // gets a list OR a single booking request
        if (method == 'GET') {
            if (Array.isArray(data) && data.length > 0) {
                apiData.campaignBookingRequests[data[0].campaign_id] = data;
            }
            if (!Array.isArray(data)) {
                apiData.campaignBookingRequests[data.campaign_id] ? '' : (apiData.campaignBookingRequests[data.campaign_id] = []);
                apiData.campaignBookingRequests[data.campaign_id].push(data);
            }
        }

        // gets a single booking request
        if (method == 'PATCH') {
            apiData.campaignBookingRequests[data.campaign_id] ? '' : (apiData.campaignBookingRequests[data.campaign_id] = []);
            let existingBookingRequest = apiData.campaignBookingRequests[data.campaign_id].find((item) => item.id === data.id);
            if (existingBookingRequest) {
                Object.assign(existingBookingRequest, data);
            } else {
                apiData.campaignBookingRequests[data.campaign_id].push(data);
            }
        }
    }

    if (type == 'podcastBookingRequests') {
        // console.log(`type: podcastBookingRequests', method: ${method}`);

        // gets a single booking request
        if (method == 'POST') {
            apiData.podcastBookingRequests[data.podcast_id] ? '' : (apiData.podcastBookingRequests[data.podcast_id] = []);
            apiData.podcastBookingRequests[data.podcast_id].push(data);
        }

        // if a campaign gets deleted: gets <data: {campaign_id: int}> as data
        if (method == 'DELETE' && info == 'campaigns') {
            apiData.podcastBookingRequests.forEach((podcast) => {
                for (const idx in podcast) {
                    podcast[idx].campaign_id == data.campaign_id ? delete apiData.podcastBookingRequests[podcast][idx] : '';
                }
            });
        }

        // if a booking requests gets deleted: gets <data: {booking_request_id: int}> as data
        if (method == 'DELETE' && info == 'bookingRequests') {
            for (let podcast in apiData.podcastBookingRequests) {
                let index = apiData.podcastBookingRequests[podcast].findIndex((item) => item.id === data.booking_request_id);
                if (index !== -1) {
                    apiData.podcastBookingRequests[podcast].splice(index, 1);
                }
            }
        }

        // gets a list OR a single booking request
        if (method == 'GET' && data.length > 0) {
            if (Array.isArray(data) && data.length > 0) {
                apiData.podcastBookingRequests[data[0].podcast_id] = data;
            }
            if (!Array.isArray(data)) {
                apiData.podcastBookingRequests[data.podcast_id] ? '' : (apiData.podcastBookingRequests[data.podcast_id] = []);
                apiData.podcastBookingRequests[data.podcast_id].push(data);
            }
        }

        // gets a single booking request
        if (method == 'PATCH') {
            apiData.podcastBookingRequests[data.podcast_id] ? '' : (apiData.podcastBookingRequests[data.podcast_id] = []);
            let existingBookingRequest = apiData.podcastBookingRequests[data.podcast_id].find((item) => item.id === data.id);
            if (existingBookingRequest) {
                Object.assign(existingBookingRequest, data);
            } else {
                apiData.podcastBookingRequests[data.podcast_id].push(data);
            }
        }
    }

    if (type == 'inventoryReservations') {
        // console.log(`type: inventoryReservations', method: ${method}`);
        // gets an inventory object
        method == 'POST' ? (apiData.inventoryReservations[data.id] = data) : '';

        // gets a list OR a single inventory reservation
        if (method == 'GET') {
            if (Array.isArray(data) && data.length > 0) {
                data.forEach((reservation) => {
                    apiData.inventoryReservations[reservation.id] = reservation;
                });
            }
            if (!Array.isArray(data)) {
                apiData.inventoryReservations[data.id] = data;
            }
        }

        // for when a campaign gets deleted by the promoter
        // gets only the campaign_id as <data: {campaign_id: int}>
        if (method == 'DELETE' && info == 'campaigns') {
            Object.values(apiData.inventoryReservations).some((item) => {
                if (item.campaign_id === data.campaign_id) {
                    return delete apiData.inventoryReservations[item.id];
                }
            });
        } else if (method == 'DELETE') {
            // gets only the booking_request_id as <data: {booking_request_id: int}>
            Object.values(apiData.inventoryReservations).some((item) => {
                if (item.booking_request_id === data.booking_request_id) {
                    delete apiData.inventoryReservations[item.id];
                    return true;
                }
            });
        }
    }

    if (type == 'campaignInventoryReservations') {
        // console.log(`type: campaignInventoryReservations', method: ${method}`);
        // gets a single inventory reservation
        if (method == 'POST') {
            apiData.campaignInventoryReservations[data.campaign_id] ? '' : (apiData.campaignInventoryReservations[data.campaign_id] = []);
            apiData.campaignInventoryReservations[data.campaign_id].push(data);
        }

        // gets a list OR a single inventory reservation
        if (method == 'GET') {
            if (Array.isArray(data) && data.length > 0) {
                apiData.campaignInventoryReservations[data[0].campaign_id] = data;
            }
            if (!Array.isArray(data)) {
                apiData.campaignInventoryReservations[data.campaign_id] ? '' : (apiData.campaignInventoryReservations[data.campaign_id] = []);
                apiData.campaignInventoryReservations[data.campaign_id].push(data);
            }
        }

        // for when a campaign gets deleted by the promoter
        // gets only the campaign_id as <data: {campaign_id: int}>
        if (method == 'DELETE' && info == 'campaigns') {
            apiData.campaignInventoryReservations[data.campaign_id] ? delete apiData.campaignInventoryReservations[data.campaign_id] : '';
        } else if (method == 'DELETE') {
            // gets only the booking_request_id as <data: {booking_request_id: int}>
            Object.values(apiData.campaignInventoryReservations).some((reservations) => {
                let campaign_id;
                reservations.some((reservation, index) => {
                    if (reservation.booking_request_id === data.booking_request_id) {
                        campaign_id = reservation.campaign_id;
                        reservations.splice(index, 1);
                        return true;
                    }
                });
                // check if at the end no iRs are left for a campaign, to delete this entry as well!
                apiData.campaignInventoryReservations[campaign_id] && apiData.campaignInventoryReservations[campaign_id].length == 0 ? delete apiData.campaignInventoryReservations[campaign_id] : '';
            });
        }

        // for when a booking request gets deleted by the promoter
        // gets only the booking_request_id as <data: {booking_request_id: int}>
        if (method == 'DELETE' && info == 'bookingRequests') {
            apiData.campaignInventoryReservations[data.booking_request_id] ? delete apiData.campaignInventoryReservations[data.booking_request_id] : '';
        } else if (method == 'DELETE') {
            // gets only the booking_request_id as <data: {booking_request_id: int}>
            Object.values(apiData.campaignInventoryReservations).some((reservations) => {
                let booking_request_id;
                reservations.some((reservation, index) => {
                    if (reservation.booking_request_id === data.booking_request_id) {
                        booking_request_id = reservation.booking_request_id;
                        reservations.splice(index, 1);
                        return true;
                    }
                });
            });
        }
    }

    if (type == 'podcastInventoryReservations') {
        // console.log(`type: podcastInventoryReservations', method: ${method}`);
        // gets a single inventory reservation
        if (method == 'POST') {
            apiData.podcastInventoryReservations[data.podcast_id] ? '' : (apiData.podcastInventoryReservations[data.podcast_id] = []);
            apiData.podcastInventoryReservations[data.podcast_id].push(data);
        }

        // gets a list OR a single inventory reservation
        if (method == 'GET') {
            if (Array.isArray(data) && data.length > 0) {
                apiData.podcastInventoryReservations[data[0].podcast_id] = data;
            }
            if (!Array.isArray(data)) {
                apiData.podcastInventoryReservations[data.podcast_id] ? '' : (apiData.podcastInventoryReservations[data.podcast_id] = []);
                apiData.podcastInventoryReservations[data.podcast_id].push(data);
            }
        }

        // for when a campaign gets deleted by the promoter
        // gets only the campaign_id as <data: {campaign_id: int}>
        if (method == 'DELETE' && info == 'campaigns') {
            let podcast_id;
            Object.values(apiData.podcastInventoryReservations).some((reservations) => {
                reservations.some((reservation, index) => {
                    if (reservation.campaign_id === data.campaign_id) {
                        podcast_id = reservation.podcast_id;
                        reservations.splice(index, 1);
                        return true;
                    }
                });
            });
            // check if at the end no iRs are left for a podcast, to delete this entry as well!
            apiData.podcastInventoryReservations[podcast_id] && apiData.podcastInventoryReservations[podcast_id].length == 0 ? delete apiData.podcastInventoryReservations[podcast_id] : '';
        } else if (method == 'DELETE') {
            // gets only the booking_request_id as <data: {booking_request_id: int}>
            Object.values(apiData.podcastInventoryReservations).some((reservations) => {
                let podcast_id;
                reservations.some((reservation, index) => {
                    if (reservation.booking_request_id === data.booking_request_id) {
                        podcast_id = reservation.podcast_id;
                        reservations.splice(index, 1);
                        return true;
                    }
                });
                // check if at the end no iRs are left for a podcast, to delete this entry as well!
                apiData.podcastInventoryReservations[podcast_id] && apiData.podcastInventoryReservations[podcast_id].length == 0 ? delete apiData.podcastInventoryReservations[podcast_id] : '';
            });
        }

        // for when a booking request gets deleted by the promoter
        // gets only the booking_request_id as <data: {booking_request_id: int}>
        if (method == 'DELETE' && info == 'bookingRequests') {
            let podcast_id;
            Object.values(apiData.podcastInventoryReservations).some((reservations) => {
                reservations.some((reservation, index) => {
                    if (reservation.booking_request_id === data.booking_request_id) {
                        podcast_id = reservation.podcast_id;
                        reservations.splice(index, 1);
                        return true;
                    }
                });
            });
        } else if (method == 'DELETE') {
            // gets only the booking_request_id as <data: {booking_request_id: int}>
            Object.values(apiData.podcastInventoryReservations).some((reservations) => {
                let podcast_id;
                reservations.some((reservation, index) => {
                    if (reservation.booking_request_id === data.booking_request_id) {
                        podcast_id = reservation.podcast_id;
                        reservations.splice(index, 1);
                        return true;
                    }
                });
            });
        }
    }

    if (type == 'bookingRequestInventoryReservations') {
        // console.log(`type: bookingRequestInventoryReservations', method: ${method}`);
        // gets a single inventory reservation
        if (method == 'POST') {
            apiData.bookingRequestInventoryReservations[data.booking_request_id] ? '' : (apiData.bookingRequestInventoryReservations[data.booking_request_id] = []);
            apiData.bookingRequestInventoryReservations[data.booking_request_id].push(data);
        }

        // gets a list OR a single inventory reservation
        if (method == 'GET') {
            if (Array.isArray(data) && data.length > 0) {
                apiData.bookingRequestInventoryReservations[data[0].booking_request_id] = data;
            }
            if (!Array.isArray(data)) {
                apiData.bookingRequestInventoryReservations[data.booking_request_id] ? '' : (apiData.bookingRequestInventoryReservations[data.booking_request_id] = []);
                apiData.bookingRequestInventoryReservations[data.booking_request_id].push(data);
            }
        }

        // for when a campaign gets deleted by the promoter
        // gets only the campaign_id as <data: {campaign_id: int}>
        if (method == 'DELETE' && info == 'campaigns') {
            let bookingRequestId;
            Object.values(apiData.bookingRequestInventoryReservations).some((reservations) => {
                reservations.some((reservation, index) => {
                    if (reservation.campaign_id === data.campaign_id) {
                        bookingRequestId = reservation.booking_request_id;
                        reservations.splice(index, 1);
                        return true;
                    }
                });
            });
            // check if at the end no iRs are left for a campaign, to delete this entry as well!
            apiData.bookingRequestInventoryReservations[bookingRequestId] && apiData.bookingRequestInventoryReservations[bookingRequestId].length == 0
                ? delete apiData.bookingRequestInventoryReservations[bookingRequestId]
                : '';
        } else if (method == 'DELETE') {
            // gets only the booking_request_id as <data: {booking_request_id: int}>
            Object.values(apiData.bookingRequestInventoryReservations).some((reservations) => {
                reservations.some((reservation, index) => {
                    if (reservation.booking_request_id === data.booking_request_id) {
                        reservations.splice(index, 1);
                        return true;
                    }
                });
                // check if at the end no iRs are left for a campaign, to delete this entry as well!
                apiData.bookingRequestInventoryReservations[data.booking_request_id] && apiData.bookingRequestInventoryReservations[data.booking_request_id].length == 0
                    ? delete apiData.bookingRequestInventoryReservations[data.booking_request_id]
                    : '';
            });
        }

        // for when a booking request gets deleted by the promoter
        // gets only the booking_request_id as <data: {booking_request_id: int}>
        if (method == 'DELETE' && info == 'campaigns') {
            let bookingRequestId;
            Object.values(apiData.bookingRequestInventoryReservations).some((reservations) => {
                reservations.some((reservation, index) => {
                    if (reservation.booking_request_id === data.booking_request_id) {
                        bookingRequestId = reservation.booking_request_id;
                        reservations.splice(index, 1);
                        return true;
                    }
                });
            });
            // check if at the end no iRs are left for a booking request, to delete this entry as well!
            apiData.bookingRequestInventoryReservations[bookingRequestId] && apiData.bookingRequestInventoryReservations[bookingRequestId].length == 0
                ? delete apiData.bookingRequestInventoryReservations[bookingRequestId]
                : '';
        } else if (method == 'DELETE') {
            // gets only the booking_request_id as <data: {booking_request_id: int}>
            Object.values(apiData.bookingRequestInventoryReservations).some((reservations) => {
                reservations.some((reservation, index) => {
                    if (reservation.booking_request_id === data.booking_request_id) {
                        reservations.splice(index, 1);
                        return true;
                    }
                });
                // check if at the end no iRs are left for a booking request, to delete this entry as well!
                apiData.bookingRequestInventoryReservations[data.booking_request_id] && apiData.bookingRequestInventoryReservations[data.booking_request_id].length == 0
                    ? delete apiData.bookingRequestInventoryReservations[data.booking_request_id]
                    : '';
            });
        }
    }

    if (type == 'inventoryBookings') {
        // console.log(`type: inventoryBookings, method: ${method}`);

        // gets an inventory object
        method == 'POST' ? (apiData.inventoryBookings[data.id] = data) : '';

        // gets a list OR a single inventory booking
        if (method == 'GET') {
            if (Array.isArray(data) && data.length > 0) {
                data.forEach((booking) => {
                    apiData.inventoryBookings[booking.id] = booking;
                });
            }
            if (!Array.isArray(data)) {
                apiData.inventoryBookings[data.id] = data;
            }
        }

        // for when a campaign gets deleted by the promoter
        // gets only the campaign_id as <data: {campaign_id: int}>
        if (method == 'DELETE' && info == 'campaigns') {
            Object.values(apiData.inventoryBookings).some((item) => {
                if (item.campaign_id === data.campaign_id) {
                    return delete apiData.inventoryBookings[item.id];
                }
            });
        }
    }

    if (type == 'campaignInventoryBookings') {
        // console.log(`type: campaignInventoryBookings', method: ${method}`);

        // gets a single inventory booking
        if (method == 'POST') {
            apiData.campaignInventoryBookings[data.campaign_id] ? '' : (apiData.campaignInventoryBookings[data.campaign_id] = []);
            apiData.campaignInventoryBookings[data.campaign_id].push(data);
        }

        // gets a list OR a single inventory booking
        if (method == 'GET') {
            if (Array.isArray(data) && data.length > 0) {
                apiData.campaignInventoryBookings[data[0].campaign_id] = data;
            }
            if (!Array.isArray(data)) {
                apiData.campaignInventoryBookings[data.campaign_id] ? '' : (apiData.campaignInventoryBookings[data.campaign_id] = []);
                apiData.campaignInventoryBookings[data.campaign_id].push(data);
            }
        }

        // for when a campaign gets deleted by the promoter
        // gets only the campaign_id as <data: {campaign_id: int}>
        if (method == 'DELETE' && info == 'campaigns') {
            apiData.campaignInventoryBookings[data.campaign_id] ? delete apiData.campaignInventoryBookings[data.campaign_id] : '';
        } else if (method == 'DELETE') {
            // gets only the booking_request_id as <data: {booking_request_id: int}>
            Object.values(apiData.campaignInventoryBookings).some((bookings) => {
                let campaign_id;
                bookings.some((booking, index) => {
                    if (booking.booking_request_id === data.booking_request_id) {
                        campaign_id = booking.campaign_id;
                        bookings.splice(index, 1);
                        return true;
                    }
                });
                // check if at the end no iRs are left for a campaign, to delete this entry as well!
                apiData.campaignInventoryBookings[campaign_id] && apiData.campaignInventoryBookings[campaign_id].length == 0 ? delete apiData.campaignInventoryBookings[campaign_id] : '';
            });
        }

        // for when a bookingReqeuest gets deleted by the promoter
        // gets only the booking_request_id as <data: {booking_request_id: int}>
        if (method == 'DELETE' && info == 'bookingRequests') {
            apiData.campaignInventoryBookings[data.booking_request_id] ? delete apiData.campaignInventoryBookings[data.booking_request_id] : '';
        } else if (method == 'DELETE') {
            // gets only the booking_request_id as <data: {booking_request_id: int}>
            Object.values(apiData.campaignInventoryBookings).some((bookings) => {
                let booking_request_id;
                bookings.some((booking, index) => {
                    if (booking.booking_request_id === data.booking_request_id) {
                        booking_request_id = booking.booking_request_id;
                        bookings.splice(index, 1);
                        return true;
                    }
                });
            });
        }
    }

    if (type == 'podcastInventoryBookings') {
        // console.log(`type: podcastInventoryBookings', method: ${method}`);
        // gets a single inventory booking
        if (method == 'POST') {
            apiData.podcastInventoryBookings[data.podcast_id] ? '' : (apiData.podcastInventoryBookings[data.podcast_id] = []);
            apiData.podcastInventoryBookings[data.podcast_id].push(data);
        }

        // gets a list OR a single inventory booking
        if (method == 'GET') {
            if (Array.isArray(data) && data.length > 0) {
                apiData.podcastInventoryBookings[data[0].podcast_id] = data;
            }
            if (!Array.isArray(data)) {
                apiData.podcastInventoryBookings[data.podcast_id] ? '' : (apiData.podcastInventoryBookings[data.podcast_id] = []);
                apiData.podcastInventoryBookings[data.podcast_id].push(data);
            }
        }

        // for when a campaign gets deleted by the promoter
        // gets only the campaign_id as <data: {campaign_id: int}>
        if (method == 'DELETE' && info == 'campaigns') {
            let podcast_id;
            Object.values(apiData.podcastInventoryBookings).some((bookings) => {
                bookings.some((booking, index) => {
                    if (booking.campaign_id === data.campaign_id) {
                        podcast_id = booking.podcast_id;
                        bookings.splice(index, 1);
                        return true;
                    }
                });
            });
            // check if at the end no iBs are left for a podcast, to delete this entry as well!
            apiData.podcastInventoryBookings[podcast_id] && apiData.podcastInventoryBookings[podcast_id].length == 0 ? delete apiData.podcastInventoryBookings[podcast_id] : '';
        } else if (method == 'DELETE') {
            // gets only the booking_request_id as <data: {booking_request_id: int}>
            Object.values(apiData.podcastInventoryBookings).some((bookings) => {
                let podcast_id;
                bookings.some((booking, index) => {
                    if (booking.booking_request_id === data.booking_request_id) {
                        podcast_id = booking.podcast_id;
                        bookings.splice(index, 1);
                        return true;
                    }
                });
                // check if at the end no iRs are left for a podcast, to delete this entry as well!
                apiData.podcastInventoryBookings[podcast_id] && apiData.podcastInventoryBookings[podcast_id].length == 0 ? delete apiData.bookingRequestInventoryBookings[podcast_id] : '';
            });
        }

        // for when a booking request gets deleted by the promoter
        // gets only the booking_request_id as <data: {booking_request_id: int}>
        if (method == 'DELETE' && info == 'bookingRequests') {
            let podcast_id;
            Object.values(apiData.podcastInventoryBookings).some((bookings) => {
                bookings.some((booking, index) => {
                    if (booking.booking_request_id === data.booking_request_id) {
                        podcast_id = booking.podcast_id;
                        bookings.splice(index, 1);
                        return true;
                    }
                });
            });
        } else if (method == 'DELETE') {
            // gets only the booking_request_id as <data: {booking_request_id: int}>
            Object.values(apiData.podcastInventoryBookings).some((bookings) => {
                let podcast_id;
                bookings.some((booking, index) => {
                    if (booking.booking_request_id === data.booking_request_id) {
                        podcast_id = booking.podcast_id;
                        bookings.splice(index, 1);
                        return true;
                    }
                });
            });
        }

        if (type == 'bookingRequestInventoryBookings') {
            // console.log(`type: bookingRequestInventoryBookings', method: ${method}`);
            // gets a single inventory booking
            if (method == 'POST') {
                apiData.bookingRequestInventoryBookings[data.booking_request_id] ? '' : (apiData.bookingRequestInventoryBookings[data.booking_request_id] = []);
                apiData.bookingRequestInventoryBookings[data.booking_request_id].push(data);
            }

            // gets a list OR a single inventory booking
            if (method == 'GET') {
                if (Array.isArray(data) && data.length > 0) {
                    apiData.bookingRequestInventoryBookings[data[0].booking_request_id] = data;
                }
                if (!Array.isArray(data)) {
                    apiData.bookingRequestInventoryBookings[data.booking_request_id] ? '' : (apiData.bookingRequestInventoryBookings[data.booking_request_id] = []);
                    apiData.bookingRequestInventoryBookings[data.booking_request_id].push(data);
                }
            }

            // for when a booking request gets deleted by the promoter
            // gets only the booking_request_id as <data: {booking_request_id: int}>
            if (method == 'DELETE' && info == 'bookingRequests') {
                let bookingRequestId;
                Object.values(apiData.bookingRequestInventoryBookings).some((bookings) => {
                    bookings.some((booking, index) => {
                        if (booking.booking_request_id === data.booking_request_id) {
                            bookingRequestId = booking.booking_request_id;
                            bookings.splice(index, 1);
                            return true;
                        }
                    });
                });
                // check if at the end no iRs are left for a booking request, to delete this entry as well!
                apiData.bookingRequestInventoryBookings[bookingRequestId] && apiData.bookingRequestInventoryBookings[bookingRequestId].length == 0
                    ? delete apiData.bookingRequestInventoryBookings[bookingRequestId]
                    : '';
            } else if (method == 'DELETE') {
                // gets only the booking_request_id as <data: {booking_request_id: int}>
                Object.values(apiData.bookingRequestInventoryBookings).some((bookings) => {
                    bookings.some((booking, index) => {
                        if (booking.booking_request_id === data.booking_request_id) {
                            bookings.splice(index, 1);
                            return true;
                        }
                    });
                    // check if at the end no iRs are left for a booking request, to delete this entry as well!
                    apiData.bookingRequestInventoryBookings[data.booking_request_id] && apiData.bookingRequestInventoryBookings[data.booking_request_id].length == 0
                        ? delete apiData.bookingRequestInventoryBookings[data.booking_request_id]
                        : '';
                });
            }
        }
    }

    // console.log('apiData: ', apiData);
    // console.log('-----END SETTER--------');
}

export function countCacheCall(origin) {
    apiData.counterSolo[origin] ? '' : (apiData.counterSolo[origin] = 0);
    apiData.counterSolo[origin] += 1;
    // console.log('++++++ START COUNTER ++++++');
    // console.log(`+++ solo CACHE calls (${origin}): `, apiData.counterSolo[origin]);

    apiData.counter += 1;
    // console.log('+++ total CACHE calls: ', apiData.counter);
    // console.log('apiData: ', apiData);
    // console.log('++++++ END COUNTER ++++++');
}

export function countApiCall(origin) {
    apiData.apiCounterSolo[origin] ? ' ' : (apiData.apiCounterSolo[origin] = 0);
    apiData.apiCounterSolo[origin] += 1;
    // console.log('---------------------------');
    // console.log(`--- solo API calls (${origin}): `, apiData.apiCounterSolo[origin]);

    apiData.apiCounter += 1;
    // console.log('--- total API calls: ', apiData.apiCounter);
    // console.log('---------------------------');
}

export function getApiData() {
    return apiData;
}

export function clearApiCache() {
    apiData.counter = 0;
    apiData.counterSolo = {};
    apiData.apiCounter = 0;
    apiData.apiCounterSolo = {};
    apiData.whoami = {};
    apiData.campaigns = {};
    apiData.campaignsByCompany = {};
    apiData.podcastsOfCompany = {};
    apiData.podcasts = {};
    apiData.campaignCompanyInfo = {};
    apiData.bookingRequests = {};
    apiData.campaignBookingRequests = {};
    apiData.podcastBookingRequests = {};
    apiData.inventoryReservations = {};
    apiData.campaignInventoryReservations = {};
    apiData.podcastInventoryReservations = {};
    apiData.bookingRequestInventoryReservations = {};
    apiData.inventoryBookings = {};
    apiData.campaignInventoryBookings = {};
    apiData.podcastInventoryBookings = {};
    apiData.bookingRequestInventoryBookings = {};

    console.log('API-cache cleared: ', apiData);
}
