<script>
    // core components
    import CardTableAllPromoter from '../../components/Cards/CardTableAllPromoter.svelte';
    import CardTableCurrentPromoter from '../../components/Cards/CardTableCurrentPromoter.svelte';
    import CardTableRunningPromoter from '../../components/Cards/CardTableRunningPromoter.svelte';
    import CardTableCompletedPromoter from '../../components/Cards/CardTableCompletedPromoter.svelte';
    import CardTableArchivedPromoter from '../../components/Cards/CardTableArchivedPromoter.svelte';

    export let card;
</script>

{#if card == 'all'}
    <div class="flex flex-wrap mt-4">
        <div class="w-full mb-12 px-4">
            <CardTableAllPromoter />
        </div>
    </div>
{/if}
{#if card == 'current'}
    <div class="flex flex-wrap mt-4">
        <div class="w-full mb-12 px-4">
            <CardTableCurrentPromoter />
        </div>
    </div>
{/if}
{#if card == 'running'}
    <div class="flex flex-wrap mt-4">
        <div class="w-full mb-12 px-4">
            <CardTableRunningPromoter />
        </div>
    </div>
{/if}
{#if card == 'completed'}
    <div class="flex flex-wrap mt-4">
        <div class="w-full mb-12 px-4">
            <CardTableCompletedPromoter />
        </div>
    </div>
{/if}
{#if card == 'archived'}
    <div class="flex flex-wrap mt-4">
        <div class="w-full mb-12 px-4">
            <CardTableArchivedPromoter />
        </div>
    </div>
{/if}
