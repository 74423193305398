<script>
    import {databaseToPrettyDateTime} from '../../functions.js';
    import {getText} from '../../language.svelte';
    import {Pulse} from 'svelte-loading-spinners';
    import AudioPlayer from '../AudioPlayer.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';
    import CardBadgeTag from 'components/Cards/CardBadgeTag.svelte';
    export let bookingRequest;
    export let campaign;
    export let file;
    export let showUpdateButton;
    export let submitForm;
    export let copyToClipboard;
    export let downloadFromUrl;
    export let backgroundColor = 'bg-blueGray-200';
</script>

{#if bookingRequest.state > 2 && bookingRequest.state != 14}
    <form>
        {#if campaign.type == 'hostread' && campaign.briefing_completed && bookingRequest.script_approved_date}
            <div class="{backgroundColor} flex-auto mt-1 pt-1 px-4 lg:px-10 py-10 pt-0">
                <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                    {getText('podcast.booking_request_details.file_upload.title')}
                </h6>

                <div class="flex flex-wrap">
                    {#if bookingRequest.state == 2 || bookingRequest.state == 3 || bookingRequest.state == 5 || bookingRequest.state == 7 || bookingRequest.state == 8}
                        <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                                <div class="flex flex-wrap">
                                    <form>
                                        <input
                                            id="grid-audio-upload"
                                            class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            type="file"
                                            bind:files={file}
                                            on:change={() => {
                                                showUpdateButton = true;
                                            }}
                                        />
                                        {#if file && !bookingRequest.audiofile_url && showUpdateButton && (bookingRequest.state == 3 || bookingRequest.state == 5)}
                                            <div id="upload-pulse" style="display: none; justify-content: center; align-items: center; margin-right: 1em;">
                                                <Pulse size="60" color="#FF3E00" unit="px" duration="1s" />
                                            </div>
                                            <CardButton id="upload-audio" type="go" text={getText('podcast.booking_request_details.file_upload.button_upload')} on:click={() => submitForm('audio')} />
                                        {/if}
                                        {#if file && bookingRequest.audiofile_url && showUpdateButton && bookingRequest.state == 8}
                                            <div id="upload-pulse" style="display: none; justify-content: center; align-items: center; margin-right: 1em;">
                                                <Pulse size="60" color="#FF3E00" unit="px" duration="1s" />
                                            </div>
                                            <CardButton id="update-audio" type="go" text={getText('podcast.booking_request_details.file_upload.button_update')} on:click={() => submitForm('audio')} />
                                        {:else if file && bookingRequest.audiofile_url && showUpdateButton}
                                            <div id="upload-pulse" style="display: none; justify-content: center; align-items: center; margin-right: 1em;">
                                                <Pulse size="60" color="#FF3E00" unit="px" duration="1s" />
                                            </div>
                                            <CardButton
                                                id="update-audio"
                                                type="go"
                                                text={getText('podcast.booking_request_details.file_upload.button_update_hostread')}
                                                on:click={() => submitForm('audio')}
                                            />
                                        {/if}
                                        {#if bookingRequest.audiofile_url}
                                            <p
                                                id="grid-audio-file"
                                                class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            >
                                                {getText('podcast.booking_request_details.file_upload.label_1')}
                                                "{bookingRequest.audiofile_name_original}":
                                                <a class="underline" target="_blank" href={bookingRequest.audiofile_url}>{bookingRequest.audiofile_url}</a>
                                                <i
                                                    class="fas fa-copy active:color-emerald-600"
                                                    on:click={() => {
                                                        copyToClipboard(bookingRequest.audiofile_url);
                                                    }}
                                                />
                                                <i
                                                    class="fas fa-download active:color-emerald-600"
                                                    on:click={() => {
                                                        downloadFromUrl(bookingRequest.audiofile_url, bookingRequest.audiofile_name_original);
                                                    }}
                                                />
                                            </p>
                                            <AudioPlayer src={bookingRequest.audiofile_url} />
                                            <div class="flex flex-wrap">
                                                <div
                                                    class="lg:w-12/12 border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                >
                                                    {#if !bookingRequest.audiofile_approved_date}
                                                        <CardBadgeTag text={getText('badges.audio_status.entry_2', databaseToPrettyDateTime(bookingRequest.audiofile_uploaded_date))} />
                                                        <CardBadgeTag text="{getText('badges.general.entry_1')}{bookingRequest.audio_editor_user}" />
                                                    {:else if bookingRequest.audiofile_approved_date}
                                                        <CardBadgeTag text={getText('badges.audio_status.entry_1', databaseToPrettyDateTime(bookingRequest.audiofile_approved_date))} />
                                                        <CardBadgeTag text="{getText('badges.general.entry_1')}{bookingRequest.audio_editor_user}" />
                                                    {/if}
                                                </div>
                                            </div>
                                        {/if}
                                    </form>
                                </div>
                            </div>
                        </div>
                    {:else if bookingRequest.state == 4}
                        {#if bookingRequest.audiofile_url}
                            <div class="w-full lg:w-12/12 px-4">
                                <div class="relative w-full mb-3">
                                    <p
                                        id="grid-audio-file"
                                        class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    >
                                        {getText('podcast.booking_request_details.file_upload.label_1')}
                                        "{bookingRequest.audiofile_name_original}":
                                        <a class="underline" target="_blank" href={bookingRequest.audiofile_url}>{bookingRequest.audiofile_url}</a>
                                        <i
                                            class="fas fa-copy active:color-emerald-600"
                                            on:click={() => {
                                                copyToClipboard(bookingRequest.audiofile_url);
                                            }}
                                        />
                                        <i
                                            class="fas fa-download active:color-emerald-600"
                                            on:click={() => {
                                                downloadFromUrl(bookingRequest.audiofile_url, bookingRequest.audiofile_name_original);
                                            }}
                                        />
                                    </p>
                                    <AudioPlayer src={bookingRequest.audiofile_url} />
                                    <div class="flex flex-wrap">
                                        <div
                                            class="lg:w-6/12 border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        >
                                            {#if !bookingRequest.audiofile_uploaded_date}
                                                <CardBadgeTag text={getText('badges.audio_status.entry_2', databaseToPrettyDateTime(bookingRequest.audiofile_uploaded_date))} />
                                                <CardBadgeTag text="{getText('badges.general.entry_1')}{bookingRequest.audio_editor_user}" />
                                            {:else if bookingRequest.rejected_date}
                                                <CardBadgeTag text={getText('badges.booking_request.entry_1', databaseToPrettyDateTime(bookingRequest.rejected_date))} />
                                                <CardBadgeTag text="{getText('badges.general.entry_1')}{bookingRequest.audio_editor_user}" />
                                            {/if}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {/if}
                    {:else if bookingRequest.state == 6}
                        {#if bookingRequest.audiofile_url}
                            <div class="w-full lg:w-12/12 px-4">
                                <div class="relative w-full mb-3">
                                    <!-- <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-requested-impressions"> Audio </label> -->
                                    <p
                                        id="grid-audio-file"
                                        class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    >
                                        {getText('podcast.booking_request_details.file_upload.label_1')}
                                        "{bookingRequest.audiofile_name_original}":
                                        <a class="underline" target="_blank" href={bookingRequest.audiofile_url}>{bookingRequest.audiofile_url}</a>
                                        <i
                                            class="fas fa-copy active:color-emerald-600"
                                            on:click={() => {
                                                copyToClipboard(bookingRequest.audiofile_url);
                                            }}
                                        />
                                        <i
                                            class="fas fa-download active:color-emerald-600"
                                            on:click={() => {
                                                downloadFromUrl(bookingRequest.audiofile_url, bookingRequest.audiofile_name_original);
                                            }}
                                        />
                                    </p>
                                    <AudioPlayer src={bookingRequest.audiofile_url} />
                                    <div class="flex flex-wrap">
                                        <div
                                            class="lg:w-6/12 border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        >
                                            {#if !bookingRequest.audiofile_uploaded_date}
                                                <CardBadgeTag text={getText('badges.text_editor.entry_4', databaseToPrettyDateTime(bookingRequest.audiofile_uploaded_date))} />
                                                <CardBadgeTag text="{getText('badges.general.entry_1')}{bookingRequest.audio_editor_user}" />
                                            {:else if bookingRequest.cancelled_date}
                                                <CardBadgeTag text={getText('badges.booking_request.entry_2', databaseToPrettyDateTime(bookingRequest.cancelled_date))} />
                                                <CardBadgeTag text="{getText('badges.general.entry_1')}{bookingRequest.audio_editor_user}" />
                                            {/if}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {/if}
                    {:else if bookingRequest.state >= 9}
                        <div class="w-full lg:w-12/12 px-4 flex flex-wrap">
                            <div class="relative w-full mb-3">
                                <p
                                    id="grid-audio-file"
                                    class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                >
                                    {getText('podcast.booking_request_details.file_upload.label_5', bookingRequest.audiofile_name_original)}
                                    /
                                    <a class="underline" target="_blank" href={bookingRequest.audiofile_url}>{bookingRequest.audiofile_url}</a>
                                    <i
                                        class="fas fa-copy active:color-emerald-600"
                                        on:click={() => {
                                            copyToClipboard(bookingRequest.audiofile_url);
                                        }}
                                    />
                                    <i
                                        class="fas fa-download active:color-emerald-600"
                                        on:click={() => {
                                            downloadFromUrl(bookingRequest.audiofile_url, bookingRequest.audiofile_name_original);
                                        }}
                                    />
                                </p>
                                <AudioPlayer src={bookingRequest.audiofile_url} />
                                <div class="flex flex-wrap">
                                    {#if !bookingRequest.audiofile_approved_date}
                                        <CardBadgeTag text={getText('badges.audio_status.entry_2', databaseToPrettyDateTime(bookingRequest.audiofile_uploaded_date))} />
                                        <CardBadgeTag text="{getText('badges.general.entry_1')}{bookingRequest.audio_editor_user}" />
                                    {:else if bookingRequest.audiofile_approved_date}
                                        <CardBadgeTag text={getText('badges.audio_status.entry_1', databaseToPrettyDateTime(bookingRequest.audiofile_approved_date))} />
                                        <CardBadgeTag text="{getText('badges.general.entry_1')}{bookingRequest.audio_editor_user}" />
                                    {/if}
                                </div>
                            </div>
                        </div>
                    {/if}
                </div>
            </div>
        {/if}
    </form>
{/if}
