<script>
    import {Pulse} from 'svelte-loading-spinners';
    import {getBookingRequestsByCampaignIdCached, getPodcastCached, getCampaignsByCompanyCached, patchCampaign, getImpressionsByDealId} from '../../api/api.js';
    import {campaignBookingRequests, storedCompany} from '../../store.js';
    import {onMount} from 'svelte';
    import {navigate} from 'svelte-routing';
    import {getDateNow, prettyPrintNumber} from '../../functions.js';
    import {Accordion, AccordionItem} from 'svelte-collapsible';
    import RowBookingRequestPendingAction from 'components/TableRows/RowBookingRequestPendingAction.svelte';
    import RowBookingRequestNotActivated from 'components/TableRows/RowBookingRequestNotActivated.svelte';
    import RowBookingRequestOnAir from 'components/TableRows/RowBookingRequestOnAir.svelte';
    import RowBookingRequestCompleted from 'components/TableRows/RowBookingRequestCompleted.svelte';
    import CardStats from 'components/Cards/CardStats.svelte';
    import {getText} from '../../language.svelte';
    import {triggerCampaignRenderTotal, triggerCampaignRenderTodo} from '../../badgeManager.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    // can be one of light or dark table layout
    export let color = 'light';

    // get from storage
    let id = $storedCompany.id;

    onMount(() => {
        campaignBookingRequests.set({});
    });

    let accordionKey = null;
    const duration = 2; // seconds
    const easing = 'linear';

    let campaignsPromise = getCampaignData();
    let currentImpressionsPerCampaign = [];
    let requestedImpressionsPerCampaign = [];
    let requestedImpressionsPerCampaignPretty = [];
    let reservedImpressionsPerCampaign = [];
    let reservedImpressionsPerCampaignPretty = [];
    let dealedImpressionsPerCampaign = [];
    let dealedImpressionsPerCampaignPretty = [];

    $: $campaignBookingRequests && updateStates(), checkOnActivity();

    let campaigns;
    let archivedCampaigns = [];
    $: campaigns && updateStates();
    $: archivedCampaigns && checkOnCampaignActivity();

    async function updateStates() {
        for (let x in campaigns) {
            // handle bookingRequest state changes
            requestedImpressionsPerCampaign[campaigns[x].id] = 0;
            reservedImpressionsPerCampaign[campaigns[x].id] = 0;
            dealedImpressionsPerCampaign[campaigns[x].id] = 0;

            let bRs;
            try {
                bRs = await getBookingRequestsByCampaignIdCached(campaigns[x].id);
            } catch (e) {
                console.log(`Error ${e} occoured whilst getting the booking requests for campaign ${campaigns[x].id}`);
            }

            for (let y in bRs) {
                // sum dealed impressions
                if (bRs[y].state >= 8) {
                    dealedImpressionsPerCampaign[campaigns[x].id] += bRs[y].offered_impressions;
                }

                // sum reserved impressions
                if (bRs[y].state != 4 && bRs[y].state != 6) {
                    reservedImpressionsPerCampaign[campaigns[x].id] += bRs[y].offered_impressions;
                }

                // sum requested impressions
                if (bRs[y].state != 4 && bRs[y].state != 6) {
                    requestedImpressionsPerCampaign[campaigns[x].id] += bRs[y].requested_impressions;
                }
            }

            // make pretty
            requestedImpressionsPerCampaignPretty[campaigns[x].id] = prettyPrintNumber(requestedImpressionsPerCampaign[campaigns[x].id]);
            reservedImpressionsPerCampaignPretty[campaigns[x].id] = prettyPrintNumber(reservedImpressionsPerCampaign[campaigns[x].id]);
            dealedImpressionsPerCampaignPretty[campaigns[x].id] = prettyPrintNumber(dealedImpressionsPerCampaign[campaigns[x].id]);
        }
    }

    let allBookingRequests = [];
    async function getCampaignData() {
        let camps2;
        try {
            // console.log('CardTableCompletedPromoter');
            camps2 = await getCampaignsByCompanyCached(id);
        } catch (e) {
            console.log(`Error ${e} occoured whilst loading the campaigns of company ${id}`);
        }

        // update badges
        triggerCampaignRenderTotal(camps2);

        let camps = [];
        for (let x in camps2) {
            if (camps2[x].state >= 10) {
                camps.push(camps2[x]);
            }
            if (!camps2[x].archived) {
                doSomethingCampaign = [...doSomethingCampaign, camps2[x].id];
            }
        }

        campaigns = camps;
        // call activity check on load
        checkOnCampaignActivity();

        let campaignBookingRequestsLocal = {};
        for (let x in camps) {
            requestedImpressionsPerCampaign[camps[x].id] = 0;
            currentImpressionsPerCampaign[camps[x].id] = 0;
            reservedImpressionsPerCampaign[campaigns[x].id] = 0;
            dealedImpressionsPerCampaign[camps[x].id] = 0;

            let bRs;
            try {
                bRs = await getBookingRequestsByCampaignIdCached(camps[x].id);
            } catch (e) {
                console.log(`Error ${e} occoured whilst getting the booking requests for campaign ${camps[x].id}`);
            }

            allBookingRequests[camps[x].id] = bRs;

            campaignBookingRequestsLocal[camps[x].id] = {
                notActivatedBRs: [],
                pendingBRs: [],
                dealedBRs: [],
                onAirBRs: [],
                completedBRs: [],
                wrappedBRs: [],
                cancelledBRs: [],
                rejectedBRs: [],
                missedDeadline: [],
            };

            for (let y in bRs) {
                if (bRs[y].state == 1) {
                    if (campaignBookingRequestsLocal[camps[x].id].notActivatedBRs) {
                        campaignBookingRequestsLocal[camps[x].id].notActivatedBRs = [...campaignBookingRequestsLocal[camps[x].id].notActivatedBRs, bRs[y]];
                    } else {
                        campaignBookingRequestsLocal[camps[x].id].notActivatedBRs = bRs[y];
                    }
                } else if (bRs[y].state == 2 || bRs[y].state == 3 || bRs[y].state == 5 || bRs[y].state == 7) {
                    if (campaignBookingRequestsLocal[camps[x].id].pendingBRs) {
                        campaignBookingRequestsLocal[camps[x].id].pendingBRs = [...campaignBookingRequestsLocal[camps[x].id].pendingBRs, bRs[y]];
                    } else {
                        campaignBookingRequestsLocal[camps[x].id].pendingBRs = bRs[y];
                    }
                } else if (bRs[y].state == 4) {
                    if (campaignBookingRequestsLocal[camps[x].id].rejectedBRs) {
                        campaignBookingRequestsLocal[camps[x].id].rejectedBRs = [...campaignBookingRequestsLocal[camps[x].id].rejectedBRs, bRs[y]];
                    } else {
                        campaignBookingRequestsLocal[camps[x].id].rejectedBRs = bRs[y];
                    }
                } else if (bRs[y].state == 6) {
                    if (campaignBookingRequestsLocal[camps[x].id].cancelledBRs) {
                        campaignBookingRequestsLocal[camps[x].id].cancelledBRs = [...campaignBookingRequestsLocal[camps[x].id].cancelledBRs, bRs[y]];
                    } else {
                        campaignBookingRequestsLocal[camps[x].id].cancelledBRs = bRs[y];
                    }
                } else if (bRs[y].state == 8) {
                    if (campaignBookingRequestsLocal[camps[x].id].dealedBRs) {
                        campaignBookingRequestsLocal[camps[x].id].dealedBRs = [...campaignBookingRequestsLocal[camps[x].id].dealedBRs, bRs[y]];
                    } else {
                        campaignBookingRequestsLocal[camps[x].id].dealedBRs = bRs[y];
                    }
                    // sum dealed impressions
                    dealedImpressionsPerCampaign[camps[x].id] += bRs[y].offered_impressions;
                } else if (bRs[y].state == 9) {
                    if (campaignBookingRequestsLocal[camps[x].id].onAirBRs) {
                        campaignBookingRequestsLocal[camps[x].id].onAirBRs = [...campaignBookingRequestsLocal[camps[x].id].onAirBRs, bRs[y]];
                    } else {
                        campaignBookingRequestsLocal[camps[x].id].onAirBRs = bRs[y];
                    }
                } else if (bRs[y].state == 10 || bRs[y].state == 11) {
                    if (campaignBookingRequestsLocal[camps[x].id].completedBRs) {
                        campaignBookingRequestsLocal[camps[x].id].completedBRs = [...campaignBookingRequestsLocal[camps[x].id].completedBRs, bRs[y]];
                    } else {
                        campaignBookingRequestsLocal[camps[x].id].completedBRs = bRs[y];
                    }
                } else if (bRs[y].state == 12) {
                    if (campaignBookingRequestsLocal[camps[x].id].wrappedBRs) {
                        campaignBookingRequestsLocal[camps[x].id].wrappedBRs = [...campaignBookingRequestsLocal[camps[x].id].wrappedBRs, bRs[y]];
                    } else {
                        campaignBookingRequestsLocal[camps[x].id].wrappedBRs = bRs[y];
                    }
                } else if (bRs[y].state == 13 || bRs[y].state == 14) {
                    if (campaignBookingRequestsLocal[camps[x].id].missedDeadline) {
                        campaignBookingRequestsLocal[camps[x].id].missedDeadline = [...campaignBookingRequestsLocal[camps[x].id].missedDeadline, bRs[y]];
                    } else {
                        campaignBookingRequestsLocal[camps[x].id].missedDeadline = bRs[y];
                    }
                }

                // sum requested impressions
                if (bRs[y].state != 4 && bRs[y].state != 6) {
                    requestedImpressionsPerCampaign[camps[x].id] += bRs[y].requested_impressions;
                }

                // sum reserved impressions
                if (bRs[y].state != 4 && bRs[y].state != 6) {
                    reservedImpressionsPerCampaign[camps[x].id] += bRs[y].offered_impressions;
                }
            }
            // sum current campaign impression
            let current = await getCampaignImpressions(camps[x].id);
            currentImpressionsPerCampaign[camps[x].id] += current;

            // make pretty
            requestedImpressionsPerCampaignPretty[campaigns[x].id] = prettyPrintNumber(requestedImpressionsPerCampaign[campaigns[x].id]);
            reservedImpressionsPerCampaignPretty[campaigns[x].id] = prettyPrintNumber(reservedImpressionsPerCampaign[campaigns[x].id]);
            dealedImpressionsPerCampaignPretty[campaigns[x].id] = prettyPrintNumber(dealedImpressionsPerCampaign[campaigns[x].id]);
        }

        $campaignBookingRequests = campaignBookingRequestsLocal;

        return camps;
    }

    async function getCampaignImpressions(campaignId) {
        let all = 0;
        for (let bR in allBookingRequests[campaignId]) {
            if (allBookingRequests[campaignId][bR].deal_id !== null) {
                let snapshotImpressions = await getImpressionsByDealId(allBookingRequests[campaignId][bR].deal_id);
                all += snapshotImpressions.value;
            } else {
            }
        }
        return all;
    }

    async function archiveCampaign(campaign) {
        campaign.archived = true;
        try {
            await patchCampaign(campaign.id, campaign);
            archivedCampaigns = [...archivedCampaigns, campaign.id];
        } catch (e) {
            console.log(`Error ${e} occoured whilst archiving the campaign ${campaign}`);
        }
    }

    // activity control for icon indicator
    let doSomething = [];

    function checkOnActivity() {
        doSomething = [];
        if (Object.keys($campaignBookingRequests).length > 0) {
            for (let camp in $campaignBookingRequests) {
                for (let folder in $campaignBookingRequests[camp]) {
                    if ($campaignBookingRequests[camp][folder].length > 0) {
                        $campaignBookingRequests[camp][folder].forEach((bR) => {
                            if (bR.state == 11) {
                                doSomething[camp] = true;
                            }
                        });
                    }
                }

                if (doSomething[camp] == false) {
                    accordionKey = null;
                }
            }
        }

        // update campaign toDo badges
        triggerCampaignRenderTodo();
    }

    let doSomethingCampaign;
    function checkOnCampaignActivity() {
        doSomethingCampaign = [];
        if (campaigns) {
            if (campaigns.length > 0) {
                for (let camp of campaigns) {
                    if (!camp.archived) {
                        doSomethingCampaign = [...doSomethingCampaign, camp.id];
                    } else {
                        doSomethingCampaign = doSomethingCampaign.filter((c) => c.id !== camp.id);
                    }
                }
            }
        }
        // update badges
        triggerCampaignRenderTodo();
        triggerCampaignRenderTotal(campaigns);
    }

    function calcPercentage(base, number) {
        if (base < number) {
            let increase = number - base;
            let res = (increase / base) * 100 + 100;
            return res.toFixed(2);
        }
        if (base > number) {
            let res = (number * 100) / base;
            return res.toFixed(2);
        }
        if (base == number) {
            return '100';
        }
    }

    function callUrl(dealId) {
        fetch('https://counter.svmaudio.com/impression/' + dealId);
    }

    function copyToClipboard(value) {
        navigator.clipboard.writeText(value);
    }
</script>

{#await campaignsPromise}
    <div style="display: flex; justify-content: center">
        <Pulse size="60" color="#FF3E00" unit="px" duration="1s" />
    </div>
{:then data}
    {#if $storedCompany.type == 'promoter'}
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">
            <div style="display: flex; justify-content: center" />
            <div class="rounded-t mb-0 px-4 py-3 border-0">
                <div class="flex flex-wrap items-center">
                    <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                        <h3 class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}">{getText('promoter.campaign_overview.hostread_table.title')}</h3>
                    </div>
                </div>
            </div>

            <div class="block w-full overflow-x-auto">
                <!-- Projects table -->
                <table class="items-center w-full bg-transparent border-collapse">
                    <thead>
                        <tr>
                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-700 text-red-200 border-red-600'}"
                            >
                                {getText('promoter.campaign_overview.hostread_table.headline_1')}
                            </th>

                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-700 text-red-200 border-red-600'}"
                            >
                                {getText('promoter.campaign_overview.hostread_table.headline_2')}
                            </th>

                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-700 text-red-200 border-red-600'}"
                            >
                                {getText('promoter.campaign_overview.hostread_table.headline_3')}
                            </th>

                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-700 text-red-200 border-red-600'}"
                            />
                        </tr>
                    </thead>
                    <tbody>
                        {#if Object.keys(data).length > 0}
                            {#each data as campaign (campaign.id)}
                                {#if doSomethingCampaign.includes(campaign.id)}
                                    {#if campaign.type == 'hostread' && !campaign.archived}
                                        <tr
                                            data-filter={campaign.title}
                                            data-id={campaign.id}
                                            on:click={() => {
                                                accordionKey = accordionKey != campaign.id ? campaign.id : null;
                                            }}
                                            class="bg-blueGray-300 hover:cell-highlight-color cursor-pointer"
                                        >
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {#if campaign.state == 14}
                                                    <i class="fas fa-exclamation text-red-500" />
                                                {:else if doSomething[campaign.id]}
                                                    <i class="fas fa-bell text-orange-500" />
                                                {:else}
                                                    <i class="fas fa-check text-emerald-500" />
                                                {/if}
                                                <span id="grid-campaign-title-{campaign.id}" data-id={campaign.id} class="ml-3 font-bold {color === 'light' ? 'btext-blueGray-600' : 'text-whit'}">
                                                    {campaign.title}
                                                </span>
                                            </td>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {prettyPrintNumber(campaign.target_impressions)}</td>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {prettyPrintNumber(currentImpressionsPerCampaign[campaign.id])}
                                            </td>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                                                {#if !campaign.briefing_completed}
                                                    <CardButton
                                                        id="briefing"
                                                        type="go"
                                                        text={getText('promoter.campaign_overview.hostread_table.button_briefing')}
                                                        on:click={() => {
                                                            navigate(`/promoter/campaign/${campaign.id}/manager/${campaign.type}`);
                                                        }}
                                                    />
                                                {/if}
                                                {#if !campaign.archived}
                                                    <CardButton
                                                        id="archive"
                                                        type="default"
                                                        text={getText('promoter.campaign_overview.hostread_table.button_archive')}
                                                        on:click={(e) => {
                                                            archiveCampaign(campaign);
                                                            e.stopPropagation();
                                                        }}
                                                    />
                                                {/if}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap" colspan="4">
                                                <Accordion bind:key={accordionKey} {duration} {easing}>
                                                    <AccordionItem key={campaign.id}>
                                                        {#if accordionKey == campaign.id}
                                                            <table class="items-center w-full bg-transparent border-collapse mt-2 mb-8 shadow-lg">
                                                                <thead>
                                                                    <tr>
                                                                        <th
                                                                            class=" bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                                                        >
                                                                            "<em>{campaign.title}</em>" {getText('promoter.campaign_overview.targeting_monitor.title')}
                                                                        </th>
                                                                    </tr>
                                                                </thead>

                                                                <div class="flex flex-wrap">
                                                                    <div class="w-full lg:w-3/12 xl:w-3/12">
                                                                        <CardStats
                                                                            statSubtitle={getText('promoter.campaign_overview.targeting_monitor.section_1.title')}
                                                                            statTitle={prettyPrintNumber(campaign.target_impressions)}
                                                                            statIconName="fas fa-bullseye"
                                                                            statIconColor="bg-lightBlue-500"
                                                                            statDescription={getText('promoter.campaign_overview.targeting_monitor.section_1.description')}
                                                                        />
                                                                    </div>
                                                                    <div class="w-full lg:w-3/12 xl:w-3/12">
                                                                        {#if calcPercentage(campaign.target_impressions, requestedImpressionsPerCampaign[campaign.id]) <= 100}
                                                                            <CardStats
                                                                                statSubtitle={getText('promoter.campaign_overview.targeting_monitor.section_2.title')}
                                                                                bind:statTitle={requestedImpressionsPerCampaignPretty[campaign.id]}
                                                                                statPercent={calcPercentage(campaign.target_impressions, requestedImpressionsPerCampaign[campaign.id]) == 100
                                                                                    ? calcPercentage(campaign.target_impressions, requestedImpressionsPerCampaign[campaign.id])
                                                                                    : (calcPercentage(campaign.target_impressions, requestedImpressionsPerCampaign[campaign.id]) - 100).toFixed(2)}
                                                                                statPercentColor={requestedImpressionsPerCampaign[campaign.id] == campaign.target_impressions
                                                                                    ? 'text-emerald-500'
                                                                                    : 'text-orange-500'}
                                                                                statDescription={requestedImpressionsPerCampaign[campaign.id] == campaign.target_impressions
                                                                                    ? getText('promoter.campaign_overview.targeting_monitor.section_2.description_1')
                                                                                    : getText('promoter.campaign_overview.targeting_monitor.section_2.description_2')}
                                                                                statIconName={requestedImpressionsPerCampaign[campaign.id] == campaign.target_impressions
                                                                                    ? 'fas fa-check'
                                                                                    : 'fas fa-arrow-down'}
                                                                                statIconColor={requestedImpressionsPerCampaign[campaign.id] == campaign.target_impressions
                                                                                    ? 'bg-emerald-500'
                                                                                    : 'bg-orange-500'}
                                                                            />
                                                                        {:else if calcPercentage(campaign.target_impressions, requestedImpressionsPerCampaign[campaign.id]) > 100}
                                                                            <CardStats
                                                                                statSubtitle={getText('promoter.campaign_overview.targeting_monitor.section_2.title')}
                                                                                bind:statTitle={requestedImpressionsPerCampaignPretty[campaign.id]}
                                                                                statPercent={calcPercentage(campaign.target_impressions, requestedImpressionsPerCampaign[campaign.id]) == 100
                                                                                    ? calcPercentage(campaign.target_impressions, requestedImpressionsPerCampaign[campaign.id])
                                                                                    : (calcPercentage(campaign.target_impressions, requestedImpressionsPerCampaign[campaign.id]) - 100).toFixed(2)}
                                                                                statPercentColor={requestedImpressionsPerCampaign[campaign.id] == campaign.target_impressions
                                                                                    ? 'text-emerald-500'
                                                                                    : 'text-orange-500'}
                                                                                statDescription={requestedImpressionsPerCampaign[campaign.id] == campaign.target_impressions
                                                                                    ? getText('promoter.campaign_overview.targeting_monitor.section_2.description_1')
                                                                                    : getText('promoter.campaign_overview.targeting_monitor.section_2.description_3')}
                                                                                statIconName={requestedImpressionsPerCampaign[campaign.id] == campaign.target_impressions
                                                                                    ? 'fas fa-check'
                                                                                    : 'fas fa-arrow-down'}
                                                                                statIconColor={requestedImpressionsPerCampaign[campaign.id] == campaign.target_impressions
                                                                                    ? 'bg-emerald-500'
                                                                                    : 'bg-orange-500'}
                                                                            />
                                                                        {/if}
                                                                    </div>
                                                                    {#if Date.parse(getDateNow()) < Date.parse(campaign.start_date)}
                                                                        <div class="w-full lg:w-3/12 xl:w-3/12">
                                                                            <CardStats
                                                                                statSubtitle={getText('promoter.campaign_overview.targeting_monitor.section_3.title')}
                                                                                bind:statTitle={reservedImpressionsPerCampaignPretty[campaign.id]}
                                                                                statPercent={calcPercentage(requestedImpressionsPerCampaign[campaign.id], reservedImpressionsPerCampaign[campaign.id])}
                                                                                statPercentColor={reservedImpressionsPerCampaign[campaign.id] >= requestedImpressionsPerCampaign[campaign.id]
                                                                                    ? 'text-emerald-500'
                                                                                    : 'text-red-500'}
                                                                                statDescription={getText('promoter.campaign_overview.targeting_monitor.section_3.description')}
                                                                                statIconName="fas fa-ticket-alt"
                                                                                statIconColor={reservedImpressionsPerCampaign[campaign.id] >= requestedImpressionsPerCampaign[campaign.id]
                                                                                    ? 'bg-emerald-500'
                                                                                    : 'bg-red-500'}
                                                                            />
                                                                        </div>

                                                                        <div class="w-full lg:w-3/12 xl:w-3/12">
                                                                            <CardStats
                                                                                statSubtitle={getText('promoter.campaign_overview.targeting_monitor.section_4.title')}
                                                                                bind:statTitle={dealedImpressionsPerCampaignPretty[campaign.id]}
                                                                                statPercent={calcPercentage(reservedImpressionsPerCampaign[campaign.id], dealedImpressionsPerCampaign[campaign.id])}
                                                                                statPercentColor={dealedImpressionsPerCampaign[campaign.id] >= reservedImpressionsPerCampaign[campaign.id]
                                                                                    ? 'text-emerald-500'
                                                                                    : 'text-red-500'}
                                                                                statDescription={getText('promoter.campaign_overview.targeting_monitor.section_4.description_1')}
                                                                                statIconName="fas fa-handshake"
                                                                                statIconColor={dealedImpressionsPerCampaign[campaign.id] >= reservedImpressionsPerCampaign[campaign.id]
                                                                                    ? 'bg-emerald-500'
                                                                                    : 'bg-red-500'}
                                                                            />
                                                                        </div>
                                                                    {/if}

                                                                    {#if Date.parse(getDateNow()) >= Date.parse(campaign.start_date)}
                                                                        <div class="w-full lg:w-3/12 xl:w-3/12">
                                                                            <CardStats
                                                                                statSubtitle={getText('promoter.campaign_overview.targeting_monitor.section_4.title')}
                                                                                bind:statTitle={dealedImpressionsPerCampaignPretty[campaign.id]}
                                                                                statPercent={calcPercentage(requestedImpressionsPerCampaign[campaign.id], dealedImpressionsPerCampaign[campaign.id])}
                                                                                statPercentColor={dealedImpressionsPerCampaign[campaign.id] >= requestedImpressionsPerCampaign[campaign.id]
                                                                                    ? 'text-emerald-500'
                                                                                    : 'text-red-500'}
                                                                                statDescription={getText('promoter.campaign_overview.targeting_monitor.section_4.description_2')}
                                                                                statIconName="fas fa-handshake"
                                                                                statIconColor={dealedImpressionsPerCampaign[campaign.id] >= requestedImpressionsPerCampaign[campaign.id]
                                                                                    ? 'bg-emerald-500'
                                                                                    : 'bg-red-500'}
                                                                            />
                                                                        </div>
                                                                        <div class="w-full lg:w-3/12 xl:w-3/12">
                                                                            <CardStats
                                                                                statSubtitle={getText('promoter.campaign_overview.targeting_monitor.section_5.title')}
                                                                                bind:statTitle={currentImpressionsPerCampaign[campaign.id]}
                                                                                statPercent={calcPercentage(dealedImpressionsPerCampaign[campaign.id], currentImpressionsPerCampaign[campaign.id])}
                                                                                statPercentColor={currentImpressionsPerCampaign[campaign.id] >= dealedImpressionsPerCampaign[campaign.id]
                                                                                    ? 'text-emerald-500'
                                                                                    : 'text-red-500'}
                                                                                statDescription={getText('promoter.campaign_overview.targeting_monitor.section_5.description')}
                                                                                statIconName={currentImpressionsPerCampaign[campaign.id] >= dealedImpressionsPerCampaign[campaign.id]
                                                                                    ? 'fas fa-check'
                                                                                    : 'fas fa-arrow-down'}
                                                                                statIconColor={currentImpressionsPerCampaign[campaign.id] >= dealedImpressionsPerCampaign[campaign.id]
                                                                                    ? 'bg-emerald-500'
                                                                                    : 'bg-red-500'}
                                                                            />
                                                                        </div>
                                                                    {/if}
                                                                </div>
                                                            </table>

                                                            <table class="items-center w-full bg-transparent border-collapse mt-2 mb-8 shadow-lg">
                                                                <thead>
                                                                    <tr>
                                                                        <th
                                                                            class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                                                        >
                                                                            {getText('promoter.campaign_overview.booking_requests_table.headline_1')}
                                                                        </th>
                                                                        <th
                                                                            class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                                                        >
                                                                            {getText('promoter.campaign_overview.booking_requests_table.headline_2')}
                                                                        </th>
                                                                        <th
                                                                            class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                                                        >
                                                                            {getText('promoter.campaign_overview.booking_requests_table.headline_3')}
                                                                        </th>
                                                                        <th
                                                                            class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                                                        >
                                                                            {getText('promoter.campaign_overview.booking_requests_table.headline_4')}
                                                                        </th>
                                                                        <th
                                                                            class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                                                        />
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {#each $campaignBookingRequests[campaign.id].notActivatedBRs as bookingRequest}
                                                                        {#await getPodcastCached(bookingRequest.podcast_id) then data}
                                                                            <RowBookingRequestNotActivated podcastName={data.name} {bookingRequest} />
                                                                        {/await}
                                                                    {/each}

                                                                    {#each $campaignBookingRequests[campaign.id].pendingBRs as bookingRequest}
                                                                        <RowBookingRequestPendingAction {campaign} {bookingRequest} bind:requestedImpressionsPerCampaign />
                                                                    {/each}

                                                                    {#each $campaignBookingRequests[campaign.id].dealedBRs as bookingRequest}
                                                                        <RowBookingRequestPendingAction {campaign} {bookingRequest} bind:requestedImpressionsPerCampaign />
                                                                    {/each}

                                                                    {#each $campaignBookingRequests[campaign.id].onAirBRs as bookingRequest}
                                                                        {#await getPodcastCached(bookingRequest.podcast_id) then data}
                                                                            <RowBookingRequestOnAir {campaign} podcastName={data.name} {bookingRequest} />
                                                                        {/await}
                                                                    {/each}

                                                                    {#each $campaignBookingRequests[campaign.id].completedBRs as bookingRequest}
                                                                        {#await getPodcastCached(bookingRequest.podcast_id) then data}
                                                                            <RowBookingRequestCompleted {campaign} podcastName={data.name} {bookingRequest} />
                                                                        {/await}
                                                                    {/each}

                                                                    {#each $campaignBookingRequests[campaign.id].wrappedBRs as bookingRequest}
                                                                        {#await getPodcastCached(bookingRequest.podcast_id) then data}
                                                                            <RowBookingRequestCompleted {campaign} podcastName={data.name} {bookingRequest} />
                                                                        {/await}
                                                                    {/each}

                                                                    {#each $campaignBookingRequests[campaign.id].cancelledBRs as bookingRequest}
                                                                        <RowBookingRequestPendingAction {campaign} {bookingRequest} bind:requestedImpressionsPerCampaign />
                                                                    {/each}
                                                                    {#each $campaignBookingRequests[campaign.id].rejectedBRs as bookingRequest}
                                                                        <RowBookingRequestPendingAction {campaign} {bookingRequest} bind:requestedImpressionsPerCampaign />
                                                                    {/each}

                                                                    {#each $campaignBookingRequests[campaign.id].missedDeadline as bookingRequest}
                                                                        <RowBookingRequestPendingAction {campaign} {bookingRequest} bind:requestedImpressionsPerCampaign />
                                                                    {/each}
                                                                </tbody>
                                                            </table>
                                                        {/if}
                                                    </AccordionItem>
                                                </Accordion>
                                            </td>
                                        </tr>
                                    {/if}
                                {/if}
                            {/each}
                        {/if}
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Spot Table -->
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">
            <div style="display: flex; justify-content: center" />
            <div class="rounded-t mb-0 px-4 py-3 border-0">
                <div class="flex flex-wrap items-center">
                    <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                        <h3 class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}">{getText('promoter.campaign_overview.spot_table.title')}</h3>
                    </div>
                </div>
            </div>

            <div class="block w-full overflow-x-auto">
                <!-- Projects table -->
                <table class="items-center w-full bg-transparent border-collapse">
                    <thead>
                        <tr>
                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-700 text-red-200 border-red-600'}"
                            >
                                {getText('promoter.campaign_overview.spot_table.headline_1')}
                            </th>

                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-700 text-red-200 border-red-600'}"
                            >
                                {getText('promoter.campaign_overview.spot_table.headline_2')}
                            </th>

                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-700 text-red-200 border-red-600'}"
                            >
                                {getText('promoter.campaign_overview.spot_table.headline_3')}
                            </th>

                            <th
                                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                    : 'bg-red-700 text-red-200 border-red-600'}"
                            />
                        </tr>
                    </thead>
                    <tbody>
                        {#if Object.keys(data).length > 0}
                            {#each data as campaign (campaign.id)}
                                {#if doSomethingCampaign.includes(campaign.id)}
                                    {#if campaign.type == 'spot' && !campaign.archived}
                                        <tr
                                            data-filter={campaign.title}
                                            data-id={campaign.id}
                                            on:click={() => {
                                                accordionKey = accordionKey != campaign.id ? campaign.id : null;
                                            }}
                                            class="bg-blueGray-300 hover:cell-highlight-color cursor-pointer"
                                        >
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {#if campaign.state == 14}
                                                    <i class="fas fa-exclamation text-red-500" />
                                                {:else if doSomething[campaign.id]}
                                                    <i class="fas fa-bell text-orange-500" />
                                                {:else}
                                                    <i class="fas fa-check text-emerald-500" />
                                                {/if}
                                                <span class="ml-3 font-bold {color === 'light' ? 'btext-blueGray-600' : 'text-whit'}">{campaign.title}</span>
                                            </td>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {prettyPrintNumber(campaign.target_impressions)}</td>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {prettyPrintNumber(currentImpressionsPerCampaign[campaign.id])}
                                            </td>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                                                {#if !campaign.audiofile_url && campaign.state < 9}
                                                    <CardButton
                                                        id="briefing"
                                                        type="go"
                                                        text={getText('promoter.campaign_overview.spot_table.button_briefing')}
                                                        on:click={() => {
                                                            navigate(`/promoter/campaign/${campaign.id}/manager/${campaign.type}`);
                                                        }}
                                                    />
                                                {/if}
                                                {#if campaign.state == 14}
                                                    <CardButton id="cancel-campaign" type="cancel" text={getText('promoter.campaign_overview.spot_table.warning_campaign_cancelled')} disabled="true" />
                                                {/if}
                                                {#if !campaign.archived}
                                                    <CardButton
                                                        id="archive"
                                                        type="default"
                                                        text={getText('promoter.campaign_overview.hostread_table.button_archive')}
                                                        on:click={(e) => {
                                                            archiveCampaign(campaign);
                                                            e.stopPropagation();
                                                        }}
                                                    />
                                                {/if}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap" colspan="4">
                                                <Accordion bind:key={accordionKey} {duration} {easing}>
                                                    <AccordionItem key={campaign.id}>
                                                        {#if accordionKey == campaign.id}
                                                            <table class="items-center w-full bg-transparent border-collapse mt-2 mb-8 shadow-lg">
                                                                <thead>
                                                                    <tr>
                                                                        <th
                                                                            class=" bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                                                        >
                                                                            "<em>{campaign.title}</em>" Targeting Monitor
                                                                        </th>
                                                                    </tr>
                                                                </thead>

                                                                <div class="flex flex-wrap">
                                                                    <div class="w-full lg:w-3/12 xl:w-3/12">
                                                                        <CardStats
                                                                            statSubtitle={getText('promoter.campaign_overview.targeting_monitor.section_1.title')}
                                                                            statTitle={prettyPrintNumber(campaign.target_impressions)}
                                                                            statIconName="fas fa-bullseye"
                                                                            statIconColor="bg-lightBlue-500"
                                                                            statDescription={getText('promoter.campaign_overview.targeting_monitor.section_1.description')}
                                                                        />
                                                                    </div>
                                                                    <div class="w-full lg:w-3/12 xl:w-3/12">
                                                                        {#if calcPercentage(campaign.target_impressions, requestedImpressionsPerCampaign[campaign.id]) <= 100}
                                                                            <CardStats
                                                                                statSubtitle={getText('promoter.campaign_overview.targeting_monitor.section_2.title')}
                                                                                bind:statTitle={requestedImpressionsPerCampaignPretty[campaign.id]}
                                                                                statPercent={calcPercentage(campaign.target_impressions, requestedImpressionsPerCampaign[campaign.id]) == 100
                                                                                    ? calcPercentage(campaign.target_impressions, requestedImpressionsPerCampaign[campaign.id])
                                                                                    : (calcPercentage(campaign.target_impressions, requestedImpressionsPerCampaign[campaign.id]) - 100).toFixed(2)}
                                                                                statPercentColor={requestedImpressionsPerCampaign[campaign.id] == campaign.target_impressions
                                                                                    ? 'text-emerald-500'
                                                                                    : 'text-orange-500'}
                                                                                statDescription={requestedImpressionsPerCampaign[campaign.id] == campaign.target_impressions
                                                                                    ? getText('promoter.campaign_overview.targeting_monitor.section_2.description_1')
                                                                                    : getText('promoter.campaign_overview.targeting_monitor.section_2.description_2')}
                                                                                statIconName={requestedImpressionsPerCampaign[campaign.id] == campaign.target_impressions
                                                                                    ? 'fas fa-check'
                                                                                    : 'fas fa-arrow-down'}
                                                                                statIconColor={requestedImpressionsPerCampaign[campaign.id] == campaign.target_impressions
                                                                                    ? 'bg-emerald-500'
                                                                                    : 'bg-orange-500'}
                                                                            />
                                                                        {:else if calcPercentage(campaign.target_impressions, requestedImpressionsPerCampaign[campaign.id]) > 100}
                                                                            <CardStats
                                                                                statSubtitle={getText('promoter.campaign_overview.targeting_monitor.section_2.title')}
                                                                                bind:statTitle={requestedImpressionsPerCampaignPretty[campaign.id]}
                                                                                statPercent={calcPercentage(campaign.target_impressions, requestedImpressionsPerCampaign[campaign.id]) == 100
                                                                                    ? calcPercentage(campaign.target_impressions, requestedImpressionsPerCampaign[campaign.id])
                                                                                    : (calcPercentage(campaign.target_impressions, requestedImpressionsPerCampaign[campaign.id]) - 100).toFixed(2)}
                                                                                statPercentColor={requestedImpressionsPerCampaign[campaign.id] == campaign.target_impressions
                                                                                    ? 'text-emerald-500'
                                                                                    : 'text-orange-500'}
                                                                                statDescription={requestedImpressionsPerCampaign[campaign.id] == campaign.target_impressions
                                                                                    ? getText('promoter.campaign_overview.targeting_monitor.section_2.description_1')
                                                                                    : getText('promoter.campaign_overview.targeting_monitor.section_2.description_3')}
                                                                                statIconName={requestedImpressionsPerCampaign[campaign.id] == campaign.target_impressions
                                                                                    ? 'fas fa-check'
                                                                                    : 'fas fa-arrow-down'}
                                                                                statIconColor={requestedImpressionsPerCampaign[campaign.id] == campaign.target_impressions
                                                                                    ? 'bg-emerald-500'
                                                                                    : 'bg-orange-500'}
                                                                            />
                                                                        {/if}
                                                                    </div>
                                                                    {#if Date.parse(getDateNow()) < Date.parse(campaign.start_date)}
                                                                        <div class="w-full lg:w-3/12 xl:w-3/12">
                                                                            <CardStats
                                                                                statSubtitle={getText('promoter.campaign_overview.targeting_monitor.section_3.title')}
                                                                                bind:statTitle={reservedImpressionsPerCampaignPretty[campaign.id]}
                                                                                statPercent={calcPercentage(requestedImpressionsPerCampaign[campaign.id], reservedImpressionsPerCampaign[campaign.id])}
                                                                                statPercentColor={reservedImpressionsPerCampaign[campaign.id] >= requestedImpressionsPerCampaign[campaign.id]
                                                                                    ? 'text-emerald-500'
                                                                                    : 'text-red-500'}
                                                                                statDescription={getText('promoter.campaign_overview.targeting_monitor.section_3.description')}
                                                                                statIconName="fas fa-ticket-alt"
                                                                                statIconColor={reservedImpressionsPerCampaign[campaign.id] >= requestedImpressionsPerCampaign[campaign.id]
                                                                                    ? 'bg-emerald-500'
                                                                                    : 'bg-red-500'}
                                                                            />
                                                                        </div>

                                                                        <div class="w-full lg:w-3/12 xl:w-3/12">
                                                                            <CardStats
                                                                                statSubtitle={getText('promoter.campaign_overview.targeting_monitor.section_4.title')}
                                                                                bind:statTitle={dealedImpressionsPerCampaignPretty[campaign.id]}
                                                                                statPercent={calcPercentage(reservedImpressionsPerCampaign[campaign.id], dealedImpressionsPerCampaign[campaign.id])}
                                                                                statPercentColor={dealedImpressionsPerCampaign[campaign.id] >= reservedImpressionsPerCampaign[campaign.id]
                                                                                    ? 'text-emerald-500'
                                                                                    : 'text-red-500'}
                                                                                statDescription={getText('promoter.campaign_overview.targeting_monitor.section_4.description_1')}
                                                                                statIconName="fas fa-handshake"
                                                                                statIconColor={dealedImpressionsPerCampaign[campaign.id] >= reservedImpressionsPerCampaign[campaign.id]
                                                                                    ? 'bg-emerald-500'
                                                                                    : 'bg-red-500'}
                                                                            />
                                                                        </div>
                                                                    {/if}

                                                                    {#if Date.parse(getDateNow()) >= Date.parse(campaign.start_date)}
                                                                        <div class="w-full lg:w-3/12 xl:w-3/12">
                                                                            <CardStats
                                                                                statSubtitle={getText('promoter.campaign_overview.targeting_monitor.section_4.title')}
                                                                                bind:statTitle={dealedImpressionsPerCampaignPretty[campaign.id]}
                                                                                statPercent={calcPercentage(requestedImpressionsPerCampaign[campaign.id], dealedImpressionsPerCampaign[campaign.id])}
                                                                                statPercentColor={dealedImpressionsPerCampaign[campaign.id] >= requestedImpressionsPerCampaign[campaign.id]
                                                                                    ? 'text-emerald-500'
                                                                                    : 'text-red-500'}
                                                                                statDescription={getText('promoter.campaign_overview.targeting_monitor.section_4.description_2')}
                                                                                statIconName="fas fa-handshake"
                                                                                statIconColor={dealedImpressionsPerCampaign[campaign.id] >= requestedImpressionsPerCampaign[campaign.id]
                                                                                    ? 'bg-emerald-500'
                                                                                    : 'bg-red-500'}
                                                                            />
                                                                        </div>
                                                                        <div class="w-full lg:w-3/12 xl:w-3/12">
                                                                            <CardStats
                                                                                statSubtitle={getText('promoter.campaign_overview.targeting_monitor.section_5.title')}
                                                                                bind:statTitle={currentImpressionsPerCampaign[campaign.id]}
                                                                                statPercent={calcPercentage(dealedImpressionsPerCampaign[campaign.id], currentImpressionsPerCampaign[campaign.id])}
                                                                                statPercentColor={currentImpressionsPerCampaign[campaign.id] >= dealedImpressionsPerCampaign[campaign.id]
                                                                                    ? 'text-emerald-500'
                                                                                    : 'text-red-500'}
                                                                                statDescription={getText('promoter.campaign_overview.targeting_monitor.section_5.description')}
                                                                                statIconName={currentImpressionsPerCampaign[campaign.id] >= dealedImpressionsPerCampaign[campaign.id]
                                                                                    ? 'fas fa-check'
                                                                                    : 'fas fa-arrow-down'}
                                                                                statIconColor={currentImpressionsPerCampaign[campaign.id] >= dealedImpressionsPerCampaign[campaign.id]
                                                                                    ? 'bg-emerald-500'
                                                                                    : 'bg-red-500'}
                                                                            />
                                                                        </div>
                                                                    {/if}
                                                                </div>
                                                            </table>

                                                            <table class="items-center w-full bg-transparent border-collapse mt-2 mb-8 shadow-lg">
                                                                <thead>
                                                                    <tr>
                                                                        <th
                                                                            class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                                                        >
                                                                            {getText('promoter.campaign_overview.booking_requests_table.headline_1')}
                                                                        </th>
                                                                        <th
                                                                            class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                                                        >
                                                                            {getText('promoter.campaign_overview.booking_requests_table.headline_2')}
                                                                        </th>
                                                                        <th
                                                                            class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                                                        >
                                                                            {getText('promoter.campaign_overview.booking_requests_table.headline_3')}
                                                                        </th>
                                                                        <th
                                                                            class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                                                        >
                                                                            {getText('promoter.campaign_overview.booking_requests_table.headline_4')}
                                                                        </th>
                                                                        <th
                                                                            class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                                                        />
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {#each $campaignBookingRequests[campaign.id].notActivatedBRs as bookingRequest}
                                                                        {#await getPodcastCached(bookingRequest.podcast_id) then data}
                                                                            <RowBookingRequestNotActivated podcastName={data.name} {bookingRequest} />
                                                                        {/await}
                                                                    {/each}
                                                                    {#each $campaignBookingRequests[campaign.id].pendingBRs as bookingRequest}
                                                                        <RowBookingRequestPendingAction {campaign} {bookingRequest} bind:requestedImpressionsPerCampaign />
                                                                    {/each}

                                                                    {#each $campaignBookingRequests[campaign.id].dealedBRs as bookingRequest}
                                                                        <RowBookingRequestPendingAction {campaign} {bookingRequest} bind:requestedImpressionsPerCampaign />
                                                                    {/each}

                                                                    {#each $campaignBookingRequests[campaign.id].onAirBRs as bookingRequest}
                                                                        {#await getPodcastCached(bookingRequest.podcast_id) then data}
                                                                            <RowBookingRequestOnAir {campaign} podcastName={data.name} {bookingRequest} />
                                                                        {/await}
                                                                    {/each}

                                                                    {#each $campaignBookingRequests[campaign.id].completedBRs as bookingRequest}
                                                                        {#await getPodcastCached(bookingRequest.podcast_id) then data}
                                                                            <RowBookingRequestCompleted {campaign} podcastName={data.name} {bookingRequest} />
                                                                        {/await}
                                                                    {/each}

                                                                    {#each $campaignBookingRequests[campaign.id].wrappedBRs as bookingRequest}
                                                                        {#await getPodcastCached(bookingRequest.podcast_id) then data}
                                                                            <RowBookingRequestCompleted {campaign} podcastName={data.name} {bookingRequest} />
                                                                        {/await}
                                                                    {/each}

                                                                    {#each $campaignBookingRequests[campaign.id].cancelledBRs as bookingRequest}
                                                                        <RowBookingRequestPendingAction {campaign} {bookingRequest} bind:requestedImpressionsPerCampaign />
                                                                    {/each}
                                                                    {#each $campaignBookingRequests[campaign.id].rejectedBRs as bookingRequest}
                                                                        <RowBookingRequestPendingAction {campaign} {bookingRequest} bind:requestedImpressionsPerCampaign />
                                                                    {/each}
                                                                </tbody>
                                                            </table>
                                                        {/if}
                                                    </AccordionItem>
                                                </Accordion>
                                            </td>
                                        </tr>
                                    {/if}
                                {/if}
                            {/each}
                        {/if}
                    </tbody>
                </table>
            </div>
        </div>
    {/if}
{/await}
