<script>
    // core components
    import {storedUser, render} from '../store.js';
    import {getText} from '../language.svelte';
    import {patchUser, forgotPassword, uploadFile} from '../api/api.js';
    import {clearApiCache} from '../api/api-cache';
    import {navigate} from 'svelte-routing';
    import {Pulse} from 'svelte-loading-spinners';
    import CardButton from 'components/Cards/CardButton.svelte';

    $render = Math.random();

    function toggleEmail() {
        renameEmail = !renameEmail;
    }
    function toggleName() {
        renameName = !renameName;
    }

    async function updateUserEmail() {
        if (email) {
            let newUser = {
                email: email,
            };

            try {
                await patchUser(newUser);
                $storedUser.email = email;
                clearApiCache();
                navigate('/auth/logout');
            } catch (e) {
                window.alert(`Email "${email}" adress either exists already or is not valid`);
                email = '';
            }
        }
    }

    async function updateUserName() {
        if (name) {
            let newUser = {
                first_name: name,
            };

            let response = await patchUser(newUser);
            if (response.status == 200) {
                $storedUser.first_name = name;
            } else {
                window.alert(`Username could not be updated.`);
                name = '';
            }
        }
    }

    async function updateUserImage(file) {
        // show uploading image
        uploading = true;
        let formData = new FormData();

        // let fileName = file[0].name;
        // fileName = fileName.replaceAll(' ', '-');
        // fileName = crypto.randomUUID() + '.' + fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);

        // name file after user id to prevent storage spam
        let fileName = $storedUser.id + '.jpg';

        formData.append('file', file[0]);
        formData.append('filename', fileName);

        if (file) {
            disableButtons();

            // upload file to s3
            try {
                let imageURL,
                    response = await uploadFile(formData, 'image')
                        .then((response) => {
                            imageURL = `https://asap-dispo.s3.amazonaws.com/images/${fileName}`;
                            return imageURL, response;
                        })
                        .catch(() => {
                            enabledButtons();
                        });

                if (response.status == 200) {
                    // s3 URL save to postgre
                    let newUser = {
                        image_url: imageURL,
                    };
                    let response = await patchUser(newUser);
                    if (response.status == 200) {
                        $storedUser.image_url = imageURL;
                    }
                }
            } catch (e) {
                console.log(`Error ${e} occoured whilst uploading the new image !`);
            }
        }
        uploading = false;
        enabledButtons();
    }

    function disableButtons() {
        document.getElementById('upload-pulse').style.display = 'flex';
        Array.from(document.querySelectorAll('input')).forEach((elem) => {
            elem.setAttribute('disabled', '');
            elem.style.filter = 'grayscale(1)';
        });
    }

    function enabledButtons() {
        if (document.getElementById('upload-pulse')) {
            document.getElementById('upload-pulse').style.display = 'none';
        }
        Array.from(document.querySelectorAll('input')).forEach((elem) => {
            elem.removeAttribute('disabled');
            elem.style.filter = 'grayscale(0)';
        });
    }

    async function requestPasswordToken() {
        try {
            let response = await forgotPassword({email: $storedUser.email});
            if (response.status == 202) {
                window.alert(getText('user.profile.snackbar_1'));
                clearApiCache();
                navigate('/auth/logout');
            }
        } catch (e) {
            console.log(`Error ${e} occoured whilst requesting a new password for user ${$storedUser}! Please contact the admin`);
        }
    }

    let uploading = false;
    let email;
    let name;
    let image = '';
    let renameEmail = false;
    let resetPassword = false;
    let renameName = false;
</script>

<div>
    <div class="container mx-auto px-4">
        <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg">
            <div class="px-6">
                <div class="flex flex-wrap justify-center">
                    <!-- TOP -->
                    <div class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                        <div class="relative">
                            <form>
                                <input
                                    id="grid-profile-image-upload"
                                    class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    type="file"
                                    style="display:none"
                                    bind:files={image}
                                    on:change={() => {
                                        updateUserImage(image);
                                    }}
                                />
                            </form>
                            <label for="grid-profile-image-upload">
                                <div id="upload-pulse" style="display: none;">
                                    <Pulse size="40" color="#FF3E00" unit="px" duration="1s" />
                                </div>
                                {#if !uploading}
                                    <img
                                        id="grid-profile-image"
                                        alt="..."
                                        src={$storedUser.image_url}
                                        class="hover:edit-pointer shadow-xl rounded-50 align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                                    />
                                {/if}
                            </label>
                        </div>
                    </div>
                    <!-- TOP RIGHT -->
                    <div class="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                        <div class="py-6 px-3 mt-32 sm:mt-0">
                            {#if renameEmail}
                                <CardButton
                                    id="save"
                                    type="go"
                                    text={getText('user.profile.button_save')}
                                    on:click={() => {
                                        toggleEmail();
                                        updateUserEmail();
                                    }}
                                />
                                <CardButton
                                    id="cancel"
                                    type="cancel"
                                    text={getText('user.profile.button_cancel')}
                                    on:click={() => {
                                        toggleEmail();
                                    }}
                                />
                            {/if}
                            {#if renameName}
                                <CardButton
                                    id="save"
                                    type="go"
                                    text={getText('user.profile.button_save')}
                                    on:click={() => {
                                        toggleName();
                                        updateUserName();
                                    }}
                                />
                                <CardButton
                                    id="cancel"
                                    type="cancel"
                                    text={getText('user.profile.button_cancel')}
                                    on:click={() => {
                                        toggleName();
                                    }}
                                />
                            {/if}
                        </div>
                    </div>
                    <!-- TOP LEFT -->
                    <div class="w-full lg:w-4/12 px-4 lg:order-1">
                        <!-- <div class="flex justify-center py-4 lg:pt-4 pt-8">
                            <div class="mr-4 p-3 text-center">
                                <span class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"> 22 </span>
                                <span class="text-sm text-blueGray-400">Friends</span>
                            </div>
                            <div class="mr-4 p-3 text-center">
                                <span class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"> 10 </span>
                                <span class="text-sm text-blueGray-400">Photos</span>
                            </div>
                            <div class="lg:mr-4 p-3 text-center">
                                <span class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"> 89 </span>
                                <span class="text-sm text-blueGray-400">Comments</span>
                            </div>
                        </div> -->
                    </div>
                </div>

                {#if !renameEmail && !renameName && !resetPassword}
                    <div class="text-center mt-12">
                        <!-- edit NAME -->
                        <h3
                            class="hover:edit-pointer text-4xl font-semibold leading-normal text-blueGray-700 mb-2"
                            on:click={() => {
                                toggleName();
                            }}
                        >
                            {$storedUser.first_name}
                            <i class="hover:edit-pointer fas fa-pen mr-2 text-sm text-blueGray-400 mx-4" />
                        </h3>

                        <!-- edit EMAIL -->
                        <div
                            class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase"
                            on:click={() => {
                                toggleEmail();
                            }}
                        >
                            <i class="fas fa-at mr-2 text-lg text-blueGray-400" />
                            {$storedUser.email}
                            <i class="hover:edit-pointer fas fa-pen mr-2 text-sm text-blueGray-400 mx-4" />
                        </div>

                        <!-- edit PASSWORD -->
                        <div class="mb-2 text-blueGray-600">
                            <i class="fas fa-lock mr-2 text-lg text-blueGray-400" />

                            <CardButton
                                id="request-token"
                                type="default"
                                text={getText('user.profile.button_reset_password')}
                                on:click={() => {
                                    requestPasswordToken();
                                }}
                            />
                        </div>
                    </div>
                {/if}
                {#if renameEmail}
                    <div class="text-center mt-12">
                        <div class="text-center mt-12">
                            <input
                                id="grid-first-name"
                                type="input"
                                class="text-sm font-semibold leading-normal mt-0 mb-2 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder={$storedUser.email}
                                bind:value={email}
                                on:keydown={(e) => {
                                    if (e.code == 'Escape') toggleEmail();
                                    if (e.code == 'Enter') {
                                        updateUserEmail();
                                    }
                                }}
                            />
                        </div>
                    </div>
                {/if}
                {#if renameName}
                    <div class="text-center mt-12">
                        <div class="text-center mt-12">
                            <input
                                id="grid-first-name"
                                type="input"
                                class="text-sm font-semibold leading-normal mt-0 mb-2 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder={$storedUser.first_name}
                                bind:value={name}
                                on:keydown={(e) => {
                                    if (e.code == 'Escape') toggleName();
                                    if (e.code == 'Enter') {
                                        toggleName();
                                        updateUserName();
                                    }
                                }}
                            />
                        </div>
                    </div>
                {/if}
            </div>
        </div>
    </div>
</div>
