<script>
    import {getText} from '../../language.svelte';
    import {Accordion, AccordionItem} from 'svelte-collapsible';
    import {getAllPodcastsOfCompanyCached, getSelfpromosOfPodcast} from '../../api/api.js';
    import {storedCompany, podcastsSelfpromos, colorCodes, render} from '../../store.js';

    import {downloadFromUrl} from '../../functions.js';
    import CardSelfpromo from '../../components/Cards/CardSelfpromo.svelte';

    $render = Math.random();

    // can be one of light or dark table layout
    // export let color = 'light';

    const userAgents = ['alexa', 'amazon music', 'apple podcasts', 'audible', 'deezer', 'facebook', 'instagram', 'radio.de', 'samsung podcasts', 'spotify', 'tikTok'];
    let promisePodcasts = getPodcasts();

    // COLORS
    let green = 'bg-emerald-500';
    let orange = 'bg-orange-500';
    let yellow = 'bg-amber-300';
    let saveRed = 'bg-red-300';
    let saveGreen = 'bg-emerald-300';

    // ACCORDION
    let accordionKey = null;
    const duration = 0.5; // seconds
    const easing = 'linear';

    // PROMISE
    async function getPodcasts() {
        try {
            let allPodcasts = await getAllPodcastsOfCompanyCached($storedCompany.id);
            if (allPodcasts) {
                for (let podcast of allPodcasts) {
                    $podcastsSelfpromos[podcast.id] = {};

                    let selfpromos;

                    try {
                        selfpromos = await getSelfpromosOfPodcast(podcast.id)
                            .then((response) => response.json())
                            .then((data) => {
                                return data;
                            });
                        for (let promo of selfpromos) {
                            let promokey = promo.plattform;
                            $podcastsSelfpromos[podcast.id][promokey] = promo;
                        }
                    } catch (e) {
                        console.log(`Error ${e} occoured whilst loading promotions of podcast ${podcast.id}`);
                    }
                }
            }

            $colorCodes = JSON.parse(JSON.stringify($podcastsSelfpromos));
            return allPodcasts;
        } catch (e) {
            console.log(`Error ${e} occoured whilst loading podcasts of company ${$storedCompany.id}`);
        }
    }
</script>

<!-- INFO BOX -->
<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between" />
        <h6 class="text-blueGray-700 mb-4 text-xl font-bold">{getText('podcast.selfpromos.title_general')}</h6>
        <div class="block w-full overflow-x-auto">
            <Accordion bind:key={accordionKey} {duration} {easing}>
                <AccordionItem key="Info">
                    <div slot="header">
                        <div
                            class="header bg-teal-100 hover:cell-highlight-color text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                        >
                            <div class="ml-2">
                                <h2>Info</h2>
                            </div>
                        </div>
                    </div>

                    <p slot="body" class="body">
                        {#if accordionKey}
                            <div class="bg-blueGray-100 border-t-0 align-middle border-l-0 border-r-0 mb-4 p-1">
                                <div class="ml-2">
                                    {getText('podcast.selfpromos.description')}
                                </div>
                            </div>
                        {/if}
                    </p>
                </AccordionItem>
            </Accordion>
        </div>
    </div>
</div>

<!-- TABLE PER PODCAST ID -->
{#await promisePodcasts then data}
    {#each data as podcast}
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div class="rounded-t bg-white mb-0 px-6 py-6">
                <div class="text-center flex justify-between" />
                <h6 class="text-blueGray-700 mb-4 text-xl font-bold">{getText('podcast.selfpromos.title')} <i>{podcast.name}</i></h6>
                <div class="block w-full overflow-x-auto">
                    <Accordion bind:key={accordionKey} {duration} {easing}>
                        {#each userAgents as agent}
                            <CardSelfpromo accordionKey="{podcast.id}-{agent}" {podcast} {agent} {green} {yellow} {saveGreen} {saveRed} {orange} {downloadFromUrl} />
                        {/each}
                    </Accordion>
                </div>
            </div>
        </div>
    {/each}
{/await}
