<script>
    import {getText} from '../../language.svelte';
    import {getPodcastCompanyExclusives, getCompanyById, deleteNetworkCompanyPodcastExclusiveRelation, patchNetworkCompanyPodcastExclusiveRelation} from '../../api/api.js';
    import SnackbarNoDismiss from 'components/Snackbars/SnackbarNoDismiss.svelte';
    import SnackbarDismiss from 'components/Snackbars/SnackbarDismiss.svelte';
    import ModalConfirm from '../Modals/ModalConfirm.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let podcast;
    let company;
    let relationshipExists = false;
    let displayData = {};
    $: displayData;

    let snackbarNoDismissMsg;
    let snackbarNoDismissOpen = false;
    let snackbarNoDismissType;
    let snackbarDismissMsg;
    let snackbarDismissOpen = false;
    let snackbarDismissType;
    let snackbarDismissTitle;

    async function exclusivePromise() {
        let exclusive;
        try {
            exclusive = await getPodcastCompanyExclusives(podcast.id);
        } catch (e) {
            console.log(`Something went wrong retrieving the exclusive promoter information for ${podcast.name}: ${e} `);
        }

        if (exclusive.length > 0) {
            relationshipExists = true;
            company = await getCompanyById(exclusive[0].company_id);
            displayData = {companyName: company.name, confirmed: exclusive[0].confirmed};
            return displayData;
        } else {
            relationshipExists = false;
        }
    }

    function deleteRelationship() {
        try {
            deleteNetworkCompanyPodcastExclusiveRelation(company.id, podcast.id);
            relationshipExists = false;
            fireSnackbarNoDismiss(getText('ui_messages.snackbars.podcast.snackbar_exclusive_promoter_delete_success', company.name), 'success');
        } catch (e) {
            console.log(`Something went wrong deleting the exclusive relationship for ${podcast.name} and ${company.name}: ${e}`);
        }
    }

    async function confirmRelationship() {
        let newData = {
            confirmed: true,
        };
        try {
            await patchNetworkCompanyPodcastExclusiveRelation(company.id, podcast.id, newData);
            displayData.confirmed = true;
        } catch (e) {
            console.log(`Something went wrong activating the exclusive promotion of ${company.name} for ${podcast.name}`);
        }
    }

    function fireSnackbarNoDismiss(msg, type) {
        snackbarNoDismissMsg = msg;
        snackbarNoDismissType = type;
        snackbarNoDismissOpen = true;
    }

    function fireSnackbarDismiss(msg, type, title) {
        snackbarDismissMsg = msg;
        snackbarDismissType = type;
        snackbarDismissTitle = title;
        snackbarDismissOpen = true;
    }

    let podcastPromise = exclusivePromise();
</script>

{#if snackbarNoDismissOpen}
    <SnackbarNoDismiss {snackbarNoDismissMsg} bind:snackbarNoDismissOpen {snackbarNoDismissType} timeout="2000" />
{/if}

{#if snackbarDismissOpen}
    <SnackbarDismiss {snackbarDismissTitle} {snackbarDismissMsg} bind:snackbarDismissOpen {snackbarDismissType} />
{/if}

{#await podcastPromise then data}
    {#if relationshipExists}
        <div class="flex flex-wrap border-t-0 align-middle border-l-0 border-r-0 mb-4">
            <div class="flex-auto p-4">
                <div class="flex flex-wrap">
                    <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3">
                            {#if displayData.confirmed}
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-target-impressions">
                                    {getText('podcast.inventory.exclusive_promoter.section_1.title')}
                                </label>
                                <div class="relative flex min-w-0 break-words bg-white rounded xl:mb-0 shadow-lg mb-2 mt-2">
                                    <input
                                        id="grid-inventory-volume-month"
                                        type="text"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={displayData.companyName}
                                        disabled
                                        readonly="true"
                                    />
                                    <ModalConfirm buttonConfirm={getText('ui_messages.button_proceed')} buttonCancel={getText('ui_messages.button_cancel')} let:confirm={confirmThis}>
                                        <!-- <button
                                            type="button"
                                            class="flex-auto p-4 bg-red-300 ml-1 rounded"
                                            on:click={() => {
                                                confirmThis(deleteRelationship);
                                            }}
                                        >
                                            <i class="fas fa-trash" />
                                        </button> -->

                                        <!-- TODO test CardButtons when live again -->
                                        <CardButton
                                            id="delete-relation"
                                            type="custom"
                                            classList="flex-auto p-4 bg-red-300 ml-1 rounded"
                                            icon="fas fa-trash"
                                            on:click={() => {
                                                confirmThis(deleteRelationship);
                                            }}
                                        />
                                        <span slot="title">{getText('ui_messages.confirms.podcast.confirm_selfpromo_delete_title')}</span>
                                        <span slot="description">{getText('ui_messages.confirms.podcast.confirm_exclusive_promotion_delete', company.name)} </span>
                                    </ModalConfirm>
                                </div>
                            {/if}
                            {#if !displayData.confirmed}
                                <h4 class="mb-4">
                                    {getText('podcast.inventory.exclusive_promoter.section_3.title', [data.companyName, podcast.name, data.companyName])}
                                </h4>
                                <div class="text-center flex justify-between">
                                    <div class="text-center flex">
                                        <!-- <button
                                            type="button"
                                            class="flex-auto p-2 bg-red-300 rounded uppercase"
                                            on:click={() => {
                                                deleteRelationship();
                                            }}
                                        >
                                            <i class="fas fa-ban mx-1" />
                                            {getText('buttons.button_reject')}
                                        </button> -->

                                        <CardButton
                                            id="delete-relation"
                                            type="custom"
                                            classList="flex-auto p-2 bg-red-300 rounded uppercase"
                                            icon="fas fa-ban mx-1"
                                            text={getText('buttons.button_reject')}
                                            on:click={() => {
                                                deleteRelationship();
                                            }}
                                        />
                                    </div>
                                    <div class="text-center flex justify-between">
                                        <!-- <button
                                            type="button"
                                            class="flex-auto p-2 bg-emerald-300 rounded uppercase"
                                            on:click={() => {
                                                confirmRelationship();
                                            }}
                                        >
                                            <i class="fas fa-handshake mx-1" />
                                            {getText('buttons.button_agree')}
                                        </button> -->

                                        <CardButton
                                            id="confirm-relation"
                                            type="custom"
                                            classList="flex-auto p-2 bg-emerald-300 rounded uppercase"
                                            icon="fas fa-handshake mx-1"
                                            text={getText('buttons.button_agree')}
                                            on:click={() => {
                                                confirmRelationship();
                                            }}
                                        />
                                    </div>
                                </div>
                            {/if}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    {:else}
        <h4 class="font-semibold text-lg text-blueGray-700">{getText('podcast.inventory.exclusive_promoter.section_2.title')}</h4>
    {/if}
{/await}
