<script>
    import {AccordionItem} from 'svelte-collapsible';
    import {getText} from '../../language.svelte';
    import AudioPlayer from '../../components/AudioPlayer.svelte';
    import {databaseToDatePickerDate, unixTimestampToDatePicker, datePickerDateToDatabase, datePickerDateToColorCompare} from '../../functions.js';
    import {uploadFile, updateSelfpromo, saveSelfpromo, deleteSelfpromo} from '../../api/api.js';
    import {podcastsSelfpromos, colorCodes} from '../../store.js';
    import {onMount} from 'svelte';
    import SnackbarNoDismiss from 'components/Snackbars/SnackbarNoDismiss.svelte';
    import SnackbarDismiss from 'components/Snackbars/SnackbarDismiss.svelte';
    import ModalConfirm from '../Modals/ModalConfirm.svelte';

    export let podcast;
    export let agent;
    export let green, yellow, orange, saveRed, saveGreen;
    export let accordionKey = podcast.id + '-' + agent;

    export let downloadFromUrl = () => {};

    let audiofile;
    let audioNameOriginal = $podcastsSelfpromos[podcast.id][agent] ? $podcastsSelfpromos[podcast.id][agent].audio_name_original : '';
    let audiofileURL = $podcastsSelfpromos[podcast.id][agent] ? $podcastsSelfpromos[podcast.id][agent].audiofile_url : '';
    let startDate;
    let endDate;
    let dataComplete;
    let hasChanged;
    let showResetButton = false;
    let colorCodesIncluded;
    let podcastsSelfpromosIncluded;
    let editExistingSelfpromo = false;
    let duration_ms = 0;
    let audiofileDurationMs = 0;

    let snackbarNoDismissMsg;
    let snackbarNoDismissOpen = false;
    let snackbarNoDismissType;
    let snackbarDismissMsg;
    let snackbarDismissOpen = false;
    let snackbarDismissType;
    let snackbarDismissTitle;

    onMount(() => {
        if ($podcastsSelfpromos[podcast.id][agent]) {
            audiofileURL = $podcastsSelfpromos[podcast.id][agent].audiofile_url;
            audioNameOriginal = $podcastsSelfpromos[podcast.id][agent].audio_name_original;
            startDate = databaseToDatePickerDate($podcastsSelfpromos[podcast.id][agent].start_date);
            endDate = databaseToDatePickerDate($podcastsSelfpromos[podcast.id][agent].end_date);
        } else {
            audiofileURL = '';
            audioNameOriginal = '';
            startDate = '';
            endDate = '';
        }
    });

    // HANDLER
    async function uploadAudio(event) {
        let file = event.target.files[0];

        // event.preventDefault();
        let formData = new FormData();

        let fileName = file.name;
        fileName = fileName.replaceAll(' ', '-');

        audioNameOriginal = fileName;
        fileName = crypto.randomUUID() + '.' + fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);

        formData.append('file', file);
        formData.append('filename', fileName);

        if (!fileName.endsWith('mp3')) {
            audiofile = '';
            fireSnackbarDismiss(getText('ui_messages.alerts.general.alert_wrong_audioformat'), 'alert');
            return;
        }

        try {
            await uploadFile(formData, 'audio').then((response) => {
                audiofileURL = `https://asap-dispo.s3.amazonaws.com/audio/${fileName}`;
                let player = document.getElementById('grid-spot-audio-player');
                if (player) {
                    player.refresh();
                }
            });

            audiofileDurationMs = await getURLDuration(audiofileURL);

            // for new selfpromo
            if (!Object.keys($colorCodes[podcast.id]).includes(agent)) {
                $colorCodes[podcast.id][agent] = {};
                $colorCodes[podcast.id][agent].audiofile_url = audiofileURL;
                $colorCodes[podcast.id][agent].audio_name_original = audioNameOriginal;
                $colorCodes[podcast.id][agent].audiofile_duration_ms = audiofileDurationMs;
                audiofile = '';
            }

            if (Object.keys($colorCodes[podcast.id]).includes(agent)) {
                $colorCodes[podcast.id][agent].audiofile_url = audiofileURL;
                $colorCodes[podcast.id][agent].audio_name_original = audioNameOriginal;
                $colorCodes[podcast.id][agent].audiofile_duration_ms = audiofileDurationMs;
                audiofile = '';
            }

            // for exisiting selfpromo
            if ($podcastsSelfpromos[podcast.id][agent]) {
                let newSelfpromo = {
                    audiofile_url: audiofileURL,
                    audio_name_original: audioNameOriginal,
                    audiofile_duration_ms: audiofileDurationMs,
                };
                try {
                    await updateSelfpromo(podcast.id, $podcastsSelfpromos[podcast.id][agent].id, newSelfpromo);
                    $podcastsSelfpromos[podcast.id][agent].audiofile_url = audiofileURL;
                    $podcastsSelfpromos[podcast.id][agent].audio_name_original = audioNameOriginal;
                    $podcastsSelfpromos[podcast.id][agent].audiofile_duration_ms = audiofileDurationMs;
                    $colorCodes[podcast.id][agent].audiofile_url = audiofileURL;
                    $colorCodes[podcast.id][agent].audio_name_original = audioNameOriginal;
                    $colorCodes[podcast.id][agent].audiofile_duration_ms = audiofileDurationMs;
                    audiofile = '';
                } catch (e) {
                    console.log('An error occured whilst updating the selfpromo for ' + agent + '! Error: ', e);
                    fireSnackbarDismiss(getText('ui_messages.alerts.general.alert_update_fail'), 'alert', getText('ui_messages.sorry'));
                }
            }
        } catch (e) {
            console.log('An error occured whilst uploading the audio for selfpromo for ' + agent + '! Error: ', e);
            fireSnackbarDismiss(getText('ui_messages.alerts.general.alert_upload_fail'), 'alert', getText('ui_messages.sorry'));
        }
    }

    // DATE handling
    function changeStartDate(startDate, elemStart, now) {
        let elemEnd = document.querySelectorAll(`input[type="date"][id="grid-end-date"][data-id="${podcast.id}"][data-agent="${agent}"]`)[0];

        if (startDate) {
            // check if startDate < now
            startDate < now ? (startDate = now) && fireSnackbarNoDismiss(getText('ui_messages.snackbars.podcast.snackbar_selfpromo_date_reset'), 'info') : (startDate = startDate);
            elemStart.value = unixTimestampToDatePicker(new Date(startDate));

            // check if end date exists & compare
            elemEnd ? (endDate = new Date(datePickerDateToDatabase(elemEnd.value)).getTime()) : '';

            if (endDate && startDate >= endDate && endDate > now) {
                startDate = new Date(endDate).setDate(new Date(endDate).getDate() - 1);
                elemStart.value = unixTimestampToDatePicker(new Date(endDate).setDate(new Date(endDate).getDate() - 1));
                fireSnackbarNoDismiss(getText('ui_messages.snackbars.podcast.snackbar_selfpromo_date_reset'), 'info');
            } else if (endDate && startDate >= endDate && endDate <= now) {
                endDate = new Date(startDate).setDate(new Date(startDate).getDate() + 1);
                elemEnd.value = unixTimestampToDatePicker(new Date(endDate).setDate(new Date(endDate).getDate()));
                fireSnackbarNoDismiss(getText('ui_messages.snackbars.podcast.snackbar_selfpromo_date_reset'), 'info');
            }

            $colorCodes[podcast.id][agent].start_date = datePickerDateToColorCompare(unixTimestampToDatePicker(startDate));
        } else {
            $colorCodes[podcast.id][agent].start_date = '';
        }
    }

    function changeEndDate(endDate, elemEnd, now) {
        let elemStart = document.querySelectorAll(`input[type="date"][id="grid-start-date"][data-id="${podcast.id}"][data-agent="${agent}"]`)[0];

        if (endDate) {
            // check if endDate < now
            endDate <= now
                ? (endDate = new Date(now).setDate(new Date(now).getDate() + 1)) && fireSnackbarNoDismiss(getText('ui_messages.snackbars.podcast.snackbar_selfpromo_date_reset'), 'info')
                : (endDate = endDate);
            elemEnd.value = unixTimestampToDatePicker(new Date(endDate));

            // check if startDate exists & compare
            elemStart.value ? (startDate = new Date(datePickerDateToDatabase(elemStart.value)).getTime()) : '';

            if (startDate && startDate >= endDate) {
                endDate = new Date(startDate).setDate(new Date(startDate).getDate() + 1);
                elemEnd.value = unixTimestampToDatePicker(new Date(startDate).setDate(new Date(startDate).getDate() + 1));
                fireSnackbarNoDismiss(getText('ui_messages.snackbars.podcast.snackbar_selfpromo_date_reset'), 'info');
            }

            $colorCodes[podcast.id][agent].end_date = datePickerDateToColorCompare(unixTimestampToDatePicker(endDate));
        } else {
            $colorCodes[podcast.id][agent].end_date = '';
        }
    }

    function handleDateChange(event) {
        let now = new Date().setHours(1, 0, 0, 0);
        let pickedDateTime = Date.parse(event.target.value);

        let startDate = 0;
        let endDate = 0;

        if (event.target.id == 'grid-start-date') {
            startDate = pickedDateTime;
            let elemStart = document.querySelectorAll(`input[type="date"][id="grid-start-date"][data-id="${podcast.id}"][data-agent="${agent}"]`)[0];

            // check if selfpromo already exists
            if (!Object.keys($colorCodes[podcast.id]).includes(agent)) {
                $colorCodes[podcast.id][agent] = {};
            }

            if (Object.keys($podcastsSelfpromos[podcast.id]).includes(agent) && !editExistingSelfpromo) {
                // TODO snackbar handling
                let answer = window.confirm(getText('ui_messages.confirms.podcast.confirm_selfpromo_edit'));

                if (answer) {
                    editExistingSelfpromo = true;
                    changeStartDate(startDate, elemStart, now);
                } else {
                    // cancel and place old value
                    elemStart.value = databaseToDatePickerDate($podcastsSelfpromos[podcast.id][agent].start_date);
                    return;
                }
            } else {
                changeStartDate(startDate, elemStart, now);
            }
        }
        if (event.target.id == 'grid-end-date') {
            endDate = pickedDateTime;
            let elemEnd = document.querySelectorAll(`input[type="date"][id="grid-end-date"][data-id="${podcast.id}"][data-agent="${agent}"]`)[0];

            // check if selfpromo already exists
            if (!Object.keys($colorCodes[podcast.id]).includes(agent)) {
                $colorCodes[podcast.id][agent] = {};
            }

            if (Object.keys($podcastsSelfpromos[podcast.id]).includes(agent) && !editExistingSelfpromo) {
                // TODO snackbar handling
                let answer = window.confirm(getText('ui_messages.confirms.podcast.confirm_selfpromo_edit'));

                if (answer) {
                    editExistingSelfpromo = true;
                    changeEndDate(endDate, elemEnd, now);
                } else {
                    // cancel and place old value
                    elemEnd.value = databaseToDatePickerDate($podcastsSelfpromos[podcast.id][agent].end_date);

                    return;
                }
            } else {
                changeEndDate(endDate, elemEnd, now);
            }
        }
    }

    async function save() {
        let selfpromo = {
            audiofile_url: audiofileURL,
            audio_name_original: audioNameOriginal,
            audiofile_duration_ms: audiofileDurationMs,
            start_date: datePickerDateToDatabase(startDate),
            end_date: datePickerDateToDatabase(endDate),
            asap_podcast_id: podcast.asap_podcast_id ? podcast.asap_podcast_id : 1,
        };

        // differenciate between a CREATE and UPDATE
        if (!Object.keys($podcastsSelfpromos[podcast.id]).includes(agent)) {
            try {
                selfpromo.plattform = agent;
                let newSelfpromo = await saveSelfpromo(podcast.id, selfpromo)
                    .then((response) => response.json())
                    .then((data) => {
                        return data;
                    });
                $podcastsSelfpromos[podcast.id][agent] = JSON.parse(JSON.stringify(newSelfpromo));
                $colorCodes[podcast.id][agent] = JSON.parse(JSON.stringify(newSelfpromo));
                accordionToggle();
                fireSnackbarNoDismiss(getText('ui_messages.snackbars.general.snackbar_save_success'), 'success');
            } catch (e) {
                console.log('An error occured whilst saving the selfpromo for ' + agent + '! Error: ', e);
                fireSnackbarDismiss(getText('ui_messages.alerts.general.alert_save_fail'), 'alert', getText('ui_messages.sorry'));
            }
        } else {
            try {
                let updatedSelfpromo = await updateSelfpromo(podcast.id, $podcastsSelfpromos[podcast.id][agent].id, selfpromo)
                    .then((response) => response.json())
                    .then((data) => {
                        return data;
                    });
                $podcastsSelfpromos[podcast.id][agent] = JSON.parse(JSON.stringify(updatedSelfpromo));
                $colorCodes[podcast.id][agent] = JSON.parse(JSON.stringify(updatedSelfpromo));
                accordionToggle();
                fireSnackbarNoDismiss(getText('ui_messages.snackbars.general.snackbar_save_success'), 'success');
            } catch (e) {
                console.log('An error occured whilst updating the selfpromo for ' + agent + '! Error: ', e);
                fireSnackbarDismiss(getText('ui_messages.alerts.general.alert_update_fail'), 'alert', getText('ui_messages.sorry'));
            }
        }
    }

    async function removeSelfpromo() {
        try {
            await deleteSelfpromo(podcast.id, $podcastsSelfpromos[podcast.id][agent].id);
            delete $podcastsSelfpromos[podcast.id][agent];
            delete $colorCodes[podcast.id][agent];
            reset();
            accordionToggle();
            fireSnackbarNoDismiss(getText('ui_messages.snackbars.general.snackbar_delete_success'), 'success');
        } catch (e) {
            console.log('An error occured whilst deleting the selfpromo for ' + agent + '! Error: ', e);
            fireSnackbarDismiss(getText('ui_messages.alerts.general.alert_delete_fail'), 'alert', getText('ui_messages.sorry'));
        }
    }

    async function reset() {
        colorCodesIncluded = false;
        podcastsSelfpromosIncluded = false;
        showResetButton = false;
        dataComplete = false;
        startDate = '';
        endDate = '';
        audiofile = '';
        audiofileURL = '';
        audioNameOriginal = '';
        delete $colorCodes[podcast.id][agent];
    }

    // get duration of mp3 url
    function getURLDuration(url) {
        return new Promise((resolve, reject) => {
            var au = document.createElement('audio');
            au.src = url;

            // Once the metadata has been loaded grep duration in ms
            au.addEventListener(
                'loadedmetadata',
                function () {
                    duration_ms = au.duration * 1000;
                    resolve(parseInt(duration_ms));
                },
                false,
            );

            au.addEventListener(
                'error',
                function () {
                    reject(-1);
                },
                false,
            );
        });
    }

    function accordionToggle() {
        if (accordionKey == '') accordionKey = podcast.id + '-' + agent;
        else accordionKey = '';
    }

    function fireSnackbarNoDismiss(msg, type) {
        snackbarNoDismissMsg = msg;
        snackbarNoDismissType = type;
        snackbarNoDismissOpen = true;
    }

    function fireSnackbarDismiss(msg, type, title) {
        snackbarDismissMsg = msg;
        snackbarDismissType = type;
        snackbarDismissTitle = title;
        snackbarDismissOpen = true;
    }

    // SFX
    function shake() {
        document.getElementById(`grid-button-save-${podcast.id}-${agent}`).classList.add('shake-save');

        var delay = setTimeout(function () {
            document.getElementById(`grid-button-save-${podcast.id}-${agent}`).classList.remove('shake-save');
        }, 2000);
    }

    // Reactive checks
    $: startDate, (startDate = unixTimestampToDatePicker(startDate));
    $: endDate, (endDate = unixTimestampToDatePicker(endDate));
    $: $podcastsSelfpromos[podcast.id][agent], checkExistingSelfpromos();
    $: $colorCodes[podcast.id][agent], checkDataComplete(), checkNewSelfpromos(), checkChangesOfExistingSelfpromo();
    $: $colorCodes[podcast.id], toggleCancelButton();

    function checkDataComplete() {
        if (Object.keys($colorCodes[podcast.id]).includes(agent)) {
            $colorCodes[podcast.id][agent].audiofile_url && $colorCodes[podcast.id][agent].start_date && $colorCodes[podcast.id][agent].end_date ? (dataComplete = true) : (dataComplete = false);
        }
    }

    function checkExistingSelfpromos() {
        if (Object.keys($podcastsSelfpromos[podcast.id]).includes(agent)) {
            podcastsSelfpromosIncluded = true;
        } else podcastsSelfpromosIncluded = false;
    }

    function checkNewSelfpromos() {
        if (Object.keys($colorCodes[podcast.id]).includes(agent)) {
            colorCodesIncluded = true;
        } else colorCodesIncluded = false;
    }

    function checkChangesOfExistingSelfpromo() {
        if (Object.keys($podcastsSelfpromos[podcast.id]).includes(agent) && Object.keys($colorCodes[podcast.id]).includes(agent)) {
            if ($colorCodes[podcast.id][agent].audiofile_url && $podcastsSelfpromos[podcast.id][agent].audiofile_url != $colorCodes[podcast.id][agent].audiofile_url) {
                hasChanged = true;
            } else if ($colorCodes[podcast.id][agent].audio_name_original && $podcastsSelfpromos[podcast.id][agent].audio_name_original != $colorCodes[podcast.id][agent].audio_name_original) {
                hasChanged = true;
            } else if ($colorCodes[podcast.id][agent].start_date && $podcastsSelfpromos[podcast.id][agent].start_date != $colorCodes[podcast.id][agent].start_date) {
                hasChanged = true;
            } else if ($colorCodes[podcast.id][agent].end_date && $podcastsSelfpromos[podcast.id][agent].end_date != $colorCodes[podcast.id][agent].end_date) {
                hasChanged = true;
            } else hasChanged = false;
        }
    }

    function toggleCancelButton() {
        if (Object.keys($colorCodes[podcast.id]).includes(agent)) {
            if ($colorCodes[podcast.id][agent].audiofile_url || $colorCodes[podcast.id][agent].start_date || $colorCodes[podcast.id][agent].end_date) {
                showResetButton = true;
            } else showResetButton = false;
        }
    }
</script>

{#if snackbarNoDismissOpen}
    <SnackbarNoDismiss {snackbarNoDismissMsg} bind:snackbarNoDismissOpen {snackbarNoDismissType} timeout="2000" />
{/if}

{#if snackbarDismissOpen}
    <SnackbarDismiss {snackbarDismissTitle} {snackbarDismissMsg} bind:snackbarDismissOpen {snackbarDismissType} />
{/if}

<AccordionItem key={accordionKey}>
    <div slot="header">
        <div
            class="header hover:cell-highlight-color text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
        >
            <div class="ml-2">
                {#if podcastsSelfpromosIncluded}
                    <h2><i class="fas fa-check-circle text-emerald-500 mr-4" />{agent}</h2>
                {:else}
                    <h2><i class="fas fa-bell text-orange-500 mr-4" /> {agent}</h2>
                {/if}
            </div>
        </div>
    </div>

    <p slot="body" class="body">
        {#if agent == 'Info'}
            <div class="bg-blueGray-100 border-t-0 align-middle border-l-0 border-r-0 mb-4">
                <div class="ml-2">
                    {getText('podcast.selfpromos.description')}
                </div>
            </div>
        {:else}
            <div class="flex flex-wrap bg-blueGray-100 border-t-0 align-middle border-l-0 border-r-0 mb-4">
                <!-- file upload -->
                <div class="relative flex flex-col min-w-0 break-words bg-white rounded ml-2 xl:mb-0 shadow-lg mb-2 mt-2">
                    <div class="flex-auto p-4">
                        <div class="flex flex-wrap">
                            <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                                <h5 class="text-blueGray-400 uppercase font-bold text-xs">{getText('podcast.selfpromos.section_1.title')}</h5>
                                <span class="font-semibold text-xl text-blueGray-700">
                                    <form>
                                        <input
                                            id="grid-audio-select"
                                            data-id={podcast.id}
                                            data-agent={agent}
                                            class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            type="file"
                                            bind:files={audiofile}
                                            on:change={(e) => {
                                                audiofile ? uploadAudio(e) : '';
                                            }}
                                        />
                                    </form>
                                </span>
                            </div>
                            <div class="relative w-auto pl-4 flex-initial">
                                <div
                                    id="grid-file-load-icon"
                                    data-promo="{podcast.id}-{agent}"
                                    class="text-white p-3 text-center inline-flex items-center justify-center w-6 h-6 shadow-lg rounded-full {audiofile
                                        ? yellow
                                        : colorCodesIncluded && $colorCodes[podcast.id][agent].audiofile_url
                                        ? green
                                        : orange}"
                                >
                                    {#if colorCodesIncluded && $colorCodes[podcast.id][agent].audiofile_url}
                                        <i
                                            class="fas fa-download"
                                            on:click={() => {
                                                downloadFromUrl($colorCodes[podcast.id][agent].audiofile_url, $colorCodes[podcast.id][agent].audio_name_original);
                                            }}
                                        />
                                    {:else}
                                        <i class="fas fa-upload" />
                                    {/if}
                                </div>
                            </div>
                        </div>
                        <p class="text-sm text-blueGray-400 mt-4">
                            {#if podcastsSelfpromosIncluded || audioNameOriginal}
                                <span class="mr-2" />
                                {getText('podcast.selfpromos.section_1.state')}:
                                <span class="whitespace-nowrap">
                                    {audioNameOriginal}
                                    <AudioPlayer src={audiofileURL} id="grid-spot-audio-player" />
                                </span>
                            {/if}
                        </p>
                    </div>
                </div>

                <!-- start date -->
                <div class="relative flex flex-col min-w-0 break-words bg-white rounded ml-2 xl:mb-0 shadow-lg mb-2 mt-2">
                    <div class="flex-auto p-4">
                        <div class="flex flex-wrap">
                            <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                                <h5 class="text-blueGray-400 uppercase font-bold text-xs">{getText('podcast.selfpromos.section_2')}</h5>
                                <span class="font-semibold text-xl text-blueGray-700">
                                    <input
                                        id="grid-start-date"
                                        data-agent={agent}
                                        data-id={podcast.id}
                                        type="date"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        bind:value={startDate}
                                        on:change={handleDateChange}
                                    />
                                </span>
                            </div>
                            <div class="relative w-auto pl-4 flex-initial">
                                <div
                                    class="text-white p-3 text-center inline-flex items-center justify-center w-6 h-6 shadow-lg rounded-full {Object.keys($podcastsSelfpromos[podcast.id]).includes(
                                        agent,
                                    ) && databaseToDatePickerDate($podcastsSelfpromos[podcast.id][agent].start_date) == startDate
                                        ? green
                                        : startDate
                                        ? yellow
                                        : orange}"
                                >
                                    <i class="fas fa-play-circle" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- end date -->
                <div class="relative flex flex-col min-w-0 break-words bg-white rounded ml-2 xl:mb-0 shadow-lg mb-2 mt-2">
                    <div class="flex-auto p-4">
                        <div class="flex flex-wrap">
                            <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                                <h5 class="text-blueGray-400 uppercase font-bold text-xs">{getText('podcast.selfpromos.section_3')}</h5>
                                <span class="font-semibold text-xl text-blueGray-700">
                                    <input
                                        id="grid-end-date"
                                        data-agent={agent}
                                        data-id={podcast.id}
                                        type="date"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        bind:value={endDate}
                                        on:change={handleDateChange}
                                    />
                                </span>
                            </div>
                            <div class="relative w-auto pl-4 flex-initial">
                                <div
                                    class="text-white p-3 text-center inline-flex items-center justify-center w-6 h-6 shadow-lg rounded-full {Object.keys($podcastsSelfpromos[podcast.id]).includes(
                                        agent,
                                    ) && databaseToDatePickerDate($podcastsSelfpromos[podcast.id][agent].end_date) == endDate
                                        ? green
                                        : endDate
                                        ? yellow
                                        : orange}"
                                >
                                    <i class="fas fa-stop-circle" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- controls -->
                <div class="relative flex flex-col min-w-0 break-words bg-white rounded ml-2 xl:mb-0 shadow-lg mb-2 mt-2">
                    {#if dataComplete && !podcastsSelfpromosIncluded}
                        <!-- TODO use CardButton for when live -->
                        <button
                            id="grid-button-save-{podcast.id}-{agent}"
                            type="button"
                            class="flex-auto p-4 {dataComplete && !podcastsSelfpromosIncluded ? saveGreen : 'bg-white'}"
                            on:click={() => {
                                save();
                            }}
                        >
                            <i class="fas fa-save" />
                        </button>
                    {:else if dataComplete && podcastsSelfpromosIncluded && hasChanged}
                        <button
                            id="grid-button-save-{podcast.id}-{agent}"
                            type="button"
                            class="flex-auto p-4 {dataComplete && podcastsSelfpromosIncluded ? saveGreen : 'bg-white'}"
                            on:click={() => {
                                save();
                            }}
                        >
                            <i class="fas fa-save" />
                        </button>
                    {:else if dataComplete && podcastsSelfpromosIncluded && !hasChanged}
                        <button
                            id="grid-button-save-{podcast.id}-{agent}"
                            type="button"
                            class="flex-auto p-4 class={dataComplete && podcastsSelfpromosIncluded && !hasChanged ? saveGreen : 'bg-white'}"
                            on:click={() => {
                                fireSnackbarNoDismiss(getText('ui_messages.warnings.general.warning_no_changes'), 'warning');
                                shake();
                            }}
                        >
                            <i class="fas fa-save" />
                        </button>
                    {:else}
                        <button
                            id="grid-button-save-{podcast.id}-{agent}"
                            type="button"
                            class="flex-auto p-4 class={dataComplete && !podcastsSelfpromosIncluded ? saveGreen : 'bg-white'}"
                            on:click={() => {
                                fireSnackbarNoDismiss(getText('ui_messages.warnings.general.warning_no_data'), 'warning');
                                shake();
                            }}
                        >
                            <i class="fas fa-save" />
                        </button>
                    {/if}
                    {#if podcastsSelfpromosIncluded}
                        <ModalConfirm buttonConfirm={getText('ui_messages.button_proceed')} buttonCancel={getText('ui_messages.button_cancel')} let:confirm={confirmThis}>
                            <button
                                type="button"
                                class="flex-auto p-4 {saveRed}"
                                on:click={() => {
                                    confirmThis(removeSelfpromo);
                                }}
                            >
                                <i class="fas fa-trash" />
                            </button>
                            <span slot="title">{getText('ui_messages.confirms.podcast.confirm_selfpromo_delete_title')}</span>
                            <span slot="description">{getText('ui_messages.confirms.podcast.confirm_selfpromo_delete')} </span>
                        </ModalConfirm>
                    {:else if showResetButton}
                        <button
                            type="button"
                            class="flex-auto p-4 {saveRed}"
                            on:click={() => {
                                reset();
                            }}
                        >
                            <i class="fas fa-ban" />
                        </button>
                    {:else if !showResetButton}
                        <button
                            type="button"
                            class="flex-auto p-4 {saveRed}"
                            on:click={() => {
                                accordionToggle();
                            }}
                        >
                            <i class="fas fa-times" />
                        </button>
                    {/if}
                </div>
            </div>
        {/if}
    </p>
</AccordionItem>
