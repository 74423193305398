<script>
    import {databaseToPrettyDate, getDateDiff, getDateNowDatetime} from '../../functions.js';
    import {getText} from '../../language.svelte';
    import {missingLinkId} from '../../store.js';

    export let bookingRequest;
    export let campaign;
    export let nextStep;
    export let responsable;
    export let date;
    export let backgroundColor = 'bg-blueGray-300';
</script>

<div class="{backgroundColor} mt-1 flex-auto px-4 lg:px-10 py-10 pt-0">
    <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        {getText('podcast.booking_request_details.status.category_1.title')}
    </h6>

    <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-requested-impressions">
                    {#if bookingRequest.state == 2}
                        {#if campaign.type == 'hostread'}
                            <i class="fas fa-circle text-emerald-500 mr-2" />
                            {getText('podcast.booking_request_details.status.category_1.state_2.entry_1')}
                        {:else if campaign.type == 'spot'}
                            <i class="fas fa-circle text-emerald-500 mr-2" />
                            {getText('podcast.booking_request_details.status.category_1.state_2.entry_2')}
                        {:else if campaign.type == 'crosspromo'}
                            <i class="fas fa-circle text-emerald-500 mr-2" />
                            {getText('podcast.booking_request_details.status.category_1.state_2.entry_3')}
                        {/if}
                    {:else if bookingRequest.state == 3}
                        {#if !bookingRequest.booking_changes_requested}
                            <i class="fas fa-circle text-orange-500 mr-2" />
                            {getText('podcast.booking_request_details.status.category_1.state_3.entry_1')}
                        {:else if bookingRequest.booking_changes_requested}
                            <i class="fas fa-circle text-orange-500 mr-2" />
                            {getText('podcast.booking_request_details.status.category_1.state_3.entry_2')}
                        {/if}
                    {:else if bookingRequest.state == 4}
                        <i class="fas fa-circle text-red-600 mr-2" />
                        {getText('podcast.booking_request_details.status.category_1.state_4.entry_1')}
                    {:else if bookingRequest.state == 5}
                        {#if campaign.type == 'hostread'}
                            {#if !campaign.briefing_completed}
                                <i class="fas fa-circle text-orange-500 mr-2" />
                                {getText('podcast.booking_request_details.status.category_1.state_5.entry_1')}
                            {:else if campaign.briefing_completed && bookingRequest.audiofile_url && !bookingRequest.audiofile_url_approved}
                                <i class="fas fa-circle text-orange-500 mr-2" />
                                {getText('podcast.booking_request_details.status.category_1.state_5.entry_7')}
                            {:else if (bookingRequest.script_updated_promoter || bookingRequest.script_updated_podcast) && !bookingRequest.script_approved_date}
                                <i class="fas fa-circle text-orange-500 mr-2" />
                                {getText('podcast.booking_request_details.status.category_1.state_5.entry_3')}
                            {:else if bookingRequest.script_approved_date}
                                <i class="fas fa-circle text-orange-500 mr-2" />
                                {getText('podcast.booking_request_details.status.category_1.state_5.entry_4')}
                            {:else if campaign.briefing_completed}
                                <i class="fas fa-circle text-orange-500 mr-2" />
                                {getText('podcast.booking_request_details.status.category_1.state_5.entry_2')}
                            {/if}
                        {:else if campaign.type == 'spot' || campaign.type == 'crosspromo'}
                            {#if !campaign.audiofile_url}
                                <i class="fas fa-circle text-orange-500 mr-2" />
                                {getText('podcast.booking_request_details.status.category_1.state_5.entry_5')}
                            {:else if campaign.audiofile_url}
                                <i class="fas fa-circle text-orange-500 mr-2" />
                                {getText('podcast.booking_request_details.status.category_1.state_5.entry_6')}
                            {/if}
                        {/if}
                    {:else if bookingRequest.state == 6}
                        <i class="fas fa-circle text-red-600 mr-2" />
                        {getText('podcast.booking_request_details.status.category_1.state_6.entry_1')}
                    {:else if bookingRequest.state == 7}
                        {#if campaign.type == 'hostread'}
                            {#if campaign.feedback_loop_audio}
                                <i class="fas fa-circle text-orange-500 mr-2" />
                                {getText('podcast.booking_request_details.status.category_1.state_7.entry_1')}
                            {:else if bookingRequest.state == 7 && campaign.type == 'hostread' && campaign.feedback_loop_none && !bookingRequest.ad_created}
                                <i class="fas fa-circle text-orange-500 mr-2" />
                                {getText('podcast.booking_request_details.status.category_1.state_7.entry_3')}
                            {/if}
                        {/if}
                        {#if campaign.type == 'spot' || campaign.type == 'crosspromo'}
                            <i class="fas fa-circle text-orange-500 mr-2" />
                            {getText('podcast.booking_request_details.status.category_1.state_7.entry_1')}
                        {/if}
                    {:else if bookingRequest.state == 8}
                        <!-- missing link sonderlocke -->
                        {#if campaign.company_id == $missingLinkId}
                            <i class="fas fa-circle text-emerald-500 mr-2" />
                            {getText('podcast.booking_request_details.status.category_1.state_8.entry_3')}
                        {/if}

                        {#if !bookingRequest.ad_created && campaign.company_id != $missingLinkId}
                            <i class="fas fa-circle text-orange-500 mr-2" />
                            {getText('podcast.booking_request_details.status.category_1.state_8.entry_1')}
                        {:else if bookingRequest.ad_created && campaign.company_id != $missingLinkId}
                            <i class="fas fa-circle text-emerald-500 mr-2" />
                            {getText('podcast.booking_request_details.status.category_1.state_8.entry_2')}
                        {/if}
                    {:else if bookingRequest.state == 9}
                        <i class="fas fa-circle text-emerald-500 mr-2" />
                        {getText('podcast.booking_request_details.status.category_1.state_9.entry_1')}
                    {:else if bookingRequest.state == 10}
                        <i class="fas fa-circle text-orange-500 mr-2" />
                        {getText('podcast.booking_request_details.status.category_1.state_10.entry_1')}
                    {:else if bookingRequest.state == 11}
                        <i class="fas fa-circle text-orange-500 mr-2" />
                        {getText('podcast.booking_request_details.status.category_1.state_11.entry_1')}
                    {:else if bookingRequest.state == 12}
                        <i class="fas fa-circle text-emerald-500 mr-2" />
                        {getText('podcast.booking_request_details.status.category_1.state_12.entry_1')}
                    {:else if bookingRequest.state == 13}
                        <i class="fas fa-circle text-orange-500 mr-2" />
                        {getText('podcast.booking_request_details.status.category_1.state_13.entry_1')}
                    {:else if bookingRequest.state == 14}
                        <i class="fas fa-circle text-red-600 mr-2" />
                        {getText('podcast.booking_request_details.status.category_1.state_14.entry_1')}
                    {/if}
                </label>
            </div>
        </div>

        <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-offered-impressions">
                    {getText('podcast.booking_request_details.status.category_2.title')}
                </label>
                <span class="italic border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                    {nextStep}
                </span>
                {#if responsable}
                    <br />
                    <span class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                        {getText('podcast.booking_request_details.status.category_2.entry_1', responsable)}
                    </span>
                {/if}
                {#if date}
                    {#if bookingRequest.state == 2 || bookingRequest.state == 5}
                        <br />
                        <span class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                            {getText('podcast.booking_request_details.status.category_2.entry_2', databaseToPrettyDate(date))}
                            {getText('podcast.booking_request_details.status.category_2.entry_3', getDateDiff(getDateNowDatetime(), date))}
                        </span>
                    {:else}
                        <br />
                        <span class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                            {getText('podcast.booking_request_details.status.category_2.entry_4', databaseToPrettyDate(date))}
                        </span>
                    {/if}
                {/if}
            </div>
        </div>
    </div>
</div>
