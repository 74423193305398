<script>
    export let color = 'light';
    import {navigate} from 'svelte-routing';
    import {getAllPodcasts, getAllPodcastsOfCompanyCached} from '../../api/api.js';
    import {Pulse} from 'svelte-loading-spinners';
    import {getText} from '../../language.svelte';
    import RowAdminPodcast from '../TableRows/RowAdminPodcast.svelte';
    import {storedCompany} from '../../store.js';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let companyId;
    export let showNewButton = false;

    function newPodcast() {
        navigate('/admin/newpodcast', {replace: true});
    }

    function editPodcast() {
        console.log('Edit podcast');
    }

    function getPodcasts() {
        // if companyId get all of companyId
        if (companyId >= 0) {
            try {
                return getAllPodcastsOfCompanyCached(companyId);
            } catch (e) {
                console.log(`Error ${e} occoured whilst loading the podcasts of company ${companyId}`);
            }
        } else {
            // else admin: get all
            try {
                return getAllPodcasts();
            } catch (e) {
                console.log(`Error ${e} occoured whilst loading all podcasts !`);
            }
        }
    }

    let podcastPromise = getPodcasts();
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">
    <div style="display: flex; justify-content: center" />
    <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
            <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}">
                    {getText('admin.podcasts.all_podcasts.title')}
                </h3>
            </div>
            {#if typeof companyId == 'undefined' && showNewButton}
                <CardButton id="new-podcast" type="go" text={getText('admin.podcasts.all_podcasts.button_new')} on:click={() => newPodcast()} />
            {/if}
        </div>
    </div>

    <div class="block w-full overflow-x-auto">
        <!-- Projects table -->
        <table class="items-center w-full bg-transparent border-collapse">
            <thead>
                <tr>
                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-red-700 text-red-200 border-red-600'}"
                    >
                        <!-- Edit button -->
                    </th>
                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-red-700 text-red-200 border-red-600'}"
                    >
                        {getText('admin.podcasts.all_podcasts.headline_1')}
                    </th>
                    {#if $storedCompany.type == 'admin'}
                        <th
                            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                : 'bg-red-700 text-red-200 border-red-600'}"
                        >
                            {getText('admin.podcasts.all_podcasts.headline_6')}
                        </th>
                    {/if}
                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-red-700 text-red-200 border-red-600'}"
                    >
                        {getText('admin.podcasts.all_podcasts.headline_2')}
                    </th>
                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-red-700 text-red-200 border-red-600'}"
                    >
                        {getText('admin.podcasts.all_podcasts.headline_5')}
                    </th>
                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-red-700 text-red-200 border-red-600'}"
                    >
                        {getText('admin.podcasts.all_podcasts.headline_3')}
                    </th>
                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-red-700 text-red-200 border-red-600'}"
                    >
                        {getText('admin.podcasts.all_podcasts.headline_4')}
                    </th>
                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-red-700 text-red-200 border-red-600'}"
                    />
                </tr>
            </thead>

            {#await podcastPromise}
                <div style="display: flex; justify-content: center">
                    <Pulse size="60" color="#FF3E00" unit="px" duration="1s" />
                </div>
            {:then data}
                <tbody />
                {#if Object.keys(data).length > 0}
                    {#each data as item}
                        <RowAdminPodcast {item} />
                    {/each}
                {/if}
            {/await}
        </table>
    </div>
</div>
