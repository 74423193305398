<script>
    import {databaseToPrettyDate, getDateNow, prettyPrintNumber, getDateDiff, getDateNowDatetime, downloadFromUrl} from '../../functions.js';
    import {storedUser, storedCompany, campaignBookingRequests} from '../../store.js';
    import {
        postCommitMessage,
        patchBookingRequest,
        makeDeal,
        getPodcastCached,
        getBookingRequestsByCampaignIdCached,
        patchCampaign,
        getImpressionsByDealId,
        deleteInventoryBookingByBookingRequest,
        deleteInventoryReservationByBookingRequest,
        postAudioBacklog,
    } from '../../api/api.js';
    import {setAudiobacklogMeta, setBookingRequestState, resetBookingRequestState, setBookingRequestApproveAudio, rejectedBookingRequestAudio} from '../../stateSetter.js';
    import CardBookingProductionFlow from 'components/Cards/CardBookingProductionFlow.svelte';
    import CardScriptVersionControl from 'components/Cards/CardScriptVersionControl.svelte';
    import CardCommitMessageFlow from 'components/Cards/CardCommitMessageFlow.svelte';
    import CardCommitMessage from 'components/Cards/CardCommitMessage.svelte';
    import CardBookingRequestModifications from 'components/Cards/CardBookingRequestModifications.svelte';
    import AudioPlayer from '../AudioPlayer.svelte';
    import {navigate} from 'svelte-routing';
    import {getText} from '../../language.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    // VARS
    export let bookingRequest;
    export let campaign;
    export let requestedImpressionsPerCampaign = [];

    let showFlow = false;
    let showScriptControl = false;
    let showCommitMessages = false;
    let showModifications = false;

    // async function patchBR() {
    //     try {
    //         return await patchBookingRequest(bookingRequest.id, bookingRequest);
    //     } catch (e) {
    //         console.log(`Error ${e} occoured whilst patching booking request ${bookingRequest.id}`);
    //     }
    // }

    // HANDLER
    async function cancelBookingRequest() {
        showModifications = false;

        bookingRequest = setBookingRequestState(bookingRequest, 6);
        // let response = await patchBR();

        try {
            await patchBookingRequest(bookingRequest.id, bookingRequest);

            // remove from pending
            $campaignBookingRequests[campaign.id].pendingBRs = $campaignBookingRequests[campaign.id].pendingBRs.filter((bR) => bR.id !== bookingRequest.id);
            // add to cancelled
            $campaignBookingRequests[campaign.id].cancelledBRs = [...$campaignBookingRequests[campaign.id].cancelledBRs, bookingRequest];

            commitMessage ? (commitMessage = commitMessage) : (commitMessage = getText('commit_messages.default.booking_request.cancelled'));
            await saveCommitMessage(bookingRequest.id, 4).catch((e) => {
                console.log(`Error ${e} occoured whilst saving the commit for booking request ${bookingRequest}`);
            });
            commitMessage = '';

            // delete inventoryReservation & inventoryBooking
            try {
                await deleteInventoryReservationByBookingRequest(bookingRequest.id);
            } catch (e) {
                console.log(`Error ${e} occoured whilst deleting the inventory reservations for booking request ${bookingRequest}`);
            }
            try {
                await deleteInventoryBookingByBookingRequest(bookingRequest.id);
            } catch (e) {
                console.log(`Error ${e} occoured whilst deleting the inventory bookings for booking request ${bookingRequest}`);
            }
        } catch (e) {
            console.log(`Error ${e} occoured whilst cancelling booking request ${bookingRequest.id}`);
        }

        // if (response) {
        //     // remove from pending
        //     $campaignBookingRequests[campaign.id].pendingBRs = $campaignBookingRequests[campaign.id].pendingBRs.filter((bR) => bR.id !== bookingRequest.id);
        //     // add to cancelled
        //     $campaignBookingRequests[campaign.id].cancelledBRs = [...$campaignBookingRequests[campaign.id].cancelledBRs, bookingRequest];

        //     commitMessage ? (commitMessage = commitMessage) : (commitMessage = getText('commit_messages.default.booking_request.cancelled'));
        //     await saveCommitMessage(bookingRequest.id, 4).catch((e) => {
        //         console.log(`Error ${e} occoured whilst saving the commit for booking request ${bookingRequest}`);
        //     });
        //     commitMessage = '';
        // } else {
        //     console.log('something went wrong whilst cancelling the request!');
        // }
    }
    // async function agreeBookingRequest() {
    //     bookingRequest.state = 5;
    //     bookingRequest.agreed_date = getDateNow();
    //     bookingRequest.agreed = true;
    //     let response = await patchBR();

    //     // remove from pending
    //     $campaignBookingRequests[campaign.id].pendingBRs = $campaignBookingRequests[campaign.id].pendingBRs.filter((bR) => bR.id !== bookingRequest.id);
    //     // add to pending
    //     $campaignBookingRequests[campaign.id].pendingBRs = [...$campaignBookingRequests[campaign.id].pendingBRs, bookingRequest];

    //     if (!response) {
    //         console.log('something went wrong whilst patching the request (agree)!');
    //     }
    // }
    async function approveAudio() {
        bookingRequest = setBookingRequestState(bookingRequest, 8);
        bookingRequest = setBookingRequestApproveAudio(bookingRequest);

        let patchedBR;
        try {
            patchedBR = await patchBookingRequest(bookingRequest.id, bookingRequest);
        } catch (e) {
            console.log(`Error ${e} occoured whilst patching booking request ${bookingRequest.id}`);
        }

        try {
            await postAudioBacklog(setAudiobacklogMeta(bookingRequest.audiofile_url, bookingRequest.campaign_id, bookingRequest, true));
        } catch (e) {
            console.log(`Error ${e} occoured whilst writing to the audio backlog for booking request ${bookingRequest.id}`);
        }

        if (patchedBR.id == bookingRequest.id) {
            // make deal
            try {
                let deal = await makeDeal(bookingRequest.id).then((response) => response.json());
                bookingRequest.deal_id = deal[0];
                bookingRequest.vast_url = deal[1];
            } catch (e) {
                console.log(`Error ${e} occoured whilst closing the deal for booking request ${patchedBR.id}!`);
                return;
            }

            // remove from pending
            $campaignBookingRequests[campaign.id].pendingBRs = $campaignBookingRequests[campaign.id].pendingBRs.filter((bR) => bR.id !== bookingRequest.id);
            // add to dealed
            $campaignBookingRequests[campaign.id].dealedBRs = [...$campaignBookingRequests[campaign.id].dealedBRs, bookingRequest];

            commitMessage = getText('commit_messages.default.booking_request.audio_approved');
            await saveCommitMessage(patchedBR.id, 9).catch((e) => {
                console.log(`Error ${e} occoured whilst saving the commit for booking request ${patchedBR.id}`);
            });
        } else {
            console.log('something went wrong whilst patching the request (approve audio)!');
        }
    }

    async function rejectAudio() {
        bookingRequest = setBookingRequestState(bookingRequest, 6);

        try {
            await patchBookingRequest(bookingRequest.id, bookingRequest);

            // call campaign impression update
            await updateRequestedImpressionsPerCampaign(campaign.id);
            // remove from pending
            $campaignBookingRequests[campaign.id].pendingBRs = $campaignBookingRequests[campaign.id].pendingBRs.filter((bR) => bR.id !== bookingRequest.id);
            // add to cancelled
            $campaignBookingRequests[campaign.id].cancelledBRs = [...$campaignBookingRequests[campaign.id].cancelledBRs, bookingRequest];
        } catch (e) {
            console.log(`Error ${e} occoured whilst rejecting the audio for booking request ${bookingRequest.id}`);
        }

        // if (response) {
        //     // call campaign impression update
        //     await updateRequestedImpressionsPerCampaign(campaign.id);
        //     // remove from pending
        //     $campaignBookingRequests[campaign.id].pendingBRs = $campaignBookingRequests[campaign.id].pendingBRs.filter((bR) => bR.id !== bookingRequest.id);
        //     // add to cancelled
        //     $campaignBookingRequests[campaign.id].cancelledBRs = [...$campaignBookingRequests[campaign.id].cancelledBRs, bookingRequest];
        // } else {
        //     console.log('something went wrong whilst patching the request (reject audio)!');
        // }
    }

    async function rejectAudioVersion() {
        bookingRequest = rejectedBookingRequestAudio(bookingRequest);
        bookingRequest.booking_changes_requested = true;

        try {
            await patchBookingRequest(bookingRequest.id, bookingRequest);

            // remove from pending
            $campaignBookingRequests[campaign.id].pendingBRs = $campaignBookingRequests[campaign.id].pendingBRs.filter((bR) => bR.id !== bookingRequest.id);
            // add to pending
            $campaignBookingRequests[campaign.id].pendingBRs = [...$campaignBookingRequests[campaign.id].pendingBRs, bookingRequest];

            // save commit message
            commitMessage ? (commitMessage = commitMessage) : (commitMessage = getText('commit_messages.default.booking_request.no_feedback'));
            await saveCommitMessage(bookingRequest.id, 10).catch((e) => {
                console.log(`Error ${e} occoured whilst saving the commit for booking request ${bookingRequest.id}`);
            });
        } catch (e) {
            console.log(`Error ${e} occoured whilst saving the script for booking request ${bookingRequest.id}`);
        }

        // if (patched.status != 200) {
        //     console.log('Something went wrong saving the script changes !');
        // } else {
        //     // remove from pending
        //     $campaignBookingRequests[campaign.id].pendingBRs = $campaignBookingRequests[campaign.id].pendingBRs.filter((bR) => bR.id !== bookingRequest.id);
        //     // add to pending
        //     $campaignBookingRequests[campaign.id].pendingBRs = [...$campaignBookingRequests[campaign.id].pendingBRs, bookingRequest];

        //     // save commit message
        //     commitMessage ? (commitMessage = commitMessage) : (commitMessage = getText('commit_messages.default.booking_request.no_feedback'));
        //     await saveCommitMessage(bookingRequest.id, 10).catch((e) => {
        //         console.log(`Error ${e} occoured whilst saving the commit for booking request ${bookingRequest}`);
        //     });
        // }
    }

    async function rejectModifications() {
        showModifications = false;
        // if (!commitMessage) {
        //     window.alert('Please provide a commit message for this change request !');
        //     showCommitMessage = true;
        //     return;
        // }

        // if (bookingRequest.requested_impressions != bookingRequest.offered_impressions) {
        //     bookingRequest.offered_impressions = 0;
        // }
        // if (campaign.roll_position_pre != bookingRequest.roll_position_pre) {
        //     bookingRequest.roll_position_pre = false;
        // }
        // if (campaign.roll_position_mid_1 != bookingRequest.roll_position_mid_1) {
        //     bookingRequest.roll_position_mid_1 = false;
        // }
        // if (campaign.roll_position_mid_2 != bookingRequest.roll_position_mid_2) {
        //     bookingRequest.roll_position_mid_2 = false;
        // }
        // if (campaign.roll_position_mid_3 != bookingRequest.roll_position_mid_3) {
        //     bookingRequest.roll_position_mid_3 = false;
        // }
        // if (campaign.roll_position_post != bookingRequest.roll_position_post) {
        //     bookingRequest.roll_position_post = false;
        // }

        bookingRequest.booking_changes_requested = true;

        try {
            await patchBookingRequest(bookingRequest.id, bookingRequest);

            // remove from pending
            $campaignBookingRequests[campaign.id].pendingBRs = $campaignBookingRequests[campaign.id].pendingBRs.filter((bR) => bR.id !== bookingRequest.id);
            // add to pending
            $campaignBookingRequests[campaign.id].pendingBRs = [...$campaignBookingRequests[campaign.id].pendingBRs, bookingRequest];

            // save commit message
            commitMessage ? (commitMessage = commitMessage) : (commitMessage = getText('commit_messages.default.booking_request.impression_role_modifications_changes'));
            await saveCommitMessage(bookingRequest.id, 19).catch((e) => {
                console.log(`Error ${e} occoured whilst saving the commit for booking request ${bookingRequest.id}`);
            });
            commitMessage = '';
        } catch (e) {
            console.log(`Error ${e} occoured whilst saving the script for booking request ${bookingRequest.id}`);
        }

        // if (patched.status != 200) {
        //     console.log('Something went wrong saving the script changes !');
        // } else {
        //     // remove from pending
        //     $campaignBookingRequests[campaign.id].pendingBRs = $campaignBookingRequests[campaign.id].pendingBRs.filter((bR) => bR.id !== bookingRequest.id);
        //     // add to pending
        //     $campaignBookingRequests[campaign.id].pendingBRs = [...$campaignBookingRequests[campaign.id].pendingBRs, bookingRequest];

        //     // save commit message
        //     commitMessage ? (commitMessage = commitMessage) : (commitMessage = getText('commit_messages.default.booking_request.impression_role_modifications_changes'));
        //     await saveCommitMessage(bookingRequest.id, 19).catch((e) => {
        //         console.log(`Error ${e} occoured whilst saving the commit for booking request ${bookingRequest}`);
        //     });
        //     commitMessage = '';
        // }
    }

    async function confirmBooking() {
        if ((campaign.type == 'hostread' && !bookingRequest.audiofile_url) || ((campaign.type == 'spot' || campaign.type == 'crosspromo') && !campaign.audiofile_url)) {
            bookingRequest = setBookingRequestState(bookingRequest, 5);
        } else if ((campaign.type == 'spot' || campaign.type == 'crosspromo') && campaign.audiofile_url) {
            bookingRequest = setBookingRequestState(bookingRequest, 5);
            bookingRequest = setBookingRequestState(bookingRequest, 8);

            // make deal
            try {
                let deal = await makeDeal(bookingRequest.id).then((response) => response.json());
                bookingRequest.deal_id = deal[0];
                bookingRequest.vast_url = deal[1];
            } catch (e) {
                console.log(`Error ${e} occoured whilst closing the deal !`);
                return;
            }

            // remove from pending
            $campaignBookingRequests[campaign.id].pendingBRs = $campaignBookingRequests[campaign.id].pendingBRs.filter((bR) => bR.id !== bookingRequest.id);
            // add to dealed
            $campaignBookingRequests[campaign.id].dealedBRs = [...$campaignBookingRequests[campaign.id].dealedBRs, bookingRequest];

            commitMessage = getText('commit_messages.default.booking_request.impression_role_modifications_accepted');
        }

        try {
            await patchBookingRequest(bookingRequest.id, bookingRequest);

            // remove from pending
            $campaignBookingRequests[campaign.id].pendingBRs = $campaignBookingRequests[campaign.id].pendingBRs.filter((bR) => bR.id !== bookingRequest.id);
            // add to pending
            $campaignBookingRequests[campaign.id].pendingBRs = [...$campaignBookingRequests[campaign.id].pendingBRs, bookingRequest];

            // save commit message
            commitMessage ? (commitMessage = commitMessage) : (commitMessage = getText('commit_messages.default.booking_request.impression_role_modifications_accepted'));
            await saveCommitMessage(bookingRequest.id, 18).catch((e) => {
                console.log(`Error ${e} occoured whilst saving the commit for booking request ${bookingRequest.id}`);
            });
        } catch (e) {
            console.log(`Error ${e} occoured whilst confirming booking request ${bookingRequest.id}`);
        }

        // if (patched.status != 200) {
        //     console.log('Something went wrong saving booking request confirmation !');
        // } else {
        //     // remove from pending
        //     $campaignBookingRequests[campaign.id].pendingBRs = $campaignBookingRequests[campaign.id].pendingBRs.filter((bR) => bR.id !== bookingRequest.id);
        //     // add to pending
        //     $campaignBookingRequests[campaign.id].pendingBRs = [...$campaignBookingRequests[campaign.id].pendingBRs, bookingRequest];

        //     // save commit message
        //     commitMessage ? (commitMessage = commitMessage) : (commitMessage = getText('commit_messages.default.booking_request.impression_role_modifications_accepted'));
        //     await saveCommitMessage(bookingRequest.id, 18).catch((e) => {
        //         console.log(`Error ${e} occoured whilst saving the commit for booking request ${bookingRequest}`);
        //     });
        // }
    }

    // update campaign impression after an audio reject
    async function updateRequestedImpressionsPerCampaign(campaignId) {
        let bRs;

        try {
            await getBookingRequestsByCampaignIdCached(campaignId);
        } catch (e) {
            console.log(`Error ${e} occoured whilst getting booking requests for campaign ${campaignId}`);
        }

        bRs.forEach((bR) => {
            if (bR.state == 4 || bR.state == 6) {
                requestedImpressionsPerCampaign[campaignId] -= bR.requested_impressions;
            }
        });
    }

    // reactivate request after acceptance deadline miss
    async function extendDeadline() {
        campaign.acceptance_deadline = campaign.start_date;

        try {
            await patchCampaign(campaign.id, campaign);
        } catch (e) {
            console.log(`Error ${e} ouccoured whilst patching the new acceptance deadline for the campaign ${campaign}`);
        }

        bookingRequest = resetBookingRequestState(bookingRequest, 2);

        try {
            await patchBookingRequest(bookingRequest.id, bookingRequest);
        } catch (e) {
            console.log(`Error ${e} occoured whilst patching booking request ${bookingRequest.id}`);
        }
    }

    let bR2;
    function details(bR) {
        bR2 = bR;
        showFlow = true;
    }

    let bR3;
    function detailsCommitMessages(bR) {
        bR3 = bR;
        showCommitMessages = true;
    }

    let bR4;
    function detailsScript(bR) {
        bR4 = bR;
        showScriptControl = true;
    }

    let podcastName;
    function detailsModifications(name) {
        showModifications = true;
        podcastName = name;
    }

    // COMMIT MESSAGES
    function parentFunction() {
        rejectAudioVersion();
    }

    async function saveCommitMessage(bookingRequestId, actionCode) {
        let data = {
            booking_request_id: bookingRequestId,
            action: actionCode,
            author: $storedUser.first_name,
            company_name: $storedCompany.name,
            company_type: $storedCompany.type,
            message: commitMessage,
            sender: true,
        };

        try {
            await postCommitMessage(bookingRequestId, data);
            return true;
        } catch (e) {
            console.log(`Error ${e} occoured whilst posting the message ${data.message}`);
            return false;
        }
    }

    function setAction(thisAction) {
        action = thisAction;
    }

    function triggerCommitMessagePopUp() {
        showCommitMessage = true;
    }

    let commitTitle;
    let showCommitMessage = false;
    let commitMessage;
    let action = '';
    let continueProcess = false;
</script>

{#if showFlow}
    <CardBookingProductionFlow bookingRequest={bR2} {campaign} />
{/if}

{#if showScriptControl}
    <CardScriptVersionControl bookingRequest={bR4} {campaign} bind:showScriptControl />
{/if}

{#if showCommitMessages}
    <CardCommitMessageFlow bookingRequest={bR3} {campaign} bind:showCommitMessages />
{/if}

{#if showCommitMessage}
    <CardCommitMessage {action} {commitTitle} bind:commitMessage bind:continueProcess bind:showCommitMessage parentFunction={() => parentFunction()} />
{/if}

{#if showModifications}
    <CardBookingRequestModifications
        {campaign}
        {podcastName}
        {bookingRequest}
        {commitTitle}
        bind:showModifications
        bind:commitMessage
        confirmBooking={() => confirmBooking()}
        rejectModifications={() => rejectModifications()}
        cancelBookingRequest={() => cancelBookingRequest()}
    />
{/if}

{#await getPodcastCached(bookingRequest.podcast_id) then data}
    {#if bookingRequest.state == 2}
        <tr class="hover:cell-highlight-color-level-2">
            {#if campaign.type == 'hostread'}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <!-- Waiting for "{data.name}" to accept hostread request -->
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_2.next_step_hostread', data.name)}
                </td>
            {:else if campaign.type == 'spot'}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    Waiting for "{data.name}" to accept spot request
                </td>
            {:else if campaign.type == 'spot' && !campaign.audiofile_url}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> Upload campaign audio ! </td>
            {:else if campaign.type == 'crosspromo'}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    Waiting for "{data.name}" to accept cross promotion request
                </td>
            {/if}
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> {data.name} </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> {prettyPrintNumber(bookingRequest.requested_impressions)} / 0 </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                0 ({getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_2.status')})
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                <CardButton id="info" type="icon" icon="fas fa-info-circle mr-2 text-sm" title="Production Flow" on:click={() => details(bookingRequest)} />
                {#if $storedCompany.type !== 'podcast'}
                    <CardButton id="commit-messages" type="icon" icon="fas fa-comment-alt mr-2 text-sm" title="Commit Messages" on:click={() => detailsCommitMessages(bookingRequest)} />
                {/if}
                {#if campaign.type == 'hostread' && campaign.briefing_completed}
                    <CardButton id="script-version-control" type="icon" icon="fas fa-file-alt mr-2 text-sm" title="Script Version Control" on:click={() => detailsScript(bookingRequest)} />
                {/if}

                <CardButton id="cancel-booking-request" type="icon" icon="fas fa-trash mr-2 text-sm" title="Cancel Booking Request" on:click={() => cancelBookingRequest()} />
            </td>
        </tr>
    {:else if bookingRequest.state == 3}
        <tr class="hover:cell-highlight-color-level-2">
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {#if !bookingRequest.booking_changes_requested}
                    <CardButton
                        id="details"
                        type="go"
                        text={getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_3.next_step_1')}
                        on:click={() => {
                            detailsModifications(data.name);
                        }}
                    />
                {:else}
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_3.next_step_2', data.name)}
                {/if}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> {data.name} </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                0 ({getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_3.status')})
            </td>

            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                <CardButton id="info" type="icon" icon="fas fa-info-circle mr-2 text-sm" title="Production Flow" on:click={() => details(bookingRequest)} />
                {#if $storedCompany.type !== 'podcast'}
                    <CardButton id="commit-messages" type="icon" icon="fas fa-comment-alt mr-2 text-sm" title="Commit Messages" on:click={() => detailsCommitMessages(bookingRequest)} />
                {/if}
                {#if campaign.type == 'hostread' && campaign.briefing_completed}
                    <CardButton id="script-version-control" type="icon" icon="fas fa-file-alt mr-2 text-sm" title="Script Version Control" on:click={() => detailsScript(bookingRequest)} />
                {/if}

                <CardButton id="cancel-booking-request" type="icon" icon="fas fa-trash mr-2 text-sm" title="Cancel Booking Request" on:click={() => cancelBookingRequest()} />
            </td>
        </tr>
    {:else if bookingRequest.state == 4}
        <tr class="hover:cell-highlight-color-level-2">
            {#if campaign.type == 'hostread'}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_4.next_step_hostread', data.name, databaseToPrettyDate(bookingRequest.rejected_date))}
                </td>
            {:else if campaign.type == 'spot'}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_4.next_step_spot', data.name, databaseToPrettyDate(bookingRequest.rejected_date))}
                </td>
            {:else if campaign.type == 'crosspromo'}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_4.next_step_crosspromo', data.name, databaseToPrettyDate(bookingRequest.rejected_date))}
                </td>
            {/if}
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left strikethrough"> {data.name} </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left strikethrough"> {prettyPrintNumber(bookingRequest.requested_impressions)} / 0</td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_4.status')}
            </td>

            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                <CardButton id="info" type="icon" icon="fas fa-info-circle mr-2 text-sm" title="Production Flow" on:click={() => details(bookingRequest)} />
                {#if $storedCompany.type !== 'podcast'}
                    <CardButton id="commit-messages" type="icon" icon="fas fa-comment-alt mr-2 text-sm" title="Commit Messages" on:click={() => detailsCommitMessages(bookingRequest)} />
                {/if}
                {#if campaign.type == 'hostread' && campaign.briefing_completed}
                    <CardButton id="script-version-control" type="icon" icon="fas fa-file-alt mr-2 text-sm" title="Script Version Control" on:click={() => detailsScript(bookingRequest)} />
                {/if}
            </td>
        </tr>
    {:else if bookingRequest.state == 5}
        <tr class="hover:cell-highlight-color-level-2">
            {#if campaign.type == 'hostread' && campaign.briefing_completed && bookingRequest.script_updated_podcast && !bookingRequest.script_approved_date}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <CardButton
                        id="script-version-control"
                        type="go"
                        text={getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_5.next_step_1')}
                        title="Script Version Control"
                        on:click={() => detailsScript(bookingRequest)}
                    />
                </td>
            {:else if campaign.type == 'hostread' && campaign.briefing_completed && campaign.feedback_loop_script && !bookingRequest.script_updated_podcast}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_5.next_step_2', data.name)}
                </td>
            {:else if campaign.type == 'hostread' && campaign.briefing_completed && campaign.feedback_loop_audio && !bookingRequest.audiofile_url_approved}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_5.next_step_3', data.name)}
                </td>
            {:else if campaign.type == 'hostread' && !campaign.briefing_completed}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_5.next_step_4')}
                </td>
            {:else if campaign.type == 'hostread' && bookingRequest.script_approved_date && !bookingRequest.audiofile_url && bookingRequest.script_updated_podcast}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_5.next_step_5')}
                </td>
            {:else if campaign.type == 'hostread' && !bookingRequest.script_approved_date && !bookingRequest.audiofile_url && !bookingRequest.script_updated_podcast}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_5.next_step_6')}
                </td>
            {:else if campaign.type == 'spot' && !campaign.audiofile_url}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_5.next_step_7')}
                </td>
            {:else if campaign.type == 'spot' && campaign.audiofile_url}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_5.next_step_8', data.name)}
                </td>
            {:else if campaign.type == 'crosspromo'}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_5.next_step_9', data.name)}
                </td>
            {:else if campaign.briefing_completed}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_5.next_step_10')}
                </td>
            {:else if !campaign.briefing_completed}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_5.next_step_7')}
                </td>
            {/if}
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> {data.name} </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                0 ({getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_5.status')})
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                <CardButton id="info" type="icon" icon="fas fa-info-circle mr-2 text-sm" title="Production Flow" on:click={() => details(bookingRequest)} />
                {#if $storedCompany.type !== 'podcast'}
                    <CardButton id="commit-messages" type="icon" icon="fas fa-comment-alt mr-2 text-sm" title="Commit Messages" on:click={() => detailsCommitMessages(bookingRequest)} />
                {/if}
                {#if campaign.type == 'hostread' && campaign.briefing_completed}
                    <CardButton id="script-version-control" type="icon" icon="fas fa-file-alt mr-2 text-sm" title="Script Version Control" on:click={() => detailsScript(bookingRequest)} />
                {/if}
                <CardButton id="cancel-booking-request" type="icon" icon="fas fa-trash mr-2 text-sm" title="Cancel Booking Request" on:click={() => cancelBookingRequest()} />
            </td>
        </tr>
    {:else if bookingRequest.state == 6}
        <tr class="hover:cell-highlight-color-level-2">
            {#if campaign.type == 'hostread'}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <!-- Hostread Request has been cancelled on {databaseToPrettyDate(bookingRequest.cancelled_date)} -->
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_6.next_step_hostread', databaseToPrettyDate(bookingRequest.cancelled_date))}
                </td>
            {:else if campaign.type == 'spot'}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_6.next_step_spot', databaseToPrettyDate(bookingRequest.cancelled_date))}
                    <!-- Spot Request has been cancelled on {databaseToPrettyDate(bookingRequest.cancelled_date)} -->
                </td>
            {:else if campaign.type == 'crosspromo'}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_6.next_step_crosspromo', databaseToPrettyDate(bookingRequest.cancelled_date))}
                    <!-- Cross promotion Request has been cancelled on {databaseToPrettyDate(bookingRequest.cancelled_date)} -->
                </td>
            {/if}
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left strikethrough"> {data.name} </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left strikethrough">
                {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_6.status')}
                <!-- Offer has been withdrawn -->
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                <CardButton id="info" type="icon" icon="fas fa-info-circle mr-2 text-sm" title="Production Flow" on:click={() => details(bookingRequest)} />
                {#if $storedCompany.type !== 'podcast'}
                    <CardButton id="commit-messages" type="icon" icon="fas fa-comment-alt mr-2 text-sm" title="Commit Messages" on:click={() => detailsCommitMessages(bookingRequest)} />
                {/if}
                {#if campaign.type == 'hostread' && campaign.briefing_completed}
                    <CardButton id="script-version-control" type="icon" icon="fas fa-file-alt mr-2 text-sm" title="Script Version Control" on:click={() => detailsScript(bookingRequest)} />
                {/if}
            </td>
        </tr>
    {:else if bookingRequest.state == 7}
        <tr class="hover:cell-highlight-color-level-2">
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <div class="flex">
                    <div class="flex-wrap">
                        <AudioPlayer src={bookingRequest.audiofile_url} />
                        <CardButton
                            id="modification-download"
                            type="go-blue"
                            text={getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_7.next_step_1')}
                            title="Download Audio"
                            on:click={() => {
                                downloadFromUrl(bookingRequest.audiofile_url, bookingRequest.audiofile_name_original);
                            }}
                        />
                        <CardButton
                            id="modification-approve"
                            type="go"
                            text={getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_7.next_step_2')}
                            title="Approve Audio"
                            on:click={approveAudio}
                        />
                        <CardButton
                            id="modification-change"
                            type="change"
                            text={getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_7.next_step_3')}
                            title="Change Audio"
                            on:click={() => {
                                setAction(getText('commit_messages.booking_modification.entry_3.action'));
                                commitTitle = getText('commit_messages.booking_modification.entry_3.headline_1');
                                triggerCommitMessagePopUp();
                            }}
                        />
                    </div>
                </div>
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> {data.name} </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                {#if bookingRequest.requested_impressions > bookingRequest.offered_impressions}
                    <i class="fas fa-arrow-down text-orange-500" />
                {:else}
                    <i class="fas fa-check text-emerald-500" />
                {/if}
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                0 ({getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_7.status')})
            </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                <CardButton id="info" type="icon" icon="fas fa-info-circle mr-2 text-sm" title="Production Flow" on:click={() => details(bookingRequest)} />
                {#if $storedCompany.type !== 'podcast'}
                    <CardButton id="commit-messages" type="icon" icon="fas fa-comment-alt mr-2 text-sm" title="Commit Messages" on:click={() => detailsCommitMessages(bookingRequest)} />
                {/if}
                {#if campaign.type == 'hostread' && campaign.briefing_completed}
                    <CardButton id="script-version-control" type="icon" icon="fas fa-file-alt mr-2 text-sm" title="Script Version Control" on:click={() => detailsScript(bookingRequest)} />
                {/if}
            </td>
        </tr>
    {:else if bookingRequest.state == 8}
        {#await getImpressionsByDealId(bookingRequest.deal_id) then liveImpressions}
            <tr class="hover:cell-highlight-color-level-2">
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_8.next_step_1', getDateDiff(getDateNowDatetime(), campaign.start_date))}
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> {data.name} </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                    {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
                    {#if bookingRequest.requested_impressions > bookingRequest.offered_impressions}
                        <i class="fas fa-arrow-down text-orange-500" />
                    {:else}
                        <i class="fas fa-check text-emerald-500" />
                    {/if}
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> {prettyPrintNumber(liveImpressions.value)} </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex">
                    <CardButton id="info" type="icon" icon="fas fa-info-circle mr-2 text-sm" title="Production Flow" on:click={() => details(bookingRequest)} />
                    {#if $storedCompany.type !== 'podcast'}
                        <CardButton id="commit-messages" type="icon" icon="fas fa-comment-alt mr-2 text-sm" title="Commit Messages" on:click={() => detailsCommitMessages(bookingRequest)} />
                    {/if}
                    {#if campaign.type == 'hostread' && campaign.briefing_completed}
                        <CardButton id="script-version-control" type="icon" icon="fas fa-file-alt mr-2 text-sm" title="Script Version Control" on:click={() => detailsScript(bookingRequest)} />
                    {/if}
                    {#if bookingRequest.audiofile_url}
                        <CardButton
                            id="download"
                            type="icon"
                            icon="fas fa-download py-1 mr-1 active:color-emerald-600"
                            title="Download Audio"
                            on:click={() => {
                                downloadFromUrl(bookingRequest.audiofile_url, bookingRequest.audiofile_name_original);
                            }}
                        />
                        <AudioPlayer src={bookingRequest.audiofile_url} />
                    {/if}
                </td>
            </tr>
        {/await}
    {:else if bookingRequest.state == 13}
        <tr class="hover:cell-highlight-color-level-2">
            {#if campaign.type == 'hostread'}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <!-- {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_13.next_step_hostread', data.name)} -->
                    {#if campaign.start_date < getDateNow}
                        <CardButton
                            id="extend-deadline"
                            type="go"
                            text={getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_13.button_extend_deadline')}
                            title="Extend Deadline"
                            on:click={() => {
                                extendDeadline();
                            }}
                        />
                    {/if}
                </td>
            {:else if campaign.type == 'spot'}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <!-- {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_13.next_step_spot', data.name)} -->
                    {#if campaign.start_date < getDateNow}
                        <CardButton
                            id="extend-deadline"
                            type="go"
                            text={getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_13.button_extend_deadline')}
                            title="Extend Deadline"
                            on:click={() => {
                                extendDeadline();
                            }}
                        />
                    {/if}
                </td>
            {:else if campaign.type == 'crosspromo'}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <!-- {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_13.next_step_crosspromo', data.name)} -->
                    {#if campaign.start_date < getDateNow}
                        <CardButton
                            id="extend-deadline"
                            type="go"
                            text={getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_13.button_extend_deadline')}
                            title="Extend Deadline"
                            on:click={() => {
                                extendDeadline();
                            }}
                        />
                    {/if}
                </td>
            {/if}
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left strikethrough"> {data.name} </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left strikethrough"> {prettyPrintNumber(bookingRequest.requested_impressions)} / 0</td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_13.status')}
            </td>

            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                <CardButton id="info" type="icon" icon="fas fa-info-circle mr-2 text-sm" title="Production Flow" on:click={() => details(bookingRequest)} />
                {#if $storedCompany.type !== 'podcast'}
                    <CardButton id="commit-messages" type="icon" icon="fas fa-comment-alt mr-2 text-sm" title="Commit Messages" on:click={() => detailsCommitMessages(bookingRequest)} />
                {/if}
                {#if campaign.type == 'hostread' && campaign.briefing_completed}
                    <CardButton id="script-version-control" type="icon" icon="fas fa-file-alt mr-2 text-sm" title="Script Version Control" on:click={() => detailsScript(bookingRequest)} />
                {/if}
            </td>
        </tr>
    {:else if bookingRequest.state == 14}
        <tr class="hover:cell-highlight-color-level-2">
            {#if campaign.type == 'hostread'}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_13.next_step_hostread', data.name)}
                </td>
            {:else if campaign.type == 'spot'}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_13.next_step_spot', data.name)}
                </td>
            {:else if campaign.type == 'crosspromo'}
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_13.next_step_crosspromo', data.name)}
                </td>
            {/if}
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left strikethrough"> {data.name} </td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left strikethrough"> {prettyPrintNumber(bookingRequest.requested_impressions)} / 0</td>
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                {getText('promoter.campaign_overview.booking_requests_table_rows.pending.state_13.status')}
            </td>

            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                <CardButton id="info" type="icon" icon="fas fa-info-circle mr-2 text-sm" title="Production Flow" on:click={() => details(bookingRequest)} />
                {#if $storedCompany.type !== 'podcast'}
                    <CardButton id="commit-messages" type="icon" icon="fas fa-comment-alt mr-2 text-sm" title="Commit Messages" on:click={() => detailsCommitMessages(bookingRequest)} />
                {/if}
                {#if campaign.type == 'hostread' && campaign.briefing_completed}
                    <CardButton id="script-version-control" type="icon" icon="fas fa-file-alt mr-2 text-sm" title="Script Version Control" on:click={() => detailsScript(bookingRequest)} />
                {/if}
            </td>
        </tr>
    {/if}
{/await}
