<script>
    import CardCampaignReservation from 'components/Cards/CardCampaignReservation.svelte';
    import {selectedCampaign} from '../../store.js';

    export let ctype;

    // reset store
    selectedCampaign.set({});
</script>

<div class="flex flex-wrap">
    <div class="w-full mb-12 px-4">
        <CardCampaignReservation {ctype} cardTitle="Create New Campaign" />
    </div>
</div>
