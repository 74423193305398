<script>
    import {getText} from '../../language.svelte';
    import CardBadgeClickableTag from './CardBadgeClickableTag.svelte';

    export let showFavs;
    export let showExlusives;
    export let showAll;
    export let showFiltered = false;
    export let showFilteredButton = false;
    export let filterKey;
    export let filteredPodcasts = [];
    export let allPodcasts;

    $: filterKey, updateCustomFilter();

    let lastCategory = 'all';
    let bgPassive = 'bg-blueGray-200';
    let bgActivated = 'bg-blueGray-400';
    let bgPassiveCustomFilter = 'bg-teal-200';
    let bgActivatedCustomFilter = 'bg-teal-400';

    function triggerFilter(origin, key) {
        if (origin == 'favs') {
            lastCategory = 'favs';
            showFavs = !showFavs;

            if (showFavs) {
                showFiltered = false;
                showExlusives = false;
                showAll = false;
            } else {
                showAll = true;
                lastCategory = 'all';
            }
        }
        if (origin == 'exclusives') {
            lastCategory = 'exclusives';
            showExlusives = !showExlusives;

            if (showExlusives) {
                showFiltered = false;
                showFavs = false;
                showAll = false;
            } else {
                showAll = true;
                lastCategory = 'all';
            }
        }
        if (origin == 'all') {
            lastCategory = 'all';
            showAll = !showAll;

            if (showAll) {
                showFiltered = false;
                showFavs = false;
                showExlusives = false;
                lastCategory = 'all';
            } else {
                showAll = true;
            }
        }
        if (origin == 'filterKey') {
            lastCategory !== previousFilterKey ? (showFiltered = !showFiltered) : (showFiltered = true);

            if (key) {
                previousFilterKey = key;
                lastCategory = key;
            }

            if (showFiltered) {
                showAll = false;
                showFavs = false;
                showExlusives = false;
                key ? (lastCategory = key) : '';
                previousFilterKey ? (lastCategory = previousFilterKey) : '';
            } else {
                showAll = true;
                lastCategory = 'all';
            }
        }
    }

    let previousFilterKey = '';
    // Custom filter
    function updateCustomFilter() {
        if (filterKey) {
            filteredPodcasts = [];

            allPodcasts.forEach((podcast) => {
                if (podcast.keywords.length > 0) {
                    podcast.keywords.forEach((key) => {
                        if (lastCategory == 'all') {
                            key == filterKey ? filteredPodcasts.push(podcast.id) : '';
                        }
                        if (lastCategory == 'favs') {
                            key == filterKey && podcast.favorite ? filteredPodcasts.push(podcast.id) : '';
                        }
                        if (lastCategory == 'exclusives') {
                            key == filterKey && (podcast.exclusiveConfirmed || podcast.exclusiveNotConfirmed) ? filteredPodcasts.push(podcast.id) : '';
                        }
                        if (lastCategory == previousFilterKey) {
                            key == filterKey ? filteredPodcasts.push(podcast.id) : '';
                        }
                    });
                }
            });

            triggerFilter('filterKey', filterKey);
            showFilteredButton = true;
            showFiltered = true;
        }
    }

    function resetCustomFilter() {
        showFilteredButton = false;
        showFiltered = false;
        showAll = true;
        filterKey = '';
        filteredPodcasts = [];
    }
</script>

<div class="rounded-t bg-white mb-0 px-6 py-3">
    <div class="text-center flex justify-between">
        <div class="block w-full overflow-x-auto">
            <CardBadgeClickableTag
                id="grid-button-filter-favs"
                text={getText('promoter.network.podcasts.title_2')}
                backgroundColor={showFavs ? bgActivated : bgPassive}
                classText="text-sm"
                icon="fas fa-star text-xs"
                on:click={() => {
                    triggerFilter('favs');
                }}
            />
            <CardBadgeClickableTag
                id="grid-button-filter-exclusives"
                text={getText('promoter.network.podcasts.title_3')}
                backgroundColor={showExlusives ? bgActivated : bgPassive}
                classText="text-sm"
                icon="fas fa-crown text-xs"
                on:click={() => {
                    triggerFilter('exclusives');
                }}
            />
            <CardBadgeClickableTag
                id="grid-button-filter-all"
                text={getText('promoter.network.podcasts.title_4')}
                backgroundColor={showAll ? bgActivated : bgPassive}
                classText="text-sm"
                on:click={() => {
                    triggerFilter('all');
                }}
            />
            {#if showFilteredButton}
                <CardBadgeClickableTag
                    id="grid-button-filter-filtered"
                    text={filterKey}
                    backgroundColor={showFiltered ? bgActivatedCustomFilter : bgPassiveCustomFilter}
                    classText="text-sm"
                    icon="fas fa-times text-xs"
                    on:click={() => {
                        triggerFilter('filterKey');
                    }}
                    iconOnClick={() => {
                        resetCustomFilter();
                    }}
                />
            {/if}
        </div>
    </div>
</div>
