<script>
    import {storedUser, campaignBookingRequests, storedCompany} from '../../store.js';
    import {postCommitMessage, postScriptBacklog, getPodcastCached, getScriptBacklogByBookingRequest, patchBookingRequest} from '../../api/api.js';
    import {databaseToPrettyDateTime, getDateNow} from '../../functions.js';
    import {setBookingRequestScript, setBookingRequestApproveScript, setScriptbacklogMeta} from '../../stateSetter.js';
    import CardCommitMessage from 'components/Cards/CardCommitMessage.svelte';
    import {getText} from '../../language.svelte';
    import {BADGE_BACKGROUND_WORDCOUNT} from '../../colors.js';
    import CardBadgeTag from 'components/Cards/CardBadgeTag.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let bookingRequest;
    export let campaign;
    let scriptEdited = waitForBookingRequest();
    export let showScriptControl = false;

    function close() {
        showScriptControl = false;
    }

    async function updateBookingRequestScript() {
        showScriptControl = false;
        bookingRequest = setBookingRequestScript(bookingRequest, scriptEdited, true, false);

        // patch bookingRequest
        try {
            await patchBookingRequest(bookingRequest.id, bookingRequest);
        } catch (e) {
            console.log(`Error ${e} occoured whilst patching the booking request: ${bR}`);
        }

        // write to backlog
        try {
            await postScriptBacklog(setScriptbacklogMeta(scriptEdited, campaign.id, bookingRequest));
        } catch (e) {
            console.log(`Error ${e} occoured whilst sending the script ${script} to the backlog !`);
        }

        scriptEdited = '';

        // for reactivity
        // remove from pending
        $campaignBookingRequests[campaign.id].pendingBRs = $campaignBookingRequests[campaign.id].pendingBRs.filter((bR) => bR.id !== bookingRequest.id);
        // add to pending
        $campaignBookingRequests[campaign.id].pendingBRs = [...$campaignBookingRequests[campaign.id].pendingBRs, bookingRequest];
    }

    async function approveScript() {
        showScriptControl = false;
        bookingRequest = setBookingRequestApproveScript(bookingRequest);

        // patch bookingRequest
        try {
            await patchBookingRequest(bookingRequest.id, bookingRequest);
            commitMessage ? (commitMessage = commitMessage) : (commitMessage = getText('commit_messages.default.booking_request.script_approved'));
            await saveCommitMessage(bookingRequest.id, 6).catch((e) => {
                console.log(`Error ${e} occoured whilst saving the commit for booking request ${bookingRequest}`);
            });
        } catch (e) {
            console.log(`Error ${e} occoured whilst saving the script changes for script ${script} and the booking request: ${bR}`);
        }

        try {
            await postScriptBacklog(setScriptbacklogMeta(scriptEdited, campaign.id, bookingRequest, true));
        } catch (e) {
            console.log(`Error ${e} occoured whilst sending the script ${script} to the backlog !`);
        }

        scriptEdited = '';

        // for reactivity
        // remove from pending
        $campaignBookingRequests[campaign.id].pendingBRs = $campaignBookingRequests[campaign.id].pendingBRs.filter((bR) => bR.id !== bookingRequest.id);
        // add to pending
        $campaignBookingRequests[campaign.id].pendingBRs = [...$campaignBookingRequests[campaign.id].pendingBRs, bookingRequest];
    }

    async function cancelBookingRequest() {
        showScriptControl = false;
        bookingRequest.state = 6;
        bookingRequest.cancelled_date = getDateNow();

        let patched = await patchBookingRequest(bookingRequest.id, bookingRequest);

        if (patched.status != 200) {
            console.log('Something went wrong saving the script changes !');
        }

        // for reactivity
        // remove from pending
        $campaignBookingRequests[campaign.id].pendingBRs = $campaignBookingRequests[campaign.id].pendingBRs.filter((bR) => bR.id !== bookingRequest.id);
        // add to cancelled
        $campaignBookingRequests[campaign.id].cancelledBRs = [...$campaignBookingRequests[campaign.id].cancelledBRs, bookingRequest];
    }

    async function rejectVersion() {
        bookingRequest.script_updated_promoter = true;
        bookingRequest.script_updated_podcast = false;
        bookingRequest.script_updated_date = getDateNow();

        // patch BR
        try {
            await patchBookingRequest(bookingRequest.id, bookingRequest);
            // for reactivity
            // remove from pending
            $campaignBookingRequests[campaign.id].pendingBRs = $campaignBookingRequests[campaign.id].pendingBRs.filter((bR) => bR.id !== bookingRequest.id);
            // add to pending
            $campaignBookingRequests[campaign.id].pendingBRs = [...$campaignBookingRequests[campaign.id].pendingBRs, bookingRequest];

            // save commit message
            await saveCommitMessage(bookingRequest.id, 8).catch((e) => {
                console.log(`Error ${e} occoured whilst saving the commit for booking request ${bookingRequest}`);
            });
        } catch (e) {
            console.log(`Error ${e} occoured whilst saving the script changes for script ${script} and the booking request: ${bR}`);
        }

        close();
    }

    function waitForBookingRequest() {
        if (bookingRequest) {
            return bookingRequest.script;
        } else setTimeout(waitForBookingRequest, 1000);
    }

    function hideScriptControl() {
        document.getElementById('grid-script-control').style.display = 'none';
    }

    // COMMIT MESSAGES
    function parentFunction() {
        rejectVersion();
    }

    async function saveCommitMessage(bookingRequestId, actionCode) {
        let data = {
            booking_request_id: bookingRequestId,
            action: actionCode,
            author: $storedUser.first_name,
            company_name: $storedCompany.name,
            company_type: $storedCompany.type,
            message: commitMessage,
            sender: true,
        };

        try {
            await postCommitMessage(bookingRequestId, data);
            return true;
        } catch (e) {
            console.log(`Error ${e} occoured whilst posting the message ${data.message}`);
            return false;
        }
    }

    function setAction(thisAction) {
        action = thisAction;
    }

    let commitTitle;
    function triggerCommitMessagePopUp() {
        commitTitle = getText('commit_messages.booking_modification.entry_1.headline_1');
        showCommitMessage = true;
    }
    let showCommitMessage = false;
    let commitMessage;
    let action = '';
    let continueProcess = false;

    function sendMail(bookingRequest, podcastName) {
        let body = `${getText('promoter.script_version_control.email_text', campaign.title)}:\n\n"${bookingRequest.script}"`;
        var link = 'mailto:?' + '&subject=' + encodeURIComponent(getText('promoter.script_version_control.email_subject', podcastName)) + '&body=' + encodeURIComponent(body);
        window.location.href = link;
    }

    function changeRequest() {
        hideScriptControl();
        setAction(getText('commit_messages.default.campaign.request_script_changes'));
        triggerCommitMessagePopUp();
    }
</script>

{#if showCommitMessage}
    <CardCommitMessage {action} {commitTitle} bind:commitMessage bind:continueProcess bind:showCommitMessage parentFunction={() => parentFunction()} />
{/if}

{#if showScriptControl}
    {#await getPodcastCached(bookingRequest.podcast_id) then data}
        <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex" id="grid-script-control">
            <div class="relative w-third mt-20 my-6 mx-auto">
                <div class="max-h-600px bg-gray-200 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                    <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded border-1">
                        <div class="rounded-t mb-0 px-4 py-3 border-0">
                            <div class="flex flex-wrap items-center">
                                <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                                    <h3 class="font-semibold text-base text-blueGray-700">
                                        <p>{getText('promoter.script_version_control.title_1', campaign.title)}</p>
                                        <p>{getText('promoter.script_version_control.title_2')}<em>{data.name}</em></p>
                                    </h3>
                                </div>
                                <div class="text-right">
                                    <CardButton id="close" type="default" text="X" on:click={() => close()} />
                                </div>
                            </div>
                        </div>

                        {#await getScriptBacklogByBookingRequest(campaign.id, bookingRequest.id) then scriptData}
                            <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-200 border-1">
                                <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                                    <div class="flex flex-wrap">
                                        <div class="w-full lg:w-12/12 px-4">
                                            <div class="relative w-full mb-3">
                                                <label class="mt-4 block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-script">
                                                    {getText('promoter.script_version_control.headline_1')}
                                                </label>
                                                <p
                                                    class="auto-linebreak border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                >
                                                    {campaign.script}
                                                </p>

                                                <div class="flex flex-wrap">
                                                    <p
                                                        id="grid-script"
                                                        class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    >
                                                        <CardBadgeTag text="{campaign.script.length} {getText('badges.text_editor.entry_1')}" backgroundColor={BADGE_BACKGROUND_WORDCOUNT} />
                                                        <CardBadgeTag text="{campaign.script.split(' ').length} {getText('badges.text_editor.entry_2')}" backgroundColor={BADGE_BACKGROUND_WORDCOUNT} />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- {#each scriptData as script}
                                <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-100 border-1">
                                    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                                        <div class="flex flex-wrap">
                                            <div class="w-full lg:w-12/12 px-4">
                                                <div class="relative w-full mb-3">
                                                    <label class="mt-4 block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-script"> Script </label>
                                                    <p
                                                        class="auto-linebreak border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    >
                                                        {script.script}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="w-full lg:w-12/12 px-4">
                                                <p
                                                    class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                >
                                                    Id: {script.id} || Editor: {script.editor} || Created: {databaseToPrettyDateTime(script.created)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            {/each} -->
                            <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-200 border-1">
                                <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                                    <div class="flex flex-wrap">
                                        <div class="w-full lg:w-12/12 px-4">
                                            <div class="relative w-full mb-3">
                                                {#if bookingRequest.state == 4}
                                                    <label class="mt-4 block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-script"> Last version</label>

                                                    <p
                                                        class="auto-linebreak border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    >
                                                        {bookingRequest.script}
                                                    </p>

                                                    <div class="flex flex-wrap">
                                                        <p
                                                            id="grid-script"
                                                            class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        >
                                                            <CardBadgeTag text="{bookingRequest.script.length} {getText('badges.text_editor.entry_1')}" backgroundColor={BADGE_BACKGROUND_WORDCOUNT} />
                                                            <CardBadgeTag
                                                                text="{bookingRequest.script.split(' ').length} {getText('badges.text_editor.entry_2')}"
                                                                backgroundColor={BADGE_BACKGROUND_WORDCOUNT}
                                                            />
                                                            <CardBadgeTag text={getText('badges.booking_request.entry_1', databaseToPrettyDateTime(bookingRequest.cancelled_date))} />
                                                            <CardBadgeTag text="{getText('badges.general.entry_1')}{bookingRequest.script_editor_user} (@ {bookingRequest.script_editor_company})" />
                                                        </p>
                                                    </div>
                                                {:else if bookingRequest.state == 6}
                                                    <label class="mt-4 block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-script">
                                                        {getText('promoter.script_version_control.headline_3')}</label
                                                    >

                                                    <p
                                                        class="auto-linebreak border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    >
                                                        {bookingRequest.script}
                                                    </p>
                                                    <CardBadgeTag text="{bookingRequest.script.length} {getText('badges.text_editor.entry_1')}" backgroundColor={BADGE_BACKGROUND_WORDCOUNT} />
                                                    <CardBadgeTag
                                                        text="{bookingRequest.script.split(' ').length} {getText('badges.text_editor.entry_2')}"
                                                        backgroundColor={BADGE_BACKGROUND_WORDCOUNT}
                                                    />
                                                    <CardBadgeTag text={getText('badges.booking_request.entry_2', databaseToPrettyDateTime(bookingRequest.cancelled_date))} />
                                                    <CardBadgeTag text="{getText('badges.general.entry_1')}{bookingRequest.script_editor_user} (@ {bookingRequest.script_editor_company})" />
                                                {:else if campaign.feedback_loop_script && bookingRequest.script_approved_date}
                                                    <label class="mt-4 block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-script">
                                                        {getText('promoter.script_version_control.headline_4')}
                                                        <CardButton id="share" type="icon" icon="fas fa-share" on:click={() => sendMail(bookingRequest, data.name)} />
                                                    </label>
                                                    <p
                                                        class="auto-linebreak border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    >
                                                        {bookingRequest.script}
                                                    </p>
                                                    <CardBadgeTag text="{bookingRequest.script.length} {getText('badges.text_editor.entry_1')}" backgroundColor={BADGE_BACKGROUND_WORDCOUNT} />
                                                    <CardBadgeTag
                                                        text="{bookingRequest.script.split(' ').length} {getText('badges.text_editor.entry_2')}"
                                                        backgroundColor={BADGE_BACKGROUND_WORDCOUNT}
                                                    />
                                                    <CardBadgeTag text={getText('badges.text_editor.entry_3', databaseToPrettyDateTime(bookingRequest.script_approved_date))} />
                                                    <CardBadgeTag text="{getText('badges.general.entry_1')}{bookingRequest.script_editor_user} (@ {bookingRequest.script_editor_company})" />
                                                {:else}
                                                    <label class="mt-4 block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-script">
                                                        {getText('promoter.script_version_control.headline_2')}
                                                        <CardButton id="share" type="icon" icon="fas fa-share" on:click={() => sendMail(bookingRequest, data.name)} />
                                                    </label>
                                                    <p
                                                        class="auto-linebreak border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                    >
                                                        {bookingRequest.script}
                                                    </p>
                                                    <CardBadgeTag text="{bookingRequest.script.length} {getText('badges.text_editor.entry_1')}" backgroundColor={BADGE_BACKGROUND_WORDCOUNT} />
                                                    <CardBadgeTag
                                                        text="{bookingRequest.script.split(' ').length} {getText('badges.text_editor.entry_2')}"
                                                        backgroundColor={BADGE_BACKGROUND_WORDCOUNT}
                                                    />
                                                    <CardBadgeTag text={getText('badges.text_editor.entry_4', databaseToPrettyDateTime(bookingRequest.script_updated_date))} />
                                                    <CardBadgeTag text="{getText('badges.general.entry_1')}{bookingRequest.script_editor_user} (@ {bookingRequest.script_editor_company})" />
                                                {/if}
                                            </div>
                                            {#if scriptEdited !== bookingRequest.script}
                                                <div class="text-center flex justify-end">
                                                    <CardButton type="go" text="Update & save script" textColor="text-black" on:click={() => updateBookingRequestScript()} />
                                                </div>
                                            {:else if bookingRequest.script_updated_podcast && !bookingRequest.script_approved_date && bookingRequest.state != 6 && $storedCompany.type == 'promoter'}
                                                <div class="text-center flex justify-end">
                                                    <CardButton id="approve" type="go" text={getText('promoter.script_version_control.button_approve')} on:click={() => approveScript()} />
                                                    <CardButton id="change" type="change" text={getText('promoter.script_version_control.button_request_changes')} on:click={() => changeRequest()} />
                                                </div>
                                            {/if}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {/await}
                    </div>
                </div>
            </div>
        </div>

        <div class="opacity-25 fixed inset-0 z-40 bg-black" />
    {/await}
{/if}
