<script>
    import {createPopper} from '@popperjs/core';
    import {navigate} from 'svelte-routing';
    import {language, storedCompany} from '../../store.js';

    let dropdownPopoverShow = false;
    let loggedIn = $storedCompany.type ? true : false;

    let btnDropdownRef;
    let popoverDropdownRef;

    const toggleDropdown = (event) => {
        event.preventDefault();
        if (dropdownPopoverShow) {
            dropdownPopoverShow = false;
        } else {
            dropdownPopoverShow = true;
            createPopper(btnDropdownRef, popoverDropdownRef, {
                placement: 'bottom-start',
            });
        }
    };

    function setLanguage() {
        if (loggedIn) {
            if ($language == 'EN') {
                $language = 'DE';
                navigate(`/`);
                dropdownPopoverShow = false;
            } else if ($language == 'DE') {
                $language = 'EN';
                navigate(`/`);
                dropdownPopoverShow = false;
            }
        }
    }
</script>

<div>
    <a class="text-blueGray-500 block" href="#pablo" bind:this={btnDropdownRef} on:click={toggleDropdown}>
        <div class="items-center flex">
            <span class="w-12 h-12 text-sm text-blueGray-200 inline-flex items-center justify-center rounded-full">
                {$language}
            </span>
        </div>
    </a>
    <div bind:this={popoverDropdownRef} class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-0 {dropdownPopoverShow ? 'block' : 'hidden'}">
        <a
            class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            href="#pablo"
            on:click={(e) => {
                e.preventDefault(), setLanguage();
            }}
        >
            {#if $language == 'EN'}
                DE
            {:else if $language == 'DE'}
                EN
            {/if}
        </a>
    </div>
</div>
