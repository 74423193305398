<script>
    import NewPodcast from '../admin/NewPodcast.svelte';
    import CardTablePodcasts from 'components/Cards/CardTablePodcasts.svelte';
    import {storedUser, render} from '../../store.js';

    export let type;
    let companyId;
    $render = Math.random();

    if (type) {
        companyId = $storedUser.company_id;
    } else companyId = -1;
</script>

<CardTablePodcasts {companyId} />
<NewPodcast {type} />
