<script>
    import CardCommitMessageFlow from 'components/Cards/CardCommitMessageFlow.svelte';
    import {getText} from '../../language.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let bookingRequest;
    export let campaign;
    export let lastCommitMessage;
    export let commitMessage;
    export let title;
    export let backgroundColor = 'bg-emerald-300';

    let showCommitMessages;

    function detailsCommitMessages() {
        showCommitMessages = true;
    }
</script>

{#if showCommitMessages}
    <CardCommitMessageFlow {bookingRequest} {campaign} bind:showCommitMessages />
{/if}

<div class="{backgroundColor} flex-auto px-4 lg:px-10 py-5 pt-0">
    <div class="flex flex-wrap mt-5">
        <div class="w-full lg:w-9/12 px-4">
            <div class="relative w-full">
                <label class="block text-blueGray-600 text-lg font-bold" for="grid-feedback">
                    {title}:
                </label>
                <p class="block text-blueGray-600 text-base font-bold">
                    {#if lastCommitMessage && lastCommitMessage.message}
                        "{lastCommitMessage.message}"
                    {:else if commitMessage}
                        "{commitMessage}"
                    {:else}
                        {getText('podcast.booking_request_details.commit_messages.headline_9', lastCommitMessage.description)}
                    {/if}
                </p>
            </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
            <div class="flex justify-end">
                <CardButton id="details" type="default" text={getText('podcast.booking_request_details.commit_messages.button_show_all')} on:click={() => detailsCommitMessages()} />
            </div>
        </div>
    </div>
</div>
