<script>
    // core components
    import CardTableCompanies from 'components/Cards/CardTableCompanies.svelte';
    import CardTablePodcasts from 'components/Cards/CardTablePodcasts.svelte';
    import CardTableUsers from 'components/Cards/CardTableUsers.svelte';
    import CardTableKeywords from 'components/Cards/CardTableKeywords.svelte';
</script>

<div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
        <CardTableUsers showNewButton={true}/>
        <CardTableCompanies showNewButton={true}/>
        <CardTablePodcasts showNewButton={true}/>
        <CardTableKeywords showNewButton={true}/>
    </div>
</div>
