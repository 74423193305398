<script>
    export let color = 'light';
    import {navigate} from 'svelte-routing';
    import {getAllCompanies} from '../../api/api.js';
    import {Pulse} from 'svelte-loading-spinners';
    import {allCompanies} from '../../store.js';
    import {onMount} from 'svelte';
    import {getText} from '../../language.svelte';
    import RowAdminCompany from '../TableRows/RowAdminCompany.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let showNewButton = false;

    onMount(() => {
        allCompanies.set([]);
    });

    function newCompany() {
        navigate('/admin/newcompany', {replace: false});
    }

    async function getCompanies() {
        try {
            return await getAllCompanies()
                .then((response) => response.json())
                .then((data) => {
                    $allCompanies = data;
                    return data;
                });
        } catch (e) {
            console.log(`Error ${e} occoured whilst loading companies !`);
        }
    }

    let companyPromise = getCompanies();
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">
    <div style="display: flex; justify-content: center" />
    <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
            <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}">
                    {getText('admin.companies.all_companies.title')}
                </h3>
            </div>
            {#if showNewButton}
                <CardButton id="new-company" type="go" text={getText('admin.companies.all_companies.button_new')} on:click={() => newCompany()} />
            {/if}
        </div>
    </div>

    <div class="block w-full overflow-x-auto">
        <table class="items-center w-full bg-transparent border-collapse">
            <thead>
                <tr>
                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-red-700 text-red-200 border-red-600'}"
                    />
                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-red-700 text-red-200 border-red-600'}"
                    >
                        {getText('admin.companies.all_companies.headline_1')}
                    </th>
                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-red-700 text-red-200 border-red-600'}"
                    >
                        {getText('admin.companies.all_companies.headline_2')}
                    </th>
                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-red-700 text-red-200 border-red-600'}"
                    >
                        {getText('admin.companies.all_companies.headline_3')}
                    </th>
                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-red-700 text-red-200 border-red-600'}"
                    />
                </tr>
            </thead>

            {#await companyPromise}
                <div style="display: flex; justify-content: center">
                    <Pulse size="60" color="#FF3E00" unit="px" duration="1s" />
                </div>
            {:then data}
                <tbody />
                {#if Object.keys(data).length > 0}
                    {#each data as item (item.id)}
                        <RowAdminCompany {item} />
                    {/each}
                {/if}
            {/await}
        </table>
    </div>
</div>
