<script>
    import {showModal} from '../../store.js';

    export let modalMsg;
    export let modalTitle;
    export let buttons;
    export let colors = ['bg-red-400', 'bg-emerald-500', 'bg-emerald-500'];

    function toggleModal() {
        $showModal = !$showModal;
    }

    export let functionOne = () => {
        toggleModal();
    };
    export let functionTwo = () => {
        toggleModal();
    };
    export let functionThree = () => {
        toggleModal();
    };
</script>

{#if $showModal}
    <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
        <div class="relative w-auto my-6 mx-auto max-w-sm">
            <!--content-->
            <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <!--header-->
                <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 class="text-3xl font-semibold">{modalTitle}</h3>
                    <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" on:click={toggleModal}>
                        <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"> × </span>
                    </button>
                </div>
                <!--body-->
                <div class="relative p-6 flex-auto">
                    <p class="my-4 text-blueGray-500 text-lg leading-relaxed">
                        {modalMsg}
                    </p>
                </div>
                <!--footer-->
                <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                        class="{colors[0]} text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        on:click={functionOne}
                    >
                        {buttons[0]}
                    </button>

                    <button
                        class="{colors[1]} text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        on:click={functionTwo}
                    >
                        {buttons[1]}
                    </button>
                    <button
                        class="{colors[2]} text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        on:click={functionThree}
                    >
                        {buttons[2]}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="opacity-25 fixed inset-0 z-40 bg-black" />
{/if}
