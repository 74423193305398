<script>
    import {onMount} from 'svelte';

    export let src;

    let player, current, length, timeline, progress, playBtn, pauseBtn;

    export function refresh() {
        player.load();
    }

    function getTimeCodeFromNum(num) {
        let seconds = parseInt(num);
        let minutes = parseInt(seconds / 60);
        seconds -= minutes * 60;
        const hours = parseInt(minutes / 60);
        minutes -= hours * 60;

        if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
        return `${String(hours).padStart(2, 0)}:${minutes}:${String(seconds % 60).padStart(2, 0)}`;
    }

    onMount(() => {
        const audio = player;
        audio.addEventListener(
            'loadeddata',
            () => {
                length.textContent = getTimeCodeFromNum(audio.duration);
                audio.volume = 0.75;
            },
            false,
        );

        audio.addEventListener('timeupdate', () => {
            progress.style.width = (audio.currentTime / audio.duration) * 100 + '%';
            current.textContent = getTimeCodeFromNum(audio.currentTime);
        });

        timeline.addEventListener(
            'click',
            (e) => {
                const timelineWidth = window.getComputedStyle(timeline).width;
                const timeToSeek = (e.offsetX / parseInt(timelineWidth)) * audio.duration;
                audio.currentTime = timeToSeek;
            },
            false,
        );

        playBtn.addEventListener(
            'click',
            () => {
                playBtn.classList.add('hide');
                pauseBtn.classList.remove('hide');
                audio.play();
            },
            false,
        );

        pauseBtn.addEventListener(
            'click',
            () => {
                playBtn.classList.remove('hide');
                pauseBtn.classList.add('hide');
                audio.pause();
            },
            false,
        );
    });
</script>

<audio bind:this={player} controls {src} />

<div class="audio-player">
    <div class="play-container">
        <div bind:this={playBtn} class="toggle-play play" />
        <div bind:this={pauseBtn} class="toggle-play pause hide" />
    </div>
    <div bind:this={timeline} class="timeline">
        <div bind:this={progress} class="progress" />
    </div>
    <div class="time">
        <div bind:this={current} class="current">0:00</div>
        <div class="divider">/</div>
        <div bind:this={length} class="length" />
    </div>
</div>

<style>
    audio {
        display: none;
    }

    .audio-player {
        width: auto;
        padding: 0.25em 0.75em;
        background: #444;
        border-radius: 0.75em;
        color: white;
        overflow: hidden;
        display: flex;
        gap: 0.5em;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 1em 0;
    }

    .audio-player > div {
        flex: 1;
    }

    .hide {
        display: none;
    }

    .audio-player .timeline {
        background: white;
        width: 100%;
        height: 0.25em;
        position: relative;
        cursor: pointer;
        box-shadow: 0 2px 10px 0 #0008;
        flex: 3;
    }

    .audio-player .timeline .progress {
        background: coral;
        width: 0%;
        height: 100%;
        /*transition: 0.25s;*/
    }

    .audio-player .toggle-play.play {
        cursor: pointer;
        position: relative;
        left: 0;
        height: 0;
        width: 0;
        border: 7px solid #0000;
        border-left: 13px solid white;
    }
    .audio-player .toggle-play.play:hover {
        transform: scale(1.1);
    }
    .audio-player .toggle-play.pause {
        height: 15px;
        width: 20px;
        cursor: pointer;
        position: relative;
    }
    .audio-player .toggle-play.pause:before {
        position: absolute;
        top: 0;
        left: 0px;
        background: white;
        content: '';
        height: 14px;
        width: 3px;
    }
    .audio-player .toggle-play.pause:after {
        position: absolute;
        top: 0;
        right: 10px;
        background: white;
        content: '';
        height: 14px;
        width: 3px;
    }
    .audio-player .toggle-play.pause:hover {
        transform: scale(1.1);
    }
    .audio-player .time {
        display: flex;
    }
    .audio-player .time > * {
        padding: 2px;
    }
</style>
