<script>
    // core components
    import {getText} from '../../language.svelte';
    import {resetPassword} from '../../api/api.js';
    import {clearApiCache} from '../../api/api-cache';
    import {navigate} from 'svelte-routing';
    import CardButton from 'components/Cards/CardButton.svelte';

    function checkPassword() {
        if (password.length >= 8) {
            // let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
            let format = /[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?]+/;

            // check that password has NO special characters and spaces
            // check that password has at least one number and one letter
            if (!format.test(password) && !/\s/g.test(password) && /\d/.test(password) && /[a-zA-Z]/.test(password)) {
                passwordCorrect = true;
            } else {
                passwordCorrect = false;
            }
        }
    }

    async function updatePassword() {
        // get token
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('token');

        let data = {
            token: token,
            password: password,
        };

        // reset with new password
        try {
            await resetPassword(data);
            clearApiCache();
            navigate('/auth/login');
        } catch (e) {
            console.log(`Error ${e} occoured whilst requesting a new password ! Please contact the admin`);
            window.alert(`Someting went wrong saving your new password. Please contact the admin.`);
        }
    }

    function shake() {
        document.getElementById('btn').classList.add('shake');

        var delay = setTimeout(function () {
            document.getElementById('btn').classList.remove('shake');
        }, 3000);
    }

    $: type = show_password ? 'text' : 'password';
    function onInput(event) {
        password = event.target.value;
    }

    let show_password = false;
    let password;
    let passwordCorrect = false;
</script>

<div>
    <div class="container mx-auto px-4">
        <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg">
            <div class="px-6">
                <div class="text-center mt-12">
                    <h3 class="hover:edit-pointer text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">{getText('user.password_reset.title')}</h3>
                    <div>{getText('user.password_reset.headline_1')}</div>
                    <div>{getText('user.password_reset.headline_2')}</div>
                    <div>{getText('user.password_reset.headline_3')}</div>
                    <div>{getText('user.password_reset.headline_4')}</div>
                    <div class="pwd text-center mt-12">
                        <input
                            id="grid-new-password"
                            {type}
                            {password}
                            class="text-sm font-semibold leading-normal mt-0 mb-2 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder={getText('user.password_reset.input_placeholder')}
                            on:keyup={(e) => {
                                checkPassword();
                                if (e.code == 'Escape') {
                                    password = '';
                                    passwordCorrect = false;
                                }
                                if (e.code == 'Enter') {
                                    updatePassword();
                                }
                            }}
                            on:input={onInput}
                        />
                        <span class="p-viewer">
                            <!-- <i class="fas fa-eye" aria-hidden="true" /> -->
                            <button type="button" on:click={() => (show_password = !show_password)}>
                                <!-- {show_password ? 'Hide' : 'Show'} -->
                                {#if show_password}
                                    <i class="fas fa-eye-slash" aria-hidden="true" />
                                {:else}
                                    <i class="fas fa-eye" aria-hidden="true" />
                                {/if}
                            </button>
                        </span>
                    </div>
                    <div class="mb-2 text-blueGray-600">
                        {#if passwordCorrect}
                            <CardButton
                                id="update-password"
                                type="go"
                                text={getText('user.password_reset.button_1')}
                                on:click={() => {
                                    updatePassword();
                                }}
                            />
                        {:else}
                            <button
                                class="bg-blueGray-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                id="btn"
                                on:click={() => {
                                    shake();
                                }}
                            >
                                <span class="default btn-caption">{getText('user.password_reset.button_1')}</span>
                                <span class="error btn-caption">{getText('user.password_reset.button_2')}</span>
                            </button>
                        {/if}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
