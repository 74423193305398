<script>
    import {prettyPrintNumber, downloadFromUrl, getDateNow} from '../../functions.js';
    import CardBookingProductionFlow from 'components/Cards/CardBookingProductionFlow.svelte';
    import {postCommitMessage, patchBookingRequest, getImpressionsByDealId, postReportBacklog} from '../../api/api.js';
    import {storedUser, storedCompany, campaignBookingRequests} from '../../store.js';
    import CardCommitMessageFlow from 'components/Cards/CardCommitMessageFlow.svelte';
    import CardCommitMessage from 'components/Cards/CardCommitMessage.svelte';
    import CardScriptVersionControl from 'components/Cards/CardScriptVersionControl.svelte';
    import {getText} from '../../language.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';
    import {setBookingRequestState, setReportbacklogMeta, rejectedBookingRequestReport} from '../../stateSetter.js';

    export let podcastName;
    export let bookingRequest;
    export let campaign;

    let showFlow = false;
    let bR2;

    function details(bR) {
        bR2 = bR;
        showFlow = true;
    }

    let bR3;
    function detailsCommitMessages(bR) {
        bR3 = bR;
        showCommitMessages = true;
    }

    let bR4;
    function detailsScript(bR) {
        bR4 = bR;
        showScriptControl = true;
    }

    async function approveReport() {
        bookingRequest = setBookingRequestState(bookingRequest, 12);

        try {
            await patchBookingRequest(bookingRequest.id, bookingRequest);

            // remove from completed
            $campaignBookingRequests[campaign.id].completedBRs = $campaignBookingRequests[campaign.id].completedBRs.filter((bR) => bR.id !== bookingRequest.id);
            // add to completed
            $campaignBookingRequests[campaign.id].completedBRs = [...$campaignBookingRequests[campaign.id].completedBRs, bookingRequest];

            // save commit message
            commitMessage = getText('commit_messages.default.booking_request.report_approved');
            await saveCommitMessage(bookingRequest.id, 12).catch((e) => {
                console.log(`Error ${e} occoured whilst saving the commit for booking request ${bookingRequest}`);
            });
        } catch (e) {
            console.log(`Error ${e} occoured whilst approving the report for booking request ${bookingRequest.id}`);
        }

        try {
            await postReportBacklog(setReportbacklogMeta(bookingRequest.report, bookingRequest.campaign_id, bookingRequest, true));
        } catch (e) {
            console.log(`Error ${e} occoured whilst writing to the report backlog for booking request ${bookingRequest}`);
        }
    }

    async function rejectVersion() {
        bookingRequest = rejectedBookingRequestReport(bookingRequest);

        // patch BR
        try {
            await patchBookingRequest(bookingRequest.id, bookingRequest);

            // remove from completed
            $campaignBookingRequests[campaign.id].completedBRs = $campaignBookingRequests[campaign.id].completedBRs.filter((bR) => bR.id !== bookingRequest.id);
            // add to completed
            $campaignBookingRequests[campaign.id].completedBRs = [...$campaignBookingRequests[campaign.id].completedBRs, bookingRequest];

            // save commit message
            commitMessage ? (commitMessage = commitMessage) : (commitMessage = getText('commit_messages.default.booking_request.no_feedback'));
            await saveCommitMessage(bookingRequest.id, 13).catch((e) => {
                console.log(`Error ${e} occoured whilst saving the commit for booking request ${bookingRequest}`);
            });
        } catch (e) {
            console.log(`Error ${e} occoured whilst rejecting the report for booking request ${bookingRequest}`);
        }
    }

    // COMMIT MESSAGES
    function parentFunction() {
        rejectVersion();
    }

    async function saveCommitMessage(bookingRequestId, actionCode) {
        let data = {
            booking_request_id: bookingRequestId,
            action: actionCode,
            author: $storedUser.first_name,
            company_name: $storedCompany.name,
            company_type: $storedCompany.type,
            message: commitMessage,
            sender: true,
        };

        try {
            await postCommitMessage(bookingRequestId, data);
            return true;
        } catch (e) {
            console.log(`Error ${e} occoured whilst posting the message ${data.message}`);
            return false;
        }
    }

    function setAction(thisAction) {
        action = thisAction;
    }

    let commitTitle;
    function triggerCommitMessagePopUp() {
        commitTitle = getText('commit_messages.booking_modification.entry_1.headline_1');
        showCommitMessage = true;
    }
    let showCommitMessage = false;
    let showCommitMessages = false;
    let commitMessage;
    let action = '';
    let continueProcess = false;
    let showScriptControl = false;
</script>

{#if showFlow}
    <CardBookingProductionFlow bookingRequest={bR2} {campaign} />
{/if}

{#if showScriptControl}
    <CardScriptVersionControl bookingRequest={bR4} {campaign} bind:showScriptControl />
{/if}

{#if showCommitMessages}
    <CardCommitMessageFlow bookingRequest={bR3} {campaign} bind:showCommitMessages />
{/if}

{#if showCommitMessage}
    <CardCommitMessage {action} {commitTitle} bind:commitMessage bind:continueProcess bind:showCommitMessage parentFunction={() => parentFunction()} />
{/if}

{#await getImpressionsByDealId(bookingRequest.deal_id) then liveImpressions}
    <tr class="hover:cell-highlight-color-level-2">
        {#if bookingRequest.state == 10}
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {getText('promoter.campaign_overview.booking_requests_table_rows.completed.state_10.next_step_1')}
            </td>
        {:else if bookingRequest.state == 11}
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <CardButton
                    id="download-report"
                    type="go-blue"
                    text={getText('promoter.campaign_overview.booking_requests_table_rows.completed.state_11.next_step_1')}
                    title="Download Report"
                    on:click={() => {
                        downloadFromUrl(bookingRequest.report, bookingRequest.report_name_original);
                    }}
                />
                <CardButton
                    id="approve-report"
                    type="go"
                    text={getText('promoter.campaign_overview.booking_requests_table_rows.completed.state_11.next_step_2')}
                    title="Approve Report"
                    on:click={approveReport}
                />
                <CardButton
                    id="change-report"
                    type="change"
                    text={getText('promoter.campaign_overview.booking_requests_table_rows.completed.state_11.next_step_3')}
                    title="Change Report"
                    on:click={() => {
                        setAction(getText('commit_messages.default.campaign.request_script_changes'));
                        triggerCommitMessagePopUp();
                    }}
                />
            </td>
        {:else if bookingRequest.state == 12}
            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {getText('promoter.campaign_overview.booking_requests_table_rows.completed.state_12.next_step_1')}
            </td>
        {/if}

        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {podcastName}
        </td>
        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            {prettyPrintNumber(bookingRequest.requested_impressions)} / {prettyPrintNumber(bookingRequest.offered_impressions)}
        </td>
        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {prettyPrintNumber(liveImpressions.value)} </td>
        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            <CardButton id="info" type="icon" icon="fas fa-info-circle mr-2 text-sm" title="Production Flow" on:click={() => details(bookingRequest)} />
            {#if $storedCompany.type !== 'podcast'}
                <CardButton id="commit-messages" type="icon" icon="fas fa-comment-alt mr-2 text-sm" title="Commit Messages" on:click={() => detailsCommitMessages(bookingRequest)} />
            {/if}
            {#if campaign.type == 'hostread' && campaign.briefing_completed}
                <CardButton id="script-version-control" type="icon" icon="fas fa-file-alt mr-2 text-sm" title="Script Version Control" on:click={() => detailsScript(bookingRequest)} />
            {/if}
        </td>
    </tr>
{/await}
