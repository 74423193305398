<script>
    import {getPodcastCached, allCommitMessagesOfBookingRequest} from '../../api/api.js';
    import {databaseToPrettyDateTime} from '../../functions.js';
    import {getText} from '../../language.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let bookingRequest;
    export let campaign;
    export let showCommitMessages;
    // export let showCommitMessages;

    function close() {
        showCommitMessages = false;
        // bookingRequest = null;
    }

    async function getAllCommits() {
        try {
            return allCommitMessagesOfBookingRequest(bookingRequest.id).then((response) =>
                response.json().then((data) => {
                    return data;
                }),
            );
        } catch (e) {
            console.log(`Error ${e} occoured whilst getting the commit messages for booking request ${bookingRequest}`);
        }
    }
</script>

{#if showCommitMessages}
    <!-- {#if bookingRequest} -->
    {#await getPodcastCached(bookingRequest.podcast_id) then data}
        {#await getAllCommits() then commitMessages}
            <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
                <div class="relative w-third mt-20 my-6 mx-auto">
                    <div class="max-h-600px bg-gray-200 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                        <div class="bg-gray-300 rounded-t mb-3 px-4 py-3 border-0">
                            <div class="flex flex-wrap items-center">
                                <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                                    <h3 class="font-semibold text-base text-blueGray-700">
                                        <p>{getText('commit_messages.commit_message_flow.title_1', campaign.title)}</p>
                                        <p>{getText('commit_messages.commit_message_flow.title_2')}<em>{data.name}</em></p>
                                    </h3>
                                </div>
                                <div class="text-right">
                                    <CardButton id="close" type="default" text="X" on:click={() => close()} />
                                </div>
                            </div>
                        </div>

                        <div class="overflow-scroll flex flex-col">
                            {#each commitMessages as commit}
                                {#if commit.company_type == 'promoter'}
                                    <div class="w-full lg:w-8/12 px-4">
                                        <div class="bg-white relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg">
                                            <div class="flex-auto px-4 lg:px-10 pt-0">
                                                <div class="flex flex-wrap">
                                                    <div class="relative w-full mb-3">
                                                        <label class="mt-4 block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-script"> {commit.description} </label>
                                                        <p
                                                            class="auto-linebreak border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        >
                                                            <em>{commit.message}</em>
                                                        </p>
                                                        <div
                                                            class="flex flex-wrap justify-between auto-linebreak border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-xs focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        >
                                                            <div>
                                                                {commit.author}
                                                            </div>
                                                            <div>
                                                                {databaseToPrettyDateTime(commit.created)}
                                                            </div>
                                                            <div>
                                                                {#if commit.read}
                                                                    <i class="fas fa-check-double text-emerald-600 mr-2" />
                                                                {:else}
                                                                    <i class="fas fa-check mr-2" />
                                                                {/if}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                {/if}

                                {#if commit.company_type == 'podcast' && campaign.type == 'crosspromo'}
                                    <!-- {console.log('bookingRequest.sender: ', bookingRequest.sender)}
                                    {console.log('data.name: ', data.name)} -->
                                    <!-- {#await getCompanyInfoCached(campaign.company_id) then senderData} -->
                                    <!-- {#await companyPromisse then senderData} -->
                                    {#if commit.sender}
                                        <!-- {#if bookingRequest.sender == senderData[0].name} -->
                                        <div class="w-full lg:w-8/12 px-4">
                                            <div class="bg-white relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg">
                                                <div class="flex-auto px-4 lg:px-10 pt-0">
                                                    <div class="flex flex-wrap">
                                                        <div class="relative w-full mb-3">
                                                            <label class="mt-4 block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-script"> {commit.description} </label>
                                                            <p
                                                                class="auto-linebreak border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            >
                                                                <em>{commit.message}</em>
                                                            </p>
                                                            <div
                                                                class="flex flex-wrap justify-between auto-linebreak border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-xs focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            >
                                                                <div>
                                                                    {commit.author}
                                                                </div>
                                                                <div>
                                                                    {databaseToPrettyDateTime(commit.created)}
                                                                </div>
                                                                <div>
                                                                    {#if commit.read}
                                                                        <i class="fas fa-check-double text-emerald-600 mr-2" />
                                                                    {:else}
                                                                        <i class="fas fa-check mr-2" />
                                                                    {/if}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    {:else}
                                        <div class="self-end w-full lg:w-8/12 px-4">
                                            <div class="bg-blueGray-300 relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg">
                                                <div class="flex-auto px-4 lg:px-10 pt-0">
                                                    <div class="flex flex-wrap">
                                                        <div class="relative w-full mb-3">
                                                            <label class="mt-4 block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-script"> {commit.description} </label>
                                                            <p
                                                                class="auto-linebreak border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            >
                                                                <em>{commit.message}</em>
                                                            </p>
                                                            <div
                                                                class="flex flex-wrap justify-between auto-linebreak border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-xs focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                            >
                                                                <div>
                                                                    {commit.author}
                                                                </div>
                                                                <div>
                                                                    {databaseToPrettyDateTime(commit.created)}
                                                                </div>
                                                                <div>
                                                                    {#if commit.read}
                                                                        <i class="fas fa-check-double text-emerald-600 mr-2" />
                                                                    {:else}
                                                                        <i class="fas fa-check mr-2" />
                                                                    {/if}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    {/if}
                                    <!-- {/await} -->
                                {:else if commit.company_type == 'podcast'}
                                    <div class="self-end w-full lg:w-8/12 px-4">
                                        <div class="bg-blueGray-300 relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg">
                                            <div class="flex-auto px-4 lg:px-10 pt-0">
                                                <div class="flex flex-wrap">
                                                    <div class="relative w-full mb-3">
                                                        <label class="mt-4 block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-script"> {commit.description} </label>
                                                        <p
                                                            class="auto-linebreak border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        >
                                                            <em>{commit.message}</em>
                                                        </p>
                                                        <div
                                                            class="flex flex-wrap justify-between auto-linebreak border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-xs focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        >
                                                            <div>
                                                                {commit.author}
                                                            </div>
                                                            <div>
                                                                {databaseToPrettyDateTime(commit.created)}
                                                            </div>
                                                            <div>
                                                                {#if commit.read}
                                                                    <i class="fas fa-check-double text-emerald-600 mr-2" />
                                                                {:else}
                                                                    <i class="fas fa-check mr-2" />
                                                                {/if}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                {/if}
                            {/each}
                        </div>
                    </div>
                </div>
            </div>

            <div class="opacity-25 fixed inset-0 z-40 bg-black" />
        {/await}
    {/await}
{/if}
