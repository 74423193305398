<script>
    import CardCampaign from 'components/Cards/CardCampaign.svelte';
    import {selectedCampaign} from '../../store.js';

    export let ctype;

    // reset store
    selectedCampaign.set({});
</script>

<div class="flex flex-wrap">
    <div class="w-full mb-12 px-4">
        <CardCampaign {ctype} cardTitle="Create New Campaign" />
    </div>
</div>
