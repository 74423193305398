<!-- App.svelte -->
<script>
    import './custom.css';

    import {Router, Route, navigate} from 'svelte-routing';

    import Index from './views/Index.svelte';
    import Auth from './layouts/Auth.svelte';
    import {beforeUpdate} from 'svelte';
    import {storedCompany, storedUser} from './store';
    import {whoami} from './api/api';
    import {clearApiCache} from './api/api-cache';

    let routes = [
        //AUTH
        {path: '/auth', component: Auth, params: {}},
        {path: '/auth/*', component: Auth, params: {}},
        {path: '/*', component: Index, params: {}},
    ];

    beforeUpdate(async () => {
        // if password reset with token
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('token');
        if (token) {
            navigate(`/user/reset-password?token=${token}`);
        } else if (!$storedUser.id && !token) {
            clearApiCache();
            navigate('/auth/login');
        } else {
            let user;
            try {
                user = await whoami();
            } catch (e) {
                console.log(`Error ${e} occoured during whoami !`);
            }

            if (user.id !== $storedUser.id) {
                clearApiCache();
                navigate('/auth/logout');
            } else if (location.pathname === '/') {
                $storedUser.isValidated = true;
                if ($storedCompany && $storedCompany.type) {
                    navigate(`/${$storedCompany.type}`);
                }
            }
        }

        // TODO check mit Vinc ob das okay ist wenn das hier auskommentiert ist !!! Security !!!
        // if (location.pathname.indexOf('auth') === -1 && location.pathname.indexOf($storedCompany.type) === -1 && !$storedUser.is_superuser) {
        //     navigate('/auth/logout');
        // }
    });

    console.log('app');
</script>

<Router>
    {#each routes as route}
        <Route path={route.path} component={route.component} {...route.params} />
    {/each}
</Router>
