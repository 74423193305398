<script>
    import {onMount} from 'svelte';
    import Chart from 'chart.js/auto';
    import annotationPlugin from 'chartjs-plugin-annotation';
    import 'chartjs-adapter-date-fns';
    import {getBookingRequestByIdCached, getCampaignByIdCached, getInventoryBatch, addCampaign, saveInventoryReservation} from '../../api/api.js';
    import {databaseToDatePickerDate, getDateMidnight, getUnixTimestampsDailyTimerange, unixTimestampToDatePicker} from '../../functions.js';
    import {CHART_COLORS_BOOKING, CHART_COLORS_RESERVATION, BORDER_COLORS_RESERVATION} from '../../colors.js';
    import {Pulse} from 'svelte-loading-spinners';
    import SnackbarNoDismiss from 'components/Snackbars/SnackbarNoDismiss.svelte';
    import {getText} from '../../language.svelte';
    import {navigate} from 'svelte-routing';
    import {selectedCampaign, selectedBookingRequest, inventoryChecks} from '../../store.js';
    import CardInventoryCheck from 'components/Cards/CardInventoryCheck.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let podcast;
    export let title;
    export let subtitle;
    export let inventoryReservations;
    export let inventoryBookings;

    export let rollPosition;
    // 1=Pre-roll, 2=Mid-1, 3=Mid-2, 4=Mid-3, 5=Post-roll, 6=group per roll

    Chart.register(annotationPlugin);

    let booking_volume = podcast.max_booking_volume;
    let dataThis = {};
    let liveData = {};
    let timerangeStart;
    let timerangeEnd;
    let timerangeDefault = [];
    let timerangeOffset = [];
    let unixStartTotalTimerange;
    let unixEndTotalTimerange;
    let minEndDate;
    let myChart;
    let offsetStartDate = 365; // days
    let offsetEndDate = 365; // days
    // let offsetStartDate = 12; // months
    // let offsetEndDate = 12; // months
    let inventoryReservationCache = [];
    let inventoryBookingCache = [];
    let colorCounter = 0;
    let usedColors = [];

    let snackbarNoDismissOpen = false;
    let snackbarNoDismissMsg;
    let snackbarNoDismissType;

    onMount(async () => {
        // 1. aktivate pulse
        document.getElementById(`loading-pulse-${podcast.id}`).style.display = 'flex';

        // 2. load api data
        let apiData = await prepInventoriesForApiCall();

        apiData.bookings.length > 0 ? inventoryBookingCache.push(await getInventoryApiData(apiData.bookings)) : '';
        apiData.reservations.length > 0 ? inventoryReservationCache.push(await getInventoryApiData(apiData.reservations)) : '';

        // 3. trigger prep methods based on received preference
        rollPosition == 6 ? await prepMultiInventory() : await prepSoloInventory();

        // 4. init & paint chart
        const config = {
            type: 'bar',
            data: liveData,
            options: {
                responsive: true,
                plugins: {
                    // title: {
                    //     display: true,
                    //     text: (ctx) => title
                    // },
                    tooltip: {
                        mode: 'index',
                        filter: function (tooltipItem) {
                            let value = myChart.data.datasets[tooltipItem.datasetIndex].data[tooltipItem.dataIndex];
                            return value > 0 ? tooltipItem.datasetIndex != 0 : '';
                        },
                    },
                    filler: {
                        propagate: false,
                    },
                    legend: {
                        display: true,
                        position: 'top',
                        align: 'center',
                        // labels: {
                        //     generateLabels: (chart) => {
                        //         var ul1 = document.createElement('ul');
                        //         var ul2 = document.createElement('ul');
                        //         var ul3 = document.createElement('ul');
                        //         chart.data.datasets.forEach((set) => {
                        //             if (set.label == 'Booking Volume') {
                        //                 ul1.innerHTML += `
                        //                 <li>
                        //                     <span style="background-color: ${set.backgroundColor}">&nbsp</span>
                        //                     ${set.label}
                        //                 </li>`;
                        //             }

                        //             if (set.label.includes('- Reservation')) {
                        //                 ul2.innerHTML += `
                        //                 <li>
                        //                     <span style="background-color: ${set.backgroundColor}">&nbsp</span>
                        //                     ${set.label}
                        //                 </li>`;
                        //             }

                        //             if (set.label.includes('- Booking')) {
                        //                 ul3.innerHTML += `
                        //                 <li>
                        //                     <span style="background-color: ${set.backgroundColor}">&nbsp</span>
                        //                     ${set.label}
                        //                 </li>`;
                        //             }
                        //         });
                        //         document.getElementById(`legend-${podcast.id}`).appendChild(ul1);
                        //         document.getElementById(`legend-${podcast.id}`).appendChild(ul2);
                        //         document.getElementById(`legend-${podcast.id}`).appendChild(ul3);
                        //         return;
                        //         // return document.getElementById(`legend-${podcast.id}`).appendChild(ul2);
                        //     },
                        // },
                    },
                    // annotation: {
                    //     annotations: [
                    //         {
                    //             drawTime: 'afterDatasetsDraw',
                    //             type: 'line',
                    //             mode: 'vertical',
                    //             scaleID: 'x-axis-0',
                    //             xMin: 'March',
                    //             xMax: 'March',
                    //             borderWidth: 5,
                    //             borderColor: 'red',
                    //             label: {
                    //                 content: 'TODAY',
                    //                 enabled: true,
                    //                 position: 'top',
                    //             },
                    //         },
                    //     ],
                    // },
                    // annotation: {
                    //     annotations: {
                    //         line1: {
                    //             type: 'line',
                    //             yMin: 20,
                    //             yMax: 20,
                    //             borderColor: 'rgb(255, 99, 132)',
                    //             borderWidth: 2,
                    //         },
                    //     },
                    // },
                },
                interaction: {
                    mode: 'nearest',
                    axis: 'x',
                    intersect: false,
                },
                scales: {
                    x: {
                        stacked: true,
                        type: 'time',
                        time: {
                            unit: 'day',
                        },
                        parsing: false,
                        alignToPixels: true,
                        title: {
                            display: true,
                            text: 'Time',
                        },
                    },
                    y: {
                        beginAtZero: true,
                        alignToPixels: true,
                        stacked: true,
                        title: {
                            display: true,
                            text: 'Impressions',
                        },
                        type: 'linear',
                        // ticks: {
                        //     max: 300,
                        //     min: -50,
                        //     stepSize: 5,
                        //     callback: function (value) {
                        //         return value;
                        //     },
                        // },
                    },
                },
                // register onclick events
                onClick: clickHandler,
            },
        };

        var ctx = document.getElementById(`line-chart-${podcast.id}`).getContext('2d');
        // window.myLine = new Chart(ctx, config);
        myChart = new Chart(ctx, config);

        // deaktivate pulse
        if (document.getElementById(`loading-pulse-${podcast.id}`)) {
            document.getElementById(`loading-pulse-${podcast.id}`).style.display = 'none';
        }

        updateInventoryViews();
    });

    function initChartDefaults(origin) {
        // setup default timerange
        var date = new Date();
        timerangeStart = new Date(date.getFullYear(), date.getMonth(), date.getDate()).setDate(1);
        timerangeEnd = new Date(timerangeStart).setMonth(date.getMonth() + 2);

        // working originals - don't change !!!
        // unixStartTotalTimerange = new Date(timerangeStart).setMonth(date.getMonth() - offsetStartDate);
        // unixEndTotalTimerange = new Date(timerangeStart).setMonth(date.getMonth() + offsetEndDate);

        unixStartTotalTimerange = new Date(subtractDays(date, offsetStartDate)).setDate(1);
        unixEndTotalTimerange = new Date(addDays(date, offsetEndDate)).setDate(1);

        // set minimum date of end date selector
        minEndDate = unixTimestampToDatePicker(new Date(timerangeStart).setHours(new Date(timerangeStart).getHours() + 24));

        // init chart data
        dataThis.datasets = [];
        dataThis.labels = [];

        // define bookingVolume dataset
        timerangeDefault = getUnixTimestampsDailyTimerange(timerangeStart, timerangeEnd);

        // TODO: mit Vinc drauf gucken wieso datenpunkte nicht gemalt werden wenn unixStartTotalTimerange statt timerangeStart
        // timerangeOffset = getUnixTimestampsDailyTimerange(unixStartTotalTimerange, unixEndTotalTimerange);
        timerangeOffset = getUnixTimestampsDailyTimerange(timerangeStart, unixEndTotalTimerange);

        if (origin == 'multi') {
            booking_volume = booking_volume * 5;
        }

        var bookingVolumeData = new Array(timerangeOffset.length).fill(booking_volume);

        let bookingVolume = {
            data: bookingVolumeData,
            label: 'Booking Volume',
            filterKeyType: 'Booking Volume',
            filterKeyPosition: 6,
            borderColor: 'rgb(0,0,0)',
            backgroundColor: 'rgb(0,0,0)',
            fill: false,
            pointRadius: 2,
            pointHoverRadius: 1,
            type: 'line',
            order: 1,
            // stack: 'stack1',
            // borderWidth: 1,
            // tension: 0.4,
        };

        // add booking volume line to chart data
        dataThis.datasets.push(bookingVolume);
    }

    // Prep exported data for detailed api call per roll position
    async function prepInventoriesForApiCall() {
        let inventoryReservationsForApi = [];
        let inventoryBookingsForApi = [];
        let rolls = [];

        rollPosition != 6 ? (rolls = [rollPosition]) : (rolls = [1, 2, 3, 4, 5]);

        for (let roll in rolls) {
            if (inventoryBookings) {
                for (let inventory of inventoryReservations) {
                    if (inventory.roll_position == rolls[roll]) {
                        inventoryReservationsForApi =
                            inventoryReservationsForApi + `[${inventory.id},${inventory.campaign_id},${inventory.booking_request_id},${inventory.roll_position},${inventory.podcast_id}];`;
                    }
                }
                for (let inventory of inventoryBookings) {
                    if (inventory.roll_position == rolls[roll]) {
                        inventoryBookingsForApi =
                            inventoryBookingsForApi + `[${inventory.id},${inventory.campaign_id},${inventory.booking_request_id},${inventory.roll_position},${inventory.podcast_id}];`;
                    }
                }
            }
        }

        // structure: [inventory_id,campaign_id,booking_request_id,roll_position,podcast_id]
        return {reservations: inventoryReservationsForApi, bookings: inventoryBookingsForApi};
    }

    // get inventory detailed data from api
    async function getInventoryApiData(inventoriesForApi) {
        return await getInventoryBatch(inventoriesForApi)
            .then((response) => response.json())
            .then((data) => {
                return data;
            });
    }

    // handle solo inventory (one roll position)
    async function prepSoloInventory() {
        initChartDefaults('solo');

        if (inventoryBookingCache.length > 0) {
            for (const entry in inventoryBookingCache[0]) {
                let inventory = inventoryBookingCache[0][entry];
                pushEntryToSoloRollChart(inventory, 'Booking', CHART_COLORS_BOOKING[colorCounter], CHART_COLORS_BOOKING[colorCounter]);
            }
            colorCounter = 0;
        }

        if (inventoryReservationCache.length > 0) {
            for (let entry in inventoryReservationCache[0]) {
                let inventory = inventoryReservationCache[0][entry];
                pushEntryToSoloRollChart(inventory, 'Reservation', CHART_COLORS_RESERVATION[colorCounter], CHART_COLORS_RESERVATION[colorCounter]);
            }
            colorCounter = 0;
        }

        // handle inventory checks
        if ($inventoryChecks.length > 0) {
            for (let entry in $inventoryChecks) {
                if ($inventoryChecks[entry].rollPosition == rollPosition && $inventoryChecks[entry].podcastId == podcast.id) {
                    let inventory = $inventoryChecks[entry];
                    pushEntryToSoloRollChart(inventory, 'Check', 'red', 'red');
                }
            }
        }

        dataThis.labels = timerangeDefault;
        dataThis.labels = [...new Set(dataThis.labels)];

        liveData = JSON.parse(JSON.stringify(dataThis));
    }

    function pushEntryToSoloRollChart(inventory, type, backgroundColor, borderColor) {
        if (type == 'Check') {
            let timerange = getUnixTimestampsDailyTimerange(Date.parse(inventory.startDate), Date.parse(inventory.endDate));

            let range = [];
            for (let overallDate of timerangeOffset) {
                timerange.includes(overallDate) ? range.push(inventory.requestedImpressions) : range.push(null);
            }

            let data = {
                id: inventory.id,
                data: range,
                label: inventory.title + ' - Check',
                filterKeyType: 'Check',
                filterKeyPosition: inventory.rollPosition,
                borderColor: 'red',
                backgroundColor: 'red',
                fill: true,
                pointRadius: 1,
                pointHoverRadius: 1,
                borderWidth: 1,
                inventory: 7,
                order: 2,
                barThickness: 25,
            };

            dataThis.datasets.push(data);
        } else {
            let timerange = getUnixTimestampsDailyTimerange(Date.parse(inventory.start_date), Date.parse(inventory.end_date));

            // for plain data points with indexing instead of timestamps
            // with time buffer use timerangeOffset
            // without time buffer use timerangeDefault
            let range = [];
            for (let overallDate of timerangeOffset) {
                timerange.includes(overallDate) ? range.push(inventory.offered_impressions == 0 ? inventory.requested_impressions : inventory.offered_impressions) : range.push(null);
            }

            // for (x,y) pairs of datapoints - doesn't work with timerange filter
            // for (let date of timerange) {
            //     range.push({x: date, y: impressions});
            //     // dataThis.labels.push(date);
            //     // timerangeDefault.push(date);
            // }

            if (colorCounter == CHART_COLORS_BOOKING.length) {
                colorCounter = 0;
            }

            let inventoryInformation = {campaign: inventory.campaign_id, bookingRequest: inventory.booking_request_id};

            let data = {
                id: inventory.id,
                data: range,
                label: inventory.title + ' - ' + type,
                filterKeyType: type,
                filterKeyPosition: inventory.roll_position,
                borderColor: borderColor,
                backgroundColor: backgroundColor,
                fill: true,
                // stack: stack,
                pointRadius: 1,
                pointHoverRadius: 1,
                borderWidth: 1,
                inventory: inventoryInformation,
                order: 2,
                barThickness: 25,
            };

            dataThis.datasets.push(data);
            colorCounter = colorCounter + 1;
            usedColors.push(colorCounter);
        }
    }

    // handle multi inventory (more than one roll position)
    async function prepMultiInventory() {
        initChartDefaults('multi');

        let preRollsBookings = [];
        let mid1Bookings = [];
        let mid2Bookings = [];
        let mid3Bookings = [];
        let postRollsBookings = [];

        let preRollsReservations = [];
        let mid1Reservations = [];
        let mid2Reservations = [];
        let mid3Reservations = [];
        let postRollsReservations = [];

        if (inventoryBookingCache.length > 0) {
            for (const entry in inventoryBookingCache[0]) {
                let inventory = inventoryBookingCache[0][entry];
                let timerange = getUnixTimestampsDailyTimerange(inventory.start_date, inventory.end_date);
                sortMultiRolls(inventory, timerange, preRollsBookings, mid1Bookings, mid2Bookings, mid3Bookings, postRollsBookings);
            }

            if (preRollsBookings.length > 0) {
                pushEntryToMultiRollChart('Pre-Rolls', 'Booking', preRollsBookings, 1, CHART_COLORS_BOOKING[CHART_COLORS_BOOKING.length - 1], CHART_COLORS_BOOKING[CHART_COLORS_BOOKING.length - 1]);
            }
            if (mid1Bookings.length > 0) {
                pushEntryToMultiRollChart('Mid-Rolls-1', 'Booking', mid1Bookings, 2, CHART_COLORS_BOOKING[CHART_COLORS_BOOKING.length - 2], CHART_COLORS_BOOKING[CHART_COLORS_BOOKING.length - 2]);
            }
            if (mid2Bookings.length > 0) {
                pushEntryToMultiRollChart('Mid-Rolls-2', 'Booking', mid2Bookings, 3, CHART_COLORS_BOOKING[CHART_COLORS_BOOKING.length - 3], CHART_COLORS_BOOKING[CHART_COLORS_BOOKING.length - 3]);
            }
            if (mid3Bookings.length > 0) {
                pushEntryToMultiRollChart('Mid-Rolls-3', 'Booking', mid3Bookings, 4, CHART_COLORS_BOOKING[CHART_COLORS_BOOKING.length - 4], CHART_COLORS_BOOKING[CHART_COLORS_BOOKING.length - 4]);
            }
            if (postRollsBookings.length > 0) {
                pushEntryToMultiRollChart('Post-Rolls', 'Booking', postRollsBookings, 5, CHART_COLORS_BOOKING[CHART_COLORS_BOOKING.length - 5], CHART_COLORS_BOOKING[CHART_COLORS_BOOKING.length - 5]);
            }
        }

        if (inventoryReservationCache.length > 0) {
            for (const entry in inventoryReservationCache[0]) {
                let inventory = inventoryReservationCache[0][entry];
                let timerange = getUnixTimestampsDailyTimerange(inventory.start_date, inventory.end_date);
                sortMultiRolls(inventory, timerange, preRollsReservations, mid1Reservations, mid2Reservations, mid3Reservations, postRollsReservations);
            }

            if (preRollsReservations.length > 0) {
                pushEntryToMultiRollChart(
                    'Pre-Rolls',
                    'Reservation',
                    preRollsReservations,
                    1,
                    CHART_COLORS_RESERVATION[CHART_COLORS_RESERVATION.length - 1],
                    BORDER_COLORS_RESERVATION[BORDER_COLORS_RESERVATION.length - 1],
                );
            }
            if (mid1Reservations.length > 0) {
                pushEntryToMultiRollChart(
                    'Mid-Rolls-1',
                    'Reservation',
                    mid1Reservations,
                    2,
                    CHART_COLORS_RESERVATION[CHART_COLORS_RESERVATION.length - 2],
                    BORDER_COLORS_RESERVATION[BORDER_COLORS_RESERVATION.length - 2],
                );
            }
            if (mid2Reservations.length > 0) {
                pushEntryToMultiRollChart(
                    'Mid-Rolls-2',
                    'Reservation',
                    mid2Reservations,
                    3,
                    CHART_COLORS_RESERVATION[CHART_COLORS_RESERVATION.length - 3],
                    BORDER_COLORS_RESERVATION[BORDER_COLORS_RESERVATION.length - 3],
                );
            }
            if (mid3Reservations.length > 0) {
                pushEntryToMultiRollChart(
                    'Mid-Rolls-3',
                    'Reservation',
                    mid3Reservations,
                    4,
                    CHART_COLORS_RESERVATION[CHART_COLORS_RESERVATION.length - 4],
                    BORDER_COLORS_RESERVATION[BORDER_COLORS_RESERVATION.length - 4],
                );
            }
            if (postRollsReservations.length > 0) {
                pushEntryToMultiRollChart(
                    'Post-Rolls',
                    'Reservation',
                    postRollsReservations,
                    5,
                    CHART_COLORS_RESERVATION[CHART_COLORS_RESERVATION.length - 5],
                    BORDER_COLORS_RESERVATION[BORDER_COLORS_RESERVATION.length - 5],
                );
            }
        }

        dataThis.labels = timerangeDefault;
        dataThis.labels = [...new Set(dataThis.labels)];

        liveData = JSON.parse(JSON.stringify(dataThis));
    }

    function sortMultiRolls(inventory, timerange, preRolls, mid1, mid2, mid3, postRolls) {
        for (let index in timerangeOffset) {
            // TODO for debuggering only!
            // if (timerange.includes(timerangeOffset[index])) {
            //     debugger;
            // }

            // init
            if (inventory.roll_position == 1 && preRolls.length == 0) {
                timerange.includes(timerangeOffset[index]) ? preRolls.push(inventory.offered_impressions == 0 ? inventory.requested_impressions : inventory.offered_impressions) : preRolls.push(null);
            }
            // add up
            if (inventory.roll_position == 1 && preRolls.length > 0) {
                if (timerange.includes(timerangeOffset[index])) {
                    let impressions = inventory.offered_impressions == 0 ? inventory.requested_impressions : inventory.offered_impressions;
                    preRolls[index] ? preRolls[index] + impressions : (preRolls[index] = impressions);
                }
            }

            if (inventory.roll_position == 2) {
                timerange.includes(timerangeOffset[index]) ? mid1.push(inventory.offered_impressions == 0 ? inventory.requested_impressions : inventory.offered_impressions) : mid1.push(null);
            }
            if (inventory.roll_position == 2 && mid1.length > 0) {
                if (timerange.includes(timerangeOffset[index])) {
                    let impressions = inventory.offered_impressions == 0 ? inventory.requested_impressions : inventory.offered_impressions;
                    if (mid1[index]) {
                        mid1[index] != impressions ? (mid1[index] = mid1[index] + impressions) : '';
                    } else mid1[index] = impressions;
                }
            }

            if (inventory.roll_position == 3) {
                timerange.includes(timerangeOffset[index]) ? mid2.push(inventory.offered_impressions == 0 ? inventory.requested_impressions : inventory.offered_impressions) : mid2.push(null);
            }
            if (inventory.roll_position == 3 && mid2.length > 0) {
                if (timerange.includes(timerangeOffset[index])) {
                    let impressions = inventory.offered_impressions == 0 ? inventory.requested_impressions : inventory.offered_impressions;
                    mid2[index] ? mid2[index] + impressions : (mid2[index] = impressions);
                }
            }

            if (inventory.roll_position == 4) {
                timerange.includes(timerangeOffset[index]) ? mid3.push(inventory.offered_impressions == 0 ? inventory.requested_impressions : inventory.offered_impressions) : mid3.push(null);
            }
            if (inventory.roll_position == 4 && mid3.length > 0) {
                if (timerange.includes(timerangeOffset[index])) {
                    let impressions = inventory.offered_impressions == 0 ? inventory.requested_impressions : inventory.offered_impressions;
                    mid3[index] ? mid3[index] + impressions : (mid3[index] = impressions);
                }
            }

            if (inventory.roll_position == 5) {
                timerange.includes(timerangeOffset[index])
                    ? postRolls.push(inventory.offered_impressions == 0 ? inventory.requested_impressions : inventory.offered_impressions)
                    : postRolls.push(null);
            }
            if (inventory.roll_position == 5 && postRolls.length > 0) {
                if (timerange.includes(timerangeOffset[index])) {
                    let impressions = inventory.offered_impressions == 0 ? inventory.requested_impressions : inventory.offered_impressions;
                    postRolls[index] ? preRolls[index] + impressions : (postRolls[index] = impressions);
                }
            }
        }
    }

    function pushEntryToMultiRollChart(title, type, dataPoints, roll, backgroundColor, borderColor) {
        if (colorCounter == CHART_COLORS_BOOKING.length) {
            colorCounter = 0;
        }

        let data = {
            data: dataPoints,
            label: title + ' - ' + type,
            filterKeyType: type,
            filterKeyPosition: roll,
            borderColor: borderColor,
            backgroundColor: backgroundColor,
            fill: true,
            // stack: stack,
            pointRadius: 1,
            pointHoverRadius: 1,
            borderWidth: 1,
            inventory: roll, // TODO
            order: 2,
            barThickness: 25,
        };

        dataThis.datasets.push(data);
        colorCounter = colorCounter + 1;
        usedColors.push(colorCounter);
    }

    // inventory CHECK handling
    function newInventoryCheck() {
        selected = {};
        updating = false;
        showInventoryCheck = true;
    }

    // TODO remove default values for prod
    // let titleCheck = 'check 1';
    // let startDateCheck = databaseToDatePickerDate(getDateMidnight(-20160));
    // let endDateCheck = databaseToDatePickerDate(getDateMidnight(-30160));
    // let requestedImpressionsCheck = 10000000;
    // let preRollCheck = false;
    // let mid1Check = false;
    // let mid2Check = false;
    // let mid3Check = false;
    // let postRollCheck = false;
    let showInventoryCheck = false;
    let updating = false;
    let selected = {};

    // let inventoryChecks = [];
    $: $inventoryChecks, updateInventoryViews();

    function updateInventoryViews() {
        if ($inventoryChecks.length > 0 && liveData.datasets) {
            // removeAllInventoryChecksFromGraph();
            for (let check of $inventoryChecks) {
                if (!parentFunction_checkExists(check.title) && podcast.id == check.podcastId && (check.rollPosition == rollPosition || rollPosition == 6)) {
                    let timerange = getUnixTimestampsDailyTimerange(Date.parse(check.startDate), Date.parse(check.endDate));
                    let range = [];
                    for (let overallDate of timerangeOffset) {
                        // timerange.includes(overallDate) ? range.push(check.offered_impressions == 0 ? inventory.requested_impressions : inventory.offered_impressions) : range.push(null);
                        timerange.includes(overallDate) ? range.push(check.requestedImpressions) : range.push(null);
                    }

                    let data = {
                        id: check.id,
                        data: range,
                        label: check.title + ' - Check',
                        filterKeyType: 'Check',
                        filterKeyPosition: check.rollPosition,
                        borderColor: 'red',
                        backgroundColor: 'red',
                        // borderColor: borderColor,
                        // backgroundColor: backgroundColor,
                        fill: true,
                        // stack: stack,
                        pointRadius: 1,
                        pointHoverRadius: 1,
                        borderWidth: 1,
                        inventory: 7,
                        order: 2,
                        barThickness: 25,
                    };

                    liveData.datasets.push(data);
                    myChart.update();
                }
            }
        }
    }

    // checks if check object with same title already exists
    function parentFunction_checkExists(title) {
        let exists = false;
        if (liveData.datasets) {
            liveData.datasets.forEach((set) => {
                // if (set.label == titleCheck + ' - Check') {
                if (set.label == title + ' - Check') {
                    exists = true;
                }
            });
        }
        return exists;
    }

    function parentFunction_deleteInventoryCheck(obj) {
        // remove from storage
        $inventoryChecks.forEach((check, index) => {
            check.id == obj.id ? $inventoryChecks.splice(index) : '';
        });
        // remove from graph
        myChart.data.datasets.forEach((check, index) => {
            check.id == obj.id ? myChart.data.datasets.splice(index, 1) : '';
        });
        myChart.update();
    }

    function parentFunction_updateGraph() {
        myChart.update();
    }

    // FILTER OPTIONS
    function filterChart(event) {
        console.log(event.srcElement.value);
    }

    function filterDate() {
        // transform labels
        let startUnix = new Date(document.getElementById(`grid-timerange-start-${podcast.id}`).value).setHours(0, 0, 0, 0);
        let endUnix = new Date(document.getElementById(`grid-timerange-end-${podcast.id}`).value).setHours(0, 0, 0, 0);
        let filteredLabels = timerangeOffset.filter((date) => date >= startUnix && date <= endUnix);
        myChart.config.data.labels = filteredLabels;

        // update minimum date of end date selector
        minEndDate = unixTimestampToDatePicker(new Date(startUnix).setHours(new Date(startUnix).getHours() + 24));

        if (endUnix < startUnix) {
            // endUnix = startUnix;
            endUnix = new Date(startUnix).setHours(new Date(startUnix).getHours() + 72);
            document.getElementById(`grid-timerange-end-${podcast.id}`).value = unixTimestampToDatePicker(endUnix);
            filteredLabels = timerangeOffset.filter((date) => date >= startUnix && date <= endUnix);
            myChart.config.data.labels = filteredLabels;
        }

        // transform data to new timeline labels
        let startIndex = timerangeOffset.indexOf(filteredLabels[0]);
        let endIndex = timerangeOffset.indexOf(filteredLabels[filteredLabels.length - 1]);
        let datasets = [...dataThis.datasets];

        for (let index in datasets) {
            let data = [...datasets[index].data];
            data.splice(endIndex + 1, filteredLabels.length);
            data.splice(0, startIndex);
            myChart.config.data.datasets[index].data = data;

            // timerange > original max
            if (endUnix > unixEndTotalTimerange) {
                fireSnackbarNoDismiss(getText('ui_messages.snackbars.podcast.snackbar_inventory_future_dates'), 'info');
            }
        }

        // update chart
        myChart.update();
    }

    // HANDLER
    async function clickHandler(event, elements, chart) {
        const points = myChart.getElementsAtEventForMode(event, 'nearest', {axis: 'x', intersect: true}, true);

        if (points[0] && myChart.data.datasets[points[0].datasetIndex].label !== 'Booking Volume') {
            // console.log('points length: ', points.length);

            const dataset = points[0].datasetIndex;
            const index = points[0].index;

            // console.log(`clicked item: ${myChart.data.datasets[dataset].label} with index number of: ${index}`);

            let inventory = myChart.data.datasets[dataset].inventory;
            // console.log(`BR: ${myChart.data.datasets[dataset].inventory.bookingRequest}`);

            // set values for detail view
            // TODO check if this can be loaded in the detail view, or better be given via export variable
            if (rollPosition != 6 && inventory != 7) {
                selectedCampaign.set(await getCampaignByIdCached(inventory.campaign));
                selectedBookingRequest.set(await getBookingRequestByIdCached(inventory.bookingRequest));

                navigate(`/podcast/campaign/${inventory.campaign}/booking-request/${inventory.bookingRequest}`, {replace: false});
            } else if (inventory == 7) {
                // get object from local storage
                $inventoryChecks.forEach((check) => {
                    check.id == myChart.data.datasets[dataset].id ? (selected = check) : '';
                });
                // set updating for pop up view options
                updating = true;
                // pop up view
                showInventoryCheck = true;
            } else {
                inventory == 1 ? navigate(`/podcast/inventory/pre-roll`, {replace: false}) : '';
                inventory == 2 ? navigate(`/podcast/inventory/mid-roll-1`, {replace: false}) : '';
                inventory == 3 ? navigate(`/podcast/inventory/mid-roll-2`, {replace: false}) : '';
                inventory == 4 ? navigate(`/podcast/inventory/mid-roll-3`, {replace: false}) : '';
                inventory == 5 ? navigate(`/podcast/inventory/post-roll`, {replace: false}) : '';
            }

            // for crossing lines
            // points.forEach((line) => {
            //     const dataset = line.datasetIndex;
            //     const index = line.index;
            //     console.log(`clicked line: ${myChart.data.datasets[dataset].label} with index number of: ${index}`);
            //     console.log(`inventory: ${myChart.data.datasets[dataset].inventory}`);
            //     navigate(`/podcast/campaign/${inventory.campaign_id}/booking-request/${inventory.bookingRequest}`, {replace: false});
            // });
        }
    }

    function checkboxHandler(event) {
        let filter = event.target.value.toLowerCase();
        for (const index in myChart.data.datasets) {
            // filterKeyPosition: 6;
            // filterKeyType: 'Booking Volume';
            if (filter == 'booking' && myChart.data.datasets[index].filterKeyType.toLowerCase() == filter) {
                myChart.isDatasetVisible(index) ? myChart.hide(index) : myChart.show(index);
            }
            if (filter == 'reservation' && myChart.data.datasets[index].filterKeyType.toLowerCase() == filter) {
                myChart.isDatasetVisible(index) ? myChart.hide(index) : myChart.show(index);
            }
            if (filter == 'preRoll' && myChart.data.datasets[index].filterKeyPosition == 1) {
                myChart.isDatasetVisible(index) ? myChart.hide(index) : myChart.show(index);
            }
            if (filter == 'mid-1' && myChart.data.datasets[index].filterKeyPosition == 2) {
                myChart.isDatasetVisible(index) ? myChart.hide(index) : myChart.show(index);
            }
        }
    }

    function fireSnackbarNoDismiss(msg, type) {
        snackbarNoDismissMsg = msg;
        snackbarNoDismissType = type;
        snackbarNoDismissOpen = true;
    }

    // HELPER
    function subtractDays(date, days) {
        let dateCopy = new Date(date);
        dateCopy.setDate(dateCopy.getDate() - days);
        return dateCopy.setHours(0, 0, 0, 0);
    }

    function addDays(date, days) {
        const dateCopy = new Date(date);
        dateCopy.setDate(dateCopy.getDate() + days);
        return dateCopy.setHours(0, 0, 0, 0);
    }

    var randomProperty = function (obj) {
        var keys = Object.keys(obj);
        return obj[keys[(keys.length * Math.random()) << 0]];
    };

    function getRandomColor() {
        return CHART_COLORS_BOOKING[Object.keys(CHART_COLORS_BOOKING)[Math.floor(Math.random() * Object.keys(CHART_COLORS_BOOKING).length)]];
    }

    function getMousePos(canvas, evt) {
        var rect = canvas.getBoundingClientRect();
        return {
            x: evt.x - rect.left,
            y: evt.y - rect.top,
        };
        var rect = canvas.getBoundingClientRect();
        return {
            x: evt.x - rect.left,
            y: evt.y - rect.top,
        };
    }
</script>

{#if snackbarNoDismissOpen}
    <SnackbarNoDismiss {snackbarNoDismissMsg} bind:snackbarNoDismissOpen {snackbarNoDismissType} timeout="4000" />
{/if}

{#if showInventoryCheck}
    <CardInventoryCheck
        bind:showInventoryCheck
        bind:updating
        bind:selected
        {podcast}
        parentFunction_deleteInventoryCheck={(obj) => parentFunction_deleteInventoryCheck(obj)}
        parentFunction_checkExists={(title) => parentFunction_checkExists(title)}
        fireSnackbarNoDismiss={(msg, type) => fireSnackbarNoDismiss(msg, type)}
    />
{/if}

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-200">
    <div class="rounded-t mb-0 py-3 bg-transparent">
        <div class="flex flex-wrap items-center">
            <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-400 text-xs font-bold mb-2" for="grid-timerange-start-{podcast.id}"> {getText('podcast.inventory.filter_bar.start')} </label>
                    <!-- TODO: set min after fix to min={unixTimestampToDatePicker(unixStartTotalTimerange)} -->
                    <input
                        id="grid-timerange-start-{podcast.id}"
                        type="date"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={unixTimestampToDatePicker(timerangeStart)}
                        min={unixTimestampToDatePicker(timerangeStart)}
                        on:change={filterDate}
                    />
                </div>
            </div>
            <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-400 text-xs font-bold mb-2" for="grid-timerange-end-{podcast.id}"> {getText('podcast.inventory.filter_bar.end')} </label>
                    <input
                        id="grid-timerange-end-{podcast.id}"
                        type="date"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={unixTimestampToDatePicker(timerangeEnd)}
                        min={minEndDate}
                        max={unixTimestampToDatePicker(unixEndTotalTimerange)}
                        on:change={filterDate}
                    />
                </div>
            </div>

            <div class="w-full lg:w-3/12 px-4">
                <label for="grid-checkbox-filter-bookings-{podcast.id}" class="block uppercase text-blueGray-400 text-xs font-bold mb-2">
                    <input
                        value="booking"
                        id="grid-checkbox-filter-bookings-{podcast.id}"
                        type="checkbox"
                        class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                        on:click={(event) => {
                            checkboxHandler(event);
                        }}
                        checked
                    />
                    {getText('podcast.inventory.filter_bar.bookings')}
                </label>
                <label for="grid-checkbox-filter-reservations-{podcast.id}" class="block uppercase text-blueGray-400 text-xs font-bold mb-2">
                    <input
                        value="reservation"
                        id="grid-checkbox-filter-reservations-{podcast.id}"
                        type="checkbox"
                        class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                        on:click={(event) => {
                            checkboxHandler(event);
                        }}
                        checked
                    />
                    {getText('podcast.inventory.filter_bar.reservations')}
                </label>
            </div>
            <div class="w-full lg:w-3/12 px-4">
                <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-400 text-xs font-bold mb-2" for="grid-timerange-end-{podcast.id}"> {getText('podcast.inventory.filter_bar.inventory_check')} </label>
                    <CardButton id="new-reservation" type="default" text="+" on:click={() => newInventoryCheck()} />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-200">
    <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div class="flex flex-wrap items-center">
            <div class="relative w-full max-w-full flex-grow flex-1">
                <h6 class="uppercase text-blueGray-700 text-xs font-semibold">{subtitle}</h6>
                <!-- <h2 class="text-blueGray-700 text-xl font-semibold">{subtitle}</h2> -->
            </div>
        </div>
    </div>
    <!-- <div class="p-4 flex-auto">
        <div class="relative h-full"> -->
    <!-- <select
        on:change={(e) => {
            filterChart(e);
        }}
    >
        <option value="0">--- select timerange ---</option>
        <option value="1">1 Month</option>
        <option value="3">3 Months</option>
        <option value="12">12 Months</option>
    </select> -->
    <div id="loading-pulse-{podcast.id}" style="display: none; justify-content: center; align-items: center; margin-right: 1em;">
        <Pulse size="60" color="#FF3E00" unit="px" duration="1s" />
    </div>
    <div class="legend" id="legend-{podcast.id}" />
    <canvas id="line-chart-{podcast.id}" />
    <!-- </div>
    </div> -->
</div>

<!-- <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-700">
    <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div class="flex flex-wrap items-center">
            <div class="relative w-full max-w-full flex-grow flex-1">
                <h6 class="uppercase text-blueGray-100 mb-1 text-xs font-semibold">{title}</h6>
                <h2 class="text-white text-xl font-semibold">{subtitle}</h2>
            </div>
        </div>
    </div>
    <div class="p-4 flex-auto">
        <div class="relative h-350-px">
            <canvas id="line-chart-{podcast.id}" />
        </div>
    </div>
</div> -->

<!-- <style>
    #legend ul {
        list-style: none;
        font: 12px Verdana;
        white-space: nowrap;
    }

    #legend li span {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        width: 50px;
        height: 25px;

        border-radius: 5px;
        color: #fff;
        font-size: 15px;
        font-weight: bold;
        border: 2px solid #fff;
    }
</style> -->
