<script>
    import {databaseToPrettyDateTime} from '../../functions.js';
    import {getText} from '../../language.svelte';
    import {Pulse} from 'svelte-loading-spinners';
    import CardButton from 'components/Cards/CardButton.svelte';
    import CardBadgeTag from 'components/Cards/CardBadgeTag.svelte';
    export let bookingRequest;
    export let file;
    export let showUpdateButton;
    export let submitForm;
    export let copyToClipboard;
    export let downloadFromUrl;
    export let backgroundColor = 'bg-blueGray-200';
</script>

{#if bookingRequest.state >= 10 && bookingRequest.state != 14}
    <form>
        <div class="{backgroundColor} flex-auto mt-1 pt-1 px-4 lg:px-10 py-10 pt-0">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {getText('podcast.booking_request_details.report.title')}
            </h6>

            <div class="flex flex-wrap">
                <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                        {#if bookingRequest.state == 10}
                            <div class="flex flex-wrap">
                                <form>
                                    <input
                                        id="grid-audio-upload"
                                        class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        type="file"
                                        bind:files={file}
                                        on:change={() => {
                                            showUpdateButton = true;
                                        }}
                                    />
                                    {#if file && showUpdateButton}
                                        <div id="upload-pulse" style="display: none; justify-content: center; align-items: center; margin-right: 1em;">
                                            <Pulse size="60" color="#FF3E00" unit="px" duration="1s" />
                                        </div>

                                        <CardButton id="upload-report" type="go" text={getText('podcast.booking_request_details.report.button_upload')} on:click={() => submitForm('report')} />
                                    {/if}
                                </form>
                            </div>
                        {/if}
                        {#if bookingRequest.state == 11}
                            <div class="flex flex-wrap">
                                <form>
                                    <input
                                        id="grid-audio-upload"
                                        class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        type="file"
                                        bind:files={file}
                                        on:change={() => {
                                            showUpdateButton = true;
                                        }}
                                    />
                                    {#if bookingRequest.report && showUpdateButton}
                                        <div id="upload-pulse" style="display: none; justify-content: center; align-items: center; margin-right: 1em;">
                                            <Pulse size="60" color="#FF3E00" unit="px" duration="1s" />
                                        </div>
                                        <CardButton id="update-report" type="go" text={getText('podcast.booking_request_details.report.button_update')} on:click={() => submitForm('report')} />
                                    {/if}
                                    {#if bookingRequest.report}
                                        <p
                                            id="grid-report-file"
                                            class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        >
                                            {getText('podcast.booking_request_details.report.label_1')} <a class="underline" target="_blank" href={bookingRequest.report}>{bookingRequest.report}</a>
                                            <i
                                                class="fas fa-copy active:color-emerald-600"
                                                on:click={() => {
                                                    copyToClipboard(bookingRequest.report);
                                                }}
                                            />
                                            <i
                                                class="fas fa-download active:color-emerald-600"
                                                on:click={() => {
                                                    downloadFromUrl(bookingRequest.report, bookingRequest.report_name_original);
                                                }}
                                            />
                                        </p>
                                        <div class="flex flex-wrap">
                                            <div
                                                class="lg:w-12/12 border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            >
                                                {#if !bookingRequest.report_approved_date}
                                                    <CardBadgeTag text={getText('badges.report_status.entry_2', databaseToPrettyDateTime(bookingRequest.report_uploaded_date))} />
                                                    <CardBadgeTag text="{getText('badges.general.entry_1')}{bookingRequest.report_editor_user}" />
                                                {:else if bookingRequest.report_approved_date}
                                                    <CardBadgeTag text={getText('badges.report_status.entry_1', databaseToPrettyDateTime(bookingRequest.report_approved_date))} />
                                                    <CardBadgeTag text="{getText('badges.general.entry_1')}{bookingRequest.report_editor_user}" />
                                                {/if}
                                            </div>
                                        </div>
                                    {/if}
                                </form>
                            </div>
                        {/if}
                        {#if bookingRequest.state == 12}
                            <div class="flex flex-wrap">
                                <form>
                                    <p
                                        id="grid-report-file"
                                        class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    >
                                        {getText('podcast.booking_request_details.report.label_3')}
                                        {bookingRequest.report_name_original} /
                                        <a class="underline" target="_blank" href={bookingRequest.report}>{bookingRequest.report}</a>
                                        <i
                                            class="fas fa-copy active:color-emerald-600"
                                            on:click={() => {
                                                copyToClipboard(bookingRequest.report);
                                            }}
                                        />
                                        <i
                                            class="fas fa-download active:color-emerald-600"
                                            on:click={() => {
                                                downloadFromUrl(bookingRequest.report, bookingRequest.report_name_original);
                                            }}
                                        />
                                    </p>
                                    <div class="flex flex-wrap">
                                        <div
                                            class="lg:w-12/12 border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        >
                                            {#if !bookingRequest.report_approved_date}
                                                <CardBadgeTag text={getText('badges.report_status.entry_2', databaseToPrettyDateTime(bookingRequest.report_uploaded_date))} />
                                                <CardBadgeTag text="{getText('badges.general.entry_1')}{bookingRequest.report_editor_user}" />
                                            {:else if bookingRequest.report_approved_date}
                                                <CardBadgeTag text={getText('badges.report_status.entry_1', databaseToPrettyDateTime(bookingRequest.report_approved_date))} />
                                                <CardBadgeTag text="{getText('badges.general.entry_1')}{bookingRequest.report_editor_user}" />
                                            {/if}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        {/if}
                    </div>
                </div>
            </div>
        </div>
    </form>
{/if}
