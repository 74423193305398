<script>
    import {patchUserById, deleteUserById} from '../../api/api.js';
    import SnackbarNoDismiss from 'components/Snackbars/SnackbarNoDismiss.svelte';
    import {getText} from '../../language.svelte';
    import {validateEmail} from '../../functions.js';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let item;
    let newName = item.first_name;
    let newEmail = item.email;
    let snackbarNoDismissOpen = false;
    let snackbarNoDismissMsg;
    let snackbarNoDismissType;

    function editUser() {
        document.getElementById(`grid-button-edit-${item.id}`).style.display = 'none';
        document.getElementById(`grid-user-name-original-${item.id}`).style.display = 'none';
        document.getElementById(`grid-user-email-original-${item.id}`).style.display = 'none';
        document.getElementById(`grid-button-save-${item.id}`).style.display = 'flex';
        document.getElementById(`grid-user-name-${item.id}`).style.display = 'flex';
        document.getElementById(`grid-user-email-${item.id}`).style.display = 'flex';
    }

    function displayOriginals() {
        document.getElementById(`grid-button-edit-${item.id}`).style.display = 'flex';
        document.getElementById(`grid-user-name-original-${item.id}`).style.display = 'flex';
        document.getElementById(`grid-user-email-original-${item.id}`).style.display = 'flex';
        document.getElementById(`grid-button-save-${item.id}`).style.display = 'none';
        document.getElementById(`grid-user-name-${item.id}`).style.display = 'none';
        document.getElementById(`grid-user-email-${item.id}`).style.display = 'none';
    }

    async function saveUser() {
        let save = true;

        // check on same as original
        if (newName == item.first_name && newEmail == item.email) {
            displayOriginals();
            save = false;
            return;
        }

        // check on not empty
        if (newName == '') {
            fireSnackbarNoDismiss(getText('ui_messages.snackbars.admin.snackbar_name_empty'), 'warning');
            return;
        }

        if (newEmail == '') {
            fireSnackbarNoDismiss(getText('ui_messages.snackbars.admin.snackbar_email_empty'), 'warning');
            return;
        }

        // validate email
        let valid = await validateEmail(newEmail);
        if (!valid) {
            fireSnackbarNoDismiss(getText('ui_messages.snackbars.admin.snackbar_email_format'), 'warning');
            save = false;
            return;
        }

        // check on uniqueness
        if (newEmail !== item.email) {
            let all = document.querySelectorAll('[id^=grid-user-email-original-]');
            all.forEach((ele) => {
                if (ele.innerHTML == newEmail) {
                    fireSnackbarNoDismiss(getText('ui_messages.snackbars.admin.snackbar_user_exists'), 'warning');
                    save = false;
                    return;
                }
            });
        }

        if (save) {
            let data = {
                first_name: newName,
                email: newEmail,
            };

            try {
                let updated = await patchUserById(item.id, data);
                item.first_name = updated.first_name;
                item.email = updated.email;

                // set styles
                document.getElementById(`grid-user-name-original-${item.id}`).innerHTML = updated.first_name;
                document.getElementById(`grid-user-email-original-${item.id}`).innerHTML = updated.email;
                displayOriginals();
            } catch (e) {
                console.log(`Something went wrong updating user ${item.first_name}`);
            }
        }
    }

    async function deleteUser() {
        let answer = window.confirm(`Are you sure you want to delete the user "${item.first_name}" ?`);

        if (answer) {
            try {
                await deleteUserById(item.id);
                document.getElementById(`grid-user-${item.id}`).remove();
            } catch (e) {
                console.log(`Something went wrong deleting user ${item.first_name}`);
            }
        } else {
            return;
        }
    }

    function fireSnackbarNoDismiss(msg, type) {
        snackbarNoDismissMsg = msg;
        snackbarNoDismissType = type;
        snackbarNoDismissOpen = true;
    }
</script>

<svelte:window
    on:keydown={(e) => {
        if (e.code == 'Escape') displayOriginals();
        if (e.code == 'Enter') {
            saveUser();
        }
    }}
/>

{#if snackbarNoDismissOpen}
    <SnackbarNoDismiss {snackbarNoDismissMsg} bind:snackbarNoDismissOpen {snackbarNoDismissType} timeout="4000" />
{/if}

<tr id="grid-user-{item.id}">
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        <CardButton id="edit-{item.id}" type="default" icon="fas fa-pen text-sm" on:click={editUser} />
        <CardButton id="save-{item.id}" classes="hidden" type="go" icon="fas fa-save text-sm" on:click={saveUser} />
    </td>
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        <input
            id="grid-user-name-{item.id}"
            type="text"
            class="hidden border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            placeholder={item.first_name}
            bind:value={newName}
        />
        <div id="grid-user-name-original-{item.id}">
            {item.first_name}
        </div>
    </td>
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        <input
            id="grid-user-email-{item.id}"
            type="text"
            class="hidden border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            placeholder={item.email}
            bind:value={newEmail}
        />
        <div id="grid-user-email-original-{item.id}">
            {item.email}
        </div>
    </td>
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        {item.company_id}
    </td>
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {item.id} </td>
    {#if item.is_superuser}
        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> superuser </td>
    {:else}
        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> editor </td>
    {/if}
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        <CardButton id="delete-{item.id}" type="cancel" icon="fas fa-trash text-sm" on:click={deleteUser} />
    </td>
</tr>
