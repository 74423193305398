<script>
    import {onMount} from 'svelte';
    import {
        BUTTON_BACKGROUND_GO,
        BUTTON_ACTIVE_GO,
        BUTTON_BORDER_GO,
        BUTTON_BACKGROUND_GO_BLUE,
        BUTTON_ACTIVE_GO_BLUE,
        BUTTON_BORDER_GO_BLUE,
        BUTTON_BACKGROUND_CHANGE,
        BUTTON_ACTIVE_CHANGE,
        BUTTON_BORDER_CHANGE,
        BUTTON_BACKGROUND_CANCEL,
        BUTTON_ACTIVE_CANCEL,
        BUTTON_BORDER_CANCEL,
        BUTTON_BACKGROUND_DEFAULT,
        BUTTON_ACTIVE_DEFAULT,
        BUTTON_BORDER_COLOR_DEFAULT,
        BUTTON_TEXT_COLOR_DEFAULT,
    } from '../../colors.js';

    export let type = '';
    export let buttonType = 'button';
    export let id = '';
    export let classes = '';
    export let backgroundColor = BUTTON_BACKGROUND_DEFAULT;
    export let activeColor = BUTTON_ACTIVE_DEFAULT;
    export let borderColor = BUTTON_BORDER_COLOR_DEFAULT;
    export let textColor = BUTTON_TEXT_COLOR_DEFAULT;
    export let text;
    export let title = '';
    export let icon = '';
    export let disabled = false;

    export let classList = '';

    onMount(() => {
        checkType();
    });

    function checkType() {
        if (type == 'go') {
            backgroundColor = BUTTON_BACKGROUND_GO;
            activeColor = BUTTON_ACTIVE_GO;
            borderColor = BUTTON_BORDER_GO;
            classList = `${backgroundColor} ${textColor} ${borderColor} active:${activeColor} font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`;
        } else if (type == 'go-blue') {
            backgroundColor = BUTTON_BACKGROUND_GO_BLUE;
            activeColor = BUTTON_ACTIVE_GO_BLUE;
            borderColor = BUTTON_BORDER_GO_BLUE;
            classList = `${backgroundColor} ${textColor} ${borderColor} active:${activeColor} font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`;
        } else if (type == 'change') {
            backgroundColor = BUTTON_BACKGROUND_CHANGE;
            activeColor = BUTTON_ACTIVE_CHANGE;
            borderColor = BUTTON_BORDER_CHANGE;
            classList = `${backgroundColor} ${textColor} ${borderColor} active:${activeColor} font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`;
        } else if (type == 'cancel') {
            backgroundColor = BUTTON_BACKGROUND_CANCEL;
            activeColor = BUTTON_ACTIVE_CANCEL;
            borderColor = BUTTON_BORDER_CANCEL;
            classList = `${backgroundColor} ${textColor} ${borderColor} active:${activeColor} font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`;
        } else if (type == 'icon') {
            backgroundColor = '';
            activeColor = '';
            borderColor = '';
            classList = 'ml-2 text-xs font-bold uppercase py-1 rounded outline-none focus:outline-none mr-1 ease-linear transition-all duration-150';
            // text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150
            // text-xs font-bold uppercase py-1 rounded outline-none focus:outline-none mr-1 ease-linear transition-all duration-150
        } else if (type == 'custom') {
            backgroundColor = '';
            activeColor = '';
            borderColor = '';
            classList = classList;
        } else if (type == 'default') {
            backgroundColor = BUTTON_BACKGROUND_DEFAULT;
            activeColor = BUTTON_ACTIVE_DEFAULT;
            borderColor = BUTTON_BORDER_COLOR_DEFAULT;
            textColor = BUTTON_TEXT_COLOR_DEFAULT;
            classList = `${backgroundColor} ${textColor} ${borderColor} active:${activeColor} font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`;
        } else {
            backgroundColor = backgroundColor;
            activeColor = activeColor;
            borderColor = borderColor;
            textColor = textColor;
            classList = `${backgroundColor} ${textColor} ${borderColor} active:${activeColor} font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`;
        }
    }
    // checkType();
</script>

<button class="{classes.length > 0 ? classes.split(' ') : ''} {classList}" type={buttonType} id="grid-button-{id}" {title} {disabled} on:click>
    {#if icon}
        <i class={icon} />
    {/if}

    {#if text}
        {text}
    {/if}
</button>
<!-- <button
    class="{classes.length > 0
        ? classes.split(' ')
        : ''} {backgroundColor} {textColor} {borderColor} active:{activeColor}  font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
    type="button"
    id="grid-button-{id}"
    {disabled}
    on:click={parentFunction(params.length > 0 ? params : '')}
>
    {#if icon}
        <i class={icon} />
    {/if}

    {#if text}
        {text}
    {/if}
</button> -->

<!-- <button on:click on:mouseover on:mouseenter on:mouseleave {...buttonProps}>
    <slot />
</button> -->
