<script>
    import {getText} from '../../language.svelte';
    export let bookingRequest;
    export let campaign;
    export let copyToClipboard;
    export let backgroundColor = 'bg-blueGray-200';
</script>

{#if ((bookingRequest.state == 8 && bookingRequest.deal_id) || bookingRequest.state >= 9) && bookingRequest.state != 14}
    <form>
        <div class="{backgroundColor} flex-auto mt-1 pt-1 px-4 lg:px-10 py-10 pt-0">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {getText('podcast.booking_request_details.deal_info.title')}
            </h6>
            <div class="flex flex-wrap">
                <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-deal-id">
                            {getText('podcast.booking_request_details.deal_info.category_1.title')}
                        </label>
                        <p
                            id="grid-deal-id"
                            class="border-0  py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        >
                            {bookingRequest.deal_id}
                            <i
                                class="fas fa-copy active:color-emerald-600"
                                on:click={() => {
                                    copyToClipboard(bookingRequest.deal_id);
                                }}
                            />
                        </p>
                    </div>
                </div>
                <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-vast-url">
                            {getText('podcast.booking_request_details.deal_info.category_2.title')}
                        </label>
                        <p
                            id="grid-vast-url"
                            class="border-0  py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        >
                            <a class="underline" target="_blank" href={bookingRequest.vast_url}>{bookingRequest.vast_url}</a>
                            <i
                                class="fas fa-copy active:color-emerald-600"
                                on:click={() => {
                                    copyToClipboard(bookingRequest.vast_url);
                                }}
                            />
                        </p>
                    </div>
                </div>
                {#if campaign.type == 'crosspromo'}
                    <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-vast-url">
                                {getText('podcast.booking_request_details.deal_info.category_3.title')}
                            </label>
                            <p
                                id="grid-vast-url"
                                class="border-0  py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            >
                                <a class="underline" target="_blank" href="https://asap-dispo.s3.amazonaws.com/crosspromo/{bookingRequest.podcast_id}.xml"
                                    >"https://asap-dispo.s3.amazonaws.com/crosspromo/{bookingRequest.podcast_id}.xml"</a
                                >
                                <i
                                    class="fas fa-copy active:color-emerald-600"
                                    on:click={() => {
                                        copyToClipboard(`https://asap-dispo.s3.amazonaws.com/crosspromo/${bookingRequest.podcast_id}.xml`);
                                    }}
                                />
                            </p>
                        </div>
                    </div>
                {/if}
            </div>
        </div>
    </form>
{/if}
