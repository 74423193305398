<script>
    export let snackbarNoDismissOpen;
    export let snackbarNoDismissMsg;
    export let timeout = 1000;
    export let snackbarNoDismissType = 'success';
    let backgroundColor;

    snackbarNoDismissType == 'success'
        ? (backgroundColor = 'bg-emerald-500')
        : snackbarNoDismissType == 'info'
        ? (backgroundColor = 'bg-orange-500')
        : snackbarNoDismissType == 'warning'
        ? (backgroundColor = 'bg-red-500')
        : '';

    setTimeout(function () {
        snackbarNoDismissOpen = false;
    }, timeout);
</script>

{#if snackbarNoDismissOpen}
    <div id="snackbar">
        <div class="show overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center flex">
            <div class="relative w-auto my-6 mx-auto max-w-4xl">
                <div v-if="snackbarOpen" class="text-white px-6 py-4 border-0 rounded relative mb-4 {backgroundColor}">
                    <span class="text-xl inline-block mr-2 align-middle">
                        <i class="fas fa-info-circle" />
                    </span>
                    <span class="inline-block align-middle mr-8">
                        <b class="capitalize" />
                        {snackbarNoDismissMsg}
                    </span>
                </div>
            </div>
        </div>
    </div>
{/if}

<style>
    .show {
        visibility: visible;
        -webkit-animation: fadein 1s, fadeout 1s 2.5s;
        animation: fadein 0.5s, fadeout 0.5s 2.5s;
    }
</style>
