<script>
    import {patchPodcast, deletePodcastById} from '../../api/api.js';
    import SnackbarNoDismiss from 'components/Snackbars/SnackbarNoDismiss.svelte';
    import {getText} from '../../language.svelte';
    import {storedCompany} from '../../store.js';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let item;
    let newName = item.name;
    let newBookingVolume = item.max_booking_volume;
    let newAsapId = item.asap_podcast_id;
    let snackbarNoDismissOpen = false;
    let snackbarNoDismissMsg;
    let snackbarNoDismissType;

    function editPodcast() {
        document.getElementById(`grid-button-edit-${item.id}`).style.display = 'none';
        document.getElementById(`grid-podcast-name-original-${item.id}`).style.display = 'none';
        document.getElementById(`grid-podcast-booking-volume-original-${item.id}`).style.display = 'none';
        $storedCompany.type == 'admin' ? (document.getElementById(`grid-podcast-asap-id-original-${item.id}`).style.display = 'none') : '';
        document.getElementById(`grid-button-save-${item.id}`).style.display = 'flex';
        document.getElementById(`grid-podcast-name-${item.id}`).style.display = 'flex';
        document.getElementById(`grid-podcast-booking-volume-${item.id}`).style.display = 'flex';
        $storedCompany.type == 'admin' ? (document.getElementById(`grid-podcast-asap-id-${item.id}`).style.display = 'flex') : '';
    }

    async function savePodcast() {
        // check on same as original
        if (newName == item.name && newBookingVolume == item.max_booking_volume && newAsapId == item.asap_podcast_id) {
            displayOriginals();
            return;
        }

        // check on not empty
        if (newName == '') {
            fireSnackbarNoDismiss(getText('ui_messages.snackbars.admin.snackbar_name_empty'), 'warning');
            return;
        }

        if (newBookingVolume == '') {
            fireSnackbarNoDismiss(getText('ui_messages.snackbars.admin.snackbar_booking_volume_empty'), 'warning');
            return;
        }

        // check on uniqueness
        let save = true;
        if (newName !== item.name) {
            let all = document.querySelectorAll('[id^=grid-podcast-name-original-]');
            all.forEach((ele) => {
                if (ele.innerHTML == newName) {
                    fireSnackbarNoDismiss(getText('ui_messages.snackbars.admin.snackbar_entry_exists'), 'warning');
                    save = false;
                    return;
                }
            });
        }

        // catch empty asap podcast id
        newAsapId == '' ? (newAsapId = null) : '';

        // save
        if (save) {
            let data = {
                name: newName,
                max_booking_volume: newBookingVolume,
                asap_podcast_id: newAsapId,
            };

            let updated = await patchPodcast(item.id, data);
            item.name = updated.name;
            item.max_booking_volume = updated.max_booking_volume;
            item.asap_podcast_id = updated.asap_podcast_id;

            // set styles
            document.getElementById(`grid-podcast-name-original-${item.id}`).innerHTML = updated.name;
            document.getElementById(`grid-podcast-booking-volume-original-${item.id}`).innerHTML = updated.max_booking_volume;
            document.getElementById(`grid-podcast-asap-id-original-${item.id}`).innerHTML = updated.asap_podcast_id;
            displayOriginals();
        }
    }

    function displayOriginals() {
        document.getElementById(`grid-button-edit-${item.id}`).style.display = 'flex';
        document.getElementById(`grid-podcast-name-original-${item.id}`).style.display = 'flex';
        document.getElementById(`grid-podcast-booking-volume-original-${item.id}`).style.display = 'flex';
        $storedCompany.type == 'admin' ? (document.getElementById(`grid-podcast-asap-id-original-${item.id}`).style.display = 'flex') : '';
        document.getElementById(`grid-button-save-${item.id}`).style.display = 'none';
        document.getElementById(`grid-podcast-name-${item.id}`).style.display = 'none';
        document.getElementById(`grid-podcast-booking-volume-${item.id}`).style.display = 'none';
        $storedCompany.type == 'admin' ? (document.getElementById(`grid-podcast-asap-id-${item.id}`).style.display = 'none') : '';
    }

    async function deletePodcast() {
        let answer = window.confirm(`Are you sure you want to delete podcast "${item.name}" ?`);

        if (answer) {
            try {
                await deletePodcastById(item.id);
                document.getElementById(`grid-podcast-${item.id}`).remove();
            } catch (e) {
                console.log(`Something went wrong deleting podcast ${item.name}`);
            }
        } else {
            return;
        }
    }

    function fireSnackbarNoDismiss(msg, type) {
        snackbarNoDismissMsg = msg;
        snackbarNoDismissType = type;
        snackbarNoDismissOpen = true;
    }
</script>

<svelte:window
    on:keydown={(e) => {
        if (e.code == 'Escape') displayOriginals();
        if (e.code == 'Enter') {
            savePodcast();
        }
    }}
/>

{#if snackbarNoDismissOpen}
    <SnackbarNoDismiss {snackbarNoDismissMsg} bind:snackbarNoDismissOpen {snackbarNoDismissType} timeout="4000" />
{/if}

<tr id="grid-podcast-{item.id}">
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        <CardButton id="edit-{item.id}" type="default" icon="fas fa-pen text-sm" on:click={editPodcast} />
        <CardButton id="save-{item.id}" classes="hidden" type="go" icon="fas fa-save text-sm" on:click={savePodcast} />
    </td>
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {item.id} </td>
    {#if $storedCompany.type == 'admin'}
        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            <input
                id="grid-podcast-asap-id-{item.id}"
                type="text"
                class="hidden border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder={item.asap_podcast_id}
                bind:value={newAsapId}
            />
            <div id="grid-podcast-asap-id-original-{item.id}">
                {item.asap_podcast_id}
            </div>
        </td>
    {/if}
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        <input
            id="grid-podcast-name-{item.id}"
            type="text"
            class="hidden border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            placeholder={item.name}
            bind:value={newName}
        />
        <div id="grid-podcast-name-original-{item.id}">
            {item.name}
        </div>
    </td>
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> https://asap-dispo.s3.amazonaws.com/crosspromo/{item.id}.xml</td>
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        <input
            id="grid-podcast-booking-volume-{item.id}"
            type="text"
            class="hidden border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            placeholder={item.max_booking_volume}
            bind:value={newBookingVolume}
        />
        <div id="grid-podcast-booking-volume-original-{item.id}">
            {item.max_booking_volume}
        </div>
    </td>
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {item.company_id} </td>
    {#if $storedCompany.type == 'admin'}
        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
            <CardButton id="delete-{item.id}" type="cancel" icon="fas fa-trash text-sm" on:click={deletePodcast} />
        </td>
    {/if}
</tr>
