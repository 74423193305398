<script>
    import {getText} from '../../language.svelte';
    import {getDateMidnight, unixTimestampToDatePicker, getDateNowDatetime, databaseToDatePickerDate, datePickerDateToDatabase} from '../../functions.js';
    import {inventoryChecks} from '../../store.js';
    import {onMount} from 'svelte';
    import CardCampaign from 'components/Cards/CardCampaign.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    // Exports
    export let podcast;
    export let showInventoryCheck = false;
    export let updating = false; // revisiting a check
    export let selected = {};

    // parent functions
    export let parentFunction_checkExists = () => {};
    export let parentFunction_deleteInventoryCheck = () => {};
    export let fireSnackbarNoDismiss = () => {};

    // requiered variables
    let title;
    let startDate;
    let endDate;
    let requestedImpressions;
    let preRoll;
    let mid1;
    let mid2;
    let mid3;
    let postRoll;

    let ctype = '';
    let position;

    $: ctype, readable();

    function readable() {
        ctype = ctype.toLowerCase();
    }

    // check on changes
    let showBookingFields = false;
    let edited = false; // editing a check
    let titleOrig;
    let startDateOrig;
    let endDateOrig;
    let requestedImpressionsOrig;
    let preRollOrig;
    let mid1Orig;
    let mid2Orig;
    let mid3Orig;
    let postRollOrig;

    let campaignTypes = ['Hostread', 'Spot'];

    // // // TODO heiss schalten for PROD
    // $: title != '' ? removeHighlightErrorBorder('grid-inventory-check-title') : '';
    // $: startDate != '' ? removeHighlightErrorBorder('grid-inventory-check-start-date') : '';
    // $: endDate != '' ? removeHighlightErrorBorder('grid-inventory-check-end-date') : '';
    // // // TODO check with Vincent wie auf minus und null checken
    // $: requestedImpressions > 0 ? removeHighlightErrorBorder('grid-inventory-check-requested-impressions') : '';
    // $: preRoll != false ? removeHighlightErrorFont('grid-inventory-check-campaign-roll-position-label') : '';
    // $: mid1 != false ? removeHighlightErrorFont('grid-inventory-check-campaign-roll-position-label') : '';
    // $: mid2 != false ? removeHighlightErrorFont('grid-inventory-check-campaign-roll-position-label') : '';
    // $: mid3 != false ? removeHighlightErrorFont('grid-inventory-check-campaign-roll-position-label') : '';
    // $: postRoll != false ? removeHighlightErrorFont('grid-inventory-check-campaign-roll-position-label') : '';

    $: (title, startDate, endDate, requestedImpressions, preRoll, mid1, mid2, mid3, postRoll), checkEditState();
    // $: type != '' ? (showInventoryCheck = false) : '';

    onMount(() => {
        if (selected.id) {
            position = selected.rollPosition == 1 ? 1 : selected.rollPosition == 2 ? 2 : selected.rollPosition == 3 ? 3 : selected.rollPosition == 4 ? 4 : selected.rollPosition == 5 ? 5 : '';

            title = titleOrig = selected.title;
            startDate = startDateOrig = selected.startDate;
            endDate = endDateOrig = selected.endDate;
            requestedImpressions = requestedImpressionsOrig = selected.requestedImpressions;
            preRoll = preRollOrig = position == 1 ? true : false;
            mid1 = mid1Orig = position == 2 ? true : false;
            mid2 = mid2Orig = position == 3 ? true : false;
            mid3 = mid3Orig = position == 4 ? true : false;
            postRoll = postRollOrig = position == 5 ? true : false;
        } else {
            // TODO make clean for prod
            title = '';
            startDate = databaseToDatePickerDate(getDateMidnight(-20160));
            endDate = databaseToDatePickerDate(getDateMidnight(-30160));
            requestedImpressions = 10000000;
            preRoll = false;
            mid1 = false;
            mid2 = false;
            mid3 = false;
            postRoll = false;
        }
    });

    function checkEditState() {
        if (updating) {
            if (
                (!showBookingFields && title != titleOrig) ||
                startDate != startDateOrig ||
                endDate != endDateOrig ||
                requestedImpressions != requestedImpressionsOrig ||
                preRoll != preRollOrig ||
                mid1 != mid1Orig ||
                mid2 != mid2Orig ||
                mid3 != mid3Orig ||
                postRoll != postRollOrig
            ) {
                edited = true;
            } else edited = false;
        }
    }

    function pushInventoryCheck() {
        let position;
        preRoll ? (position = 1) : mid1 ? (position = 2) : mid2 ? (position = 3) : mid3 ? (position = 4) : postRoll ? (position = 5) : '';

        let newInventoryCheck = {
            id: Date.now(),
            podcastId: podcast.id,
            title: title,
            startDate: startDate,
            endDate: endDate,
            rollPosition: position,
            requestedImpressions: requestedImpressions,
        };

        $inventoryChecks = [...$inventoryChecks, newInventoryCheck];
    }

    function updateInventoryCheck() {
        $inventoryChecks.forEach((check) => {
            if (check.id == selected.id) {
                title != titleOrig ? (selected.title = title) : '';
                startDate != startDateOrig ? (selected.startDate = startDate) : '';
                endDate != endDateOrig ? (selected.endDate = endDate) : '';
                requestedImpressions != requestedImpressionsOrig ? (selected.requestedImpressions = requestedImpressions) : '';
                preRoll ? (selected.rollPosition = 1) : '';
                mid1 ? (selected.rollPosition = 2) : '';
                mid2 ? (selected.rollPosition = 3) : '';
                mid3 ? (selected.rollPosition = 4) : '';
                postRoll ? (selected.rollPosition = 5) : '';

                $inventoryChecks = $inventoryChecks;
            }
        });

        parentFunction_deleteInventoryCheck(selected);
        pushInventoryCheck(selected);
    }

    function checkParameters() {
        if (
            title == '' ||
            startDate == '' ||
            endDate == '' ||
            // TODO check with Vincent wie auf minus und null checken
            requestedImpressions < 0 ||
            requestedImpressions == 0 ||
            requestedImpressions == null ||
            (preRoll == false && mid1 == false && mid2 == false && mid3 == false && postRoll == false)
        ) {
            if (title == '') {
                document.getElementById('grid-inventory-check-title').classList.remove('border-0');
                document.getElementById('grid-inventory-check-title').classList.add('border-2');
                document.getElementById('grid-inventory-check-title').classList.add('highlight-error');
            }
            if (startDate == '') {
                document.getElementById('grid-inventory-check-start-date').classList.remove('border-0');
                document.getElementById('grid-inventory-check-start-date').classList.add('border-2');
                document.getElementById('grid-inventory-check-start-date').classList.add('highlight-error');
            }
            if (endDate == '') {
                document.getElementById('grid-inventory-check-end-date').classList.remove('border-0');
                document.getElementById('grid-inventory-check-end-date').classList.add('border-2');
                document.getElementById('grid-inventory-check-end-date').classList.add('highlight-error');
            }
            if (preRoll == false && mid1 == false && mid2 == false && mid3 == false && postRoll == false) {
                document.getElementById('grid-inventory-check-campaign-roll-position-label').classList.remove('text-blueGray-600');
                document.getElementById('grid-inventory-check-campaign-roll-position-label').classList.add('text-red-500');
                document.getElementById('grid-inventory-check-campaign-roll-position-label').innerHTML = getText('podcast.inventory.inventory_check.roll_position_error');
            }
            if (requestedImpressions == 0) {
                document.getElementById('grid-inventory-check-requested-impressions').classList.remove('border-0');
                document.getElementById('grid-inventory-check-requested-impressions').classList.add('border-2');
                document.getElementById('grid-inventory-check-requested-impressions').classList.add('highlight-error');
            }
            return false;
        } else {
            return true;
        }
    }

    function resetParameters() {
        title = '';
        startDate = '';
        endDate = '';
        requestedImpressions = 0;
        preRoll = false;
        mid1 = false;
        mid2 = false;
        mid3 = false;
        postRoll = false;

        selected = {};
        updating = false;
    }

    // function makeBooking() {
    //     ctype = document.getElementById('grid-inventory-check-campaign-type').value;
    //     document.getElementById('grid-inventory-check-campaign').style.display = '';
    // }

    function removeHighlightErrorBorder(id) {
        document.getElementById(id).classList.remove('border-2');
        document.getElementById(id).classList.remove('highlight-error');
        document.getElementById(id).classList.add('border-0');
    }

    function removeHighlightErrorFont(id) {
        document.getElementById(id).classList.remove('text-red-500');
        document.getElementById(id).innerHTML = getText('podcast.inventory.inventory_check.roll_position');
        document.getElementById(id).classList.add('text-blueGray-600');
    }

    function handleDateChange(event) {
        let pickedDateTime = Date.parse(event.target.value);

        if (event.target.id == 'grid-inventory-check-start-date') {
            if (pickedDateTime < Date.parse(getDateMidnight(60)) || (endDate && pickedDateTime >= Date.parse(endDate))) {
                startDate = unixTimestampToDatePicker(getDateNowDatetime());
                document.getElementById('grid-inventory-check-start-date').value = '';
            } else {
                startDate = unixTimestampToDatePicker(pickedDateTime);
            }
        }

        // if fired from endDate
        if (event.target.id == 'grid-inventory-check-end-date') {
            if (pickedDateTime < Date.parse(getDateMidnight(60)) || (startDate && pickedDateTime <= Date.parse(startDate))) {
                endDate = databaseToDatePickerDate(datePickerDateToDatabase(startDate));
                document.getElementById('grid-inventory-check-end-date').value = '';
            } else {
                endDate = unixTimestampToDatePicker(pickedDateTime);
            }
        }
    }

    function updateRollPositionToggle(e, roll) {
        if (roll == 'pre') {
            if (e.srcElement.checked) {
                preRoll = true;

                // uncheck all others
                mid1 = false;
                mid2 = false;
                mid3 = false;
                postRoll = false;

                document.getElementById('grid-inventory-check-booking-request-checkbox-roll-mid-1').checked = false;
                document.getElementById('grid-inventory-check-booking-request-checkbox-roll-mid-2').checked = false;
                document.getElementById('grid-inventory-check-booking-request-checkbox-roll-mid-3').checked = false;
                document.getElementById('grid-inventory-check-booking-request-checkbox-roll-post').checked = false;
            } else {
                preRoll = false;
            }
        }
        if (roll == 'mid-1') {
            if (e.srcElement.checked) {
                mid1 = true;

                preRoll = false;
                mid2 = false;
                mid3 = false;
                postRoll = false;

                document.getElementById('grid-inventory-check-booking-request-checkbox-roll-pre').checked = false;
                document.getElementById('grid-inventory-check-booking-request-checkbox-roll-mid-2').checked = false;
                document.getElementById('grid-inventory-check-booking-request-checkbox-roll-mid-3').checked = false;
                document.getElementById('grid-inventory-check-booking-request-checkbox-roll-post').checked = false;
            } else {
                mid1 = false;
            }
        }
        if (roll == 'mid-2') {
            if (e.srcElement.checked) {
                mid2 = true;

                preRoll = false;
                mid1 = false;
                mid3 = false;
                postRoll = false;

                document.getElementById('grid-inventory-check-booking-request-checkbox-roll-pre').checked = false;
                document.getElementById('grid-inventory-check-booking-request-checkbox-roll-mid-1').checked = false;
                document.getElementById('grid-inventory-check-booking-request-checkbox-roll-mid-3').checked = false;
                document.getElementById('grid-inventory-check-booking-request-checkbox-roll-post').checked = false;
            } else {
                mid2 = false;
            }
        }
        if (roll == 'mid-3') {
            if (e.srcElement.checked) {
                mid3 = true;

                preRoll = false;
                mid1 = false;
                mid2 = false;
                postRoll = false;

                document.getElementById('grid-inventory-check-booking-request-checkbox-roll-pre').checked = false;
                document.getElementById('grid-inventory-check-booking-request-checkbox-roll-mid-1').checked = false;
                document.getElementById('grid-inventory-check-booking-request-checkbox-roll-mid-2').checked = false;
                document.getElementById('grid-inventory-check-booking-request-checkbox-roll-post').checked = false;
            } else {
                mid3 = false;
            }
        }
        if (roll == 'post') {
            if (e.srcElement.checked) {
                postRoll = true;

                preRoll = false;
                mid1 = false;
                mid2 = false;
                mid3 = false;

                document.getElementById('grid-inventory-check-booking-request-checkbox-roll-pre').checked = false;
                document.getElementById('grid-inventory-check-booking-request-checkbox-roll-mid-1').checked = false;
                document.getElementById('grid-inventory-check-booking-request-checkbox-roll-mid-2').checked = false;
                document.getElementById('grid-inventory-check-booking-request-checkbox-roll-mid-3').checked = false;
            } else {
                postRoll = false;
            }
        }
    }
</script>

<svelte:window
    on:keydown={(event) => {
        if (event.code == 'Escape') {
            resetParameters();
            showInventoryCheck = false;
        }
        if (event.code == 'Enter') {
            if (checkParameters()) {
                if (parentFunction_checkExists(title) == false) {
                    pushInventoryCheck();
                    resetParameters();
                    showInventoryCheck = false;
                } else {
                    fireSnackbarNoDismiss(getText('ui_messages.snackbars.podcast.snackbar_inventory_exists'), 'warning');
                    event.preventDefault();
                    showInventoryCheck = true;
                }
            }
        }
    }}
/>

{#if showInventoryCheck}
    <div id="grid-inventory-check-pop-up" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
        <div class="relative w-third mt-20 my-6 mx-auto">
            <div class="bg-gray-200 relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded border-1">
                <div class="bg-gray-300 rounded-t mb-3 px-4 py-3 border-0">
                    <div class="flex flex-wrap items-center">
                        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 class="font-semibold text-base text-blueGray-700">
                                <p>{getText('podcast.inventory.inventory_check.title')}</p>
                            </h3>
                        </div>
                    </div>
                </div>

                <div class="flex flex-wrap items-center">
                    {#if showBookingFields}
                        <div class="w-full lg:w-8/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-inventory-check-title">
                                    {getText('podcast.inventory.inventory_check.campaign_title')}
                                </label>
                                <input
                                    id="grid-inventory-check-title"
                                    type="text"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder={getText('promoter.campaign.general_information.category_1.input_placeholder')}
                                    bind:value={title}
                                />
                            </div>
                        </div>
                    {:else}
                        <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-inventory-check-title">
                                    {getText('podcast.inventory.inventory_check.campaign_title')}
                                </label>
                                <input
                                    id="grid-inventory-check-title"
                                    type="text"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder={getText('promoter.campaign.general_information.category_1.input_placeholder')}
                                    bind:value={title}
                                />
                            </div>
                        </div>
                    {/if}
                    <!-- TODO make type field dropdown select ! -->
                    {#if showBookingFields}
                        <div class="w-full lg:w-4/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-inventory-check-campaign-type">
                                    {getText('podcast.inventory.inventory_check.campaign_type')}
                                </label>

                                <select bind:value={ctype} name="type" id="grid-inventory-check-campaign-type">
                                    <option value="select">
                                        {getText('admin.companies.new_company.category_1.entry_2.input_placeholder')}
                                    </option>
                                    {#each campaignTypes as ctype}
                                        <option value={ctype}>{ctype}</option>
                                    {/each}
                                </select>
                            </div>
                        </div>
                    {/if}
                </div>

                <!-- {#if type == 'Hostread'}
                    <div class="flex flex-wrap items-center">
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-client">
                                    {getText('promoter.campaign.general_information.category_2.title')}
                                </label>
                                <input
                                    id="grid-client"
                                    type="search"
                                    list="clientContactOptions"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder={getText('promoter.campaign.general_information.category_2.input_placeholder')}
                                    bind:value={client}
                                />
                            </div>
                        </div>
                    </div>

                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-campaign-hostread-duration">
                                {getText('promoter.campaign.general_information.category_4.title')}
                            </label>
                            <input
                                id="grid-campaign-hostread-duration"
                                type="number"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder={getText('promoter.campaign.general_information.category_4.input_placeholder')}
                                bind:value={duration}
                            />
                        </div>
                    </div>
                {/if} -->

                <div class="flex flex-wrap items-center">
                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-inventory-check-start-date">
                                {getText('podcast.inventory.inventory_check.start_date')}
                            </label>
                            <input
                                id="grid-inventory-check-start-date"
                                type="date"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                bind:value={startDate}
                                on:blur={handleDateChange}
                            />
                        </div>
                    </div>
                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-inventory-check-end-date">
                                {getText('podcast.inventory.inventory_check.end_date')}
                            </label>
                            <input
                                id="grid-inventory-check-end-date"
                                type="date"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                bind:value={endDate}
                                on:blur={handleDateChange}
                            />
                        </div>
                    </div>
                </div>
                <div class="flex flex-wrap items-center">
                    <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3">
                            <label
                                id="grid-inventory-check-campaign-roll-position-label"
                                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                for="grid-inventory-check-campaign-roll-position"
                            >
                                {getText('podcast.inventory.inventory_check.roll_position')}
                            </label>
                            <div class="text-center flex justify-between">
                                <label for="grid-inventory-check-campaign-checkbox-roll-pre" class="mr-4 mt-2">
                                    <input
                                        id="grid-inventory-check-booking-request-checkbox-roll-pre"
                                        type="checkbox"
                                        class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                        bind:checked={preRoll}
                                        on:click={(e) => {
                                            updateRollPositionToggle(e, 'pre');
                                        }}
                                    />
                                    {getText('general.roll_positions.entry_1')}
                                </label>

                                <label for="grid-inventory-check-booking-request-checkbox-roll-mid-1" class="mr-4 mt-2">
                                    <input
                                        id="grid-inventory-check-booking-request-checkbox-roll-mid-1"
                                        type="checkbox"
                                        class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                        bind:checked={mid1}
                                        on:click={(e) => {
                                            updateRollPositionToggle(e, 'mid-1');
                                        }}
                                    />
                                    {getText('general.roll_positions.entry_2')}
                                </label>

                                <label for="grid-inventory-check-booking-request-checkbox-roll-mid-2" class="mr-4 mt-2">
                                    <input
                                        id="grid-inventory-check-booking-request-checkbox-roll-mid-2"
                                        type="checkbox"
                                        class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                        bind:checked={mid2}
                                        on:click={(e) => {
                                            updateRollPositionToggle(e, 'mid-2');
                                        }}
                                    />
                                    {getText('general.roll_positions.entry_3')}
                                </label>

                                <label for="grid-inventory-check-booking-request-checkbox-roll-mid-3" class="mr-4 mt-2">
                                    <input
                                        id="grid-inventory-check-booking-request-checkbox-roll-mid-3"
                                        type="checkbox"
                                        class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                        bind:checked={mid3}
                                        on:click={(e) => {
                                            updateRollPositionToggle(e, 'mid-3');
                                        }}
                                    />
                                    {getText('general.roll_positions.entry_4')}
                                </label>

                                <label for="grid-inventory-check-campaign-checkbox-roll-post" class="mr-4 mt-2">
                                    <input
                                        id="grid-inventory-check-booking-request-checkbox-roll-post"
                                        type="checkbox"
                                        class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                        bind:checked={postRoll}
                                        on:click={(e) => {
                                            updateRollPositionToggle(e, 'post');
                                        }}
                                    />
                                    {getText('general.roll_positions.entry_5')}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="w-full lg:w-6/12 px-4">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-inventory-check-requested-impressions">
                            {getText('podcast.inventory.inventory_check.impressions')}
                        </label>
                        <div class="relative w-full mb-3">
                            <input
                                id="grid-inventory-check-requested-impressions"
                                type="number"
                                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                bind:value={requestedImpressions}
                            />
                        </div>
                    </div>
                </div>

                <form>
                    <div class="flex-auto px-4 lg:px-10 lg:pb-2">
                        <div class="text-center flex justify-end">
                            <!-- TODO test new CardButton with inventory -->
                            <!-- <button
                                class="bg-red-500 active:bg-blueGray-600 text-white font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="submit"
                                on:click={() => {
                                    resetParameters();
                                    showInventoryCheck = false;
                                }}
                            >
                                {getText('podcast.inventory.inventory_check.button_cancel')}
                            </button> -->

                            <CardButton
                                id="cancel-inventory"
                                type="cancel"
                                buttonType="submit"
                                text={getText('podcast.inventory.inventory_check.button_cancel')}
                                on:click={() => {
                                    resetParameters();
                                    showInventoryCheck = false;
                                }}
                            />

                            {#if !updating}
                                <!-- <button
                                    class="bg-emerald-500 active:bg-blueGray-600 text-white font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="submit"
                                    on:click={(event) => {
                                        if (checkParameters()) {
                                            if (!parentFunction_checkExists(title)) {
                                                pushInventoryCheck();
                                                resetParameters();
                                                showInventoryCheck = false;
                                            } else {
                                                fireSnackbarNoDismiss(getText('ui_messages.snackbars.podcast.snackbar_inventory_exists'), 'warning');
                                                event.preventDefault();
                                                showInventoryCheck = true;
                                            }
                                        } else {
                                            event.preventDefault();
                                            showInventoryCheck = true;
                                        }
                                    }}
                                >
                                    {getText('podcast.inventory.inventory_check.button_check')}
                                </button> -->

                                <CardButton
                                    id="check-inventory"
                                    type="go"
                                    buttonType="submit"
                                    text={getText('podcast.inventory.inventory_check.button_check')}
                                    on:click={(event) => {
                                        if (checkParameters()) {
                                            if (!parentFunction_checkExists(title)) {
                                                pushInventoryCheck();
                                                resetParameters();
                                                showInventoryCheck = false;
                                            } else {
                                                fireSnackbarNoDismiss(getText('ui_messages.snackbars.podcast.snackbar_inventory_exists'), 'warning');
                                                event.preventDefault();
                                                showInventoryCheck = true;
                                            }
                                        } else {
                                            event.preventDefault();
                                            showInventoryCheck = true;
                                        }
                                    }}
                                />
                            {/if}
                            {#if updating}
                                <!-- <button
                                    class="bg-red-500 active:bg-blueGray-600 text-white font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="submit"
                                    on:click={() => {
                                        parentFunction_deleteInventoryCheck(selected);
                                        resetParameters();
                                        showInventoryCheck = false;
                                    }}
                                >
                                    {getText('podcast.inventory.inventory_check.button_delete')}
                                </button> -->

                                <CardButton
                                    id="delete-inventory"
                                    type="cancel"
                                    buttonType="submit"
                                    text={getText('podcast.inventory.inventory_check.button_delete')}
                                    on:click={() => {
                                        parentFunction_deleteInventoryCheck(selected);
                                        resetParameters();
                                        showInventoryCheck = false;
                                    }}
                                />
                                {#if edited}
                                    <!-- <button
                                        class="bg-emerald-500 active:bg-blueGray-600 text-white font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="submit"
                                        on:click={(event) => {
                                            if (checkParameters()) {
                                                updateInventoryCheck();
                                                resetParameters();
                                                showInventoryCheck = false;
                                            } else {
                                                event.preventDefault();
                                                showInventoryCheck = true;
                                            }
                                        }}
                                    >
                                        {getText('podcast.inventory.inventory_check.button_update')}
                                    </button> -->

                                    <CardButton
                                        id="update-inventory"
                                        type="go"
                                        buttonType="submit"
                                        text={getText('podcast.inventory.inventory_check.button_update')}
                                        on:click={(event) => {
                                            if (checkParameters()) {
                                                updateInventoryCheck();
                                                resetParameters();
                                                showInventoryCheck = false;
                                            } else {
                                                event.preventDefault();
                                                showInventoryCheck = true;
                                            }
                                        }}
                                    />
                                {/if}
                                <!-- <button
                                    class="bg-emerald-500 active:bg-blueGray-600 text-white font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="submit"
                                    on:click={(event) => {
                                        // TODO add fields for additional parameters
                                        event.preventDefault();
                                        edited = false;
                                        showBookingFields = true;
                                    }}
                                >
                                    {getText('podcast.inventory.inventory_check.button_reserve')}
                                </button> -->

                                <CardButton
                                    id="reserve-inventory"
                                    type="go"
                                    buttonType="submit"
                                    text={getText('podcast.inventory.inventory_check.button_reserve')}
                                    on:click={(event) => {
                                        // TODO add fields for additional parameters
                                        event.preventDefault();
                                        edited = false;
                                        showBookingFields = true;
                                    }}
                                />
                            {/if}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="opacity-25 fixed inset-0 z-40 bg-black" />
{/if}

{#if ctype != ''}
    <div id="grid-inventory-check-campaign" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
        <div class="relative w-auto mt-20 my-6 mx-auto max-w-4xl">
            <!-- TODO check mit Vinc  -->
            <div class="max-h-600px relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                <div class="flex flex-wrap">
                    <div class="w-full mb-12 px-4">
                        <CardCampaign
                            bind:ctype
                            {title}
                            podcastId={podcast.id}
                            startDate={databaseToDatePickerDate(startDate)}
                            endDate={databaseToDatePickerDate(endDate)}
                            targetImpressions={requestedImpressions}
                            rollPositionPre={preRoll}
                            rollPositionMid1={mid1}
                            rollPositionMid2={mid2}
                            rollPositionMid3={mid3}
                            rollPositionPost={postRoll}
                            inventory={true}
                            cardTitle="Create New Campaign"
                            bind:showInventoryCheck
                            parentFunction_deleteInventoryCheck={(obj) => parentFunction_deleteInventoryCheck(obj)}
                            selectedInventoryCheck={selected}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
{/if}
