<script>
    import {Accordion, AccordionItem} from 'svelte-collapsible';
    import {getText} from '../../language.svelte';
    import CardPodcastInfo from 'components/Cards/CardPodcastInfo.svelte';
    import CardPodcastFilterBar from '../../components/Cards/CardPodcastFilterBar.svelte';
    import {getAllPodcasts, getCompanyPodcastFavorites, getCompanyPodcastExclusives, getKeywordsPerPodcast, getAllExclusives} from '../../api/api.js';
    import {storedCompany, missingLinkId, render} from '../../store.js';

    $render = Math.random();

    let accordionKey = null;
    const duration = 0.5; // seconds
    const easing = 'linear';

    let podcastPromise = gatherPodcasts();

    let showFavs = false;
    let showExlusives = false;
    let showAll = true;
    let showFiltered = false;
    let filterKey;
    let allPodcasts;
    let filteredPodcasts = [];

    // $: filterKey, console.log('parent filterKey: ', filterKey);

    async function gatherPodcasts() {
        try {
            allPodcasts = await getAllPodcasts();
        } catch (e) {
            console.log('An error occoured gathering podcast information !');
        }

        let filteredPodcasts = [];

        // only show DEMO podcasts to DEMO accounts
        allPodcasts.forEach((podcast) => {
            if ($storedCompany.id == 137 || $storedCompany.id == 138) {
                podcast.checked = false;
                podcast.requested_impressions = 0;
                filteredPodcasts.push(podcast);
            } else if (!podcast.name.includes('DEMO')) {
                podcast.checked = false;
                podcast.requested_impressions = 0;
                filteredPodcasts.push(podcast);
            }
        });

        allPodcasts = filteredPodcasts;

        // Missing Link Sonderlocke: do not show missing link exclusive podcasts if company is not missing link
        if ($storedCompany.id !== $missingLinkId) {
            const exclusivesML = await getCompanyPodcastExclusives($missingLinkId);
            let exclusiveConfirmedIdsML = exclusivesML.map((item) => (item.confirmed ? item.podcast_id : ''));
            exclusiveConfirmedIdsML = exclusiveConfirmedIdsML.filter((e) => e !== '');

            let pods = [];
            allPodcasts.forEach((pod) => {
                exclusiveConfirmedIdsML.includes(pod.id) ? '' : pods.push(pod);
            });

            allPodcasts = pods;
        }

        // sort allPodcasts alph
        allPodcasts.sort(function (a, b) {
            var nameA = a.name.toUpperCase();
            var nameB = b.name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        const favs = await getCompanyPodcastFavorites($storedCompany.id);
        const favIds = favs.map((item) => item.podcast_id);

        const exclusives = await getCompanyPodcastExclusives($storedCompany.id);
        let exclusiveConfirmedIds = exclusives.map((item) => (item.confirmed ? item.podcast_id : ''));
        exclusiveConfirmedIds = exclusiveConfirmedIds.filter((e) => e !== '');

        let exclusiveNotConfirmedIds = exclusives.map((item) => (!item.confirmed ? item.podcast_id : ''));
        exclusiveNotConfirmedIds = exclusiveNotConfirmedIds.filter((e) => e !== '');

        // check if podcast already has exclusive deal with other promoter
        const allExclusives = await getAllExclusives();
        const allExclusivesPodcastsIds = allExclusives.map((item) => (item.confirmed ? item.podcast_id : ''));

        for (const pod of allPodcasts) {
            try {
                pod.keywords = await getKeywordsPerPodcast(pod.id);
            } catch (e) {
                console.log(`An error occoured gathering keywords of podcast ${pod.name} !`);
            }

            favIds.includes(pod.id) ? (pod.favorite = true) : (pod.favorite = false);
            exclusiveConfirmedIds.includes(pod.id) ? (pod.exclusiveConfirmed = true) : (pod.exclusiveConfirmed = false);
            exclusiveNotConfirmedIds.includes(pod.id) ? (pod.exclusiveNotConfirmed = true) : (pod.exclusiveNotConfirmed = false);

            // set if podcast is available for an exclusive request
            allExclusivesPodcastsIds.includes(pod.id) && exclusiveConfirmedIds.includes(pod.id) ? (pod.exclusiveAvailable = false) : (pod.exclusiveAvailable = true);
        }

        // debugger;
        return allPodcasts;
    }
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between" />
        <h6 class="text-blueGray-700 mb-4 text-xl font-bold">{getText('promoter.network.podcasts.headline')}</h6>
        <div class="block w-full overflow-x-auto">
            <Accordion bind:key={accordionKey} {duration} {easing}>
                <AccordionItem key="Info">
                    <div slot="header">
                        <div
                            class="header bg-blueGray-200 hover:cell-highlight-color text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                        >
                            <div class="ml-2">
                                <h2>{getText('promoter.network.podcasts.title_1')}</h2>
                            </div>
                        </div>
                    </div>

                    <p slot="body" class="body">
                        {#if accordionKey}
                            <div class="bg-blueGray-100 border-t-0 align-middle border-l-0 border-r-0 mb-4 p-1">
                                <div class="ml-2">
                                    {getText('promoter.network.podcasts.description')}
                                </div>
                            </div>
                        {/if}
                    </p>
                </AccordionItem>
            </Accordion>
        </div>
    </div>

    {#await podcastPromise then data}
        <CardPodcastFilterBar bind:showFavs bind:showExlusives bind:showAll bind:showFiltered bind:filterKey bind:filteredPodcasts {allPodcasts} />
        <div class="rounded-t bg-white mb-0 px-6 py-3">
            <div class="text-center flex flex-wrap">
                <div class="masonry-with-columns">
                    {#each data as podcast}
                        {#if showAll}
                            <div id="grid-podcast-info-{podcast.id}" class="overflow-x-auto">
                                <CardPodcastInfo bind:podcast bind:filterKey view="all" />
                            </div>
                        {:else if showFavs && podcast.favorite}
                            <div id="grid-podcast-info-{podcast.id}" class="overflow-x-auto">
                                <CardPodcastInfo bind:podcast bind:filterKey view="favs" />
                            </div>
                        {:else if showExlusives && (podcast.exclusiveConfirmed || podcast.exclusiveNotConfirmed)}
                            <div id="grid-podcast-info-{podcast.id}" class="overflow-x-auto">
                                <CardPodcastInfo bind:podcast bind:filterKey view="exclusives" />
                            </div>
                        {:else if showFiltered && filteredPodcasts.includes(podcast.id)}
                            <div id="grid-podcast-info-{podcast.id}" class="overflow-x-auto">
                                <CardPodcastInfo bind:podcast bind:filterKey view="filterKey" />
                            </div>
                        {/if}
                    {/each}
                </div>
            </div>
        </div>
    {/await}
</div>
