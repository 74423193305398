<script>
    import CardCampaign from 'components/Cards/CardCampaign.svelte';
    export let id;
    export let ctype;
</script>

<div class="flex flex-wrap">
    <div class="w-full mb-12 px-4">
        <CardCampaign cardTitle="Edit Campaign" {id} {ctype} />
    </div>
</div>
