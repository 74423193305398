<script context="module">
    import {get} from 'svelte/store';
    import {
        reRender,
        preProductionCampaignsTotal,
        preProductionCampaignsTodo,
        completedCampaignsTotal,
        completedCampaignsTodo,
        runningCampaigns,
        campaignsAllTotal,
        campaignsAllTodo,
        storedCompany,
        crossPromosTotal,
        crossPromosTodo,
        archivedCampaignsTotal,
    } from './store';
    import {getCampaignsByCompanyCached, getBookingRequestsByCampaignIdCached} from './api/api.js';
    import {to_number} from 'svelte/internal';
    import {getDateNow} from '../src/functions.js';

    export function toggleRender() {
        let rr = get(reRender);
        reRender.set(!rr);
    }

    export async function triggerCampaignRenderTotal(campaigns) {
        let campaignsCurrentTotal = [];
        let campaignsCompletedTotal = [];
        let campaignsRunningTotal = [];
        let crossPromosAll = [];
        let archivedCampaignsAll = [];

        for (let x in campaigns) {
            if (campaigns[x].state < 9) {
                campaignsCurrentTotal.push(campaigns[x]);
            }
            if (campaigns[x].state == 9) {
                campaignsRunningTotal.push(campaigns[x]);
            }
            if (campaigns[x].state >= 10 && !campaigns[x].archived) {
                campaignsCompletedTotal.push(campaigns[x]);
            }
            if (campaigns[x].type == 'crosspromo') {
                crossPromosAll.push(campaigns[x]);
            }
            if (campaigns[x].archived) {
                archivedCampaignsAll.push(campaigns[x]);
            }
        }

        preProductionCampaignsTotal.set(campaignsCurrentTotal.length);
        completedCampaignsTotal.set(campaignsCompletedTotal.length);
        runningCampaigns.set(campaignsRunningTotal.length);
        crossPromosTotal.set(crossPromosAll.length);
        archivedCampaignsTotal.set(archivedCampaignsAll.length);

        let total = campaignsCurrentTotal.length + campaignsCompletedTotal.length + campaignsRunningTotal.length + archivedCampaignsAll.length;
        campaignsAllTotal.set(total);

        toggleRender();
    }

    export async function triggerCampaignRenderTodo() {
        let campaigns;
        try {
            // console.log('Calling cache from badgeManager');
            // TODO limit api calls from company.type == "podcast" (only need for crosspromos)
            campaigns = await getCampaignsByCompanyCached(get(storedCompany).id);
        } catch (e) {
            console.log(`Error ${e} occoured whilst loading campaigns of company ${get(storedCompany).id}`);
        }

        let campaignsCurrentTodo = 0;
        let campaignsCompletedTodo = 0;
        let crossPromosAllTodo = 0;

        for (let x in campaigns) {
            if (campaigns[x].state < 9) {
                let count = await checkOnActivity(campaigns[x].id, campaigns);
                campaignsCurrentTodo = campaignsCurrentTodo + count;
            }
            if (campaigns[x].state >= 10 && !campaigns[x].archived) {
                let count = await checkOnActivity(campaigns[x].id, campaigns);
                campaignsCompletedTodo = campaignsCompletedTodo + count;
            }
            if (campaigns[x].type == 'crosspromo') {
                let count = await checkOnActivity(campaigns[x].id, campaigns);
                crossPromosAllTodo = crossPromosAllTodo + count;
            }
        }

        preProductionCampaignsTodo.set(campaignsCurrentTodo);
        completedCampaignsTodo.set(campaignsCompletedTodo);
        crossPromosTodo.set(crossPromosAllTodo);

        let total = campaignsCurrentTodo + campaignsCompletedTodo;
        campaignsAllTodo.set(total);

        toggleRender();
    }

    async function checkOnActivity(campaignId, campaigns) {
        let campaignBookingRequestsForBadges = await getCampaignDataForBadges(campaigns).then((response) => {
            return response;
        });

        let doSomething = [];

        if (Object.keys(campaignBookingRequestsForBadges).length > 0) {
            for (let camp in campaignBookingRequestsForBadges) {
                if (camp == campaignId) {
                    doSomething[camp] = 0;
                    for (let folder in campaignBookingRequestsForBadges[camp]) {
                        if (campaignBookingRequestsForBadges[camp][folder].length > 0) {
                            campaignBookingRequestsForBadges[camp][folder].forEach((bR) => {
                                if (bR.state == 1 || (bR.state == 3 && !bR.booking_changes_requested > 0) || bR.state == 7 || bR.state == 11) {
                                    doSomething[camp] = doSomething[camp] + 1;
                                } else if (bR.state == 13) {
                                    let campaign = campaigns.filter((x) => x.id == bR.campaign_id);
                                    if (campaign[0].start_date >= getDateNow()) {
                                        doSomething[camp] = doSomething[camp] + 1;
                                    }
                                } else if (bR.state == 5 && bR.script_updated_podcast && !bR.script_approved_date) {
                                    doSomething[camp] = doSomething[camp] + 1;
                                }
                            });
                            if (campaigns) {
                                const campaignFiltered = campaigns.filter((x) => x.id == to_number(camp));

                                if (campaignFiltered[0].type == 'hostread' && campaignFiltered[0].briefing_completed == false) {
                                    doSomething[camp] = doSomething[camp] + 1;
                                }

                                if ((campaignFiltered[0].type == 'spot' || campaignFiltered[0].type == 'crosspromo') && !campaignFiltered[0].audiofile_url) {
                                    doSomething[camp] = doSomething[camp] + 1;
                                }
                                if (campaignFiltered[0].state == 14) {
                                    doSomething[camp] = false;
                                }
                            }
                        }
                    }
                }
            }
        }

        // catch undefined
        if (!doSomething[campaignId]) {
            doSomething[campaignId] = 0;
        }

        return doSomething[campaignId];
    }

    async function getCampaignDataForBadges(campaigns) {
        let campaignBookingRequestsLocal = {};
        for (let x in campaigns) {
            let bRs;
            try {
                bRs = await getBookingRequestsByCampaignIdCached(campaigns[x].id);
            } catch (e) {
                console.log(`Error ${e} occoured whilst loading booking requests of company ${campaigns[x].id}`);
            }

            campaignBookingRequestsLocal[campaigns[x].id] = {
                notActivatedBRs: [],
                pendingBRs: [],
                dealedBRs: [],
                onAirBRs: [],
                completedBRs: [],
                wrappedBRs: [],
                cancelledBRs: [],
                rejectedBRs: [],
                missedDeadline: [],
            };

            for (let y in bRs) {
                if (bRs[y].state == 1) {
                    if (campaignBookingRequestsLocal[campaigns[x].id].notActivatedBRs) {
                        campaignBookingRequestsLocal[campaigns[x].id].notActivatedBRs = [...campaignBookingRequestsLocal[campaigns[x].id].notActivatedBRs, bRs[y]];
                    } else {
                        campaignBookingRequestsLocal[campaigns[x].id].notActivatedBRs = bRs[y];
                    }
                } else if (bRs[y].state == 2 || bRs[y].state == 3 || bRs[y].state == 5 || bRs[y].state == 7) {
                    if (campaignBookingRequestsLocal[campaigns[x].id].pendingBRs) {
                        campaignBookingRequestsLocal[campaigns[x].id].pendingBRs = [...campaignBookingRequestsLocal[campaigns[x].id].pendingBRs, bRs[y]];
                    } else {
                        campaignBookingRequestsLocal[campaigns[x].id].pendingBRs = bRs[y];
                    }
                } else if (bRs[y].state == 4) {
                    if (campaignBookingRequestsLocal[campaigns[x].id].rejectedBRs) {
                        campaignBookingRequestsLocal[campaigns[x].id].rejectedBRs = [...campaignBookingRequestsLocal[campaigns[x].id].rejectedBRs, bRs[y]];
                    } else {
                        campaignBookingRequestsLocal[campaigns[x].id].rejectedBRs = bRs[y];
                    }
                } else if (bRs[y].state == 6) {
                    if (campaignBookingRequestsLocal[campaigns[x].id].cancelledBRs) {
                        campaignBookingRequestsLocal[campaigns[x].id].cancelledBRs = [...campaignBookingRequestsLocal[campaigns[x].id].cancelledBRs, bRs[y]];
                    } else {
                        campaignBookingRequestsLocal[campaigns[x].id].cancelledBRs = bRs[y];
                    }
                } else if (bRs[y].state == 8) {
                    if (campaignBookingRequestsLocal[campaigns[x].id].dealedBRs) {
                        campaignBookingRequestsLocal[campaigns[x].id].dealedBRs = [...campaignBookingRequestsLocal[campaigns[x].id].dealedBRs, bRs[y]];
                    } else {
                        campaignBookingRequestsLocal[campaigns[x].id].dealedBRs = bRs[y];
                    }
                } else if (bRs[y].state == 9) {
                    if (campaignBookingRequestsLocal[campaigns[x].id].onAirBRs) {
                        campaignBookingRequestsLocal[campaigns[x].id].onAirBRs = [...campaignBookingRequestsLocal[campaigns[x].id].onAirBRs, bRs[y]];
                    } else {
                        campaignBookingRequestsLocal[campaigns[x].id].onAirBRs = bRs[y];
                    }
                } else if (bRs[y].state == 10 || bRs[y].state == 11) {
                    if (campaignBookingRequestsLocal[campaigns[x].id].completedBRs) {
                        campaignBookingRequestsLocal[campaigns[x].id].completedBRs = [...campaignBookingRequestsLocal[campaigns[x].id].completedBRs, bRs[y]];
                    } else {
                        campaignBookingRequestsLocal[campaigns[x].id].completedBRs = bRs[y];
                    }
                } else if (bRs[y].state == 12) {
                    if (campaignBookingRequestsLocal[campaigns[x].id].wrappedBRs) {
                        campaignBookingRequestsLocal[campaigns[x].id].wrappedBRs = [...campaignBookingRequestsLocal[campaigns[x].id].wrappedBRs, bRs[y]];
                    } else {
                        campaignBookingRequestsLocal[campaigns[x].id].wrappedBRs = bRs[y];
                    }
                } else if (bRs[y].state == 13) {
                    if (campaignBookingRequestsLocal[campaigns[x].id].missedDeadline) {
                        campaignBookingRequestsLocal[campaigns[x].id].missedDeadline = [...campaignBookingRequestsLocal[campaigns[x].id].missedDeadline, bRs[y]];
                    } else {
                        campaignBookingRequestsLocal[campaigns[x].id].missedDeadline = bRs[y];
                    }
                }
            }
        }

        return campaignBookingRequestsLocal;
    }
</script>
