// BADGE styles
export const BADGE_BACKGROUND_DEFAULT = 'bg-blueGray-100';
export const BADGE_BORDER_COLOR_DEFAULT = 'border-blueGray-300';
export const BADGE_TEXT_COLOR_DEFAULT = 'text-blueGray-600';

export const BADGE_BACKGROUND_WORDCOUNT = 'bg-amber-200';

export const BADGE_BACKGROUND_BRIEFING_KEYWORD_ADD = 'bg-emerald-400';
export const BADGE_ACTIVE_BRIEFING_KEYWORD_ADD = 'bg-emerald-500';
export const BADGE_BACKGROUND_BRIEFING_KEYWORD_REMOVE = 'bg-red-400';
export const BADGE_ACTIVE_BRIEFING_KEYWORD_REMOVE = 'bg-red-400';

// BUTTON STYLES
export const BUTTON_BACKGROUND_DEFAULT = 'bg-blueGray-600';
export const BUTTON_ACTIVE_DEFAULT = 'bg-blueGray-600';
export const BUTTON_BORDER_COLOR_DEFAULT = 'bg-blueGray-600';
export const BUTTON_TEXT_COLOR_DEFAULT = 'text-white';

export const BUTTON_BACKGROUND_DEFAULT_FADED = 'bg-blueGray-400';
export const BUTTON_ACTIVE_DEFAULT_FADED = 'bg-blueGray-400';
export const BUTTON_BORDER_COLOR_DEFAULT_FADED = 'bg-blueGray-400';

export const BUTTON_BACKGROUND_GO = 'bg-emerald-500';
export const BUTTON_ACTIVE_GO = 'bg-emerald-500';
export const BUTTON_BORDER_GO = 'bg-emerald-500';

export const BUTTON_BACKGROUND_GO_BLUE = 'bg-lightBlue-500';
export const BUTTON_ACTIVE_GO_BLUE = 'bg-lightBlue-500';
export const BUTTON_BORDER_GO_BLUE = 'bg-lightBlue-500';

export const BUTTON_BACKGROUND_CHANGE = 'bg-orange-500';
export const BUTTON_ACTIVE_CHANGE = 'bg-orange-500';
export const BUTTON_BORDER_CHANGE = 'bg-orange-500';

export const BUTTON_BACKGROUND_CANCEL = 'bg-red-500';
export const BUTTON_ACTIVE_CANCEL = 'bg-red-500';
export const BUTTON_BORDER_CANCEL = 'bg-emerald-500';

// KEYWORDS

// INVENTORY CHART COLORS
export const COLORS = ['#4dc9f6', '#f67019', '#f53794', '#537bc4', '#acc236', '#166a8f', '#00a950', '#58595b', '#8549ba'];
export const CHART_COLORS_BOOKING = ['rgb(255, 99, 132, 1)', 'rgb(255, 159, 64, 1)', 'rgb(255, 205, 86, 1)', 'rgb(75, 192, 192, 1)', 'rgb(54, 162, 235, 1)', 'rgb(153, 102, 255, 1)'];

export const CHART_COLORS_RESERVATION = ['rgb(255, 99, 132, 0.3)', 'rgb(255, 159, 64, 0.3)', 'rgb(255, 205, 86, 0.3)', 'rgb(75, 192, 192, 0.3)', 'rgb(54, 162, 235, 0.3)', 'rgb(153, 102, 255, 0.3)'];
export const BORDER_COLORS_RESERVATION = ['rgb(255, 99, 132, 0.3)', 'rgb(255, 159, 64, 0.3)', 'rgb(255, 205, 86, 0.3)', 'rgb(75, 192, 192, 0.3)', 'rgb(54, 162, 235, 0.3)', 'rgb(153, 102, 255, 0.3)'];

export const CHART_COLORS_PASTEL = {
    red: 'rgb(255, 99, 132)',
    orange: 'rgb(255, 159, 64)',
    yellow: 'rgb(255, 205, 86)',
    green: 'rgb(75, 192, 192)',
    blue: 'rgb(54, 162, 235)',
    purple: 'rgb(153, 102, 255)',
    grey: 'rgb(201, 203, 207)',
};

// export const CHART_COLORS_GRAY = [
//     'rgb(210,210,210, 1)',
//     'rgb(190,190,190, 1)',
//     'rgb(170,170,170, 1)',
//     'rgb(150, 150, 150, 1)',
//     'rgb(130, 130, 130, 1)',
//     'rgb(110,110,110, 1)',
//     'rgb(80,80,80, 1)',
//     'rgb(50,50,50, 1)',
// ];

// export const BORDER_COLORS_GRAY = ['rgb(0,0,0)', 'rgb(190,190,190)', 'rgb(170,170,170)', 'rgb(150, 150, 150)', 'rgb(130, 130, 130)', 'rgb(110,110,110)', 'rgb(80,80,80)', 'rgb(50,50,50)'];

export const CHART_COLORS_GRAY = ['rgb(210,210,210, 1)', 'rgb(190,190,190, 1)', 'rgb(170,170,170, 1)', 'rgb(150, 150, 150, 1)', 'rgb(130, 130, 130, 1)'];
export const BORDER_COLORS_GRAY = ['rgb(210,210,210, 1)', 'rgb(190,190,190, 1)', 'rgb(170,170,170, 1)', 'rgb(150, 150, 150, 1)', 'rgb(130, 130, 130, 1)'];
