<script>
    import {alertOpen} from '../../store.js';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let alertMsg;
    export let alertTitle;

    export function toggleAlert() {
        $alertOpen = !$alertOpen;
    }
</script>

{#if $alertOpen}
    <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
        <div class="relative w-auto my-6 mx-auto max-w-3xl">
            <!--content-->
            <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <!--header-->
                <div class="bg-red-500 text-white flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 class="text-3xl font-semibold">{alertTitle}</h3>
                    <!-- <span class="text-xl inline-block mr-5 align-middle">
                        <i class="fas fa-bell" />
                    </span> -->
                </div>
                <!--body-->
                <div class="relative p-6 flex-auto">
                    {#each alertMsg as msg}
                        <p class="my-4 text-blueGray-500 text-lg leading-relaxed">
                            {msg}
                        </p>
                    {/each}
                </div>
                <!--footer-->
                <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <CardButton id="toggle" type="cancel" text="Got it !" on:click={() => toggleAlert()} />
                </div>
            </div>
        </div>
    </div>
    <div class="opacity-25 fixed inset-0 z-40 bg-black" />
{/if}
