<script>
    import {navigate} from 'svelte-routing';
    import {storedUser, selectedBookingRequest, storedCompany, missingLinkId} from '../../store.js';
    import {getDateNow, prettyPrintNumber, getDateDiff, getDateNowDatetime, copyToClipboard, downloadFromUrl} from '../../functions.js';
    import {
        lastCommitMessageOfBookingRequest,
        postCommitMessage,
        postScriptBacklog,
        makeDeal,
        getBookingRequestByIdCached,
        getCampaignByIdCached,
        patchBookingRequest,
        uploadFile,
        deleteKey,
        deleteInventoryReservationByRollPosition,
        getInventoryReservationsOfBookingRequestCached,
        saveInventoryBooking,
        deleteInventoryReservationByBookingRequest,
        deleteInventoryBookingByBookingRequest,
        postAudioBacklog,
        postReportBacklog,
    } from '../../api/api';
    import {
        setAudio,
        setAudiobacklogMeta,
        setBookingRequestScript,
        setBookingRequestApproveScript,
        setBookingRequestState,
        resetBookingRequestState,
        setBookingRequestAudioEditor,
        setScriptbacklogMeta,
        setReportbacklogMeta,
        setBookingRequestReport,
    } from '../../stateSetter.js';
    import {Pulse} from 'svelte-loading-spinners';
    import CardDetailStatus from 'components/Cards/CardDetailStatus.svelte';
    import CardDetailBookingInformation from 'components/Cards/CardDetailBookingInformation.svelte';
    import CardDetailFileUpload from 'components/Cards/CardDetailFileUpload.svelte';
    import CardDetailSpotAudio from 'components/Cards/CardDetailSpotAudio.svelte';
    import CardDetailReport from 'components/Cards/CardDetailReport.svelte';
    import CardDetailBriefingInformation from 'components/Cards/CardDetailBriefingInformation.svelte';
    import CardDetailDealInfo from 'components/Cards/CardDetailDealInfo.svelte';
    import CardDetailAdServerSetup from 'components/Cards/CardDetailAdServerSetup.svelte';
    import CardCommitMessage from 'components/Cards/CardCommitMessage.svelte';
    import CardDetailLastCommit from 'components/Cards/CardDetailLastCommit.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';
    import {getText} from '../../language.svelte';

    export let id;
    export let bid;
    export let cardTitle;

    let campaign, bookingRequest, nextStep, date, responsable;
    $: bookingRequest, setNextAction();
    let adNotes = '';
    let adCreated = false;
    let script = '';
    let backgroundHighlight = 'bg-lightBlue-200';
    let originalFileName;

    let rollPositionPre = false;
    let rollPositionMid1 = false;
    let rollPositionMid2 = false;
    let rollPositionMid3 = false;
    let rollPositionMid4 = false;
    let rollPositionMid5 = false;
    let rollPositionPost = false;
    let rollPositionEdited = false;

    let rollPositionPreOriginal;
    let rollPositionMid1Original;
    let rollPositionMid2Original;
    let rollPositionMid3Original;
    let rollPositionMid4Original;
    let rollPositionMid5Original;
    let rollPositionPostOriginal;

    let lastCommitMessage;

    let requested = $selectedBookingRequest.requested_impressions;
    let offeredImpressions = $selectedBookingRequest.offered_impressions ? $selectedBookingRequest.offered_impressions : requested;

    let promise = collectData(id, bid);

    // missing link sonderlocke
    let missingLinkCase;

    // COLLECT DATA
    async function collectCampaignData(id) {
        try {
            return getCampaignByIdCached(id);
        } catch (e) {
            console.log(`Error ${e} occoured whilst collecting the campaign ${id}`);
        }
    }

    async function collectBookingRequestData(id) {
        try {
            return getBookingRequestByIdCached(id);
        } catch (e) {
            console.log(`Error ${e} occoured whilst collecting the booking requests for podcast ${id}`);
        }
    }

    async function collectData(cid, bid) {
        campaign = await collectCampaignData(cid);
        missingLinkCase = campaign.company_id == $missingLinkId ? true : false;

        rollPositionPreOriginal = campaign.roll_position_pre;
        rollPositionMid1Original = campaign.roll_position_mid_1;
        rollPositionMid2Original = campaign.roll_position_mid_2;
        rollPositionMid3Original = campaign.roll_position_mid_3;
        rollPositionMid4Original = campaign.roll_position_mid_4;
        rollPositionMid5Original = campaign.roll_position_mid_5;
        rollPositionPostOriginal = campaign.roll_position_post;

        if (campaign.type == 'hostread') {
            cardTitle = 'Hostread Request Details';
        } else if (campaign.type == 'spot') {
            cardTitle = 'Spot Request Details';
        } else if (campaign.type == 'crosspromo') {
            cardTitle = 'Cross Promotion Request Details';
        }
        bookingRequest = await collectBookingRequestData(bid);
        if (campaign.type == 'hostread') {
            script = bookingRequest.script;
        }
        setNextAction();

        lastCommitMessage = await getLastCM(bookingRequest);

        return bookingRequest;
    }

    async function getLastCM(bookingRequest) {
        if (bookingRequest) {
            try {
                return lastCommitMessageOfBookingRequest(bookingRequest.id)
                    .then((response) => response.json())
                    .then((data) => {
                        return data;
                    });
            } catch (e) {
                console.log(`Error ${e} occoured whilst retreiving the last commit message for ${bookingRequest}`);
            }
        }
    }

    // BUTTONS
    function exit() {
        navigate(`/podcast`);
    }

    async function save() {
        // TODO check on script injection

        // check ad settings
        if (adCreated) {
            bookingRequest.ad_created = true;
            bookingRequest.ad_notes = adNotes;
        }

        // get new impression value
        let newImpressions = document.getElementById('grid-offered-impressions').value;
        if (newImpressions) {
            bookingRequest.offered_impressions = newImpressions;
            bookingRequest.booking_changes_requested = false;
        }

        // get new roll positions
        if (rollPositionEdited) {
            bookingRequest.booking_changes_requested = false;

            bookingRequest.roll_position_pre = rollPositionPre;
            bookingRequest.roll_position_mid_1 = rollPositionMid1;
            bookingRequest.roll_position_mid_2 = rollPositionMid2;
            bookingRequest.roll_position_mid_3 = rollPositionMid3;
            bookingRequest.roll_position_mid_4 = rollPositionMid4;
            bookingRequest.roll_position_mid_5 = rollPositionMid5;
            bookingRequest.roll_position_post = rollPositionPost;
        }

        try {
            let newbR = await patchBookingRequest(bookingRequest.id, bookingRequest);
            $selectedBookingRequest = newbR;
            snackbarMsg = getText('ui_messages.snackbars.podcast.snackbar_booking_request_updated');
            snackbarOpen = true;
            edited = false;

            commitMessage ? (commitMessage = commitMessage) : (commitMessage = getText('commit_messages.default.booking_request.updated'));

            await saveCommitMessage(newbR.id, actionCodeUpdated).catch((e) => {
                console.log(`Error ${e} occoured whilst saving the commit for booking request ${newbR.id}`);
            });
        } catch (e) {
            console.log(`Error ${e} occoured whilst patching the booking requests  ${bookingRequest}`);
        }

        if (waiting) {
            navigate('/podcast');
        }
    }

    function checkRolePosition() {
        // roll position
        if (!rollPositionPre && !rollPositionMid1 && !rollPositionMid2 && !rollPositionMid3 && !rollPositionMid4 && !rollPositionMid5 && !rollPositionPost) {
            window.alert(getText('ui_messages.alerts.podcast.alert_role_position'));
            return;
        } else return true;
    }

    async function updateScript() {
        bookingRequest = setBookingRequestScript(bookingRequest, script, false, true);

        // bookingRequest.script = script;
        // bookingRequest.script_updated_podcast = true;
        // bookingRequest.script_updated_promoter = false;
        // bookingRequest.script_updated_date = getDateNow();

        // catch feedback options
        if (campaign.feedback_loop_none) {
            bookingRequest = setBookingRequestApproveScript(bookingRequest);
        }

        // patch bR
        try {
            await patchBookingRequest(bookingRequest.id, bookingRequest);
        } catch (e) {
            console.log(`Error ${e} occoured whilst saving the script changes for ${bookingRequest} with script ${script}`);
        }

        // save commit message
        commitMessage ? (commitMessage = commitMessage) : (commitMessage = getText('commit_messages.default.booking_request.script_updated'));
        await saveCommitMessage(bookingRequest.id, 5).catch((e) => {
            console.log('something went wrong whilst saving the commit for booking request!', bookingRequest);
        });

        try {
            await postScriptBacklog(setScriptbacklogMeta(script, campaign.id, bookingRequest));
        } catch (e) {
            console.log(`Error ${e} occoured whilst updating the script backlog for ${bookingRequest.id} with ${script}`);
        }

        if (waiting) {
            navigate('/podcast');
        }
    }

    async function handleInventory(patchedBR, bookingRequest) {
        try {
            // delete inventoryReservations
            !patchedBR.roll_position_pre && rollPositionPreOriginal
                ? await deleteInventoryReservationByRollPosition(bookingRequest.id, 1)
                : `Not deleting inventory request for ${bookingRequest.id} and position 1`;
            !patchedBR.roll_position_mid_1 && rollPositionMid1Original
                ? await deleteInventoryReservationByRollPosition(bookingRequest.id, 2)
                : `Not deleting inventory request for ${bookingRequest.id} and position 2`;
            !patchedBR.roll_position_mid_2 && rollPositionMid2Original
                ? await deleteInventoryReservationByRollPosition(bookingRequest.id, 3)
                : `Not deleting inventory request for ${bookingRequest.id} and position 3`;
            !patchedBR.roll_position_mid_3 && rollPositionMid3Original
                ? await deleteInventoryReservationByRollPosition(bookingRequest.id, 4)
                : `Not deleting inventory request for ${bookingRequest.id} and position 4`;
            !patchedBR.roll_position_mid_4 && rollPositionMid4Original
                ? await deleteInventoryReservationByRollPosition(bookingRequest.id, 5)
                : `Not deleting inventory request for ${bookingRequest.id} and position 5`;
            !patchedBR.roll_position_mid_5 && rollPositionMid5Original
                ? await deleteInventoryReservationByRollPosition(bookingRequest.id, 6)
                : `Not deleting inventory request for ${bookingRequest.id} and position 6`;
            !patchedBR.roll_position_post && rollPositionPostOriginal
                ? await deleteInventoryReservationByRollPosition(bookingRequest.id, 7)
                : `Not deleting inventory request for ${bookingRequest.id} and position 7`;

            // get reservation thats left
            let data = await getInventoryReservationsOfBookingRequestCached(bookingRequest.id);

            // save inventoryBooking of selected rollPosition
            patchedBR.roll_position_pre
                ? await saveInventoryBooking(bookingRequest.campaign_id, bookingRequest.id, bookingRequest.podcast_id, data[0])
                : `Not saving inventory booking for ${bookingRequest.id} and position 1`;
            patchedBR.roll_position_mid_1
                ? await saveInventoryBooking(bookingRequest.campaign_id, bookingRequest.id, bookingRequest.podcast_id, data[0])
                : `Not saving inventory booking for ${bookingRequest.id} and position 2`;
            patchedBR.roll_position_mid_2
                ? await saveInventoryBooking(bookingRequest.campaign_id, bookingRequest.id, bookingRequest.podcast_id, data[0])
                : `Not saving inventory booking for ${bookingRequest.id} and position 3`;
            patchedBR.roll_position_mid_3
                ? await saveInventoryBooking(bookingRequest.campaign_id, bookingRequest.id, bookingRequest.podcast_id, data[0])
                : `Not saving inventory booking for ${bookingRequest.id} and position 4`;
            patchedBR.roll_position_post
                ? await saveInventoryBooking(bookingRequest.campaign_id, bookingRequest.id, bookingRequest.podcast_id, data[0])
                : `Not saving inventory booking for ${bookingRequest.id} and position 5`;

            // delete remaining inventoryReservations
            patchedBR.roll_position_pre && rollPositionPreOriginal
                ? await deleteInventoryReservationByRollPosition(bookingRequest.id, 1)
                : `Not deleting inventory reservation for ${bookingRequest.id} and position 1`;
            patchedBR.roll_position_mid_1 && rollPositionMid1Original
                ? await deleteInventoryReservationByRollPosition(bookingRequest.id, 2)
                : `Not deleting inventory reservation for ${bookingRequest.id} and position 2`;
            patchedBR.roll_position_mid_2 && rollPositionMid2Original
                ? await deleteInventoryReservationByRollPosition(bookingRequest.id, 3)
                : `Not deleting inventory reservation for ${bookingRequest.id} and position 3`;
            patchedBR.roll_position_mid_3 && rollPositionMid3Original
                ? await deleteInventoryReservationByRollPosition(bookingRequest.id, 4)
                : `Not deleting inventory reservation for ${bookingRequest.id} and position 4`;
            patchedBR.roll_position_mid_4 && rollPositionMid4Original
                ? await deleteInventoryReservationByRollPosition(bookingRequest.id, 5)
                : `Not deleting inventory reservation for ${bookingRequest.id} and position 5`;
            patchedBR.roll_position_mid_5 && rollPositionMid5Original
                ? await deleteInventoryReservationByRollPosition(bookingRequest.id, 6)
                : `Not deleting inventory reservation for ${bookingRequest.id} and position 6`;
            patchedBR.roll_position_post && rollPositionPostOriginal
                ? await deleteInventoryReservationByRollPosition(bookingRequest.id, 7)
                : `Not deleting inventory reservation for ${bookingRequest.id} and position 7`;
        } catch (e) {
            console.log(`Error ${e} occoured whilst patching the inventory reservations for ${bookingRequest.id}`);
        }
    }

    async function acceptPodcastReservationRequest() {
        // showCommitMessage = false;
        justAccepted = true;
        let patchedBR;

        // roll position
        if (!rollPositionPre && !rollPositionMid1 && !rollPositionMid2 && !rollPositionMid3 && !rollPositionMid4 && !rollPositionMid5 && !rollPositionPost) {
            window.alert(getText('ui_messages.alerts.podcast.alert_role_position'));
            return;
        }

        // set roll positions
        bookingRequest.roll_position_pre = rollPositionPre;
        bookingRequest.roll_position_mid_1 = rollPositionMid1;
        bookingRequest.roll_position_mid_2 = rollPositionMid2;
        bookingRequest.roll_position_mid_3 = rollPositionMid3;
        bookingRequest.roll_position_mid_4 = rollPositionMid4;
        bookingRequest.roll_position_mid_5 = rollPositionMid5;
        bookingRequest.roll_position_post = rollPositionPost;

        // set offered impressions
        if (offeredImpressions == 0) {
            window.alert(getText('ui_messages.alerts.podcast.alert_zero_impressions'));
            return;
        } else {
            // set offered impressions
            bookingRequest.offered_impressions = offeredImpressions;
        }

        // set states - 3 for impression/roll position differences / 5 for no indifferences
        if (
            bookingRequest.offered_impressions != bookingRequest.requested_impressions ||
            campaign.roll_position_pre != bookingRequest.roll_position_pre ||
            campaign.roll_position_mid_1 != bookingRequest.roll_position_mid_1 ||
            campaign.roll_position_mid_2 != bookingRequest.roll_position_mid_2 ||
            campaign.roll_position_mid_3 != bookingRequest.roll_position_mid_3 ||
            campaign.roll_position_mid_4 != bookingRequest.roll_position_mid_4 ||
            campaign.roll_position_mid_5 != bookingRequest.roll_position_mid_5 ||
            campaign.roll_position_post != bookingRequest.roll_position_post
        ) {
            bookingRequest.state = 3;
            bookingRequest.booking_changes_requested = false;
        } else {
            bookingRequest.state = 5;
        }

        bookingRequest.accepted = true;
        bookingRequest.accepted_date = getDateNow();

        try {
            patchedBR = await patchBookingRequest(bookingRequest.id, bookingRequest);
        } catch (e) {
            console.log(`Error ${e} occoured whilst accepting the reservation request for ${bookingRequest}`);
        }

        if (patchedBR) {
            handleInventory(patchedBR, bookingRequest);
        }

        commitMessage ? (commitMessage = commitMessage) : (commitMessage = getText('commit_messages.default.booking_request.accepted'));

        await saveCommitMessage(bookingRequest.id, 3).catch((e) => {
            console.log(`Error ${e} occoured whilst saving the commit for booking request ${bookingRequest}`);
        });

        if (waiting) {
            navigate('/podcast');
        }
    }

    async function acceptPodcastBookingRequest() {
        // showCommitMessage = false;
        justAccepted = true;
        let patchedBR;

        // roll position
        if (!rollPositionPre && !rollPositionMid1 && !rollPositionMid2 && !rollPositionMid3 && !rollPositionMid4 && !rollPositionMid5 && !rollPositionPost) {
            window.alert(getText('ui_messages.alerts.podcast.alert_role_position'));
            return;
        }

        // set roll positions
        bookingRequest.roll_position_pre = rollPositionPre;
        bookingRequest.roll_position_mid_1 = rollPositionMid1;
        bookingRequest.roll_position_mid_2 = rollPositionMid2;
        bookingRequest.roll_position_mid_3 = rollPositionMid3;
        bookingRequest.roll_position_mid_4 = rollPositionMid4;
        bookingRequest.roll_position_mid_5 = rollPositionMid5;
        bookingRequest.roll_position_post = rollPositionPost;

        // set offered impressions
        if (offeredImpressions == 0) {
            window.alert(getText('ui_messages.alerts.podcast.alert_zero_impressions'));
            return;
        } else {
            // set offered impressions
            bookingRequest.offered_impressions = offeredImpressions;
        }

        // set states - 3 for impression/roll position differences / 5 for no indifferences
        if (
            bookingRequest.offered_impressions != bookingRequest.requested_impressions ||
            campaign.roll_position_pre != bookingRequest.roll_position_pre ||
            campaign.roll_position_mid_1 != bookingRequest.roll_position_mid_1 ||
            campaign.roll_position_mid_2 != bookingRequest.roll_position_mid_2 ||
            campaign.roll_position_mid_3 != bookingRequest.roll_position_mid_3 ||
            campaign.roll_position_post != bookingRequest.roll_position_post
        ) {
            bookingRequest.state = 3;
            bookingRequest.accepted = true;
            bookingRequest.accepted_date = getDateNow();
        } else if (campaign.type == 'hostread') {
            bookingRequest.state = 5;
            bookingRequest.agreed = true;
            bookingRequest.agreed_date = getDateNow();
        } else if (campaign.type == 'spot' || campaign.type == 'crosspromo') {
            bookingRequest.state = 8;
            bookingRequest.accepted = true;
            bookingRequest.accepted_date = getDateNow();
            bookingRequest.agreed = true;
            bookingRequest.agreed_date = getDateNow();
            bookingRequest.approved = true;
            bookingRequest.approved_date = getDateNow();
        }

        if (campaign.type == 'hostread') {
            // do not show impression update button
            edited = false;

            // check if file is selected
            if (file) {
                // upload audio + save
                await submitForm('audio', true);
            }

            // if no file is selected
            if (!file && bookingRequest.script_approved_date && campaign.feedback_loop_script) {
                window.alert(getText('ui_messages.alerts.podcast.alert_no_audio_hostread'));
            } else {
                try {
                    patchedBR = await patchBookingRequest(bookingRequest.id, bookingRequest);
                } catch (e) {
                    console.log(`Error ${e} occoured whilst accepting the booking request for ${bookingRequest}`);
                }
            }
        } else if (campaign.type == 'spot' || campaign.type == 'crosspromo') {
            // make deal
            try {
                let deal = await makeDeal(bookingRequest.id).then((response) => response.json());
                bookingRequest.deal_id = deal[0];
                bookingRequest.vast_url = deal[1];
            } catch (e) {
                console.log(`Error ${e} occoured whilst closing the deal !`);
                return;
            }

            // patch BR
            try {
                patchedBR = await patchBookingRequest(bookingRequest.id, bookingRequest);
                $selectedBookingRequest = bookingRequest;
                snackbarMsg = getText('ui_messages.snackbars.podcast.snackbar_booking_request_create_ad');
                snackbarOpen = true;
                timeout = 3000;
            } catch (e) {
                console.log(`Error ${e} occoured whilst accepting the reservation request for ${bookingRequest}`);
            }
        }

        // handle inventory
        if (patchedBR) {
            handleInventory(patchedBR, bookingRequest);
        }

        commitMessage ? (commitMessage = commitMessage) : (commitMessage = getText('commit_messages.default.booking_request.accepted'));
        await saveCommitMessage(bookingRequest.id, 3).catch((e) => {
            console.log(`Error ${e} occoured whilst saving the commit for booking request ${bookingRequest}`);
        });

        if (waiting) {
            navigate('/podcast');
        }
    }

    async function rejectPodcastBookingRequest() {
        bookingRequest.state = 4;
        bookingRequest.accepted = false;
        bookingRequest.rejected_date = getDateNow();

        // update to API
        try {
            await patchBookingRequest(bookingRequest.id, bookingRequest);
            $selectedBookingRequest = bookingRequest;
            commitMessage ? (commitMessage = commitMessage) : (commitMessage = getText('commit_messages.default.booking_request.rejected'));
            await saveCommitMessage(bookingRequest.id, 2).catch((e) => {
                console.log(`Error ${e} occoured whilst saving the commit for booking request ${bookingRequest}`);
            });
            navigate(`/podcast`);
        } catch (e) {
            console.log(`Error ${e} occoured whilst rejecting the reservation request for ${bookingRequest}`);
        }

        // delete inventoryReservation & inventoryBooking
        try {
            await deleteInventoryReservationByBookingRequest(bookingRequest.id);
        } catch (e) {
            console.log(`Error ${e} occoured whilst deleting the inventory reservations for booking request ${bookingRequest}`);
        }
        try {
            await deleteInventoryBookingByBookingRequest(bookingRequest.id);
        } catch (e) {
            console.log(`Error ${e} occoured whilst deleting the inventory bookings for booking request ${bookingRequest}`);
        }
    }

    // FILE UPLOADs
    // accepts mp3 and pdf
    let file;
    let fileName;
    let duration_ms;
    let formData = new FormData();

    // get duration of mp3 url
    async function getURLDuration(url) {
        var au = document.createElement('audio');
        au.src = url;

        // Once the metadata has been loaded grep duration in ms
        au.addEventListener(
            'loadedmetadata',
            function () {
                duration_ms = au.duration * 1000;
                bookingRequest.audiofile_duration_ms = parseInt(duration_ms);
                patchBookingRequest(bookingRequest.id, bookingRequest);
                $selectedBookingRequest = bookingRequest;
            },
            false,
        );
    }

    function updateAdCreated() {
        adCreated = !adCreated;
        edited = !edited;
        justAccepted = false;
    }

    function checkStatus() {
        if (adNotes !== bookingRequest.ad_notes) {
            adCreated = true;
            edited = true;
        }
        if (adNotes == bookingRequest.ad_notes) {
            edited = false;
        }
    }

    async function submitForm(type, initialAccept) {
        event.preventDefault();

        fileName = file[0].name;
        fileName = fileName.replaceAll(' ', '-');

        originalFileName = fileName;
        fileName = crypto.randomUUID() + '.' + fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);

        formData.append('file', file[0]);
        formData.append('filename', fileName);

        if (type == 'audio') {
            // TODO accept other format as well?
            if (!fileName.endsWith('mp3')) {
                window.alert(getText('ui_messages.alerts.podcast.alert_mp3_check'));
                return;
            }

            // get duration if audio upload

            disableButtons();
            await uploadAudio(initialAccept)
                .then(() => {
                    getURLDuration(`https://asap-dispo.s3.amazonaws.com/audio/${fileName}`).then(() => {
                        setTimeout(() => {
                            hideUpdateButton();
                            enabledButtons();
                        }, 1500);
                    });
                })
                .catch(() => {
                    enabledButtons();
                });
        }

        if (type == 'report') {
            uploadPDF(bookingRequest);
        }
    }

    function disableButtons() {
        document.getElementById('upload-pulse').style.display = 'flex';
        Array.from(document.querySelectorAll('button')).forEach((elem) => {
            elem.setAttribute('disabled', '');
            elem.style.filter = 'grayscale(1)';
        });
    }

    function enabledButtons() {
        if (document.getElementById('upload-pulse')) {
            document.getElementById('upload-pulse').style.display = 'none';
        }
        Array.from(document.querySelectorAll('button')).forEach((elem) => {
            elem.removeAttribute('disabled');
            elem.style.filter = 'grayscale(0)';
        });
    }

    let showUpdateButton = false;
    function hideUpdateButton() {
        let div = document.getElementById('grid-update-audio-button');
        if (div) {
            div.style.display = 'none';
        }
    }

    async function uploadAudio(initialAccept) {
        // save audiofile to s3
        let audioSuccess;
        try {
            audioSuccess = await uploadFile(formData, 'audio').then((response) => {
                bookingRequest = setAudio(bookingRequest, `https://asap-dispo.s3.amazonaws.com/audio/${fileName}`, parseInt(duration_ms), originalFileName);
                bookingRequest = setBookingRequestAudioEditor(bookingRequest);
                if (campaign.feedback_loop_audio && bookingRequest.state < 8) {
                    bookingRequest = setBookingRequestState(bookingRequest, 7);
                }
                // catch re-uploads of audio file after approval
                if (campaign.feedback_loop_audio && bookingRequest.state == 8) {
                    // delete old VAST to prevent wrong deliveries
                    let key = 'vast/' + bookingRequest.deal_id + '.xml';
                    deleteKey(key);
                    bookingRequest = resetBookingRequestState(bookingRequest, 7);
                } else if (!campaign.feedback_loop_audio || campaign.feedback_loop_none) {
                    bookingRequest = setBookingRequestState(bookingRequest, 8);
                }
                return response;
            });
        } catch (e) {
            window.alert(getText('ui_messages.alerts.podcast.alert_audio_upload', response.status));
        }

        hideUpdateButton();

        if (audioSuccess.status == 200) {
            try {
                await patchBookingRequest(bookingRequest.id, bookingRequest);
                $selectedBookingRequest = bookingRequest;
                // snackbar
                if (initialAccept) {
                    snackbarMsg = getText('ui_messages.snackbars.podcast.snackbar_booking_request_accepted_hostread');
                    snackbarOpen = true;
                } else {
                    snackbarMsg = getText('ui_messages.snackbars.podcast.snackbar_booking_request_updated_audio');
                    snackbarOpen = true;
                }

                // make deal - only if feedback_loop_audio is not activated
                if (!campaign.feedback_loop_audio || campaign.feedback_loop_none) {
                    try {
                        let deal = await makeDeal(bookingRequest.id).then((response) => response.json());
                        bookingRequest.deal_id = deal[0];
                        bookingRequest.vast_url = deal[1];
                    } catch (e) {
                        console.log(`Error ${e} occoured whilst closing the deal !`);
                        return;
                    }
                }

                commitMessage = getText('commit_messages.default.booking_request.audio_uploaded');
                await saveCommitMessage(bookingRequest.id, 11).catch((e) => {
                    console.log(`Error ${e} occoured whilst saving the commit for booking request ${bookingRequest}`);
                });

                if (waiting) {
                    navigate('/podcast');
                }
            } catch (e) {
                console.log(`Error ${e} occoured whilst patching booking request ${bookingRequest}`);
            }

            try {
                await postAudioBacklog(setAudiobacklogMeta(bookingRequest.audiofile_url, bookingRequest.campaign_id, bookingRequest));
            } catch (e) {
                console.log(`Error ${e} occoured whilst writing to the audio backlog for booking request ${bookingRequest}`);
            }
        }
    }

    async function uploadPDF(bookingRequest) {
        let pdfSuccess;
        try {
            pdfSuccess = await uploadFile(formData, 'report').then((response) => {
                bookingRequest = setBookingRequestState(bookingRequest, 11);
                // bookingRequest.state = 11;
                // bookingRequest.report_uploaded_date = getDateNow();

                bookingRequest = setBookingRequestReport(bookingRequest, `https://asap-dispo.s3.amazonaws.com/report/${fileName}`, originalFileName);
                // bookingRequest['report'] = `https://asap-dispo.s3.amazonaws.com/report/${fileName}`;
                // bookingRequest.report_name_original = originalFileName;

                return response;
            });
        } catch (e) {
            console.log(`Error ${e} occoured whilst uploading the pdf for booking request ${bookingRequest}`);
            window.alert(getText('ui_messages.alerts.podcast.alert_report_upload'));
        }

        if (pdfSuccess.status == 200) {
            try {
                await patchBookingRequest(bookingRequest.id, bookingRequest);
                $selectedBookingRequest = bookingRequest;

                commitMessage = getText('commit_messages.default.booking_request.report_uploaded');
                await saveCommitMessage(bookingRequest.id, 14).catch((e) => {
                    console.log(`Error ${e} occoured whilst saving the commit for booking request ${bookingRequest}`);
                });

                navigate('/podcast');
            } catch (e) {
                console.log(`Error (e) occoured whilst patching booking request ${bookingRequest}`);
            }

            try {
                await postReportBacklog(setReportbacklogMeta(bookingRequest.report, bookingRequest.campaign_id, bookingRequest));
            } catch (e) {
                console.log(`Error ${e} occoured whilst writing to the report backlog for booking request ${bookingRequest}`);
            }
        }
    }

    let highlight;
    let waiting = false;
    async function setNextAction() {
        if (bookingRequest) {
            if (bookingRequest.ad_created) {
                adCreated = true;
                adNotes = bookingRequest.ad_notes;
            }

            if (bookingRequest.state == 2) {
                if (campaign.type == 'hostread') {
                    nextStep = getText('podcast.booking_request_details.next_step.state_2.entry_1');
                    date = campaign.acceptance_deadline;
                    responsable = $storedCompany.name;
                } else if (campaign.type == 'spot') {
                    nextStep = getText('podcast.booking_request_details.next_step.state_2.entry_2');
                    date = campaign.acceptance_deadline;
                    responsable = $storedCompany.name;
                } else if (campaign.type == 'crosspromo') {
                    nextStep = getText('podcast.booking_request_details.next_step.state_2.entry_3');
                    date = campaign.acceptance_deadline;
                    responsable = $storedCompany.name;
                }
                highlight = 'Booking Information';
            }

            if (bookingRequest.state == 3) {
                if (!bookingRequest.booking_changes_requested) {
                    nextStep = getText('podcast.booking_request_details.next_step.state_3.entry_1');
                    date = '';
                    responsable = getText('podcast.booking_request_details.next_step.label_1');
                    highlight = '';
                    waiting = true;
                }
                if (bookingRequest.booking_changes_requested) {
                    nextStep = getText('podcast.booking_request_details.next_step.state_3.entry_2');
                    date = '';
                    responsable = $storedCompany.name;
                    highlight = 'Booking Information';
                }
            }

            if (bookingRequest.state == 4) {
                if (campaign.type == 'hostread') {
                    nextStep = getText('podcast.booking_request_details.next_step.state_4.entry_1');
                    date = bookingRequest.rejected_date;
                    responsable = $storedCompany.name;
                } else if (campaign.type == 'spot') {
                    nextStep = getText('podcast.booking_request_details.next_step.state_4.entry_2');
                    date = bookingRequest.rejected_date;
                    responsable = $storedCompany.name;
                } else if (campaign.type == 'crosspromo') {
                    nextStep = getText('podcast.booking_request_details.next_step.state_4.entry_3');
                    date = bookingRequest.rejected_date;
                    responsable = $storedCompany.name;
                }
                highlight = '';
            }

            if (bookingRequest.state == 5) {
                // HOSTREAD
                if (campaign.type == 'hostread') {
                    if (!campaign.briefing_completed) {
                        nextStep = getText('podcast.booking_request_details.next_step.state_5.entry_1');
                        date = '';
                        responsable = getText('podcast.booking_request_details.next_step.label_1');
                        highlight = '';
                        waiting = true;
                    }

                    if (campaign.briefing_completed && campaign.feedback_loop_script && bookingRequest.script_updated_promoter && !bookingRequest.script_approved_date) {
                        nextStep = getText('podcast.booking_request_details.next_step.state_5.entry_2');
                        date = '';
                        responsable = $storedCompany.name;
                        highlight = 'Briefing Information';
                    }

                    if (campaign.briefing_completed && campaign.feedback_loop_script && bookingRequest.script_updated_podcast && !bookingRequest.script_approved_date) {
                        nextStep = getText('podcast.booking_request_details.next_step.state_5.entry_3');
                        date = '';
                        responsable = getText('podcast.booking_request_details.next_step.label_1');
                        highlight = '';
                        waiting = true;
                    }

                    if (
                        campaign.briefing_completed &&
                        campaign.feedback_loop_audio &&
                        bookingRequest.audiofile_url &&
                        !bookingRequest.audiofile_approved_date &&
                        bookingRequest.booking_changes_requested
                    ) {
                        nextStep = getText('podcast.booking_request_details.next_step.state_5.entry_4');
                        date = '';
                        responsable = $storedCompany.name;
                        highlight = 'File Upload';
                    }

                    if (
                        campaign.briefing_completed &&
                        campaign.feedback_loop_audio &&
                        bookingRequest.audiofile_url &&
                        !bookingRequest.audiofile_approved_date &&
                        !bookingRequest.booking_changes_requested
                    ) {
                        nextStep = getText('podcast.booking_request_details.next_step.state_5.entry_5');
                        date = '';
                        responsable = getText('podcast.booking_request_details.next_step.label_1');
                        highlight = '';
                        waiting = true;
                    }

                    if (campaign.briefing_completed && bookingRequest.script_approved_date && !bookingRequest.audiofile_url) {
                        nextStep = getText('podcast.booking_request_details.next_step.state_5.entry_6');
                        date = campaign.upload_deadline;
                        responsable = $storedCompany.name;
                        highlight = 'File Upload';
                    }
                }

                // SPOTS + CROSS PROMOS
                if ((campaign.type == 'spot' || campaign.type == 'crosspromo') && !campaign.audiofile_url) {
                    nextStep = getText('podcast.booking_request_details.next_step.state_5.entry_7');
                    date = '';
                    responsable = getText('podcast.booking_request_details.next_step.label_1');
                    highlight = '';
                    waiting = true;
                }
            }

            if (bookingRequest.state == 6) {
                if (campaign.type == 'hostread') {
                    nextStep = getText('podcast.booking_request_details.next_step.state_6.entry_1');
                    date = bookingRequest.cancelled_date;
                    responsable = getText('podcast.booking_request_details.next_step.label_1');
                } else if (campaign.type == 'spot') {
                    nextStep = getText('podcast.booking_request_details.next_step.state_6.entry_2');
                    date = bookingRequest.cancelled_date;
                    responsable = getText('podcast.booking_request_details.next_step.label_1');
                } else if (campaign.type == 'crosspromo') {
                    nextStep = getText('podcast.booking_request_details.next_step.state_6.entry_3');
                    date = bookingRequest.cancelled_date;
                    responsable = getText('podcast.booking_request_details.next_step.label_1');
                }
                highlight = '';
            }

            if (bookingRequest.state == 7) {
                if (campaign.type == 'hostread' && campaign.feedback_loop_audio) {
                    nextStep = getText('podcast.booking_request_details.next_step.state_7.entry_1');
                    date = campaign.start_date;
                    responsable = getText('podcast.booking_request_details.next_step.label_1');
                    highlight = 'Ad Server Setup';
                    waiting = true;
                }
            }

            if (bookingRequest.state == 8) {
                // missing link sonderlocke
                if (missingLinkCase) {
                    nextStep = getText('podcast.booking_request_details.next_step.state_8.entry_2', getDateDiff(getDateNowDatetime(), campaign.start_date));
                    date = campaign.start_date;
                    responsable = '';
                    highlight = '';
                    waiting = false;
                }

                if (!bookingRequest.ad_created && !missingLinkCase) {
                    nextStep = getText('podcast.booking_request_details.next_step.state_8.entry_1');
                    date = campaign.start_date;
                    responsable = '';
                    highlight = 'Ad Server Setup';
                }

                if (bookingRequest.ad_created && !missingLinkCase) {
                    nextStep = getText('podcast.booking_request_details.next_step.state_8.entry_2', getDateDiff(getDateNowDatetime(), campaign.start_date));
                    date = campaign.start_date;
                    responsable = '';
                    highlight = '';
                    waiting = true;
                }
            }

            if (bookingRequest.state == 9) {
                nextStep = getText('podcast.booking_request_details.next_step.state_9.entry_1', getDateDiff(getDateNowDatetime(), campaign.end_date));
                date = campaign.end_date;
                responsable = '';
                highlight = '';
                waiting = true;
            }

            if (bookingRequest.state == 10) {
                nextStep = getText('podcast.booking_request_details.next_step.state_10.entry_1');
                date = '';
                responsable = $storedCompany.name;
                highlight = 'Report';
            }

            if (bookingRequest.state == 11) {
                nextStep = getText('podcast.booking_request_details.next_step.state_11.entry_1');
                date = '';
                responsable = getText('podcast.booking_request_details.next_step.label_1');
                highlight = '';
                waiting = true;
            }

            if (bookingRequest.state == 12) {
                nextStep = getText('podcast.booking_request_details.next_step.state_12.entry_1');
                date = '';
                responsable = '';
                highlight = '';
            }

            if (bookingRequest.state == 13) {
                nextStep = getText('podcast.booking_request_details.next_step.state_13.entry_1');
                date = '';
                responsable = $storedCompany.name;
                highlight = '';
            }

            if (bookingRequest.state == 14) {
                nextStep = getText('podcast.booking_request_details.next_step.state_14.entry_1');
                date = '';
                responsable = '';
                highlight = '';
            }
        }
    }

    function showSave() {
        // check input
        if (offeredImpressions > bookingRequest.requested_impressions) {
            window.alert(getText('ui_messages.alerts.podcast.alert_maxed_impressions'));
            offeredImpressions = bookingRequest.requested_impressions;
        }

        if (offeredImpressions == bookingRequest.offered_impressions) {
            edited = false;
        } else {
            edited = true;
        }
    }

    function showRollSave() {
        // check input
        if (!rollPositionEdited) {
            edited = false;
        } else {
            edited = true;
        }
    }

    // async function approveScript() {
    //     bookingRequest = setBookingRequestApproveScript(bookingRequest);

    //     // patch bookingRequest
    //     let patched = await patchBookingRequest(bookingRequest.id, bookingRequest);

    //     if (patched.status != 200) {
    //         console.log('Something went wrong saving the script changes !');
    //     }

    //     // write to backlog
    //     await postScriptBacklog(setScriptbacklogMeta(bookingRequest.script, campaign.id, bookingRequest, true));
    // }

    $: edited = false;
    let justAccepted = false;
    let snackbarMsg;
    let snackbarOpen;
    let timeout = 0;

    // COMMIT MESSAGES
    function parentFunction() {
        if (acceptReservationClicked) {
            acceptPodcastReservationRequest();
        } else if (acceptBookingClicked) {
            acceptPodcastBookingRequest();
        } else if (rejectClicked) {
            rejectPodcastBookingRequest();
        } else if (updatedClicked) {
            save();
        }
    }

    async function saveCommitMessage(bookingRequestId, actionCode) {
        let data = {
            booking_request_id: bookingRequestId,
            action: actionCode,
            author: $storedUser.first_name,
            company_name: $storedCompany.name,
            company_type: $storedCompany.type,
            message: commitMessage,
            sender: false,
        };

        try {
            await postCommitMessage(bookingRequestId, data);
            return true;
        } catch (e) {
            console.log(`An error occoured posting the message ${data.message} with error ${e}`);
            return false;
        }
    }

    function setAction(thisAction) {
        action = thisAction;
    }

    function triggerCommitMessagePopUp() {
        showCommitMessage = true;
    }

    let showCommitMessage = false;
    let commitMessage;
    let action = '';
    let continueProcess = false;
    let acceptReservationClicked = false;
    let acceptBookingClicked = false;
    let rejectClicked = false;
    let updatedClicked = false;
    let actionCodeUpdated = 17;

    let lastCommitMessagePromise = getLastCM();

    function acceptReservation() {
        if (checkRolePosition()) {
            setAction(getText('commit_messages.default.booking_request.accept_reservation'));
            acceptReservationClicked = true;
            triggerCommitMessagePopUp();
        }
    }

    function acceptRequest() {
        if (checkRolePosition()) {
            setAction(getText('commit_messages.default.booking_request.accept_request'));
            acceptBookingClicked = true;
            triggerCommitMessagePopUp();
        }
    }

    function rejectRequest() {
        setAction(getText('commit_messages.default.booking_request.reject'));
        rejectClicked = true;
        triggerCommitMessagePopUp();
    }

    function updateRequest16() {
        commitMessage = getText('commit_messages.default.booking_request.advertising_media_created');
        actionCodeUpdated = 16;
        save();
    }

    function updateRequest17() {
        setAction(getText('commit_messages.default.booking_request.updated'));
        updatedClicked = true;
        actionCodeUpdated = 17;
        triggerCommitMessagePopUp();
    }
</script>

{#if showCommitMessage}
    <CardCommitMessage {action} bind:commitMessage bind:continueProcess bind:showCommitMessage parentFunction={() => parentFunction()} />
{/if}

{#await promise}
    <div style="display: flex; justify-content: center">
        <Pulse size="60" color="#FF3E00" unit="px" duration="1s" />
    </div>
{:then data}
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-left flex justify-between">
                <h6 class="text-blueGray-700 text-xl font-bold">
                    <div>{campaign.title} -</div>
                    <div>{cardTitle}</div>
                </h6>

                <!-- BUTTONS -->
                <div class="text-center flex">
                    {#if bookingRequest.state == 2}
                        <div id="upload-pulse" style="display: none; justify-content: center; align-items: center; margin-right: 1em;">
                            <Pulse size="60" color="#FF3E00" unit="px" duration="1s" />
                        </div>
                        {#if (campaign.type == 'hostread' && !campaign.briefing_completed) || (campaign.type == 'spot' && !campaign.audiofile_url)}
                            <CardButton
                                id="accept"
                                type="go"
                                text={getText('podcast.booking_request_details.button_accept_reservation', prettyPrintNumber(offeredImpressions))}
                                on:click={() => acceptReservation()}
                            />
                        {:else if campaign.type == 'hostread' && campaign.briefing_completed}
                            <CardButton
                                id="accept"
                                type="go"
                                text={getText('podcast.booking_request_details.button_accept_hostread', prettyPrintNumber(offeredImpressions))}
                                on:click={() => acceptRequest()}
                            />
                        {:else if campaign.type == 'spot' && campaign.audiofile_url}
                            <CardButton
                                id="accept"
                                type="go"
                                text={getText('podcast.booking_request_details.button_accept_spot', prettyPrintNumber(offeredImpressions))}
                                on:click={() => acceptRequest()}
                            />
                        {:else if campaign.type == 'crosspromo'}
                            <CardButton
                                id="accept"
                                type="go"
                                text={getText('podcast.booking_request_details.button_accept_crosspromo', prettyPrintNumber(offeredImpressions))}
                                on:click={() => acceptRequest()}
                            />
                        {/if}
                        <CardButton id="reject" type="cancel" text={getText('podcast.booking_request_details.button_reject')} on:click={() => rejectRequest()} />
                    {/if}
                    {#if bookingRequest.state != 2 && edited && !justAccepted && !adCreated}
                        <CardButton
                            id="update"
                            type="go"
                            text={getText('podcast.booking_request_details.button_update')}
                            on:click={() => {
                                setAction(getText('commit_messages.default.booking_request.updated'));
                                updatedClicked = true;
                                actionCodeUpdated = 17;
                                triggerCommitMessagePopUp();
                            }}
                        />
                    {/if}
                    {#if bookingRequest.state != 2 && edited && !justAccepted && adCreated}
                        <CardButton
                            id="update"
                            type="go"
                            text={getText('podcast.booking_request_details.button_update')}
                            on:click={() => {
                                commitMessage = getText('commit_messages.default.booking_request.advertising_media_created');
                                actionCodeUpdated = 16;
                                save();
                            }}
                        />
                    {/if}
                    <CardButton id="exit" type="default" text={getText('podcast.booking_request_details.button_exit')} on:click={() => exit()} />
                </div>
            </div>
        </div>

        {#if lastCommitMessage && !commitMessage}
            {#if lastCommitMessage.action == 1 && lastCommitMessage.message && bookingRequest.state == 2}
                <CardDetailLastCommit {bookingRequest} {campaign} {lastCommitMessage} title={getText('podcast.booking_request_details.commit_messages.headline_1')} />
            {:else if lastCommitMessage.action == 4 && lastCommitMessage.message}
                <CardDetailLastCommit {bookingRequest} {campaign} {lastCommitMessage} title={getText('podcast.booking_request_details.commit_messages.headline_2')} />
            {:else if lastCommitMessage.action == 5 && lastCommitMessage.message && bookingRequest.script_updated_promoter}
                <CardDetailLastCommit {bookingRequest} {campaign} {lastCommitMessage} title={getText('podcast.booking_request_details.commit_messages.headline_3')} />
            {:else if lastCommitMessage.action == 8 && bookingRequest.script_updated_promoter}
                <CardDetailLastCommit {bookingRequest} {campaign} {lastCommitMessage} title={getText('podcast.booking_request_details.commit_messages.headline_4')} />
            {:else if lastCommitMessage.action == 10 && lastCommitMessage.message}
                <CardDetailLastCommit {bookingRequest} {campaign} {lastCommitMessage} title={getText('podcast.booking_request_details.commit_messages.headline_5')} />
            {:else if lastCommitMessage.action == 13 && lastCommitMessage.message}
                <CardDetailLastCommit {bookingRequest} {campaign} {lastCommitMessage} title={getText('podcast.booking_request_details.commit_messages.headline_6')} />
            {:else if lastCommitMessage.action == 15 && lastCommitMessage.message && bookingRequest.approved}
                <CardDetailLastCommit {bookingRequest} {campaign} {lastCommitMessage} title={getText('podcast.booking_request_details.commit_messages.headline_7')} />
            {:else}
                <CardDetailLastCommit {bookingRequest} {campaign} {lastCommitMessage} title={getText('podcast.booking_request_details.commit_messages.headline_8')} />
            {/if}
        {/if}

        {#if commitMessage && lastCommitMessage && commitMessage != lastCommitMessage}
            <CardDetailLastCommit {bookingRequest} {campaign} {commitMessage} title={getText('podcast.booking_request_details.commit_messages.headline_8')} />
        {/if}

        <CardDetailStatus {bookingRequest} {campaign} {nextStep} {responsable} {date} />

        {#if highlight == 'Booking Information'}
            <CardDetailBookingInformation
                {bookingRequest}
                {campaign}
                {requested}
                bind:offeredImpressions
                {showSave}
                {showRollSave}
                bind:rollPositionEdited
                backgroundColor={backgroundHighlight}
                bind:rollPositionPre
                bind:rollPositionMid1
                bind:rollPositionMid2
                bind:rollPositionMid3
                bind:rollPositionMid4
                bind:rollPositionMid5
                bind:rollPositionPost
            />
        {:else}
            <CardDetailBookingInformation {bookingRequest} {campaign} {requested} bind:offeredImpressions {showSave} />
        {/if}

        {#if highlight == 'Briefing Information'}
            <CardDetailBriefingInformation {bookingRequest} {campaign} bind:script {updateScript} {downloadFromUrl} backgroundColor={backgroundHighlight} />
        {:else}
            <CardDetailBriefingInformation {bookingRequest} {campaign} bind:script {updateScript} {downloadFromUrl} />
        {/if}

        {#if highlight == 'File Upload'}
            <CardDetailFileUpload {bookingRequest} {campaign} bind:file {showUpdateButton} {submitForm} {copyToClipboard} {downloadFromUrl} backgroundColor={backgroundHighlight} />
        {:else}
            <CardDetailFileUpload {bookingRequest} {campaign} bind:file {showUpdateButton} {submitForm} {copyToClipboard} {downloadFromUrl} />
        {/if}

        <CardDetailSpotAudio {campaign} {copyToClipboard} {downloadFromUrl} />

        {#if highlight == 'Ad Server Setup'}
            <CardDetailDealInfo {campaign} {bookingRequest} {copyToClipboard} backgroundColor={backgroundHighlight} />
            <CardDetailAdServerSetup {bookingRequest} {campaign} {adCreated} bind:adNotes {updateAdCreated} {checkStatus} backgroundColor={backgroundHighlight} />
        {:else}
            <CardDetailDealInfo {campaign} {bookingRequest} {copyToClipboard} />
            <CardDetailAdServerSetup {bookingRequest} {campaign} {adCreated} bind:adNotes {updateAdCreated} {checkStatus} />
        {/if}

        {#if !missingLinkCase}
            {#if highlight == 'Report'}
                <CardDetailReport {bookingRequest} bind:file {showUpdateButton} {submitForm} {copyToClipboard} {downloadFromUrl} backgroundColor={backgroundHighlight} />
            {:else}
                <CardDetailReport {bookingRequest} bind:file {showUpdateButton} {submitForm} {copyToClipboard} {downloadFromUrl} />
            {/if}
        {/if}
    </div>

    <!-- BUTTONS -->
    <div class="text-center flex justify-between">
        {#if bookingRequest.state == 2}
            <div class="text-center flex">
                <CardButton id="exit" type="default" text={getText('podcast.booking_request_details.button_exit')} on:click={() => exit()} />
                <CardButton id="reject" type="cancel" text={getText('podcast.booking_request_details.button_reject')} on:click={() => rejectRequest()} />
            </div>
            <div class="text-center flex">
                {#if (campaign.type == 'hostread' && !campaign.briefing_completed) || (campaign.type == 'spot' && !campaign.audiofile_url)}
                    <CardButton
                        id="accept"
                        type="go"
                        text={getText('podcast.booking_request_details.button_accept_reservation', prettyPrintNumber(offeredImpressions))}
                        on:click={() => acceptReservation()}
                    />
                {:else if campaign.type == 'hostread' && campaign.briefing_completed}
                    <CardButton
                        id="accept"
                        type="go"
                        text={getText('podcast.booking_request_details.button_accept_hostread', prettyPrintNumber(offeredImpressions))}
                        on:click={() => acceptRequest()}
                    />
                {:else if campaign.type == 'spot' && campaign.audiofile_url}
                    <CardButton id="accept" type="go" text={getText('podcast.booking_request_details.button_accept_spot', prettyPrintNumber(offeredImpressions))} on:click={() => acceptRequest()} />
                {:else if campaign.type == 'crosspromo'}
                    <CardButton
                        id="accept"
                        type="go"
                        text={getText('podcast.booking_request_details.button_accept_crosspromo', prettyPrintNumber(offeredImpressions))}
                        on:click={() => acceptRequest()}
                    />
                {/if}
            </div>
        {/if}
        {#if bookingRequest.state != 2 && edited && !justAccepted && !adCreated}
            <CardButton
                id="update"
                type="go"
                text={getText('podcast.booking_request_details.button_update')}
                on:click={() => {
                    setAction(getText('commit_messages.default.booking_request.updated'));
                    updatedClicked = true;
                    actionCodeUpdated = 17;
                    triggerCommitMessagePopUp();
                }}
            />
        {/if}
        {#if bookingRequest.state != 2 && edited && !justAccepted && adCreated}
            <CardButton
                id="update"
                type="go"
                text={getText('podcast.booking_request_details.button_update')}
                on:click={() => {
                    commitMessage = getText('commit_messages.default.booking_request.advertising_media_created');
                    actionCodeUpdated = 16;
                    save();
                }}
            />
        {/if}
    </div>
{/await}
