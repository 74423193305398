<script>
    import {navigate} from 'svelte-routing';

    import {createPodcast, getAllKeywords, createPodcastKeywordRelation} from '../../api/api';
    import {allCompanies, storedCompany, storedUser} from '../../store.js';
    import {getText} from '../../language.svelte';
    import CardTablePodcasts from 'components/Cards/CardTablePodcasts.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let cardTitle = getText('admin.podcasts.new_podcast.title');
    export let type;
    let name;
    let maxBookingVolume;
    let asapPodcastId;
    let summary;
    let description;
    let url;
    let previewImage;

    let companieNames = [];
    $allCompanies.forEach((item) => {
        if (item.type == 'podcast') {
            companieNames.push([item.name, item.id]);
        }
    });

    let keywordsPromise = getKeywords();
    let allKeywords;
    async function getKeywords() {
        try {
            return await getAllKeywords()
                .then((response) => response.json())
                .then((data) => {
                    let sorted = data.sort((a, b) => {
                        let fa = a.keyword.toLowerCase(),
                            fb = b.keyword.toLowerCase();

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    });
                    allKeywords = sorted;
                    return sorted;
                });
        } catch (e) {
            console.log(`Error ${e} occoured whilst loading all keywords !`);
        }
    }

    function collectKeywords() {
        let selector = "input:checked[type='checkbox'][data-id]";
        let inputs = Array.from(document.querySelectorAll(selector));
        let values = inputs.map((elem) => {
            return elem.getAttribute('data-id');
        });
        return values;
    }

    function checkRequired() {
        if (!name | !maxBookingVolume | !asapPodcastId) {
            window.alert(getText('ui_messages.alerts.general.alert_required_information'));
            return false;
        }
        if (!type && document.getElementById('company').value == 'select') {
            window.alert(getText('ui_messages.alerts.general.alert_required_information'));
            return false;
        }
        return true;
    }

    async function save() {
        if (checkRequired()) {
            let companyId;
            if (!type) {
                let selection = document.getElementById('company').value;
                companyId = parseInt(selection.split(',')[1]);
            } else {
                companyId = $storedUser.company_id;
            }

            let data = {
                name: name,
                max_booking_volume: maxBookingVolume,
                company_id: companyId,
                summary: summary,
                description: description,
                url: url,
                preview_image: previewImage,
                asap_podcast_id: asapPodcastId,
            };

            try {
                let newPodcast = await createPodcast(companyId, data);
                if (newPodcast) {
                    // collect keywords
                    let keys = collectKeywords();
                    console.log(keys);
                    for (let x of keys) {
                        // look up keyword id
                        let keyword = allKeywords.find((keyword) => {
                            if (keyword.keyword == x) {
                                return keyword;
                            }
                        });

                        let newData = {
                            podcast_id: newPodcast.id,
                            keyword_id: keyword.id,
                        };

                        try {
                            await createPodcastKeywordRelation(newData);
                        } catch (e) {
                            console.log(`Error ${e} occoured whilst creating a new keyword-podcast relation for ${newData}`);
                        }
                    }
                    if (!type) {
                        navigate('/admin', {replace: true});
                    } else {
                        navigate('/', {replace: true});
                    }
                }
            } catch (e) {
                console.log(`Error ${e} occoured whilst saving the new podcast ${data}`);
            }
        }
    }

    function cancel() {
        if (!type) {
            navigate('/admin', {replace: true});
        } else {
            navigate('/', {replace: true});
        }
    }
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
            <h6 class="text-blueGray-700 text-xl font-bold">{cardTitle}</h6>
            <div class="flex flex-wrap items-center">
                <CardButton classes="saveproceed" id="saveproceed" type="go" text={getText('admin.podcasts.new_podcast.button_save')} on:click={() => save()} />
                <CardButton classes="saveproceed" id="saveproceed" type="cancel" text={getText('admin.podcasts.new_podcast.button_cancel')} on:click={() => cancel()} />
            </div>
        </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form>
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                {getText('admin.podcasts.new_podcast.category_1.title')}
            </h6>

            <div class="flex flex-wrap">
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-title">
                            {getText('admin.podcasts.new_podcast.category_1.entry_1.title')}
                        </label>
                        <input
                            id="grid-title"
                            type="input"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            bind:value={name}
                        />
                    </div>
                </div>

                {#if !type}
                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-client">
                                {getText('admin.podcasts.new_podcast.category_1.entry_2.title')}
                            </label>

                            <select name="type" id="company">
                                <option value="select">
                                    {getText('admin.podcasts.new_podcast.category_1.entry_2.input_placeholder')}
                                </option>
                                {#each companieNames as company}
                                    <option value={company}>{company[0]}</option>
                                {/each}
                            </select>
                        </div>
                    </div>
                {/if}

                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-client">
                            {getText('admin.podcasts.new_podcast.category_1.entry_3.title')}
                        </label>
                        <input
                            id="grid-booking-volume"
                            type="number"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            bind:value={maxBookingVolume}
                        />
                    </div>
                </div>

                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-client">
                            {getText('admin.podcasts.new_podcast.category_1.entry_9.title')}
                        </label>
                        <input
                            id="grid-asap-podcast-id"
                            type="number"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            bind:value={asapPodcastId}
                        />
                    </div>
                </div>

                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-client">
                            {getText('admin.podcasts.new_podcast.category_1.entry_4.title')}
                        </label>
                        <input
                            id="grid-summary"
                            type="input"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            bind:value={summary}
                        />
                    </div>
                </div>

                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-client">
                            {getText('admin.podcasts.new_podcast.category_1.entry_5.title')}
                        </label>
                        <input
                            id="grid-description"
                            type="input"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            bind:value={description}
                        />
                    </div>
                </div>

                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-client">
                            {getText('admin.podcasts.new_podcast.category_1.entry_6.title')}
                        </label>
                        <input
                            id="grid-url"
                            type="input"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            bind:value={url}
                        />
                    </div>
                </div>

                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-client">
                            {getText('admin.podcasts.new_podcast.category_1.entry_7.title')}
                        </label>
                        <input
                            id="grid-preview-image"
                            type="input"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            bind:value={previewImage}
                        />
                    </div>
                </div>

                {#await keywordsPromise then data}
                    <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-client">
                                {getText('admin.podcasts.new_podcast.category_1.entry_8.title')}
                            </label>
                            {#each data as keyword}
                                <div>
                                    <input
                                        id="grid-select-booking-request-{keyword.keyword}"
                                        type="checkbox"
                                        data-id={keyword.keyword}
                                        class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                    />
                                    <span class="ml-2 text-sm font-semibold text-blueGray-600">{keyword.keyword}</span>
                                </div>
                            {/each}
                        </div>
                    </div>
                {/await}
            </div>
        </form>
    </div>
</div>

{#if $storedCompany.type == 'admin'}
    <CardTablePodcasts showNewButton={false} />
{/if}
