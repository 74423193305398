<script>
    import {alertSingleOpen} from '../../store.js';
    import {toggleButtonState} from '../../functions.js';

    function closeAlert() {
        $alertSingleOpen = false;
        toggleButtonState(idName, buttonState, msg);
    }

    export let alertMsg;
    export let idName;
    export let buttonState;
    export let msg;
</script>

{#if $alertSingleOpen}
    <!-- <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"> -->
    <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center flex">
        <div class="relative w-auto my-6 mx-auto max-w-4xl">
            <div v-if="alertSingleOpen" class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500">
                <span class="text-xl inline-block mr-5 align-middle">
                    <i class="fas fa-bell" />
                </span>
                <span class="inline-block align-middle mr-8">
                    <b class="capitalize" />
                    {alertMsg}
                </span>
                <button class="mr-1 absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none" on:click={closeAlert}>
                    <span>×</span>
                </button>
            </div>
        </div>
    </div>
{/if}
