<script>
    import {
        BADGE_BACKGROUND_WORDCOUNT,
        BUTTON_BACKGROUND_GO,
        BUTTON_ACTIVE_GO,
        BUTTON_ACTIVE_CANCEL,
        BUTTON_TEXT_COLOR_DEFAULT,
        BUTTON_BACKGROUND_CANCEL,
        BADGE_BACKGROUND_BRIEFING_KEYWORD_ADD,
        BADGE_ACTIVE_BRIEFING_KEYWORD_ADD,
        BADGE_BACKGROUND_BRIEFING_KEYWORD_REMOVE,
        BADGE_ACTIVE_BRIEFING_KEYWORD_REMOVE,
    } from '../../colors.js';
    import {
        postCommitMessage,
        postScriptBacklog,
        makeDeal,
        patchBookingRequest,
        getAllPodcastsOfCompanyCached,
        getCampaignByIdCached,
        uploadFile,
        patchCampaign,
        getBookingRequestsByCampaignIdCached,
        addCampaign,
        postBookingRequest,
        saveInventoryReservation,
        saveInventoryBooking,
        getCompanyPodcastExclusives,
        postAudioBacklog,
    } from '../../api/api.js';
    import {navigate} from 'svelte-routing';
    import {
        podcastPodcastId,
        inventoryChecks,
        storedUser,
        campaignEdited,
        targetingEdited,
        selectedCampaign,
        storedCompany,
        newCampaignTargetImpressions,
        showModal,
        alertOpen,
        alertSingleOpen,
        missingLinkId,
        render
    } from '../../store.js';
    import {get} from 'svelte/store';
    import CardCampaignTargeting from 'components/Cards/CardCampaignTargeting.svelte';
    import ModalSmall from 'components/Modals/ModalSmall.svelte';
    import AlertMultiLine from 'components/Alerts/AlertMultiLine.svelte';
    import AlertOneLine from 'components/Alerts/AlertOneLine.svelte';
    import SnackbarNoDismiss from 'components/Snackbars/SnackbarNoDismiss.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';
    import {Pulse} from 'svelte-loading-spinners';
    import AudioPlayer from '../AudioPlayer.svelte';
    import CardCommitMessage from 'components/Cards/CardCommitMessage.svelte';
    import CardBadgeTag from 'components/Cards/CardBadgeTag.svelte';
    import CardBadgeClickableTag from 'components/Cards/CardBadgeClickableTag.svelte';
    import SnackbarDismiss from 'components/Snackbars/SnackbarDismiss.svelte';

    import {getDateMidnight, databaseToDatePickerDate, getDateNow, datePickerDateToDatabase, toggleButtonState, copyToClipboard, databaseToPrettyDateTime, downloadFromUrl} from '../../functions.js';
    import {getText} from '../../language.svelte';
    import CardTooltip from 'components/Cards/CardTooltip.svelte';
    import {
        setCampaignState,
        setBookingRequestState,
        setBookingRequestScript,
        setAudio,
        resetBookingRequestState,
        setBookingRequestApproveScript,
        setBookingRequestAudioEditor,
        setScriptbacklogMeta,
        setAudiobacklogMeta,
    } from '../../stateSetter.js';

    // VARS
    export let id;
    export let ctype = ctype;
    export let inventory = false;
    export let showInventoryCheck;
    export let podcastId;
    export let parentFunction_deleteInventoryCheck = () => {};
    export let selectedInventoryCheck;

    $render = Math.random();

    let campaign = {};
    let company = get(storedCompany);
    let renderingMode;

    // UI text
    let modalMsg;
    let modalTitle;
    let alertMsg;
    let alertTitle;
    let snackbarNoDismissOpen;
    let snackbarNoDismissMsg;
    let snackbarNoDismissType;
    let snackbarDismissMsg;
    let snackbarDismissType;
    let snackbarDismissTitle;
    let snackbarDismissOpen;

    export let title;
    let hostreadDurationMs;
    let client;
    let acceptanceDeadline = databaseToDatePickerDate(getDateMidnight(-10080)); // default to plus 7 days
    let uploadDeadline;
    export let startDate = databaseToDatePickerDate(getDateMidnight(-20160));
    export let endDate = databaseToDatePickerDate(getDateMidnight(-30240));
    // var endDate = '2022-02-02T11:55'; // example time stamp
    let script = '';
    $: script;
    let backgroundInformation;
    let audioKeywords;
    let briefingURL;
    let briefingSupplementaryURL;
    let briefingURLSoundEffects;
    let goal;
    export let targetImpressions;
    if (company.type != 'podcast') {
        newCampaignTargetImpressions.subscribe((value) => {
            targetImpressions = value;
        });
    }
    let campaignAcceptanceDeadline;
    let campaignUploadDeadline;
    let campaignStartDate;
    let campaignEndDate;

    let campaignTargeting;
    let campaignTargetingStatus;

    let fileNameOriginal;

    let audiofileUrl;
    let audiofileNameOriginal;
    let audiofileUploadedDate;
    let audiofileDurationMs;
    let audioUploadedSucess = false;

    let hearFeelURL;
    let hearFeelNameOriginal;
    let hearFeelUploadedSucess = false;

    let soundEffectsURL;
    let soundEffectsNameOriginal;
    let soundEffectsUploadedSucess = false;

    export let rollPositionPre = false;
    export let rollPositionMid1 = false;
    export let rollPositionMid2 = false;
    export let rollPositionMid3 = false;
    export let rollPositionMid4 = false;
    export let rollPositionMid5 = false;
    export let rollPositionPost = false;
    let briefingCompleted = false;
    let scriptEdited = '';
    $: scriptEdited;

    let time = 'T00:00:00.000Z';

    let titleTwo = ctype == 'hostread' ? 'Campaign Briefing' : 'Campaign Audio';
    let showUpdateAudioFileButton = false;
    let showUpdateHearFeelButton = false;
    let showUpdateSoundEffectsButton = false;

    let selectedPodcast;
    let exclusiveConfirmedIds;

    // checkbox options
    let campaignLoopsChecked = {};
    let campaignRollPositionChecked = {};

    let disabled = true;
    let existingBRs;

    function updateEditMode() {
        $campaignEdited = true;
    }

    async function collectCampaignData(id) {
        if (!id) {
            return {};
        }

        campaign = await getCampaignByIdCached(id);
        setup();
        return campaign;
    }

    let promise = collectCampaignData(id)
        .then((data) => (campaign = data))
        .then(setup);

    async function collectExisitingBookingRequests() {
        existingBRs = await getOriginalBRs(campaign.id);
    }

    function checkExisitingBookingRequestsState() {
        // if no BRs enable editing by default
        if (existingBRs.length == 0) {
            disabled = false;
            return;
        } else disabled = true;

        // if no BRs has been accepted yet enable editing
        for (let br of existingBRs) {
            if (br.state <= 2) {
                disabled = false;
            } else {
                disabled = true;
                return;
            }
        }
    }

    async function setup() {
        campaignAcceptanceDeadline = databaseToDatePickerDate(campaign.acceptance_deadline);
        campaignUploadDeadline = databaseToDatePickerDate(campaign.upload_deadline);
        campaignStartDate = databaseToDatePickerDate(campaign.start_date);
        campaignEndDate = databaseToDatePickerDate(campaign.end_date);

        if (Object.keys(campaign).length == 0) {
            renderingMode = 1;
            $campaignEdited = false;
            $targetingEdited = false;
            newCampaignTargetImpressions.set(0);
            selectedCampaign.set({});
            campaignLoopsChecked = {
                feedbackLoopScript: false,
                feedbackLoopAudio: false,
                feedbackLoopNone: false,
            };
            campaignRollPositionChecked = {
                rollPositionPre: rollPositionPre,
                rollPositionMid1: rollPositionMid1,
                rollPositionMid2: rollPositionMid2,
                rollPositionMid3: rollPositionMid3,
                rollPositionMid4: rollPositionMid4,
                rollPositionMid5: rollPositionMid5,
                rollPositionPost: rollPositionPost,
            };
            // campaign = {
            //     title: '',
            //     acceptance_deadline: getDateNow(),
            //     upload_deadline: getDateNow(),
            //     start_date: getDateNow(),
            //     end_date: getDateNow(),
            //     created: getDateNow(),
            //     updated: getDateNow(),
            //     state: 2,
            //     target_impressions: 0,
            //     feedback_loop_script: false,
            //     feedback_loop_audio: false,
            //     feedback_loop_none: false,
            //     roll_position_pre: rollPositionPre,
            //     roll_position_mid_1: rollPositionMid1,
            //     roll_position_mid_2: rollPositionMid2,
            //     roll_position_mid_3: rollPositionMid3,
            //     roll_position_mid_4: rollPositionMid4,
            //     roll_position_mid_5: rollPositionMid5,
            //     roll_position_post: rollPositionPost,
            // };
        } else {
            renderingMode = 2;
            $campaignEdited = false;
            $targetingEdited = false;
            startDate = databaseToDatePickerDate(campaign.start_date);
            endDate = databaseToDatePickerDate(campaign.end_date);
            acceptanceDeadline = databaseToDatePickerDate(campaign.acceptance_deadline);
            // uploadDeadline = databaseToDatePickerDate(campaign.upload_deadline);
            uploadDeadline = databaseToDatePickerDate(campaign.acceptance_deadline);
            audioKeywords = campaign.audio_keywords;
            excludedKeywords = campaign.excluded_keywords;
            scriptEdited = campaign.script;
            audiofileNameOriginal = campaign.audiofile_name_original;
            hearFeelNameOriginal = campaign.hear_feel_name_original;
            soundEffectsNameOriginal = campaign.sound_effects_name_original;
            targetImpressions = campaign.target_impressions;
            campaignLoopsChecked = {
                feedbackLoopScript: campaign.feedback_loop_script,
                feedbackLoopAudio: campaign.feedback_loop_audio,
                feedbackLoopNone: campaign.feedback_loop_none,
            };
            campaignRollPositionChecked = {
                rollPositionPre: campaign.roll_position_pre,
                rollPositionMid1: campaign.roll_position_mid_1,
                rollPositionMid2: campaign.roll_position_mid_2,
                rollPositionMid3: campaign.roll_position_mid_3,
                rollPositionMid4: campaign.roll_position_mid_4,
                rollPositionMid5: campaign.roll_position_mid_5,
                rollPositionPost: campaign.roll_position_post,
            };
            // campaign = {
            //     title: campaign.title,
            //     acceptance_deadline: campaign.acceptance_deadline,
            //     upload_deadline: campaign.upload_deadline,
            //     start_date: campaign.start_date,
            //     end_date: campaign.end_date,
            //     created: campaign.created,
            //     updated: campaign.updated,
            //     state: campaign.state,
            //     target_impressions: campaign.target_impressions,
            //     feedback_loop_script: campaign.feedback_loop_script,
            //     feedback_loop_audio: campaign.feedback_loop_audio,
            //     feedback_loop_none: campaign.feedback_loop_none,
            //     roll_position_pre: campaign.roll_position_pre,
            //     roll_position_mid_1: campaign.roll_position_mid_1,
            //     roll_position_mid_2: campaign.roll_position_mid_2,
            //     roll_position_mid_3: campaign.roll_position_mid_3,
            //     roll_position_post: campaign.roll_position_post,
            // };
            await collectExisitingBookingRequests();
            // check if booking requests exist already to decide if default = true or false
            checkExisitingBookingRequestsState();
        }

        // hide inventory pop up
        company.type == 'podcast' && document.getElementById('grid-inventory-check-pop-up') ? (document.getElementById('grid-inventory-check-pop-up').style.display = 'none') : '';

        // check if company has exclusive podcasts
        const exclusives = await getCompanyPodcastExclusives($storedCompany.id);
        exclusiveConfirmedIds = exclusives.map((item) => (item.confirmed ? item.podcast_id : ''));
        exclusiveConfirmedIds = exclusiveConfirmedIds.filter((e) => e !== '');
    }

    // SNACKBARS
    function fireSnackbarNoDismiss(msg, type) {
        snackbarNoDismissMsg = msg;
        snackbarNoDismissType = type;
        snackbarNoDismissOpen = true;
    }

    function fireSnackbarDismiss(msg, type, title) {
        snackbarDismissMsg = msg;
        snackbarDismissType = type;
        snackbarDismissTitle = title;
        snackbarDismissOpen = true;
    }

    // KEYWORD SELECTOR DROPDOWN
    // var keywords = ['E-mobility', 'Energy', 'Food', 'Health', 'Politics', 'Wellness'];

    // let dropdownPopoverShow = false;

    // let btnDropdownRef;
    // let popoverDropdownRef;

    // const toggleDropdown = () => {
    //     if (dropdownPopoverShow) {
    //         dropdownPopoverShow = false;
    //     } else {
    //         dropdownPopoverShow = true;
    //         createPopper(btnDropdownRef, popoverDropdownRef, {
    //             placement: 'bottom-start',
    //         });
    //     }
    // };

    // SELECT for contacts with search
    // TODO api contact endpoint
    // const clientContacts = ['Client 1', 'Client 2', 'Client 3'];

    let allPodcasts = [];
    let allPodcastsPromise = getAllPodcasts();

    async function getAllPodcasts() {
        try {
            let podcasts = await getAllPodcastsOfCompanyCached(company.id);

            podcasts.forEach((item) => {
                allPodcasts.push([item.name, item.id]);
            });
        } catch (e) {
            console.log(`Error ${e} occoured whilst fetching and processing the podcast data !`);
            return;
        }
    }

    // +++ EVENT HANDLER +++
    function handleCancel() {
        if (company.type !== 'podcast') {
            if ($campaignEdited) {
                modalMsg = 'All changes made here will be lost. Are you sure you want to proceed without saving your changes?';
                modalTitle = 'Attention !';
                $showModal = true;
            } else {
                navigate(`/promoter`, {replace: true});
            }
        } else if (document.getElementById('grid-inventory-check-campaign')) {
            document.getElementById('grid-inventory-check-campaign').style.display = 'none';
            showInventoryCheck = false;
        } else navigate(`/podcast/inventory/campaigns`, {replace: true});
    }

    // save campaign + call "send all BRs"
    async function handleSaveProceed() {
        if (checkRequirements()) {
            if ($campaignEdited || $targetingEdited) {
                let buttons = document.getElementsByClassName('saveproceed');
                Array.from(buttons).forEach((elem) => {
                    toggleButtonState(elem, 'disable', 'SAVING ...');
                });

                let response = await save();
                if (response) {
                    //TODO make that it shows - time is too short here
                    fireSnackbarNoDismiss(getText('ui_messages.snackbars.promoter.snackbar_campaign_save_success'), 'success');
                    $storedCompany.type == 'promoter' ? navigate(`/promoter`, {replace: true}) : navigate(`/podcast`, {replace: true});
                }
            } else {
                $storedCompany.type == 'promoter' ? navigate(`/promoter`, {replace: true}) : navigate(`/podcast`, {replace: true});
            }
        }
    }

    function checkRequirements() {
        let bookingRequests;

        // bookingRequests = campaignTargeting.collectDeals();

        // check on inventory campaigns
        if (company.type !== 'podcast') {
            bookingRequests = campaignTargeting.collectDeals();
        } else {
            ctype == 'crosspromo' && renderingMode == 1 ? ($podcastPodcastId = selectedPodcast[1]) : '';
            bookingRequests = [
                {
                    podcast_id: podcastId ? podcastId : $podcastPodcastId,
                    requested_impressions: targetImpressions,
                },
            ];
        }

        if (bookingRequests.length > 0) {
            campaign = setCampaignState(campaign, 2);
        }

        let zero = 0;
        for (let bookingRequest of bookingRequests) {
            if (bookingRequest.requested_impressions == 0) {
                zero += 1;
            }
        }

        // warning for no client in podcast campaigns (former inventoy checks)
        if (company.type !== 'podcast') {
            if (!client && !document.getElementById('grid-client').value) {
                window.alert(getText('ui_messages.alerts.podcast.alert_no_client'));
                return false;
            }
        }

        // Warning for 0 requested impressions
        if (zero > 0) {
            window.alert(getText('ui_messages.alerts.promoter.alert_zero_impressions'));
            return false;
        }

        // Check on requiered values
        if (!title && !document.getElementById('grid-title').value) {
            alertMsg = getText('ui_messages.alerts.promoter.alert_no_title');
            $alertSingleOpen = true;
            return false;
        }

        if (company.type !== 'podcast') {
            if (!acceptanceDeadline && !document.getElementById('grid-acceptance-date').value) {
                alertMsg = getText('ui_messages.alerts.promoter.alert_no_acceptance_deadline');
                $alertSingleOpen = true;
                return false;
            }
        } else {
            acceptanceDeadline = startDate;
        }

        if (!startDate && !document.getElementById('grid-start-date').value) {
            alertMsg = getText('ui_messages.alerts.promoter.alert_no_start_date');
            $alertSingleOpen = true;
            return false;
        }
        if (!endDate && !document.getElementById('grid-end-date').value) {
            alertMsg = getText('ui_messages.alerts.promoter.alert_no_end_date');
            $alertSingleOpen = true;
            return false;
        }
        if ((targetImpressions == 0 || !targetImpressions) && !parseInt(document.getElementById('grid-target-impressions').value)) {
            alertMsg = getText('ui_messages.alerts.promoter.alert_no_target_impressions');
            $alertSingleOpen = true;
            return false;
        }
        if (ctype == 'spot' && !fileAudio && !campaign.audiofile_url) {
            alertMsg = getText('ui_messages.alerts.promoter.alert_no_audio_spot');
            $alertSingleOpen = true;
            return false;
        }
        if (ctype == 'crosspromo' && !fileAudio && !campaign.audiofile_url) {
            alertMsg = getText('ui_messages.alerts.promoter.alert_no_audio_crosspromo');
            $alertSingleOpen = true;
            return false;
        }
        if (ctype == 'crosspromo' && selectedPodcast == 'select') {
            alertMsg = getText('ui_messages.alerts.promoter.alert_no_sender');
            $alertSingleOpen = true;
            return false;
        }
        if (
            ctype == 'hostread' &&
            !campaignLoopsChecked.feedbackLoopAudio &&
            !campaignLoopsChecked.feedbackLoopScript &&
            !campaignLoopsChecked.feedbackLoopNone &&
            !campaign.feedback_loop_audio &&
            !campaign.feedback_loop_script &&
            !campaign.feedback_loop_none
        ) {
            alertMsg = getText('ui_messages.alerts.promoter.alert_no_approval_option');
            $alertSingleOpen = true;
            return false;
        }

        positionRolls.forEach((roll) => {
            roll == 0 ? (campaignRollPositionChecked.rollPositionPre = true) : false;
            roll == 1 ? (campaignRollPositionChecked.rollPositionMid1 = true) : false;
            roll == 2 ? (campaignRollPositionChecked.rollPositionMid2 = true) : false;
            roll == 3 ? (campaignRollPositionChecked.rollPositionMid3 = true) : false;
            roll == 4 ? (campaignRollPositionChecked.rollPositionMid4 = true) : false;
            roll == 5 ? (campaignRollPositionChecked.rollPositionMid5 = true) : false;
            roll == 6 ? (campaignRollPositionChecked.rollPositionPost = true) : false;
        });

        if (
            renderingMode == 1 &&
            !campaignRollPositionChecked.rollPositionPre &&
            !campaignRollPositionChecked.rollPositionMid1 &&
            !campaignRollPositionChecked.rollPositionMid2 &&
            !campaignRollPositionChecked.rollPositionMid3 &&
            !campaignRollPositionChecked.rollPositionMid4 &&
            !campaignRollPositionChecked.rollPositionMid5 &&
            !campaignRollPositionChecked.rollPositionPost
        ) {
            window.alert(getText('ui_messages.alerts.promoter.alert_role_position'));
            return false;
        }

        return true;
    }

    // CHECK & SAVE
    async function save() {
        let bookingRequests;

        // bookingRequests = campaignTargeting.collectDeals();

        // check on inventory campaigns
        if (company.type !== 'podcast') {
            bookingRequests = campaignTargeting.collectDeals();
        } else {
            ctype == 'crosspromo' && renderingMode == 1 ? ($podcastPodcastId = selectedPodcast[1]) : '';
            bookingRequests = [
                {
                    podcast_id: podcastId ? podcastId : $podcastPodcastId,
                    requested_impressions: targetImpressions,
                },
            ];
        }

        // upload to s3
        if (fileAudio) {
            if (!audioUploadedSucess) {
                await submitForm(fileAudio, 'audio', 'fileAudio');
            }
        }

        if (fileHearFeel) {
            if (!hearFeelUploadedSucess) {
                await submitForm(fileHearFeel, 'briefing', 'fileHearFeel');
            }
        }

        if (fileSoundEffects) {
            if (!soundEffectsUploadedSucess) {
                await submitForm(fileSoundEffects, 'briefing', 'fileSoundEffects');
            }
        }

        // write to API
        // campaign
        if (renderingMode == 1) {
            let sender = 'not provided';

            if (ctype == 'crosspromo') {
                sender = selectedPodcast[0];
                $podcastPodcastId = selectedPodcast[1];
            } else if ((ctype == 'hostread' || ctype == 'spot') && company.type !== 'podcast') {
                sender = company.name;
            } else if (ctype == 'hostread' || (ctype == 'spot' && company.type == 'podcast')) {
                sender = client;
            }

            if (!script && campaignLoopsChecked.feedbackLoopScript) {
                script = getText('placeholder.compose_script');
            }

            // quick fix to join acceptanceDeadline and uploadDeadline to one field
            uploadDeadline = datePickerDateToDatabase(acceptanceDeadline);

            let newCampaign = {
                title: title,
                client: client,
                script: script,
                background_information: backgroundInformation,
                goal: goal,
                start_date: datePickerDateToDatabase(startDate),
                end_date: datePickerDateToDatabase(endDate),
                created: getDateNow(),
                updated: getDateNow(),
                state: 2,
                acceptance_deadline: datePickerDateToDatabase(acceptanceDeadline),
                upload_deadline: uploadDeadline,
                // target_impressions: campaignTargeting.getTotalTargetImpressions(),
                target_impressions: company.type !== 'podcast' ? campaignTargeting.getTotalTargetImpressions() : targetImpressions,
                current_total_impressions: 0,
                audiofile_url: audiofileUrl,
                audiofile_uploaded_date: audiofileUploadedDate,
                audiofile_duration_ms: audiofileDurationMs,
                audiofile_name_original: audiofileNameOriginal,
                type: ctype,
                sender: sender,
                hostread_duration_ms: hostreadDurationMs > 0 ? hostreadDurationMs : 0,
                feedback_loop_script: campaignLoopsChecked.feedbackLoopScript,
                feedback_loop_audio: campaignLoopsChecked.feedbackLoopAudio,
                feedback_loop_none: campaignLoopsChecked.feedbackLoopNone,
                roll_position_pre: campaignRollPositionChecked.rollPositionPre,
                roll_position_mid_1: campaignRollPositionChecked.rollPositionMid1,
                roll_position_mid_2: campaignRollPositionChecked.rollPositionMid2,
                roll_position_mid_3: campaignRollPositionChecked.rollPositionMid3,
                roll_position_mid_4: campaignRollPositionChecked.rollPositionMid4,
                roll_position_mid_5: campaignRollPositionChecked.rollPositionMid5,
                roll_position_post: campaignRollPositionChecked.rollPositionPost,
                audio_keywords: audioKeywords,
                excluded_keywords: excludedKeywords,
                briefing_url: briefingURL,
                briefing_url_sound_effects: briefingURLSoundEffects,
                briefing_supplementary_url: briefingSupplementaryURL,
                hear_feel_url: hearFeelURL,
                hear_feel_name_original: hearFeelNameOriginal,
                sound_effects_url: soundEffectsURL,
                sound_effects_name_original: soundEffectsNameOriginal,
                briefing_completed: briefingCompleted,
                briefing_last_updated_date: getDateNow(),
            };

            // save and get campaignId
            try {
                campaign = await addCampaign(company.id, newCampaign);
                selectedCampaign.set(campaign);

                if (company.type == 'podcast') {
                    parentFunction_deleteInventoryCheck(selectedInventoryCheck);
                }
            } catch (e) {
                console.log(`Error ${e} occoured whilst saving the new campaign "${newCampaign.title}"`);
                return false;
            }

            if (script) {
                try {
                    await postScriptBacklog(setScriptbacklogMeta(script, campaign.id));
                } catch (e) {
                    console.log(`Error ${e} occoured whilst sending the script ${script} to the backlog !`);
                }
            }

            if (audiofileUrl) {
                try {
                    await postAudioBacklog(setAudiobacklogMeta(audiofileUrl, campaign.id));
                } catch (e) {
                    console.log(`Error ${e} occoured whilst writing to the audio backlog for campaign ${campaign.id}`);
                }
            }

            for (let bookingRequest of bookingRequests) {
                // activate BR
                bookingRequest.activated = true;
                bookingRequest.activated_date = getDateNow();

                if (company.type !== 'podcast') {
                    bookingRequest = setBookingRequestState(bookingRequest, 2);
                } else if (company.type == 'podcast') {
                    bookingRequest.offered_impressions = bookingRequest.requested_impressions;
                    if (ctype == 'hostread') {
                        bookingRequest = setBookingRequestState(bookingRequest, 5);
                    } else if (ctype == 'spot' || ctype == 'crosspromo') {
                        bookingRequest = setBookingRequestState(bookingRequest, 2);
                        bookingRequest = setBookingRequestState(bookingRequest, 3);
                        bookingRequest = setBookingRequestState(bookingRequest, 5);
                        bookingRequest = setBookingRequestState(bookingRequest, 8);
                    }
                }

                // for inventory checks - state 5
                // if (company.type == 'podcast') {
                //     bookingRequest.agreed = true;
                //     bookingRequest.agreed_date = getDateNow();
                //     bookingRequest.offered_impressions = targetImpressions;
                //     bookingRequest = setBookingRequestState(bookingRequest, 5);
                // }

                // take over roll position of inventory check objects
                campaignRollPositionChecked.rollPositionPre && company.type == 'podcast' ? (bookingRequest.roll_position_pre = true) : (bookingRequest.roll_position_pre = false);
                campaignRollPositionChecked.rollPositionMid1 && company.type == 'podcast' ? (bookingRequest.roll_position_mid_1 = true) : (bookingRequest.roll_position_mid_1 = false);
                campaignRollPositionChecked.rollPositionMid2 && company.type == 'podcast' ? (bookingRequest.roll_position_mid_2 = true) : (bookingRequest.roll_position_mid_2 = false);
                campaignRollPositionChecked.rollPositionMid3 && company.type == 'podcast' ? (bookingRequest.roll_position_mid_3 = true) : (bookingRequest.roll_position_mid_3 = false);
                campaignRollPositionChecked.rollPositionMid4 && company.type == 'podcast' ? (bookingRequest.roll_position_mid_4 = true) : (bookingRequest.roll_position_mid_4 = false);
                campaignRollPositionChecked.rollPositionMid5 && company.type == 'podcast' ? (bookingRequest.roll_position_mid_5 = true) : (bookingRequest.roll_position_mid_5 = false);
                campaignRollPositionChecked.rollPositionPost && company.type == 'podcast' ? (bookingRequest.roll_position_post = true) : (bookingRequest.roll_position_post = false);

                if ((ctype == 'spot' || ctype == 'crosspromo') && fileAudio) {
                    bookingRequest = setAudio(bookingRequest, audiofileUrl, audiofileDurationMs, audiofileNameOriginal);
                    bookingRequest = setBookingRequestAudioEditor(bookingRequest);
                }

                if (ctype == 'crosspromo') {
                    bookingRequest.sender = selectedPodcast[0];
                } else if ((ctype == 'hostread' || ctype == 'spot') && company.type !== 'podcast') {
                    bookingRequest.sender = company.name;
                } else if (ctype == 'hostread' || (ctype == 'spot' && company.type == 'podcast')) {
                    bookingRequest.sender = campaign.client;
                }

                if (ctype == 'hostread') {
                    bookingRequest.script = company.script;

                    if (campaign.briefing_completed) {
                        bookingRequest = setBookingRequestScript(bookingRequest, campaign.script, true);

                        if (!campaign.feedback_loop_script) {
                            bookingRequest = setBookingRequestApproveScript(bookingRequest);
                        }
                    }
                }

                let newbR;
                try {
                    newbR = await postBookingRequest(campaign.id, bookingRequest.podcast_id, bookingRequest);

                    await saveCommitMessage(newbR.id, 1).catch((e) => {
                        console.log(`Error ${e} occoured whilst saving the commit for booking request ${newbR}`);
                    });
                } catch (e) {
                    console.log(`Error ${e} occoured whilst saving the booking request of campaign ${campaign.id} for podcast ${bookingRequest.podcast_id} !`);
                }

                // close deal for spot/XP podcast-campaigns that came via the inventory tool (state 8)
                if ((company.type == 'podcast' && ctype == 'spot') || ctype == 'crosspromo') {
                    try {
                        let deal = await makeDeal(newbR.id).then((response) => response.json());
                        newbR.deal_id = deal[0];
                        newbR.vast_url = deal[1];
                    } catch (e) {
                        console.log(`Error ${e} occoured whilst closing the deal !`);
                        return;
                    }

                    try {
                        await patchBookingRequest(newbR.id, newbR);
                    } catch (e) {
                        console.log(`Error ${e} occoured whilst patching the booking request: ${newbR}`);
                    }
                }

                try {
                    // create inventoryReservation or Booking (depends if campaign is a promoter or podcast campaign)
                    let rollPositions = [];
                    campaignRollPositionChecked.rollPositionPre ? rollPositions.push(1) : '';
                    campaignRollPositionChecked.rollPositionMid1 ? rollPositions.push(2) : '';
                    campaignRollPositionChecked.rollPositionMid2 ? rollPositions.push(3) : '';
                    campaignRollPositionChecked.rollPositionMid3 ? rollPositions.push(4) : '';
                    campaignRollPositionChecked.rollPositionMid4 ? rollPositions.push(5) : '';
                    campaignRollPositionChecked.rollPositionMid5 ? rollPositions.push(6) : '';
                    campaignRollPositionChecked.rollPositionPost ? rollPositions.push(7) : '';

                    for (let roll of rollPositions) {
                        let newInventory = {
                            roll_position: roll,
                            campaign_id: newbR.campaign_id,
                            booking_request_id: newbR.id,
                            podcast_id: newbR.podcast_id,
                        };

                        company.type !== 'podcast'
                            ? await saveInventoryReservation(newbR.campaign_id, newbR.id, newbR.podcast_id, newInventory)
                            : await saveInventoryBooking(newbR.campaign_id, newbR.id, newbR.podcast_id, newInventory);
                    }
                } catch (e) {
                    console.log(`Error ${e} occoured whilst saving the inventory reservation for booking request ${newbR.id} for podcast ${newbR.podcast_id} !`);
                }

                // close deal for exclusive podcasts -> keep this for channel booking !
                // if (exclusiveConfirmedIds.includes(Number(newbR.podcast_id))) {
                //     newbR.offered_impressions = newbR.requested_impressions;
                //     newbR.roll_position_pre = newCampaign.roll_position_pre;
                //     newbR.roll_position_mid_1 = newCampaign.roll_position_mid_1;
                //     newbR.roll_position_mid_2 = newCampaign.roll_position_mid_2;
                //     newbR.roll_position_mid_3 = newCampaign.roll_position_mid_3;
                //     newbR.roll_position_post = newCampaign.roll_position_post;

                //     if (ctype == 'hostread') {
                //         newbR = setBookingRequestState(newbR, 3);
                //         newbR = setBookingRequestState(newbR, 5);
                //     }

                //     // make deal
                //     if (ctype == 'spot') {
                //         newbR = setBookingRequestState(newbR, 8);
                //         try {
                //             let deal = await makeDeal(newbR.id).then((response) => response.json());
                //             newbR.deal_id = deal[0];
                //             newbR.vast_url = deal[1];
                //         } catch (e) {
                //             console.log(`Error ${e} occoured whilst closing the deal !`);
                //             return;
                //         }
                //     }

                //     try {
                //         await patchBookingRequest(newbR.id, newbR);
                //     } catch (e) {
                //         console.log(`Error ${e} occoured whilst patching the booking request: ${newbR}`);
                //     }
                // }
            }
            return true;
        }

        // update edited, already existing campaign
        if (renderingMode == 2) {
            // set bookingRequests to empty for podcast campaigns on update
            company.type == 'podcast' ? (bookingRequests = []) : (bookingRequests = bookingRequests);

            // catch general type-independent edits
            company.type !== 'podcast' ? (campaign.target_impressions = campaignTargeting.getTotalTargetImpressions()) : (campaign.target_impressions = targetImpressions);
            company.type !== 'podcast' ? (campaign.acceptance_deadline = datePickerDateToDatabase(campaignAcceptanceDeadline)) : '';
            company.type !== 'podcast' ? (campaign.upload_deadline = datePickerDateToDatabase(campaignUploadDeadline)) : '';
            campaign.start_date = datePickerDateToDatabase(campaignStartDate);
            campaign.end_date = datePickerDateToDatabase(campaignEndDate);
            campaign.roll_position_pre = campaignRollPositionChecked.rollPositionPre;
            campaign.roll_position_mid_1 = campaignRollPositionChecked.rollPositionMid1;
            campaign.roll_position_mid_2 = campaignRollPositionChecked.rollPositionMid2;
            campaign.roll_position_mid_3 = campaignRollPositionChecked.rollPositionMid3;
            campaign.roll_position_mid_4 = campaignRollPositionChecked.rollPositionMid4;
            campaign.roll_position_mid_5 = campaignRollPositionChecked.rollPositionMid5;
            campaign.roll_position_post = campaignRollPositionChecked.rollPositionPost;

            if (ctype == 'hostread') {
                if (scriptEdited == '' && campaign.feedback_loop_script) {
                    scriptEdited = getText('placeholder.compose_script');
                }
                campaign.script = scriptEdited;
                campaign.audio_keywords = audioKeywords ? audioKeywords : campaign.audio_keywords;
                campaign.excluded_keywords = excludedKeywords ? excludedKeywords : campaign.excluded_keywords;
                campaign.hear_feel_url = hearFeelURL ? hearFeelURL : campaign.hear_feel_url;
                campaign.hear_feel_name_original = hearFeelNameOriginal ? hearFeelNameOriginal : campaign.hear_feel_name_original;
                campaign.sound_effects_url = soundEffectsURL ? soundEffectsURL : campaign.sound_effects_url;
                campaign.sound_effects_name_original = soundEffectsNameOriginal ? soundEffectsNameOriginal : campaign.sound_effects_name_original;
                campaign.briefing_completed = briefingCompleted ? briefingCompleted : campaign.briefing_completed;
                campaign.briefing_last_updated_date = getDateNow();
                campaign.feedback_loop_script = campaignLoopsChecked.feedbackLoopScript;
                campaign.feedback_loop_audio = campaignLoopsChecked.feedbackLoopAudio;
                campaign.feedback_loop_none = campaignLoopsChecked.feedbackLoopNone;
            }

            if ((ctype == 'spot' || ctype == 'crosspromo') && fileAudio) {
                campaign = setAudio(campaign, audiofileUrl, audiofileDurationMs, audiofileNameOriginal);
            }

            let campaignUpdated;
            try {
                campaignUpdated = await patchCampaign(campaign.id, campaign);
            } catch (e) {
                console.log(`Error ${e} ouccoured whilst updating campaign`);
            }

            let commitMessageOriginal = commitMessage;
            for (let bR of existingBRs) {
                if (company.type == 'podcast') {
                    bR.requested_impressions = campaignUpdated.target_impressions;
                    bR.offered_impressions = campaignUpdated.target_impressions;

                    try {
                        await patchBookingRequest(bR.id, bR);
                    } catch (e) {
                        console.log(`Error ${e} occoured whilst patching the booking request: ${bR}`);
                    }
                }

                // handle audiofile change after handshake
                if (audiofileUrl && (campaignUpdated.type == 'spot' || campaignUpdated.type == 'crosspromo') && bR.state == 8 && audiofileUrl != bR.audiofile_Url) {
                    bR = resetBookingRequestState(bR, 2);

                    try {
                        await patchBookingRequest(bR.id, bR);

                        commitMessage ? (commitMessage = commitMessage) : (commitMessage = getText('commit_messages.default.campaign.audio_changed'));
                        await saveCommitMessage(bR.id, 15).catch((e) => {
                            console.log(`Error ${e} occoured whilst saving the commit for booking request ${bR}`);
                        });
                    } catch (e) {
                        console.log(`Error ${e} occoured whilst patching the booking request: ${bR}`);
                    }
                }

                // handle new audiofile on accepted campaigns
                if (audiofileUrl && (campaignUpdated.type == 'spot' || campaignUpdated.type == 'crosspromo') && bR.state <= 8) {
                    bR = setAudio(bR, campaignUpdated.audiofile_url, campaignUpdated.audiofile_duration_ms, campaignUpdated.audiofile_name_original);
                    bR = setBookingRequestAudioEditor(bR);

                    if (bR.state == 5) {
                        bR = setBookingRequestState(bR, 8);

                        // make deal
                        try {
                            let deal = await makeDeal(bR.id).then((response) => response.json());
                            bR.deal_id = deal[0];
                            bR.vast_url = deal[1];
                        } catch (e) {
                            console.log(`Error ${e} occoured whilst closing the deal !`);
                            return;
                        }
                    }

                    try {
                        await patchBookingRequest(bR.id, bR);

                        commitMessage ? (commitMessage = commitMessage) : (commitMessage = getText('commit_messages.default.campaign.updated'));
                        await saveCommitMessage(bR.id, 15).catch((e) => {
                            console.log(`Error ${e} occoured whilst saving the commit for booking request ${bR}`);
                        });
                    } catch (e) {
                        console.log(`Error ${e} occoured whilst patching the booking request: ${bR}`);
                    }
                }

                // set script after completed briefing
                if (campaignUpdated.type == 'hostread' && briefingCompleted) {
                    bR = setBookingRequestScript(bR, campaignUpdated.script, true);

                    if (!campaignUpdated.feedback_loop_script) {
                        bR.script_approved_date = getDateNow();
                    }

                    try {
                        await patchBookingRequest(bR.id, bR);

                        commitMessage ? (commitMessage = commitMessage) : (commitMessage = getText('commit_messages.default.campaign.updated'));
                        await saveCommitMessage(bR.id, 15).catch((e) => {
                            console.log(`Error ${e} occoured whilst saving the commit for booking request ${bR}`);
                        });
                    } catch (e) {
                        console.log(`Error ${e} occoured whilst patching the booking request: ${bR}`);
                    }
                }
            }

            // save newly added booking requests
            for (let bookingRequest of bookingRequests) {
                // TODO check here why old BRs also get the initial commit message

                let sender = 'not provided';
                if (ctype == 'crosspromo') {
                    sender = selectedPodcast[0];
                } else if (ctype == 'hostread' || ctype == 'spot') {
                    sender = company.name;
                }

                bookingRequest.sender = sender;
                bookingRequest = setBookingRequestState(bookingRequest, 2);

                if ((ctype == 'spot' || ctype == 'crosspromo') && fileAudio) {
                    bookingRequest = setAudio(bookingRequest, audiofileUrl, audiofileDurationMs, audiofileNameOriginal);
                    bookingRequest = setBookingRequestAudioEditor(bookingRequest);
                }
                if ((ctype == 'spot' || ctype == 'crosspromo') && campaign.audiofile_url) {
                    bookingRequest = setAudio(bookingRequest, campaign.audiofileUrl, campaign.audiofile_duration_ms, campaign.audiofile_name_original);
                    bookingRequest = setBookingRequestAudioEditor(bookingRequest);
                }

                if (ctype == 'hostread' && briefingCompleted) {
                    bookingRequest = setBookingRequestScript(bookingRequest, campaign.script, true);

                    if (!campaign.feedback_loop_script) {
                        bookingRequest = setBookingRequestApproveScript(bookingRequest);
                    }
                }

                let newbR;
                try {
                    newbR = await postBookingRequest(campaign.id, bookingRequest.podcast_id, bookingRequest);

                    commitMessage = commitMessageOriginal;
                    await saveCommitMessage(newbR.id, 1).catch((e) => {
                        console.log(`Error ${e} occoured whilst saving the commit for booking request ${newbR}`);
                    });
                } catch (e) {
                    console.log(`Error ${e} occoured whilst saving the booking request of campaign ${campaign.id} for podcast ${bookingRequest.podcast_id} !`);
                }

                try {
                    // create inventoryReservation
                    let rollPositions = [];
                    campaignRollPositionChecked.rollPositionPre ? rollPositions.push(1) : '';
                    campaignRollPositionChecked.rollPositionMid1 ? rollPositions.push(2) : '';
                    campaignRollPositionChecked.rollPositionMid2 ? rollPositions.push(3) : '';
                    campaignRollPositionChecked.rollPositionMid3 ? rollPositions.push(4) : '';
                    campaignRollPositionChecked.rollPositionMid4 ? rollPositions.push(5) : '';
                    campaignRollPositionChecked.rollPositionMid5 ? rollPositions.push(6) : '';
                    campaignRollPositionChecked.rollPositionPost ? rollPositions.push(7) : '';

                    for (let roll of rollPositions) {
                        let newInventory = {
                            roll_position: roll,
                            campaign_id: newbR.campaign_id,
                            booking_request_id: newbR.id,
                            podcast_id: newbR.podcast_id,
                        };
                        await saveInventoryReservation(newbR.campaign_id, newbR.id, newbR.podcast_id, newInventory);
                    }
                } catch (e) {
                    console.log(`Error ${e} occoured whilst saving the inventory reservation for booking request ${newbR.id} for podcast ${newbR.podcast_id} !`);
                }

                // close deal for spot campagins & exclusive podcasts -> keep this for channel booking
                // if (exclusiveConfirmedIds.includes(Number(newbR.podcast_id))) {
                //     if (ctype == 'hostread') {
                //         newbR.offered_impressions = newbR.requested_impressions;
                //         newbR = setBookingRequestState(newbR, 3);
                //         newbR = setBookingRequestState(newbR, 5);
                //         newbR.script_updated_podcast = false;
                //         newbR.script_updated_promoter = true;
                //         newbR.script_approved_date = getDateNow();
                //         newbR.script_updated_date = getDateNow();
                //     }

                //     // make deal
                //     if (ctype == 'spot') {
                //         newbR.offered_impressions = newbR.requested_impressions;
                //         newbR = setBookingRequestState(newbR, 8);
                //         try {
                //             let deal = await makeDeal(newbR.id).then((response) => response.json());
                //             newbR.deal_id = deal[0];
                //             newbR.vast_url = deal[1];
                //         } catch (e) {
                //             console.log(`Error ${e} occoured whilst closing the deal !`);
                //             return;
                //         }
                //     }

                //     try {
                //         await patchBookingRequest(newbR.id, newbR);
                //     } catch (e) {
                //         console.log(`Error ${e} occoured whilst patching the booking request: ${newbR}`);
                //     }
                // }
            }
            return true;
        }
    }

    async function updateCampaignScript() {
        campaign.script = scriptEdited;

        try {
            await patchCampaign(campaign.id, campaign);
        } catch (e) {
            console.log(`Error ${e} ouccoured whilst saving the script changes `);
        }

        try {
            await postScriptBacklog(setScriptbacklogMeta(scriptEdited, campaign.id));
        } catch (e) {
            console.log(`Error ${e} occoured whilst sending the script ${script} to the backlog !`);
        }
    }

    // Date checks
    function handleDateChange(event) {
        updateEditMode();
        let pickedDateTime = Date.parse(event.target.value);

        //TODO make alert prettier, e.g. tooltip on info-icon
        alertTitle = getText('ui_messages.alerts.promoter.alert_dates_title');
        alertMsg = [
            getText('ui_messages.alerts.promoter.alert_dates_line_1'),
            getText('ui_messages.alerts.promoter.alert_dates_line_2'),
            getText('ui_messages.alerts.promoter.alert_dates_line_3'),
            getText('ui_messages.alerts.promoter.alert_dates_line_4'),
            getText('ui_messages.alerts.promoter.alert_dates_line_5'),
            getText('ui_messages.alerts.promoter.alert_dates_line_6'),
        ];
        // if fired from acceptanceDeadline
        if (event.target.id == 'grid-acceptance-date') {
            // alertMsg = 'this date cannot lie in the past & must be before the upload deadline and campaign period ! please provide a valid value.';
            if (pickedDateTime < Date.parse(getDateMidnight(60)) || pickedDateTime > Date.parse(startDate + time) || pickedDateTime > Date.parse(campaignStartDate + time)) {
                $alertOpen = true;
                acceptanceDeadline = '';
                campaignAcceptanceDeadline = '';
            }
        }

        // if fired from startDate
        if (event.target.id == 'grid-start-date') {
            if (company.type !== 'podcast') {
                if (
                    pickedDateTime < Date.parse(getDateMidnight(60)) ||
                    (endDate && pickedDateTime >= Date.parse(endDate + time)) ||
                    (campaignEndDate && pickedDateTime >= Date.parse(campaignEndDate + time)) ||
                    pickedDateTime < Date.parse(acceptanceDeadline) ||
                    pickedDateTime < Date.parse(campaignAcceptanceDeadline)
                ) {
                    $alertOpen = true;
                    startDate = '';
                    campaignStartDate = '';
                }
            } else if (company.type == 'podcast') {
                if (
                    pickedDateTime < Date.parse(getDateMidnight(60)) ||
                    (endDate && pickedDateTime >= Date.parse(endDate + time)) ||
                    (campaignEndDate && pickedDateTime >= Date.parse(campaignEndDate + time))
                ) {
                    $alertOpen = true;
                    startDate = '';
                    campaignStartDate = '';
                }
            }
        }

        // if fired from endDate
        if (event.target.id == 'grid-end-date') {
            if (company.type !== 'podcast') {
                if (
                    pickedDateTime < Date.parse(getDateMidnight(60)) ||
                    (startDate && pickedDateTime <= Date.parse(startDate + time)) ||
                    (campaignStartDate && pickedDateTime <= Date.parse(campaignStartDate + time)) ||
                    pickedDateTime < Date.parse(acceptanceDeadline) ||
                    pickedDateTime < Date.parse(campaignAcceptanceDeadline)
                ) {
                    $alertOpen = true;
                    endDate = '';
                    campaignEndDate = '';
                }
            } else if (company.type == 'podcast') {
                if (
                    pickedDateTime < Date.parse(getDateMidnight(60)) ||
                    (startDate && pickedDateTime <= Date.parse(startDate + time)) ||
                    (campaignStartDate && pickedDateTime <= Date.parse(campaignStartDate + time))
                ) {
                    $alertOpen = true;
                    endDate = '';
                    campaignEndDate = '';
                }
            }
        }
    }

    // FILE UPLOADs
    // accepts mp3 and pdf
    let fileAudio;
    let fileHearFeel;
    let fileSoundEffects;
    let duration_ms;

    // get duration of mp3 url
    async function getURLDuration(url) {
        var au = document.createElement('audio');
        au.src = url;
        // Once the metadata has been loaded grep duration in ms
        au.addEventListener(
            'loadedmetadata',
            function () {
                duration_ms = au.duration * 1000;
                audiofileDurationMs = parseInt(duration_ms);
                audioUploadedSucess = true;
            },
            false,
        );
    }

    async function submitForm(file, type, source) {
        let formData = new FormData();

        let fileName = file[0].name;
        fileName = fileName.replaceAll(' ', '-');

        fileNameOriginal = fileName;
        fileName = crypto.randomUUID() + '.' + fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);

        formData.append('file', file[0]);
        formData.append('filename', fileName);

        if (type == 'audio') {
            // TODO accept other format as well?
            if (!fileName.endsWith('mp3')) {
                window.alert(getText('ui_messages.alerts.promoter.alert_mp3_check'));
                return;
            }

            await uploadAudio(fileName, formData, source)
                .then(() => {
                    getURLDuration(`https://asap-dispo.s3.amazonaws.com/audio/${fileName}`).then(() => {
                        setTimeout(() => {}, 1500);
                    });
                })
                .catch((e) => {
                    console.log(`Error ${e} occoured whilst uploading the audio file ${fileName}`);
                });

            if (campaign.id) {
                try {
                    await postAudioBacklog(setAudiobacklogMeta(`https://asap-dispo.s3.amazonaws.com/audio/${fileName}`, campaign.id));
                } catch (e) {
                    console.log(`Error ${e} occoured whilst writing to the audio backlog for booking request ${campaign.id}`);
                }
            }
        }

        if (type == 'briefing') {
            await uploadBriefingFiles(fileName, formData, source);
        }

        showUpdateAudioFileButton = false;
        showUpdateHearFeelButton = false;
        showUpdateSoundEffectsButton = false;
    }

    async function uploadAudio(fileName, formData, source) {
        // save audiofile to s3
        try {
            await uploadFile(formData, 'audio').then((response) => {
                if (source == 'fileAudio') {
                    audiofileUrl = `https://asap-dispo.s3.amazonaws.com/audio/${fileName}`;
                    let player = document.getElementById('grid-spot-audio-player');
                    if (player) {
                        player.refresh();
                    }
                    audiofileUploadedDate = getDateNow();
                    audiofileNameOriginal = fileNameOriginal;
                    return response;
                }
            });
            // TODO notify all BRs on change ???
        } catch (e) {
            audioUploadedSucess = false;
            window.alert(getText('ui_messages.alerts.promoter.alert_audio_upload', response.status));
        }
    }

    async function uploadBriefingFiles(fileName, formData, source) {
        try {
            await uploadFile(formData, 'briefing').then((response) => {
                if (source == 'fileHearFeel') {
                    hearFeelURL = `https://asap-dispo.s3.amazonaws.com/briefing/${fileName}`;
                    campaign.hear_feel_url = hearFeelURL;
                    campaign.hear_feel_name_original = fileNameOriginal;
                    hearFeelNameOriginal = fileNameOriginal;
                    hearFeelUploadedSucess = true;
                    return response;
                }
                if (source == 'fileSoundEffects') {
                    soundEffectsURL = `https://asap-dispo.s3.amazonaws.com/briefing/${fileName}`;
                    campaign.sound_effects_url = soundEffectsURL;
                    campaign.sound_effects_name_original = fileNameOriginal;
                    soundEffectsNameOriginal = fileNameOriginal;
                    soundEffectsUploadedSucess = true;
                    return response;
                }
            });
            if (campaign.id) {
                try {
                    await patchCampaign(campaign.id, campaign);
                } catch (e) {
                    console.log(`Error ${e} ouccoured whilst updating the campaign `);
                }
            }
        } catch (e) {
            hearFeelUploadedSucess = false;
            soundEffectsUploadedSucess = false;
            window.alert(getText('ui_messages.alerts.promoter.alert_report_upload', response.status));
        }
    }

    // +++ MODAL +++
    let idName;
    let buttonState;
    let msg;
    function cancel() {
        $showModal = false;
        $storedCompany.type == 'promoter' ? navigate(`/promoter`, {replace: true}) : navigate(`/podcast`, {replace: true});
    }
    async function handleSaveExit() {
        if (checkRequirements()) {
            let buttons = document.getElementsByClassName('saveproceed');
            Array.from(buttons).forEach((elem) => {
                toggleButtonState(elem, 'disable', 'SAVING ...');
            });

            if ($alertSingleOpen == false) {
                if (await save()) {
                    $showModal = false;
                    $storedCompany.type == 'promoter' ? navigate(`/promoter`, {replace: true}) : navigate(`/podcast`, {replace: true});
                }
            }
        }
    }

    async function getOriginalBRs(id) {
        try {
            return await getBookingRequestsByCampaignIdCached(id);
        } catch (e) {
            console.log(`Error ${e} occoured whilst getting the booking requests for the campaign with the id ${id}`);
        }
    }

    function campaignTargetingStatusHandler() {
        $targetingEdited = campaignTargetingStatus;
    }

    $: campaignTargetStatusListener = campaignTargetingStatus & campaignTargetingStatusHandler();

    // AUDIO KEYWORDS
    function removeAudioKeyword(key) {
        // document.getElementById('grid-audio-key-tag-' + key).style.display = 'none';
        audioKeywords = audioKeywords.replace(key, '');
        audioKeywords = audioKeywords
            .replace(/,\s*$/, '')
            .replace(/,+/g, ',')
            .replace(/(,\s*$)|(^,*)/, '');
    }

    function removeExcludedKeyword(key) {
        excludedKeywords = excludedKeywords.replace(key, '');
        excludedKeywords = excludedKeywords
            .replace(/,\s*$/, '')
            .replace(/,+/g, ',')
            .replace(/(,\s*$)|(^,*)/, '');
    }

    $: audioKeywords, seperateKeywords();
    let seperatedAudioKeywords = [];
    function seperateKeywords() {
        if (audioKeywords) {
            seperatedAudioKeywords = audioKeywords.split(',');
        } else {
            seperatedAudioKeywords = [];
        }
    }

    let excludedKeywords;
    $: excludedKeywords, seperateExcludedKeywords();
    let seperatedExcludedKeywords = [];
    function seperateExcludedKeywords() {
        if (excludedKeywords) {
            seperatedExcludedKeywords = excludedKeywords.split(',');
        } else {
            seperatedExcludedKeywords = [];
        }
    }

    // POSITION ROLLS
    let positionRolls = [];
    function updatePositionRolls(e, position) {
        // Missing Link Sonderlocke
        if (company.type == 'promoter' && company.id !== $missingLinkId) {
            if (e.srcElement.checked) {
                positionRolls.push(position);
            } else if (!e.srcElement.checked) {
                positionRolls = positionRolls.filter((x) => x !== position);
            }
        } else {
            if (position == 0) {
                if (e.srcElement.checked) {
                    campaignRollPositionChecked.rollPositionPre = true;
                    campaignRollPositionChecked.rollPositionMid1 =
                        campaignRollPositionChecked.rollPositionMid2 =
                        campaignRollPositionChecked.rollPositionMid3 =
                        campaignRollPositionChecked.rollPositionMid4 =
                        campaignRollPositionChecked.rollPositionMid5 =
                        campaignRollPositionChecked.rollPositionPost =
                            false;
                } else {
                    campaignRollPositionChecked.rollPositionPre = false;
                }
            }
            if (position == 1) {
                if (e.srcElement.checked) {
                    campaignRollPositionChecked.rollPositionMid1 = true;
                    campaignRollPositionChecked.rollPositionPre =
                        campaignRollPositionChecked.rollPositionMid2 =
                        campaignRollPositionChecked.rollPositionMid3 =
                        campaignRollPositionChecked.rollPositionMid4 =
                        campaignRollPositionChecked.rollPositionMid5 =
                        campaignRollPositionChecked.rollPositionPost =
                            false;
                } else {
                    campaignRollPositionChecked.rollPositionMid1 = false;
                }
            }
            if (position == 2) {
                if (e.srcElement.checked) {
                    campaignRollPositionChecked.rollPositionMid2 = true;
                    campaignRollPositionChecked.rollPositionPre =
                        campaignRollPositionChecked.rollPositionMid1 =
                        campaignRollPositionChecked.rollPositionMid3 =
                        campaignRollPositionChecked.rollPositionMid4 =
                        campaignRollPositionChecked.rollPositionMid5 =
                        campaignRollPositionChecked.rollPositionPost =
                            false;
                } else {
                    campaignRollPositionChecked.rollPositionMid2 = false;
                }
            }
            if (position == 3) {
                if (e.srcElement.checked) {
                    campaignRollPositionChecked.rollPositionMid3 = true;
                    campaignRollPositionChecked.rollPositionPre =
                        campaignRollPositionChecked.rollPositionMid1 =
                        campaignRollPositionChecked.rollPositionMid2 =
                        campaignRollPositionChecked.rollPositionMid4 =
                        campaignRollPositionChecked.rollPositionMid5 =
                        campaignRollPositionChecked.rollPositionPost =
                            false;
                } else {
                    campaignRollPositionChecked.rollPositionMid3 = false;
                }
            }
            if (position == 4) {
                if (e.srcElement.checked) {
                    campaignRollPositionChecked.rollPositionMid4 = true;
                    campaignRollPositionChecked.rollPositionPre =
                        campaignRollPositionChecked.rollPositionMid1 =
                        campaignRollPositionChecked.rollPositionMid2 =
                        campaignRollPositionChecked.rollPositionMid3 =
                        campaignRollPositionChecked.rollPositionMid5 =
                        campaignRollPositionChecked.rollPositionPost =
                            false;
                } else {
                    campaignRollPositionChecked.rollPositionMid4 = false;
                }
            }
            if (position == 5) {
                if (e.srcElement.checked) {
                    campaignRollPositionChecked.rollPositionMid5 = true;
                    campaignRollPositionChecked.rollPositionPre =
                        campaignRollPositionChecked.rollPositionMid1 =
                        campaignRollPositionChecked.rollPositionMid2 =
                        campaignRollPositionChecked.rollPositionMid3 =
                        campaignRollPositionChecked.rollPositionMid4 =
                        campaignRollPositionChecked.rollPositionPost =
                            false;
                } else {
                    campaignRollPositionChecked.rollPositionMid5 = false;
                }
            }
            if (position == 6) {
                if (e.srcElement.checked) {
                    campaignRollPositionChecked.rollPositionPost = true;
                    campaignRollPositionChecked.rollPositionPre =
                        campaignRollPositionChecked.rollPositionMid1 =
                        campaignRollPositionChecked.rollPositionMid2 =
                        campaignRollPositionChecked.rollPositionMid3 =
                        campaignRollPositionChecked.rollPositionMid4 =
                        campaignRollPositionChecked.rollPositionMid5 =
                            false;
                } else {
                    campaignRollPositionChecked.rollPositionPost = false;
                }
            }
            // set position
            positionRolls = [];
            positionRolls.push(position);
        }
    }

    // FEEDBACK LOOPS TOGGLE
    function updateFeedbackLoops(e, type) {
        if (type == 'script') {
            if (e.srcElement.checked) {
                campaignLoopsChecked.feedbackLoopScript = true;
                campaignLoopsChecked.feedbackLoopNone = false;
            } else {
                campaignLoopsChecked.feedbackLoopScript = false;
            }
        }
        if (type == 'audio') {
            if (e.srcElement.checked) {
                campaignLoopsChecked.feedbackLoopAudio = true;
                campaignLoopsChecked.feedbackLoopNone = false;
            } else {
                campaignLoopsChecked.feedbackLoopAudio = false;
            }
        }
        if (type == 'none') {
            if (e.srcElement.checked) {
                campaignLoopsChecked.feedbackLoopNone = true;
                campaignLoopsChecked.feedbackLoopScript = false;
                campaignLoopsChecked.feedbackLoopAudio = false;
            } else {
                campaignLoopsChecked.feedbackLoopNone = false;
            }
        }
    }

    function updateBriefingCompleted() {
        briefingCompleted = !briefingCompleted;
    }

    // COMMIT MESSAGES
    function parentFunction() {
        handleSaveExitClicked ? handleSaveExit() : handleSaveProceed();
    }

    async function saveCommitMessage(bookingRequestId, actionCode) {
        let data = {
            booking_request_id: bookingRequestId,
            action: actionCode,
            author: $storedUser.first_name,
            company_name: $storedCompany.name,
            company_type: $storedCompany.type,
            message: commitMessage ? commitMessage : getText('commit_messages.default.campaign.created'),
            sender: true,
        };

        try {
            await postCommitMessage(bookingRequestId, data);
            return true;
        } catch (e) {
            console.log(`Error ${e} occoured whilst posting the message ${data.message}`);
            return false;
        }
    }

    function setAction(thisAction) {
        action = thisAction;
    }

    function getTitleByCtype(ctype) {
        let actions = {
            hostread: 'promoter.campaign.title_hostread',
            spot: 'promoter.campaign.title_spot',
            crosspromo: 'promoter.campaign.title_cross_promo',
        };
        return getText(actions[ctype]);
    }

    function getSaveProceedTextByCtype(ctype) {
        let actions = {
            hostread: 'promoter.campaign.button_save_send_hostread',
            spot: 'promoter.campaign.button_save_send_spot',
            crosspromo: 'promoter.campaign.button_save_send_crosspromo',
        };
        return getText(actions[ctype]);
    }

    function getSaveProceedActionByCtype(ctype) {
        let actions = {
            hostread: 'commit_messages.default.campaign.sending_hostread_requests',
            spot: 'commit_messages.default.campaign.sending_spot_requests',
            crosspromo: 'commit_messages.default.campaign.sending_cross_promo_requests',
        };
        return getText(actions[ctype]);
    }

    function saveProceedExitOnClick() {
        if (!$targetingEdited && company.type === 'podcast') {
            setAction(getText('commit_messages.default.campaign.created'));
            handleSaveExitClicked = true;
        }

        if ($targetingEdited) {
            setAction(getSaveProceedActionByCtype(ctype));
            triggerCommitMessagePopUp();
            return;
        }

        if (!$targetingEdited && fileAudio) {
            if (company.type === 'podcast') {
                handleSaveExitClicked = true;
                parentFunction();
                return;
            }

            setAction(getText('commit_messages.default.campaign.audio_uploaded'));
            commitMessage = getText('commit_messages.default.campaign.audio_uploaded');
            handleSaveExitClicked = true;
            triggerCommitMessagePopUp();
            return;
        }

        if ((!$targetingEdited && !briefingCompleted) || ($campaignEdited && !briefingCompleted)) {
            commitMessage = getText('commit_messages.default.campaign.updated');
            handleSaveExit();
            return;
        }

        if (!$targetingEdited && briefingCompleted && storedCompany.type == 'podcast') {
            if (company.type === 'podcast') {
                handleSaveExitClicked = true;
                parentFunction();
                return;
            }

            getText('commit_messages.default.campaign.briefing_completed');
            commitMessage = getText('commit_messages.default.campaign.briefing_completed');
            handleSaveExitClicked = true;
            triggerCommitMessagePopUp();
        }

        if (!$targetingEdited && briefingCompleted) {
            if (company.type === 'podcast') {
                handleSaveExitClicked = true;
                parentFunction();
                return;
            }

            getText('commit_messages.default.campaign.briefing_completed');
            commitMessage = getText('commit_messages.default.campaign.briefing_completed');
            handleSaveExitClicked = true;
            triggerCommitMessagePopUp();
        }

        if ((!$targetingEdited && briefingCompleted && !fileAudio) || $campaignEdited) {
            setAction(getText('commit_messages.default.campaign.updated'));
            commitMessage = getText('commit_messages.default.campaign.updated');
            handleSaveExitClicked = true;
            triggerCommitMessagePopUp();
        }
    }

    // currently only implemented for podcast campaigns
    async function cancelCampaign() {
        campaign = setCampaignState(campaign, 15);
        try {
            await patchCampaign(campaign.id, campaign);
        } catch (e) {
            console.log(`Error ${e} ouccoured whilst saving the script changes `);
        }

        let existingBRs = await getOriginalBRs(campaign.id);

        for (let idx in existingBRs) {
            let bookingRequest = existingBRs[idx];
            bookingRequest = setBookingRequestState(bookingRequest, 6);

            try {
                await patchBookingRequest(bookingRequest.id, bookingRequest);
            } catch (e) {
                console.log(`Error ${e} occoured whilst patching the booking request: ${bookingRequest}`);
            }
        }
    }

    function triggerCommitMessagePopUp() {
        showCommitMessage = true;
    }
    let showCommitMessage = false;
    let commitMessage;
    let action = '';
    let continueProcess = false;
    let handleSaveExitClicked = false;

    function buttonCancelCampaign() {
        let answer = window.confirm(getText('ui_messages.confirms.podcast.confirm_campaign_cancel'));
        if (answer) {
            cancelCampaign();
            return;
        } else {
            return;
        }
    }
</script>

{#await promise}
    <div style="display: flex; justify-content: center">
        <Pulse size="60" color="#FF3E00" unit="px" duration="1s" />
    </div>
{:then}
    <ModalSmall {modalMsg} {modalTitle} buttons={['Yes', 'Save', 'Stay']} functionOne={() => cancel()} functionTwo={() => handleSaveExit()} />
    <AlertMultiLine {alertTitle} {alertMsg} />
    <AlertOneLine {alertMsg} bind:idName bind:buttonState bind:msg />

    {#if snackbarNoDismissOpen}
        <SnackbarNoDismiss {snackbarNoDismissMsg} bind:snackbarNoDismissOpen {snackbarNoDismissType} />
    {/if}

    {#if snackbarDismissOpen}
        <SnackbarDismiss {snackbarDismissTitle} {snackbarDismissMsg} bind:snackbarDismissOpen {snackbarDismissType} />
    {/if}

    {#if showCommitMessage}
        <CardCommitMessage {action} bind:commitMessage bind:continueProcess bind:showCommitMessage parentFunction={() => parentFunction()} />
    {/if}

    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
                <h6 class="text-blueGray-700 text-xl font-bold">{campaign.title || getTitleByCtype(ctype)}</h6>
                {#if company.type == 'podcast'}
                    <div class="text-center flex">
                        <CardButton classes="saveproceed" type="cancel" text={getText('promoter.campaign.button_cancel_campaign')} on:click={() => buttonCancelCampaign()} />
                        <CardButton
                            classes="saveproceed"
                            type="go"
                            text={$targetingEdited ? getSaveProceedTextByCtype(ctype) : getText('promoter.campaign.button_save')}
                            on:click={() => saveProceedExitOnClick()}
                        />
                    </div>
                {/if}

                {#if company.type !== 'podcast'}
                    <CardButton
                        id={$targetingEdited ? 'saveproceed' : 'saveexit'}
                        classes="saveproceed"
                        type="go"
                        text={$targetingEdited ? getSaveProceedTextByCtype(ctype) : getText('promoter.campaign.button_save')}
                        on:click={() => saveProceedExitOnClick()}
                    />
                {/if}
            </div>
        </div>
    </div>

    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-100 border-1">
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
                <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">{getText('promoter.campaign.general_information.title')}</h6>

                {#if renderingMode == 1}
                    <div class="flex flex-wrap">
                        {#if ctype == 'crosspromo'}
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-title">
                                        {getText('promoter.campaign.general_information.category_1.title')}
                                    </label>
                                    <input
                                        id="grid-title"
                                        type="input"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder={getText('promoter.campaign.general_information.category_1.input_placeholder')}
                                        bind:value={title}
                                    />
                                </div>
                            </div>
                            {#await allPodcastsPromise then data}
                                <div class="w-full lg:w-6/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-requesting-podcast">
                                            {getText('promoter.campaign.general_information.category_3.title')}
                                        </label>

                                        <select name="podcastName" id="grid-requesting-podcast" bind:value={selectedPodcast}>
                                            <option value="select">{getText('promoter.campaign.general_information.category_3.input_placeholder')}</option>
                                            {#each allPodcasts as podcast}
                                                <option value={podcast}>{podcast[0]}</option>
                                            {/each}
                                        </select>
                                    </div>
                                </div>
                            {/await}
                        {/if}

                        {#if ctype == 'hostread' || ctype == 'spot'}
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-title">
                                        {getText('promoter.campaign.general_information.category_1.title')}
                                    </label>
                                    <input
                                        id="grid-title"
                                        type="input"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder={getText('promoter.campaign.general_information.category_1.input_placeholder')}
                                        bind:value={title}
                                    />
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                {#if company.type !== 'podcast'}
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-client">
                                            {getText('promoter.campaign.general_information.category_2.title')}
                                        </label>
                                        <input
                                            id="grid-client"
                                            type="search"
                                            list="clientContactOptions"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder={getText('promoter.campaign.general_information.category_2.input_placeholder')}
                                            bind:value={client}
                                        />
                                    </div>
                                {:else}
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-client">
                                            {getText('promoter.campaign.general_information.category_2.title_podcast')}
                                        </label>
                                        <input
                                            id="grid-client"
                                            type="search"
                                            list="clientContactOptions"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder={getText('promoter.campaign.general_information.category_2.input_placeholder')}
                                            bind:value={client}
                                        />
                                    </div>
                                {/if}
                            </div>

                            <!-- <datalist id="clientContactOptions">
                                    {#each clientContacts as option}
                                        <option>{option}</option>
                                    {/each}
                                </datalist> -->
                        {/if}

                        {#if ctype == 'hostread'}
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-campaign-hostread-duration">
                                        {getText('promoter.campaign.general_information.category_4.title')}
                                    </label>
                                    <input
                                        id="grid-campaign-hostread-duration"
                                        type="number"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder={getText('promoter.campaign.general_information.category_4.input_placeholder')}
                                        bind:value={hostreadDurationMs}
                                    />
                                </div>
                            </div>
                        {/if}

                        {#if ctype == 'hostread'}
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-campaign-feedback-loops">
                                        {getText('promoter.campaign.general_information.category_5.title')}
                                    </label>
                                    <div class="text-center flex flex-wrap">
                                        <label for="grid-campaign-feedback-loop-script" class="mr-4 mt-2">
                                            <input
                                                id="grid-campaign-feedback-loop-script"
                                                type="checkbox"
                                                class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                bind:checked={campaignLoopsChecked.feedbackLoopScript}
                                                on:click={(e) => {
                                                    updateFeedbackLoops(e, 'script');
                                                }}
                                            />

                                            {getText('promoter.campaign.general_information.category_5.entry_1')}
                                        </label>
                                        <label for="grid-campaign-feedback-loop-audio" class="mr-4 mt-2">
                                            <input
                                                id="grid-campaign-feedback-loop-audio"
                                                type="checkbox"
                                                class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                bind:checked={campaignLoopsChecked.feedbackLoopAudio}
                                                on:click={(e) => {
                                                    updateFeedbackLoops(e, 'audio');
                                                }}
                                            />

                                            {getText('promoter.campaign.general_information.category_5.entry_2')}
                                        </label>
                                        <label for="grid-campaign-feedback-loop-none" class="mr-4 mt-2">
                                            <input
                                                id="grid-campaign-feedback-loop-none"
                                                type="checkbox"
                                                class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                bind:checked={campaignLoopsChecked.feedbackLoopNone}
                                                on:click={(e) => {
                                                    updateFeedbackLoops(e, 'none');
                                                }}
                                            />

                                            {getText('promoter.campaign.general_information.category_5.entry_3')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        {/if}

                        <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-campaign-roll-position">
                                    {getText('promoter.campaign.general_information.category_6.title')}
                                    {#if company.id != $missingLinkId}
                                        <CardTooltip text={getText('promoter.campaign.general_information.category_6.info')} />
                                    {/if}
                                </label>
                                <div class="text-center flex justify-between">
                                    <label for="grid-campaign-checkbox-roll-pre" class="mr-4 mt-2">
                                        <input
                                            id="grid-campaign-checkbox-roll-pre"
                                            type="checkbox"
                                            class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            bind:checked={campaignRollPositionChecked.rollPositionPre}
                                            on:click={(e) => {
                                                updatePositionRolls(e, 0);
                                            }}
                                        />
                                        {getText('general.roll_positions_missing_link.entry_1')}
                                    </label>
                                    <label for="grid-campaign-checkbox-roll-mid-1" class="mr-4 mt-2">
                                        <input
                                            id="grid-campaign-checkbox-roll-mid-1"
                                            type="checkbox"
                                            class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            bind:checked={campaignRollPositionChecked.rollPositionMid1}
                                            on:click={(e) => {
                                                updatePositionRolls(e, 1);
                                            }}
                                        />
                                        {getText('general.roll_positions_missing_link.entry_2')}
                                    </label>
                                    <label for="grid-campaign-checkbox-roll-mid-2" class="mr-4 mt-2">
                                        <input
                                            id="grid-campaign-checkbox-roll-mid-2"
                                            type="checkbox"
                                            class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            bind:checked={campaignRollPositionChecked.rollPositionMid2}
                                            on:click={(e) => {
                                                updatePositionRolls(e, 2);
                                            }}
                                        />
                                        {getText('general.roll_positions_missing_link.entry_3')}
                                    </label>
                                    <label for="grid-campaign-checkbox-roll-mid-3" class="mr-4 mt-2">
                                        <input
                                            id="grid-campaign-checkbox-roll-mid-3"
                                            type="checkbox"
                                            class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            bind:checked={campaignRollPositionChecked.rollPositionMid3}
                                            on:click={(e) => {
                                                updatePositionRolls(e, 3);
                                            }}
                                        />
                                        {getText('general.roll_positions_missing_link.entry_4')}
                                    </label>
                                    <!-- Missing Link Sonderlocke-->
                                    {#if company.id == $missingLinkId}
                                        <label for="grid-campaign-checkbox-roll-mid-4" class="mr-4 mt-2">
                                            <input
                                                id="grid-campaign-checkbox-roll-mid-4"
                                                type="checkbox"
                                                class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                bind:checked={campaignRollPositionChecked.rollPositionMid4}
                                                on:click={(e) => {
                                                    updatePositionRolls(e, 4);
                                                }}
                                            />
                                            {getText('general.roll_positions_missing_link.entry_5')}
                                        </label>
                                        <label for="grid-campaign-checkbox-roll-mid-5" class="mr-4 mt-2">
                                            <input
                                                id="grid-campaign-checkbox-roll-mid-5"
                                                type="checkbox"
                                                class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                bind:checked={campaignRollPositionChecked.rollPositionMid5}
                                                on:click={(e) => {
                                                    updatePositionRolls(e, 5);
                                                }}
                                            />
                                            {getText('general.roll_positions_missing_link.entry_6')}
                                        </label>
                                    {/if}
                                    <label for="grid-campaign-checkbox-roll-post" class="mr-4 mt-2">
                                        <input
                                            id="grid-campaign-checkbox-roll-post"
                                            type="checkbox"
                                            class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            bind:checked={campaignRollPositionChecked.rollPositionPost}
                                            on:click={(e) => {
                                                updatePositionRolls(e, 6);
                                            }}
                                        />
                                        {getText('general.roll_positions_missing_link.entry_7')}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr class="mt-6 border-b-1 border-blueGray-300" />

                    <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">{getText('promoter.campaign.date_information.title')}</h6>
                    <div class="flex flex-wrap">
                        {#if company.type !== 'podcast'}
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-acceptance-date">
                                        {getText('promoter.campaign.date_information.category_1.title')}
                                    </label>
                                    <input
                                        id="grid-acceptance-date"
                                        type="date"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        bind:value={acceptanceDeadline}
                                        on:change={handleDateChange}
                                    />
                                </div>
                            </div>
                        {/if}
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-start-date">
                                    {getText('promoter.campaign.date_information.category_2.title')}
                                </label>
                                <input
                                    id="grid-start-date"
                                    type="date"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    bind:value={startDate}
                                    on:change={handleDateChange}
                                />
                            </div>
                        </div>
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-end-date">
                                    {getText('promoter.campaign.date_information.category_3.title')}
                                </label>
                                <input
                                    id="grid-end-date"
                                    type="date"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    bind:value={endDate}
                                    on:change={handleDateChange}
                                />
                            </div>
                        </div>
                    </div>

                    <!-- TODO check if needed for inventory campaigns -->
                    <!-- {#if company.type == 'podcast'}
                        <hr class="mt-6 border-b-1 border-blueGray-300" />
                        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">{getText('promoter.targeting.title')}</h6>
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-target-impressions">
                                    {getText('promoter.targeting.category_1.entry_1')}
                                </label>
                                <input
                                    id="grid-target-impressions"
                                    type="number"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    bind:value={targetImpressions}
                                />
                            </div>
                        </div>
                    {/if} -->
                {/if}

                <!-- For campaign Edit-->
                {#if renderingMode == 2}
                    <div class="flex flex-wrap">
                        {#if ctype == 'crosspromo'}
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-title">
                                        {getText('promoter.campaign.general_information.category_1.title')}
                                    </label>
                                    <input
                                        id="grid-title"
                                        type="input"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        bind:value={campaign.title}
                                        on:change={updateEditMode}
                                    />
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-title">
                                        {getText('promoter.campaign.general_information.category_3.title')}
                                    </label>
                                    <input
                                        id="grid-requesting-podcast"
                                        type="input"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="Requesting podcast"
                                        readonly
                                        value={campaign.sender}
                                    />
                                </div>
                            </div>
                        {/if}

                        {#if ctype == 'hostread' || ctype == 'spot'}
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-title">
                                        {getText('promoter.campaign.general_information.category_1.title')}
                                    </label>
                                    <input
                                        id="grid-title"
                                        type="input"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        bind:value={campaign.title}
                                        on:change={updateEditMode}
                                    />
                                </div>
                            </div>

                            <div class="w-full lg:w-6/12 px-4">
                                {#if company.type !== 'podcast'}
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-client">
                                            {getText('promoter.campaign.general_information.category_2.title')}
                                        </label>
                                        <input
                                            id="grid-client"
                                            type="search"
                                            list="clientContactOptions"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder={getText('promoter.campaign.general_information.category_2.input_placeholder')}
                                            bind:value={campaign.client}
                                            on:change={updateEditMode}
                                        />
                                    </div>
                                {:else}
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-client">
                                            {getText('promoter.campaign.general_information.category_2.title')}
                                        </label>
                                        <input
                                            id="grid-client"
                                            type="search"
                                            list="clientContactOptions"
                                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder={getText('promoter.campaign.general_information.category_2.input_placeholder')}
                                            bind:value={campaign.client}
                                            on:change={updateEditMode}
                                        />
                                    </div>
                                {/if}
                            </div>

                            <!-- <datalist id="clientContactOptions">
                                {#each clientContacts as option}
                                    <option>{option}</option>
                                {/each}
                            </datalist> -->
                        {/if}
                    </div>

                    {#if ctype == 'hostread'}
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-campaign-hostread-duration">
                                    {getText('promoter.campaign.general_information.category_4.title')}
                                </label>
                                <input
                                    id="grid-campaign-hostrad-duration"
                                    type="number"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder={getText('promoter.campaign.general_information.category_4.input_placeholder')}
                                    bind:value={campaign.hostread_duration_ms}
                                    on:change={updateEditMode}
                                />
                            </div>
                        </div>
                    {/if}

                    {#if ctype == 'hostread'}
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-campaign-feedback-loops">
                                    {getText('promoter.campaign.general_information.category_5.title')}
                                </label>
                                <div class="text-center flex flex-wrap">
                                    <label for="grid-campaign-feedback-loop-script" class="mr-4 mt-2">
                                        <input
                                            id="grid-campaign-feedback-loop-script"
                                            type="checkbox"
                                            class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            bind:checked={campaignLoopsChecked.feedbackLoopScript}
                                            on:click={(e) => {
                                                updateFeedbackLoops(e, 'script');
                                            }}
                                            {disabled}
                                        />
                                        {getText('promoter.campaign.general_information.category_5.entry_1')}
                                    </label>

                                    <label for="grid-campaign-feedback-loop-audio" class="mr-4 mt-2">
                                        <input
                                            id="grid-campaign-feedback-loop-audio"
                                            type="checkbox"
                                            class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            bind:checked={campaignLoopsChecked.feedbackLoopAudio}
                                            on:click={(e) => {
                                                updateFeedbackLoops(e, 'audio');
                                            }}
                                            {disabled}
                                        />

                                        {getText('promoter.campaign.general_information.category_5.entry_2')}
                                    </label>

                                    <label for="grid-campaign-feedback-loop-none" class="mr-4 mt-2">
                                        <input
                                            id="grid-campaign-feedback-loop-none"
                                            type="checkbox"
                                            class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            bind:checked={campaignLoopsChecked.feedbackLoopNone}
                                            on:click={(e) => {
                                                updateFeedbackLoops(e, 'none');
                                            }}
                                            {disabled}
                                        />
                                        {getText('promoter.campaign.general_information.category_5.entry_3')}
                                    </label>
                                </div>
                            </div>
                        </div>
                    {/if}

                    <div class="w-full lg:w-12/12 px-4">
                        <div class="relative w-full mb-3">
                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-campaign-roll-position">
                                {getText('promoter.campaign.general_information.category_6.title')}
                                {#if company.id != $missingLinkId}
                                    <CardTooltip text={getText('promoter.campaign.general_information.category_6.info')} />
                                {/if}
                            </label>
                            <div class="text-center flex justify-between">
                                <label for="grid-campaign-checkbox-roll-pre" class="mr-4 mt-2">
                                    <input
                                        id="grid-campaign-checkbox-roll-pre"
                                        type="checkbox"
                                        class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                        bind:checked={campaignRollPositionChecked.rollPositionPre}
                                        on:click={(e) => {
                                            updatePositionRolls(e, 0);
                                        }}
                                        {disabled}
                                    />
                                    {getText('general.roll_positions_missing_link.entry_1')}
                                </label>
                                <label for="grid-campaign-checkbox-roll-mid-1" class="mr-4 mt-2">
                                    <input
                                        id="grid-campaign-checkbox-roll-mid-1"
                                        type="checkbox"
                                        class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                        bind:checked={campaignRollPositionChecked.rollPositionMid1}
                                        on:click={(e) => {
                                            updatePositionRolls(e, 1);
                                        }}
                                        {disabled}
                                    />
                                    {getText('general.roll_positions_missing_link.entry_2')}
                                </label>
                                <label for="grid-campaign-checkbox-roll-mid-2" class="mr-4 mt-2">
                                    <input
                                        id="grid-campaign-checkbox-roll-mid-2"
                                        type="checkbox"
                                        class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                        bind:checked={campaignRollPositionChecked.rollPositionMid2}
                                        on:click={(e) => {
                                            updatePositionRolls(e, 2);
                                        }}
                                        {disabled}
                                    />
                                    {getText('general.roll_positions_missing_link.entry_3')}
                                </label>
                                <label for="grid-campaign-checkbox-roll-mid-3" class="mr-4 mt-2">
                                    <input
                                        id="grid-campaign-checkbox-roll-mid-3"
                                        type="checkbox"
                                        class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                        bind:checked={campaignRollPositionChecked.rollPositionMid3}
                                        on:click={(e) => {
                                            updatePositionRolls(e, 3);
                                        }}
                                        {disabled}
                                    />
                                    {getText('general.roll_positions_missing_link.entry_4')}
                                </label>
                                <!-- Missing Link Sonderlocke-->
                                {#if company.id == $missingLinkId}
                                    <label for="grid-campaign-checkbox-roll-mid-4" class="mr-4 mt-2">
                                        <input
                                            id="grid-campaign-checkbox-roll-mid-4"
                                            type="checkbox"
                                            class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            bind:checked={campaignRollPositionChecked.rollPositionMid4}
                                            on:click={(e) => {
                                                updatePositionRolls(e, 4);
                                            }}
                                            {disabled}
                                        />
                                        {getText('general.roll_positions_missing_link.entry_5')}
                                    </label>
                                    <label for="grid-campaign-checkbox-roll-mid-5" class="mr-4 mt-2">
                                        <input
                                            id="grid-campaign-checkbox-roll-mid-5"
                                            type="checkbox"
                                            class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            bind:checked={campaignRollPositionChecked.rollPositionMid5}
                                            on:click={(e) => {
                                                updatePositionRolls(e, 5);
                                            }}
                                            {disabled}
                                        />
                                        {getText('general.roll_positions_missing_link.entry_6')}
                                    </label>
                                {/if}
                                <label for="grid-campaign-checkbox-roll-post" class="mr-4 mt-2">
                                    <input
                                        id="grid-campaign-checkbox-roll-post"
                                        type="checkbox"
                                        class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                        bind:checked={campaignRollPositionChecked.rollPositionPost}
                                        on:click={(e) => {
                                            updatePositionRolls(e, 6);
                                        }}
                                        {disabled}
                                    />
                                    {getText('general.roll_positions_missing_link.entry_7')}
                                </label>
                            </div>
                        </div>
                    </div>

                    <hr class="mt-6 border-b-1 border-blueGray-300" />

                    <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">{getText('promoter.campaign.date_information.title')}</h6>
                    <div class="flex flex-wrap">
                        {#if company.type !== 'podcast'}
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="relative w-full mb-3">
                                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-acceptance-date">
                                        {getText('promoter.campaign.date_information.category_1.title')}
                                    </label>
                                    <input
                                        id="grid-acceptance-date"
                                        type="date"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        bind:value={campaignAcceptanceDeadline}
                                        on:change={handleDateChange}
                                    />
                                </div>
                            </div>
                        {/if}

                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-start-date"> {getText('promoter.campaign.date_information.category_2.title')} </label>
                                <input
                                    id="grid-start-date"
                                    type="date"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    bind:value={campaignStartDate}
                                    on:change={handleDateChange}
                                />
                            </div>
                        </div>
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-end-date"> {getText('promoter.campaign.date_information.category_3.title')} </label>
                                <input
                                    id="grid-end-date"
                                    type="date"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    bind:value={campaignEndDate}
                                    on:change={handleDateChange}
                                />
                            </div>
                        </div>
                    </div>

                    <!-- TODO check if needed for inventory campaigns -->
                    <!-- {#if company.type == 'podcast'}
                        <hr class="mt-6 border-b-1 border-blueGray-300" />
                        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">{getText('promoter.targeting.title')}</h6>
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-target-impressions">
                                    {getText('promoter.targeting.category_1.entry_1')}
                                </label>
                                <input
                                    id="grid-target-impressions"
                                    type="number"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    bind:value={targetImpressions}
                                />
                            </div>
                        </div>
                    {/if} -->
                {/if}
            </form>
        </div>
    </div>

    <!-- BRIEFING -->
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-100 border-1">
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            {#if renderingMode == 1}
                <div class="flex flex-wrap">
                    {#if ctype == 'hostread'}
                        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">{getText('promoter.campaign.briefing_information.category_1.title')}</h6>
                        <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="mt-4 block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-script">
                                    {getText('promoter.campaign.briefing_information.category_1.entry_1.title')}
                                </label>
                                <textarea
                                    id="grid-script"
                                    type="input"
                                    class="mono border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    rows="10"
                                    placeholder={getText('promoter.campaign.briefing_information.category_1.entry_1.input_placeholder')}
                                    bind:value={script}
                                />
                            </div>
                            {#if script.length > 0}
                                <CardBadgeTag text="{script.split(' ').join('').length} {getText('badges.text_editor.entry_1')}" backgroundColor={BADGE_BACKGROUND_WORDCOUNT} />
                                <CardBadgeTag text="{script.split(' ').length} {getText('badges.text_editor.entry_2')}" backgroundColor={BADGE_BACKGROUND_WORDCOUNT} />
                            {/if}
                        </div>

                        <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-background-information">
                                    {getText('promoter.campaign.briefing_information.category_2.entry_1.title')}
                                </label>
                                <textarea
                                    id="grid-background-information"
                                    type="input"
                                    class="mono border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    rows="4"
                                    placeholder={getText('promoter.campaign.briefing_information.category_2.entry_1.input_placeholder')}
                                    bind:value={backgroundInformation}
                                />
                            </div>
                        </div>
                        <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-goal">
                                    {getText('promoter.campaign.briefing_information.category_2.entry_2.title')}
                                </label>
                                <textarea
                                    id="grid-campaign-goal"
                                    type="input"
                                    class="mono border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    rows="4"
                                    placeholder={getText('promoter.campaign.briefing_information.category_2.entry_2.input_placeholder')}
                                    bind:value={goal}
                                />
                            </div>
                        </div>

                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-emerald-600 text-xs font-bold mb-2" for="grid-audio-keywords">
                                    {getText('promoter.campaign.briefing_information.category_2.entry_3.title_1')}
                                </label>
                                <input
                                    id="grid-audio-keywords"
                                    type="input"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder={getText('promoter.campaign.briefing_information.category_2.entry_3.input_placeholder')}
                                    bind:value={audioKeywords}
                                />
                            </div>
                        </div>
                        {#if seperatedAudioKeywords.length > 0}
                            <div class="w-full lg:w-6/12 px-4">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-audio-keywords">
                                    {getText('promoter.campaign.briefing_information.category_2.entry_3.title_2')}
                                </label>
                                {#each seperatedAudioKeywords as key}
                                    <CardBadgeClickableTag
                                        id={key}
                                        text={key}
                                        backgroundColor={BADGE_BACKGROUND_BRIEFING_KEYWORD_ADD}
                                        activeColor={BADGE_ACTIVE_BRIEFING_KEYWORD_ADD}
                                        textColor={BUTTON_TEXT_COLOR_DEFAULT}
                                        classFont="font-bold"
                                        classPY="py-2"
                                        classPX="px-4"
                                        classRounded="rounded"
                                        on:click={() => {
                                            removeAudioKeyword(key);
                                        }}
                                    />
                                {/each}
                            </div>
                        {/if}

                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-red-600 text-xs font-bold mb-2" for="grid-excluded-keywords">
                                    {getText('promoter.campaign.briefing_information.category_2.entry_4.title_1')}
                                </label>
                                <input
                                    id="grid-excluded-keywords"
                                    type="input"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder={getText('promoter.campaign.briefing_information.category_2.entry_4.input_placeholder')}
                                    bind:value={excludedKeywords}
                                />
                            </div>
                        </div>
                        {#if seperatedExcludedKeywords.length > 0}
                            <div class="w-full lg:w-6/12 px-4">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-audio-keywords">
                                    {getText('promoter.campaign.briefing_information.category_2.entry_4.title_2')}
                                </label>
                                {#each seperatedExcludedKeywords as key}
                                    <CardBadgeClickableTag
                                        id={key}
                                        text={key}
                                        backgroundColor={BADGE_BACKGROUND_BRIEFING_KEYWORD_REMOVE}
                                        activeColor={BADGE_ACTIVE_BRIEFING_KEYWORD_REMOVE}
                                        textColor={BUTTON_TEXT_COLOR_DEFAULT}
                                        classFont="font-bold"
                                        classPY="py-2"
                                        classPX="px-4"
                                        classRounded="rounded"
                                        on:click={() => {
                                            removeExcludedKeyword(key);
                                        }}
                                    />
                                {/each}
                            </div>
                        {/if}

                        <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-briefing-url">
                                    {getText('promoter.campaign.briefing_information.category_2.entry_5.title')}
                                </label>
                                <input
                                    id="grid-briefing-url"
                                    type="text"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder={getText('promoter.campaign.briefing_information.category_2.entry_5.input_placeholder')}
                                    bind:value={briefingURL}
                                />
                            </div>
                        </div>

                        <hr class="mt-6 border-b-1 border-blueGray-300" />

                        <div class="w-full lg:w-12/12">
                            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">{getText('promoter.campaign.briefing_information.category_3.title')}</h6>
                        </div>

                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-campaign-upload-hear-and-feel">
                                    {getText('promoter.campaign.briefing_information.category_3.entry_1.title')}
                                </label>
                                <span class="block text-blueGray-600 text-xs mb-2">{getText('promoter.campaign.briefing_information.category_3.entry_1.description')}</span>
                                <form>
                                    <input
                                        id="grid-campaign-upload-hear-and-feel"
                                        class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        type="file"
                                        bind:files={fileHearFeel}
                                    />
                                </form>
                            </div>
                        </div>

                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-campaign-upload-sound-effects">
                                    {getText('promoter.campaign.briefing_information.category_3.entry_2.title')}
                                </label>
                                <span class="block text-blueGray-600 text-xs mb-2">
                                    {getText('promoter.campaign.briefing_information.category_3.entry_2.description')}
                                </span>
                                <form>
                                    <input
                                        id="grid-campaign-upload-sound-effects"
                                        class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        type="file"
                                        bind:files={fileSoundEffects}
                                    />
                                    <input
                                        id="grid-campaign-sound-effects-url"
                                        type="input"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder={getText('promoter.campaign.briefing_information.category_3.entry_2.input_placeholder')}
                                        bind:value={briefingURLSoundEffects}
                                    />
                                </form>
                            </div>
                        </div>

                        <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-briefing-url">
                                    {getText('promoter.campaign.briefing_information.category_3.entry_3.title')}
                                </label>
                                <input
                                    id="grid-briefing-url"
                                    type="input"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder={getText('promoter.campaign.briefing_information.category_3.entry_3.input_placeholder')}
                                    bind:value={briefingSupplementaryURL}
                                />
                            </div>
                        </div>
                    {/if}

                    {#if ctype == 'spot' || ctype == 'crosspromo'}
                        <div class="mt-4 w-full lg:w-12/12 px-4">
                            <div class="relative w-full">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-script">
                                    {getText('promoter.campaign.audio_spot_upload.title_1')}
                                </label>
                                <form>
                                    <input
                                        id="grid-audio-upload"
                                        class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        type="file"
                                        bind:files={fileAudio}
                                    />
                                    {#if fileAudio}
                                        <CardButton
                                            id="submit-audio"
                                            type="go"
                                            text={getText('promoter.campaign.audio_spot_upload.button_upload')}
                                            on:click={() => submitForm(fileAudio, 'audio', 'fileAudio')}
                                        />
                                    {/if}
                                    {#if audioUploadedSucess}
                                        <p
                                            id="grid-audio-file"
                                            class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        >
                                            {getText('promoter.campaign.audio_spot_upload.label_1')} <a class="underline" target="_blank" href={audiofileUrl}>{audiofileUrl}</a>
                                            <i
                                                class="fas fa-copy active:color-emerald-600"
                                                on:click={() => {
                                                    copyToClipboard(audiofileUrl);
                                                }}
                                            />
                                        </p>
                                        <AudioPlayer bind:src={audiofileUrl} id="grid-spot-audio-player" />
                                        <div class="flex flex-wrap">
                                            <div
                                                class="lg:w-12/12 border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            >
                                                {getText('promoter.campaign.audio_spot_upload.label_2', databaseToPrettyDateTime(audiofileUploadedDate))}
                                            </div>
                                        </div>
                                    {/if}
                                </form>
                            </div>
                        </div>
                        {#if audioUploadedSucess}
                            <div class="w-full lg:w-6/12 px-4">
                                <div class="flex flex-wrap">
                                    <div
                                        class="lg:w-12/12 border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    >
                                        {getText('promoter.campaign.general_information.category_4.title')}: {duration_ms / 1000}
                                    </div>
                                </div>
                            </div>
                        {/if}
                    {/if}

                    {#if ctype == 'hostread'}
                        <div>
                            <hr class="mt-6 border-b-1 border-blueGray-300" />

                            {#if ctype == 'hostread'}
                                <div>
                                    <hr class="mt-6 border-b-1 border-blueGray-300" />

                                    {#if company.type !== 'podcast'}
                                        {#if !campaign.briefing_completed}
                                            <div class="relative flex flex-col min-w-0 break-words w-full rounded bg-lightBlue-200 border-1 px-4 lg:px-10 py-10 pt-0">
                                                <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                                    {getText('promoter.campaign.briefing_information.category_4.title')}
                                                </h6>
                                                <div class="flex flex-wrap">
                                                    <div class="w-full lg:w-12/12 px-4">
                                                        <div class="relative w-full mb-3">
                                                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-briefing-completed">
                                                                {getText('promoter.campaign.briefing_information.category_4.description')}
                                                            </label>

                                                            <input
                                                                id="grid-briefing-completed"
                                                                type="checkbox"
                                                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                                on:click={updateBriefingCompleted(campaign.id)}
                                                            />
                                                            <span class="ml-2 text-sm font-semibold text-blueGray-600">
                                                                {getText('promoter.campaign.briefing_information.category_4.label_1')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        {:else}
                                            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">{getText('promoter.campaign.briefing_information.category_4.title')}</h6>
                                            <div class="flex flex-wrap">
                                                <div class="w-full lg:w-12/12 px-4">
                                                    <div class="relative w-full mb-3">
                                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-briefing-completed">
                                                            {getText('promoter.campaign.briefing_information.category_4.label_2')}
                                                        </label>

                                                        <input
                                                            id="grid-briefing-completed"
                                                            type="checkbox"
                                                            checked
                                                            {disabled}
                                                            class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                        />
                                                        <span class="ml-2 text-sm font-semibold text-blueGray-600">
                                                            {getText('promoter.campaign.briefing_information.category_4.label_2', databaseToPrettyDateTime(campaign.briefing_last_updated_date))}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        {/if}
                                    {/if}

                                    {#if company.type == 'podcast'}
                                        {#if !campaign.briefing_completed}
                                            <div class="relative flex flex-col min-w-0 break-words w-full rounded bg-lightBlue-200 border-1 px-4 lg:px-10 py-10 pt-0">
                                                <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                                    {getText('promoter.campaign.briefing_information.category_4.title')}
                                                </h6>
                                                <div class="flex flex-wrap">
                                                    <div class="w-full lg:w-12/12 px-4">
                                                        <div class="relative w-full mb-3">
                                                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-briefing-completed">
                                                                {getText('promoter.campaign.briefing_information.category_4.description_podcast_campaign')}
                                                            </label>

                                                            <input
                                                                id="grid-briefing-completed"
                                                                type="checkbox"
                                                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                                on:click={updateBriefingCompleted(campaign.id)}
                                                            />
                                                            <span class="ml-2 text-sm font-semibold text-blueGray-600">
                                                                {getText('promoter.campaign.briefing_information.category_4.label_1_podcast_campaign')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        {:else}
                                            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">{getText('promoter.campaign.briefing_information.category_4.title')}</h6>
                                            <div class="flex flex-wrap">
                                                <div class="w-full lg:w-12/12 px-4">
                                                    <div class="relative w-full mb-3">
                                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-briefing-completed">
                                                            {getText('promoter.campaign.briefing_information.category_4.label_2_podcast_campaign')}
                                                        </label>

                                                        <input
                                                            id="grid-briefing-completed"
                                                            type="checkbox"
                                                            checked
                                                            {disabled}
                                                            class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                        />
                                                        <span class="ml-2 text-sm font-semibold text-blueGray-600">
                                                            {getText(
                                                                'promoter.campaign.briefing_information.category_4.label_3_podcast_campaign',
                                                                databaseToPrettyDateTime(campaign.briefing_last_updated_date),
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        {/if}
                                    {/if}
                                </div>
                            {/if}
                        </div>
                    {/if}
                </div>
            {/if}

            {#if renderingMode == 2}
                <div class="flex flex-wrap">
                    {#if ctype == 'hostread'}
                        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                            {getText('promoter.campaign.briefing_information.category_1.title')}
                        </h6>
                        <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="mt-4 block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-script">
                                    {getText('promoter.campaign.briefing_information.category_1.entry_1.title')}
                                </label>
                                {#if !campaign.briefing_completed}
                                    <textarea
                                        id="grid-script"
                                        type="input"
                                        class="mono border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        rows="4"
                                        placeholder={getText('promoter.campaign.briefing_information.category_1.entry_1.input_placeholder')}
                                        bind:value={scriptEdited}
                                        on:change={updateEditMode}
                                    />
                                    <CardBadgeTag text="{scriptEdited.split(' ').join('').length} {getText('badges.text_editor.entry_1')}" backgroundColor={BADGE_BACKGROUND_WORDCOUNT} />
                                    <CardBadgeTag text="{scriptEdited.split(' ').length} {getText('badges.text_editor.entry_2')}" backgroundColor={BADGE_BACKGROUND_WORDCOUNT} />

                                    {#if scriptEdited !== campaign.script}
                                        <div class="text-center flex justify-end">
                                            <CardButton type="go" text={getText('promoter.campaign.briefing_information.category_1.entry_1.button_update')} on:click={() => updateCampaignScript()} />
                                        </div>
                                    {/if}
                                {:else}
                                    <p
                                        id="grid-script"
                                        class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    >
                                        {campaign.script}
                                    </p>
                                    <CardBadgeTag text="{campaign.script.split(' ').join('').length} {getText('badges.text_editor.entry_1')}" backgroundColor={BADGE_BACKGROUND_WORDCOUNT} />
                                    <CardBadgeTag text="{campaign.script.split(' ').length} {getText('badges.text_editor.entry_2')}" backgroundColor={BADGE_BACKGROUND_WORDCOUNT} />
                                    <p
                                        id="grid-script"
                                        class="italic border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    >
                                        {getText('promoter.campaign.briefing_information.category_1.entry_1.label_1')}
                                    </p>
                                {/if}
                            </div>
                        </div>

                        <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-background-information">
                                    {getText('promoter.campaign.briefing_information.category_2.entry_1.title')}
                                </label>
                                <textarea
                                    id="grid-background-information"
                                    type="input"
                                    class="mono border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    rows="4"
                                    placeholder={getText('promoter.campaign.briefing_information.category_2.entry_1.input_placeholder')}
                                    bind:value={campaign.background_information}
                                    on:change={updateEditMode}
                                />
                            </div>
                        </div>
                        <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-goal">
                                    {getText('promoter.campaign.briefing_information.category_2.entry_2.title')}
                                </label>
                                <textarea
                                    id="grid-goal"
                                    type="input"
                                    class="mono border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    rows="4"
                                    placeholder={getText('promoter.campaign.briefing_information.category_2.entry_2.input_placeholder')}
                                    bind:value={campaign.goal}
                                    on:change={updateEditMode}
                                />
                            </div>
                        </div>
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-emerald-600 text-xs font-bold mb-2" for="grid-audio-keywords">
                                    {getText('promoter.campaign.briefing_information.category_2.entry_3.title_1')}
                                </label>
                                <input
                                    id="grid-audio-keywords"
                                    type="input"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder={getText('promoter.campaign.briefing_information.category_2.entry_3.input_placeholder')}
                                    bind:value={audioKeywords}
                                    on:change={updateEditMode}
                                />
                            </div>
                        </div>
                        {#if seperatedAudioKeywords.length > 0}
                            <div class="w-full lg:w-6/12 px-4">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-audio-keywords">
                                    {getText('promoter.campaign.briefing_information.category_2.entry_3.title_2')}
                                </label>
                                {#each seperatedAudioKeywords as key}
                                    <CardBadgeClickableTag
                                        id={key}
                                        text={key}
                                        backgroundColor={BADGE_BACKGROUND_BRIEFING_KEYWORD_ADD}
                                        activeColor={BADGE_ACTIVE_BRIEFING_KEYWORD_ADD}
                                        textColor={BUTTON_TEXT_COLOR_DEFAULT}
                                        classFont="font-bold"
                                        classPY="py-2"
                                        classPX="px-4"
                                        classRounded="rounded"
                                        on:click={() => {
                                            removeAudioKeyword(key);
                                        }}
                                    />
                                {/each}
                            </div>
                        {/if}
                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-red-600 text-xs font-bold mb-2" for="grid-excluded-keywords">
                                    {getText('promoter.campaign.briefing_information.category_2.entry_4.title_1')}
                                </label>
                                <input
                                    id="grid-excluded-keywords"
                                    type="input"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder={getText('promoter.campaign.briefing_information.category_2.entry_4.input_placeholder')}
                                    bind:value={excludedKeywords}
                                    on:change={updateEditMode}
                                />
                            </div>
                        </div>
                        {#if seperatedExcludedKeywords.length > 0}
                            <div class="w-full lg:w-6/12 px-4">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-excluded-keywords">
                                    {getText('promoter.campaign.briefing_information.category_2.entry_4.title_2')}
                                </label>
                                {#each seperatedExcludedKeywords as key}
                                    <CardBadgeClickableTag
                                        id={key}
                                        text={key}
                                        backgroundColor={BADGE_BACKGROUND_BRIEFING_KEYWORD_REMOVE}
                                        activeColor={BADGE_ACTIVE_BRIEFING_KEYWORD_REMOVE}
                                        textColor={BUTTON_TEXT_COLOR_DEFAULT}
                                        classFont="font-bold"
                                        classPY="py-2"
                                        classPX="px-4"
                                        classRounded="rounded"
                                        on:click={() => {
                                            removeExcludedKeyword(key);
                                        }}
                                    />
                                {/each}
                            </div>
                        {/if}

                        <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-briefing-url">
                                    {getText('promoter.campaign.briefing_information.category_2.entry_5.title')}
                                </label>
                                <input
                                    id="grid-briefing-url"
                                    type="text"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder={getText('promoter.campaign.briefing_information.category_2.entry_5.input_placeholder')}
                                    bind:value={campaign.briefing_url}
                                    on:change={updateEditMode}
                                />
                            </div>
                        </div>

                        <hr class="mt-6 border-b-1 border-blueGray-300" />

                        <div class="w-full lg:w-12/12">
                            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">{getText('promoter.campaign.briefing_information.category_3.title')}</h6>
                        </div>

                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-campaign-file-hear-and-feel">
                                    {getText('promoter.campaign.briefing_information.category_3.entry_1.title')}</label
                                >
                                <span class="block text-blueGray-600 text-xs mb-2">
                                    {getText('promoter.campaign.briefing_information.category_3.entry_1.description')}
                                </span>
                                <form>
                                    <input
                                        id="grid-campaign-file-hear-and-feel"
                                        class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        type="file"
                                        bind:files={fileHearFeel}
                                        on:change={() => {
                                            showUpdateHearFeelButton = true;
                                        }}
                                    />
                                    {#if showUpdateHearFeelButton}
                                        <CardButton
                                            id="submit-hear-feel"
                                            type="go"
                                            text={getText('promoter.campaign.briefing_information.category_3.entry_1.button_update')}
                                            on:click={() => submitForm(fileHearFeel, 'briefing', 'fileHearFeel')}
                                        />
                                    {/if}
                                    {#if hearFeelUploadedSucess}
                                        <span class="block text-blueGray-600 text-xs mb-2">Updated: {hearFeelNameOriginal}</span>
                                        <span class="block text-blueGray-600 text-xs mb-2"><a class="underline" target="_blank" href={hearFeelURL}>{hearFeelURL}</a></span>
                                    {:else if campaign.hear_feel_url}
                                        <span class="block text-blueGray-600 text-xs mb-2"
                                            >{getText('promoter.campaign.audio_spot_upload.label_1')}
                                            {campaign.hear_feel_name_original}
                                        </span>
                                        <span class="block text-blueGray-600 text-xs mb-2"> <a class="underline" target="_blank" href={campaign.hear_feel_url}>{campaign.hear_feel_url}</a></span>
                                    {/if}
                                </form>
                            </div>
                        </div>

                        <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-campaign-file-sound-effects">
                                    {getText('promoter.campaign.briefing_information.category_3.entry_2.title')}</label
                                >
                                <span class="block text-blueGray-600 text-xs mb-2">{getText('promoter.campaign.briefing_information.category_3.entry_2.description')}</span>
                                <form>
                                    <input
                                        id="grid-campaign-file-sound-effects"
                                        class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        type="file"
                                        bind:files={fileSoundEffects}
                                        on:change={() => {
                                            showUpdateSoundEffectsButton = true;
                                        }}
                                    />

                                    {#if showUpdateSoundEffectsButton}
                                        <CardButton
                                            id="update-hear-feel"
                                            type="go"
                                            text={getText('promoter.campaign.briefing_information.category_3.entry_2.button_update')}
                                            on:click={() => submitForm(fileSoundEffects, 'briefing', 'fileSoundEffects')}
                                        />
                                    {/if}
                                    {#if soundEffectsUploadedSucess}
                                        <span class="block text-blueGray-600 text-xs mb-2">Updated: {soundEffectsNameOriginal} </span>
                                        <span class="block text-blueGray-600 text-xs mb-2"><a class="underline" target="_blank" href={soundEffectsURL}>{soundEffectsURL}</a></span>
                                    {:else if campaign.sound_effects_url}
                                        <span class="block text-blueGray-600 text-xs mb-2"
                                            >{getText('promoter.campaign.audio_spot_upload.label_1')}
                                            {campaign.sound_effects_name_original}</span
                                        >
                                        <span class="block text-blueGray-600 text-xs mb-2">
                                            <a class="underline" target="_blank" href={campaign.sound_effects_url}>{campaign.sound_effects_url}</a></span
                                        >
                                    {/if}

                                    <input
                                        id="grid-campaign-sound-effects-url"
                                        type="input"
                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder={getText('promoter.campaign.briefing_information.category_3.entry_2.input_placeholder')}
                                        bind:value={campaign.briefing_url_sound_effects}
                                        on:change={updateEditMode}
                                    />
                                </form>
                            </div>
                        </div>

                        <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-briefing-url">
                                    {getText('promoter.campaign.briefing_information.category_3.entry_3.title')}
                                </label>
                                <input
                                    id="grid-briefing-url"
                                    type="input"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder={getText('promoter.campaign.briefing_information.category_3.entry_3.input_placeholder')}
                                    bind:value={campaign.briefing_supplementary_url}
                                    on:change={updateEditMode}
                                />
                            </div>
                        </div>
                    {/if}
                </div>

                {#if ctype == 'spot' || ctype == 'crosspromo'}
                    {#if campaign.state < 9}
                        <div class="mt-4 w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-campaign-audio-upload">
                                    {getText('promoter.campaign.audio_spot_upload.title_1')}
                                </label>
                                <form>
                                    <input
                                        id="grid-audio-upload"
                                        class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        type="file"
                                        bind:files={fileAudio}
                                        on:change={() => {
                                            showUpdateAudioFileButton = true;
                                        }}
                                    />
                                    {#if showUpdateAudioFileButton}
                                        <CardButton
                                            id="update-audio"
                                            type="go"
                                            text={getText('promoter.campaign.audio_spot_upload.button_update')}
                                            on:click={() => submitForm(fileAudio, 'audio', 'fileAudio')}
                                        />
                                    {/if}
                                    {#if audioUploadedSucess}
                                        <p
                                            id="grid-audio-file"
                                            class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        >
                                            {getText('promoter.campaign.audio_spot_upload.label_1')} <a class="underline" target="_blank" href={audiofileUrl}>{audiofileUrl}</a>
                                            <i
                                                class="fas fa-copy active:color-emerald-600"
                                                on:click={() => {
                                                    copyToClipboard(audiofileUrl);
                                                }}
                                            />
                                        </p>
                                        <AudioPlayer bind:src={audiofileUrl} id="grid-spot-audio-player" />
                                        <div class="flex flex-wrap">
                                            <div
                                                class="lg:w-6/12 border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            >
                                                {getText('promoter.campaign.audio_spot_upload.label_2', databaseToPrettyDateTime(audiofileUploadedDate))}
                                            </div>
                                            <div
                                                class="lg:w-6/12 border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            >
                                                {getText('promoter.campaign.general_information.category_4.title')}: {duration_ms / 1000}
                                            </div>
                                        </div>
                                    {:else if !audioUploadedSucess && campaign.audiofile_url}
                                        <p
                                            id="grid-audio-file"
                                            class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        >
                                            {getText('promoter.campaign.audio_spot_upload.label_1')}
                                            {campaign.audiofile_name_original} /
                                            <a class="underline" target="_blank" href={campaign.audiofile_url}>{campaign.audiofile_url}</a>
                                            <i
                                                class="fas fa-copy active:color-emerald-600"
                                                on:click={() => {
                                                    copyToClipboard(campaign.audiofile_url);
                                                }}
                                            />
                                            <i
                                                class="fas fa-download py-1 mr-1 active:color-emerald-600"
                                                on:click={() => {
                                                    downloadFromUrl(campaign.audiofile_url, campaign.audiofile_name_original);
                                                }}
                                            />
                                        </p>
                                        <AudioPlayer bind:src={campaign.audiofile_url} id="grid-spot-audio-player" />
                                        <div class="flex flex-wrap">
                                            <div
                                                class="lg:w-6/12 border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            >
                                                {getText('promoter.campaign.audio_spot_upload.label_2', databaseToPrettyDateTime(campaign.audiofile_uploaded_date))}
                                            </div>
                                            {#if campaign.audiofile_duration_ms}
                                                <div
                                                    class="lg:w-6/12 border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                >
                                                    {getText('promoter.campaign.general_information.category_4.title')}: {campaign.audiofile_duration_ms / 1000}
                                                </div>
                                            {/if}
                                        </div>
                                    {/if}
                                </form>
                            </div>
                        </div>
                    {/if}

                    {#if campaign.state >= 9}
                        <div class="mt-4 w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-campaign-audio-upload">
                                    {getText('promoter.campaign.audio_spot_upload.title_2')}
                                </label>
                                <form>
                                    <p
                                        id="grid-audio-file"
                                        class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    >
                                        {getText('promoter.campaign.audio_spot_upload.label_1')}
                                        {campaign.audiofile_name_original} /
                                        <a class="underline" target="_blank" href={campaign.audiofile_url}>{campaign.audiofile_url}</a>
                                        <i
                                            class="fas fa-copy active:color-emerald-600"
                                            on:click={() => {
                                                copyToClipboard(audiofileUrl);
                                            }}
                                        />
                                        <i
                                            class="fas fa-download py-1 mr-1 active:color-emerald-600"
                                            on:click={() => {
                                                downloadFromUrl(campaign.audiofile_url, campaign.audiofile_name_original);
                                            }}
                                        />
                                    </p>
                                    <AudioPlayer bind:src={campaign.audiofile_url} id="grid-spot-audio-player" />
                                    <div class="flex flex-wrap">
                                        <div
                                            class="lg:w-12/12 border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        >
                                            {getText('promoter.campaign.audio_spot_upload.label_2', databaseToPrettyDateTime(campaign.audiofile_uploaded_date))}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    {/if}
                {/if}

                {#if ctype == 'hostread'}
                    <div>
                        <hr class="mt-6 border-b-1 border-blueGray-300" />

                        {#if !campaign.briefing_completed}
                            <div class="relative flex flex-col min-w-0 break-words w-full rounded bg-lightBlue-200 border-1 px-4 lg:px-10 py-10 pt-0">
                                <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                                    {getText('promoter.campaign.briefing_information.category_4.title')}
                                </h6>
                                <div class="flex flex-wrap">
                                    <div class="w-full lg:w-12/12 px-4">
                                        <div class="relative w-full mb-3">
                                            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-briefing-completed">
                                                {getText('promoter.campaign.briefing_information.category_4.description')}
                                            </label>

                                            <input
                                                id="grid-briefing-completed"
                                                type="checkbox"
                                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                on:click={updateBriefingCompleted(campaign.id)}
                                            />
                                            <span class="ml-2 text-sm font-semibold text-blueGray-600">
                                                {getText('promoter.campaign.briefing_information.category_4.label_1')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {:else}
                            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">Briefing Status</h6>
                            <div class="flex flex-wrap">
                                <div class="w-full lg:w-12/12 px-4">
                                    <div class="relative w-full mb-3">
                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-briefing-completed">
                                            {getText('promoter.campaign.briefing_information.category_4.label_2')}
                                        </label>

                                        <input
                                            id="grid-briefing-completed"
                                            type="checkbox"
                                            checked
                                            {disabled}
                                            class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                        />
                                        <span class="ml-2 text-sm font-semibold text-blueGray-600">
                                            {getText('promoter.campaign.briefing_information.category_4.label_3', databaseToPrettyDateTime(campaign.briefing_last_updated_date))}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        {/if}
                    </div>
                {/if}
            {/if}
        </div>
    </div>

    {#if !inventory}
        <!-- {#if !inventory && company.type !== 'podcast'} -->
        <CardCampaignTargeting bind:this={campaignTargeting} bind:status={campaignTargetingStatus} {campaign} />
    {/if}

    <div class="text-center flex justify-between">
        <div class="text-center flex">
            <CardButton type="cancel" text={getText('promoter.campaign.button_cancel')} on:click={() => handleCancel()} />
        </div>
        <div class="text-center flex">
            {#if company.type !== 'podcast'}
                <CardButton
                    id={$targetingEdited ? 'saveproceed' : 'saveexit'}
                    classes="saveproceed"
                    type="go"
                    text={$targetingEdited ? getSaveProceedTextByCtype(ctype) : getText('promoter.campaign.button_save')}
                    on:click={() => saveProceedExitOnClick()}
                />
            {/if}

            {#if company.type == 'podcast'}
                <CardButton
                    id={$targetingEdited ? 'saveproceed' : 'saveexit'}
                    classes="saveproceed"
                    type="go"
                    text={$targetingEdited ? getSaveProceedTextByCtype(ctype) : getText('promoter.campaign.button_save')}
                    on:click={() => saveProceedExitOnClick()}
                />
            {/if}
        </div>
    </div>
{/await}
