<script>
    import {Accordion, AccordionItem} from 'svelte-collapsible';
    import {getText} from '../../language.svelte';
    import {
        createNetworkCompanyPodcastFavoriteRelation,
        deleteNetworkCompanyPodcastFavoriteRelation,
        deleteNetworkCompanyPodcastExclusiveRelation,
        createNetworkCompanyPodcastExclusiveRelation,
    } from '../../api/api';
    import {prettyPrintNumber} from '../../functions';
    import {storedCompany, render} from '../../store.js';
    import ModalConfirm from '../Modals/ModalConfirm.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let podcast = {};
    export let view = 'all';
    export let filterKey = '';

    $render = Math.random();

    let accordionKey = null;
    const duration = 0.5; // seconds
    const easing = 'linear';

    function showfilteredKeyword(key) {
        filterKey = key;
    }

    async function selectFavorite() {
        const data = {company_id: $storedCompany.id, podcast_id: podcast.id};
        try {
            await createNetworkCompanyPodcastFavoriteRelation(data);
            podcast.favorite = true;
        } catch (e) {
            console.log('An error occoured adding podcast ${podcast.name} as favorite !');
        }
    }

    async function deselectFavorite() {
        try {
            await deleteNetworkCompanyPodcastFavoriteRelation($storedCompany.id, podcast.id);
            podcast.favorite = false;

            if (view != 'all') {
                document.getElementById(`grid-podcast-info-${podcast.id}`).style.display = 'none';
            }
        } catch (e) {
            console.log('An error occoured deleting podcast ${podcast.name} as favorite !');
        }
    }

    async function selectExclusive() {
        const data = {company_id: $storedCompany.id, podcast_id: podcast.id};
        try {
            await createNetworkCompanyPodcastExclusiveRelation(data);
            podcast.exclusiveNotConfirmed = true;
        } catch (e) {
            console.log('An error occoured adding podcast ${podcast.name} as exclusive podcast !');
        }
    }

    async function deselectExclusive() {
        try {
            await deleteNetworkCompanyPodcastExclusiveRelation($storedCompany.id, podcast.id);
            podcast.exclusiveConfirmed = false;
            podcast.exclusiveNotConfirmed = false;
            exclusiveAvailable = true;
            if (view != 'all') {
                document.getElementById(`grid-podcast-info-${podcast.id}`).style.display = 'none';
            }
        } catch (e) {
            console.log('An error occoured deleting podcast ${podcast.name} as exclusive podcast !');
        }
    }
</script>

<div class="mb-2 w-250" id="grid-podcast-info-{podcast}">
    <div class="justify-center items-center border-1 border-blueGray-300 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
        <div class="items-start justify-between p-5 border-solid border-blueGray-200 rounded-t-lg">
            <!-- IMAGE -->
            {#if podcast.preview_image}
                <img class="rounded" src={podcast.preview_image} width="30" height="30" alt="" />
            {:else}
                <i class="fas fa-podcast" />
            {/if}
            <!-- ICONS -->
            <div>
                {#if podcast.favorite}
                    <i class="fas fa-star text-emerald-500" on:click={deselectFavorite} />
                {:else}
                    <i class="fas fa-star" on:click={selectFavorite} />
                {/if}
                {#if podcast.exclusiveConfirmed}
                    <ModalConfirm buttonConfirm={getText('ui_messages.button_proceed')} buttonCancel={getText('ui_messages.button_cancel')} let:confirm={confirmThis}>
                        <i
                            class="fas fa-crown text-emerald-500"
                            on:click={() => {
                                confirmThis(deselectExclusive);
                            }}
                        />
                        <span slot="title">{getText('ui_messages.confirms.podcast.confirm_selfpromo_delete_title')}</span>
                        <span slot="description">{getText('ui_messages.confirms.promoter.confirm_exclusive_promotion_delete', podcast.name)} </span>
                    </ModalConfirm>
                {:else if podcast.exclusiveNotConfirmed}
                    <i class="fas fa-crown text-orange-500" on:click={deselectExclusive} />
                {:else if podcast.exclusiveAvailable}
                    <ModalConfirm buttonConfirm={getText('ui_messages.button_proceed')} buttonCancel={getText('ui_messages.button_cancel')} let:confirm={confirmThis}>
                        <i
                            class="fas fa-crown"
                            on:click={() => {
                                confirmThis(selectExclusive);
                            }}
                        />
                        <span slot="title">{getText('ui_messages.confirms.podcast.confirm_selfpromo_delete_title')}</span>
                        <span slot="description"
                            >{getText('ui_messages.confirms.promoter.confirm_exclusive_promotion_select', [$storedCompany.name, podcast.name, podcast.name, $storedCompany.name])}
                        </span>
                    </ModalConfirm>
                {/if}
            </div>
            <!-- NAME -->
            <h3 class="text-1xl font-semibold">{podcast.name}</h3>
            <!-- DESCRIPTION -->
            {#if podcast.summary}
                <h6 class="text-sm">{podcast.summary}</h6>
            {/if}
            {#if podcast.description}
                <div class="block w-full overflow-x-auto">
                    <Accordion bind:key={accordionKey} {duration} {easing}>
                        <AccordionItem key="Info">
                            <div slot="header">
                                <div
                                    class="header bg-white hover:cell-highlight-color text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs border-l-0 border-r-0 whitespace-nowrap font-semibold text-center rounded-lg"
                                >
                                    <div>
                                        <i class="fas fa-plus" />
                                    </div>
                                </div>
                            </div>

                            <p slot="body" class="body">
                                {#if accordionKey}
                                    <div class="bg-blueGray-100 border-t-0 align-middle border-l-0 border-r-0 mb-4 p-1 rounded-lg">
                                        <div class="my-1">
                                            <h6 class="text-sm">{podcast.description}</h6>
                                        </div>
                                    </div>
                                {/if}
                            </p>
                        </AccordionItem>
                    </Accordion>
                </div>
            {/if}
            {#if podcast.url}
                <a href={podcast.url} class="text-lightBlue-600 hover:text-lightBlue-800 text-sm block py-1 px-3" target="_blank"> Website </a>
            {/if}
            {#if podcast.keywords.length > 0}
                <div class="relative">
                    <span class="text-sm">{getText('promoter.network.podcast_info.title_2')}:</span>
                    {#each podcast.keywords as key}
                        <!-- TODO use badge instead of button when for live -->
                        <button
                            class="rounded-lg border bg-blueGray-100 border-blueGray-300 text-blueGray-600 font-bold uppercase text-xs px-2 mr-1"
                            id="grid-audio-key-tag-{key}"
                            on:click={() => {
                                showfilteredKeyword(key);
                            }}
                        >
                            {key}
                        </button>
                    {/each}
                </div>
            {/if}

            <div class="relative">
                <span class="text-sm">{getText('promoter.network.podcast_info.title_3')}:</span>
                <span class="text-sm">{prettyPrintNumber(podcast.max_booking_volume)}</span>
            </div>
        </div>
    </div>
</div>
