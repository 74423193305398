<script>
    import {getAllPodcasts, getBookingRequestsByCampaignIdCached, getCompanyPodcastFavorites, getCompanyPodcastExclusives, getAllExclusives, getAllPodcastsOfCompanyCached} from '../../api/api.js';
    import {Pulse} from 'svelte-loading-spinners';
    import {prettyPrintNumber} from '../../functions.js';
    import {storedCompany, missingLinkId} from '../../store.js';
    import {link} from 'svelte-routing';
    import {getText} from '../../language.svelte';
    import CardPodcastFilterBar from './CardPodcastFilterBar.svelte';

    export let color = 'light';
    export let campaign;
    export let status = 0;

    let podcasts = [];
    let deals = [];
    let originalPodcasts = [];

    $: podcasts, updatePodcasts();

    let totalTargetImpressions = campaign.target_impressions || 0;
    let requestedTargetImpressionsBase,
        requestedTargetImpressionsSum = 0;
    $: requestedTargetImpressions = prettyPrintNumber(requestedTargetImpressionsBase + requestedTargetImpressionsSum);
    $: remainingTargetImpressions = prettyPrintNumber(totalTargetImpressions - (requestedTargetImpressionsBase + requestedTargetImpressionsSum));
    $: remainingTargetImpressionsCalc = totalTargetImpressions - (requestedTargetImpressionsBase + requestedTargetImpressionsSum);

    let filterNeedle = '';

    let showFavs = false;
    let showExlusives = false;
    let showAll = true;
    let showFiltered = false;
    let filterKey;
    let filteredPodcasts = [];

    let favIds;
    let favs;
    let exclusiveConfirmedIds;
    let exclusives;

    $: (showFavs, showExlusives, showAll), updateFilterBarSet();

    export function getTotalTargetImpressions() {
        return totalTargetImpressions;
    }

    export function collectDeals() {
        let selector = "input:checked[type='checkbox'][data-id]";
        let inputs = Array.from(document.querySelectorAll(selector));
        let values = inputs.map((elem) => {
            return {
                podcast_id: elem.getAttribute('data-id'),
                requested_impressions: document.getElementById(`grid-requested-impressions-${elem.getAttribute('data-id')}`).value * 1,
            };
        });
        return values;
    }

    function updatePodcasts() {
        return podcasts;
    }

    function checkTotal() {
        if (remainingTargetImpressionsCalc < 0) {
            window.alert(getText('ui_messages.alerts.promoter.alert_targeting_lower_target_impressions'));
            totalTargetImpressions = requestedTargetImpressionsBase + requestedTargetImpressionsSum;
            remainingTargetImpressions = totalTargetImpressions - (requestedTargetImpressionsBase + requestedTargetImpressionsSum);
        }
    }

    function inputClickHandler(id) {
        let checkbox = document.getElementById(`grid-select-booking-request-${id}`);
        if (!checkbox.checked) {
            checkbox.checked = true;

            // write individual value into podcast object for CardPodcastFilterBar-memory
            podcasts.forEach((pod) => {
                if (pod.id == id) {
                    pod.checked = true;
                    pod.requested_impressions = parseInt(document.getElementById(`grid-requested-impressions-${id}`).value);
                }
            });

            updateRequestedImpressions(id);
        }
        updateRequestedImpressions(id);
    }

    function updateRequestedImpressions(id) {
        // update requestedTargetImpressionsSum
        let selector = "input:checked[type='checkbox'][data-id]";
        let inputs = Array.from(document.querySelectorAll(selector));
        let values = inputs.map((elem) => document.getElementById(`grid-requested-impressions-${elem.getAttribute('data-id')}`).value * 1);

        if (values.length) {
            requestedTargetImpressionsSum = values.reduce((a, b) => a + b);
            // check if input value crosses requestedTargetImpressionsSum threshold - if so: reset
            if (requestedTargetImpressionsSum > totalTargetImpressions) {
                window.alert(getText('ui_messages.alerts.promoter.alert_targeting_higher_target_impressions'));
                let input = document.getElementById(`grid-requested-impressions-${id}`);
                let valuesFiltered = values.filter((x) => x !== input.value * 1);
                let valuesReduced = valuesFiltered.reduce((a, b) => a + b);
                input.value = totalTargetImpressions - valuesReduced;

                // reset values for correct requestedTargetImpressionsSum
                requestedTargetImpressionsSum = valuesReduced + input.value * 1;
            }
        } else {
            requestedTargetImpressionsSum = 0;
        }

        updateStatusEvent();
    }

    function updateStatusEvent() {
        let selector = "input:checked[type='checkbox'][data-id]";
        let inputs = Array.from(document.querySelectorAll(selector));
        status = inputs.length > 0;
    }

    function setInitialRequestedImpression(target, value) {
        if (target.value > 0 && remainingTargetImpressionsCalc > 0) {
            target.value = parseInt(target.value) + remainingTargetImpressionsCalc;
        } else if (remainingTargetImpressionsCalc > 0) {
            target.value = value > remainingTargetImpressionsCalc ? remainingTargetImpressionsCalc : value;
        }
    }

    function filterPodcasts() {
        let items = Array.from(document.querySelectorAll('tr[data-filter]'));
        items.forEach((elem) => {
            let value = elem.getAttribute('data-filter').toLowerCase().indexOf(filterNeedle.toLowerCase()) !== -1;
            elem.style.display = value ? '' : 'none';
        });
    }
    $: filterNeedle, filterPodcasts();

    async function getDeals() {
        if (Object.keys(campaign).length > 0) {
            return await getBookingRequestsByCampaignIdCached(campaign.id);
        } else {
            return [];
        }
    }

    async function updateFilterBarSet() {
        if (showFavs) {
            podcasts = getFavPodcasts();
        } else if (showExlusives) {
            podcasts = getExclusivePodcasts();
        } else if (showAll) {
            podcasts = originalPodcasts;
        }
    }

    function getFavPodcasts() {
        let favPodcasts = [];
        for (const pod of originalPodcasts) {
            favIds.includes(pod.id) ? (pod.favorite = true && favPodcasts.push(pod)) : (pod.favorite = false);
        }
        return favPodcasts;
    }

    function getExclusivePodcasts() {
        const exclusivePodcasts = [];
        // catches the case that missing link also has the network tab in the sidebar
        if ($storedCompany.id == $missingLinkId) {
            for (const pod of podcasts) {
                exclusiveConfirmedIds.includes(pod.id) ? (pod.exclusiveConfirmed = true && exclusivePodcasts.push(pod)) : (pod.exclusiveConfirmed = false);
            }
        } else {
            for (const pod of originalPodcasts) {
                exclusiveConfirmedIds.includes(pod.id) ? (pod.exclusiveConfirmed = true && exclusivePodcasts.push(pod)) : (pod.exclusiveConfirmed = false);
            }
        }
        return exclusivePodcasts;
    }

    async function loadData() {
        podcasts = await getAllPodcasts();
        deals = await getDeals(campaign.id);

        let filteredPodcasts = [];

        // only show DEMO podcasts to DEMO accounts
        podcasts.forEach((podcast) => {
            if ($storedCompany.id == 137 || $storedCompany.id == 138) {
                podcast.checked = false;
                podcast.requested_impressions = 0;
                filteredPodcasts.push(podcast);
            } else if (!podcast.name.includes('DEMO')) {
                podcast.checked = false;
                podcast.requested_impressions = 0;
                filteredPodcasts.push(podcast);
            }
        });

        console.log('filteredPodcasts: ', filteredPodcasts);
        podcasts = filteredPodcasts;
        // debugger;

        // Enhance podcast-data with fav + exclusive flags
        favs = await getCompanyPodcastFavorites($storedCompany.id);
        favIds = favs.map((item) => item.podcast_id);

        exclusives = await getCompanyPodcastExclusives($storedCompany.id);
        exclusiveConfirmedIds = exclusives.map((item) => (item.confirmed ? item.podcast_id : ''));
        exclusiveConfirmedIds = exclusiveConfirmedIds.filter((e) => e !== '');

        // case missing link
        if ($storedCompany.id == $missingLinkId) {
            podcasts = getExclusivePodcasts();

            // Do not show podcasts with a booking request/deal for this campaign
            podcasts = podcasts.filter((item) => {
                if ($storedCompany.type == 'promoter') {
                    return (
                        deals.filter((i) => {
                            return i.podcast_id === item.id;
                        }).length === 0
                    );
                } else if ($storedCompany.type == 'podcast' && item.company_id == $storedCompany.id) {
                    return (
                        deals.filter((i) => {
                            return i.podcast_id === item.id;
                        }).length === 0
                    );
                }
            });

            // Sort podcasts alphabetically
            podcasts.sort((a, b) => {
                let fa = a.name.toLowerCase(),
                    fb = b.name.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });

            // deep copy of original podcasts for network filtering
            Object.assign(originalPodcasts, podcasts);

            requestedTargetImpressionsBase = deals
                .filter((deal) => {
                    return deal.state !== 4 && deal.state !== 6;
                })
                .map((deal) => (deal.offered_impressions > 0 ? deal.offered_impressions : deal.requested_impressions))
                .reduce((a, b) => a + b, 0);
        } else {
            // Do not show podcasts that have an exclusive relationship with another promoter
            const allExclusives = await getAllExclusives();

            // keep own podcasts for crosspromos - even if in exclusive relation
            const allCompanyPodcasts = await getAllPodcastsOfCompanyCached($storedCompany.id);
            let keepers = [];
            allCompanyPodcasts.forEach((item) => {
                keepers.push(item.id);
            });

            let exclude = [];
            allExclusives.forEach((item) => {
                item.confirmed && item.company_id !== $storedCompany.id && !keepers.includes(item.podcast_id) ? exclude.push(item.podcast_id) : '';
            });

            let podcastsFiltered = [];
            podcasts.forEach((item) => {
                !exclude.includes(item.id) ? podcastsFiltered.push(item) : '';
            });

            podcasts = podcastsFiltered;

            // Do not show podcasts with a booking request/deal for this campaign
            podcasts = podcasts.filter((item) => {
                if ($storedCompany.type == 'promoter') {
                    return (
                        deals.filter((i) => {
                            return i.podcast_id === item.id;
                        }).length === 0
                    );
                } else if ($storedCompany.type == 'podcast' && item.company_id == $storedCompany.id) {
                    return (
                        deals.filter((i) => {
                            return i.podcast_id === item.id;
                        }).length === 0
                    );
                }
            });

            // Sort podcasts alphabetically
            podcasts.sort((a, b) => {
                let fa = a.name.toLowerCase(),
                    fb = b.name.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });

            // deep copy of original podcasts for network filtering
            Object.assign(originalPodcasts, podcasts);

            requestedTargetImpressionsBase = deals
                .filter((deal) => {
                    return deal.state !== 4 && deal.state !== 6;
                })
                .map((deal) => (deal.offered_impressions > 0 ? deal.offered_impressions : deal.requested_impressions))
                .reduce((a, b) => a + b, 0);
        }
    }

    let podcastPromise = loadData();

    let selectionSort = false;
    let podcastSort = false;
    let bookingVolumeSort = false;
    let impressionRequestsSort = false;

    $: podcastSort, sortPodcasts('name');
    $: selectionSort, sortPodcasts('select');
    $: bookingVolumeSort, sortPodcasts('volume');
    $: impressionRequestsSort, sortPodcasts('requested');

    function toggleSelectionSort() {
        selectionSort = !selectionSort;
    }

    function togglePodcastSort() {
        podcastSort = !podcastSort;
    }

    function toggleBookingVolumeSort() {
        bookingVolumeSort = !bookingVolumeSort;
    }

    function toggleImpressionRequestsSort() {
        impressionRequestsSort = !impressionRequestsSort;
    }

    function sortPodcasts(keyword) {
        if (keyword == 'select') {
            let items = Array.from(document.querySelectorAll('tr[data-filter]'));
            if (items.length > 0) {
                if (selectionSort) {
                    [...items]
                        .sort((a, b) =>
                            document.getElementById(`grid-select-booking-request-${parseInt(a.getAttribute('data-id'))}`).checked >
                            document.getElementById(`grid-select-booking-request-${parseInt(b.getAttribute('data-id'))}`).checked
                                ? 1
                                : -1,
                        )
                        .forEach((node) => document.getElementById('tbody').appendChild(node));
                } else {
                    [...items]
                        .sort((a, b) =>
                            document.getElementById(`grid-select-booking-request-${parseInt(a.getAttribute('data-id'))}`).checked <
                            document.getElementById(`grid-select-booking-request-${parseInt(b.getAttribute('data-id'))}`).checked
                                ? 1
                                : -1,
                        )
                        .forEach((node) => document.getElementById('tbody').appendChild(node));
                }
            }
        } else if (keyword == 'name') {
            let items = Array.from(document.querySelectorAll('tr[data-filter]'));
            if (items.length > 0) {
                if (!podcastSort) {
                    [...items]
                        .sort((a, b) => (a.getAttribute('data-filter').toLocaleLowerCase() > b.getAttribute('data-filter').toLocaleLowerCase() ? 1 : -1))
                        .forEach((node) => document.getElementById('tbody').appendChild(node));
                } else {
                    [...items]
                        .sort((a, b) => (a.getAttribute('data-filter').toLocaleLowerCase() < b.getAttribute('data-filter').toLocaleLowerCase() ? 1 : -1))
                        .forEach((node) => document.getElementById('tbody').appendChild(node));
                }
            }
        } else if (keyword == 'volume') {
            let items = Array.from(document.querySelectorAll('tr[data-filter]'));
            if (items.length > 0) {
                if (bookingVolumeSort) {
                    [...items]
                        .sort((a, b) =>
                            parseInt(document.getElementById(`grid-max-booking-volume-${a.getAttribute('data-id')}`).innerText.replaceAll('.', '')) >
                            parseInt(document.getElementById(`grid-max-booking-volume-${b.getAttribute('data-id')}`).innerText.replaceAll('.', ''))
                                ? 1
                                : -1,
                        )
                        .forEach((node) => document.getElementById('tbody').appendChild(node));
                } else {
                    [...items]
                        .sort((a, b) =>
                            parseInt(document.getElementById(`grid-max-booking-volume-${a.getAttribute('data-id')}`).innerText.replaceAll('.', '')) <
                            parseInt(document.getElementById(`grid-max-booking-volume-${b.getAttribute('data-id')}`).innerText.replaceAll('.', ''))
                                ? 1
                                : -1,
                        )
                        .forEach((node) => document.getElementById('tbody').appendChild(node));
                }
            }
        } else if (keyword == 'requested') {
            let items = Array.from(document.querySelectorAll('tr[data-filter]'));
            if (items.length > 0) {
                if (impressionRequestsSort) {
                    [...items]
                        .sort((a, b) =>
                            parseInt(document.getElementById(`grid-requested-impressions-${a.getAttribute('data-id')}`).value) >
                            parseInt(document.getElementById(`grid-requested-impressions-${b.getAttribute('data-id')}`).value)
                                ? 1
                                : -1,
                        )
                        .forEach((node) => document.getElementById('tbody').appendChild(node));
                } else {
                    [...items]
                        .sort((a, b) =>
                            parseInt(document.getElementById(`grid-requested-impressions-${a.getAttribute('data-id')}`).value) <
                            parseInt(document.getElementById(`grid-requested-impressions-${b.getAttribute('data-id')}`).value)
                                ? 1
                                : -1,
                        )
                        .forEach((node) => document.getElementById('tbody').appendChild(node));
                }
            }
        }
    }
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-100 border-1">
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            {getText('promoter.targeting.title')}
        </h6>

        <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-target-impressions">
                    {getText('promoter.targeting.category_1.entry_1')}
                </label>
                <input
                    id="grid-target-impressions"
                    type="number"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    bind:value={totalTargetImpressions}
                    on:change={checkTotal}
                />
            </div>
        </div>

        <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-400 text-xs font-bold mb-2" for="grid-selected-impressions">
                        {getText('promoter.targeting.category_1.entry_2')}
                    </label>
                    <input
                        id="grid-selected-impressions"
                        type="text"
                        readonly="true"
                        disabled
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        bind:value={requestedTargetImpressions}
                    />
                </div>
            </div>

            <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-400 text-xs font-bold mb-2" for="grid-remaining-impressions">
                        {getText('promoter.targeting.category_1.entry_3')}
                    </label>
                    <input
                        id="grid-remaining-impressions"
                        type="text"
                        readonly="true"
                        disabled
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        bind:value={remainingTargetImpressions}
                    />
                </div>
            </div>
        </div>

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            {getText('promoter.targeting.category_2.title')}
        </h6>

        <!-- Search -->
        <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
                <div class="relative flex flex-wrap items-stretch" style="width: 18rem;">
                    <span class="z-10 h-full leading-snug font-normal text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                        <i class="fas fa-search" />
                    </span>
                    <input
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
                        type="text"
                        placeholder={getText('promoter.targeting.category_2.entry_1.input_placeholder')}
                        bind:value={filterNeedle}
                    />
                </div>
            </div>
        </div>

        <!-- only makes sense when network manager is activated -->
        {#if $storedCompany.id !== $missingLinkId}
            <CardPodcastFilterBar bind:showFavs bind:showExlusives bind:showAll bind:showFiltered bind:filterKey bind:filteredPodcasts />
        {/if}

        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">
            <div class="block w-full overflow-x-auto">
                <!-- Podcasts table -->
                <div class="scroller">
                    <table class="items-center w-full bg-transparent border-collapse" id="grid-targeting-table">
                        <thead>
                            <tr>
                                <th
                                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                        : 'bg-red-700 text-red-200 border-red-600'}"
                                >
                                    {getText('promoter.targeting.category_2.entry_2.headline_1')}
                                    {#if selectionSort}
                                        <i
                                            class="fas fa-sort-up mr-2 text-sm"
                                            on:click={() => {
                                                toggleSelectionSort();
                                            }}
                                        />
                                    {:else if !selectionSort}
                                        <i
                                            class="fas fa-sort-down mr-2 text-sm"
                                            on:click={() => {
                                                toggleSelectionSort();
                                            }}
                                        />
                                    {/if}
                                </th>
                                <th
                                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                        : 'bg-red-700 text-red-200 border-red-600'}"
                                >
                                    {getText('promoter.targeting.category_2.entry_2.headline_2')}
                                    {#if podcastSort}
                                        <i
                                            class="fas fa-sort-up mr-2 text-sm"
                                            on:click={() => {
                                                togglePodcastSort();
                                            }}
                                        />
                                    {:else if !podcastSort}
                                        <i
                                            class="fas fa-sort-down mr-2 text-sm"
                                            on:click={() => {
                                                togglePodcastSort();
                                            }}
                                        />
                                    {/if}
                                </th>
                                <th
                                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                        : 'bg-red-700 text-red-200 border-red-600'}"
                                >
                                    {getText('promoter.targeting.category_2.entry_2.headline_3')}
                                    {#if !bookingVolumeSort}
                                        <i
                                            class="fas fa-sort-up mr-2 text-sm"
                                            on:click={() => {
                                                toggleBookingVolumeSort();
                                            }}
                                        />
                                    {:else if bookingVolumeSort}
                                        <i
                                            class="fas fa-sort-down mr-2 text-sm"
                                            on:click={() => {
                                                toggleBookingVolumeSort();
                                            }}
                                        />
                                    {/if}
                                </th>
                                <th
                                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                                        : 'bg-red-700 text-red-200 border-red-600'}"
                                >
                                    {getText('promoter.targeting.category_2.entry_2.headline_4')}
                                    {#if impressionRequestsSort}
                                        <i
                                            class="fas fa-sort-up mr-2 text-sm"
                                            on:click={() => {
                                                toggleImpressionRequestsSort();
                                            }}
                                        />
                                    {:else if !impressionRequestsSort}
                                        <i
                                            class="fas fa-sort-down mr-2 text-sm"
                                            on:click={() => {
                                                toggleImpressionRequestsSort();
                                            }}
                                        />
                                    {/if}
                                </th>
                            </tr>
                        </thead>

                        <tbody id="tbody">
                            {#await podcastPromise}
                                <div style="display: flex; justify-content: center">
                                    <Pulse size="60" color="#FF3E00" unit="px" duration="1s" />
                                </div>
                            {:then data}
                                {#each podcasts as podcast}
                                    <tr data-filter={podcast.name} data-id={podcast.id} id="grid-targeting-table-row-{podcast.id}">
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                id="grid-select-booking-request-{podcast.id}"
                                                type="checkbox"
                                                data-id={podcast.id}
                                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                on:click={updateRequestedImpressions(podcast.id)}
                                                checked={podcast.checked}
                                            />
                                            <span class="ml-2 text-sm font-semibold text-blueGray-600" />
                                        </td>

                                        <td class="mutli-row-entries border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <div id="grid-podcast-name-{podcast.id}">
                                                <td class="border-t-0 align-middle border-l-0 border-r-0 p-4">
                                                    <a class="pr-4" use:link href={podcast.url} target="_blank">
                                                        <i class="mr-2 text-sm" src="url({podcast.preview_image})" />
                                                        {#if podcast.preview_image}
                                                            <img class="rounded" src={podcast.preview_image} width="25" height="25" alt="" />
                                                        {:else}
                                                            <i class="fas fa-podcast" />
                                                        {/if}
                                                    </a>
                                                    {podcast.name}
                                                </td>
                                            </div>
                                        </td>

                                        <td id="grid-max-booking-volume-{podcast.id}" class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {prettyPrintNumber(podcast.max_booking_volume)}
                                        </td>

                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <div class="w-full lg:w-12/12 px-4">
                                                <div class="relative w-full mb-3">
                                                    <input
                                                        id="grid-requested-impressions-{podcast.id}"
                                                        type="number"
                                                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        bind:value={podcast.requested_impressions}
                                                        on:click={() => {
                                                            inputClickHandler(podcast.id);
                                                        }}
                                                        on:input={() => {
                                                            updateRequestedImpressions(podcast.id);
                                                        }}
                                                        on:focus={(event) => {
                                                            setInitialRequestedImpression(event.currentTarget, podcast.max_booking_volume);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                {/each}
                            {/await}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
