<script>
    import {prettyPrintNumber, databaseToPrettyDate, getDateDiff, getDateNowDatetime} from '../../functions.js';
    import {getText} from '../../language.svelte';
    import CardTooltip from './CardTooltip.svelte';
    import {missingLinkId} from '../../store.js';

    export let bookingRequest;
    export let campaign;
    export let requested;
    export let offeredImpressions;
    export let showSave;
    export let showRollSave;
    export let backgroundColor = 'bg-blueGray-200';

    export let rollPositionPre = campaign.roll_position_pre;
    export let rollPositionMid1 = campaign.roll_position_mid_1;
    export let rollPositionMid2 = campaign.roll_position_mid_2;
    export let rollPositionMid3 = campaign.roll_position_mid_3;
    export let rollPositionMid4 = campaign.roll_position_mid_4;
    export let rollPositionMid5 = campaign.roll_position_mid_5;
    export let rollPositionPost = campaign.roll_position_post;
    export let rollPositionEdited;

    let campaignLoopsChecked = [
        {idx: 1, val: campaign.feedback_loop_script},
        {idx: 2, val: campaign.feedback_loop_audio},
        {idx: 3, val: campaign.feedback_loop_none},
    ];

    let campaignRollPositionChecked = [
        {idx: 1, val: campaign.roll_position_pre},
        {idx: 2, val: campaign.roll_position_mid_1},
        {idx: 3, val: campaign.roll_position_mid_2},
        {idx: 4, val: campaign.roll_position_mid_3},
        {idx: 5, val: campaign.roll_position_post},
    ];

    let bookingRequestRollPositionChecked = [
        {idx: 1, val: bookingRequest.roll_position_pre},
        {idx: 2, val: bookingRequest.roll_position_mid_1},
        {idx: 3, val: bookingRequest.roll_position_mid_2},
        {idx: 4, val: bookingRequest.roll_position_mid_3},
        {idx: 5, val: bookingRequest.roll_position_post},
    ];

    function toggleRollPositionEdited() {
        if (
            bookingRequestRollPositionChecked[0].val == bookingRequest.roll_position_pre &&
            bookingRequestRollPositionChecked[1].val == bookingRequest.roll_position_mid_1 &&
            bookingRequestRollPositionChecked[2].val == bookingRequest.roll_position_mid_2 &&
            bookingRequestRollPositionChecked[3].val == bookingRequest.roll_position_mid_3 &&
            bookingRequestRollPositionChecked[4].val == bookingRequest.roll_position_post
        ) {
            rollPositionEdited = false;
        } else {
            rollPositionEdited = true;
            rollPositionPre = bookingRequestRollPositionChecked[0].val;
            rollPositionMid1 = bookingRequestRollPositionChecked[1].val;
            rollPositionMid2 = bookingRequestRollPositionChecked[2].val;
            rollPositionMid3 = bookingRequestRollPositionChecked[3].val;
            rollPositionPost = bookingRequestRollPositionChecked[4].val;
        }
    }

    function updateRollPosition(e, roll) {
        const rolls = [1, 2, 3, 4, 5];

        rolls.forEach((r) => {
            bookingRequestRollPositionChecked[r - 1].val = false;
        });

        bookingRequestRollPositionChecked[roll - 1].val = !bookingRequestRollPositionChecked[roll - 1].val;

        // update bound variable
        roll == 1 ? (rollPositionPre = true) : (rollPositionPre = false);
        roll == 2 ? (rollPositionMid1 = true) : (rollPositionMid1 = false);
        roll == 3 ? (rollPositionMid2 = true) : (rollPositionMid2 = false);
        roll == 4 ? (rollPositionMid3 = true) : (rollPositionMid3 = false);
        roll == 5 ? (rollPositionPost = true) : (rollPositionPost = false);
    }

    // missing link sonderlocke
    let missingLinkCase = campaign.company_id == $missingLinkId ? true : false;

    let campaignRollPositionCheckedMissingLink = [
        {idx: 1, val: campaign.roll_position_pre},
        {idx: 2, val: campaign.roll_position_mid_1},
        {idx: 3, val: campaign.roll_position_mid_2},
        {idx: 4, val: campaign.roll_position_mid_3},
        {idx: 5, val: campaign.roll_position_mid_4},
        {idx: 6, val: campaign.roll_position_mid_5},
        {idx: 7, val: campaign.roll_position_post},
    ];

    let bookingRequestRollPositionCheckedMissingLink = [
        {idx: 1, val: bookingRequest.roll_position_pre},
        {idx: 2, val: bookingRequest.roll_position_mid_1},
        {idx: 3, val: bookingRequest.roll_position_mid_2},
        {idx: 4, val: bookingRequest.roll_position_mid_3},
        {idx: 5, val: bookingRequest.roll_position_mid_4},
        {idx: 6, val: bookingRequest.roll_position_mid_5},
        {idx: 7, val: bookingRequest.roll_position_post},
    ];

    function toggleRollPositionEditedMissingLink() {
        if (
            bookingRequestRollPositionCheckedMissingLink[0].val == bookingRequest.roll_position_pre &&
            bookingRequestRollPositionCheckedMissingLink[1].val == bookingRequest.roll_position_mid_1 &&
            bookingRequestRollPositionCheckedMissingLink[2].val == bookingRequest.roll_position_mid_2 &&
            bookingRequestRollPositionCheckedMissingLink[3].val == bookingRequest.roll_position_mid_3 &&
            bookingRequestRollPositionCheckedMissingLink[4].val == bookingRequest.roll_position_mid_4 &&
            bookingRequestRollPositionCheckedMissingLink[5].val == bookingRequest.roll_position_mid_5 &&
            bookingRequestRollPositionCheckedMissingLink[6].val == bookingRequest.roll_position_post
        ) {
            rollPositionEdited = false;
        } else {
            rollPositionEdited = true;
            rollPositionPre = bookingRequestRollPositionCheckedMissingLink[0].val;
            rollPositionMid1 = bookingRequestRollPositionCheckedMissingLink[1].val;
            rollPositionMid2 = bookingRequestRollPositionCheckedMissingLink[2].val;
            rollPositionMid3 = bookingRequestRollPositionCheckedMissingLink[3].val;
            rollPositionMid4 = bookingRequestRollPositionCheckedMissingLink[4].val;
            rollPositionMid5 = bookingRequestRollPositionCheckedMissingLink[5].val;
            rollPositionPost = bookingRequestRollPositionCheckedMissingLink[6].val;
        }
    }

    function updateRollPositionMissingLink(e, roll) {
        const rolls = [1, 2, 3, 4, 5, 6, 7];

        rolls.forEach((r) => {
            bookingRequestRollPositionCheckedMissingLink[r - 1].val = false;
        });

        bookingRequestRollPositionCheckedMissingLink[roll - 1].val = !bookingRequestRollPositionCheckedMissingLink[roll - 1].val;

        // update bound variable
        roll == 1 ? (rollPositionPre = true) : (rollPositionPre = false);
        roll == 2 ? (rollPositionMid1 = true) : (rollPositionMid1 = false);
        roll == 3 ? (rollPositionMid2 = true) : (rollPositionMid2 = false);
        roll == 4 ? (rollPositionMid3 = true) : (rollPositionMid3 = false);
        roll == 5 ? (rollPositionMid4 = true) : (rollPositionMid4 = false);
        roll == 6 ? (rollPositionMid5 = true) : (rollPositionMid5 = false);
        roll == 7 ? (rollPositionPost = true) : (rollPositionPost = false);
    }
</script>

<form>
    <div class="{backgroundColor} flex-auto mt-1 pt-1 px-4 lg:px-10 py-10 pt-0">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            {getText('podcast.booking_request_details.booking_information.title')}
        </h6>
        <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-start-date">
                        {getText('podcast.booking_request_details.booking_information.category_1.title')}
                    </label>
                    {#if campaign.state < 9}
                        <p
                            id="grid-start-date"
                            class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        >
                            {databaseToPrettyDate(campaign.start_date)} ({getDateDiff(getDateNowDatetime(), campaign.start_date)}
                            {getText('podcast.booking_request_details.booking_information.category_1.description')}
                        </p>
                    {:else}
                        <p
                            id="grid-start-date"
                            class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        >
                            {databaseToPrettyDate(campaign.start_date)} (0 {getText('podcast.booking_request_details.booking_information.category_1.description')}
                        </p>
                    {/if}
                </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-end-date">
                        {getText('podcast.booking_request_details.booking_information.category_2.title')}
                    </label>
                    {#if campaign.state < 10}
                        <p
                            id="grid-end-date"
                            class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        >
                            {databaseToPrettyDate(campaign.end_date)} ({getDateDiff(getDateNowDatetime(), campaign.end_date)}
                            {getText('podcast.booking_request_details.booking_information.category_2.description')}
                        </p>
                    {:else}
                        <p
                            id="grid-end-date"
                            class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        >
                            {databaseToPrettyDate(campaign.end_date)} (0 {getText('podcast.booking_request_details.booking_information.category_2.description')}
                        </p>
                    {/if}
                </div>
            </div>
            <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                    {#if campaign.type == 'hostread'}
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-acceptance-date">
                            {getText('podcast.booking_request_details.booking_information.category_3.title_hostread')}
                        </label>
                    {:else if campaign.type == 'spot'}
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-acceptance-date">
                            {getText('podcast.booking_request_details.booking_information.category_3.title_spot')}
                        </label>
                    {:else if campaign.type == 'crosspromo'}
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-acceptance-date">
                            {getText('podcast.booking_request_details.booking_information.category_3.title_crosspromo')}
                        </label>
                    {/if}
                    {#if campaign.state < 8}
                        <p
                            id="grid-acceptance-date"
                            class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        >
                            {databaseToPrettyDate(campaign.acceptance_deadline)} ({getDateDiff(getDateNowDatetime(), campaign.acceptance_deadline)}
                            {getText('podcast.booking_request_details.booking_information.category_3.description')}
                        </p>
                    {:else}
                        <p
                            id="grid-acceptance-date"
                            class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        >
                            {databaseToPrettyDate(campaign.acceptance_deadline)} (0 {getText('podcast.booking_request_details.booking_information.category_3.description')}
                        </p>
                    {/if}
                </div>
            </div>

            <div class="w-full lg:w-6/12 px-4 mt-5">
                <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-requested-impressions">
                        {getText('podcast.booking_request_details.booking_information.category_4.title')}
                    </label>
                    <p
                        id="grid-requested-impressions"
                        class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    >
                        {prettyPrintNumber(bookingRequest.requested_impressions)}
                    </p>
                </div>
            </div>

            <div class="w-full lg:w-6/12 px-4 mt-5">
                <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-offered-impressions">
                        {getText('podcast.booking_request_details.booking_information.category_5.title', prettyPrintNumber(bookingRequest.requested_impressions))}
                    </label>
                    {#if bookingRequest.state == 2 || (bookingRequest.state == 3 && bookingRequest.booking_changes_requested)}
                        <input
                            id="grid-offered-impressions"
                            type="number"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder={requested}
                            bind:value={offeredImpressions}
                            on:input={showSave}
                        />
                        <!-- {#if bookingRequest.state >= 8 || bookingRequest.state == 4 || bookingRequest.state == 6} -->
                    {:else if bookingRequest.state > 2}
                        <p
                            id="grid-offered-impressions"
                            class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        >
                            {prettyPrintNumber(bookingRequest.offered_impressions)}
                        </p>
                    {/if}
                </div>
            </div>

            {#if campaign.type == 'hostread' && campaign.hostread_duration_ms}
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-campaign-feedback-loops">
                            {getText('podcast.booking_request_details.booking_information.category_6.title')}
                        </label>
                        <div class="text-center flex flex-wrap">
                            <label for="grid-campaign-feedback-loop-script" class="mr-4 mt-2">
                                <p
                                    id="grid-requested-impressions"
                                    class="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 rounded text-sm focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                >
                                    {getText('podcast.booking_request_details.booking_information.category_6.description', campaign.hostread_duration_ms)}
                                </p>
                            </label>
                        </div>
                    </div>
                </div>
            {/if}

            {#if campaign.type == 'hostread'}
                <div class="w-full lg:w-6/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-campaign-feedback-loops">
                            {getText('podcast.booking_request_details.booking_information.category_7.title')}
                        </label>
                        <div class="text-center flex flex-wrap">
                            {#each campaignLoopsChecked as { idx, val }}
                                <label for="grid-campaign-feedback-loop-{idx}" class="mr-4 mt-2">
                                    <input
                                        id="grid-campaign-feedback-loop-{idx}"
                                        type="checkbox"
                                        class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                        bind:checked={val}
                                        disabled="disabled"
                                    />
                                    {getText(`podcast.booking_request_details.booking_information.category_7.entry_${idx}`)}
                                </label>
                            {/each}
                        </div>
                    </div>
                </div>
            {/if}

            <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-requested-impressions">
                        {getText('podcast.booking_request_details.booking_information.category_8.title')}
                    </label>
                    <div class="text-center flex justify-between">
                        {#if missingLinkCase}
                            {#each campaignRollPositionCheckedMissingLink as { idx, val }}
                                <label for="grid-campaign-checkbox-roll-${idx}" class="mr-4 mt-2">
                                    <input
                                        id="grid-campaign-checkbox-roll-${idx}"
                                        type="checkbox"
                                        class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                        bind:checked={val}
                                        disabled="disabled"
                                    />
                                    {getText(`general.roll_positions_missing_link.entry_${idx}`)}
                                </label>
                            {/each}
                        {:else}
                            {#each campaignRollPositionChecked as { idx, val }}
                                <label for="grid-campaign-checkbox-roll-${idx}" class="mr-4 mt-2">
                                    <input
                                        id="grid-campaign-checkbox-roll-${idx}"
                                        type="checkbox"
                                        class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                        bind:checked={val}
                                        disabled="disabled"
                                    />
                                    {getText(`general.roll_positions.entry_${idx}`)}
                                </label>
                            {/each}
                        {/if}
                    </div>
                </div>
            </div>
            <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-requested-impressions">
                        {getText('podcast.booking_request_details.booking_information.category_9.title')}
                        <CardTooltip text={getText('podcast.booking_request_details.booking_information.category_9.info')} />
                    </label>
                    <div class="text-center flex justify-between">
                        {#if missingLinkCase}
                            {#each bookingRequestRollPositionCheckedMissingLink as { idx, val }}
                                <label for="grid-campaign-checkbox-roll-{idx}" class="mr-4 mt-2">
                                    {#if bookingRequest.state == 2}
                                        <input
                                            id="grid-booking-request-checkbox-roll-{idx}"
                                            type="checkbox"
                                            class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            bind:checked={val}
                                            on:click={(e) => {
                                                updateRollPositionMissingLink(e, idx);
                                            }}
                                        />
                                    {:else if bookingRequest.state == 3 && bookingRequest.booking_changes_requested}
                                        <input
                                            id="grid-booking-request-checkbox-roll-{idx}"
                                            type="checkbox"
                                            class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            bind:checked={val}
                                            on:click={(e) => {
                                                updateRollPositionMissingLink(e, idx);
                                                toggleRollPositionEditedMissingLink();
                                                showRollSave();
                                            }}
                                        />
                                    {:else}
                                        <input
                                            id="grid-booking-request-checkbox-roll-{idx}"
                                            type="checkbox"
                                            class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            bind:checked={val}
                                            disabled="disabled"
                                        />
                                    {/if}
                                    {getText(`general.roll_positions_missing_link.entry_${idx}`)}
                                </label>
                            {/each}
                        {:else}
                            {#each bookingRequestRollPositionChecked as { idx, val }}
                                <label for="grid-campaign-checkbox-roll-{idx}" class="mr-4 mt-2">
                                    {#if bookingRequest.state == 2}
                                        <input
                                            id="grid-booking-request-checkbox-roll-{idx}"
                                            type="checkbox"
                                            class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            bind:checked={val}
                                            on:click={(e) => {
                                                updateRollPosition(e, idx);
                                            }}
                                        />
                                    {:else if bookingRequest.state == 3 && bookingRequest.booking_changes_requested}
                                        <input
                                            id="grid-booking-request-checkbox-roll-{idx}"
                                            type="checkbox"
                                            class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            bind:checked={val}
                                            on:click={(e) => {
                                                updateRollPosition(e, idx);
                                                toggleRollPositionEdited();
                                                showRollSave();
                                            }}
                                        />
                                    {:else}
                                        <input
                                            id="grid-booking-request-checkbox-roll-{idx}"
                                            type="checkbox"
                                            class="mr-2 form-checkbox align-midle border-0 px-3 py-3 rounded text-sm shadow text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            bind:checked={val}
                                            disabled="disabled"
                                        />
                                    {/if}
                                    {getText(`general.roll_positions.entry_${idx}`)}
                                </label>
                            {/each}
                        {/if}
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
