<script>
    import {getText} from '../../language.svelte';
    import {Accordion, AccordionItem} from 'svelte-collapsible';
    import {getAllPodcastsOfCompanyCached, getSelfpromosOfPodcast} from '../../api/api.js';
    import {storedCompany, podcastsSelfpromos, colorCodes, render} from '../../store.js';
    import CardInventorySettings from '../../components/Cards/CardInventorySettings.svelte';
    import CardInventoryExclusivePromotion from '../../components/Cards/CardInventoryExclusivePromotion.svelte';

    // can be one of light or dark table layout
    // export let color = 'light';

    let promisePodcasts = getPodcasts();
    $render = Math.random();

    // ACCORDION
    let accordionKey = null;
    const duration = 0.5; // seconds
    const easing = 'linear';

    // PROMISE
    async function getPodcasts() {
        try {
            return await getAllPodcastsOfCompanyCached($storedCompany.id);
        } catch (e) {
            console.log(`Error ${e} occoured whilst loading podcasts of company ${$storedCompany.id}`);
        }
    }
</script>

<!-- INFO BOX -->
<!-- <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between" />
        <h6 class="text-blueGray-700 mb-4 text-xl font-bold">{getText('podcast.inventory.title_general')}</h6>
        <div class="block w-full overflow-x-auto">
            <Accordion bind:key={accordionKey} {duration} {easing}>
                <AccordionItem key="Info">
                    <div slot="header">
                        <div
                            class="header bg-teal-100 hover:cell-highlight-color text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                        >
                            <div class="ml-2">
                                <h2>Info</h2>
                            </div>
                        </div>
                    </div>

                    <p slot="body" class="body">
                        {#if accordionKey}
                            <div class="bg-blueGray-100 border-t-0 align-middle border-l-0 border-r-0 mb-4">
                                <div class="ml-2">
                                    {getText('podcast.inventory.description')}
                                </div>
                            </div>
                        {/if}
                    </p>
                </AccordionItem>
            </Accordion>
        </div>
    </div>
</div> -->

<!-- TABLE PER PODCAST ID -->
<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between" />
        <h6 class="text-blueGray-700 mb-4 text-xl font-bold">{getText('podcast.inventory.title_general')}</h6>
        <div class="block w-full overflow-x-auto">
            <Accordion bind:key={accordionKey} {duration} {easing}>
                <AccordionItem key="Info">
                    <div slot="header">
                        <div
                            class="header bg-teal-100 hover:cell-highlight-color text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                        >
                            <div class="ml-2">
                                <h2>Info</h2>
                            </div>
                        </div>
                    </div>

                    <p slot="body" class="body">
                        {#if accordionKey}
                            <div class="bg-blueGray-100 border-t-0 align-middle border-l-0 border-r-0 mb-4">
                                <div class="ml-2">
                                    {getText('podcast.inventory.description')}
                                </div>
                            </div>
                        {/if}
                    </p>
                </AccordionItem>
            </Accordion>
            {#await promisePodcasts then data}
                <Accordion bind:key={accordionKey} {duration} {easing}>
                    <AccordionItem key="Settings">
                        <div slot="header">
                            <div
                                class="header bg-blueGray-100 hover:cell-highlight-color text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            >
                                <div class="ml-2">
                                    <h2>{getText('podcast.inventory.settings.section_title')}</h2>
                                </div>
                            </div>
                        </div>

                        <p slot="body" class="body">
                            {#if accordionKey}
                                {#each data as podcast}
                                    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                                        <div class="rounded-t bg-white mb-0 px-6 py-6">
                                            <div class="text-center flex justify-between" />
                                            <h6 class="text-blueGray-700 mb-4 text-xl font-bold">{getText('podcast.inventory.settings.card_title')} <i>{podcast.name}</i></h6>
                                            <div class="block w-full overflow-x-auto">
                                                <CardInventorySettings {podcast} />
                                            </div>
                                        </div>
                                    </div>
                                {/each}
                            {/if}
                        </p>
                    </AccordionItem>
                </Accordion>
                <Accordion bind:key={accordionKey} {duration} {easing}>
                    <AccordionItem key="Promoters">
                        <div slot="header">
                            <div
                                class="header bg-blueGray-100 hover:cell-highlight-color text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                            >
                                <div class="ml-2">
                                    <h2>{getText('podcast.inventory.exclusive_promoter.section_title')}</h2>
                                </div>
                            </div>
                        </div>

                        <p slot="body" class="body">
                            {#if accordionKey}
                                {#each data as podcast}
                                    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                                        <div class="rounded-t bg-white mb-0 px-6 py-6">
                                            <div class="text-center flex justify-between" />
                                            <h6 class="text-blueGray-700 mb-4 text-xl font-bold">
                                                {getText('podcast.inventory.exclusive_promoter.card_title')}
                                                <i>{podcast.name}</i>
                                            </h6>
                                            <div class="block w-full overflow-x-auto">
                                                <CardInventoryExclusivePromotion {podcast} />
                                            </div>
                                        </div>
                                    </div>
                                {/each}
                            {/if}
                        </p>
                    </AccordionItem>
                </Accordion>
            {/await}
        </div>
    </div>
</div>

<!-- {#await promisePodcasts then data}
    {#each data as podcast}
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
            <div class="rounded-t bg-white mb-0 px-6 py-6">
                <div class="text-center flex justify-between" />
                <h6 class="text-blueGray-700 mb-4 text-xl font-bold">{getText('podcast.inventory.settings.title')} <i>{podcast.name}</i></h6>
                <div class="block w-full overflow-x-auto">
                    <CardInventorySettings {podcast} />
                </div>
            </div>
        </div>
    {/each}
{/await} -->
