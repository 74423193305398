<script>
    import {storedCompany} from '../../store.js';
    import {getPodcastCached} from '../../api/api.js';
    import {prettyPrintNumber, databaseToPrettyDateTime, databaseToPrettyDate, getDateNow, addDaysToDateString, copyToClipboard} from '../../functions.js';
    import {getText} from '../../language.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    function calcDiffPercentage(val1, val2) {
        if (val1 == 0 || val2 == 0) {
            return 0;
        }
        let diff = val1 - val2;
        let avg = (val1 + val2) / 2;
        let diffTotal = Math.abs(diff) / avg;
        return parseInt(diffTotal * 100);
    }

    function calcPercantage(val1, val2) {
        return parseInt((val1 / val2) * 100);
    }

    function close() {
        bookingRequest = null;
    }

    export let bookingRequest;
    export let campaign;
    let position = [];

    if (bookingRequest.roll_position_pre) {
        position.push('pre-roll');
    }
    if (bookingRequest.roll_position_mid_1) {
        position.push('mid-roll 1');
    }
    if (bookingRequest.roll_position_mid_2) {
        position.push('mid-roll 2');
    }
    if (bookingRequest.roll_position_mid_3) {
        position.push('mid-roll 3');
    }
    if (bookingRequest.roll_position_post) {
        position.push('post-roll');
    }
</script>

{#if bookingRequest}
    {#await getPodcastCached(bookingRequest.podcast_id) then data}
        <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
            <div class="relative w-auto mt-20 my-6 mx-auto max-w-4xl">
                <div class="max-h-600px relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                    <div class="rounded-t mb-0 px-4 py-3 border-0">
                        <div class="flex flex-wrap items-center">
                            <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                                <h3 class="font-semibold text-base text-blueGray-700">
                                    <p>{getText('promoter.production_flow.title')} <em>{data.name}</em></p>
                                    <p>({campaign.title})</p>
                                </h3>
                            </div>
                            <div class="text-right">
                                <CardButton id="cancel" type="default" text="X" on:click={() => close()} />
                            </div>
                        </div>
                    </div>

                    <!-- Production Flow table -->
                    <div class="block w-full overflow-x-auto">
                        <table class="production-flow-table items-center w-full bg-transparent border-collapse">
                            <thead>
                                <tr>
                                    <th
                                        class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                    >
                                        {getText('promoter.production_flow.headline_1')}
                                    </th>
                                    <th
                                        class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                    >
                                        {getText('promoter.production_flow.headline_2')}
                                    </th>
                                    <th
                                        class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                    >
                                        {getText('promoter.production_flow.headline_3')}
                                    </th>
                                    <th
                                        class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                                    >
                                        {getText('promoter.production_flow.headline_4')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- Created -->
                                <tr>
                                    <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                        {getText('promoter.production_flow.category_1.title')}
                                    </th>
                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        <input
                                            type="checkbox"
                                            class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                            checked
                                            readonly
                                        />
                                        <span class="ml-2 text-sm font-semibold text-blueGray-600" />
                                    </td>
                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {databaseToPrettyDateTime(campaign.created)}
                                    </td>
                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {$storedCompany.name} </td>
                                </tr>

                                <!-- Activated -->
                                <tr>
                                    <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                        {getText('promoter.production_flow.category_2.title')}
                                    </th>
                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {#if bookingRequest.activated_date}
                                            <input
                                                type="checkbox"
                                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                checked
                                                readonly
                                            />
                                        {:else}
                                            <input type="checkbox" class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150" />
                                        {/if}
                                        <span class="ml-2 text-sm font-semibold text-blueGray-600" />
                                    </td>
                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {#if bookingRequest.activated_date}
                                            {databaseToPrettyDateTime(bookingRequest.activated_date)}
                                        {/if}
                                    </td>
                                    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {$storedCompany.name} </td>
                                </tr>

                                <!-- Acceptance Deadline -->
                                {#if getDateNow() < campaign.acceptance_deadline && !bookingRequest.activated}
                                    <tr class="relative flex-col min-w-0 break-words bg-blueGray-50 w-full mb-6 rounded italic">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            <div>{getText('promoter.production_flow.category_3.title_1')}</div>
                                            <div>{getText('promoter.production_flow.category_3.title_2')}</div>
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input type="checkbox" class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150" readonly />
                                        </td><td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {databaseToPrettyDate(campaign.acceptance_deadline)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {:else if getDateNow() < campaign.acceptance_deadline}
                                    <tr class="relative flex-col min-w-0 break-words bg-emerald-50 w-full mb-6 rounded italic">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            <div>{getText('promoter.production_flow.category_3.title_1')}</div>
                                            <div>{getText('promoter.production_flow.category_3.title_2')}</div>
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" /><td
                                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                        >
                                            {databaseToPrettyDate(campaign.acceptance_deadline)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {:else if getDateNow() > campaign.acceptance_deadline && bookingRequest.accepted}
                                    <tr class="relative flex-col min-w-0 break-words bg-emerald-50 w-full mb-6 rounded italic">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            <div>{getText('promoter.production_flow.category_3.title_1')}</div>
                                            <div>{getText('promoter.production_flow.category_3.title_2')}</div>
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                type="checkbox"
                                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                checked
                                                readonly
                                            />
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {databaseToPrettyDate(campaign.acceptance_deadline)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {:else if getDateNow() > campaign.acceptance_deadline && !bookingRequest.accepted}
                                    <tr class="relative flex-col min-w-0 break-words bg-red-50 w-full mb-6 rounded italic">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            <div>{getText('promoter.production_flow.category_3.title_1')}</div>
                                            <div>{getText('promoter.production_flow.category_3.title_2')}</div>
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" /><td
                                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                        >
                                            {databaseToPrettyDate(campaign.acceptance_deadline)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {/if}

                                <!-- Accepted / Rejected-->
                                {#if bookingRequest.state == 4}
                                    <tr class="bg-red-100">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_4.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                type="checkbox"
                                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                checked
                                                readonly
                                            />
                                            <span class="ml-2 text-sm font-semibold text-blueGray-600" />
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {databaseToPrettyDateTime(bookingRequest.rejected_date)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{data.name} </td>
                                    </tr>
                                {/if}

                                {#if bookingRequest.accepted}
                                    <tr>
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_5.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                type="checkbox"
                                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                checked
                                                readonly
                                            />
                                            <span class="ml-2 text-sm font-semibold text-blueGray-600" />
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {databaseToPrettyDateTime(bookingRequest.accepted_date)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{data.name} </td>
                                    </tr>
                                    <tr>
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left" />
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {#if position.length == 1}
                                                <!-- with {prettyPrintNumber(bookingRequest.offered_impressions)} of {prettyPrintNumber(bookingRequest.requested_impressions)} requested impressions as a {position} -->
                                                {getText('promoter.production_flow.category_5.description_1', prettyPrintNumber(bookingRequest.offered_impressions))}
                                                {getText('promoter.production_flow.category_5.description_2', prettyPrintNumber(bookingRequest.requested_impressions))}
                                                {position}
                                            {:else}
                                                <!-- with {prettyPrintNumber(bookingRequest.offered_impressions)} of {prettyPrintNumber(bookingRequest.requested_impressions)} requested impressions on positions -->
                                                {getText('promoter.production_flow.category_5.description_1', prettyPrintNumber(bookingRequest.offered_impressions))}
                                                {getText('promoter.production_flow.category_5.description_3', prettyPrintNumber(bookingRequest.requested_impressions))}
                                                {position}
                                            {/if}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {:else}
                                    <tr class="bg-blueGray-100">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_5.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <span class="ml-2 text-sm font-semibold text-blueGray-600" />
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {/if}

                                <!-- Agreed -->
                                <!-- {#if bookingRequest.agreed}
                                    <tr>
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> Agreed </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                type="checkbox"
                                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                checked
                                                readonly
                                            />
                                            <span class="ml-2 text-sm font-semibold text-blueGray-600" />
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {#if bookingRequest.agreed_date}
                                                {databaseToPrettyDateTime(bookingRequest.agreed_date)}
                                            {/if}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {$storedCompany.name} </td>
                                    </tr>
                                {:else if bookingRequest.state == 6}
                                    <tr class="bg-red-100">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> Cancelled </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                type="checkbox"
                                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                checked
                                                readonly
                                            />
                                            <span class="ml-2 text-sm font-semibold text-blueGray-600" />
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {databaseToPrettyDateTime(bookingRequest.cancelled_date)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{$storedCompany.name} </td>
                                    </tr>
                                {:else}
                                    <tr class="bg-blueGray-100">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> Agreed </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <span class="ml-2 text-sm font-semibold text-blueGray-600" />
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {/if} -->

                                <!-- Upload Deadline -->
                                <!-- {#if getDateNow() < campaign.upload_deadline && !bookingRequest.agreed}
                                    <tr class="relative flex-col min-w-0 break-words bg-blueGray-50 w-full mb-6 rounded italic">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> Upload Deadline </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" /><td
                                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                        >
                                            {databaseToPrettyDateTime(campaign.upload_deadline)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {:else if getDateNow() < campaign.upload_deadline}
                                    <tr class="relative flex-col min-w-0 break-words bg-emerald-50 w-full mb-6 rounded italic">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> Upload Deadline </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {databaseToPrettyDateTime(campaign.upload_deadline)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {:else if getDateNow() > campaign.upload_deadline && bookingRequest.audiofile_url}
                                    <tr class="relative flex-col min-w-0 break-words bg-emerald-50 w-full mb-6 rounded italic">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> Upload Deadline </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                type="checkbox"
                                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                checked
                                                readonly
                                            />
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {databaseToPrettyDateTime(campaign.upload_deadline)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {:else if getDateNow() > campaign.upload_deadline && !bookingRequest.audiofile_url}
                                    <tr class="relative flex-col min-w-0 break-words bg-red-50 w-full mb-6 rounded italic">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> Upload Deadline </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {databaseToPrettyDateTime(campaign.upload_deadline)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {/if} -->

                                <!-- Script Info
                                {#if campaign.briefing_completed}
                                    <tr>
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> Script (original) </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                type="checkbox"
                                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                checked
                                                readonly
                                            />
                                            <span class="ml-2 text-sm font-semibold text-blueGray-600" />
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {campaign.script}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {$storedCompany.name} </td>
                                    </tr>
                                    <tr>
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"> Script (current) </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                type="checkbox"
                                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                checked
                                                readonly
                                            />
                                            <span class="ml-2 text-sm font-semibold text-blueGray-600" />
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {bookingRequest.script}
                                        </td>
                                        {#if bookingRequest.script_updated_podcast}
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {data.name} </td>
                                        {:else if bookingRequest.script_updated_promoter}
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {$storedCompany.name} </td>
                                        {/if}
                                    </tr>
                                {/if} -->

                                <!-- Audiofile Uploaded -->
                                {#if bookingRequest.audiofile_url}
                                    <tr>
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_6.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                type="checkbox"
                                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                checked
                                                readonly
                                            />
                                            <span class="ml-2 text-sm font-semibold text-blueGray-600" />
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {#if bookingRequest.audiofile_uploaded_date}
                                                {databaseToPrettyDateTime(bookingRequest.audiofile_uploaded_date)}
                                            {/if}
                                        </td>
                                        {#if campaign.type == 'hostread'}
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {data.name} </td>
                                        {:else if campaign.type == 'spot' || campaign.type == 'crosspromo'}
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {$storedCompany.name} </td>
                                        {/if}
                                    </tr>
                                {:else}
                                    <tr class="bg-blueGray-100">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_6.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <span class="ml-2 text-sm font-semibold text-blueGray-600" />
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {/if}

                                <!-- Approved -->
                                {#if bookingRequest.audiofile_approved_date}
                                    <tr>
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_7.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                type="checkbox"
                                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                checked
                                                readonly
                                            />
                                            <span class="ml-2 text-sm font-semibold text-blueGray-600" />
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {#if bookingRequest.audiofile_approved_date}
                                                {databaseToPrettyDateTime(bookingRequest.audiofile_approved_date)}
                                            {/if}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {$storedCompany.name} </td>
                                    </tr>
                                {:else if bookingRequest.state == 6}
                                    <tr class="bg-red-100">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_8.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                type="checkbox"
                                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                checked
                                                readonly
                                            />
                                            <span class="ml-2 text-sm font-semibold text-blueGray-600" />
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {databaseToPrettyDateTime(bookingRequest.cancelled_date)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{$storedCompany.name} </td>
                                    </tr>
                                {:else}
                                    <tr class="bg-blueGray-100">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_7.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <span class="ml-2 text-sm font-semibold text-blueGray-600" />
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {/if}

                                <!-- Deal details -->
                                {#if bookingRequest.state >= 8 && bookingRequest.deal_id}
                                    <tr>
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_9.title')}
                                        </th>
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" colspan="3">
                                            <i
                                                class="fas fa-copy active:color-emerald-600"
                                                on:click={() => {
                                                    copyToClipboard(bookingRequest.deal_id);
                                                    // copyToClipboard('https://counter.svmaudio.com/impression/' + bookingRequest.deal_id);
                                                }}
                                            />
                                            {bookingRequest.deal_id}
                                        </th>
                                    </tr>
                                {/if}
                                {#if bookingRequest.state >= 8 && bookingRequest.vast_url}
                                    <tr>
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_10.title')}
                                        </th>
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" colspan="3">
                                            <i
                                                class="fas fa-copy active:color-emerald-600"
                                                on:click={() => {
                                                    copyToClipboard(bookingRequest.vast_url);
                                                }}
                                            />
                                            <a class="underline" target="_blank" href={bookingRequest.vast_url}>{bookingRequest.vast_url}</a>
                                        </th>
                                    </tr>
                                {/if}

                                <!-- CAMPAIGN START -->
                                {#if getDateNow() < campaign.start_date && !bookingRequest.approved}
                                    <tr class="relative flex-col min-w-0 break-words bg-blueGray-50 w-full mb-6 rounded italic">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_11.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {databaseToPrettyDate(campaign.start_date)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {:else if getDateNow() < campaign.start_date}
                                    <tr class="relative flex-col min-w-0 break-words bg-emerald-50 w-full mb-6 rounded italic">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_11.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {databaseToPrettyDate(campaign.start_date)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {:else if getDateNow() > campaign.start_date && bookingRequest.approved}
                                    <tr class="relative flex-col min-w-0 break-words bg-emerald-50 w-full mb-6 rounded italic">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_11.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                type="checkbox"
                                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                checked
                                                readonly
                                            />
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {databaseToPrettyDate(campaign.start_date)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {:else if getDateNow() > campaign.start_date && !bookingRequest.approved}
                                    <tr class="relative flex-col min-w-0 break-words bg-red-50 w-full mb-6 rounded italic">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_11.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {databaseToPrettyDate(campaign.start_date)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {/if}

                                <!-- CAMPAIGN END -->
                                {#if getDateNow() < campaign.end_date && !bookingRequest.approved}
                                    <tr class="relative flex-col min-w-0 break-words bg-blueGray-50 w-full mb-6 rounded italic">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_12.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {databaseToPrettyDate(campaign.end_date)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {:else if getDateNow() < campaign.end_date}
                                    <tr class="relative flex-col min-w-0 break-words bg-emerald-50 w-full mb-6 rounded italic">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_12.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {databaseToPrettyDate(campaign.end_date)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {:else if getDateNow() > campaign.end_date && bookingRequest.approved}
                                    <tr class="relative flex-col min-w-0 break-words bg-emerald-50 w-full mb-6 rounded italic">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_12.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                type="checkbox"
                                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                checked
                                                readonly
                                            />
                                        </td><td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {databaseToPrettyDate(campaign.end_date)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {:else}
                                    <tr class="relative flex-col min-w-0 break-words bg-red-50 w-full mb-6 rounded italic">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_12.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" /><td
                                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                        >
                                            {databaseToPrettyDate(campaign.end_date)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {/if}

                                <!-- Report Upload Deadline -->
                                {#if getDateNow() < campaign.end_date && !bookingRequest.approved}
                                    <tr class="relative flex-col min-w-0 break-words bg-blueGray-50 w-full mb-6 rounded italic">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_13.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" /><td
                                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                        >
                                            {addDaysToDateString(campaign.end_date, 30)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {:else if getDateNow() < campaign.end_date}
                                    <tr class="relative flex-col min-w-0 break-words bg-emerald-50 w-full mb-6 rounded italic">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_13.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" /><td
                                            class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                        >
                                            {addDaysToDateString(campaign.end_date, 30)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {:else if getDateNow() > campaign.end_date && bookingRequest.report}
                                    <tr class="relative flex-col min-w-0 break-words bg-emerald-50 w-full mb-6 rounded italic">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_13.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                type="checkbox"
                                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                checked
                                                readonly
                                            />
                                        </td><td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {addDaysToDateString(campaign.end_date, 30)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {:else if getDateNow() > campaign.end_date && !bookingRequest.report}
                                    <tr class="relative flex-col min-w-0 break-words bg-red-50 w-full mb-6 rounded italic">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_13.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {addDaysToDateString(campaign.end_date, 30)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {/if}

                                <!-- Report Uploaded -->
                                {#if bookingRequest.report}
                                    <tr>
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_14.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                type="checkbox"
                                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                checked
                                                readonly
                                            />
                                            <span class="ml-2 text-sm font-semibold text-blueGray-600" />
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {databaseToPrettyDateTime(bookingRequest.report_uploaded_date)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {data.name}
                                        </td>
                                    </tr>
                                {:else}
                                    <tr class="bg-blueGray-100">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_14.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <span class="ml-2 text-sm font-semibold text-blueGray-600" />
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {/if}
                                {#if bookingRequest.report_approved_date}
                                    <tr>
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_15.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <input
                                                type="checkbox"
                                                class="form-checkbox align-midle border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                                checked
                                                readonly
                                            />
                                            <span class="ml-2 text-sm font-semibold text-blueGray-600" />
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {databaseToPrettyDateTime(bookingRequest.report_approved_date)}
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {$storedCompany.name}
                                        </td>
                                    </tr>
                                {:else}
                                    <tr class="bg-blueGray-100">
                                        <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                            {getText('promoter.production_flow.category_15.title')}
                                        </th>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <span class="ml-2 text-sm font-semibold text-blueGray-600" />
                                        </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" />
                                    </tr>
                                {/if}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="opacity-25 fixed inset-0 z-40 bg-black" />
    {/await}
{/if}
