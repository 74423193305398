<script>
    import {getText} from '../../language.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let action;
    export let commitMessage = '';
    export let commitTitle = getText('commit_messages.commit_message.headline_2');
    export let showCommitMessage = false;

    export let parentFunction = () => {};

    function continueCommit() {
        showCommitMessage = false;
        parentFunction();
    }
</script>

<svelte:window
    on:keydown={(e) => {
        if (e.code == 'Escape') showCommitMessage = false;
        if (e.code == 'Enter') {
            parentFunction();
            showCommitMessage = false;
        }
    }}
/>

{#if showCommitMessage}
    <div id="grid-commit-message-pop-up" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
        <div class="relative w-third mt-20 my-6 mx-auto">
            <div class="bg-gray-200 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded border-1">
                <div class="bg-gray-300 rounded-t mb-3 px-4 py-3 border-0">
                    <div class="flex flex-wrap items-center">
                        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3 class="font-semibold text-base text-blueGray-700">
                                <p>
                                    {getText('commit_messages.commit_message.headline_1', action)}
                                </p>
                            </h3>
                        </div>
                    </div>
                </div>

                <div class="w-full lg:w-12/12 px-4">
                    <div class="flex-auto px-4 lg:px-10 pt-0">
                        <div class="flex flex-wrap">
                            <div class="relative w-full mb-3">
                                <label class="mt-4 block uppercase text-blueGray-600 text-xs font-bold mb-2" for="grid-commit-message"> {commitTitle}</label>
                                <textarea
                                    id="grid-commit-message"
                                    type="input"
                                    class="mono border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    rows="1"
                                    placeholder={getText('commit_messages.commit_message.input_placeholder')}
                                    bind:value={commitMessage}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <form>
                    <div class="flex-auto px-4 lg:px-10 lg:pb-2">
                        <div class="text-center flex justify-end">
                            <CardButton id="continue-commit" type="go" text={getText('commit_messages.commit_message.button_continue')} on:click={() => continueCommit()} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="opacity-25 fixed inset-0 z-40 bg-black" />
{/if}
