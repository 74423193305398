<script>
    import {navigate, Route, Router} from 'svelte-routing';
    import {beforeUpdate} from 'svelte';
    import {storedCompany, storedUser, language, reRender} from '../store';
    import {whoami} from '../api/api';
    import {clearApiCache} from '../api/api-cache';

    import NewCampaign from './promoter/NewCampaign.svelte';
    import NewReservation from './promoter/NewReservation.svelte';
    import EditCampaign from './promoter/EditCampaign.svelte';
    import CampaignManager from './promoter/CampaignManager.svelte';
    import Admin from '../layouts/Admin.svelte';
    import Sidebar from '../components/Sidebar.svelte';
    import Navbar from '../components/Navbar.svelte';
    import Header from '../components/Header.svelte';
    import Footer from '../components/Footer.svelte';
    import TablePromoter from './promoter/TablePromoter.svelte';
    import OverviewTablePodcastCrossPromos from './podcast/OverviewTablePodcastCrossPromos.svelte';
    import TablePodcast from './podcast/TablePodcast.svelte';
    import CardBookingRequestDetail from '../components/Cards/CardBookingRequestDetail.svelte';
    import NewUser from '../views/NewUser.svelte';
    import NewPodcast from '../views/podcast/NewPodcast.svelte';
    import Profile from '../views/Profile.svelte';
    import PasswordReset from '../views/auth/PasswordReset.svelte';
    import SelfpromoSettings from '../views/podcast/SelfpromoSettings.svelte';
    import InventorySettings from '../views/podcast/InventorySettings.svelte';
    import InventoryOverview from '../views/podcast/InventoryOverview.svelte';
    import CardTableAllCampaignsPodcast from '../components/Cards/CardTableAllCampaignsPodcast.svelte';
    import NetworkPodcasts from './promoter/NetworkPodcasts.svelte';

    // export let location;

    console.log('index');

    beforeUpdate(async () => {
        // if password reset with token
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('token');
        if (token) {
            navigate(`/user/reset-password?token=${token}`);
        } else if (!$storedUser.id && !token) {
            clearApiCache();
            navigate('/auth/login');
        } else {
            let user = await whoami();
            if (user.id !== $storedUser.id) {
                clearApiCache();
                navigate('/auth/logout');
            } else if (location.pathname === '/') {
                $storedUser.isValidated = true;
                if ($storedCompany && $storedCompany.type) {
                    navigate(`/${$storedCompany.type}`);
                }
            }
        }

        // if(location.pathname.indexOf("auth") === -1 && location.pathname.indexOf($storedCompany.type) === -1 && !$storedUser.is_superuser) {
        //     navigate("/auth/logout");
        // }
    });

    let routes = [
        //PROMOTER
        {path: '/promoter/campaign/new/:ctype', component: NewCampaign, params: {}},
        {path: '/promoter/campaign/new/reservation/:ctype', component: NewReservation, params: {}},
        {path: '/promoter/campaign/:id/edit/:ctype', component: EditCampaign, params: {}},
        {path: '/promoter/campaign/:id/manager/:ctype', component: CampaignManager, params: {}},
        {path: '/promoter/newuser', component: NewUser, params: {type: 'promoter'}},
        {path: '/promoter/*', component: TablePromoter, params: {card: 'all'}},
        {path: '/promoter/current', component: TablePromoter, params: {card: 'current'}},
        {path: '/promoter/running', component: TablePromoter, params: {card: 'running'}},
        {path: '/promoter/completed', component: TablePromoter, params: {card: 'completed'}},
        {path: '/promoter/archived', component: TablePromoter, params: {card: 'archived'}},
        {path: '/promoter/network/podcasts', component: NetworkPodcasts, params: {}},

        //PODCASTER
        {path: '/podcast/campaign/:id/booking-request/:bid', component: CardBookingRequestDetail, params: {}},
        {path: '/podcast/*', component: TablePodcast, params: {card: 'todo'}},
        {path: '/podcast/todo', component: TablePodcast, params: {card: 'todo'}},
        {path: '/podcast/waiting', component: TablePodcast, params: {card: 'waiting'}},
        {path: '/podcast/upcoming', component: TablePodcast, params: {card: 'upcoming'}},
        {path: '/podcast/running', component: TablePodcast, params: {card: 'running'}},
        {path: '/podcast/completed', component: TablePodcast, params: {card: 'completed'}},
        {path: '/podcast/wrapped', component: TablePodcast, params: {card: 'wrapped'}},
        {path: '/podcast/rejected', component: TablePodcast, params: {card: 'rejected'}},
        {path: '/podcast/cancelled', component: TablePodcast, params: {card: 'cancelled'}},
        {path: '/podcast/newpodcast', component: NewPodcast, params: {type: 'podcast'}},
        {path: '/podcast/newuser', component: NewUser, params: {type: 'podcast'}},
        {path: '/podcast/crosspromo/overview', component: OverviewTablePodcastCrossPromos, params: {}},
        {path: '/podcast/crosspromo/new/:ctype', component: NewCampaign, params: {}},
        {path: '/podcast/crosspromo/:id/edit/:ctype', component: EditCampaign, params: {}},
        {path: '/podcast/selfpromo/settings', component: SelfpromoSettings, params: {}},
        {path: '/podcast/inventory/overview', component: InventoryOverview, params: {rollPosition: 6}},
        {path: '/podcast/inventory/pre-roll', component: InventoryOverview, params: {rollPosition: 1}},
        {path: '/podcast/inventory/mid-roll-1', component: InventoryOverview, params: {rollPosition: 2}},
        {path: '/podcast/inventory/mid-roll-2', component: InventoryOverview, params: {rollPosition: 3}},
        {path: '/podcast/inventory/mid-roll-3', component: InventoryOverview, params: {rollPosition: 4}},
        {path: '/podcast/inventory/post-roll', component: InventoryOverview, params: {rollPosition: 5}},
        {path: '/podcast/inventory/campaigns', component: CardTableAllCampaignsPodcast, params: {}},
        {path: '/podcast/inventory/settings', component: InventorySettings, params: {}},
        {path: '/podcast/inventory/campaigns/:id/edit/:ctype', component: EditCampaign, params: {}},

        //ADMIN
        {path: '/admin/*', component: Admin, params: {}},

        //USER
        {path: '/user/profile', component: Profile, params: {}},
        {path: '/user/reset-password', component: PasswordReset, params: {}},
    ];
</script>

<div>
    <main>
        <section class="relative w-full h-full py-40 min-h-screen">
            <div class="absolute top-0 w-full h-full bg-blueGray-600 bg-no-repeat bg-full bg-image">
                <div>
                    {#if $language == 'DE'}
                        <Sidebar {location} />
                    {:else if $language == 'EN'}
                        <Sidebar {location} />
                    {/if}

                    {#if $reRender == false}
                        <Sidebar {location} />
                    {:else if $reRender == true}
                        <Sidebar {location} />
                    {/if}

                    <div class="relative md:ml-64 bg-blueGray-100">
                        <Navbar />
                        <Header />
                        <div class="px-4 md:px-10 mx-auto w-full -m-24">
                            <Router>
                                {#each routes as route}
                                    <Route path={route.path} component={route.component} {...route.params} />
                                {/each}
                            </Router>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</div>
