<script context="module">
    import {get} from 'svelte/store';
    import {language} from './store';

    // functions
    export function getText(identifier, replacement_text, replacement_date) {
        let lan = get(language);

        // for language blobs woth more than one text replacement
        if (replacement_text && !Array.isArray(replacement_text)) {
            replacement_text = [replacement_text];
        }

        if (lan == 'EN') {
            const get = (t, path) => path.split('.').reduce((r, k) => r?.[k], t);

            if (replacement_text && !replacement_date) {
                if (replacement_text.length == 1) {
                    return get(en, identifier).replace(/\{.*\}/g, replacement_text);
                } else if (replacement_text.length > 1) {
                    let text = get(en, identifier);
                    for (let index in replacement_text) {
                        text = text.replace('PLACEHOLDER', replacement_text[index]);
                    }
                    return text;
                }
            } else if (replacement_text && replacement_date) {
                let text = get(en, identifier).replace(/\{databaseToPrettyDate.*\}/g, replacement_date);
                return text.replace(/\{.*\}/g, replacement_text);
            } else {
                return get(en, identifier);
            }
        }

        if (lan == 'DE') {
            const get = (t, path) => path.split('.').reduce((r, k) => r?.[k], t);

            if (replacement_text && !replacement_date) {
                if (replacement_text.length == 1) {
                    return get(de, identifier).replace(/\{.*\}/g, replacement_text);
                } else if (replacement_text.length > 1) {
                    let text = get(de, identifier);
                    for (let index in replacement_text) {
                        text = text.replace('PLACEHOLDER', replacement_text[index]);
                    }
                    return text;
                }
            } else if (replacement_text && replacement_date) {
                let text = get(de, identifier).replace(/\{databaseToPrettyDate.*\}/g, replacement_date);
                return text.replace(/\{.*\}/g, replacement_text);
            } else {
                return get(de, identifier);
            }

            // if (replacement_text && !replacement_date) {
            //     return get(de, identifier).replace(/\{.*\}/g, replacement_text);
            // } else if (replacement_text && replacement_date) {
            //     let text = get(de, identifier).replace(/\{databaseToPrettyDate.*\}/g, replacement_date);
            //     return text.replace(/\{.*\}/g, replacement_text);
            // } else {
            //     return get(de, identifier);
            // }
        }
    }

    // TO CALL
    // import {getText} from '../../language.svelte';
    // let abc = getText("en", "sidebar.promoter.section_1.headline")

    // german dictionary
    const de = {
        admin: {
            sidebar: {
                promoter: {
                    title: 'Admin',
                    entry_1: 'Nutzer*in',
                },
                podcast: {
                    title: 'Admin',
                    entry_1: 'Podcast(s)',
                    entry_2: 'Nutzer*in',
                },
                admin: {
                    title: 'Admin',
                    entry_1: 'Allgemeiner Überblick',
                    entry_2: 'Organisationen',
                    entry_3: 'Podcasts',
                    entry_4: 'Schlagwörter',
                    entry_5: 'Nutzer*in',
                },
            },
            companies: {
                new_company: {
                    title: 'Neue Organisation',
                    button_new: 'Neu',
                    button_save: 'Speichern',
                    button_cancel: 'Abbrechen',
                    category_1: {
                        title: 'Information',
                        entry_1: {
                            title: 'Name *',
                        },
                        entry_2: {
                            title: 'Typ *',
                            input_placeholder: '--- auswählen ---',
                        },
                    },
                },
                all_companies: {
                    title: 'Alle Organisationen',
                    button_new: 'Neu',
                    headline_1: 'ID',
                    headline_2: 'Name',
                    headline_3: 'Typ',
                },
            },
            podcasts: {
                new_podcast: {
                    title: 'Neuer Podcast',
                    button_save: 'Speichern',
                    button_cancel: 'Abbrechen',
                    category_1: {
                        title: 'Podcast Information',
                        entry_1: {
                            title: 'Name *',
                        },
                        entry_2: {
                            title: 'Organisation *',
                            input_placeholder: '--- auswählen ---',
                        },
                        entry_3: {
                            title: 'Maximales Buchungsvolumen *',
                        },
                        entry_4: {
                            title: 'Zusammenfassung',
                        },
                        entry_5: {
                            title: 'Beschreibung',
                        },
                        entry_6: {
                            title: 'URL',
                        },
                        entry_7: {
                            title: 'Vorschaubild URL',
                        },
                        entry_8: {
                            title: 'Schlagwörter',
                        },
                        entry_9: {
                            title: 'ASAP Podcast ID *',
                        },
                    },
                },
                all_podcasts: {
                    title: 'Alle Podcasts',
                    button_new: 'Neu',
                    headline_1: 'Podcast-ID',
                    headline_6: 'ASAP-Podcast-ID',
                    headline_2: 'Name',
                    headline_3: 'Maximales Buchungsvolumen',
                    headline_4: 'Organisations-ID',
                    headline_5: 'Cross-Promotion Endpunkt',
                },
            },
            keywords: {
                new_keyword: {
                    title: 'Neues Schlagwort',
                    button_save: 'Speichern',
                    button_cancel: 'Abbrechen',
                    category_1: {
                        title: 'Schlagwörter',
                        entry_1: {
                            title: 'Schlagwort/Schlagwörter hinzufügen *',
                            input_placeholder: 'Mehrere Schlagwörter mit einem Komma trennen',
                        },
                    },
                },
                all_keywords: {
                    title: 'Alle Schlagwörter',
                    button_new: 'Neu',
                    headline_1: 'ID',
                    headline_2: 'Schlagwort',
                },
            },
            users: {
                current_user: {
                    title: 'Aktuell eingelogged',
                    button_new: 'New',
                    headline_1: 'Name',
                    headline_2: 'Email',
                    headline_3: 'Company ID',
                    headline_4: 'User ID',
                    headline_5: 'Role',
                },
                all_users: {
                    title: 'Alle Nutzer*in',
                    button_new: 'New',
                    headline_1: 'Name',
                    headline_2: 'Email',
                    headline_3: 'Company ID',
                    headline_4: 'User ID',
                    headline_5: 'Role',
                },
                new_user: {
                    title: 'Neu anlegen',
                    button_save: 'Speichern',
                    button_cancel: 'Abbrechen',
                    category_1: {
                        title: 'Podcast Information',
                        entry_1: {
                            title: 'Name *',
                        },
                        entry_2: {
                            title: 'Email *',
                        },
                        entry_3: {
                            title: 'Passwort *',
                        },
                        entry_4: {
                            title: 'Company *',
                            input_placeholder: '--- auswählen ---',
                        },
                    },
                },
            },
        },
        promoter: {
            sidebar: {
                section_1: {
                    title: 'Kampagnen',
                    entry_1: 'In Vorbereitung',
                    entry_2: 'Laufend',
                    entry_3: 'Abgeschlossen',
                    entry_4: 'Alle',
                    entry_5: 'Archiviert',
                },
                section_2: {
                    title: 'User',
                    entry_1: 'Profil',
                    entry_2: 'Abmelden',
                },
            },
            campaign_overview: {
                hostread_table: {
                    title: 'Host-Read Kampagnen',
                    button_reservation: 'Neue Reservierung',
                    button_booking: 'Neuer Host-Read',
                    headline_1: 'Kampagne',
                    headline_2: 'Zielkontakte',
                    headline_3: 'Aktuelle Kontakte',
                    button_edit: 'Bearbeiten',
                    button_briefing: 'Kampagnenbriefing vervollständigen',
                    button_archive: 'Kampagne Archivieren',
                },
                spot_table: {
                    title: 'Spot Kampagnen',
                    button_reservation: 'Neue Reservierung',
                    button_booking: 'Neuer Spot',
                    headline_1: 'Kampagne',
                    headline_2: 'Zielkontakte',
                    headline_3: 'Aktuelle Kontakte',
                    button_edit: 'Bearbeiten',
                    button_briefing: 'Audio für diese Kampagnen hochladen',
                    warning_campaign_cancelled: 'Frist verstrichen ! Kampagne storniert',
                },
                targeting_monitor: {
                    title: '- Anfrage- und Auslierungsstatus',
                    section_1: {
                        title: 'ANVISIERT',
                        description: 'Zielkontakte',
                    },
                    section_2: {
                        title: 'ANGEFRAGT',
                        description_1: 'vom Ziel',
                        description_2: 'unterbucht !',
                        description_3: 'überbucht !',
                    },
                    section_3: {
                        title: 'RESERVIERT',
                        description: 'von angefragt',
                    },
                    section_4: {
                        title: 'ABGESCHLOSSEN',
                        description_1: 'von reserviert',
                        description_2: 'von angefragt',
                    },
                    section_5: {
                        title: 'AUSGELIEFERT',
                        description: 'von abgeschlossen',
                    },
                },
                booking_requests_table: {
                    headline_1: 'Nächster Schritt',
                    headline_2: 'Podcast',
                    headline_3: 'Angefragte / Angebotene Kontakte',
                    headline_4: 'Aktuelle Kontakte',
                },
                booking_requests_table_rows: {
                    not_activated: {
                        button_send: 'Anfrage senden',
                        status: 'Anfrage noch nicht gesendet',
                    },
                    pending: {
                        booking_request_modifications: {
                            title: 'Änderungen von "{podcastName}" für ',
                            button_confirm: 'Buchung bestätigen',
                            button_request_changes: 'Änderungen anfordern',
                            button_delete: 'Buchung löschen',
                            category_1: {
                                title: 'Kontakte',
                                entry_1: {
                                    title: 'Angefordert',
                                },
                                entry_2: {
                                    title: 'Angeboten',
                                },
                                entry_3: {
                                    title: 'Differenz',
                                },
                            },
                            category_2: {
                                title: 'Platzierung',
                                entry_1: {
                                    title: 'Angefordert',
                                },
                                entry_2: {
                                    title: 'Angeboten',
                                },
                            },
                        },
                        state_2: {
                            next_step_hostread: 'Warten, dass "{data.name}" die Host-Read Anfrage annimmt',
                            next_step_spot: 'Warten, dass "{data.name}" die Spot Anfrage annimmt',
                            next_step_crossporomo: 'Warten, dass "{data.name}" die Cross-Promotion Anfrage annimmt',
                            status: 'Deal noch nicht abgeschlossen',
                        },
                        state_3: {
                            next_step_1: 'Anfrage angenommen - Änderungen prüfen',
                            next_step_2: 'Warten, dass "{data.name}" das Angebot nachbessert',
                            status: 'Deal noch nicht abgeschlossen',
                        },
                        state_4: {
                            next_step_hostread: 'Die Host-Read Anfrage wurde von "{data.name}" am {databaseToPrettyDate(bookingRequest.rejected_date)} abgelehnt',
                            next_step_spot: 'Die Spot Anfrage wurde von "{data.name}" am {databaseToPrettyDate(bookingRequest.rejected_date)} abgelehnt',
                            next_step_crosspromo: 'Die Cross-Promotion Anfrage wurde von "{data.name}" am {databaseToPrettyDate(bookingRequest.rejected_date)} abgelehnt',
                            status: 'Anfrage wurde abgelehnt',
                        },
                        state_5: {
                            next_step_1: 'Kampagnentext überprüfen',
                            next_step_2: 'Warten, dass "{ data.name }" den Kampagnentext erstellt',
                            next_step_3: 'Warten, dass "{data.name}" das Audio erstellt',
                            next_step_4: 'Anfrage angenommen - Kampagnenbriefing vervollständigen!',
                            next_step_5: 'Kampagnentext freigegeben - warten auf Audiodatei',
                            next_step_6: 'Kampagnentext aktualisiert - warten auf Korrektur',
                            next_step_7: 'Antrag angenommen - Audio der Kampagne hochladen !',
                            next_step_8: 'Warten, dass "{data.name}" den Deal abschliesst',
                            next_step_9: 'Warten, dass "{data.name}" die Cross-Promotion Anfrage annimmt',
                            next_step_10: 'Warten auf Audiodatei',
                            status: 'Deal noch nicht abgeschlossen',
                        },
                        state_6: {
                            next_step_hostread: 'Host-Read Anfrage wurde storniert am {databaseToPrettyDate(bookingRequest.cancelled_date)}',
                            next_step_spot: 'Spot Anfrage wurde storniert am  {databaseToPrettyDate(bookingRequest.cancelled_date)}',
                            next_step_crosspromo: 'Cross-Promotion Anfrage wurde storniert am {databaseToPrettyDate(bookingRequest.cancelled_date)}',
                            status: 'Die Anfrage wurde storniert',
                        },
                        state_7: {
                            next_step_1: 'Audio runterladen',
                            next_step_2: 'Audio freigeben',
                            next_step_3: 'Änderungen anfordern',
                            status: 'Deal noch nicht abgeschlossen',
                        },
                        state_8: {
                            next_step_1: 'Kampagne beginnt in {getDateDiff(getDateNowDatetime(), campaign.start_date)} Tagen',
                        },
                        state_13: {
                            next_step_hostread: 'Die Frist zur Annahme wurde von "{data.name}" verpasst',
                            next_step_spot: 'Die Frist zur Annahme wurde von "{data.name}" verpasst',
                            next_step_crosspromo: 'Die Frist zur Annahme wurde von "{data.name}" verpasst',
                            status: 'Die Frist zur Annahme wurde verpasst',
                            button_extend_deadline: 'Die Frist bis Kampagnenstart verlängern',
                        },
                    },
                    on_air: {
                        next_step_1: 'Kampagne endet in {getDateDiff(getDateNowDatetime(), campaign.end_date)} Tagen',
                    },
                    completed: {
                        state_10: {
                            next_step_1: 'Bericht wird erwartet',
                        },
                        state_11: {
                            next_step_1: 'Bericht runterladen',
                            next_step_2: 'Bericht freigeben',
                            next_step_3: 'Änderungen anfordern',
                        },
                        state_12: {
                            next_step_1: 'Abgeschlossen',
                        },
                    },
                },
            },
            campaign: {
                title_hostread: 'Erstellung einer neuen Host-Read Kampagne',
                title_spot: 'Erstellung einer neuen Spot Kampagne',
                title_cross_promo: 'Erstellung einer neuen Cross-Promotion Kampagne',
                title_reserve_hostread: 'Erstellung einer neuen Reservierung für eine Host-Read Kampagne',
                title_reserve_spot: 'Erstellung einer neuen Reservierung für eine Spot Kampagne',
                title_reserve_cross_promo: 'Erstellung einer neuen Reservierung für eine Cross-Promotion Kampagne',
                button_save: 'Speichern',
                button_saving: 'Speichert ...',
                button_save_send_hostread: 'Alle Host-Read Anfragen speichern & senden',
                button_save_send_spot: 'Alle Spot-Anfragen speichern & senden',
                button_save_send_crosspromo: 'Alle Cross-Promotion-Anfragen speichern & senden',
                button_save_send_reservation: 'Alle Reservierungsanfragen speichern & senden',
                button_cancel: 'Abbrechen',
                button_cancel_campaign: 'Kampagne & Buchungen stornieren',
                general_information: {
                    title: 'Generelle Information',
                    category_1: {
                        title: 'Titel *',
                        input_placeholder: 'Name für diese Kampagne',
                    },
                    category_2: {
                        title: 'Kund*in *',
                        title_podcast: 'Kund*in *',
                        input_placeholder: 'Kontaktinformation',
                    },
                    category_3: {
                        title: 'Anfragender Podcast *',
                        input_placeholder: '--- auswählen ---',
                    },
                    category_4: {
                        title: 'Länge (Sekunden)',
                        input_placeholder: 'Länge in Sekunden',
                    },
                    category_5: {
                        title: 'Erforderliche Abnahmen *',
                        entry_1: 'Kampagnentext',
                        entry_2: 'Audio',
                        entry_3: 'Keine',
                    },
                    category_6: {
                        title: 'Gewünschte Platzierung *',
                        info: 'Es können mehrere Platzierungswünsche angegeben werden. Die endgültige Auslieferung wird auf nur einem Platz erfolgen.',
                    },
                },
                date_information: {
                    title: 'Daten',
                    category_1: {
                        title: 'Buchungsannahme bis *',
                    },
                    category_2: {
                        title: 'Geplanter Start der Kampagne *',
                    },
                    category_3: {
                        title: 'Geplantes Ende der Kampagne *',
                    },
                },
                briefing_information: {
                    category_1: {
                        title: 'Briefing',
                        entry_1: {
                            title: 'Kampagnentext',
                            button_update: 'Kampagnentext aktualisieren',
                            input_placeholder: 'Für den Text, der vorgelesen werden soll.',
                            label_1: 'Das Briefing ist abgeschlossen. Weitere Bearbeitungen des Kampagnentexts können im Kampagnentext-Manager der jeweiligen Buchungsanfrage vorgenommen werden.',
                        },
                    },
                    category_2: {
                        title: 'Moods',
                        entry_1: {
                            title: 'Hintergrund Information',
                            input_placeholder: 'Für die Kampagnenplanung - z.B. neues Produkt, Modell, ...',
                        },
                        entry_2: {
                            title: 'Kampagnen-Ziel',
                            input_placeholder: 'Beschreibung dessen, was mit der Kampagne kommuniziert werden soll.',
                        },
                        entry_3: {
                            title_1: 'Audio Schlagwörter - kommasepariert',
                            title_2: 'Audio Schlagwörter - zum Entfernen anklicken',
                            input_placeholder: 'Mehrere Schlagwörter durch ein Komma trennen',
                        },
                        entry_4: {
                            title_1: 'Ausschluss-Wörter - kommasepariert',
                            title_2: 'Ausschluss-Wörter - zum Entfernen anklicken',
                            input_placeholder: 'Diese Wörter dürfen nicht verwendet werden.',
                        },
                        entry_5: {
                            title: 'URL für Kommunikation',
                            input_placeholder: 'URL, die in der Anzeige erwähnt werden sollte',
                        },
                    },
                    category_3: {
                        title: 'Ressourcen',
                        entry_1: {
                            title: 'Hear & Feel',
                            button_update: 'Aktualisieren',
                            description: 'Upload einer komprimierten Datei für ein bestehendes Audiologo, Beispiele für die Aussprache von Marken und Produkten.',
                        },
                        entry_2: {
                            title: 'Sound-Effekte',
                            button_update: 'Aktualisieren',
                            description: 'Ressourcen für Sound-Effekte (komprimierte Datei oder Cloud-Link).',
                            input_placeholder: 'Cloud-Link',
                        },
                        entry_3: {
                            title: 'Ergänzende URL',
                            input_placeholder: 'Link zu Audio- oder Videodateien',
                        },
                    },
                    category_4: {
                        title: 'Briefing Status',
                        description: 'Briefing vervollständigen und an Podcast(s) senden !',
                        label_1: 'Aktivieren, wenn das Briefing abgeschlossen ist, damit die Publisher mit der Produktion des Host-Reads beginnen können.',
                        label_2: 'Briefing abgeschlossen.',
                        label_3: 'Zuletzt aktualisiert am: {databaseToPrettyDateTime(campaign.briefing_last_updated_date)}',
                        description_podcast_campaign: 'Ist das Briefing vollständig ?',
                        label_1_podcast_campaign: 'Aktivieren, wenn das Briefing abgeschlossen ist. Die Produktion des Host-Reads kann nun beginnen !',
                        label_2_podcast_campaign: 'Briefing abgeschlossen.',
                        label_3_podcast_campaign: 'Zuletzt aktualisiert am: {databaseToPrettyDateTime(campaign.briefing_last_updated_date)}',
                    },
                },
                audio_spot_upload: {
                    title_1: 'Hochladen der Audiodatei *',
                    title_2: 'Audiodatei',
                    button_upload: 'Hochladen und Audiodatei überprüfen',
                    button_update: 'Anhören der neuen Audiodatei',
                    // label_1: 'Aktuell: {audiofileUrl}',
                    label_1: 'Aktuell: ',
                    label_2: 'Zuletzt aktualisiert am: {databaseToPrettyDateTime(audiofileUploadedDate)}',
                },
            },
            targeting: {
                title: 'Kontakte',
                category_1: {
                    entry_1: 'Zielkontakte *',
                    entry_2: 'Angefragte Kontakte',
                    entry_3: 'Verbleibende Kontakte',
                },
                category_2: {
                    title: 'Podcasts',
                    entry_1: {
                        input_placeholder: 'Durchsuche Podcasts ...',
                    },
                    entry_2: {
                        headline_1: 'Auswahl',
                        headline_2: 'Podcast',
                        headline_3: 'Maximales Buchungsvolumen',
                        headline_4: 'Angefragte Kontakte',
                    },
                },
            },
            production_flow: {
                title: 'Produktionsablauf für ',
                headline_1: 'Aktion',
                headline_2: 'Status',
                headline_3: 'Datum',
                headline_4: 'Nutzer*in',
                category_1: {
                    title: 'Buchung erstellt',
                },
                category_2: {
                    title: 'Buchung aktiviert',
                },
                category_3: {
                    title_1: 'Frist zur Buchungsannahme- &',
                    title_2: 'Hochladen des Audios',
                },
                category_4: {
                    title: 'Buchung abgelehnt',
                },
                category_5: {
                    title: 'Buchung angenommen',
                    description_1: 'mit {prettyPrintNumber(bookingRequest.offered_impressions)}',
                    description_2: 'von {prettyPrintNumber(bookingRequest.requested_impressions)} angefragten Kontakten auf ',
                    description_3: 'von {prettyPrintNumber(bookingRequest.requested_impressions)} angefragten Kontakten auf ',
                },
                category_6: {
                    title: 'Audio hochgeladen',
                },
                category_7: {
                    title: 'Audio abgenommen',
                },
                category_8: {
                    title: 'Buchung storniert',
                },
                category_9: {
                    title: 'Deal ID',
                },
                category_10: {
                    title: 'VAST URL',
                },
                category_11: {
                    title: 'Kampagnenstart',
                },
                category_12: {
                    title: 'Kampagnenende',
                },
                category_13: {
                    title: 'Frist zum Hochladen des Berichts',
                },
                category_14: {
                    title: 'Bericht hochgeladen',
                },
                category_15: {
                    title: 'Bericht überprüft und abgenommen',
                },
            },
            script_version_control: {
                title_1: 'Kampagnentext-Versionskontrolle für "{campaign.title}"',
                title_2: '- mit ',
                headline_1: 'Originalversion',
                headline_2: 'Aktuelle Version',
                headline_3: 'Letzte Version',
                headline_4: 'Abgenommene Version',
                updated: 'Zuletzt aktualisiert am: {databaseToPrettyDateTime(bookingRequest.script_updated_date)}',
                button_approve: 'Diese Version abnehmen & freigeben',
                button_request_changes: 'Änderungen anfordern',
                button_update: 'Kampagnentext aktualisieren',
                button_share_email: 'Versenden via Email',
                email_subject: 'Aktueller Kampagnentext von "{podcast.name}"',
                email_text: 'Hallo,\n\nanbei der aktuelle Kampagnentext für "{campaign.title}"',
            },
            network: {
                podcasts: {
                    headline: 'Podcast Manager',
                    description: 'Der Podcast Manager ...',
                    title_1: 'Info',
                    title_2: 'Favoriten',
                    title_3: 'Exklusive Podcasts',
                    title_4: 'Alle Podcasts',
                },
                podcast_info: {
                    title_1: 'Beschreibung',
                    title_2: 'Genre',
                    title_3: 'Kontakte/Monat',
                },
            },
        },
        podcast: {
            sidebar: {
                section_1: {
                    title: 'Buchungsanfragen',
                    entry_1: 'AdOps - ToDo',
                    entry_2: 'Ausstehend',
                    entry_3: 'Bevorstehend',
                    entry_4: 'Laufend',
                    entry_5: 'Ausgelaufen',
                    entry_6: 'Abgeschlossen',
                    entry_7: 'Abgelehnt',
                    entry_8: 'Storniert',
                },
                section_2: {
                    title: 'Cross-Promotions',
                    entry_1: 'Neu',
                    entry_2: 'Übersicht',
                },
                section_3: {
                    title: 'User',
                    entry_1: 'Profil',
                    entry_2: 'Abmelden',
                },
                section_4: {
                    title: 'Eigenwerbung',
                    entry_1: 'Einstellungen',
                },
                section_5: {
                    title: 'Inventar',
                    entry_1: 'Überblick',
                    entry_2: 'Kampagnen',
                    entry_3: 'Einstellungen',
                },
            },
            booking_requests: {
                todo: {
                    empty_status: 'Tschaka! Alles, was unerledigt war, ist jetzt erledigt.',
                    headline_1: 'Kund*in',
                    headline_2: 'Kampagne',
                    headline_3: 'Angefragte / Angebotene Kontakte',
                    headline_4: 'Nächster Schritt',
                    headline_5: 'Optionen',
                    rows: {
                        button_archive: 'Archivieren',
                        next_step_1: 'Neue Anfrage !',
                        next_step_2: 'Audio hochgeladen',
                        next_step_3: 'Auf Änderungswünsche reagieren !',
                        next_step_4: 'Warten auf ausstehendes Briefing',
                        next_step_5: 'Briefing steht nun zur Verfügung !',
                        next_step_6: 'Kampagnentext freigegeben - Audio erstellen !',
                        next_step_7: 'Feedback zum Audio steht zur Verfügung !',
                        next_step_8: 'Auf Briefing reagieren !',
                        next_step_9: 'Briefing steht nun zur Verfügung !',
                        next_step_10: 'Fertiges Audio hochladen !',
                        next_step_11: 'Warten auf ausstehende Abnahme des Audiofiles',
                        next_step_12: 'Deal ist abgeschlossen - Kampagne auf dem Adserver erstellen !',
                        next_step_13: 'Kampagne ist ausgelaufen - Bericht hochladen !',
                        next_step_14: 'Die Buchungsanfrage wurde storniert am {databaseToPrettyDate(bookingRequest.cancelled_date)}',
                        next_step_15: 'Feedback zum Kampagnentext steht zur Verfügung !',
                        next_step_16: 'Die Buchungsanfrage wurde abgelehnt',
                        next_step_17: 'Die Frist zur Annahme der Buchungsanfrage ist verstrichen',
                        next_step_18: 'Deal ist abgeschlossen - Anzeige wird platziert !',
                    },
                },
                waiting: {
                    empty_status: 'Zur Zeit gibt es keine ausstehenden Buchungsprozesse.',
                    headline_1: 'Kund*in',
                    headline_2: 'Kampagne',
                    headline_3: 'Angefragte / Angebotene Kontakte',
                    headline_4: 'Nächster Schritt',
                    headline_5: 'Optionen',
                    rows: {
                        next_step_1: 'Warten auf ausstehende Buchungsannahme',
                        next_step_2: 'Warten auf ausstehende Freigabe des Kampagnentexts',
                        next_step_3: 'Warten auf ausstehendes Briefing',
                        next_step_4: 'Warten auf ausstehende Audiodatei',
                        next_step_5: 'Warten auf ausstehende Abnahme der Audiodatei & Abschluss des Deals',
                    },
                },
                upcoming: {
                    empty_status: 'Zur Zeit gibt es keine bevorstehenden Kampagnen.',
                    headline_1: 'Kund*in',
                    headline_2: 'Kampagne',
                    headline_3: 'Start',
                    headline_4: 'Ende',
                    headline_5: 'Aktuelle Kontakte',
                    headline_6: 'Optionen',
                },
                running: {
                    empty_status: 'Zur Zeit gibt es keine aktuell laufenden Kampagnen.',
                    headline_1: 'Kund*in',
                    headline_2: 'Kampagne',
                    headline_3: 'Laufzeit & Verbleibende Zeit',
                    headline_4: 'Ausgelieferte- & Verbleibende Kontakte',
                    headline_5: 'Optionen',
                },
                completed: {
                    empty_status: 'Zur Zeit gibt es keine beendeten Kampagnen.',
                    headline_1: 'Kund*in',
                    headline_2: 'Kampagne',
                    headline_3: 'Optionen',
                    rows: {
                        next_step_1: 'Warten auf ausstehende Abnahme des Reports',
                    },
                },
                wrapped: {
                    empty_status: 'Zur Zeit gibt es keine abgeschlossenen Kampagnen.',
                },
                rejected: {
                    empty_status: 'Es wurden bis dato keine Anfragen abgelehnt.',
                    headline_1: 'Kund*in',
                    headline_2: 'Kampagne',
                    headline_3: 'Angefragte / Angebotene Kontakte',
                    headline_4: 'Status',
                    headline_5: 'Optionen',
                },
                cancelled: {
                    empty_status: 'Es wurden bis dato keine Anfragen storniert.',
                    headline_1: 'Kund*in',
                    headline_2: 'Kampagne',
                    headline_3: 'Angefragte / Angebotene Kontakte',
                    headline_4: 'Status',
                    headline_5: 'Optionen',
                },
            },
            booking_request_details: {
                button_accept_reservation: 'Reservierung für {prettyPrintNumber(offeredImpressions)} Kontakte annehmen',
                button_accept_hostread: 'Host-Read Anfrage für {prettyPrintNumber(offeredImpressions)} Kontakte annehmen',
                button_accept_spot: 'Spot Anfrage für {prettyPrintNumber(offeredImpressions)} Kontakte annehmen',
                button_accept_crosspromo: 'Cross-Promotion Anfrage für {prettyPrintNumber(offeredImpressions)0-} Kontakte annehmen',
                button_reject: 'Ablehnen',
                button_update: 'Aktualisieren',
                button_exit: 'Abbrechen',
                next_step: {
                    label_1: 'Kampagnen Inhaber*in',
                    state_2: {
                        entry_1: 'Reservierung für diesen Host-Read annehmen',
                        entry_2: 'Anfrage für diesen Spot annehmen',
                        entry_3: 'Anfrage für diese Cross-Promotion annehmen',
                    },
                    state_3: {
                        // entry_1: 'Waiting for campaign owner to approve booking with adjusted impressions/roll-position.',
                        entry_1: 'Warten auf Freigabe nach Anpassung des Angebots.',
                        // entry_2: 'React to the change request the campaign owner has sent via commit message !',
                        entry_2: 'Sofern möglich, Buchung laut Änderungswünsche im Kommentar korrigieren !',
                    },
                    state_4: {
                        // entry_1: 'Host-Read request has been rejected',
                        entry_1: 'Die Host-Read Anfrage wurde abgelehnt',
                        entry_2: 'Die Spot Anfrage wurde abgelehnt',
                        entry_3: 'Die Cross-Promotion Anfrage wurde abgelehnt',
                    },
                    state_5: {
                        entry_1: 'Warten auf Freigabe des Briefings.',
                        entry_2: 'Den Kampagnentext bearbeiten !',
                        entry_3: 'Warten auf Freigabe des Kampagnentexts',
                        entry_4: 'Das Audio bearbeiten !',
                        entry_5: 'Warten auf Freigabe des Audios.',
                        entry_6: 'Das Audio produzieren & hochladen !',
                        entry_7: 'Warten auf Bereitstellung des Audios.',
                    },
                    state_6: {
                        entry_1: 'Die Host-Read Anfrage wurde storniert',
                        entry_2: 'Die Spot Anfrage wurde storniert',
                        entry_3: 'Die Cross-Promotion Anfrage wurde storniert',
                    },
                    state_7: {
                        entry_1: 'Warten auf Freigabe des Audios.',
                    },
                    state_8: {
                        // entry_1: 'Create ad campaign on ad server - or place ad in booked episode(s)',
                        entry_1: 'Die abgenommene Kampagne auf dem AdServer anlegen - oder in die gebuchte(n) Episode(n) schneiden',
                        entry_2: 'Die Kampagne läuft in {getDateDiff(getDateNowDatetime(), campaign.start_date)} Tag(en) an',
                        entry_3: 'Die Kampagne wird von {responsable} auf dem Ad-Server angelegt, und läuft in {getDateDiff(getDateNowDatetime(), campaign.start_date)} Tag(en) an',
                    },
                    state_9: {
                        entry_1: 'Die Kampagne endet in {getDateDiff(getDateNowDatetime(), campaign.end_date)} Tagen',
                    },
                    state_10: {
                        entry_1: 'Den Bericht hochladen !',
                    },
                    state_11: {
                        entry_1: 'Warten auf Abnahme des Reports',
                    },
                    state_12: {
                        // entry_1: 'This campaign is wrapped. Nothing left to do here.',
                        entry_1: 'Diese Kampagne ist abgeschlossen',
                    },
                    state_13: {
                        entry_1: 'Auf die Buchungsanfrage reagieren !',
                    },
                    state_14: {
                        entry_1: 'Die Frist zur Annahme der Buchungsanfrage ist verstrichen.',
                    },
                },
                commit_messages: {
                    button_show_all: 'Alle Kommentare',
                    headline_1: 'Kommentar bezüglich dieser Buchung',
                    headline_2: 'Kommentar bezüglich der Stornierung diser Anfrage',
                    headline_3: 'Kommentar bezüglich der Aktualisierung des Kampagnentexts',
                    headline_4: 'Feedback bezüglich des Kampagnentexts',
                    headline_5: 'Kommentar bezüglich des Audios',
                    headline_6: 'Kommentar bezüglich des Berichts',
                    headline_7: 'Kommentar bezüglich der Aktualisierung(en) der Kampagnen',
                    headline_8: 'Letzter Kommentar',
                    headline_9: 'Es wurde kein Kommentar für die Aktion {lastCommitMessage.description} hinterlegt.',
                },
                status: {
                    category_1: {
                        title: 'Status',
                        state_2: {
                            entry_1: 'Neue Host-Read Anfrage',
                            entry_2: 'Neue Spot Anfrage',
                            entry_3: 'Neue Cross-Promotion Anfrage',
                        },
                        state_3: {
                            entry_1: 'Warten auf Buchungsfreigabe',
                            entry_2: 'Auf Änderungswünsche reagieren',
                        },
                        state_4: {
                            entry_1: 'Abgelehnt',
                        },
                        state_5: {
                            entry_1: 'Warten auf austehendes Briefing',
                            entry_2: 'Briefing abgeschlossen',
                            entry_3: 'Kampagnentext aktualisieren',
                            entry_4: 'Kampagnentext freigegeben',
                            entry_5: 'Warten auf austehendes Audio',
                            entry_6: 'Warten auf Abschluss des Deals',
                            entry_7: 'Audio bearbeiten',
                        },
                        state_6: {
                            entry_1: 'Storniert',
                        },
                        state_7: {
                            entry_1: 'Warten auf Abschluss des Deals',
                            entry_2: 'Deal ist abgeschlossen - Anzeige platzieren',
                        },
                        state_8: {
                            entry_1: 'Deal ist abgeschlossen - Anzeige platzieren',
                            entry_2: 'Anzeige platziert',
                            entry_3: 'Deal ist abgeschlossen - Anzeige wird platziert',
                        },
                        state_9: {
                            entry_1: 'On air',
                        },
                        state_10: {
                            entry_1: 'Ausgelaufen - warten auf Bericht',
                        },
                        state_11: {
                            entry_1: 'Ausgelaufen - warten auf Abnahme des Reports',
                        },
                        state_12: {
                            entry_1: 'Abgeschlossen',
                        },
                        state_13: {
                            entry_1: 'Warten auf Abschluss des Deals',
                        },
                        state_14: {
                            entry_1: 'Frist verstrichen',
                        },
                    },
                    category_2: {
                        title: 'Nächster Schritt',
                        entry_1: 'Von: {responsable}',
                        entry_2: 'Bis: {databaseToPrettyDate(date)}',
                        entry_3: '- noch ({getDateDiff(getDateNowDatetime(), date)} Tage)',
                        entry_4: 'Datum: {databaseToPrettyDate(date)}',
                    },
                },
                booking_information: {
                    title: 'Buchungs Information',
                    category_1: {
                        title: 'Kampagnenstart',
                        description: 'verbleibende Tage)',
                    },
                    category_2: {
                        title: 'Kampagnenende',
                        description: 'verbleibende Tage)',
                    },
                    category_3: {
                        // title_hostread: 'Deadline to accept Host-Read request & upload audio',
                        title_hostread: 'Frist um die Host-Read Anfrage anzunehmen & das Audio hochzuladen',
                        title_spot: 'Frist um die Spot Anfrage anzunehmen',
                        title_crosspromo: 'Frist um die Cross-Promotion Anfrage anzunehmen',
                        description: 'verbleibende Tage)',
                    },
                    category_4: {
                        title: 'Angefragte Kontakte',
                    },
                    category_5: {
                        title: 'Angebotene Kontakte (max. {prettyPrintNumber(bookingRequest.requested_impressions)})',
                    },
                    category_6: {
                        title: 'Dauer',
                        description: '{campaign.hostread_duration_ms} Sekunden',
                    },
                    category_7: {
                        title: 'Benötigte Abnahmen',
                        entry_1: 'Kampagnentext',
                        entry_2: 'Audio',
                        entry_3: 'Keine',
                    },
                    category_8: {
                        title: 'Angefragte Platzierung(en)',
                    },
                    category_9: {
                        title: 'Angebotene Platzierung(en)',
                        info: 'Es können mehere Positionen zur Auswahl angefragt werden, die konkrete Buchung kann jedoch nur auf einer Position erfolgen.',
                    },
                },
                briefing_information: {
                    title: 'Briefing Information',
                    category_1: {
                        title: 'Kampagnentext',
                        button_update: 'Kampagnentext aktualisieren',
                    },
                    category_2: {
                        title: 'Hintergrund Information',
                    },
                    category_3: {
                        title: 'Kampagnen-Ziel',
                    },
                    category_4: {
                        title: 'Audio Schlagwörter',
                    },
                    category_5: {
                        title: 'Ausschluss-Wörter',
                    },
                    category_6: {
                        title: 'URL, die in der Anzeige erwähnt werden soll',
                    },
                    category_7: {
                        title: 'Hear & Feel',
                    },
                    category_8: {
                        title: 'Sound-Effekte',
                    },
                    category_9: {
                        title: 'Sound-Effekte Cloud Link',
                    },
                    category_10: {
                        title: 'Ergänzende URL',
                    },
                },
                file_upload: {
                    title: 'Audio',
                    button_upload: 'Audio hochladen & überprüfen',
                    button_update: 'Audio aktualiseren',
                    button_update_hostread: 'Host-Read mit neuem Audio aktualisieren',
                    label_1: 'Aktuell: ',
                    // label_1: 'Aktuell: {bookingRequest.audiofile_url}',
                    label_2: 'Zuletzt aktualisiert am: {databaseToPrettyDateTime(bookingRequest.audiofile_uploaded_date)}',
                    label_3: 'Abgelehnt am: {databaseToPrettyDate(bookingRequest.rejected_date)}',
                    label_4: 'Storniert am: {databaseToPrettyDate(bookingRequest.cancelled_date)}',
                    label_5: 'URL für "{bookingRequest.audiofile_name_original}": ',
                    label_6: 'Abgenommen am: {databaseToPrettyDateTime(bookingRequest.approved_date)}',
                },
                spot_audio: {
                    title: 'Spot Audio',
                    category_1: {
                        title: 'Audio',
                        label_1: 'Aktuell: ',
                        label_2: 'Zuletzt aktualisiert am: {databaseToPrettyDate(campaign.audiofile_uploaded_date)}',
                        button_download: 'Audio runterladen',
                    },
                },
                deal_info: {
                    title: 'Deal Info',
                    category_1: {
                        title: 'Deal ID',
                    },
                    category_2: {
                        title: 'VAST URL',
                    },
                    category_3: {
                        title: 'Statischer Cross-Promotion Endpunkt',
                    },
                },
                ad_server_setup: {
                    title: 'AdServer Konfiguration',
                    category_1: {
                        title: 'Werbekampagne angelegt',
                        description_hostread:
                            'Aktivieren, wenn die Kampagne zu dieser Buchung im AdServer angelegt und das Werbemittel hinterlegt wurde - oder alternativ in den gebuchten Episoden platziert',
                        description_spot:
                            'Aktivieren, wenn die Kampagne zu dieser Buchung im AdServer angelegt und das Werbemittel hinterlegt wurde - oder alternativ in den gebuchten Episoden platziert',
                        description_crosspromo:
                            'Aktivieren, wenn entweder der allgemeine Cross-Promotion-Endpunkt auf dem AdServer definiert wurde (statischer Endpunkt), oder die Kampagne für diese Cross-Promotion auf dem AdServer erstellt wurde (VAST URL), oder die Audiodatei heruntergeladen und manuell in den gebuchten Episoden platziert wurde.',
                    },
                    category_2: {
                        title: 'AdServer Kampagnen-ID',
                        input_placeholder: 'e.g. AdServer Kampagnen-ID oder ID des Werbemittels',
                    },
                },
                report: {
                    title: 'Report',
                    button_upload: 'Report hochladen',
                    button_update: 'Report aktualisieren',
                    label_1: 'Aktuell: ',
                    // label_1: 'Aktuell: {bookingRequest.report}',
                    label_2: 'Zuletzt aktualisiert am: {databaseToPrettyDateTime(bookingRequest.report_uploaded_date)}',
                    label_3: 'Datei: ',
                    // label_3: 'Datei: {bookingRequest.report}',
                    label_4: 'Überprüft und abgenommen am: {databaseToPrettyDateTime(bookingRequest.report_approved_date)}',
                },
            },
            campaign_overview: {
                crosspromo_table: {
                    title: 'Cross-Promotions',
                    button_booking: 'Neue Cross-Promotion',
                    headline_1: 'Kampagne',
                    headline_2: 'Zielkontakte',
                    headline_3: 'Kampagnen Kontakte',
                    button_edit: 'Bearbeiten',
                },
                crosspromo_endpoint: {
                    title: 'Endpunkt(e) ',
                    headline_1: 'Podcast',
                    headline_2: 'Endpunkt',
                    button_copy: 'Kopieren',
                },
            },
            selfpromos: {
                title_general: 'Eigenwerbung',
                title: 'Eigenwerbungen für',
                description:
                    'Zur Bearbeitung und Verwaltung von Spots, die zur Eigenwerbung auf verschiedenen Podcast-Plattformen dienen. ASAP kann die unten aufgeführten Podcast-Plattformen identifizieren und, sobald eine Audiodatei für eine Plattform hinterlegt ist, automatisch an die jeweiligen Episoden pro Podcast als letzten Spot anhängen. Hierfür muss der jeweilige Podcast in ASAP angelegt, und die Option "Selfpromos" aktiviert sein. Zur Aktivierung/Deaktivierung bitte an das Support Team wenden.',
                section_1: {
                    title: 'Audio',
                    state: 'Aktuell',
                },
                section_2: 'Startdatum',
                section_3: 'Enddatum',
            },
            inventory: {
                title_general: 'Inventar',
                description:
                    'ASAP-Dispo Inventarverwaltung: Damit die Inventarverwaltung und Planung bestmöglich funktioniert, sollten alle Anfragen durch ASAP-Dispo gestellt werden. Anfragen von Extern können über den "Inventar-Check" als Kampagnen angelegt werden. Zum Aufsetzen bitte in den Inventar-Einstellungen das Buchungsvolumen pro Monat und Ausspielposition eingeben.',
                settings: {
                    section_title: 'Podcast Inventar Einstellungen',
                    card_title: 'Inventar-Einstellungen für',
                    section_1: {
                        title: 'Buchungsvolumen/Monat und Ausspielungs-position',
                    },
                    section_2: {
                        title: 'Buchungsvolumen/Tag',
                    },
                    section_3: {
                        title: 'Buchungsvolumen/Jahr',
                    },
                },
                filter_bar: {
                    start: 'Von',
                    end: 'Bis',
                    bookings: 'Buchungen',
                    reservations: 'reservierungen',
                    inventory_check: 'Inventar-Check',
                },
                titles: {
                    title_1: 'Überblick für ',
                    title_2: 'Details für ',
                    entry_1: 'Alle Werbeplätze zusammengefasst',
                    entry_2: 'Alle Kampagnen auf der Pre-Roll-Position',
                    entry_3: 'Alle Kampagnen auf der Mid-Roll-1-Position',
                    entry_4: 'Alle Kampagnen auf der Mid-Roll-2-Position',
                    entry_5: 'Alle Kampagnen auf der Mid-Roll-3-Position',
                    entry_6: 'Alle Kampagnen auf der Post-Roll-Position',
                },
                inventory_check: {
                    title_new: 'Neuer Inventar-check für eine externe Anfrage',
                    title_edit: 'Inventar-check bearbeiten',
                    campaign_title: 'Titel der Kampagne *',
                    campaign_type: 'Art der Kampagne *',
                    start_date: 'Geplanter Start der Kampagne *',
                    end_date: 'Geplantes Ende der Kampagne *',
                    roll_position: 'Gewünschte Platzierung *',
                    roll_position_error: 'Gewünschte Platzierung * - Bitte eine Position auswählen',
                    impressions: 'Zielkontakte *',
                    button_check: 'Jetzt checken',
                    button_cancel: 'Abbrechen',
                    button_delete: 'Löschen',
                    button_update: 'Aktualisieren',
                    button_reserve: 'Als Buchung einrichten',
                    button_book: 'Als Buchung speichern',
                },
                exclusive_promoter: {
                    section_title: 'Exklusive Vermarktung',
                    card_title: 'Exklusiver Vermarkter von',
                    section_1: {
                        title: 'Vermarkter',
                    },
                    section_2: {
                        title: 'Derzeit gibt es keinen exklusiven Vermarkter für diesen Podcast.',
                    },
                    section_3: {
                        title: 'PLACEHOLDER möchte PLACEHOLDER exklusiv vermarkten. Das heißt, dass in diesem Podcast die Werbung exklusiv durch PLACEHOLDER gestellt wird.',
                    },
                    section_4: {
                        title: 'Für PLACEHOLDER liegt eine Anfrage zur exklusiven Vermarktung vor. Die Anfrage kann unter “Inventar" > “Einstellungen” > "Exklusive Vermarktung", bearbeitet werden, oder untenstehenden Button klicken.',
                    },
                },
            },
        },
        commit_messages: {
            default: {
                booking_request: {
                    booking_sent: 'Buchungsanfrage wurde gesendet',
                    reservation_sent: 'Reservierungsanfrage wurde gesendet',
                    updated: 'Buchung wurde aktualisiert',
                    accept_reservation: 'Reservierung annehmen',
                    accept_request: 'Buchungsanfrage annehmen',
                    accepted: 'Buchung wurde angenommen',
                    rejected: 'Buchung wurde abgelehnt ohne Angabe von Gründen.',
                    audio_uploaded: 'Audiodatei wurde hochgeladen',
                    audio_approved: 'Audiodatei wurde abgenommen & freigegeben',
                    report_uploaded: 'Bericht wurde hochgeladen',
                    advertising_media_created: 'Werbemittel wurde auf AdServer hinterlegt.',
                    script_updated: 'Kampagnentext wurde aktualisiert',
                    script_approved: 'Kampagnentext wurde abgenommen & freigegeben.',
                    report_approved: 'Bericht wurde abgenommen.',
                    no_feedback: 'Es wurde kein Feedback hinterlegt.',
                    cancelled: 'Die Buchungsanfrage wurde aufgrund der Abweichungen im Angebot storniert.',
                    // impression_role_modifications_changes: 'impression/roll position modification changes have been requested.',
                    impression_role_modifications_changes: 'Anpassungswünsche der angebotenen Kontakte und/oder Platzierung.',
                    // impression_role_modifications_accepted: 'impression/roll position modifications have been accepted - deal has been closed',
                    impression_role_modifications_accepted: 'Anpassung der Kontakte/Platzierung wurde angenommen - Deal wurde abgeschlossen',
                    reject: 'Buchungsanfrage ablehnen',
                },
                campaign: {
                    created: 'Neue Kampagne erstellt & Buchungsanfrage gesendet',
                    updated: 'Kampagne wurde aktualisiert',
                    audio_uploaded: 'Audio zur Kampagne wurde hochgeladen',
                    audio_changed: 'Audio zur Kampagne wurde geändert',
                    briefing_completed: 'Briefing vervollständigt & freigegeben von Kundenseite',
                    sending_hostread_requests: 'Versenden von Host-Read Buchungsanfragen',
                    sending_spot_requests: 'Versenden von Spot-Buchungsanfragen',
                    sending_cross_promo_requests: 'Versenden von Cross-Promotion Buchungsanfragen',
                    request_script_changes: 'Änderungen am Kampagnentext anfordern',
                },
            },
            commit_message: {
                headline_1: 'Kommentar für "{action}"',
                headline_2: 'Welcher Kommentar soll für diese Aktion mitgeschickt werden? (optional)',
                input_placeholder: 'Nachricht zu dieser Aktion.',
                button_continue: 'Weiter',
            },
            booking_modification: {
                entry_1: {
                    action: 'Buchungsänderungen anfordern',
                    headline_1: 'Hier bitte die Änderungswünsche genauer beschreiben.',
                },
                entry_2: {
                    action: 'Buchungsanfrage löschen',
                    headline_1: 'Wieso wird diese Buchungsanfrage gelöscht?',
                },
                entry_3: {
                    action: 'Audioänderungen anfordern ',
                    headline_1: 'Hier bitte die Änderungswünsche genauer beschreiben.',
                },
            },
            commit_message_flow: {
                title_1: 'Kommentare für "{campaign.title}"',
                title_2: '- mit ',
            },
        },
        ui_messages: {
            sorry: 'Verzeihung!',
            button_proceed: 'Weiter',
            button_cancel: 'Abbrechen',
            alerts: {
                general: {
                    alert_save_fail: 'Speichern ist fehlgeschlagen. Bitte nochmal versuchen !',
                    alert_update_fail: 'Aktualisieren ist fehlgeschlagen. Bitte nochmal versuchen !',
                    alert_delete_fail: 'Löschen ist fehlgeschlagen. Bitte nochmal versuchen !',
                    alert_upload_fail: 'Hochladen ist fehlgeschlagen. Bitte nochmal versuchen !',
                    alert_wrong_audioformat: 'Bitte eine Audiodatei im mp3-Format hochladen !',
                    alert_required_information: 'Bitte die mit * gekennzeichneten Informationen eingeben!',
                },
                podcast: {
                    alert_role_position: 'Bitte eine Platzierung auswählen !',
                    alert_zero_impressions: 'Es werden 0 Kontakte angeboten! Bitte das Angebot anpassen.',
                    alert_maxed_impressions: 'Es können nicht mehr Kontakte als angefragt angeboten werden. Das Angebot wird auf das Maximum gesetzt.',
                    alert_no_audio_hostread: 'Um diese Anfrage annehmen zu können muss ein Audiofile des fertigen Host-Reads hochgeladen werden !',
                    alert_mp3_check: 'Bitte eine Audiodatei im mp3-Format hochladen !',
                    alert_audio_upload: 'Hochladen der Audiodatei fehlgeschlagen ! error: {response.status}',
                    alert_report_upload: 'Hochladen der PDF-Datei fehlgeschlagen !',
                    alert_no_client: 'Bitte Kund*in-Information hinterlegen !',
                },
                promoter: {
                    alert_zero_impressions: 'Anfragen mit 0 Kontakten werden ignoriert. Bitte bearbeiten !',
                    alert_no_title: 'Bitte einen Titel für diese Kampagne angeben !',
                    alert_no_acceptance_deadline: 'Bitte eine Annahmefrist für diese Kampagne angeben !',
                    alert_no_start_date: 'Bitte ein Startdatum für diese Kampagne angeben !',
                    alert_no_end_date: 'Bitte ein Enddatum für diese Kampagne angeben !',
                    alert_no_target_impressions: 'Bitte die Zielkontakte für diese Kampagne angeben !',
                    alert_no_audio_spot: 'Bitte eine Audiodatei für diesen Spot hochladen !',
                    alert_no_audio_crosspromo: 'Bitte eine Audiodatei für diese Cross-Promotion hochladen !',
                    alert_no_sender: 'Bitte einen gewünschten Podcast aus der Podcastliste für diese Cross-Promotion auswählen !',
                    alert_no_approval_option:
                        'Bitte mindestens eine Abnahmeoption für diesen Host-Read auswählen! Dies teilt den Podcaster*in mit, für welche Schritte während der Produktion eine Abnahme erforderlich ist.',
                    alert_role_position: 'Bitte eine Platzierung auswählen !',
                    alert_mp3_check: 'Bitte eine Audiodatei im mp3-Format hochladen !',
                    alert_audio_upload: 'Hochladen der Audiodatei fehlgeschlagen ! error: {response.status}',
                    alert_report_upload: 'Hochladen der PDF-Datei fehlgeschlagen ! error: {response.status}',
                    alert_targeting_lower_target_impressions:
                        'Eine Verringerung dieses Wertes führt zu einer Überbuchung der Kampagne! Bitte die Buchungsanfragen anpassen. Zunächst wird der vorherige Wert wiederhergestellt.',
                    alert_targeting_higher_target_impressions: 'Ein höherer Wert würde die Kampagne überbuchen. Die Kontakte werden für diese Anfrage auf das Maximum gesetzt.',
                    alert_dates_title: 'Vorsicht mit der Datums-Hirachie !',
                    alert_dates_line_1: 'Daten können nicht in der Vergangenheit liegen - die Bearbeitung erfolgt am besten rückwärts !',
                    alert_dates_line_2: 'Die chronologische Reihenfolge der Daten ist wie folgt:',
                    alert_dates_line_3: '1. Frist für die Annahme der Buchungsanfrage ->',
                    alert_dates_line_4: '2. Start der Kampagne ->',
                    alert_dates_line_5: '3. Ende der Kampagne.',
                    alert_dates_line_6: 'Bitte gültige Werte angeben !',
                },
                login: {
                    alert_valid_credentails: 'Bitte geben Sie gültige Anmeldedaten an!',
                    alert_wrong_credentails: 'E-Mail oder Passwort unbekannt. Bitte geben Sie gültige Anmeldedaten an!',
                },
            },
            warnings: {
                general: {
                    warning_no_changes: 'Es gibt keine zu speichernden Änderungen!',
                    warning_no_data: 'Bitte zuerst alle Daten eingegeben !',
                },
            },
            snackbars: {
                general: {
                    snackbar_save_success: 'Erfolgreich gespeichert.',
                    snackbar_update_success: 'Erfolgreich aktualisiert.',
                    snackbar_delete_success: 'Successfully deleted.',
                },
                podcast: {
                    snackbar_booking_request_updated: 'Buchungsanfrage erfolgreich aktualisiert.',
                    snackbar_booking_request_create_ad: 'Tschaka! Jetzt die Kampagne auf dem AdServer erstellen - oder in gebuchter(n) Episode(n) platzieren.',
                    snackbar_booking_request_accepted_hostread: 'Audio erfolgreich hochgeladen und Host-Read Anfrage akzeptiert.',
                    snackbar_booking_request_updated_audio: 'Audio erfolgreich aktualisiert.',
                    snackbar_selfpromo_date_reset: 'Datum wurde angepasst!',
                    snackbar_inventory_future_dates: 'Zur Zeit können wir nicht weiter als ein Jahr in die Zukunft gucken.',
                    snackbar_inventory_exists: 'Für diese Kampagne wurde bereits ein Check gemacht! Bitte eindeutigen Titel verwenden.',
                    snackbar_exclusive_promoter_delete_success: 'Die exklusive Vermarktung durch {company.name} wurde erfolgreich aufgelöst.',
                },
                promoter: {
                    snackbar_campaign_save_success: 'Neue Kampagne erfolgreich gespeichert.',
                },
                admin: {
                    snackbar_name_empty: 'Das Namensfeld kann nicht leer sein !',
                    snackbar_email_empty: 'Das Email-Feld kann nicht leer sein !',
                    snackbar_company_empty: 'Die Zuweisung zu einer Company kann nicht leer sein !',
                    snackbar_booking_volume_empty: 'Das Buchungsvolumen kann nicht leer sein ! Wenn Null gewünscht ist, bitte 0 eintragen.',
                    snackbar_entry_exists: 'Es existiert bereits ein Eintrag mit diesem Namen. Bitte ändern !',
                    snackbar_user_exists: 'Es existiert bereits ein Eintrag mit dieser Emailadressse. Bitte ändern !',
                    snackbar_password_includes_email: 'Das eingegebene Passwort enthällt die Emailadressse. Bitte ein neues eingeben !',
                    snackbar_password_characters: 'Das eingegebene Passwort muss länger als 8 Zeichen haben. Bitte bearbeiten !',
                    snackbar_email_format:
                        'Die eingegebene Emailadresse muss dem Format "adresse@provider.top-level-domain" folgen, wobei die Top-Level-Domain keine Zahlen enthalten darf. Bitte bearbeiten !',
                },
            },
            confirms: {
                podcast: {
                    confirm_selfpromo_edit: 'Wenn eine bestehende Eigenwerbung geändert wird, werden die Daten, die in der Vergangenheit liegen, automatisch auf heute aktualisiert. Einverstanden?',
                    confirm_selfpromo_delete: 'Das Löschen einer Eigenwerbung führt dazu, dass sie komplett aus dem System entfernt wird. Mit dem Löschen fortfahren?',
                    confirm_selfpromo_delete_title: 'Achtung !',
                    confirm_campaign_cancel:
                        'Achtung! Die Stornierung einer Kampagne und ihrer Buchungsanfrage bedeutet, dass sie mit sofortiger Wirkung gestoppt werden. Eine spätere Reaktivierung ist nicht möglich. Mit dem Stornieren fortfahren?',
                    confirm_exclusive_promotion_delete: 'Hiermit wird, mit sofortiger Wirkung, die exklusive Vermarktung durch {campaign.name} gestoppt. Wirklich fortfahren?',
                },
                promoter: {
                    confirm_exclusive_promotion_delete: 'Hiermit wird, mit sofortiger Wirkung, die exklusive Vermarktung von {podcast.name} gestoppt. Wirklich fortfahren?',
                    confirm_exclusive_promotion_select:
                        'Hiermit wird angefragt, ob PLACEHOLDER den Podcast PLACEHOLDER exklusiv vermarkten darf. Dies bedeutet, dass PLACEHOLDER nur Werbung durch PLACEHOLDER platzieren darf. Wirklich fortfahren?',
                },
            },
        },
        buttons: {
            button_reject: 'Ablehnen',
            button_agree: 'Zustimmen',
            button_confirm: 'Bestätigen',
            button_save: 'Speichern',
            button_edit: 'Bearbeiten',
        },
        badges: {
            general: {
                entry_1: 'Von: ',
            },
            booking_request: {
                entry_1: 'Abgelehnt am: {databaseToPrettyDateTime(bookingRequest.cancelled_date)}',
                entry_2: 'Storniert am: {databaseToPrettyDateTime(bookingRequest.cancelled_date)}',
            },
            text_editor: {
                entry_1: 'Zeichen',
                entry_2: 'Wörter',
                entry_3: 'Abgenommen am: {databaseToPrettyDateTime(bookingRequest.script_approved_date)}',
                entry_4: 'Zuletzt aktualisiert am: {databaseToPrettyDateTime(bookingRequest.script_updated_date)}',
            },
            audio_status: {
                entry_1: 'Abgenommen am: {databaseToPrettyDateTime(bookingRequest.audiofile_approved_date)}',
                entry_2: 'Zuletzt aktualisiert am: {databaseToPrettyDateTime(bookingRequest.audiofile_updated_date)}',
            },
            report_status: {
                entry_1: 'Abgenommen am: {databaseToPrettyDateTime(bookingRequest.report_approved_date)}',
                entry_2: 'Zuletzt aktualisiert am: {databaseToPrettyDateTime(bookingRequest.report_updated_date)}',
            },
        },
        placeholder: {
            compose_script: 'Bitte Kampagnentext verfassen.',
        },
        user: {
            dropdown: {
                entry_1: 'Profil',
                entry_2: 'Abmelden',
                entry_3: 'Anmelden',
            },
            password_reset: {
                title: 'Passwort zurück setzen',
                headline_1: 'Das neue Passwort muss aus mindestens 8 Zeichen bestehen.',
                headline_2: 'Mindestens eines davon muss eine Zahl sein.',
                headline_3: 'Mindestens eines davon muss ein Buchstabe sein.',
                headline_4: 'Es darf keine Sonderzeichen oder Leerzeichen enthalten.',
                input_placeholder: 'Neues Passwort eingeben',
                button_1: 'Neues Passwort speichern',
                button_2: 'Zuerst gültige Passphrase eingeben !',
            },
            profile: {
                button_cancel: 'Abbrechen',
                button_save: 'Speichern',
                button_reset_password: 'Passwort zurücksetzen',
                snackbar_1: 'Der Link zum Zurücksetzen des Passworts wurde an die hinterlegte E-Mail-Adresse geschickt. Es kann ein paar Minuten dauern, bis diese im Postfach ankommt.',
            },
        },
        auth: {
            login: {
                snackbar_password_forgotten: 'Bitte die E-Mail-Adresse eingeben, an die der Link zum Zurücksetzen des Passworts gesendet werden soll! Die Zustellung kann ein paar Minuten dauern.',
            },
        },
        general: {
            roll_positions: {
                entry_1: 'Pre-Roll',
                entry_2: 'Mid-Roll-1',
                entry_3: 'Mid-Roll-2',
                entry_4: 'Mid-Roll-3',
                entry_5: 'Post-Roll',
            },
            roll_positions_missing_link: {
                entry_1: 'Pre-Roll',
                entry_2: 'Mid-Roll-1',
                entry_3: 'Mid-Roll-2',
                entry_4: 'Mid-Roll-3',
                entry_5: 'Mid-Roll-4',
                entry_6: 'Mid-Roll-5',
                entry_7: 'Post-Roll',
            },
        },
    };

    // english dictionary
    const en = {
        admin: {
            sidebar: {
                promoter: {
                    title: 'Admin',
                    entry_1: 'Users',
                },
                podcast: {
                    title: 'Admin',
                    entry_1: 'Podcasts',
                    entry_2: 'Users',
                },
                admin: {
                    title: 'Admin',
                    entry_1: 'Overview',
                    entry_2: 'Companies',
                    entry_3: 'Podcasts',
                    entry_4: 'Keywords',
                    entry_5: 'Users',
                },
            },
            companies: {
                new_company: {
                    title: 'New Company',
                    button_new: 'New',
                    button_save: 'Save',
                    button_cancel: 'Cancel',
                    category_1: {
                        title: 'Company Information',
                        entry_1: {
                            title: 'Name *',
                        },
                        entry_2: {
                            title: 'Type *',
                            input_placeholder: '--- choose ---',
                        },
                    },
                },
                all_companies: {
                    title: 'All Companies',
                    button_new: 'New',
                    headline_1: 'ID',
                    headline_2: 'Name',
                    headline_3: 'Type',
                },
            },
            podcasts: {
                new_podcast: {
                    title: 'New Podcast',
                    button_save: 'Save',
                    button_cancel: 'Cancel',
                    category_1: {
                        title: 'Podcast Information',
                        entry_1: {
                            title: 'Name *',
                        },
                        entry_2: {
                            title: 'Company *',
                            input_placeholder: '--- choose ---',
                        },
                        entry_3: {
                            title: 'Max Booking Volume *',
                        },
                        entry_4: {
                            title: 'Summary',
                        },
                        entry_5: {
                            title: 'Description',
                        },
                        entry_6: {
                            title: 'URL',
                        },
                        entry_7: {
                            title: 'Preview Image URL',
                        },
                        entry_8: {
                            title: 'Keywords',
                        },
                        entry_9: {
                            title: 'ASAP Podcast ID *',
                        },
                    },
                },
                all_podcasts: {
                    title: 'All Podcasts',
                    button_new: 'New',
                    headline_1: 'Podcast ID',
                    headline_6: 'ASAP-Podcast-ID',
                    headline_2: 'Name',
                    headline_3: 'Max Booking Volume',
                    headline_4: 'Company ID',
                    headline_5: 'Cross-Promotion Endpoint',
                },
            },
            keywords: {
                new_keyword: {
                    title: 'New Keyword',
                    button_save: 'Save',
                    button_cancel: 'Cancel',
                    category_1: {
                        title: 'Keywords',
                        entry_1: {
                            title: 'Add Keyword(s) *',
                            input_placeholder: 'Separate multiple keywords with a comma',
                        },
                    },
                },
                all_keywords: {
                    title: 'All Keywords',
                    button_new: 'New',
                    headline_1: 'ID',
                    headline_2: 'Keyword',
                },
            },
            users: {
                current_user: {
                    title: 'Current User',
                    button_new: 'New',
                    headline_1: 'Name',
                    headline_2: 'Email',
                    headline_3: 'Company ID',
                    headline_4: 'User ID',
                    headline_5: 'Role',
                },
                all_users: {
                    title: 'All Users',
                    button_new: 'New',
                    headline_1: 'Name',
                    headline_2: 'Email',
                    headline_3: 'Company ID',
                    headline_4: 'User ID',
                    headline_5: 'Role',
                },
                new_user: {
                    title: 'New User',
                    button_save: 'Save',
                    button_cancel: 'Cancel',
                    category_1: {
                        title: 'Podcast Information',
                        entry_1: {
                            title: 'Name *',
                        },
                        entry_2: {
                            title: 'Email *',
                        },
                        entry_3: {
                            title: 'Password *',
                        },
                        entry_4: {
                            title: 'Company *',
                            input_placeholder: '--- choose ---',
                        },
                    },
                },
            },
        },
        promoter: {
            sidebar: {
                section_1: {
                    title: 'Campaigns',
                    entry_1: 'In Preparation',
                    entry_2: 'Running',
                    entry_3: 'Completed',
                    entry_4: 'All',
                    entry_5: 'Archived',
                },
                section_2: {
                    title: 'User',
                    entry_1: 'Profile',
                    entry_2: 'Logout',
                },
            },
            campaign_overview: {
                hostread_table: {
                    title: 'Host-Read Campaigns',
                    button_reservation: 'New Reservation',
                    button_booking: 'New Host-Read',
                    headline_1: 'Campaign',
                    headline_2: 'Target contacts',
                    headline_3: 'Campaign contacts',
                    button_edit: 'Edit',
                    button_briefing: 'Complete campaign briefing',
                    button_archive: 'Archive campaign',
                },
                spot_table: {
                    title: 'Spot Campaigns',
                    button_reservation: 'New Reservation',
                    button_booking: 'New Spot',
                    headline_1: 'Campaign',
                    headline_2: 'Target contacts',
                    headline_3: 'Campaign contacts',
                    button_edit: 'Edit',
                    button_briefing: 'Upload campaign audio',
                    warning_campaign_cancelled: 'Deadline passed ! Campaign cancelled',
                },
                targeting_monitor: {
                    title: 'Targeting Monitor',
                    section_1: {
                        title: 'TARGETED',
                        description: 'Target contacts',
                    },
                    section_2: {
                        title: 'REQUESTED',
                        description_1: 'of targeted',
                        description_2: 'underbooked !',
                        description_3: 'overbooked !',
                    },
                    section_3: {
                        title: 'RESERVED',
                        description: 'of requested',
                    },
                    section_4: {
                        title: 'DEALED',
                        description_1: 'of reserved',
                        description_2: 'of requested',
                    },
                    section_5: {
                        title: 'SERVED',
                        description: 'of dealed',
                    },
                },
                booking_requests_table: {
                    headline_1: 'Next Step',
                    headline_2: 'Podcast',
                    headline_3: 'Requested / Offered Contacts',
                    headline_4: 'Current Contacts',
                },
                booking_requests_table_rows: {
                    not_activated: {
                        button_send: 'Send request',
                        status: 'Request not sent yet',
                    },
                    pending: {
                        booking_request_modifications: {
                            title: 'Modifications from "{podcastName}" for ',
                            button_confirm: 'Confirm booking',
                            button_request_changes: ' Request changes',
                            button_delete: 'Delete booking',
                            category_1: {
                                title: 'Contacts',
                                entry_1: {
                                    title: 'Requested',
                                },
                                entry_2: {
                                    title: 'Offered',
                                },
                                entry_3: {
                                    title: 'Difference',
                                },
                            },
                            category_2: {
                                title: 'Roll Position(s)',
                                entry_1: {
                                    title: 'Requested',
                                },
                                entry_2: {
                                    title: 'Offered',
                                },
                            },
                        },
                        state_2: {
                            next_step_hostread: 'Waiting for "{data.name}" to accept Host-Read request',
                            next_step_spot: 'Waiting for "{data.name}" to accept spot request',
                            next_step_crossporomo: 'Waiting for "{data.name}" to accept crosspromo request',
                            status: 'Deal not closed yet',
                        },
                        state_3: {
                            next_step_1: 'Request accepted - check modifications',
                            next_step_2: 'Waiting for "{data.name}" to adjust offer',
                            status: 'Deal not closed yet',
                        },
                        state_4: {
                            next_step_hostread: 'Host-Read Request was rejected by "{data.name}" on {databaseToPrettyDate(bookingRequest.rejected_date)}',
                            next_step_spot: 'Spot Request was rejected by "{data.name}" on {databaseToPrettyDate(bookingRequest.rejected_date)}',
                            next_step_crosspromo: 'Cross Promotion Request was rejected by "{data.name}" on {databaseToPrettyDate(bookingRequest.rejected_date)}',
                            status: 'Offer has been rejected',
                        },
                        state_5: {
                            next_step_1: 'Review script changes',
                            next_step_2: 'Waiting for "{ data.name }" to supply script',
                            next_step_3: 'Waiting for "{data.name}" to supply audio',
                            next_step_4: 'Request accepted - complete campaign briefing',
                            next_step_5: 'Script approved - awaiting audiofile',
                            next_step_6: 'Script updated - awaiting correction',
                            next_step_7: 'Request accepted - upload campaign audio !',
                            next_step_8: 'Waiting for "{data.name}" to close deal',
                            next_step_9: 'Waiting for "{data.name}" to accept cross promotion request',
                            next_step_10: 'Waiting for audio file',
                            status: 'Deal not closed yet',
                        },
                        state_6: {
                            next_step_hostread: 'Host-Read Request has been cancelled on {databaseToPrettyDate(bookingRequest.cancelled_date)}',
                            next_step_spot: 'Spot Request has been cancelled on {databaseToPrettyDate(bookingRequest.cancelled_date)}',
                            next_step_crosspromo: 'Cross Promotion Request has been cancelled on {databaseToPrettyDate(bookingRequest.cancelled_date)}',
                            status: 'Offer has been withdrawn',
                        },
                        state_7: {
                            next_step_1: 'Download audio',
                            next_step_2: 'Approve audio',
                            next_step_3: 'Request changes',
                            status: 'Deal not closed yet',
                        },
                        state_8: {
                            next_step_1: 'Campaign starts in {getDateDiff(getDateNowDatetime(), campaign.start_date)} days',
                        },
                        state_13: {
                            next_step_hostread: 'Acceptance deadline has been missed by "{data.name}"',
                            next_step_spot: 'Acceptance deadline has been missed by "{data.name}"',
                            next_step_crosspromo: 'Acceptance deadline has been missed by "{data.name}"',
                            status: 'Acceptance deadline has been missed',
                            button_extend_deadline: 'Extend deadline to start date',
                        },
                    },
                    on_air: {
                        next_step_1: 'Campaign ends in {getDateDiff(getDateNowDatetime(), campaign.end_date)} days',
                    },
                    completed: {
                        state_10: {
                            next_step_1: 'Awaiting report',
                        },
                        state_11: {
                            next_step_1: 'Download report',
                            next_step_2: 'Approve report',
                            next_step_3: 'Request changes',
                        },
                        state_12: {
                            next_step_1: 'Wrapped',
                        },
                    },
                },
            },
            campaign: {
                title_hostread: 'Create New Host-Read Campaign',
                title_spot: 'Create New Spot Campaign',
                title_cross_promo: 'Create New Cross Promotion Campaign',
                title_reserve_hostread: 'Create New Reservation for a Host-Read Campaign',
                title_reserve_spot: 'Create New Reservation for a Spot Campaign',
                title_reserve_cross_promo: 'Create New Reservation for a Cross Promotion Campaign',
                button_save: 'Save',
                button_saving: 'Saving ...',
                button_save_send_hostread: 'Save & send all Host-Read Requests',
                button_save_send_spot: 'Save & send all Spot Requests',
                button_save_send_crosspromo: 'Save & send all Cross Promotion Requests',
                button_save_send_reservation: 'Save & send Reservation Requests',
                button_cancel: 'Cancel',
                button_cancel_campaign: 'Cancel campaign & booking requests',
                general_information: {
                    title: 'General Information',
                    category_1: {
                        title: 'Title *',
                        input_placeholder: 'Campaign title',
                    },
                    category_2: {
                        title: 'Client *',
                        title_podcast: 'Client *',
                        input_placeholder: 'Client contact',
                    },
                    category_3: {
                        title: 'Requesting Podcast *',
                        input_placeholder: '--- select podcast ---',
                    },
                    category_4: {
                        title: 'Duration (seconds)',
                        input_placeholder: 'Length of the ad in seconds',
                    },
                    category_5: {
                        title: 'Required Approvals *',
                        entry_1: 'Script',
                        entry_2: 'Audio',
                        entry_3: 'None',
                    },
                    category_6: {
                        title: 'Desired Position(s) *',
                        info: 'Multiple roll postions can be selected. However, the final delivery will only be on one slot.',
                    },
                },
                date_information: {
                    title: 'Date Information',
                    category_1: {
                        title: 'Booking Accaptance Deadline *',
                    },
                    category_2: {
                        title: 'Campaign Start Date *',
                    },
                    category_3: {
                        title: 'Campaign End Date *',
                    },
                },
                briefing_information: {
                    category_1: {
                        title: 'Briefing',
                        entry_1: {
                            title: 'Script',
                            button_update: 'Update script',
                            input_placeholder: 'For the text to be read.',
                            label_1: 'The briefing has been completed. Further edits of the script can be done in the script manager of the individual booking request.',
                        },
                    },
                    category_2: {
                        title: 'Moods',
                        entry_1: {
                            title: 'Background Information',
                            input_placeholder: 'For campaign planning - e.g. new product, model, ...',
                        },
                        entry_2: {
                            title: 'Campaign Goal',
                            input_placeholder: 'Description of what should be communicated with the campaign.',
                        },
                        entry_3: {
                            title_1: 'Audio Keywords - comma separated',
                            title_2: 'Audio keywords - click to remove',
                            input_placeholder: 'Type comma separated keywords',
                        },
                        entry_4: {
                            title_1: 'No Go Words - comma separated',
                            title_2: 'No go words - click to remove',
                            input_placeholder: 'Type comma separated keywords',
                        },
                        entry_5: {
                            title: 'URL to be communicated',
                            input_placeholder: 'URL that should be mentioned in add',
                        },
                    },
                    category_3: {
                        title: 'Resources',
                        entry_1: {
                            title: 'Hear & Feel',
                            button_update: 'Update',
                            description: 'Zipped file upload for existing audio logo, examples of brand and product pronunciation.',
                        },
                        entry_2: {
                            title: 'Sound effects',
                            button_update: 'Update',
                            description: 'Resources for sound effects (zipped media file or cloud link).',
                            input_placeholder: 'Cloud link',
                        },
                        entry_3: {
                            title: 'Supplementary URL',
                            input_placeholder: 'Link to audio or video files',
                        },
                    },
                    category_4: {
                        title: 'Briefing Status',
                        description: 'Complete Briefing & send to podcast(s)',
                        label_1: 'Check this if the briefing is completed, so that the publishers can start producing the Host-Read.',
                        label_2: 'Briefing completed.',
                        label_3: 'Last updated on: {databaseToPrettyDateTime(campaign.briefing_last_updated_date)}',
                    },
                },
                audio_spot_upload: {
                    title_1: 'Audio Spot Upload *',
                    title_2: 'Audio Spot',
                    button_upload: 'Click to upload & check audio',
                    button_update: 'Click to listen to new audio',
                    label_1: 'Current: ',
                    label_2: 'Last updated on: {databaseToPrettyDateTime(audiofileUploadedDate)}',
                },
            },
            targeting: {
                title: 'Contacts',
                category_1: {
                    entry_1: 'Total Target Contacts *',
                    entry_2: 'Requested Target Contacts',
                    entry_3: 'Remaining Target Contacts',
                },
                category_2: {
                    title: 'Podcasts',
                    entry_1: {
                        input_placeholder: 'Filter Podcasts ...',
                    },
                    entry_2: {
                        headline_1: 'Selection',
                        headline_2: 'Podcast',
                        headline_3: 'Max Booking Volume',
                        headline_4: 'Contacts Requests',
                    },
                },
            },
            production_flow: {
                title: 'Production Flow for ',
                headline_1: 'Action',
                headline_2: 'State',
                headline_3: 'Date',
                headline_4: 'User',
                category_1: {
                    title: 'Created',
                },
                category_2: {
                    title: 'Activated',
                },
                category_3: {
                    title_1: 'Acceptance &',
                    title_2: 'Audio Upload Deadline',
                },
                category_4: {
                    title: 'Rejected',
                },
                category_5: {
                    title: 'Accepted',
                    description_1: 'with {prettyPrintNumber(bookingRequest.offered_impressions)}',
                    description_2: 'of {prettyPrintNumber(bookingRequest.requested_impressions)} requested impressions as a ',
                    description_3: 'of {prettyPrintNumber(bookingRequest.requested_impressions)} requested impressions on positions ',
                },
                category_6: {
                    title: 'Audiofile Uploaded',
                },
                category_7: {
                    title: 'Approved',
                },
                category_8: {
                    title: 'Cancelled',
                },
                category_9: {
                    title: 'Deal ID',
                },
                category_10: {
                    title: 'VAST URL',
                },
                category_11: {
                    title: 'Campaign Start',
                },
                category_12: {
                    title: 'Campaign End',
                },
                category_13: {
                    title: 'Report Upload Deadline',
                },
                category_14: {
                    title: 'Report Uploaded',
                },
                category_15: {
                    title: 'Report Checked & Approved',
                },
            },
            script_version_control: {
                title_1: 'Script Version Control for "{campaign.title}"',
                title_2: '- with ',
                headline_1: 'Original version',
                headline_2: 'Current version',
                headline_3: 'Last version',
                headline_4: 'Approved version',
                updated: 'Last updated on: {databaseToPrettyDateTime(bookingRequest.script_updated_date)}',
                button_approve: 'Approve this version',
                button_request_changes: 'Request changes',
                button_update: 'Update & save script',
                button_share_email: 'Share via email',
                email_subject: 'Script from "{podcast.name}"',
                email_text: 'Hello,\n\nhere comes the current script for "{campaign.title}"',
            },
            network: {
                podcasts: {
                    headline: 'Podcast Manager',
                    description: 'The Podcast Manager ...',
                    title_1: 'Info',
                    title_2: 'Favorites',
                    title_3: 'Exklusive podcasts',
                    title_4: 'All podcasts',
                },
                podcast_info: {
                    title_1: 'Description',
                    title_2: 'Genre',
                    title_3: 'Contacts/Month',
                },
            },
        },
        podcast: {
            sidebar: {
                section_1: {
                    title: 'Booking Requests',
                    entry_1: 'AdOps - ToDo',
                    entry_2: 'Waiting',
                    entry_3: 'Upcoming',
                    entry_4: 'Running',
                    entry_5: 'Completed',
                    entry_6: 'Wrapped',
                    entry_7: 'Rejected',
                    entry_8: 'Cancelled',
                },
                section_2: {
                    title: 'Cross Promotions',
                    entry_1: 'New',
                    entry_2: 'All',
                },
                section_3: {
                    title: 'User',
                    entry_1: 'Logout',
                },
                section_4: {
                    title: 'Self promotion',
                    entry_1: 'Settings',
                },
                section_5: {
                    title: 'Inventory',
                    entry_1: 'Overview',
                    entry_2: 'Campaigns',
                    entry_3: 'Settings',
                },
            },
            booking_requests: {
                todo: {
                    empty_status: 'Tschaka! All that was undone is now done !',
                    headline_1: 'Customer',
                    headline_2: 'Campaign',
                    headline_3: 'Requested/Offered Contacts',
                    headline_4: 'Next Step',
                    headline_5: 'Controls',
                    rows: {
                        button_archive: 'Archive',
                        next_step_1: 'Check out new incoming !',
                        next_step_2: 'Audio uploaded',
                        next_step_3: 'React to change request !',
                        next_step_4: 'Awaiting briefing',
                        next_step_5: 'Check script briefing !',
                        next_step_6: 'Script approved - produce audio !',
                        next_step_7: 'Check audio feedback !',
                        next_step_8: 'React to briefing !',
                        next_step_9: 'Briefing completed !',
                        next_step_10: 'Upload audio !',
                        next_step_11: 'Awaiting audiofile to be approved',
                        next_step_12: 'Deal has been closed - create ad !',
                        next_step_13: 'Campaign is over - upload report !',
                        next_step_14: 'Booking Request has been withdrawn on {databaseToPrettyDate(bookingRequest.cancelled_date)}',
                        next_step_15: 'Check script feedback !',
                        next_step_16: 'Booking Request has been rejected',
                        next_step_17: 'Acceptance deadline has been missed',
                        next_step_18: 'Deal is closed - ad will be placed by the promoter !',
                    },
                },
                waiting: {
                    empty_status: 'Currently nothing to wait for.',
                    headline_1: 'Customer',
                    headline_2: 'Campaign',
                    headline_3: 'Requested/Offered Contacts',
                    headline_4: 'Next Step',
                    headline_5: 'Controls',
                    rows: {
                        next_step_1: 'Awaiting booking approval',
                        next_step_2: 'Awaiting script to be approved',
                        next_step_3: 'Awaiting briefing',
                        next_step_4: 'Waiting for audio to be uploaded',
                        next_step_5: 'Waiting for audio to be approved & deal to be closed',
                    },
                },
                upcoming: {
                    empty_status: 'Currently no upcoming campaigns.',
                    headline_1: 'Customer',
                    headline_2: 'Campaign',
                    headline_3: 'Start',
                    headline_4: 'End',
                    headline_5: 'Dealed Contacts',
                    headline_6: 'Controls',
                },
                running: {
                    empty_status: 'Currently no running campaigns.',
                    headline_1: 'Customer',
                    headline_2: 'Campaign',
                    headline_3: 'Runtime & Remaining Time',
                    headline_4: 'Served & Remaining Contacts',
                    headline_5: 'Controls',
                },
                completed: {
                    empty_status: 'Currently no completed campaigns.',
                    headline_1: 'Customer',
                    headline_2: 'Campaign',
                    headline_3: 'Controls',
                    rows: {
                        next_step_1: 'Awaiting report to be approved',
                    },
                },
                wrapped: {
                    empty_status: 'No wrapped campaigns.',
                },
                rejected: {
                    empty_status: 'So far, no requests have been rejected.',
                    headline_1: 'Customer',
                    headline_2: 'Campaign',
                    headline_3: 'Requested/Offered Contacts',
                    headline_4: 'State',
                    headline_5: 'Controls',
                },
                cancelled: {
                    empty_status: 'So far, no requests have been cancelled.',
                    headline_1: 'Customer',
                    headline_2: 'Campaign',
                    headline_3: 'Requested/Offered Contacts',
                    headline_4: 'State',
                    headline_5: 'Controls',
                },
            },
            booking_request_details: {
                button_accept_reservation: 'Accept Reservation with {prettyPrintNumber(offeredImpressions)} Contacts',
                button_accept_hostread: 'Accept Host-Read Request with {prettyPrintNumber(offeredImpressions)} Contacts',
                button_accept_spot: 'Accept Spot Request with {prettyPrintNumber(offeredImpressions)} Contacts',
                button_accept_crosspromo: 'Accept Cross Promotion Request with {prettyPrintNumber(offeredImpressions)} Contacts',
                button_reject: 'Reject',
                button_update: 'Update',
                button_exit: 'Exit',
                next_step: {
                    label_1: 'Campaign owner',
                    state_2: {
                        entry_1: 'Accept reservation for Host-Read contacts',
                        entry_2: 'Accept spot request',
                        entry_3: 'Accept cross promotion request',
                    },
                    state_3: {
                        entry_1: 'Waiting for campaign owner to approve booking with adjusted contacts/roll-position.',
                        entry_2: 'React to the change request the campaign owner has sent via commit message !',
                    },
                    state_4: {
                        entry_1: 'Host-Read request has been rejected',
                        entry_2: 'Spot request has been rejected',
                        entry_3: 'Cross promotion request has been rejected',
                    },
                    state_5: {
                        entry_1: 'Waiting for briefing to be completed.',
                        entry_2: 'Edit script !',
                        entry_3: 'Waiting for script approval.',
                        entry_4: 'Adjust audio !',
                        entry_5: 'Waiting for audio approval.',
                        entry_6: 'Produce & upload Host-Read audio !',
                        entry_7: 'Waiting for audio to be uploaded.',
                    },
                    state_6: {
                        entry_1: 'Host-Read request has been withdrawn',
                        entry_2: 'Spot request has been withdrawn',
                        entry_3: 'Cross promotion request has been withdrawn',
                    },
                    state_7: {
                        entry_1: 'Waiting for audiofile to be approved',
                    },
                    state_8: {
                        entry_1: 'Create ad campaign on ad server - or place ad in booked episode(s)',
                        entry_2: 'Campaign starts in {getDateDiff(getDateNowDatetime(), campaign.start_date)} days',
                        entry_3: 'The campaign is created by {responsable} on the ad server, and starts in {getDateDiff(getDateNowDatetime(), campaign.start_date)} day(s)',
                    },
                    state_9: {
                        entry_1: 'Campaign ends in {getDateDiff(getDateNowDatetime(), campaign.end_date)} days',
                    },
                    state_10: {
                        entry_1: 'Upload report !',
                    },
                    state_11: {
                        entry_1: 'Waiting for report to be checked & approved',
                    },
                    state_12: {
                        entry_1: 'This campaign is wrapped. Nothing left to do here.',
                    },
                    state_13: {
                        entry_1: 'React to booking request.',
                    },
                    state_14: {
                        entry_1: 'Deadline for booking request acceptance has been missed.',
                    },
                },
                commit_messages: {
                    button_show_all: 'Show all commits',
                    headline_1: 'Message regarding this request',
                    headline_2: 'Message regarding the cancellation of this request',
                    headline_3: 'Message regarding the script update',
                    headline_4: 'Feedback regarding the script',
                    headline_5: 'Message regarding the audio',
                    headline_6: 'Message regarding the report',
                    headline_7: 'Message regarding the campaign update',
                    headline_8: 'Last commit',
                    headline_9: 'No message supplied for the last action: {lastCommitMessage.description}.',
                },
                status: {
                    category_1: {
                        title: 'Status',
                        state_2: {
                            entry_1: 'New incoming Host-Read request',
                            entry_2: 'New incoming spot request',
                            entry_3: 'New incoming cross promotion request',
                        },
                        state_3: {
                            entry_1: 'Awaiting booking approval',
                            entry_2: 'React to change request',
                        },
                        state_4: {
                            entry_1: 'Rejected',
                        },
                        state_5: {
                            entry_1: 'Awaiting briefing',
                            entry_2: 'Briefing completed',
                            entry_3: 'Script updated',
                            entry_4: 'Script approved',
                            entry_5: 'Awaiting audio file',
                            entry_6: 'Awaiting to close deal',
                            entry_7: 'Audiofile changes requested',
                        },
                        state_6: {
                            entry_1: 'Cancelled',
                        },
                        state_7: {
                            entry_1: 'Awaiting to close deal',
                            entry_2: 'Deal closed - create ad',
                        },
                        state_8: {
                            entry_1: 'Deal closed - create ad',
                            entry_2: 'Ad created',
                            entry_3: 'Deal is closed - ad will be placed by the promoter',
                        },
                        state_9: {
                            entry_1: 'On air',
                        },
                        state_10: {
                            entry_1: 'Completed - awaiting report',
                        },
                        state_11: {
                            entry_1: 'Completed - awaiting report check',
                        },
                        state_12: {
                            entry_1: 'Wrapped',
                        },
                        state_13: {
                            entry_1: 'Awaiting to close deal',
                        },
                        state_14: {
                            entry_1: 'Missed deadline',
                        },
                    },
                    category_2: {
                        title: 'Next Step',
                        entry_1: 'By: {responsable}',
                        entry_2: 'Until: {databaseToPrettyDate(date)}',
                        entry_3: '- ({getDateDiff(getDateNowDatetime(), date)} days left)',
                        entry_4: 'Date: {databaseToPrettyDate(date)}',
                    },
                },
                booking_information: {
                    title: 'Booking Information',
                    category_1: {
                        title: 'Campaign Start',
                        description: 'days left)',
                    },
                    category_2: {
                        title: 'Campaign End',
                        description: 'days left)',
                    },
                    category_3: {
                        title_hostread: 'Deadline to accept Host-Read request & upload audio',
                        title_spot: 'Deadline to accept spot request',
                        title_crosspromo: 'Deadline to accept cross promotion request',
                        description: 'days left)',
                    },
                    category_4: {
                        title: 'Requested Contacts',
                    },
                    category_5: {
                        title: 'Offered Contacts (max. {prettyPrintNumber(bookingRequest.requested_impressions)})',
                    },
                    category_6: {
                        title: 'Duration',
                        description: '{campaign.hostread_duration_ms} seconds',
                    },
                    category_7: {
                        title: 'Required Approvals',
                        entry_1: 'Script',
                        entry_2: 'Audio',
                        entry_3: 'None',
                    },
                    category_8: {
                        title: 'Potential Roll Position(s)',
                    },
                    category_9: {
                        title: 'Offered Roll Position(s)',
                        info: 'Several roll-positions can be requested for selection, but the actual booking can only be placed on one position.',
                    },
                },
                briefing_information: {
                    title: 'Briefing information',
                    category_1: {
                        title: 'Script',
                        description: 'Last updated on: {databaseToPrettyDateTime(bookingRequest.script_updated_date)}',
                        button_update: 'Update & save script',
                    },
                    category_2: {
                        title: 'Background Information',
                    },
                    category_3: {
                        title: 'Campaign Goal',
                    },
                    category_4: {
                        title: 'Audio Keywords',
                    },
                    category_5: {
                        title: 'No Go Words',
                    },
                    category_6: {
                        title: 'URL to be communicated',
                    },
                    category_7: {
                        title: 'Hear & Feel',
                    },
                    category_8: {
                        title: 'Sound Effects',
                    },
                    category_9: {
                        title: 'Sound Effects Cloud Link',
                    },
                    category_10: {
                        title: 'Supplementary URL',
                    },
                },
                file_upload: {
                    title: 'Audio',
                    button_upload: 'Click to upload & check audio',
                    button_update: 'Update with new audiofile',
                    button_update_hostread: 'Update this Host-Read request with new audiofile',
                    label_1: 'Current: ',
                    label_2: 'Last updated on: {databaseToPrettyDateTime(bookingRequest.audiofile_uploaded_date)}',
                    label_3: 'Rejected on: {databaseToPrettyDate(bookingRequest.rejected_date)}',
                    label_4: 'Cancelled on: {databaseToPrettyDate(bookingRequest.cancelled_date)}',
                    label_5: 'URL for "{bookingRequest.audiofile_name_original}": ',
                    label_6: 'Approved on: {databaseToPrettyDateTime(bookingRequest.approved_date)}',
                },
                spot_audio: {
                    title: 'Spot Audio',
                    category_1: {
                        title: 'Audio',
                        label_1: 'Current: ',
                        label_2: 'Last updated on: {databaseToPrettyDate(campaign.audiofile_uploaded_date)}',
                        button_download: 'Download Audio File',
                    },
                },
                deal_info: {
                    title: 'Deal Info',
                    category_1: {
                        title: 'Deal ID',
                    },
                    category_2: {
                        title: 'VAST URL',
                    },
                    category_3: {
                        title: 'Static Cross Promotion Endpoint',
                    },
                },
                ad_server_setup: {
                    title: 'Ad Server Setup',
                    category_1: {
                        title: 'Ad created',
                        description_hostread: 'Check this if you created the campaign for this Host-Read on your ad sever, or placed it in your podcast.',
                        description_spot: 'Check this if you created the campaign for this spot on your ad sever, or placed it in your podcast.',
                        description_crosspromo:
                            'Check this if you have either defined a general cross promotion endpoint on your ad server (static endpoint), or created the campaign for this cross promotion on your ad sever (VAST URL), or downloaded the audio file and placed it manually in your podcast episodes.',
                        // description_crosspromo: 'Check this if you created the campaign for this cross promotion on your ad sever, or placed it in your podcast.',
                    },
                    category_2: {
                        title: 'Ad Server Campaign ID',
                        input_placeholder: 'e.g. ad server campaign id or ad id',
                    },
                },
                report: {
                    title: 'Report',
                    button_upload: 'Submit Report',
                    button_update: 'Update Report',
                    label_1: 'Current: ',
                    label_2: 'Last updated on: {databaseToPrettyDateTime(bookingRequest.report_uploaded_date)}',
                    label_3: 'File: ',
                    label_4: 'Checked and approved on: {databaseToPrettyDateTime(bookingRequest.report_approved_date)}',
                },
            },
            campaign_overview: {
                crosspromo_table: {
                    title: 'Cross Promotions',
                    button_booking: 'New Cross Promotion',
                    headline_1: 'Campaign',
                    headline_2: 'Target contacts',
                    headline_3: 'Campaign contacts',
                    button_edit: 'Edit',
                },
                crosspromo_endpoint: {
                    title: 'Endpoint(s) ',
                    headline_1: 'Podcast',
                    headline_2: 'Endpoint',
                    button_copy: 'Copy',
                },
            },
            selfpromos: {
                title_general: 'Self promotion',
                title: 'Self promotions for',
                description:
                    'For editing and managing spots used for self promotion on various podcast platforms. ASAP can identify the podcast platforms listed below and, as soon as an audio file is uploaded for a platform, automatically attach the respective self-promotion per podcast to its episodes as the last spot.  For this, the respective podcast must be registered with ASAP, and the option "selfpromos" must be activated. For activation/deactivation, please contact the support team.',
                section_1: {
                    title: 'Audio',
                    state: 'Current',
                },
                section_2: 'Start date',
                section_3: 'End date',
            },
            inventory: {
                title_general: 'Inventory',
                description:
                    'ASAP-Dispo inventory management: In order for inventory management and planning to work best, all requests should be made through ASAP-Dispo. External enquiries can be created as campaigns via the "Inventory check". To get started, please enter the booking volume per month and playout position in the inventory settings.',
                settings: {
                    section_title: 'Podcast Inventory Settings',
                    card_title: 'Inventory settings for',
                    section_1: {
                        title: 'Booking volume/month and roll position',
                    },
                    section_2: {
                        title: 'Booking volume/day',
                    },
                    section_3: {
                        title: 'Booking volume/year',
                    },
                },
                filter_bar: {
                    start: 'Start',
                    end: 'End',
                    bookings: 'Bookings',
                    reservations: 'Reservations',
                    inventory_check: 'Inventory-Check',
                },
                titles: {
                    title_1: 'Overview for ',
                    title_2: 'Detail view for ',
                    entry_1: 'All ad-breaks combined',
                    entry_2: 'All campaigns on the Pre-Roll-position',
                    entry_3: 'All campaigns on the Mid-Roll-1-position',
                    entry_4: 'All campaigns on the Mid-Roll-2-position',
                    entry_5: 'All campaigns on the Mid-Roll-3-position',
                    entry_6: 'All campaigns on the Post-Roll-position',
                },
                inventory_check: {
                    title_new: 'New inventory check for an external booking request',
                    title_edit: 'Edit inventory check',
                    campaign_title: 'Campaign title *',
                    campaign_type: 'Campaign type *',
                    start_date: 'Campaign Start Date *',
                    end_date: 'Campaign End Date *',
                    roll_position: 'Roll position to check on *',
                    roll_position_error: 'Roll position to check on * - please select a roll position',
                    impressions: 'Target contacts *',
                    button_check: 'Check now',
                    button_cancel: 'Cancel',
                    button_delete: 'Delete',
                    button_update: 'Update',
                    button_reserve: 'Set up as booking',
                    button_book: 'Save as booking',
                },
                exclusive_promoter: {
                    section_title: 'Exclusive promoter',
                    card_title: 'Exclusive promoter of',
                    section_1: {
                        title: 'Promoter',
                    },
                    section_2: {
                        title: 'Currently there is no exclusive promoter for this podcast.',
                    },
                    section_3: {
                        title: 'PLACEHOLDER would like to promote PLACEHOLDER exclusively. This means that in this podcast, advertising will be provided exclusively by PLACEHOLDER.',
                    },
                    section_4: {
                        title: 'PLACEHOLDER has received an exclusive marketing request. The request can be edited under "Inventory" > "Settings" > "Exclusive Promoter", or click the button below.',
                    },
                },
            },
        },
        commit_messages: {
            default: {
                booking_request: {
                    booking_sent: 'booking request(s) sent',
                    reservation_sent: 'reservation request(s) sent',
                    sent: 'booking request sent',
                    updated: 'booking request updated',
                    accept_reservation: 'accept reservation',
                    accept_request: 'accept request',
                    accepted: 'booking request has been accepted',
                    rejected: 'booking request rejected for unknown reasons.',
                    audio_uploaded: 'audio file uploaded',
                    audio_approved: 'audio has been approved',
                    report_uploaded: 'report uploaded',
                    advertising_media_created: 'advertising media has been created on ad server.',
                    script_updated: 'script has been updated',
                    script_approved: 'script has been approved.',
                    report_approved: 'report accepted.',
                    no_feedback: 'no feedback supplied.',
                    cancelled: 'booking request has been cancelled based on modifications.',
                    impression_role_modifications_changes: 'contacts/roll position modification changes have been requested.',
                    impression_role_modifications_accepted: 'contacts/roll position modifications have been accepted - deal has been closed',
                    reject: 'reject request',
                },
                campaign: {
                    created: 'new campaign created & booking request sent',
                    updated: 'campaign details have been updated',
                    audio_uploaded: 'campaign audio uploaded',
                    audio_changed: 'campaign audio changed',
                    briefing_completed: 'campaign owner completed briefing',
                    sending_hostread_requests: 'sending Host-Read booking request(s)',
                    sending_spot_requests: 'sending spot booking request(s)',
                    sending_cross_promo_requests: 'sending cross promotion booking request(s)',
                    request_script_changes: 'request script changes',
                },
            },
            commit_message: {
                headline_1: 'Commit Message for "{action}"',
                headline_2: 'Would you like to provide a commit message for this action? (optional)',
                input_placeholder: 'Type commit message.',
                button_continue: 'Continue',
            },
            booking_modification: {
                entry_1: {
                    action: 'request booking changes',
                    headline_1: 'Please provide feedback on what should be changed.',
                },
                entry_2: {
                    action: 'delete booking request',
                    headline_1: 'Please provide feedback on why this booking request is deleted.',
                },
                entry_3: {
                    action: 'request audio changes',
                    headline_1: 'Please provide feedback on what should be changed.',
                },
            },
            commit_message_flow: {
                title_1: 'Commit messages for "{campaign.title}"',
                title_2: '- with "',
            },
        },
        ui_messages: {
            sorry: 'Sorry!',
            button_confirm: 'Proceed',
            button_cancel: 'Cancel',
            alerts: {
                general: {
                    alert_save_fail: 'Save failed. Please try again !',
                    alert_update_fail: 'Update failed. Please try again !',
                    alert_delete_fail: 'Delete failed. Please try again !',
                    alert_upload_fail: 'Upload failed. Please try again !',
                    alert_wrong_audioformat: 'Please provide a valid mp3 file !',
                    alert_required_information: 'Please enter the requiered information marked with * !',
                },
                podcast: {
                    alert_role_position: 'Please select a role position !',
                    alert_zero_impressions: 'You are offering 0 contacts! Please adjust your offer or reject this request.',
                    alert_maxed_impressions: 'You cannot offer more contacts than requested. Your offer will be set to the maximum.',
                    alert_no_audio_hostread: 'To accept this request, you must upload the audio file of the finished host read as it will run in the podcast !',
                    alert_mp3_check: 'Please provide a valid mp3 file',
                    alert_audio_upload: 'Failed to upload audio file ! error: {response.status}',
                    alert_report_upload: 'Failed to upload pdf file !',
                    alert_no_client: 'Please provide client information !',
                },
                promoter: {
                    alert_zero_impressions: 'Requests with 0 contacts requests will be ignored. Please edit or uncheck.',
                    alert_no_title: 'Please provide a title for this campaign !',
                    alert_no_acceptance_deadline: 'Please provide an acceptance deadline for this campaign !',
                    alert_no_start_date: 'Please provide a start date for this campaign !',
                    alert_no_end_date: 'Please provide an end date for this campaign !',
                    alert_no_target_impressions: 'Please provide the target contacts for this campaign !',
                    alert_no_audio_spot: 'Please provide an audio file for this spot !',
                    alert_no_audio_crosspromo: 'Please provide an audio file for this cross promotion !',
                    alert_no_sender: 'Please select a requesting podcast from your podcast list for this cross promotion !',
                    alert_no_approval_option:
                        'Please select at least one approval option for this Host-Read! This tells the podcasters for which steps your approval is required througout production.',
                    alert_role_position: 'Please select a role position !',
                    alert_mp3_check: 'Please provide a valid mp3 file',
                    alert_audio_upload: 'Failed to upload audio file ! error: {response.status}',
                    alert_report_upload: 'Failed to upload briefing file ! error: {response.status}',
                    alert_targeting_lower_target_impressions:
                        'Reducing this value leads to the campaign being overbooked ! Please adjust your booking requests. For now, the previous value will be restored.',
                    alert_targeting_higher_target_impressions: 'A higher value would overbook the campaign. Contacts will be set to the maximum for this request.',
                    alert_dates_title: 'Watch the Date Hirachy !',
                    alert_dates_line_1: 'Dates cannot lie in the past - editing is best done backwards !',
                    alert_dates_line_2: 'The chronological hirachy for dates is as follows:',
                    alert_dates_line_3: '1. booking acceptance deadline ->',
                    alert_dates_line_4: '2. campaign start ->',
                    alert_dates_line_5: '3. campaign end.',
                    alert_dates_line_6: 'Please provide valid values !',
                },
                login: {
                    alert_valid_credentails: 'Please provide valid log in credentials!',
                    alert_wrong_credentails: 'Email or password unknown. Please provide valid log in credentials!',
                },
            },
            warnings: {
                general: {
                    warning_no_changes: 'There are no changes to be saved!',
                    warning_no_data: 'Not all required data has been entered!',
                },
            },
            snackbars: {
                general: {
                    snackbar_save_success: 'Successfully saved.',
                    snackbar_update_success: 'Successfully updated.',
                    snackbar_delete_success: 'Successfully deleted.',
                },
                podcast: {
                    snackbar_booking_request_updated: 'Successfully updated booking request.',
                    snackbar_booking_request_create_ad: 'Tschaka! Now create ad on adserver - or place in booked episode(s).',
                    snackbar_booking_request_accepted_hostread: 'Successfully uploaded audio and accepted Host-Read request.',
                    snackbar_booking_request_updated_audio: 'Successfully updated audio.',
                    snackbar_selfpromo_date_reset: 'Date has been adapted!',
                    snackbar_inventory_future_dates: 'At this time, we cannot look further than one year into the future.',
                    snackbar_inventory_exists: 'A check has already been made for this campaign! Please use unique title.',
                    snackbar_exclusive_promoter_delete_success: 'The exclusive promotion by {company.name} was successfully terminated.',
                },
                promoter: {
                    snackbar_campaign_save_success: 'Succesfully saved new campaign !',
                },
                admin: {
                    snackbar_name_empty: 'The name field cannot be blank !',
                    snackbar_email_empty: 'The email field cannot be blank !',
                    snackbar_company_empty: 'The company_id field cannot be blank !',
                    snackbar_booking_volume_empty: 'The booking volume cannot be empty ! If zero is wanted, please put in 0.',
                    snackbar_entry_exists: 'There is already an entry with this name. Please change !',
                    snackbar_user_exists: 'There is already an entry with this email address. Please change !',
                    snackbar_password_includes_email: 'The password you entered contains the email address. Please edit password !',
                    snackbar_password_characters: 'The password entered must be longer than 8 characters. Please edit password!',
                    snackbar_email_format:
                        'The entered email address must follow the format "addresse@provider.top-level-domain", whereby the top-level domain must not contain any numbers. Please edit !',
                },
            },
            confirms: {
                podcast: {
                    confirm_selfpromo_edit:
                        'You are about to change a date from an existing self promotion. Dates in the past will be automatically updated to today. Are you sure you want to continue?',
                    confirm_selfpromo_delete: 'Deleting a self-promotion will result in its complete removal from the system. Continue?',
                    confirm_selfpromo_delete_title: 'Attention !',
                    confirm_campaign_cancel:
                        'Attention ! Cancelling a campaign and its booking request means that they are stopped with immediate effect. Reactivating them later is not possible. Are you sure you want to continue?',
                    confirm_exclusive_promotion_delete: 'Hereby, with immediate effect, the exclusive promotion by {campaign.name} is stopped. Continue?',
                },
                promoter: {
                    confirm_exclusive_promotion_delete: 'Hereby, with immediate effect, the exclusive marketing of {podcast.name} is stopped. Continue?',
                    confirm_exclusive_promotion_select:
                        'A request is hereby made to allow PLACEHOLDER to exclusively market the podcast PLACEHOLDER. This means that PLACEHOLDER may only place advertisements through PLACEHOLDER. Continue?',
                },
            },
        },
        buttons: {
            button_reject: 'Reject',
            button_agree: 'Agree',
            button_confirm: 'Confirm',
            button_save: 'Save',
            button_edit: 'Edit',
        },
        badges: {
            general: {
                entry_1: 'From: ',
            },
            booking_request: {
                entry_1: 'Rejected on: {databaseToPrettyDateTime(bookingRequest.cancelled_date)}',
                entry_2: 'Cancelled on: {databaseToPrettyDateTime(bookingRequest.cancelled_date)}',
            },
            text_editor: {
                entry_1: 'characters',
                entry_2: 'words',
                entry_3: 'Approved on: {databaseToPrettyDateTime(bookingRequest.script_approved_date)}',
                entry_4: 'Last updated on: {databaseToPrettyDateTime(bookingRequest.script_updated_date)}',
            },
            audio_status: {
                entry_1: 'Approved on: {databaseToPrettyDateTime(bookingRequest.audiofile_approved_date)}',
                entry_2: 'Last updated on: {databaseToPrettyDateTime(bookingRequest.audiofile_uploaded_date)}',
            },
            report_status: {
                entry_1: 'Approved on: {databaseToPrettyDateTime(bookingRequest.report_approved_date)}',
                entry_2: 'Last updated on: {databaseToPrettyDateTime(bookingRequest.report_updated_date)}',
            },
        },
        placeholder: {
            compose_script: 'Please compose script.',
        },
        user: {
            dropdown: {
                entry_1: 'Profile',
                entry_2: 'Logout',
                entry_3: 'Login',
            },
            password_reset: {
                title: 'Resetting password',
                headline_1: 'The new password must consist of at least 8 characters.',
                headline_2: 'At least one of them must be a number.',
                headline_3: 'At least one of them must be a letter.',
                headline_4: 'It may not contain any special characters, or spaces.',
                input_placeholder: 'Enter new password',
                button_1: 'Save new password',
                button_2: 'Enter valid passphrase to enable save',
            },
            profile: {
                button_cancel: 'Cancel',
                button_save: 'Save',
                button_reset_password: 'Reset Password',
                snackbar_1: 'Please check your mails, we have sent you a reset link. It can take a couple of minutes until you receive the link.',
            },
        },
        auth: {
            login: {
                snackbar_password_forgotten: 'Bitte die E-Mail-Adresse eingeben, an die der Link zum Zurücksetzen des Passworts gesendet werden soll! Die Zustellung kann ein paar Minuten dauern.',
            },
        },
        general: {
            roll_positions: {
                entry_1: 'Pre-Roll',
                entry_2: 'Mid-Roll-1',
                entry_3: 'Mid-Roll-2',
                entry_4: 'Mid-Roll-3',
                entry_5: 'Post-Roll',
            },
            roll_positions_missing_link: {
                entry_1: 'Pre-Roll',
                entry_2: 'Mid-Roll-1',
                entry_3: 'Mid-Roll-2',
                entry_4: 'Mid-Roll-3',
                entry_5: 'Mid-Roll-4',
                entry_6: 'Mid-Roll-5',
                entry_7: 'Post-Roll',
            },
        },
    };
</script>
