<script>
    export let snackbarDismissOpen;
    export let snackbarDismissMsg;
    export let snackbarDismissTitle;
    export let snackbarDismissType = 'success';
    let backgroundColor;

    snackbarDismissType == 'sucess'
        ? (backgroundColor = 'bg-emerald-500')
        : snackbarDismissType == 'info'
        ? (backgroundColor = 'bg-orange-500')
        : snackbarDismissType == 'warning' || snackbarDismissType == 'error' || snackbarDismissType == 'alert'
        ? (backgroundColor = 'bg-red-500')
        : '';

    function close() {
        snackbarDismissOpen = false;
    }
</script>

{#if snackbarDismissOpen}
    <div v-if="alertOpen" class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500">
        <span class="text-xl inline-block mr-3 align-middle">
            <i class="fas fa-bell" />
        </span>
        <span class="inline-block align-middle mr-1">
            <b class="capitalize mr-2">{snackbarDismissTitle ? snackbarDismissTitle : ''}</b>
            {snackbarDismissMsg}
        </span>
        <button class="absolute bg-transparent text-2xl font-semibold leading-none right-1 top-0 mt-4 mr-6 outline-none focus:outline-none" on:click={close}>
            <span>×</span>
        </button>
    </div>
{/if}
