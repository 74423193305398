<script>
    export let color = 'light';
    import {navigate} from 'svelte-routing';
    import {getAllKeywords} from '../../api/api.js';
    import {Pulse} from 'svelte-loading-spinners';
    import {getText} from '../../language.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let companyId;
    export let showNewButton = false;

    function newKeyword() {
        navigate('/admin/newkeyword', {replace: true});
    }

    let keywordsPromise = getKeywords();
    async function getKeywords() {
        try {
            return await getAllKeywords()
                .then((response) => response.json())
                .then((data) => {
                    // let sorted = data.sort((a, b) => {
                    //     let fa = a.keyword.toLowerCase(),
                    //         fb = b.keyword.toLowerCase();

                    //     if (fa < fb) {
                    //         return -1;
                    //     }
                    //     if (fa > fb) {
                    //         return 1;
                    //     }
                    //     return 0;
                    // });
                    // allKeywords = sorted;
                    // return sorted;
                    return data;
                });
        } catch (e) {
            console.log(`Error ${e} occoured whilst loading the keywords !`);
        }
    }
</script>

<div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">
    <div style="display: flex; justify-content: center" />
    <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
            <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 class="font-semibold text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}">
                    {getText('admin.keywords.all_keywords.title')}
                </h3>
            </div>
            {#if typeof companyId == 'undefined' && showNewButton}
                <CardButton id="new-keyword" type="go" text={getText('admin.keywords.all_keywords.button_new')} on:click={() => newKeyword()} />
            {/if}
        </div>
    </div>

    <div class="block w-full overflow-x-auto">
        <!-- Projects table -->
        <table class="items-center w-full bg-transparent border-collapse">
            <thead>
                <tr>
                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-red-700 text-red-200 border-red-600'}"
                    >
                        {getText('admin.keywords.all_keywords.headline_1')}
                    </th>
                    <th
                        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light'
                            ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                            : 'bg-red-700 text-red-200 border-red-600'}"
                    >
                        {getText('admin.keywords.all_keywords.headline_2')}
                    </th>
                </tr>
            </thead>

            {#await keywordsPromise}
                <div style="display: flex; justify-content: center">
                    <Pulse size="60" color="#FF3E00" unit="px" duration="1s" />
                </div>
            {:then data}
                <tbody />
                {#if Object.keys(data).length > 0}
                    {#each data as item}
                        <tr>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {item.id} </td>
                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {item.keyword} </td>
                        </tr>
                    {/each}
                {/if}
            {/await}
        </table>
    </div>
</div>
