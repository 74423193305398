<script>
    import {patchCompany, deleteCompanyById} from '../../api/api.js';
    import SnackbarNoDismiss from 'components/Snackbars/SnackbarNoDismiss.svelte';
    import {getText} from '../../language.svelte';
    import CardButton from 'components/Cards/CardButton.svelte';

    export let item;
    let newName = item.name;
    let snackbarNoDismissOpen = false;
    let snackbarNoDismissMsg;
    let snackbarNoDismissType;

    function editCompany() {
        // hide originals and show save options
        document.getElementById(`grid-button-edit-${item.id}`).style.display = 'none';
        document.getElementById(`grid-company-name-original-${item.id}`).style.display = 'none';
        document.getElementById(`grid-button-save-${item.id}`).style.display = 'flex';
        document.getElementById(`grid-company-name-${item.id}`).style.display = 'flex';
    }

    function displayOriginals() {
        document.getElementById(`grid-button-edit-${item.id}`).style.display = 'flex';
        document.getElementById(`grid-company-name-original-${item.id}`).style.display = 'flex';
        document.getElementById(`grid-button-save-${item.id}`).style.display = 'none';
        document.getElementById(`grid-company-name-${item.id}`).style.display = 'none';
    }

    async function saveCompany() {
        // check on same as original
        if (newName == item.name) {
            displayOriginals();
            return;
        }

        // check on not empty
        if (newName == '') {
            fireSnackbarNoDismiss(getText('ui_messages.snackbars.admin.snackbar_name_empty'), 'warning');
            return;
        }

        // check on uniqueness
        let save = true;
        let all = document.querySelectorAll('[id^=grid-company-name-original-]');
        all.forEach((ele) => {
            if (ele.innerHTML == newName) {
                fireSnackbarNoDismiss(getText('ui_messages.snackbars.admin.snackbar_entry_exists'), 'warning');
                save = false;
                return;
            }
        });
        // save changes
        if (save) {
            let data = {
                name: newName,
            };

            let updated = await patchCompany(item.id, data);
            item.name = updated.name;

            // set styles
            document.getElementById(`grid-company-name-original-${item.id}`).innerHTML = updated.name;
            displayOriginals();
        }
    }

    async function deleteCompany() {
        let answer = window.confirm(`Are you sure you want to delete company "${item.name}" ?`);

        if (answer) {
            try {
                await deleteCompanyById(item.id);
                document.getElementById(`grid-company-${item.id}`).remove();
            } catch (e) {
                console.log(`Something went wrong deleting company ${item.name}`);
            }
        } else {
            return;
        }
    }

    function fireSnackbarNoDismiss(msg, type) {
        snackbarNoDismissMsg = msg;
        snackbarNoDismissType = type;
        snackbarNoDismissOpen = true;
    }
</script>

<svelte:window
    on:keydown={(e) => {
        if (e.code == 'Escape') displayOriginals();
        if (e.code == 'Enter') {
            saveCompany();
        }
    }}
/>

{#if snackbarNoDismissOpen}
    <SnackbarNoDismiss {snackbarNoDismissMsg} bind:snackbarNoDismissOpen {snackbarNoDismissType} timeout="4000" />
{/if}

<tr id="grid-company-{item.id}">
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        <CardButton id="edit-{item.id}" type="default" icon="fas fa-pen text-sm" on:click={editCompany} />
        <CardButton id="save-{item.id}" classes="hidden" type="go" icon="fas fa-save text-sm" on:click={saveCompany} />
    </td>
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        {item.id}
    </td>
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        <input
            id="grid-company-name-{item.id}"
            type="text"
            class="hidden border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            placeholder={item.name}
            bind:value={newName}
        />
        <div id="grid-company-name-original-{item.id}">
            {item.name}
        </div>
    </td>
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"> {item.type} </td>
    <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        <CardButton id="delete-{item.id}" type="cancel" icon="fas fa-trash text-sm" on:click={deleteCompany} />
    </td>
</tr>
